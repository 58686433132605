import React, { Component } from "react";
import { debounce } from "lodash";
import "./index.scss";
import { getOptions } from "./helper";
import { forbiddenCheck } from "../../helper";
import { getPartnerName } from "../../../../component/ThemeManager/helper";

class GetFunded extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getOptions = debounce(getOptions.bind(this), 600);
    this.testCaseGetOptions = getOptions.bind(this);
  }

  componentWillMount() {
    window.location.href = process.env.REACT_APP_HOMEPAGE_URL;
  }

  componentDidMount() {
    document.title = `${getPartnerName()}: Sign Up`;
    forbiddenCheck();
  }

  render() {
    return <div></div>;
  }
}

export default GetFunded;
