import React, { Component } from "react";
import { IMG_VALIDATION_MESSAGES, PDF_VALIDATION_MESSAGE } from "./helper";
import whiteLabelStyles from "./WhiteLabel.module.scss";
import bbLogo from "../../../../img/icons/bb-blue.png";
import ccLogo from "../../../../img/icons/cc-favicon.png";
import { getPartnerName } from "../../../../component/ThemeManager/helper";
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoadError: false,
    };
  }
  /**
   * onImageError
   * @param {object} e
   * sets fallback profile image as src
   */
  onImageError = (e) => {
    const { imageLoadError } = this.state;
    if (imageLoadError === false) {
      e.target.src = getPartnerName() === "beatBread" ? bbLogo : ccLogo;
      this.setState({
        imageLoadError: true,
      });
    }
  };

  getErrors = (sizeError, typeError, dimensionError, uploadType) => {
    if (uploadType === "Favicon") {
      return (
        <>
          {sizeError && (
            <div className={whiteLabelStyles.errorText}>
              {IMG_VALIDATION_MESSAGES.FAVICON_SIZE}
            </div>
          )}
          {typeError && (
            <div className={whiteLabelStyles.errorText}>
              {IMG_VALIDATION_MESSAGES.FAVICON_TYPE}
            </div>
          )}
          {dimensionError && (
            <div className={whiteLabelStyles.errorText}>
              {IMG_VALIDATION_MESSAGES.FAVICON_DIMENSION}
            </div>
          )}
        </>
      );
    }
    if (uploadType === "Instruction PDF") {
      return (
        <>
          {sizeError && (
            <div className={whiteLabelStyles.errorText}>
              {PDF_VALIDATION_MESSAGE.SIZE}
            </div>
          )}
          {typeError && (
            <div className={whiteLabelStyles.errorText}>
              {PDF_VALIDATION_MESSAGE.TYPE}
            </div>
          )}
        </>
      );
    }
    return (
      <>
        {sizeError && (
          <div className={whiteLabelStyles.errorText}>
            {IMG_VALIDATION_MESSAGES.SIZE}
          </div>
        )}
        {typeError && (
          <div className={whiteLabelStyles.errorText}>
            {IMG_VALIDATION_MESSAGES.TYPE}
          </div>
        )}
        {dimensionError && (
          <div className={whiteLabelStyles.errorText}>
            {IMG_VALIDATION_MESSAGES.DIMENSION}
          </div>
        )}
      </>
    );
  };

  imgButtonContainer = () => {
    const {
      accept,
      aspectRatio,
      uploadType,
      maxSize,
      imageAvailable,
      editLogoMessage,
      buttonText,
    } = this.props;
    return imageAvailable ? (
      <>
        <img src={imageAvailable} alt={"logo"} onError={this.onImageError} />
        <p>{editLogoMessage}</p>
      </>
    ) : (
      <>
        <button type="button">
          <div>
            <p>{buttonText}</p>
            <p>Drag and drop or click to add {uploadType}.</p>
            <p>Recommended file formats are {accept}</p>
            <p>Max file size: {maxSize}</p>
            {aspectRatio && <p>Aspect ratio should not exceed {aspectRatio}</p>}
          </div>
        </button>
      </>
    );
  };

  render() {
    const {
      accept,
      uploadType,
      multiple,
      onChange,
      sizeError,
      typeError,
      dimensionError,
      buttonText,
    } = this.props;
    return (
      <>
        <input
          type="file"
          accept={accept}
          title={uploadType}
          multiple={multiple}
          onChange={onChange}
        />
        {uploadType === "Instruction PDF" ? (
          <button type="button">{buttonText}</button>
        ) : (
          <>{this.imgButtonContainer()}</>
        )}
        {this.getErrors(sizeError, typeError, dimensionError, uploadType)}
      </>
    );
  }
}
export default ImageUpload;
