import React, { Component } from "react";
import styles from "./ReviewEstimateContainer.module.scss";
import Odometer from "react-odometerjs";
import { getModifiedValue } from "./helper";
import Tooltip from "../../../component/Tooltip/Tooltip";
import Image from "../../../component/Image";
import midLogo from "../../../img/Rectangle108@2x.png";
import firstLogo from "../../../img/Rectangle152@2x.png";
import secondLogo from "../../../img/Rectangle153@2x.png";
import { get } from "lodash";
import { ESTIMATED_TOOLTIP_TEXT, POR_DEFAULT_OPTION } from "./constants";

class ReviewEstimateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishingModal: false,
      proOptions: [POR_DEFAULT_OPTION],
      publisherOptions: [],
    };
  }

  getMinEstimation = () =>
    get(this.props, "isSwitchDistributor")
      ? get(this.props.currentData, "min", 0) +
        get(this.props.currentData, "min", 0) * 0.1
      : get(this.props.currentData, "min", 0);
  getMaxEstimation = () =>
    get(this.props, "isSwitchDistributor")
      ? get(this.props.currentData, "max", 0) +
        get(this.props.currentData, "max", 0) * 0.3
      : get(this.props.currentData, "max", 0);

  getEstimatedDigits = () => {
    if (this.props.currentData) {
      return (
        <>
          <div className={`${styles.totalEstimateContainer}`}>
            <div className={`${styles.countText}`}>Total Advance between:</div>
            <div className={styles.mobFundingText}>Total Estimated Funding</div>
            <div className={styles.fundingContainer}>
              <div className={styles.fundingValues}>
                $
                <Odometer
                  value={
                    this.props.isCashFlow
                      ? getModifiedValue(
                          this.props.currentData,
                          this.props.cashflow_option_selected,
                        ).total_advance
                      : this.getMinEstimation()
                  }
                  format="(,ddd).dd"
                  duration={400}
                />
              </div>
              <span className={styles.andText}>and</span>
              <span className={styles.dashLine}>-</span>
              <div className={styles.fundingValues}>
                $
                <Odometer
                  value={
                    this.props.isCashFlow
                      ? getModifiedValue(
                          this.props.currentData,
                          this.props.cashflow_option_selected,
                        ).total_advance
                      : this.getMaxEstimation()
                  }
                  format="(,ddd).dd"
                  duration={400}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  showFullMenu = () => {
    new Set(document.getElementsByClassName(styles.collapseMenu)).forEach(
      (ele) => ele.classList.toggle(`${styles.active}`),
    );
    document
      .getElementsByClassName(`${styles.collapseArrow}`)[0]
      .classList.toggle(`${styles.arrowDown}`);
    document
      .getElementsByClassName(`${styles.totalEstimateContainer}`)[0]
      .classList.toggle(`${styles.active}`);

    document
      .getElementsByClassName(`${styles.primary}`)[0]
      .classList.toggle(`${styles.active}`);

    document
      .getElementsByClassName(`${styles.btnContainer}`)[0]
      .classList.toggle(`${styles.active}`);

    document
      .getElementsByClassName(`${styles.accurateTextContainer}`)[0]
      .classList.toggle(`${styles.active}`);
  };

  getImage = () => (
    <>
      <Image
        src={firstLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.firstImage}
      />
      <Image
        src={midLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.secondImage}
      />
      <Image
        src={secondLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.thirdImage}
      />
    </>
  );

  render() {
    return (
      <div className={styles.estimateContainer}>
        <div className={styles.fastMainContainer}>
          <div
            className={styles.collapseArrow}
            onClick={this.showFullMenu}
          ></div>
          <div
            className={`${styles.menuBarContainerBox} ${styles.collapseMenu}`}
          >
            <div className={styles.digitDetails}>
              <div className={styles.menuToolTip}>
                <p className={`mb-0`}>
                  Total Estimated Funding
                  <Tooltip
                    place="top"
                    dark
                    id="estimatedFunding"
                    delay={200}
                    content={ESTIMATED_TOOLTIP_TEXT}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className={styles.accountCount}>{this.getEstimatedDigits()}</div>
          <div className={styles.btnContainer}>
            <button
              className={styles.primary}
              data-testid="next-btn"
              onClick={() => this.props.saveFundingAdvance()}
            >
              NEXT
            </button>
            <p className={styles.finePrintText}>
              <span onClick={this.props.toggleFinePrintText}>
                Read the Fine Print
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewEstimateContainer;
