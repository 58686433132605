import React from "react";
import styles from "./Loader.module.scss";

const Loader = ({
  isOverlayBlur,
  solidBackground,
  isAdmin,
  className = "",
}) => {
  return (
    <div
      className={`${styles.container} ${styles.light}
       ${isOverlayBlur ? styles.blurOverlay : ""}
        ${solidBackground ? styles.solidBg : ""}
        ${isAdmin ? styles.smallBg : ""}
        ${className}
        `}
    >
      <i className={`spinner-border`}></i>
    </div>
  );
};

export default Loader;
