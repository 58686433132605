import React, { Component, createRef } from "react";
import ReactModal from "react-modal";
import _, { get } from "lodash";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import {
  IMG_EXTENSIONS_REGEX,
  IMG_MAX_SIZE,
} from "../AdminDashboard/WhiteLabel/helper";
import whiteLabelStyles from "../AdminDashboard/WhiteLabel/WhiteLabel.module.scss";
import ImageUpload from "../AdminDashboard/WhiteLabel/ImageUpload";
import LeftPanel from "../AdminDashboard/LeftPanel";
import styles from "../AdminDashboard/AdminDashboard.module.scss";
import classes from "./DemoArtists.module.scss";
import { GetErrorMessage } from "../helper";
import {
  API_URL,
  DEMO_ARTIST_EDIT,
  DEMO_ARTIST_CREATE,
  DEMO_ARTIST,
  ADMIN,
  DEMO_ARTIST_API,
  ARTIST_API,
  SAVE_DEMO_ARTIST,
  DEMO_ARTIST_DATASET,
} from "../constants";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import FormField from "../../../component/FormField/FormField";
import request from "../../../utils/request";
import { FIELD_NAMES, FIELD_INITIAL_VALUES } from "./constants";
import { FIELD_MAX_CHARACTERS } from "../AdminDashboard/WhiteLabel/constants";
import { getPartnerName } from "../../../component/ThemeManager/helper";
import selectStyle from "../../../component/UploadFiles/selectStyle";

const useDefaultToken = false;
class DemoArtistForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: FIELD_INITIAL_VALUES,
      isEdit: false,
      profilePicture: { src: "", fileName: "" },
      profilePictureTypeError: false,
      profilePictureDimensionError: false,
      profilePictureSizeError: false,
      datasetOptions: [],
    };
    this.editorRef = createRef(null);
  }

  componentDidMount() {
    this.getDataset();
    this.setTitle();
  }

  setTitle = () => {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    if (pathname === DEMO_ARTIST_EDIT) {
      document.title = `${getPartnerName()}: Edit Demo Artist`;
    } else {
      document.title = `${getPartnerName()}: Create Demo Artist`;
    }
  };

  setValuesFromAPI = (res) => {
    const { data } = res;
    return {
      initialValues: {
        [FIELD_NAMES.DEMO_ARTIST_NAME.NAME]: get(data, "artistData.name", ""),
        [FIELD_NAMES.PROFILE_SUMMARY.NAME]: get(
          data,
          "artistData.profileSummary",
          "",
        ),
        [FIELD_NAMES.MUSIC_GENRE.NAME]: get(data, "artistData.musicGenre", ""),
        [FIELD_NAMES.PROFILE_GUIDE.NAME]: get(
          data,
          "artistData.profileGuide",
          "",
        ),
        [FIELD_NAMES.OFFER_DATASET.NAME]: get(
          data,
          "artistData.dummy_artist_type",
        ),
        [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2.NAME]: get(
          data,
          "artistData.funnelGuideVerification2",
          "",
        ),
        [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3.NAME]: get(
          data,
          "artistData.funnelGuideIncomeVerification",
          "",
        ),
        [FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION.NAME]: get(
          data,
          "artistData.funnelGuideCustomization",
          "",
        ),
        [FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS.NAME]: get(
          data,
          "artistData.funnelGuideSendReports",
          "",
        ),
        [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
      },
    };
  };

  getDataset = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${DEMO_ARTIST_DATASET}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const data = get(res, "data", []).map((dataset) => ({
            label: dataset.artist_type,
            value: dataset.artist_type_id,
          }));
          this.setState(
            {
              datasetOptions: data,
            },
            this.validateForEdit,
          );
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  validateForEdit = () => {
    const {
      history: {
        location: { pathname, state },
      },
    } = this.props;
    if (pathname.includes(DEMO_ARTIST_EDIT) && get(state, "artist._id")) {
      this.setState({
        isEdit: true,
        loading: true,
      });
      const data = {
        method: "GET",
      };
      const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${DEMO_ARTIST_API}?artistId=${state.artist._id}`;
      request(requestUrl, data, useDefaultToken)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setState({
              profilePicture: {
                src: get(
                  res.data,
                  `artistData.profilePicture[${
                    get(res.data, "artistData.profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                ),
                fileName: get(
                  res.data,
                  `artistData.profilePicture[${
                    get(res.data, "artistData.profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                )
                  .split("/")
                  .pop()
                  .trim(),
              },
            });
            const values = this.setValuesFromAPI(res);
            this.setState({ initialValues: values.initialValues });
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    } else {
      this.setState({ isEdit: false });
      this.props.history.push(DEMO_ARTIST_CREATE);
    }
  };

  validationSchema = Yup.object().shape({
    [FIELD_NAMES.DEMO_ARTIST_NAME.NAME]: Yup.string()
      .required()
      .label(FIELD_NAMES.DEMO_ARTIST_NAME.LABEL),
    [FIELD_NAMES.PROFILE_SUMMARY.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.PROFILE_SUMMARY.LABEL),
    [FIELD_NAMES.MUSIC_GENRE.NAME]: Yup.string()
      .required()
      .label(FIELD_NAMES.MUSIC_GENRE.LABEL),
    [FIELD_NAMES.OFFER_DATASET.NAME]: Yup.string()
      .required()
      .label(FIELD_NAMES.OFFER_DATASET.LABEL),
    [FIELD_NAMES.PROFILE_GUIDE.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.PROFILE_GUIDE.LABEL),
    [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2.LABEL),
    [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3.LABEL),
    [FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION.LABEL),
    [FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS.NAME]: Yup.string()
      .required()
      .max(FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT)
      .label(FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS.LABEL),
    [FIELD_NAMES.ADMIN_PASSWORD.NAME]: Yup.string().label(
      FIELD_NAMES.ADMIN_PASSWORD.LABEL,
    ),
  });

  checkImageValidation = (file, type) => {
    this.setState({
      profilePictureTypeError: false,
      profilePictureDimensionError: false,
      profilePictureSizeError: false,
    });
    if (!IMG_EXTENSIONS_REGEX.test(file[0].type)) {
      this.setState({ [`${type}TypeError`]: true });
      return false;
    }
    if (file[0].size > IMG_MAX_SIZE) {
      this.setState({ [`${type}SizeError`]: true });
      return false;
    }
    return file;
  };

  onChangeFileHandler = (event, type) => {
    const file = event.target.files;
    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file, type);
    }
    if (validateFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file[0]);
      const fileToSave = file[0];
      img.onload = () => {
        this.setState({
          [type]: {
            fileName: fileToSave && fileToSave.name,
            src: img.src,
          },
        });
        window.URL.revokeObjectURL(file[0]);
      };
    }
    event.target.value = null;
  };

  getImageBlob = async (type) => {
    const payload = {
      blob: true,
    };
    return await request(this.state[type].src, payload).then((r) => r);
  };

  checkIfImageChanged = (type) => {
    if (this.state[type].src.includes("blob")) {
      return true;
    }
    return false;
  };

  onSubmit = async (values) => {
    const formData = new FormData();
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { isEdit, profilePicture } = this.state;
    const hasProfilePictureChanged = this.checkIfImageChanged("profilePicture");
    if (hasProfilePictureChanged) {
      const profilePictureBlob = await this.getImageBlob("profilePicture");
      formData.append(
        "profilePicture",
        profilePictureBlob,
        profilePicture.fileName,
      );
    }
    formData.append("artistName", values[FIELD_NAMES.DEMO_ARTIST_NAME.NAME]);
    formData.append("profileSummary", values[FIELD_NAMES.PROFILE_SUMMARY.NAME]);
    formData.append("profileGuide", values[FIELD_NAMES.PROFILE_GUIDE.NAME]);
    formData.append("musicGenre", values[FIELD_NAMES.MUSIC_GENRE.NAME]);
    formData.append("dataset", values[FIELD_NAMES.OFFER_DATASET.NAME]);
    formData.append(
      "funnelGuideVerification2",
      values[FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2.NAME],
    );
    formData.append(
      "funnelGuideIncomeVerification",
      values[FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3.NAME],
    );
    formData.append(
      "funnelGuideCustomization",
      values[FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION.NAME],
    );
    formData.append(
      "funnelGuideSendReports",
      values[FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS.NAME],
    );
    formData.append("adminPassword", values[FIELD_NAMES.ADMIN_PASSWORD.NAME]);
    if (isEdit) {
      formData.append("artistId", state.artist._id);
    }

    this.setState({
      loading: true,
    });
    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${SAVE_DEMO_ARTIST}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(
            `${
              values[FIELD_NAMES.DEMO_ARTIST_NAME.NAME]
            } artist is successfully ${isEdit ? `updated!` : `created!`}`,
          );
          this.props.history.push(DEMO_ARTIST);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  editorInit = (placeholder) => ({
    placeholder,
    height: 200,
    width: "100%",
    menubar: "edit view insert format help",
    branding: false,
    extended_valid_elements: "style",
    custom_elements: "style",
    plugins: ["advlist lists link preview", "code", "media table paste code "],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic forecolor backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat",
    toolbar_sticky: true,
    toolbar_mode: "sliding",
    content_style: "body { font-family:Montserrat; font-size:14px }",
  });

  getHtmlEditor = (setFieldValue, field) => (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onInit={(evt, editor) => (this.editorRef.current = editor)}
      initialValue={this.state.initialValues[field.NAME]}
      onEditorChange={(e) => {
        setFieldValue(field.NAME, e);
      }}
      init={this.editorInit(field.PLACEHOLDER)}
    />
  );

  renderNameAndLogo = (setFieldValue, values) => {
    const { profilePicture, profilePictureSizeError, profilePictureTypeError } =
      this.state;
    return (
      <div className={whiteLabelStyles.nameAndLogoContainer}>
        <p>Artist Profile Details</p>
        <div className={whiteLabelStyles.subContainer}>
          <div>
            <div className={`form-group ${whiteLabelStyles.formGroup}`}>
              <FormField
                name={FIELD_NAMES.DEMO_ARTIST_NAME.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.DEMO_ARTIST_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.DEMO_ARTIST_NAME.LABEL}*`}
              />
            </div>
            <div className={`form-group ${whiteLabelStyles.formGroup}`}>
              <FormField
                name={FIELD_NAMES.MUSIC_GENRE.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.MUSIC_GENRE.PLACEHOLDER}
                label={`${FIELD_NAMES.MUSIC_GENRE.LABEL}*`}
              />
            </div>
            <div className={`${whiteLabelStyles.formGroup}`}>
              <span class="textPrimary textSize14 textBold d-block text-left">
                {FIELD_NAMES.OFFER_DATASET.LABEL}*
              </span>
              <Select
                options={this.state.datasetOptions}
                placeholder="Select a dataset"
                value={_.find(this.state.datasetOptions, {
                  value: values[FIELD_NAMES.OFFER_DATASET.NAME],
                })}
                onChange={(e) => {
                  setFieldValue(FIELD_NAMES.OFFER_DATASET.NAME, e.value);
                }}
                styles={{
                  ...selectStyle,
                  placeholder: (provided) => ({
                    ...provided,
                    color: `#5d5d5d`,
                  }),
                }}
              />
              <FormField
                name={FIELD_NAMES.OFFER_DATASET.NAME}
                type="text"
                as={"textMsg"}
              />
            </div>
          </div>
          <div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="Profile Picture"
                maxSize="250 KB"
                editLogoMessage="Edit Profile Picture"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "profilePicture")}
                imageAvailable={profilePicture.src}
                buttonText="+ Add Profile Picture"
                sizeError={profilePictureSizeError}
                typeError={profilePictureTypeError}
                data-testid="profilePicture"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  validateCondition = (validateForm, handleSubmit) => {
    validateForm().then((val) => {
      !_.size(val) ? this.handleOpenPasswordBox() : handleSubmit();
    });
  };

  checkLogoError = () => {
    const {
      profilePictureDimensionError,
      profilePictureTypeError,
      profilePictureSizeError,
    } = this.state;
    return (
      profilePictureDimensionError ||
      profilePictureSizeError ||
      profilePictureTypeError
    );
  };

  renderSubmitButton = (validateForm, handleSubmit) => {
    const { loading, isEdit } = this.state;
    const isButtonDisabled = loading || this.checkLogoError();
    return (
      <div className={whiteLabelStyles.saveButton}>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-link"
          onClick={() => {
            toast.info("Data entered in this form will not be saved.");
            this.props.history.push(DEMO_ARTIST);
          }}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            this.validateCondition(validateForm, handleSubmit);
          }}
          type="button"
          className={isButtonDisabled && whiteLabelStyles.disabledSubmitButton}
          disabled={isButtonDisabled}
        >
          {isEdit ? "Save Changes" : "Create Demo Artist"}
        </button>
      </div>
    );
  };

  handleClosePasswordBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ passwordBox: false });
  };
  handleOpenPasswordBox = () => this.setState({ passwordBox: true });

  renderAdminPasswordField = (isValid, handleSubmit, values, setFieldValue) => {
    if (!isValid && this.state.passwordBox)
      this.setState({ passwordBox: false });
    return (
      <ReactModal
        isOpen={this.state.passwordBox && isValid}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={() => this.handleClosePasswordBox(setFieldValue)}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          {this.state.isEdit ? "Save Changes for" : "Create Demo Artist"}{" "}
          <span className={styles.primaryName}>
            {values[FIELD_NAMES.DEMO_ARTIST_NAME.NAME]}
          </span>
          ?
        </h3>
        <div className={styles.changeSource}>
          <p>Please input the password to confirm these changes: </p>
          <div
            className={`${styles.changeTo} form-group justify-content-center`}
          >
            <FormField
              name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
              label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
              placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
              as="password"
            />
          </div>
        </div>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
            onClick={handleSubmit}
            type="submit"
          >
            Okay
          </button>
          <button
            className={styles.secondary}
            onClick={() => this.handleClosePasswordBox(setFieldValue)}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    );
  };

  summaryDetailContainer = (setFieldValue) => (
    <>
      <div className={whiteLabelStyles.wpDomainURL}>
        <p>{FIELD_NAMES.PROFILE_SUMMARY.LABEL_TO_DISPLAY}</p>
        <div className={whiteLabelStyles.subContainer}>
          <span className="textPrimary textSize14 textBold">
            {FIELD_NAMES.PROFILE_SUMMARY.LABEL}*
          </span>
          <div
            className={`form-group ${whiteLabelStyles.formGroup} d-block ${classes.summaryContainer}`}
          >
            {this.getHtmlEditor(setFieldValue, FIELD_NAMES.PROFILE_SUMMARY)}
            <FormField
              name={FIELD_NAMES.PROFILE_SUMMARY.NAME}
              type="text"
              as={"textMsg"}
            />
          </div>
          <span className="textPrimary textSize14 textBold">
            {FIELD_NAMES.PROFILE_GUIDE.LABEL}*
          </span>
          <div
            className={`form-group ${whiteLabelStyles.formGroup} d-block ${classes.summaryContainer}`}
          >
            {this.getHtmlEditor(setFieldValue, FIELD_NAMES.PROFILE_GUIDE)}
            <FormField
              name={FIELD_NAMES.PROFILE_GUIDE.NAME}
              type="text"
              as={"textMsg"}
            />
          </div>
        </div>
      </div>
      <div className={whiteLabelStyles.wpDomainURL}>
        <p>{FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_1.EXTRA_TEXT}</p>
        <div className={whiteLabelStyles.subContainer}>
          {[
            FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2,
            FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3,
            FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION,
            FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS,
          ].map((field) => (
            <>
              <span className="textPrimary textSize14 textBold">
                {field.LABEL}*
              </span>
              <div
                className={`form-group ${whiteLabelStyles.formGroup} d-block ${classes.summaryContainer}`}
              >
                {this.getHtmlEditor(setFieldValue, field)}
                <FormField name={field.NAME} type="text" as={"textMsg"} />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );

  render() {
    const { loading, isEdit } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>{isEdit ? "Edit" : "Create New"} Demo Artist</h1>
            </div>
            <div className={whiteLabelStyles.contentContainer}>
              <Formik
                initialValues={this.state.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
                data-testid="formRender"
              >
                {({
                  values,
                  isValid,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                }) => (
                  <Form>
                    {this.renderNameAndLogo(setFieldValue, values)}
                    {this.summaryDetailContainer(setFieldValue, values)}
                    {this.renderSubmitButton(validateForm, handleSubmit)}
                    {this.renderAdminPasswordField(
                      isValid,
                      handleSubmit,
                      values,
                      setFieldValue,
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {loading && <Loader data-testid="Loader" />}
      </>
    );
  }
}
export default DemoArtistForm;
