import React, { Component } from "react";
import { get, invoke } from "lodash";
import styles from "./HorizontalMenu.module.scss";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { VERIFIED } from "../MenuBar/constants";
import StorageService from "../../utils/StorageService";
import {
  TUNE_MY_DATA,
  FUNDING,
  SEND_REPORTS,
  OFFER_SUMMARY,
  ACCEPT_OFFER,
  LINK_SPOTIFY,
  API_URL,
  USER_API,
  ARTIST_API,
  NAVIGATION_STATUS_API,
  WAITING_ROOM,
  GET_EVEN_MORE,
  UPCOMING_WORKS,
  INVITE_BIDDERS,
  POST_CONFIRMATION,
  SET_TARGET_ADVANCE,
  POSTED_TO_NETWORK,
  ARTIST_REGISTRATION,
  INVITE_BIDDERS_PREVIEW,
  HOME,
  ADMIN,
  LOGGED_OUT,
  LOGIN,
  ADMIN_DASHBOARD,
  DASHBOARD,
  ACTIVATE_API,
  REGISTER_EVENT_API,
  GENERATING_OFFERS,
  WELCOME_BACK,
  AUTH,
  FUNDING_OPTION,
  PUBLISHING_REPORTS,
  CONFIRM_OWNERSHIP,
  CONFIRMED_OFFERS_V2,
} from "../../routes/pages/constants";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import { ReactComponent as NeedHelpIcon } from "../../assets/logos/needHelpIcon.svg";
import { ReactComponent as SettingIcon } from "../../assets/logos/settingIcon.svg";
import { ReactComponent as ArtistSvg } from "../../assets/logos/artist.svg";
import MyAccount from "../../routes/pages/MyAccount/MyAccount";
import EmailPreferences from "../../routes/pages/EmailPreferences/EmailPreferences";
import { fetchAccountImg } from "../commonApiCall/AccountImageCall";
import Image from "../Image";
import {
  NAVIGATION_ICONS,
  NAVIGATION_TEXTS,
  VERIFICATION_STATUS,
  VERIFICATION_STATUS_ROUTE,
  NAV_STATUS,
  mobileNavigationStyle,
  LEFTNAVIGATION_ICONS,
  ROUTES,
  FAST_FLOW,
  ARTIST_OUT_RANGE_STATUS_LIST,
} from "./constants";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../routes/pages/helper";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { Menu, MenuItem } from "@material-ui/core";
import {
  EMAIL_PREFERENCES,
  LINK_ANOTHER_ARTIST,
  MY_ACCOUNT,
} from "../LoginHeader/constants";
import { artistDropDownStyle } from "../../routes/pages/Auth/Getfunded/autofillStyle";
import Dashboard from "../../routes/pages/Dashboard/Dashboard";
import LinkAnohterArtist from "../../routes/pages/Auth/LinkAnotherArtist";
import ContactPopup from "../ContactPopup";
import OverLay from "../OverLay";
import AuthTokenService from "../../utils/AuthTokenService";
import {
  logout,
  logoutFromWaitingRoom,
  setDataDogUser,
} from "../../utils/Helper";
import { IMAGE_TYPE } from "../Image/constants";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../MaterialIcons/constants";
import Loader from "../Loader";
import NeedHelpPopup from "../NeedHelpPopup/NeedHelpPopup";
import ShortCallLoader from "../Loader/ShortCallLoader";
import { ReactComponent as HelpIcon } from "../../assets/logos/help-icon.svg";
import { numberWithCommas } from "../../routes/pages/YourAdvance/helper";
import {
  CHOOSE_PATH_FIELDS,
  FAST_FLOW_STEPS,
} from "../../routes/pages/Auth/LinkAnotherArtist/constant";
import { RenderModal } from "../LoginHeader/helper";
import segment from "../../utils/segment";
import {
  RIGHTS_TYPES,
  SWITCH_MIN,
} from "../../routes/pages/YourAdvance/constants";

class HorizontalMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: undefined,
      role: "",
      isOpen: false,
      isDropOpen: false,
      loading: false,
      artists: [],
      selectedArtist: null,
      isDrawerOpen: false,
      drawerComponent: "",
      userEmail: "",
      isOfferReady: false,
      artistOfferReady: {},
      pathname: "",
      navigationStatus: {},
      artistLoader: false,
      estimateModalOpen: false,
      fastFlowStep: "",
      offerApiInProgress: false,
    };
    this.eventSource = null;
    this.offerInterval = null;
  }

  componentDidMount() {
    this.drawerComponent();
    !this.props.isDemoDashboard && fetchAccountImg.call(this);
    this.setState({ pathname: this.props.history.location.pathname });
    this.getArtistStatus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userToken !== this.props.userToken) {
      fetchAccountImg.call(this);
    }
  }

  componentWillUnmount() {
    this.unregisterEventSource();
  }

  drawerComponent = () => {
    this.props.drawerComponent && this.props.drawerComponent(this.handleDrawer);
  };

  unregisterEventSource = () => {
    if (this.eventSource && typeof this.eventSource.close === "function") {
      this.eventSource.close();
    }
  };

  registerEventSource = (artistId) => {
    this.eventSource = new EventSource(
      `${API_URL}${USER_API}${ARTIST_API}${REGISTER_EVENT_API}?artistId=${artistId}`,
    );

    this.eventSource.onerror = (err) => {
      this.unregisterEventSource();
      this.registerEventSource(artistId);
    };

    this.eventSource.onmessage = (event) => {
      const data = JSON.parse(get(event, "data", "{}"));
      this.setState({ isOfferReady: true, artistOfferReady: data });
      this.unregisterEventSource();
    };
  };

  routeHandling = () => {
    const { pathname } = this.props.history.location;
    if (
      TUNE_MY_DATA === pathname &&
      get(this.state.navigationStatus, "isFastFlow")
    ) {
      this.props.history.push(HOME);
    }
    if (
      pathname === WAITING_ROOM &&
      get(this.state.navigationStatus, "isFastFlow")
    ) {
      return true;
    }
    if (
      ROUTES.WAITING_ROOM.indexOf(pathname) !== -1 &&
      get(this.state.navigationStatus, "artistStatus") !==
        NAV_STATUS.IN_RANGE &&
      !get(this.state.navigationStatus, "finishingTouch")
    ) {
      return false;
    }
    if (
      get(this.state.navigationStatus, "isFastFlow") &&
      [
        FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
        FAST_FLOW_STEPS.INCOME_VERIFIED,
        FAST_FLOW_STEPS.PENDING,
      ].indexOf(get(this.state.navigationStatus, "artistStatus")) !== -1
    ) {
      return false;
    }
    if (
      ROUTES.FUNNEL.indexOf(pathname) !== -1 &&
      !get(this.state.navigationStatus, "offer_approved") &&
      get(this.state.navigationStatus, "artistStatus") === NAV_STATUS.IN_RANGE
    ) {
      if (
        pathname === FUNDING &&
        get(this.state.navigationStatus, "isFastFlow") &&
        get(this.state.navigationStatus, "choosePath") ===
          CHOOSE_PATH_FIELDS().OPTIONS[0].value
      ) {
        this.props.history.replace(HOME);
      }
      if (
        pathname === GENERATING_OFFERS &&
        !get(this.state.navigationStatus, "reports") &&
        get(this.state.navigationStatus, "isFastFlow")
      ) {
        this.props.history.replace(HOME);
        return false;
      } else if (
        pathname === GENERATING_OFFERS &&
        !get(this.state.navigationStatus, "finishingTouch") &&
        !get(this.state.navigationStatus, "isFastFlow")
      ) {
        this.props.history.replace(HOME);
      }
      return false;
    }
    if (
      ROUTES.FINAL_CHECKOUT.indexOf(pathname) !== -1 &&
      !get(this.state.navigationStatus, "isMarketPlace") &&
      get(this.state.navigationStatus, "offer_approved")
    ) {
      return false;
    }
    if (
      ROUTES.MARKETPLACE.indexOf(pathname) !== -1 &&
      !get(this.state.navigationStatus, "artistRegistration") &&
      get(this.state.navigationStatus, "isMarketPlace")
    ) {
      return false;
    }
    this.props.history.replace(HOME);
  };

  getArtistStatus = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${NAVIGATION_STATUS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState(
            { navigationStatus: get(res, "data", {}) },
            this.routeHandling,
          );
          this.props.getStatus &&
            this.props.getStatus(get(res, "data", {}), this.getArtistStatus);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getStatusIndicatorClass = (route) => {
    const { navigationStatus } = this.state;
    if (navigationStatus.artistStatus === NAV_STATUS.PENDING)
      return styles.initialIndicator;
    if (
      route === LINK_SPOTIFY &&
      [NAV_STATUS.LINKED, NAV_STATUS.LINK_PENDING].indexOf(
        navigationStatus.spotifyStatus,
      ) !== -1
    )
      return styles.successIndicator;
    if (this.getPipelineStatus() >= VERIFICATION_STATUS_ROUTE[route])
      return styles.successIndicator;
    return "";
  };

  getGeneratingOfferStatus = (artist, isJWT) => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}?requestId=${get(
      artist,
      "request_id",
      "",
    )}&artistId=${get(artist, "_id", "")}${isJWT ? "&isJWT=true" : ""}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (get(res, "data.artistStatus") === FAST_FLOW_STEPS.IN_RANGE) {
            this.setState({ isOfferReady: true });
          } else if (
            [
              FAST_FLOW_STEPS["2BIG"],
              FAST_FLOW_STEPS["2NEW"],
              FAST_FLOW_STEPS["2SMALL"],
            ].indexOf(get(res, "data.artistStatus")) !== -1 ||
            isJWT
          ) {
            invoke(this.props, "history.push", HOME);
          } else {
            this.getGeneratingOfferStatus(artist, true);
          }
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getStatusNavigation = (status) =>
    status && this.state.navigationStatus.isMarketPlaceEnabled ? true : false;

  renderGetEvenMoreMenu = () => {
    const { pathname } = this.state;
    return (
      <>
        <Link
          to={GET_EVEN_MORE}
          className={`${styles.menuItem} ${
            pathname === GET_EVEN_MORE && styles.active
          }`}
        >
          <div className={styles.options}>
            <LEFTNAVIGATION_ICONS.MONEY />
            <p>Get Even More</p>
          </div>
        </Link>

        {this.getStatusNavigation(this.state.navigationStatus.getEvenMore) && (
          <Link
            to={SET_TARGET_ADVANCE}
            className={`${styles.menuItem} ${
              pathname === SET_TARGET_ADVANCE && styles.active
            }`}
          >
            <div className={styles.options}>
              <NAVIGATION_ICONS.DIAL />
              <p>Set Target Advance</p>
            </div>
          </Link>
        )}

        {this.getStatusNavigation(this.state.navigationStatus.targetOffer) && (
          <Link
            to={UPCOMING_WORKS}
            className={`${styles.menuItem} ${
              pathname === UPCOMING_WORKS && styles.active
            }`}
          >
            <div className={styles.options}>
              <NAVIGATION_ICONS.PROFILE />
              <p>Artist Profile</p>
            </div>
          </Link>
        )}

        {this.getStatusNavigation(
          this.state.navigationStatus.artistProfile,
        ) && (
          <Link
            to={INVITE_BIDDERS}
            className={`${styles.menuItem} ${
              (pathname === INVITE_BIDDERS ||
                pathname === INVITE_BIDDERS_PREVIEW) &&
              styles.active
            }`}
          >
            <div className={styles.options}>
              <NAVIGATION_ICONS.INVITE />
              <p>Invite Bidders</p>
            </div>
          </Link>
        )}

        {this.getStatusNavigation(this.state.navigationStatus.inviteBidder) && (
          <Link
            to={POST_CONFIRMATION}
            className={`${styles.menuItem} ${
              pathname === POST_CONFIRMATION && styles.active
            }`}
          >
            <div className={styles.options}>
              <LEFTNAVIGATION_ICONS.MONEY />
              <p>Confirmation</p>
            </div>
          </Link>
        )}
      </>
    );
  };
  renderPostConfirmationMenu = () => {
    const { pathname } = this.state;
    return (
      <Link
        to={POSTED_TO_NETWORK}
        className={`${styles.menuItem} ${
          pathname === POSTED_TO_NETWORK && styles.active
        }`}
      >
        <div className={styles.options}>
          <LEFTNAVIGATION_ICONS.MONEY />
          <p>Posted To Network</p>
        </div>
      </Link>
    );
  };
  renderPostToInvestorMenu = () => {
    if (this.state.navigationStatus.artistRegistration) {
      return this.renderPostConfirmationMenu();
    } else {
      return this.renderGetEvenMoreMenu();
    }
  };

  getPipelineStatus = () => {
    const { navigationStatus } = this.state;
    const statusArray = [];
    statusArray.push(navigationStatus.streamingData === VERIFIED);
    statusArray.push(navigationStatus.funding === VERIFIED);
    statusArray.push(!!navigationStatus.reports);
    statusArray.push(!!navigationStatus.publisherReport);
    return statusArray.lastIndexOf(true);
  };

  getStatusLinkCard = (Icon, route, isDisplay, isText = "") => {
    const redirectionRoute =
      route === TUNE_MY_DATA &&
      this.state.navigationStatus.artistStatus !== NAV_STATUS.IN_RANGE
        ? WAITING_ROOM
        : route;
    const linkTitle =
      route === TUNE_MY_DATA &&
      this.state.navigationStatus.artistStatus !== NAV_STATUS.IN_RANGE
        ? FAST_FLOW.WAITING
        : NAVIGATION_TEXTS[route].title;
    const CurrentIcon =
      route === TUNE_MY_DATA &&
      this.state.navigationStatus.artistStatus !== NAV_STATUS.IN_RANGE
        ? NAVIGATION_ICONS.GENERATING
        : Icon;
    return (
      isDisplay && (
        <Link
          to={redirectionRoute}
          className={`${styles.menuItem} ${
            this.state.pathname === redirectionRoute && styles.active
          }`}
        >
          <div className={styles.options}>
            <CurrentIcon />
            <p>{isText || linkTitle}</p>
          </div>
        </Link>
      )
    );
  };

  showGeneratingOffer = () => {
    if (this.getPipelineStatus() >= VERIFICATION_STATUS.generatingOffers - 3) {
      if (get(this.state, "navigationStatus.addPublisher")) {
        if (
          (get(this.state, "navigationStatus.initialRightsType") !==
            RIGHTS_TYPES[1].value ||
            get(this.state, "navigationStatus.rightsType") ===
              RIGHTS_TYPES[2].value) &&
          get(this.state, "navigationStatus.reports") &&
          get(this.state, "navigationStatus.publisherReport")
        ) {
          return true;
        }
        return (
          get(
            this.state,
            "navigationStatus.rightsType",
            get(this.state, "navigationStatus.initialRightsType"),
          ) === RIGHTS_TYPES[1].value &&
          get(this.state, "navigationStatus.reports")
        );
      }
      return get(this.state, "navigationStatus.reports");
    }
    return false;
  };

  showDistributorReports = () => {
    if (this.getPipelineStatus() >= VERIFICATION_STATUS.reports - 2) {
      if (get(this.state, "navigationStatus.addPublisher")) {
        return (
          (get(this.state, "navigationStatus.initialRightsType") ===
            RIGHTS_TYPES[0].value &&
            get(this.state, "navigationStatus.publisherReport")) ||
          get(this.state, "navigationStatus.initialRightsType") !==
            RIGHTS_TYPES[0].value
        );
      }
      return true;
    }
    return false;
  };

  checkPublishingReportBtn = () => {
    return (
      get(this.state, "navigationStatus.funding") === VERIFIED ||
      get(this.state, "navigationStatus.choosePath") ===
        CHOOSE_PATH_FIELDS().OPTIONS[0].value
    );
  };

  showPublishingReports = () => {
    if (get(this.state, "navigationStatus.addPublisher")) {
      return (
        (get(
          this.state,
          "navigationStatus.rightsType",
          get(this.state, "navigationStatus.initialRightsType"),
        ) === RIGHTS_TYPES[2].value &&
          get(this.state, "navigationStatus.reports")) ||
        (get(this.state, "navigationStatus.initialRightsType") ===
          RIGHTS_TYPES[0].value &&
          this.checkPublishingReportBtn())
      );
    }
    return false;
  };

  renderInitialFunnelMenu = () => {
    const { pathname } = this.props.history.location;
    if (this.state.loading) {
      return this.skeletonLoader();
    } else if (
      get(this.state.navigationStatus, "isFastFlow") &&
      pathname === WAITING_ROOM
    ) {
      return (
        <>
          {this.getStatusLinkCard(
            NAVIGATION_ICONS.STREAMING,
            TUNE_MY_DATA,
            [
              NAV_STATUS.PENDING,
              NAV_STATUS.TOO_BIG,
              NAV_STATUS.TOO_SMALL,
            ].indexOf(this.state.navigationStatus.artistStatus) !== -1,
          )}
        </>
      );
    } else if (get(this.state.navigationStatus, "isFastFlow")) {
      return (
        <>
          <>
            {this.getStatusLinkCard(
              LEFTNAVIGATION_ICONS.MONEY,
              FUNDING,
              this.getPipelineStatus() >= VERIFICATION_STATUS.funding - 2 &&
                this.state.navigationStatus.choosePath !==
                  CHOOSE_PATH_FIELDS().OPTIONS[0].value &&
                [
                  FAST_FLOW_STEPS.INCOME_VERIFIED,
                  FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
                  FAST_FLOW_STEPS.PENDING,
                ].indexOf(this.state.navigationStatus.artistStatus) === -1,
              FAST_FLOW.FUNDING,
            )}
            {invoke(
              [
                this.getStatusLinkCard(
                  NAVIGATION_ICONS.REPORTS,
                  SEND_REPORTS,
                  this.showDistributorReports() ||
                    [
                      FAST_FLOW_STEPS.INCOME_VERIFIED,
                      FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
                      FAST_FLOW_STEPS.PENDING,
                    ].indexOf(this.state.navigationStatus.artistStatus) !==
                      -1 ||
                    (this.state.navigationStatus.choosePath ===
                      CHOOSE_PATH_FIELDS().OPTIONS[0].value &&
                      this.state.navigationStatus.initialRightsType !==
                        RIGHTS_TYPES[0].value),
                ),
                this.getStatusLinkCard(
                  NAVIGATION_ICONS.PUBLISHING_REPORTS,
                  PUBLISHING_REPORTS,
                  this.showPublishingReports() || this.isOutRangePublishing(),
                ),
              ],
              `${
                get(this.state, "navigationStatus.initialRightsType") ===
                RIGHTS_TYPES[0].value
                  ? "reverse"
                  : "concat"
              }`,
            )}
          </>
          {this.getStatusLinkCard(
            LEFTNAVIGATION_ICONS.CONFIRM_OWNERSHIP,
            CONFIRM_OWNERSHIP,
            get(this.state, "navigationStatus.confirmOwnership") &&
              (this.showGeneratingOffer() ||
                (!get(this.state, "navigationStatus.addPublisher") &&
                  get(this.state, "navigationStatus.reports"))),
          )}
          {this.getStatusLinkCard(
            LEFTNAVIGATION_ICONS.GENERATING_OFFERS,
            GENERATING_OFFERS,
            this.showGeneratingOffer() ||
              (!get(this.state, "navigationStatus.addPublisher") &&
                get(this.state, "navigationStatus.reports")),
          )}
        </>
      );
    } else {
      return (
        <>
          <>
            {this.getStatusLinkCard(
              NAVIGATION_ICONS.STREAMING,
              TUNE_MY_DATA,
              [NAV_STATUS.PENDING, NAV_STATUS.IN_RANGE].indexOf(
                this.state.navigationStatus.artistStatus,
              ) !== -1,
            )}
            {this.getStatusLinkCard(
              LEFTNAVIGATION_ICONS.MONEY,
              FUNDING,
              this.getPipelineStatus() >= VERIFICATION_STATUS.funding - 1,
            )}
            {this.getStatusLinkCard(
              NAVIGATION_ICONS.REPORTS,
              SEND_REPORTS,
              this.getPipelineStatus() >= VERIFICATION_STATUS.reports - 1,
            )}
          </>
          {!this.props.isDemo && (
            <>
              {this.getStatusLinkCard(
                NAVIGATION_ICONS.SPOTIFY,
                LINK_SPOTIFY,
                this.state.navigationStatus.reports,
              )}
            </>
          )}
          {this.getStatusLinkCard(
            LEFTNAVIGATION_ICONS.GENERATING_OFFERS,
            GENERATING_OFFERS,
            this.getPipelineStatus() >=
              VERIFICATION_STATUS.generatingOffers - 1 &&
              get(this.state.navigationStatus, "finishingTouch"),
          )}
        </>
      );
    }
  };

  renderOfferApprovedMenu = () => {
    const { pathname } = this.state;
    if (this.state.navigationStatus.isMarketPlace) {
      return this.renderPostToInvestorMenu();
    } else {
      return (
        <>
          {!this.state.navigationStatus.isAccepted && (
            <>
              <Link
                to={WELCOME_BACK}
                className={`${styles.menuItem} ${
                  pathname === WELCOME_BACK && styles.active
                }`}
              >
                <div className={styles.options}>
                  <LEFTNAVIGATION_ICONS.WELCOME />
                  <p>{NAVIGATION_TEXTS[WELCOME_BACK].title}</p>
                </div>
              </Link>
              {this.state.navigationStatus.offerSummary && (
                <Link
                  to={OFFER_SUMMARY}
                  className={`${styles.menuItem} ${
                    [OFFER_SUMMARY, CONFIRMED_OFFERS_V2].indexOf(pathname) !==
                      -1 && styles.active
                  }`}
                >
                  <div className={styles.options}>
                    <LEFTNAVIGATION_ICONS.MONEY />
                    <p>{NAVIGATION_TEXTS[OFFER_SUMMARY].title}</p>
                  </div>
                </Link>
              )}
            </>
          )}

          {this.state.navigationStatus.adjustTerms && (
            <Link
              to={ACCEPT_OFFER}
              className={`${styles.menuItem} ${
                pathname === ACCEPT_OFFER && styles.active
              }`}
            >
              <div className={styles.options}>
                <NAVIGATION_ICONS.PROFILE />
                <p>{NAVIGATION_TEXTS[ACCEPT_OFFER].title}</p>
              </div>
            </Link>
          )}
        </>
      );
    }
  };

  getLinkOptionsForPost = () => {
    const options = [];

    options.push({
      label: "Get Even More",
      value: GET_EVEN_MORE,
    });

    this.getStatusNavigation(this.state.navigationStatus.getEvenMore) &&
      options.push({
        label: "Set Target Advance",
        value: SET_TARGET_ADVANCE,
      });

    this.getStatusNavigation(this.state.navigationStatus.targetOffer) &&
      options.push({
        label: "Artist Profile",
        value: UPCOMING_WORKS,
      });
    this.getStatusNavigation(this.state.navigationStatus.artistProfile) &&
      options.push({
        label: "Invite Bidders",
        value: INVITE_BIDDERS,
      });

    this.getStatusNavigation(this.state.navigationStatus.inviteBidder) &&
      options.push({
        label: "Confirmation",
        value: POST_CONFIRMATION,
      });

    return options;
  };

  getLinkOptionsForPostToNetWork = () => {
    let options = [];
    if (this.state.navigationStatus.postConfirmation) {
      this.state.navigationStatus.artistRegistration
        ? options.push({
            label: "Posted To Network",
            value: POSTED_TO_NETWORK,
          })
        : options.push({
            label: "Artist Registration",
            value: ARTIST_REGISTRATION,
          });
    } else {
      options = this.getLinkOptionsForPost();
    }

    return options;
  };

  getLinkOptionsForApproved = () => {
    const options = [];
    if (!this.state.navigationStatus.isAccepted) {
      options.push({
        label: NAVIGATION_TEXTS[WELCOME_BACK].title,
        value: WELCOME_BACK,
      });
      get(this.state.navigationStatus, "offerSummary") &&
        options.push({
          label: NAVIGATION_TEXTS[OFFER_SUMMARY].title,
          value: OFFER_SUMMARY,
        });
    }
    this.state.navigationStatus.adjustTerms &&
      options.push({
        label: NAVIGATION_TEXTS[ACCEPT_OFFER].title,
        value: ACCEPT_OFFER,
      });

    return options;
  };

  conditionCheckFunc = () => {
    if (
      !get(this.state, "selectedArtist.offer_approved", false) &&
      !this.props.isDemo &&
      !(
        window.location.pathname.includes(FUNDING) ||
        window.location.pathname.includes(TUNE_MY_DATA)
      )
    ) {
      return true;
    }
    return false;
  };

  handleCloseModal = () => {
    this.setState({ estimateModalOpen: false });
  };

  getMinEstimation = () =>
    get(this.state, "navigationStatus.switchDistributorForFunding")
      ? get(this.state, "selectedArtist.estimatedOffer.offer.min") +
        get(this.state, "selectedArtist.estimatedOffer.offer.min") * SWITCH_MIN
      : get(this.state, "selectedArtist.estimatedOffer.offer.min");
  getMaxEstimation = () =>
    get(this.state, "navigationStatus.switchDistributorForFunding")
      ? get(this.state, "selectedArtist.estimatedOffer.offer.max") +
        get(this.state, "selectedArtist.estimatedOffer.offer.max") * SWITCH_MIN
      : get(this.state, "selectedArtist.estimatedOffer.offer.max");

  renderEstimateModal = () => (
    <>
      <div className={styles.estimateContainerText}>
        {(get(
          this.state,
          "selectedArtist.offerStage.defaultOffer.totalAdvance",
          false,
        ) ||
          get(
            this.state,
            "selectedArtist.estimatedOffer.offer.min",
            false,
          )) && (
          <>
            <div className={styles.fundingEstimatesText}>
              Current Estimate
              <span
                onClick={() => {
                  this.setState({ estimateModalOpen: true });
                }}
                data-testid="estimate-modal"
              >
                <HelpIcon />
              </span>
            </div>
            <div className={styles.fundingEstimatedValue}>
              $
              {get(this.state, "selectedArtist.isFastFlow") ? (
                <>
                  {numberWithCommas(this.getMinEstimation())} -{" "}
                  {numberWithCommas(this.getMaxEstimation())}
                </>
              ) : (
                numberWithCommas(
                  get(
                    this.state,
                    "selectedArtist.offerStage.defaultOffer.totalAdvance",
                    "",
                  ),
                )
              )}
            </div>
          </>
        )}
      </div>
    </>
  );

  isOutRangePublishing = () =>
    get(this.state, "navigationStatus.initialRightsType") ===
      RIGHTS_TYPES[0].value &&
    [
      FAST_FLOW_STEPS.INCOME_VERIFIED,
      FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
      FAST_FLOW_STEPS.PENDING,
    ].indexOf(this.state.navigationStatus.artistStatus) !== -1;

  getLinkOptions = () => {
    let options = [];
    const { pathname } = this.props.history.location;
    if (this.state.navigationStatus.offer_approved) {
      this.state.navigationStatus.isMarketPlace
        ? (options = this.getLinkOptionsForPostToNetWork())
        : (options = this.getLinkOptionsForApproved());
    } else {
      if (get(this.state.navigationStatus, "isFastFlow")) {
        options.push(
          {
            options: [],
          },
          !this.props.isDemo && {
            options: [],
          },
        );
        if (pathname === WAITING_ROOM) {
          options[0].options.push({
            label: FAST_FLOW.WAITING,
            value: WAITING_ROOM,
          });
          return options;
        }
        if (
          this.getPipelineStatus() >= VERIFICATION_STATUS.funding - 2 &&
          this.state.navigationStatus.choosePath !==
            CHOOSE_PATH_FIELDS().OPTIONS[0].value &&
          [
            FAST_FLOW_STEPS.INCOME_VERIFIED,
            FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
            FAST_FLOW_STEPS.PENDING,
          ].indexOf(this.state.navigationStatus.artistStatus) === -1
        ) {
          options[0].options.push({
            label: FAST_FLOW.FUNDING,
            value: FUNDING,
          });
        }
        if (
          this.showDistributorReports() ||
          [
            FAST_FLOW_STEPS.INCOME_VERIFIED,
            FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
            FAST_FLOW_STEPS.PENDING,
          ].indexOf(this.state.navigationStatus.artistStatus) !== -1 ||
          (this.state.navigationStatus.choosePath ===
            CHOOSE_PATH_FIELDS().OPTIONS[0].value &&
            this.state.navigationStatus.initialRightsType !==
              RIGHTS_TYPES[0].value)
        ) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[SEND_REPORTS].title,
            value: SEND_REPORTS,
          });
        }
        if (this.showPublishingReports() || this.isOutRangePublishing()) {
          if (
            get(this.state, "navigationStatus.initialRightsType") ===
            RIGHTS_TYPES[0].value
          ) {
            if (this.showDistributorReports()) {
              options[0].options.pop();
            }
            options[0].options.push({
              label: NAVIGATION_TEXTS[PUBLISHING_REPORTS].title,
              value: PUBLISHING_REPORTS,
            });
            if (this.showDistributorReports()) {
              options[0].options.push({
                label: NAVIGATION_TEXTS[SEND_REPORTS].title,
                value: SEND_REPORTS,
              });
            }
          } else {
            options[0].options.push({
              label: NAVIGATION_TEXTS[PUBLISHING_REPORTS].title,
              value: PUBLISHING_REPORTS,
            });
          }
        }
        if (
          get(this.state, "navigationStatus.confirmOwnership") &&
          (this.showGeneratingOffer() ||
            (!get(this.state, "navigationStatus.addPublisher") &&
              get(this.state, "navigationStatus.reports")))
        ) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[CONFIRM_OWNERSHIP].title,
            value: CONFIRM_OWNERSHIP,
          });
        }
        if (
          this.showGeneratingOffer() ||
          (!get(this.state, "navigationStatus.addPublisher") &&
            get(this.state, "navigationStatus.reports"))
        ) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[GENERATING_OFFERS].title,
            value: GENERATING_OFFERS,
          });
        }
      } else {
        options.push(
          {
            options: [
              {
                label:
                  this.state.navigationStatus.artistStatus ===
                  NAV_STATUS.IN_RANGE
                    ? NAVIGATION_TEXTS[TUNE_MY_DATA].title
                    : FAST_FLOW.WAITING,
                value:
                  this.state.navigationStatus.artistStatus ===
                  NAV_STATUS.IN_RANGE
                    ? TUNE_MY_DATA
                    : WAITING_ROOM,
              },
            ],
          },
          !this.props.isDemo && {
            options: [],
          },
        );
        if (this.getPipelineStatus() >= VERIFICATION_STATUS.funding - 1) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[FUNDING].title,
            value: FUNDING,
          });
        }
        if (this.getPipelineStatus() >= VERIFICATION_STATUS.reports - 1) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[SEND_REPORTS].title,
            value: SEND_REPORTS,
          });
        }
        if (this.state.navigationStatus.reports) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[LINK_SPOTIFY].title,
            value: LINK_SPOTIFY,
          });
        }
        if (this.state.navigationStatus.finishingTouch) {
          options[0].options.push({
            label: NAVIGATION_TEXTS[GENERATING_OFFERS].title,
            value: GENERATING_OFFERS,
          });
        }
      }
    }

    if (
      !window.location.pathname.includes(ADMIN) &&
      this.conditionCheckFunc()
    ) {
      get(options[0], "options", false)
        ? options[0].options.push({
            label: this.renderEstimateModal(),
            value: "OPEN_MODAL",
          })
        : options.push({
            options: [
              {
                label: this.renderEstimateModal(),
                value: "OPEN_MODAL",
              },
            ],
          });
    }

    return options;
  };

  skeletonLoader = () => (
    <>
      <Skeleton
        variant="text"
        className={`${styles.skeletonStyle} ${styles.txt}`}
      />
      <Skeleton
        variant="text"
        className={`${styles.skeletonStyle} ${styles.txt}`}
      />
      <Skeleton
        variant="text"
        className={`${styles.skeletonStyle} ${styles.txt}`}
      />
      <Skeleton
        variant="text"
        className={`${styles.skeletonStyle} ${styles.txt}`}
      />
    </>
  );

  handleNavigateScreen = (option) => {
    if (option.value !== "OPEN_MODAL") {
      this.props.history.push(option.value);
    }
  };

  handleImageClick = () => {
    const urlArr = get(this.context, "whitelistUrl", []);
    const URL = urlArr[0];
    if (typeof URL !== "undefined") {
      window.location.href = URL;
    }
  };

  handleContactPopup = () => {
    this.setState({ isOpen: true });
  };

  handleLogout = (event) => {
    this.setState({ isDropOpen: event.currentTarget });
    invoke(
      document.getElementById("demo-positioned-button"),
      "classList.add",
      styles["activeSettings"],
    );
  };

  handleDropMenuClose = () => {
    this.setState({
      isDropOpen: null,
    });
    invoke(
      document.getElementById("demo-positioned-button"),
      "classList.remove",
      styles["activeSettings"],
    );
  };

  handleCloseOffer = () => {
    this.setState({ isOpen: false, isOfferReady: false });
  };

  toggleDrawer = (open) => () => {
    if (ARTIST_OUT_RANGE_STATUS_LIST.indexOf(this.state.fastFlowStep) !== -1) {
      this.setState({
        isDrawerOpen: open,
      });
    }
  };

  handleFastFlowStep = (step) => {
    this.setState({ fastFlowStep: step });
  };

  handleDrawer = (val) => {
    this.setState({ drawerComponent: val, isDrawerOpen: true });
  };

  checkActivePath = (value) => {
    return window.location.pathname.includes(value);
  };

  artistLengthFn = () =>
    this.state.artists.length ? "Select an artist" : "No artist found";

  storeCustomTokenInJwt = () => {
    AuthTokenService.storeToken(StorageService.get("customToken"));
  };

  logOutMenu = () => {
    if (window.location.pathname.includes(WAITING_ROOM)) {
      logoutFromWaitingRoom.call(this, true);
    } else {
      logout();
    }
  };

  renderImage = (imageUrl) => {
    return (
      <div className={styles.profile}>
        {this.props.profileImg || imageUrl ? (
          <Image
            src={this.props.profileImg || imageUrl}
            alt="artist_profile"
            imageType={IMAGE_TYPE.PROFILE}
          />
        ) : (
          <SvgIcons icon={PROFILE_ICON} />
        )}
      </div>
    );
  };

  renderMenuForSubAdmin = () =>
    this.state.role === "subAdmin" ? (
      <>
        <MenuItem
          data-testid="logOutMenuItem1"
          onClick={() => this.logOutMenu()}
        >
          <li className={styles.menuText}>Logout</li>
        </MenuItem>
      </>
    ) : (
      <>
        {StorageService.get("customToken") && (
          <MenuItem>
            <Link
              to={ADMIN_DASHBOARD}
              className={`${
                this.checkActivePath(ADMIN_DASHBOARD) && styles.primary
              } ${styles.menuText}`}
              onClick={this.storeCustomTokenInJwt}
            >
              Admin
            </Link>
          </MenuItem>
        )}
        {!window.location.pathname.includes(ADMIN) && (
          <MenuItem
            onClick={() => this.handleDrawer(DASHBOARD)}
            data-testid="menuItem"
            className={styles.mobileMenuItem}
          >
            <li className={styles.menuText}>Artist Hub</li>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => this.handleDrawer(MY_ACCOUNT)}
          data-testid="menuItem"
        >
          <li
            className={`${this.checkActivePath(MY_ACCOUNT) && styles.primary} ${
              styles.menuText
            }`}
          >
            My Account
          </li>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleDrawer(EMAIL_PREFERENCES)}
          data-testid="menuItem"
        >
          <li
            className={`${
              this.checkActivePath(EMAIL_PREFERENCES) && styles.primary
            } ${styles.menuText}`}
          >
            Email Preferences
          </li>
        </MenuItem>

        <MenuItem onClick={this.handleContactPopup}>
          <li className={styles.menuText}>Contact Us</li>
        </MenuItem>
        <MenuItem
          data-testid="logOutMenuItem"
          onClick={() => this.logOutMenu()}
        >
          <li className={styles.menuText}>Logout</li>
        </MenuItem>
      </>
    );

  renderMenuItemArtist = ({ children, ...otherProps }) => (
    <div {...otherProps}>
      <div className={styles.dropBtnContainer}>
        <div
          onClick={() => this.handleDrawer(DASHBOARD)}
          data-testid="div-dash"
          className={styles.goToArtistBtn}
        >
          <ArtistSvg className={styles.artistSvg} />
          <span>Go to Artist Hub</span>
        </div>
      </div>
      {this.state.artists.length ? children : null}
    </div>
  );

  formatArtistDropDown = ({ name, profilePicture }) => {
    return (
      <div className={`${styles.selectOption}`}>
        <div className={`d-flex align-items-center`}>
          {this.renderImage(get(profilePicture, "0.url", ""))}
          <div
            className={`d-flex flex-column justify-content-center ${styles.labelContainer}`}
          >
            <p className={styles.dropLabel} style={{ fontSize: 12 }}>
              {name}
            </p>
          </div>
        </div>
      </div>
    );
  };

  formatOptionLabel = ({ label, imageUrl, email }) => {
    return (
      <div className={`${styles.selectOption}`}>
        {imageUrl !== "Total Users" ? (
          <div className={`d-flex align-items-center`}>
            {this.renderImage(imageUrl)}
            <div className={`d-flex flex-column justify-content-center`}>
              <p>{label}</p>
              {!label.includes("@") && (
                <p className={styles.optional}>{email.value}</p>
              )}
            </div>
          </div>
        ) : (
          <div className={`d-flex align-items-center`}>
            <p className={styles.searchUsers}>{label}</p>
          </div>
        )}
      </div>
    );
  };

  setArtistToken = (artistId) => {
    this.setState({ artistLoader: true });
    const payload = { id: artistId };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVATE_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ artistLoader: false });
        if (res.status) {
          AuthTokenService.storeToken(res.data.accessToken);
          this.props.history.push(HOME);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ artistLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  overLayComponent = () => {
    switch (this.state.drawerComponent) {
      case MY_ACCOUNT:
        return (
          <MyAccount
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            {...this.props}
          />
        );
      case EMAIL_PREFERENCES:
        return (
          <EmailPreferences toggleDrawer={this.toggleDrawer} {...this.props} />
        );

      case DASHBOARD:
        return (
          <Dashboard
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            addArtist={fetchAccountImg.bind(this)}
            {...this.props}
          />
        );
      case LINK_ANOTHER_ARTIST:
        return (
          <LinkAnohterArtist
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            handleFastFlowStep={this.handleFastFlowStep}
            {...this.props}
          />
        );

      default:
        break;
    }
  };

  eventPopupRender = () => (
    <div className={styles.eventPopup}>
      <p className={styles.mainText}>
        Your data is <span>ready!</span>
      </p>
      <button
        className={styles.primaryBtn}
        onClick={() => this.props.history.push(HOME)}
      >
        {"Let's go"}
      </button>
    </div>
  );

  getFunnel = () => {
    if (this.state.navigationStatus.offer_approved) {
      return this.renderOfferApprovedMenu();
    } else {
      return this.renderInitialFunnelMenu();
    }
  };

  handleReturnFunc = () => {
    this.setState({ estimateModalOpen: false });
    this.props.history.push(FUNDING);
  };

  getMobileSelectMenu = () => {
    if (
      this.state.pathname === FUNDING &&
      get(this.state.navigationStatus, "isFastFlow")
    ) {
      return FAST_FLOW.FUNDING;
    } else if (this.state.pathname === WAITING_ROOM) {
      return FAST_FLOW.WAITING;
    } else {
      return get(NAVIGATION_TEXTS[this.state.pathname], "title", "");
    }
  };

  render() {
    const reactSelectComponents = {
      IndicatorSeparator: () => null,
      NoOptionsMessage: () => null,
      MenuList: this.renderMenuItemArtist,
    };
    const profileClassName = window.location.pathname.includes(ADMIN)
      ? styles.profileContainer
      : styles.userProfileContainer;
    return (
      <>
        <div className={`${styles.container} ${this.props.className}`}>
          <div className={styles.sideBarContainer}>
            <div
              onClick={this.handleImageClick}
              className={styles.logoContainer}
              data-testid="logoClick"
            >
              <Image
                src={get(this.context, "appLogo", "")}
                className={styles.logo}
                alt="Logo"
              />
            </div>
            <div
              className={`${styles.menuContainer} ${styles.approvedMenu} ${
                this.state.loading && styles.skeletonContainer
              }`}
            >
              {this.getFunnel()}
            </div>
            <div className={styles.mobileMenuContainer}>
              <ReactSelect
                options={this.getLinkOptions()}
                classNamePrefix="navigation"
                onChange={this.handleNavigateScreen}
                isSearchable={false}
                value={{
                  label: this.getMobileSelectMenu(),
                  value: this.state.pathname,
                }}
                components={{ IndicatorSeparator: null }}
                styles={mobileNavigationStyle}
              />
            </div>
          </div>
          {!this.state.loading && (
            <div className={styles.rightNav}>
              {!window.location.pathname.includes(ADMIN) &&
                !this.props.isDemo && (
                  <>
                    <div className={styles.rightContainer}>
                      <div
                        className={styles.text}
                        onClick={this.handleContactPopup}
                      >
                        <div className={styles.leftSideMenu}>
                          <div className={styles.svgLeft}>
                            <NeedHelpIcon />
                          </div>
                          <p>Need Help?</p>
                        </div>
                      </div>
                      <div
                        className={profileClassName}
                        id="settings-button-container"
                      >
                        {!window.location.pathname.includes(LOGGED_OUT) && (
                          <div
                            className={`${styles.settingIconContainer}`}
                            data-testid="setting_icon"
                            id="demo-positioned-button"
                            aria-haspopup="true"
                            onClick={this.handleLogout}
                          >
                            <div className={styles.svgLeft}>
                              <SettingIcon />
                            </div>
                            <p>Settings</p>
                          </div>
                        )}
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={this.state.isDropOpen}
                          open={this.state.isDropOpen}
                          onClose={this.handleDropMenuClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          className={styles.settingMenu}
                          onClick={this.handleDropMenuClose}
                        >
                          {this.renderMenuForSubAdmin()}
                        </Menu>
                      </div>
                    </div>
                  </>
                )}
              {!window.location.pathname.includes(ADMIN) &&
                !this.props.isDemo && (
                  <div className={styles.artistDropDown}>
                    {!window.location.pathname.includes(LOGGED_OUT) ? (
                      <div className={styles.artistSelectDropDown}>
                        <ReactSelect
                          maxMenuHeight={176}
                          isSearchable={false}
                          id="artistSelect"
                          data-test="artistSelect"
                          className={`${styles.selectArtistDrop}`}
                          classNamePrefix="artist-select"
                          options={this.state.artists}
                          getOptionValue={(option) => option._id}
                          value={this.state.selectedArtist}
                          onChange={(values) => {
                            this.setState({ selectedArtist: values }, () => {
                              setDataDogUser(
                                values._id,
                                values.name,
                                this.state.userEmail,
                              );
                              this.setArtistToken(values._id);
                              segment.storage.update(values._id);
                            });
                          }}
                          getOptionLabel={this.formatArtistDropDown}
                          formatOptionLabel={this.formatArtistDropDown}
                          name="artistSelect"
                          components={reactSelectComponents}
                          styles={artistDropDownStyle}
                          menuPlacement="top"
                          placeholder={this.artistLengthFn()}
                        />
                      </div>
                    ) : (
                      <Link to={LOGIN} className={styles.loginBtn}>
                        Login
                      </Link>
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
        {window.location.pathname.includes("/marketplace") ? (
          <NeedHelpPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleCloseOffer}
          />
        ) : (
          <ContactPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleCloseOffer}
          />
        )}
        <OverLay
          anchor={"left"}
          component={this.overLayComponent()}
          open={this.state.isDrawerOpen}
          onClose={this.toggleDrawer(false)}
          className={
            this.state.drawerComponent === DASHBOARD ? styles.dashboard : null
          }
        />
        {this.state.artistLoader && <Loader />}
        {this.state.isOfferReady && (
          <ShortCallLoader
            defaultLoader
            loaderTitle=" "
            text={this.eventPopupRender()}
          />
        )}
        <RenderModal
          isModalOpen={this.state.estimateModalOpen}
          handleCloseModal={this.handleCloseModal}
          selectedArtist={this.state.selectedArtist}
          handleReturnFunc={this.handleReturnFunc}
        />
      </>
    );
  }
}

HorizontalMenu.contextType = ThemeContext;
export default HorizontalMenu;

HorizontalMenu.contextTypes = {
  whitelistUrl: PropTypes.array,
};
