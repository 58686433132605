export const FINAL_CHECKOUT_MESSAGE = {
  PARTNER_ADVANCES: "partner_advances",
  MOVE_DISTRIBUTORS: "move_distributors",
  TAKE_CONTROL_OF_ACCOUNT: "take_control_of_account",
  SECURE_INCOME: "secure_income",
  MUST_MOVE_PARTNER_ADVANCE: "must_move_partner_advance",
};

export const APPROVAL_MESSAGE_WITH_YOUTUBE_LINK = {
  PARTNER_ADVANCES: {
    BB: "https://www.youtube.com/watch?v=BmC5T6bodYU",
    CC: "https://www.youtube.com/watch?v=d_XAyBO3T7M"
  },
  DEFAULT_LINK: {
    BB: "https://www.youtube.com/watch?v=1mNWQThY8gk",
    CC: "https://www.youtube.com/watch?v=UA2YMaUo1H0"
  }
};

