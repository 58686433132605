import { GENERAL_FIELD_VALIDATION_MESSAGE } from "../AdminDashboard/WhiteLabel/constants";

export const ARTIST_COLUMN = [
  { id: "artist_name", label: "Artist Name", align: "left" },
  { id: "artist_details", label: "Genre / ID", align: "center" },
  { id: "artist_action", label: "Action", align: "right" },
];

export const FIELD_NAMES = {
  DEMO_ARTIST_NAME: {
    NAME: "artistName",
    LABEL: "Artist name",
    PLACEHOLDER: "Enter Artist Name",
  },
  PROFILE_SUMMARY: {
    NAME: "profileSummary",
    LABEL: "Add Profile Summary",
    PLACEHOLDER: "Enter profile summery here...",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Profile Summary and Guide Details",
  },
  MUSIC_GENRE: {
    NAME: "musicGenre",
    LABEL: "Music Genre",
    PLACEHOLDER: "Enter Music Genre",
  },
  OFFER_DATASET: {
    NAME: "offerDataset",
    LABEL: "Offer Dataset",
    PLACEHOLDER: "Select dataset",
  },
  PROFILE_GUIDE: {
    NAME: "profileGuide",
    LABEL: "Add Profile Guide",
    PLACEHOLDER: "Enter profile guide here...",
    LABEL_TO_DISPLAY: "Add Profile Guide",
  },
  FUNNEL_GUIDE_VERIFICATION_1: {
    NAME: "funnelGuideVerification1",
    LABEL: "Add Guide for Data Review Screen",
    PLACEHOLDER: "Enter guide for data review screen",
    LABEL_TO_DISPLAY: "Add Guide for Data Review Screen",
    EXTRA_TEXT: "Artist Funnel Guide Information",
  },
  FUNNEL_GUIDE_VERIFICATION_2: {
    NAME: "funnelGuideVerification2",
    LABEL: "Add Guide for Data Verification Screen",
    PLACEHOLDER: "Enter  guide for data verification screen",
    LABEL_TO_DISPLAY: "Add Guide for Data Verification Screen",
  },
  FUNNEL_GUIDE_VERIFICATION_3: {
    NAME: "funnelGuideIncomeVerification",
    LABEL: "Add Guide for Income Verification Screen",
    PLACEHOLDER: "Enter guide for income verification screen",
    LABEL_TO_DISPLAY: "Add Guide for Income Verification Screen",
  },
  FUNNEL_GUIDE_CUSTOMIZATION: {
    NAME: "funnelGuideCustomization",
    LABEL: "Add Guide for Customize Funding",
    PLACEHOLDER: "Enter  guide for customize funding",
    LABEL_TO_DISPLAY: "Add Guide for Customize Funding",
  },
  FUNNEL_GUIDE_SEND_REPORTS: {
    NAME: "funnelGuideSendReports",
    LABEL: "Add Guide for Reports Screen",
    PLACEHOLDER: "Enter  guide for reports",
    LABEL_TO_DISPLAY: "Add Guide for Reports Screen",
  },
  ADMIN_PASSWORD: {
    NAME: "adminPassword",
    LABEL: "Password",
    PLACEHOLDER: "Password",
  },
};

export const FIELD_INITIAL_VALUES = {
  [FIELD_NAMES.DEMO_ARTIST_NAME.NAME]: "",
  [FIELD_NAMES.PROFILE_SUMMARY.NAME]: "",
  [FIELD_NAMES.MUSIC_GENRE.NAME]: "",
  [FIELD_NAMES.PROFILE_GUIDE.NAME]: "",
  [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_2.NAME]: "",
  [FIELD_NAMES.FUNNEL_GUIDE_VERIFICATION_3.NAME]: "",
  [FIELD_NAMES.FUNNEL_GUIDE_CUSTOMIZATION.NAME]: "",
  [FIELD_NAMES.FUNNEL_GUIDE_SEND_REPORTS.NAME]: "",
  [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
};

export const FIELD_VALIDATION_MESSAGES = {
  [FIELD_NAMES.DEMO_ARTIST_NAME
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DEMO_ARTIST_NAME.LABEL}`,
  [FIELD_NAMES.PROFILE_SUMMARY
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.PROFILE_SUMMARY.LABEL}`,
  [FIELD_NAMES.MUSIC_GENRE
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.MUSIC_GENRE.LABEL}`,
  [FIELD_NAMES.PROFILE_GUIDE
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.PROFILE_GUIDE.LABEL}`,
  [FIELD_NAMES.ADMIN_PASSWORD
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.ADMIN_PASSWORD.LABEL}`,
};
