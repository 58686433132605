export const USERS_TABLE_COLUMNS = [
  {
    name: "",
    key: 1,
    props: {
      width: "5%",
    },
  },
  {
    name: "",
    key: 2,
  },
  {
    name: "Username/Email",
    key: 3,
  },
  {
    name: "In Range Artists",
    key: 4,
  },
  {
    name: "IP/Location",
    key: 5,
  },
  {
    name: "Created Date/Last Login",
    key: 6,
  },
  {
    name: "Actions",
    key: 7,
  },
];

export const ARTISTS_RANGE = {
  IN_RANGE: "in_range",
  SMALL: "small",
  NEW: "new",
  PENDING: "pending",
  FAILED: "failed",
  BIG: "big",
  TOO_BIG: "too_big",
  TOO_SMALL: "too_small",
};

export const ARTISTS_TABLE_COLUMN = [
  {
    name: "",
    key: "profileIcon",
  },
  {
    name: "Artist Name",
    key: "artistName",
  },
  {
    name: "ChartMetric ID",
    key: "chartMetricId",
  },
  {
    name: "Range",
    key: "range",
  },
  {
    name: "Pipeline",
    key: "pipeline",
  },
  {
    name: "Added Date/Last Update",
    key: "lastUpdate",
  },
  {
    name: "Actions",
    key: "actions",
  },
];

export const PENDING_ARTISTS_TABLE_COLUMN = [
  {
    name: "",
    key: "profileIcon",
  },
  {
    name: "Artist Name",
    key: "artistName",
  },
  {
    name: "ChartMetric ID",
    key: "chartMetricId",
  },
  {
    name: "Range",
    key: "range",
  },
  {
    name: "Added Date",
    key: "addedDate",
  },
  {
    name: "Re-Processing Count/Date",
    key: "offerInfo",
  },
  {
    name: "Actions",
    key: "actions",
  },
];
export const PIPELINE_STAGES_LABELS = [
  "Verify Funding",
  "Customize Funds",
  "Report Sent",
  "Profile",
];
export const PIPELINE_STAGES = {
  streamingData: {
    label: PIPELINE_STAGES_LABELS[0],
    stagePendingStatus: ["unverified"],
    stageCompletedStatus: ["verified", "updated"],
  },
  funding: {
    label: PIPELINE_STAGES_LABELS[1],
    stagePendingStatus: ["unverified"],
    stageCompletedStatus: ["verified", "updated"],
  },
  reports: {
    label: PIPELINE_STAGES_LABELS[2],
    stagePendingStatus: [false],
    stageCompletedStatus: [true],
  },
  publicProfile: {
    label: PIPELINE_STAGES_LABELS[3],
    stagePendingStatus: [false],
    stageCompletedStatus: [true],
  },
};

export const MAX_REPROCESS = 5;

export const FILTER_STATUS_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Offer Funnel", value: "offerFunnel" },
  { label: "Approval Pending", value: "approvalPending" },
  { label: "Approved", value: "approved" },
  { label: "Offer Accepted", value: "offerAccepted" },
];
