import React, { Component, createRef } from "react";
import ReactModal from "react-modal";
import _, { get, debounce } from "lodash";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import {
  IMG_EXTENSIONS_REGEX,
  FIELD_INITIAL_VALUES,
  IMG_MAX_SIZE,
  IMG_NOT_AVAILABLE_CASES,
  IMG_RATIO_LIMIT,
  validationSchema,
  checkForAdditionalFields,
  setFieldsMapping,
  setTitle,
  renderReportAdditionalFields,
  renderFinePrintText,
  renderSidebarText,
  renderSvgAssets,
  renderThankYouCta,
  renderAckScreenCta,
  FAVICON_EXTENSIONS_REGEX,
  FAVICON_MAX_SIZE,
  FAVICON_RATIO_LIMIT,
  renderOfferSummaryText,
  renderContractUrl,
  renderLandingPageURL,
  renderResetPasswordModal,
  renderColorField,
  renderColorSubFields,
  addNewColor,
  removeColor,
  getDeletedColor,
  getReplacedColor,
  getTouchedValue,
  checkForObjectValue,
  objToArray,
  arrayToObj,
  checkForObjectValueforRemove,
} from "./helper";
import whiteLabelStyles from "./WhiteLabel.module.scss";
import ImageUpload from "./ImageUpload";
import LeftPanel from "../LeftPanel";
import Switch from "../Switch";
import styles from "../AdminDashboard.module.scss";
import { GetErrorMessage } from "../../helper";
import {
  API_URL,
  ADMIN_WHITELABEL,
  ADMIN_WHITELABEL_EDIT_ACCOUNT,
  ADMIN_WHITELABEL_NEW_ACCOUNT,
  INSTANCE,
  ADMIN_WHITELABEL_API,
} from "../../constants";
import generalColors from "../../../../styles/global.scss";
import LoginHeader from "../../../../component/LoginHeader";
import Loader from "../../../../component/Loader";
import FormField from "../../../../component/FormField/FormField";
import request from "../../../../utils/request";
import {
  FIELD_NAMES,
  MAPPING_FIELDS,
  ASSETS_IDS,
  COLOR_CODES,
} from "./constants";
import { DEFAULT_BB_THEME } from "../../../../component/ThemeManager/constants";

const useDefaultToken = false;
class WhiteLabelAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: FIELD_INITIAL_VALUES,
      //image
      appLogo: { src: "", fileName: "" },
      emailLogo: { src: "", fileName: "" },
      favicon: { src: "", fileName: "" },
      appLogoSizeError: false,
      emailLogoSizeError: false,
      appLogoTypeError: false,
      emailLogoTypeError: false,
      appLogoDimensionError: false,
      emailLogoDimensionError: false,
      faviconSizeError: false,
      faviconTypeError: false,
      faviconDimensionError: false,
      emailIconColor: "",
      emailBoxColor: "",
      // is distributor
      [FIELD_NAMES.IS_DISTRIBUTOR.NAME]: "No",
      [FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME]: "No",
      isDistributorError: false,
      //edit
      isEdit: false,
      passwordBox: false,
      removeColorBox: false,
      deleteIndex: null,
      isPublishingBtnDisable: false,
      [FIELD_NAMES.IS_PUBLISHING.NAME]: false,
    };
    this.emailAssets = { ...ASSETS_IDS };
    this.assetsColorFieldChanges = debounce(this.assetsColorFieldChange, 700);
    this.editorRef = createRef(null);
    this.editorSidebarRef = createRef(null);
    this.resetPasswordRef = createRef(null);
  }

  /**
   * setValuesFromAPI
   * @param {object} res
   * @returns values populated from api
   */
  setValuesFromAPI = (res) => {
    const {
      data,
      data: {
        thankYouCTA,
        acknowledgementCTA,
        getContractUrl,
        emailStyle,
        appStyle,
        progressStatusStyle,
        sidebarStyle,
        alertStyle,
        contentStyle,
        reportFields,
        spotifyStyle,
        loginStyle,
      },
    } = res;
    this.setState ({ isPublishingBtnDisable: data.isPublishing });
    this.setState({
      [FIELD_NAMES.IS_DISTRIBUTOR.NAME]:
        reportFields[FIELD_NAMES.IS_DISTRIBUTOR.NAME] || "No",
      [FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME]:
        reportFields[FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME] || "Yes",
      [FIELD_NAMES.IS_PUBLISHING.NAME]: data.isPublishing || false,
    });
    return {
      initialValues: {
        [FIELD_NAMES.DISPLAY_NAME.NAME]: data.displayName,
        [FIELD_NAMES.SLUG_NAME.NAME]: data.slugName,
        [FIELD_NAMES.CTA_DEFAULT_VALUE.NAME]: thankYouCTA.popupText,
        [FIELD_NAMES.CTA_BUTTON_TEXT.NAME]: thankYouCTA.buttonText,
        [FIELD_NAMES.CTA_ACTION_URL.NAME]: thankYouCTA.actionUrl,
        [FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME]: acknowledgementCTA.buttonText,
        [FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME]: acknowledgementCTA.actionUrl,
        [FIELD_NAMES.LANDING_PAGE_URL.NAME]: data.frontendDomain,
        [FIELD_NAMES.WHITE_LISTED_URLS.NAME]:
          data[FIELD_NAMES.WHITE_LISTED_URLS.NAME] || [],
        [FIELD_NAMES.FINE_PRINT_TEXT.NAME]: data.finePrintText,
        [FIELD_NAMES.SIDEBAR_TEXT.NAME]: data.sidebarText,
        [FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME]: data.offerSummaryText,
        [FIELD_NAMES.GET_CONTRACT_US_URL.NAME]:
          getContractUrl.nonInternationalUrl,
        [FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME]:
          getContractUrl.internationalUrl,
        [FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME]: data.isResetPassword,
        [FIELD_NAMES.RESET_PASSWORD_MODAL.NAME]: data.resetPassword,
        [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
        [FIELD_NAMES.COLOR_SETS.NAME]: objToArray(data.colorSet) || [],
        ...setFieldsMapping(MAPPING_FIELDS.API_VALUES, data),
        colorCodes: {
          general: {
            primaryColor: data.primaryColor,
            ctaFontColor: data.ctaFontColor,
          },
          email: {
            headerFooterColor: emailStyle.headerFooterColor,
            ctaColor: emailStyle.ctaColor,
            fontColorOne: emailStyle.fontColor1,
            fontColorTwo: emailStyle.fontColor2,
            boxColor: emailStyle.boxColor,
            iconColor: emailStyle.iconColor,
          },
          register: {
            mainBackgroundColor: appStyle.primaryBackgroundColor,
            headerBackgroundColor: appStyle.headerBackgroundColor,
            headerFontColor: appStyle.headerFontColor,
            accentTextColor: appStyle.accentFontColor,
            ctaColor: appStyle.ctaColor,
          },
          mainFunnel: {
            sidebar: {
              headerFooterAccentColor: sidebarStyle.headerFooterColor,
              primaryColor: sidebarStyle.primaryBackgroundColor,
              fontColorOne: sidebarStyle.fontColor1,
              fontColorTwo: sidebarStyle.fontColor2,
              toolTipIconColor: sidebarStyle.tooltipIconColor,
              textIconColor: sidebarStyle.textIconColor,
              cardButtonColor: sidebarStyle.cardButtonColor,
            },
            progressionStatus: {
              accentColor: progressStatusStyle.accentColor,
              reviewUpdatesColor: progressStatusStyle.updateColor,
            },
            alertsAndToolTips: {
              toolTipsBackground: alertStyle.tooltipBackgroundColor,
              toolTipsFontColor: alertStyle.tooltipFontColor,
              alertBackground: alertStyle.alertBackgroundColor,
              alertFontColor: alertStyle.alertFontColor,
            },
            content: {
              sliderColor: contentStyle.sliderColor,
              helpTextColor: contentStyle.helpTextColor,
              fundCtaFontColor: contentStyle.fundCtaFontColor,
              fundCtaBgColor: contentStyle.fundCtaBgColor,
            },
            spotify: {
              partnerCardBgColor: spotifyStyle.cardBgColor,
            },
            login: {
              navigationBgColor: loginStyle.navigationBgColor,
              navigationTextColor: loginStyle.navigationTextColor,
              bgColor: loginStyle.bgColor,
            },
          },
        },
      },
    };
  };
  /**
   * setFormLogo
   * @param {object} res
   * @returns image populated from api
   */
  setFormLogo = (res, type) => {
    const splitLogoValue =
      get(res, `data.${type}`) && res.data[type].split("/").pop().trim();
    this.setState({
      [type]: { src: res.data[type], fileName: splitLogoValue },
    });
  };
  /**
   * validateForEdit - check if the form is edit and there is edit flag from listing page
   */
  validateForEdit = () => {
    const {
      history: {
        location: { pathname, state },
      },
    } = this.props;
    if (
      pathname.includes(ADMIN_WHITELABEL_EDIT_ACCOUNT) &&
      get(state, "accountData._id")
    ) {
      this.setState({
        isEdit: true,
        loading: true,
      });
      const data = {
        method: "GET",
        id: {
          id: state.accountData._id,
        },
      };
      const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}${INSTANCE}?source=${state.accountData.slugName}`;
      request(requestUrl, data, useDefaultToken)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setFormLogo(res, "appLogo");
            this.setFormLogo(res, "emailLogo");
            this.setFormLogo(res, "favicon");
            const values = this.setValuesFromAPI(res);
            this.setState({ initialValues: values.initialValues });
            this.assetsColorFieldChange(
              get(
                values,
                "initialValues.colorCodes.email.iconColor",
                DEFAULT_BB_THEME.emailStyle.iconColor,
              ),
              get(
                values,
                "initialValues.colorCodes.email.boxColor",
                DEFAULT_BB_THEME.emailStyle.boxColor,
              ),
            );
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    } else {
      this.setState({ isEdit: false });
      this.assetsColorFieldChange(
        DEFAULT_BB_THEME.emailStyle.iconColor,
        DEFAULT_BB_THEME.emailStyle.boxColor,
      );
      this.props.history.push(ADMIN_WHITELABEL_NEW_ACCOUNT);
    }
  };

  componentDidMount() {
    this.validateForEdit();
    setTitle(this.props);
  }
  /**
   * checkImageValidation
   * @param {object} file
   * @returns
   */
  checkImageValidation = (file, type) => {
    this.setState({
      appLogoTypeError: false,
      appLogoDimensionError: false,
      appLogoSizeError: false,
      emailLogoDimensionError: false,
      emailLogoTypeError: false,
      emailLogoSizeError: false,
      faviconTypeError: false,
      faviconDimensionError: false,
      faviconSizeError: false,
    });
    const fileType =
      type === "favicon" ? FAVICON_EXTENSIONS_REGEX : IMG_EXTENSIONS_REGEX;
    if (!fileType.test(file[0].type)) {
      this.setState({ [`${type}TypeError`]: true });
      return false;
    }
    const maxSize = type === "favicon" ? FAVICON_MAX_SIZE : IMG_MAX_SIZE;
    if (file[0].size > maxSize) {
      this.setState({ [`${type}SizeError`]: true });
      return false;
    }
    return file;
  };
  /**
   * onChangeFileHandler - file handler function
   * @param {object} event
   */
  onChangeFileHandler = (event, type) => {
    const file = event.target.files;
    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file, type);
    }
    if (validateFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file[0]);
      const fileToSave = file[0];
      img.onload = () => {
        const ratio = img.width / img.height;
        const minRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MIN : IMG_RATIO_LIMIT.MIN;
        const maxRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MAX : IMG_RATIO_LIMIT.MAX;
        if (!(ratio >= minRatio && ratio <= maxRatio) && type === "favicon") {
          this.setState({ [`${type}DimensionError`]: true });
        } else {
          this.setState({
            [type]: {
              fileName: fileToSave && fileToSave.name,
              src: img.src,
            },
          });
          window.URL.revokeObjectURL(file[0]);
        }
      };
    }
    event.target.value = null;
  };
  /**
   * checkIfImageChanged - is image changed while editing.
   * @param {string} type
   */
  checkIfImageChanged = (type) => {
    if (this.state[type].src.includes("blob")) {
      return true;
    }
    return false;
  };
  /**
   * getImageBlob - returns blob of image
   * @param {string} type
   * @returns
   */
  getImageBlob = async (type, isEmailAsset) => {
    const payload = {
      blob: true,
    };
    if (isEmailAsset) {
      return await request(this.emailAssets[type], payload).then((r) => r);
    }
    return await request(this.state[type].src, payload).then((r) => r);
  };
  /**
   * onSubmit
   * @param {object} values
   */
  onSubmit = async (values) => {
    const formData = new FormData();
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { isEdit, emailLogo, appLogo, favicon } = this.state;
    const {
      colorCodes: {
        general,
        email,
        register,
        mainFunnel: {
          sidebar,
          progressionStatus,
          alertsAndToolTips,
          content,
          spotify,
          login,
        },
      },
    } = values;
    formData.append(
      "isDistributor",
      this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME],
    );
    formData.append(
      "isAckScreen",
      this.state[FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME],
    );
    for (
      let index = 0;
      index <
      FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS.length;
      index++
    ) {
      const label =
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .LABEL.NAME
        ];
      formData.append(
        `isField${index + 1}Required`,
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .REQUIRED.NAME
        ],
      );
      const tooltip =
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .TOOLTIP.NAME
        ];
      if (label && label.length > 0) {
        formData.append(`label${index + 1}`, label);
        if (tooltip && tooltip.length > 0) {
          formData.append(`tooltip${index + 1}`, tooltip);
        }
      }
    }
    // email - logo
    const hasEmailLogoChanged = this.checkIfImageChanged("emailLogo");
    if (hasEmailLogoChanged) {
      const emailLogoBlob = await this.getImageBlob("emailLogo");
      formData.append("emailLogo", emailLogoBlob, emailLogo.fileName);
    }
    // app-logo
    const hasAppLogoChanged = this.checkIfImageChanged("appLogo");
    if (hasAppLogoChanged) {
      const appLogoBlob = await this.getImageBlob("appLogo");
      formData.append("appLogo", appLogoBlob, appLogo.fileName);
    }
    // favicon
    const hasFaviconChanged = this.checkIfImageChanged("favicon");
    if (hasFaviconChanged) {
      const faviconBlob = await this.getImageBlob("favicon");
      formData.append("favicon", faviconBlob, favicon.fileName);
    }
    // whitelabel display name and slug name
    formData.append("slugName", values[FIELD_NAMES.SLUG_NAME.NAME]);
    formData.append("displayName", values[FIELD_NAMES.DISPLAY_NAME.NAME]);
    // thankyou cta pop-up text
    formData.append(
      "thankYouCtaPopupText",
      values[FIELD_NAMES.CTA_DEFAULT_VALUE.NAME],
    );
    formData.append(
      "thankYouCtaButtonText",
      values[FIELD_NAMES.CTA_BUTTON_TEXT.NAME],
    );
    formData.append(
      "thankYouCtaActionUrl",
      values[FIELD_NAMES.CTA_ACTION_URL.NAME],
    );
    // acknowledgement screen cta
    formData.append(
      "acknowledgementCtaButtonText",
      values[FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME],
    );
    formData.append(
      "acknowledgementCtaActionUrl",
      values[FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME],
    );
    // landing page URL
    formData.append(
      "frontendDomain",
      values[FIELD_NAMES.LANDING_PAGE_URL.NAME],
    );
    formData.append("whitelistUrl", values[FIELD_NAMES.WHITE_LISTED_URLS.NAME]);
    formData.append("finePrintText", values[FIELD_NAMES.FINE_PRINT_TEXT.NAME]);
    formData.append("sidebarText", values[FIELD_NAMES.SIDEBAR_TEXT.NAME]);
    formData.append(
      "offerSummaryText",
      values[FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME],
    );
    formData.append(
      "nonInternationalUrl",
      values[FIELD_NAMES.GET_CONTRACT_US_URL.NAME],
    );
    formData.append(
      "internationalUrl",
      values[FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME],
    );
    // reset Password switch
    formData.append(
      "isResetPassword",
      values[FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME],
    );
    // reset Password String
    formData.append(
      "resetPassword",
      values[FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME]
        ? values[FIELD_NAMES.RESET_PASSWORD_MODAL.NAME]
        : "",
    );
    // color Sets
    formData.append(
      "colorSet",
      JSON.stringify(arrayToObj(values[FIELD_NAMES.COLOR_SETS.NAME])),
    );
    // general color
    formData.append("primaryColor", general.primaryColor);
    formData.append("ctaFontColor", general.ctaFontColor);
    // email color
    formData.append("emailHeaderFooterColor", email.headerFooterColor);
    formData.append("emailCtaColor", email.ctaColor);
    formData.append("emailFontColor1", email.fontColorOne);
    formData.append("emailFontColor2", email.fontColorTwo);
    formData.append("emailBoxColor", email.boxColor);
    formData.append("emailIconColor", email.iconColor);
    // main funnel
    // register
    formData.append("appPrimaryBackgroundColor", register.mainBackgroundColor);
    formData.append("appHeaderBackgroundColor", register.headerBackgroundColor);
    formData.append("appHeaderFontColor", register.headerFontColor);
    formData.append("appAccentFontColor", register.accentTextColor);
    formData.append("appCtaColor", register.ctaColor);
    // sidebar
    formData.append("sidebarPrimaryBackgroundColor", sidebar.primaryColor);
    formData.append(
      "sidebarHeaderFooterColor",
      sidebar.headerFooterAccentColor,
    );
    formData.append("sidebarFontColor1", sidebar.fontColorOne);
    formData.append("sidebarFontColor2", sidebar.fontColorTwo);
    formData.append("sidebarTooltipIconColor", sidebar.toolTipIconColor);
    formData.append("sidebarTextIconColor", sidebar.textIconColor);
    formData.append("sidebarCardButtonColor", sidebar.cardButtonColor);
    // progression status
    formData.append("progressStatusAccentColor", progressionStatus.accentColor);
    formData.append(
      "progressStatusUpdateColor",
      progressionStatus.reviewUpdatesColor,
    );
    // alert and tooltips
    formData.append(
      "alertTooltipBackgroundColor",
      alertsAndToolTips.toolTipsBackground,
    );
    formData.append(
      "alertTooltipFontColor",
      alertsAndToolTips.toolTipsFontColor,
    );
    formData.append("alertBackgroundColor", alertsAndToolTips.alertBackground);
    formData.append("alertFontColor", alertsAndToolTips.alertFontColor);
    // content
    formData.append("contentSliderColor", content.sliderColor);
    formData.append("contentHelpTextColor", content.helpTextColor);
    formData.append("contentFundCtaTextColor", content.fundCtaFontColor);
    formData.append("contentFundCtaBgColor", content.fundCtaBgColor);

    // spotify
    formData.append("spotifyCardBgColor", spotify.partnerCardBgColor);

    //login
    formData.append("loginNavigationBgColor", login.navigationBgColor);
    formData.append("loginNavigationTextColor", login.navigationTextColor);
    formData.append("loginBgColor", login.bgColor);
    formData.append("adminPassword", values[FIELD_NAMES.ADMIN_PASSWORD.NAME]);
    if (isEdit) {
      formData.append("id", state.accountData._id);
    }
    // custom assets
    formData.append(
      "iconMonitor",
      await this.getImageBlob("iconMonitor", true),
      "iconMonitor.png",
    );
    formData.append(
      "iconSlider",
      await this.getImageBlob("iconSlider", true),
      "iconSlider.png",
    );
    formData.append(
      "iconDiamond",
      await this.getImageBlob("iconDiamond", true),
      "iconDiamond.png",
    );
    formData.append(
      "iconLocker",
      await this.getImageBlob("iconLocker", true),
      "iconLocker.png",
    );
    formData.append(
      "iconCash",
      await this.getImageBlob("iconCash", true),
      "iconCash.png",
    );
    formData.append(
      "iconPunctuation",
      await this.getImageBlob("iconPunctuation", true),
      "iconPunctuation.png",
    );
    formData.append("isPublishing", this.state[FIELD_NAMES.IS_PUBLISHING.NAME]);

    this.setState({
      loading: true,
      isDistributorError: false,
    });
    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(
            `${values.displayName} account is successfully ${
              isEdit ? `updated!` : `created!`
            }`,
          );
          this.props.history.push(ADMIN_WHITELABEL);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  renderNameAndLogo = () => {
    const {
      emailLogo,
      appLogo,
      favicon,
      appLogoSizeError,
      emailLogoSizeError,
      faviconSizeError,
      appLogoTypeError,
      emailLogoTypeError,
      faviconTypeError,
      appLogoDimensionError,
      emailLogoDimensionError,
      faviconDimensionError,
      isEdit,
    } = this.state;
    return (
      <div className={whiteLabelStyles.nameAndLogoContainer}>
        <p>Whitelabel Name and Logo</p>
        <div className={whiteLabelStyles.subContainer}>
          <div>
            <div className={`form-group ${whiteLabelStyles.formGroup}`}>
              <FormField
                name={FIELD_NAMES.DISPLAY_NAME.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.DISPLAY_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.DISPLAY_NAME.LABEL}*`}
              />
            </div>
            <div
              className={`form-group ${whiteLabelStyles.formGroup}
                                        ${whiteLabelStyles.slugInstruction}`}
            >
              <FormField
                name={FIELD_NAMES.SLUG_NAME.NAME}
                disabled={isEdit}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.SLUG_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.SLUG_NAME.LABEL}*`}
              />
              <p>{FIELD_NAMES.SLUG_NAME.EXTRA_TEXT}</p>
            </div>
          </div>
          <div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="Email Logo"
                maxSize="250 KB"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "emailLogo")}
                editLogoMessage="Edit Email Logo"
                imageAvailable={emailLogo.src}
                buttonText="+ Add Email Logo*"
                sizeError={emailLogoSizeError}
                typeError={emailLogoTypeError}
                dimensionError={emailLogoDimensionError}
                data-testid="EmailLogo"
              />
            </div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="App Logo"
                maxSize="250 KB"
                editLogoMessage="Edit App Logo"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "appLogo")}
                imageAvailable={appLogo.src}
                buttonText="+ Add App Logo*"
                sizeError={appLogoSizeError}
                typeError={appLogoTypeError}
                dimensionError={appLogoDimensionError}
                data-testid="AppLogo"
              />
            </div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg, .gif, .ico"
                uploadType="Favicon"
                aspectRatio="1:1"
                maxSize="5 KB"
                editLogoMessage="Edit Favicon"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "favicon")}
                imageAvailable={favicon.src}
                buttonText="+ Add Favicon"
                sizeError={faviconSizeError}
                typeError={faviconTypeError}
                dimensionError={faviconDimensionError}
                data-testid="favicon"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDistributorSwitch = (values) => {
    const { isDistributorError } = this.state;
    return (
      <div className={whiteLabelStyles.isDistributor}>
        <div className={whiteLabelStyles.isDistributorContainer}>
          <p>{FIELD_NAMES.IS_DISTRIBUTOR.EXTRA_TEXT}</p>
          <div className={whiteLabelStyles.subContainer}>
            <Switch
              id="distributorSwitch"
              title="Enable/Disable Distributor Fields"
              isOn={this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes"}
              onColor={generalColors.primaryColor}
              data-testid="distributorSwitch"
              handleToggle={() => {
                const ifValidToGo = checkForAdditionalFields(values);
                if (!ifValidToGo) {
                  this.setState({
                    isDistributorError: true,
                  });
                } else {
                  this.setState((prevState) => ({
                    [FIELD_NAMES.IS_DISTRIBUTOR.NAME]:
                      prevState[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes"
                        ? "No"
                        : "Yes",
                    isDistributorError: false,
                  }));
                }
              }}
            />
          </div>
        </div>
        {isDistributorError && (
          <div
            className={`${whiteLabelStyles.errorText} ${whiteLabelStyles.additionalFieldErrorMessage}`}
          >
            {FIELD_NAMES.IS_DISTRIBUTOR.ERROR_TEXT}
          </div>
        )}
      </div>
    );
  };

  renderAckScreenCtaSwitch = (values) => {
    return (
      <div className={whiteLabelStyles.isDistributor}>
        <div className={whiteLabelStyles.isDistributorContainer}>
          <p>{FIELD_NAMES.IS_ACK_SCREEN_CTA.EXTRA_TEXT}</p>
          <div className={whiteLabelStyles.subContainer}>
            <Switch
              id="ackCtaSwitch"
              title="Enable/Disable Acknowledgement Screen CTA Fields"
              isOn={this.state[FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME] === "Yes"}
              onColor={generalColors.primaryColor}
              data-testid="ackCtaSwitch"
              handleToggle={() => {
                this.setState((prevState) => ({
                  [FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME]:
                    prevState[FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME] === "Yes"
                      ? "No"
                      : "Yes",
                }));
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderIsPublishingSwitch = () => {
    return (
      <div className={whiteLabelStyles.isDistributor}>
        <div className={whiteLabelStyles.isDistributorContainer}>
          <p>{FIELD_NAMES.IS_PUBLISHING.EXTRA_TEXT}</p>
          <div className={whiteLabelStyles.subContainer}>
            <Switch
              id="isPublishingSwitch"
              title="Enable/Disable Publishing"
              isOn={this.state[FIELD_NAMES.IS_PUBLISHING.NAME]}
              onColor={generalColors.primaryColor}
              data-testid="isPublishingSwitch"
              disabled={this.state.isPublishingBtnDisable}
              handleToggle={() => {
                this.setState((prevState) => ({
                  [FIELD_NAMES.IS_PUBLISHING.NAME]:
                    !prevState[FIELD_NAMES.IS_PUBLISHING.NAME],
                }));
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  validateCondition = (validateForm, handleSubmit) => {
    validateForm().then((val) => {
      !_.size(val) ? this.handleOpenPasswordBox() : handleSubmit();
    });
  };

  renderSubmitButton = (validateForm, handleSubmit) => {
    const {
      loading,
      isEdit,
      appLogo,
      emailLogo,
      favicon,
      appLogoDimensionError,
      appLogoTypeError,
      appLogoSizeError,
      emailLogoDimensionError,
      emailLogoTypeError,
      emailLogoSizeError,
      faviconLogoDimensionError,
      faviconLogoTypeError,
      faviconLogoSizeError,
    } = this.state;
    const appError =
      appLogoDimensionError || appLogoSizeError || appLogoTypeError;
    const emailError =
      emailLogoTypeError || emailLogoSizeError || emailLogoDimensionError;
    const faviconError =
      faviconLogoTypeError || faviconLogoSizeError || faviconLogoDimensionError;
    const areLogosUploaded =
      IMG_NOT_AVAILABLE_CASES.some((imgCase) => appLogo.src === imgCase) ||
      IMG_NOT_AVAILABLE_CASES.some((imgCase) => emailLogo.src === imgCase) ||
      IMG_NOT_AVAILABLE_CASES.some((imgCase) => favicon.src === imgCase);
    const imageError =
      appError || emailError || faviconError || areLogosUploaded;
    const isButtonDisabled = loading || imageError;
    return (
      <div className={whiteLabelStyles.saveButton}>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-link"
          onClick={() => {
            toast.info("Data entered in this form will not be saved.");
            this.props.history.push(ADMIN_WHITELABEL);
          }}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            this.validateCondition(validateForm, handleSubmit);
          }}
          type="button"
          className={isButtonDisabled && whiteLabelStyles.disabledSubmitButton}
          disabled={isButtonDisabled}
        >
          {isEdit ? "Save Changes" : "Create Whitelabel"}
        </button>
      </div>
    );
  };

  handleOpenPasswordBox = () => this.setState({ passwordBox: true });
  handleClosePasswordBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ passwordBox: false });
  };

  renderAdminPasswordField = (isValid, handleSubmit, values, setFieldValue) => {
    if (!isValid && this.state.passwordBox)
      this.setState({ passwordBox: false });
    return (
      <ReactModal
        isOpen={this.state.passwordBox && isValid}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleClosePasswordBox}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          {this.state.isEdit ? "Save Changes" : "Create Whitelabel"} for{" "}
          <span className={styles.primaryName}>
            {values[FIELD_NAMES.DISPLAY_NAME.NAME]}
          </span>
          ?
        </h3>
        <div className={styles.changeSource}>
          <p>Please input the password to confirm these changes: </p>
          <div
            className={`${styles.changeTo} form-group justify-content-center`}
          >
            <FormField
              name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
              label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
              placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
              as="password"
            />
          </div>
        </div>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
            onClick={handleSubmit}
            type="submit"
          >
            Okay
          </button>
          <button
            className={styles.secondary}
            onClick={() => this.handleClosePasswordBox(setFieldValue)}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    );
  };

  changeSvgColor = (element, field) => {
    element.getElementById("color-change").style.fill =
      this.state.emailIconColor;
    if (field === "iconPunctuation") {
      element.getElementById("color-change-2").style.fill =
        this.state.emailBoxColor;
    }
  };

  onImageLoad = (img, blobUrl, field) => () => {
    const canvas = document.createElement("canvas");
    canvas.height = 118;
    canvas.width = 118;
    if (field === "iconPunctuation") {
      canvas.height = 190;
      canvas.width = 254;
    }
    const ctx = canvas.getContext("2d");
    if (field !== "iconPunctuation") {
      ctx.drawImage(img, 0, 0, 118, 118);
    }
    if (field === "iconPunctuation") {
      ctx.drawImage(img, 0, 0, 254, 190);
    }
    this.emailAssets[field] = canvas.toDataURL();
    URL.revokeObjectURL(blobUrl);
  };

  convertToPng = (field) => {
    const svgElement = document.getElementById(ASSETS_IDS[field]);
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.changeSvgColor(svgElement, field);
    const blob = new Blob([svgElement.outerHTML], {
      type: "image/svg+xml;charset=utf-8",
    });
    const blobUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = this.onImageLoad(img, blobUrl, field).bind(this);
    img.src = blobUrl;
  };

  createEmailAssets = () => {
    for (const key in ASSETS_IDS) {
      this.convertToPng(key);
    }
  };

  assetsColorFieldChange = (emailIconColor, emailBoxColor) => {
    this.setState(
      {
        emailIconColor: emailIconColor || this.state.emailIconColor,
        emailBoxColor: emailBoxColor || this.state.emailBoxColor,
      },
      this.createEmailAssets,
    );
  };
  renderResetPasswordModalSwitch = (values, setFieldValue) => (
    <div className={whiteLabelStyles.isDistributor}>
      <div className={whiteLabelStyles.isDistributorContainer}>
        <p>{FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.EXTRA_TEXT}</p>
        <div className={whiteLabelStyles.subContainer}>
          <Switch
            id="passwordSwitch"
            title="Enable/Disable reset password Fields"
            isOn={values[FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME]}
            onColor={generalColors.primaryColor}
            data-testid="resetPasswordSwitch"
            handleToggle={(e) => {
              setFieldValue(
                [FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME],
                e.target.checked,
              );
              if (!e.target.checked) {
                setFieldValue(
                  [FIELD_NAMES.RESET_PASSWORD_MODAL.NAME],
                  this.state.initialValues[
                    FIELD_NAMES.RESET_PASSWORD_MODAL.NAME
                  ],
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  renderColorCodes = (
    onChangeHandle,
    values,
    setFieldValue,
    setTouched,
    touched,
    errors,
  ) => {
    return (
      <div className={whiteLabelStyles.colorsContainer}>
        <p>Color Codes</p>
        <div className={whiteLabelStyles.subContainer}>
          <section>
            <p className={whiteLabelStyles.colorSection}>
              <span>Color List</span>{" "}
              {values[FIELD_NAMES.COLOR_SETS.NAME].length < 10 && (
                <button
                  type="button"
                  onClick={() => {
                    addNewColor(values, setFieldValue);
                    setTouched({
                      ...touched,
                      colorSet: getTouchedValue(
                        values[FIELD_NAMES.COLOR_SETS.NAME],
                      ),
                    });
                  }}
                  data-testid="addColor"
                >
                  <span>+</span>Add New Color
                </button>
              )}
            </p>
            <div>
              {values[FIELD_NAMES.COLOR_SETS.NAME].map((_, index) =>
                this.renderNewColor(index, values, setFieldValue),
              )}
            </div>
          </section>
        </div>
        <div className={whiteLabelStyles.subContainer}>
          {COLOR_CODES.map((color, index) => {
            return (
              <section key={index}>
                <p>{color.label}</p>
                <div>
                  {color.subFields &&
                    color.subFields.map((subField, subIndex) => (
                      <>
                        {subField.subFields === undefined
                          ? renderColorField(
                              subField,
                              subIndex,
                              onChangeHandle,
                              values,
                              setFieldValue,
                              errors,
                            )
                          : renderColorSubFields(
                              subField,
                              subIndex,
                              values,
                              setFieldValue,
                              errors,
                            )}
                      </>
                    ))}
                </div>
              </section>
            );
          })}
        </div>
      </div>
    );
  };

  checkSameColor = (value, colorSet) => {
    let count = 0;
    colorSet.forEach((color) => {
      if (`${color.value}`.toLowerCase() === `${value}`.toLowerCase()) {
        count += 1;
      }
    });
    return count > 1 ? true : false;
  };

  OnChangeColorCode = (index, values, inputHex, setFieldValue) => {
    const oldValue = get(
      values[FIELD_NAMES.COLOR_SETS.NAME],
      `[${index}].value`,
    );
    if (!this.checkSameColor(oldValue, values[FIELD_NAMES.COLOR_SETS.NAME])) {
      const colorCode = { ...values.colorCodes };
      const oldValue = get(
        values[FIELD_NAMES.COLOR_SETS.NAME],
        `[${index}].value`,
      );
      const newObj = checkForObjectValueforRemove(
        colorCode,
        oldValue,
        `${inputHex}`,
      );
      setFieldValue("colorCodes", newObj);
    }
    setFieldValue(`colorSet[${index}].value`, `${inputHex}`);
  };

  renderNewColor = (index, values, setFieldValue) => (
    <div className={`form-group ${whiteLabelStyles.newColorSection}`}>
      <FormField
        as="color"
        type="color"
        name={`colorSet[${index}].value`}
        className={whiteLabelStyles.colorDiv}
        hideError
        onChangeHandle={(val) => {
          this.OnChangeColorCode(index, values, val, setFieldValue);
        }}
        data-testid="test-color"
      />
      <FormField
        as="input"
        label="Hex Code"
        name={`colorSet[${index}].value`}
        onChange={(e) => {
          const inputHex =
            get(e.target, "value").charAt(0) !== "#"
              ? `#${e.target.value}`
              : e.target.value;
          this.OnChangeColorCode(index, values, inputHex, setFieldValue);
        }}
        data-testid="hexCode"
      />
      <FormField
        type="text"
        as={"input"}
        label={`Color Name`}
        name={`colorSet[${index}].label`}
      />
      {values[FIELD_NAMES.COLOR_SETS.NAME].length !== 1 && (
        <button
          type="button"
          onClick={() => {
            if (
              checkForObjectValue(
                values.colorCodes,
                get(values[FIELD_NAMES.COLOR_SETS.NAME], `[${index}].value`),
              )
            ) {
              this.handleOpenColorBox(index);
            } else {
              removeColor(index, values, setFieldValue);
            }
          }}
          data-testid="removeColor"
        >
          -
        </button>
      )}
    </div>
  );
  renderRemoveColorModal = (values, setFieldValue) => (
    <ReactModal
      isOpen={this.state.removeColorBox}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.handleCloseColorBox}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.colorDiv}>
        <h3>Warning!</h3>
        <h2>
          <span>
            {getDeletedColor(
              values[FIELD_NAMES.COLOR_SETS.NAME],
              this.state.deleteIndex,
            )}
          </span>{" "}
          is currently being used to set colors in the app.
        </h2>
        <p>
          If you continue, these values will be set to{" "}
          <span>
            {get(
              getReplacedColor(
                values[FIELD_NAMES.COLOR_SETS.NAME],
                this.state.deleteIndex,
              ),
              "label",
            )}
          </span>
        </p>
      </div>
      <div className={styles.buttonDiv}>
        <button
          type="button"
          onClick={this.handleCloseColorBox}
          className={styles.secondary}
        >
          Cancel
        </button>
        <button
          type="button"
          className={styles.btn}
          onClick={() => {
            this.handleCloseColorBox();
            removeColor(this.state.deleteIndex, values, setFieldValue);
          }}
          data-testid="remove-id"
        >
          Continue
        </button>
      </div>
    </ReactModal>
  );
  handleCloseColorBox = () => {
    this.setState({ removeColorBox: false });
  };
  handleOpenColorBox = (index) => {
    this.setState({ deleteIndex: index, removeColorBox: true });
  };
  render() {
    const { loading, isEdit } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>{isEdit ? "Edit" : "Create New"} Whitelabel Account</h1>
            </div>
            <div className={whiteLabelStyles.contentContainer}>
              <Formik
                initialValues={this.state.initialValues}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
                data-testid="formRender"
              >
                {({
                  values,
                  isValid,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                  setTouched,
                  touched,
                  errors,
                }) => (
                  <Form>
                    {this.renderNameAndLogo()}
                    {renderThankYouCta()}

                    {this.renderIsPublishingSwitch()}
                    {this.renderAckScreenCtaSwitch(values)}
                    {this.state[FIELD_NAMES.IS_ACK_SCREEN_CTA.NAME] === "Yes" &&
                      renderAckScreenCta(values)}
                    {this.renderDistributorSwitch(values)}
                    {this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes" &&
                      renderReportAdditionalFields(values, setFieldValue)}
                    {renderLandingPageURL(values)}
                    {renderFinePrintText()}
                    {renderSidebarText(
                      this.state.initialValues[FIELD_NAMES.SIDEBAR_TEXT.NAME],
                      setFieldValue,
                      this.editorSidebarRef,
                    )}
                    {renderOfferSummaryText(
                      this.state.initialValues[
                        FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME
                      ],
                      setFieldValue,
                      this.editorRef,
                    )}
                    {renderContractUrl()}
                    {this.renderResetPasswordModalSwitch(values, setFieldValue)}
                    {values[FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME] ===
                      true &&
                      renderResetPasswordModal(
                        this.state.initialValues[
                          FIELD_NAMES.RESET_PASSWORD_MODAL.NAME
                        ],
                        setFieldValue,
                        this.resetPasswordRef,
                      )}
                    {this.renderColorCodes(
                      this.assetsColorFieldChanges,
                      values,
                      setFieldValue,
                      setTouched,
                      touched,
                      errors[FIELD_NAMES.COLOR_SETS.NAME],
                    )}
                    {this.renderSubmitButton(validateForm, handleSubmit)}
                    {this.renderAdminPasswordField(
                      isValid,
                      handleSubmit,
                      values,
                      setFieldValue,
                    )}
                    {this.renderRemoveColorModal(values, setFieldValue)}
                  </Form>
                )}
              </Formik>
            </div>
            {renderSvgAssets()}
          </div>
        </div>
        {loading && <Loader data-testid="Loader" />}
      </>
    );
  }
}
export default WhiteLabelAccount;
