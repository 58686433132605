export const CONTENT_TYPE = [
  { label: "All", value: "all" },
  { label: "Knowledge Center", value: "Articles" },
  { label: "beatBread News", value: "News" },
  { label: "Artist Testimonials", value: "Testimonials" },
];

export const HOMEPAGE_CONTENT_COLUMNS = [
  { name: "Feat.", key: 1 },
  {
    name: "Image",
    key: 2,
  },
  {
    name: "Article Name",
    key: 3,
  },
  {
    name: "Date Created",
    key: 4,
  },
  {
    name: "Publish Date",
    key: 5,
  },
  {
    name: "Publish To",
    key: 6,
  },
  {
    name: "Category",
    key: 7,
  },
  {
    name: "Actions",
    key: 8,
  },
];
export const FIELD_NAMES = {
  ADMIN_PASSWORD: {
    NAME: "adminPassword",
    LABEL: "Password",
    PLACEHOLDER: "Password",
  },
};
export const VIDEO_CATEGORY = [
  { label: "All", value: "all" },
  { label: "Case Studies", value: "Case Studies" },
];

export const ARTICLE_CATEGORY_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Advancing Independence", value: "Advancing Independence" },
  { label: "Artists Like You", value: "Artists Like You" },
  { label: "Learn More", value: "Learn More" },
];
export const NEWS_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Press", value: "Press" },
  { label: "Release Notes", value: "Release Notes" },
];
export const ALL_HOMEPAGE_DEFAULT_CATEGORY = [
  { label: "All", value: "all" },
  {
    label: "Advancing Independence",
    value: "Advancing Independence",
  },
  {
    label: "Artists Like You",
    value: "Artists Like You",
  },
  {
    label: "Learn More",
    value: "Learn More",
  },

  { label: "Press", value: "Press" },
  { label: "Release Notes", value: "Release Notes" },
  { label: "Case Studies", value: "Case Studies" },
];
