import React, { Component } from "react";
import styles from "./YourAdvance.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import MenuBar from "../../../component/MenuBar";
import { Link } from "react-router-dom";
import { SUCCESS } from "../constants";
import Footer from "../../../component/Footer";
import { ReactComponent as BarChartSvg } from "../../../assets/logos/bar-chart.svg";
import { ReactComponent as CloudSvg } from "../../../assets/logos/cloud.svg";
import { ReactComponent as NoteSvg } from "../../../assets/logos/note.svg";
import { ReactComponent as CoinSvg } from "../../../assets/logos/coin-money.svg";

class ContactForFund extends Component {
  componentDidMount() {
    document.title = "beatBread: Contact For Funding";
  }

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.pageContainer}>
          <MenuBar />
          <div className={styles.contactMainContainer}>
            <div className={styles.contactContainer}>
              <h1 className={styles.contactHeader}>Finalize this deal</h1>
              <p className={styles.contactText}>
                To complete the process, schedule a call with beatBread. We’ll
                walk you through the four step process in appoximately 2-3
                business days.
              </p>
              <div className={styles.buttonLine}>
                <button
                  data-testid="backBtn"
                  onClick={() => this.props.history.goBack()}
                  className={styles.goBackBtn}
                >
                  Return to advance settings
                </button>
                <Link to={SUCCESS} className={styles.scheduleBtn}>
                  Schedule appointment
                </Link>
              </div>
              <div className={styles.stepRow}>
                <div className={styles.noteItem}>
                  <BarChartSvg />
                  1. Confirm your streaming & <br /> revenue history
                </div>
                <div className={styles.noteItem}>
                  <NoteSvg />
                  2. Review and eSign your <br /> agreement
                </div>
                <div className={styles.noteItem}>
                  <CloudSvg />
                  3. Connect us with your <br /> distribution accounts
                </div>
                <div className={styles.noteItem}>
                  <CoinSvg />
                  4. Get your upfront <br /> advance funds
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default ContactForFund;
