export const ROUND_AMOUNT = 5000;
export const STEPS = 100;

export const MULTIPLIER = {
  new_release: {
    catalog_advance: 0.05,
    nr_advance: 0.95,
  },
  option_release: {
    catalog_advance: 0.05,
    nr_advance: (1 / 2.25) * 0.95,
    option_advance: (1.25 / 2.25) * 0.95,
  },
};
