import React, { useEffect, useRef, useState } from "react";
import styles from "./CompareDealChart.module.scss";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import { DEAL_CATEGORY_FILTER, SUBMIT_BUTTON } from "./constants";
import DealLineChart from "./LineChart";
import DealBarChart from "./BarChart";
import DealComparisonTable from "./DealComparisonTable";
import { get, invoke } from "lodash";
import { useMediaQuery } from "../../../utils/Helper";

const CompareDealChart = (props) => {
  const data = get(props, "data", []);
  const isMobile = useMediaQuery("(max-width:767px)");
  const chartDivRef = useRef();

  const newSongFilter = data
    .map((distributor) =>
      Object.keys(
        distributor.cashflows[Object.keys(DEAL_CATEGORY_FILTER[0])].n_new,
      ),
    )
    .reduce((common, keys) => common.filter((key) => keys.includes(key)))
    .map((key) => ({ label: `${key} songs`, value: key }));

  const [releaseSong, setReleaseSong] = useState(() =>
    newSongFilter.length ? newSongFilter[0].value : null,
  );

  const newReleasePerfomanceFilter = (releaseSong, data) => {
    if (!data || releaseSong === null) return [];
    const low =
      data[0].cashflows[Object.keys(DEAL_CATEGORY_FILTER[0])].n_new[releaseSong]
        .nr_dollars_year_one;
    const expected =
      data[0].cashflows[Object.keys(DEAL_CATEGORY_FILTER[1])].n_new[releaseSong]
        .nr_dollars_year_one;
    const high =
      data[0].cashflows[Object.keys(DEAL_CATEGORY_FILTER[2])].n_new[releaseSong]
        .nr_dollars_year_one;

    return [
      {
        label: `Low $${Math.round(low)} per year`,
        value: Object.keys(DEAL_CATEGORY_FILTER[0]),
      },
      {
        label: `Expected $${Math.round(expected)} per year`,
        value: Object.keys(DEAL_CATEGORY_FILTER[1]),
      },
      {
        label: `High $${Math.round(high)} per year`,
        value: Object.keys(DEAL_CATEGORY_FILTER[2]),
      },
    ];
  };

  const [performanceOptions, setPerformanceOptions] = useState([]);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [activeButton, setActiveButton] = useState(SUBMIT_BUTTON[0]);

  const filter = {
    category: selectedPerformance,
    song: releaseSong,
  };

  const updatedData = data.map((item, index) => ({
    ...item,
    title: `Deal ${index + 1}`,
  }));

  const getTitle = () => {
    let title = "";
    if (activeButton === SUBMIT_BUTTON[0]) {
      title = "How much Total money will I receive over time?";
    } else {
      title = "How much money will I receive Each Year?";
    }
    return title;
  };

  const getDescription = () => {
    let description = "";
    if (activeButton === SUBMIT_BUTTON[0]) {
      description =
        "The following chart breaks down the total income you'll receive during the length of your deal. This amount include your advances, royalties after recoupment, and new releases.";
    } else {
      description =
        "The following chart breaks down the  income you'll receive each year over time. These amounts include advances, royalties after recoupment, and income earned from new releases.";
    }
    return description;
  };

  const handleClick = (buttonName) => {
    if (activeButton !== buttonName) {
      setActiveButton(buttonName);
    }
  };

  useEffect(() => {
    const options = newReleasePerfomanceFilter(releaseSong, data);
    setPerformanceOptions(options);
    setSelectedPerformance(options[0] || null);
  }, [releaseSong]);

  useEffect(() => {
    if (chartDivRef.current) {
      invoke(props, "setChartDivHeight", chartDivRef.current.clientHeight);
    }
  }, [isMobile, data, filter]);

  const renderDesktopCompareLineChart = () => {
    return (
      <div className={styles.mainDealContainer}>
        <div className={styles.mainBoxContainer}>
          <div className={styles.filterContainer}>
            <div className={styles.titleContainer}>
              <h2>Chart Type</h2>
              <div className={styles.buttonContainer}>
                <button
                  type="submit"
                  className={`${styles.submitButton} ${activeButton === SUBMIT_BUTTON[0] ? styles.activeButton : ""}`}
                  onClick={() => handleClick(SUBMIT_BUTTON[0])}
                >
                  {SUBMIT_BUTTON[0]}
                </button>
                <button
                  type="submit"
                  className={`${styles.submitButton} ${activeButton === SUBMIT_BUTTON[1] ? styles.activeButton : ""}`}
                  onClick={() => handleClick(SUBMIT_BUTTON[1])}
                >
                  {SUBMIT_BUTTON[1]}
                </button>
              </div>
            </div>
            <div className={styles.titleContainer}>
              <h2>New Release Performance</h2>
              <div className={styles.newReleasePerformContainer}>
                <Select
                  classNamePrefix="distributor"
                  styles={distributorSelectStyle}
                  onChange={(selectedOption) =>
                    setSelectedPerformance(selectedOption)
                  }
                  options={performanceOptions}
                  value={selectedPerformance}
                  components={{ IndicatorSeparator: null }}
                  isSearchable={false}
                  data-testid="performance-select"
                  placeholder={`Select New release`}
                  isDisabled={releaseSong === "0"}
                />
              </div>
            </div>
            <div className={styles.titleContainer}>
              <h2># New Releases In Next Year</h2>
              <div className={styles.newReleasePerformContainer}>
                <Select
                  classNamePrefix="distributor"
                  styles={distributorSelectStyle}
                  onChange={(selectedOption) => {
                    const newValue = selectedOption.value;
                    setReleaseSong(newValue);
                  }}
                  options={newSongFilter}
                  value={newSongFilter.find(
                    (option) => option.value === releaseSong,
                  )}
                  components={{ IndicatorSeparator: null }}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
          <div className={styles.chartContainer}>
            {activeButton === SUBMIT_BUTTON[0] ? (
              <DealLineChart data={updatedData} filter={filter} />
            ) : (
              <DealBarChart data={updatedData} filter={filter} />
            )}
          </div>
        </div>
        <DealComparisonTable
          data={updatedData}
          filter={filter}
          type={activeButton}
        />
      </div>
    );
  };

  return (
    <div ref={chartDivRef} className={styles.CompareDealChartContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          <h2>{getTitle()}</h2>
          <p>{getDescription()}</p>
        </div>
      </div>
      {renderDesktopCompareLineChart()}
    </div>
  );
};

export default CompareDealChart;
