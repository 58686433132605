import React, { Component } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Form } from "formik";
import * as Yup from "yup";

import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  LOGIN,
  FORGOT_PASS_API,
  ACK_REDIRECT,
  FF_SECURE_LOGIN
} from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../constants";
import request from "../../../../utils/request";
import { forbiddenCheck, GetErrorMessage } from "../../helper";
import { userExists } from "../../../../utils/Helper";
import LoginHeader from "../../../../component/LoginHeader";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import LoginSideBar from "../../../../component/LoginSideBar";


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      successCall: false,
      identifier: "",
      isOpen: false,
      name: "",
      artistId: "",
      artistRange: "",
      landingPageUrl: "",
      userId: "",
      verificationCode: "",
      image: "",
      isFastFlow: false,
      resEmail: "",
    };
  }
  componentDidMount() {
    forbiddenCheck();
  }

  componentDidUpdate() {
    setTitle("Forgot Password", this.context);
  }

  validationSchema = Yup.object().shape({
    identifier: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
  });

  handelSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      emailOrPhone: values.identifier,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FORGOT_PASS_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });

        if (res.status) {
          this.setState({
            successCall: true,
            identifier: EMAIL_REGEX.test(values.identifier) ? "email" : "phone",
          });
          if (res.data && res.data.isActive === false) {
            this.setState({
              isOpen: true,
              artistRange: res.data.artist_range,
              landingPageUrl: res.data.landingPageUrl,
              userId: res.data.userId,
              verificationCode: res.data.verificationCode,
              artistId: res.data.artist_id,
              image: get(res.data, "profile_picture[2].url"),
              name: res.data.artist_name,
              isFastFlow: get(res.data, "isFastflow"),
              resEmail: values.identifier,
            });
          }
        } else {
          toast.error(get(res, "message"));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  handleRedirect = () => {
    window.top.location.href = `${this.state.landingPageUrl}${ACK_REDIRECT}?name=${this.state.name}&profile=${this.state.image}&status=${this.state.artistRange}&artistId=${this.state.artistId}&_id=${this.state.userId}&code=${this.state.verificationCode}`;
  };
  handleFastFlowRedirect = () => {
    window.top.location.href = `${
      this.state.landingPageUrl
    }${FF_SECURE_LOGIN}?emailId=${encodeURIComponent(this.state.resEmail)}`;
  };
  sucessCallFn = () =>
    !this.state.successCall ? (
      <>
        <div className="form-group form-group-m0 mb-0">
          <FormField
            name="identifier"
            placeholder="Email address"
            label="Email address"
          />
        </div>
        <div className="form-group mb-0">
          <button
            type="submit"
            disabled={this.state.loading}
            className={`${styles.btn} ${styles.forgotResetPasswordBtn}`}
          >
            Send me a new password
          </button>
          {!userExists() && (
            <Link className={styles.formLink} to={LOGIN}>
              Return to login
            </Link>
          )}
        </div>
      </>
    ) : (
      <div className={styles.resendContainer}>
        <span>Did not receive any text or mail? </span>
        <button type="submit">Resend</button>
      </div>
    );

  getErrText = () =>
    this.state.isFastFlow
      ? "Click below to login via secure login link"
      : "Click below to enter your details and create a password";

  getRedirectLink = () =>
    this.state.isFastFlow ? this.handleFastFlowRedirect : this.handleRedirect;

  render() {
    return (
      <div className={styles.layoutContainer}>
        <LoginSideBar  {...this.props} />
        <div className={styles.funnelContainer}>
          {userExists() ? <LoginHeader  {...this.props} /> : <Header {...this.props} />}
          <div className={styles.loginMainContent}>
            <div className={styles.loginContent}>
              {!this.state.isOpen ? (
                <FormikForm
                  initialValues={{ identifier: "" }}
                  validationSchema={this.validationSchema}
                  onSubmit={this.handelSubmit}
                >
                  <div className={`${styles.formContainer} ${styles.formWithoutLogo}`}>
                    <Form>
                      <h1>Get a new password.</h1>
                      <p className={styles.subTitle}>
                        {this.state.successCall &&
                          "Instructions for creating a new password have been mailed to you."}
                      </p>
                      {this.sucessCallFn()}
                    </Form>
                  </div>
                </FormikForm>
              ) : (
                <div className={styles.errorContainer}>
                  <div>
                    <h3>Oops! Your account setup is incomplete.</h3>
                    <p>{this.getErrText()}</p>
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      data-testid="redirectBtn"
                      onClick={this.getRedirectLink()}
                    >
                      LET’S GO
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

ForgotPassword.contextType = ThemeContext;
export default ForgotPassword;
