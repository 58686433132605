import { get } from "lodash";

export const getRadioInformationText = (displayName) =>
  `Would you like ${displayName} to share your earnings reports?`;

export const getRadioText = (displayName) => {
  return {
    trueValue: `Yes, ${displayName} can share my reports.`,
    falseValue: `None of my music is on ${displayName}`,
  };
};

export const OTHER_DISTRIBUTOR = "other";

export const areBothFieldsExist = (context) =>
  get(context.reportFields, "label1", "") &&
  get(context.reportFields, "label2", "");
export const isOneFieldRequired = (context) =>
  get(context.reportFields, "isField1Required", false) ||
  get(context.reportFields, "isField2Required", false);

export const OVERALL_STATUS = [
  "complete",
  "failed_reports",
  "failed_tracks",
  "error",
  "no_reports_processed",
];

export const MUSIC_NOTE_ICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAmCAYAAABQ3RJZAAAAAXNSR0IArs4c6QAAAXFJREFUWEftl0EKhDAMRWfwEIIncuPCK4jgmQTxCi5E8ESCh5AZUujQGbT5HWOLUDduYvr6fxrT5+OGz/OGzI8I7cu14Eqv6/oax1Htt65riAcKklRQQw7DoNLO8/xJv20bxAMFuUITmPkNKUmQJuBeTq/QGlLbvKcisvHLoW02I4BBlE6S5KsE/gU1v7tcaVfoPM8VX1mW6l0UhXpnWebvIHLQBPkLqOnSNP00ADOPd6U1JCloQnFlEwx6WRYnUHMjwaBRW/dUj9BX1GJUmlNVQiHzD6pnEOo4XgcmlwNEwFVVhR+YUGgUmNxEuxA7mp61teu6V9M0UAWKQKMq2RbzCo0Cc7Z6hZZaDM1DnWSaJrZcSaTDIHQxTmlyzBw/j4q7bdvzF1tJaLqG2Q4jqdz3PTx0nVYatfVIBFdga3lI2/p7+dVl4jp/s9DStkLNGghiT6ukrQAPFMJCS9oKEQFBLDSQw3tIhPYleVQ6Km1R4A08x0g2NWL5sQAAAABJRU5ErkJggg==";

export const TYPE_PARTIAL = "partial";
export const TYPE_COMPLETE = "complete";
export const TYPE_MISSING = "missing";
export const DROPDOWN_OPTIONS = [
  { label: "Exclude this track", value: "Exclude this track" },
  { label: "Track included in other", value: "Track included in other" },
  { label: "Let me explain...", value: "" },
];

export const ROUTE_STATUS = [
  "success",
  "month-missing",
  "track-missing",
  "error",
];
