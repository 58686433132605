import React from "react";
import styles from "./CompareDealChart.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { get } from "lodash";
import { SUBMIT_BUTTON } from "./constants";

const DealComparisonTable = (props) => {
  const key =
    props?.type === SUBMIT_BUTTON[0] ? "cum_artist_cfs" : "artist_cfs";
  const category = get(props.filter, "category.value", "");
  const song = get(props.filter, "song", null);

  const tableData = props.data.map((ele) => ({
    name: ele.title,
    values: ele.cashflows[category]?.n_new[song]?.[key] || [],
  }));

  const formatValue = (value) => {
    if (typeof value !== "number") return "-";
    const numericValue = value < 0 ? 0 : Math.floor(value);
    return `$${new Intl.NumberFormat().format(numericValue)}`;
  };

  const maxRows = Math.max(...tableData.map((item) => item.values.length));
  return (
    <div className={styles.mainTableDiv}>
      <TableContainer className={styles.tableDesktopContainer}>
        <Table className={styles.table} style={{ width: "100%" }} stickyHeader>
          <TableBody>
            <TableRow className={styles.headerRow}>
              <TableCell></TableCell>
              {tableData.map((ele, index) => (
                <TableCell key={`cell-${index}`} className={styles.value}>
                  <div className={styles.partnerCell}>{get(ele, "name")}</div>
                </TableCell>
              ))}
            </TableRow>
            {Array.from({ length: maxRows }, (_, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell className={styles.dealIndex}>
                  {new Date().getFullYear() + rowIndex}
                </TableCell>
                {tableData.map((ele) => (
                  <TableCell key={`${ele.distributor}-${rowIndex}`}>
                    {formatValue(ele.values[rowIndex])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DealComparisonTable;
