import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import styles from "./Navigator.module.scss";
import { Link } from "react-router-dom";
import {
  DASHBOARD,
  HOME,
  MY_ACCOUNT,
  LOGIN,
  ADMIN_DASHBOARD,
  ADMIN_WHITELABEL,
  ADMIN_BLACKLIST,
  ADMIN_MAINTENANCE_MODE,
  DISTRIBUTORS,
  EMAIL_PREFERENCES,
  ADJUST_TERMS,
  ACCEPT_OFFER,
  OFFER_SUMMARY,
  DEMO_ARTIST,
  API_URL,
  USER_API,
  ARTIST_API,
  EXIT_DEMO,
  HOMEPAGE_CONTENT,
  PUBLISHERS,
} from "../../routes/pages/constants";
import style from "../Header/Header.module.scss";
import ContactPopup from "../ContactPopup";
import { logout } from "../../utils/Helper";
import AuthTokenService from "../../utils/AuthTokenService";
import StorageService from "../../utils/StorageService";
import { ADMIN_ROUTE } from "./constants";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Image from "../Image/index";
import { IMAGE_TYPE } from "../Image/constants";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { MENU_ICON } from "../MaterialIcons/constants";
import Loader from "../Loader";
import request from "../../utils/request";
import { toast } from "react-toastify";

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  toggleMenu = () => {
    const menuDiv = document.getElementsByClassName(style.menu)[0];
    menuDiv.classList.toggle(style.dNone);
    document
      .getElementsByClassName(styles.menuOverlay)[0]
      .classList.toggle(styles.dNone);
  };

  checkActiveLocation = (value) => {
    return (
      get(this.props, "location.pathname") &&
      get(this.props, "location.pathname").includes(value)
    );
  };
  storeCustomTokenInJwt = () => {
    AuthTokenService.storeToken(StorageService.get("customToken"));
  };

  isApprovedFlow = () => {
    const pathname = get(this.props, "history.location.pathname");
    return [ADJUST_TERMS, OFFER_SUMMARY, ACCEPT_OFFER].indexOf(pathname) !== -1;
  };

  renderSubAdminRoute = () =>
    get(this.props, "role") === "subAdmin" ? (
      <>
        <Link
          to={HOMEPAGE_CONTENT}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          Homepage Content
        </Link>
        <Link
          to={{ pathname: LOGIN, state: { logout: true } }}
          onClick={logout}
          className={`${style.link}`}
        >
          Logout
        </Link>
      </>
    ) : (
      this.renderAdminRouteMenu()
    );

  renderAdminRouteMenu = () =>
    !this.checkActiveLocation(ADMIN_ROUTE) ? (
      <Link to={DASHBOARD} className={`${style.primary} ${style.link}`}>
        My Artists
      </Link>
    ) : (
      <>
        <Link to={ADMIN_BLACKLIST} className={`${style.primary} ${style.link}`}>
          Manage Blacklist
        </Link>
        <Link
          to={ADMIN_WHITELABEL}
          className={`${style.primary} ${style.link}`}
        >
          Whitelabel Accounts
        </Link>
        <Link
          to={ADMIN_MAINTENANCE_MODE}
          className={`${style.primary} ${style.link}`}
        >
          Maintenance Mode
        </Link>
        <Link
          to={DISTRIBUTORS}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          Distributors
        </Link>
        <Link
          to={PUBLISHERS}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          PRO / Publisher
        </Link>
        <Link
          to={DEMO_ARTIST}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          Demo Artists
        </Link>
        <Link
          to={HOMEPAGE_CONTENT}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          Homepage Content
        </Link>
      </>
    );

  exitDemo = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EXIT_DEMO}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          window.location.href = get(this.context, "whitelistUrl[0]", "");
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };
  renderSubAdminMenu = () =>
    get(this.props, "role") !== "subAdmin" && (
      <>
        <Link to={MY_ACCOUNT} className={`${style.primary} ${style.link}`}>
          My Account
        </Link>
        <Link
          to={EMAIL_PREFERENCES}
          className={`${style.primary} ${style.link} ${styles.borderBottom}`}
        >
          Email Preferences
        </Link>
        <Link
          onClick={this.handleOpen}
          className={`${this.state.isOpen && style.primary} ${style.link} ${
            styles.spanLink
          }`}
        >
          Contact us
        </Link>
        <Link
          to={{ pathname: LOGIN, state: { logout: true } }}
          onClick={logout}
          className={`${style.link}`}
        >
          Logout
        </Link>
      </>
    );

  render() {
    return (
      <>
        <div
          className={`${style.menuContainer} ${styles.hamburgerIcon}`}
          onClick={this.toggleMenu}
          data-testid="menuClick"
        >
          <i className={style.menuIcon}>
            <SvgIcons
              icon={MENU_ICON}
              className={`${style.menuIcon} ${this.props.customClassName} ${style.white}`}
            />
          </i>
          <div className={`${styles.menuOverlay} ${styles.dNone}`}>
            <div
              className={`${style.menu} ${style.dNone} ${styles.afterLoginHamburger}`}
            >
              <Link to={HOME} className={style.title}>
                <Image
                  src={`${get(this.context, "appLogo")}`}
                  alt="logo"
                  imageType={IMAGE_TYPE.LOGO}
                />
              </Link>
              <div>
                <div className={style.menuBtns}>
                  {this.props.isDemo ? (
                    <>
                      <span
                        onClick={this.exitDemo}
                        className={`${style.primary} ${style.link}`}
                      >
                        See Your Real Advance Offers
                      </span>
                      <span
                        onClick={this.exitDemo}
                        className={`${style.primary} ${style.link}`}
                      >
                        Exit demo
                      </span>
                    </>
                  ) : (
                    <>
                      {this.renderSubAdminRoute()}
                      {StorageService.get("customToken") &&
                        get(this.props, "role") !== "subAdmin" && (
                          <Link
                            to={ADMIN_DASHBOARD}
                            className={`${style.primary} ${style.link}`}
                            onClick={this.storeCustomTokenInJwt}
                          >
                            Admin
                          </Link>
                        )}
                      {this.renderSubAdminMenu()}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
        {this.state.loading && <Loader />}
      </>
    );
  }
}

Navigator.defaultProps = {
  isArtistPage: false,
};

Navigator.propTypes = {
  isArtistPage: PropTypes.bool,
  customClassName: PropTypes.string,
};

Navigator.contextType = ThemeContext;
export default Navigator;
