import styleVariables from "../../styles/variables.scss";
import varStyleGlobal from "../../styles/global.scss";
import { unset } from "lodash";
const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 300,
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `#5d5d5d`,
    fontWeight: "600",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 2,
    padding: "3px 0",
    border: "none",
    boxShadow: "unset",
    borderBottom: `1px solid #7d7d7d`,
    height: 50,
    "&:hover": {
      borderBottom: `1px solid #5d5d5d`,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 2,
    maxHeight: 200,
    zIndex: 5,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),
};
export const formStyle = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    ...selectStyle.control(),
    borderBottom: "transparent",
    "&:hover": {
      borderBottom: "transparent",
    },
    backgroundColor: "unset",
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: varStyleGlobal.primaryColor,
    fontFamily: styleVariables.circularStd,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: styleVariables.light,
    fontFamily: styleVariables.circularStd,
    fontSize: 14,
    opacity: 0.3,
  }),
};

export const selectFormStyle = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    ...selectStyle.control(),
    borderBottom: `1px solid ${varStyleGlobal.darkGreyColor}`,
    "&:hover": {
      borderBottom: `1px solid ${varStyleGlobal.darkGreyColor}`,
    },
    height: 20,
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
    color: styleVariables.black,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: styleVariables.grey,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "0px",
    color: styleVariables.primaryColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: varStyleGlobal.primaryColor,
    fontFamily: styleVariables.circularBold,
  }),
};

export const distributorSelectStyle = {
  ...selectStyle,
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 400,
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `#5d5d5d`,
    fontWeight: "400",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0 0 7px 7px",
    border: `2px solid ${varStyleGlobal.sidebarPrimaryBackgroundColor}`,
    borderTop: "none",
    boxShadow: "none",
    padding: "0 14px 8px 8px",
    margin: 0
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "7px",
    boxShadow: "none",
    border: `2px solid ${varStyleGlobal.sidebarPrimaryBackgroundColor}`,
    padding: "3px 0 3px 8px",
    height: 50,
    "&:hover": {
      border: `2px solid ${varStyleGlobal.sidebarPrimaryBackgroundColor}`,
    },
  }),
};

export const selectTypeStyle = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 350,
    textAlign: "left",
    marginBottom: 5,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${varStyleGlobal.grey}`,
    fontWeight: "100",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 2,
    padding: "3px 0",
    border: "none",
    boxShadow: "unset",
    height: 42,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 2,
    maxHeight: 200,
    zIndex: 5,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),
};

export const tagStyle = {
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 15,
    backgroundColor: "transparent",
    border: `1px solid ${varStyleGlobal.primaryColor}`,
    textTransform: "capitalize",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ":hover": {
      backgroundColor: unset,
      color: unset,
    },
  }),
};
export default selectStyle;
