import React, { Component } from "react";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import { NAVIGATION_TEXTS } from "../../../component/HorizontalMenu/constants";
import {
  API_URL,
  ARTIST_API,
  FUNNEL_GUIDE_VIDEO,
  GENERATING_OFFERS,
  USER_API,
  YT_EMBED_URL_NO_AUTOPLAY,
} from "../constants";
import styles from "./GeneratingOffers.module.scss";
import { SUBMISSION_TEXT } from "./constants";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { get } from "lodash";
import { GetErrorMessage, GetIframeVideo, YT_VIDEO_ID_REGEX } from "../helper";
import {
  getPartnerName,
  setTitle,
} from "../../../component/ThemeManager/helper";
import { toast } from "react-toastify";
import request from "../../../utils/request";
import Loader from "../../../component/Loader";
import { ReactComponent as CheckMarkSpike } from "../../../assets/logos/checkmark-spiked.svg";
import { ReactComponent as DoubleArrowRight } from "../../../assets/logos/DoubleArrowRight.svg";
import { KNOWLEDGE_CENTER } from "../Auth/LinkAnotherArtist/constant";
import SurveySparrow from "./SurveySparrow";
import { ARTISTS_RANGE } from "../AdminDashboard/constants";
import { numberWithCommas } from "../YourAdvance/helper";

class GeneratingOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      urlData: {},
      url: "",
      navigationStatus: {},
      surveyScreen: false,
    };
    this.statusDataCallback = null;
  }

  componentDidMount() {
    getPartnerName() === "beatBread" && this.getVideoContent();
  }

  componentDidUpdate() {
    setTitle("Next Steps", this.context);
  }

  handleEmailValue = (val, emailVal) => {
    this.setState({ selectedArtist: val, email: emailVal });
  };

  getVideoContent = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const pageName = encodeURIComponent("Generating Offers");
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${FUNNEL_GUIDE_VIDEO}?page=${pageName}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState(
            {
              urlData: get(res.data, "How does it work?"),
            },
            () => {
              this.getVideoURL();
            },
          );
          return true;
        }
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getVideoURL = () => {
    if (this.state.urlData) {
      const videoIdMatch = this.state.urlData.match(YT_VIDEO_ID_REGEX);
      if (videoIdMatch && videoIdMatch.length > 0) {
        const videoId = videoIdMatch.pop();
        const url = `${YT_EMBED_URL_NO_AUTOPLAY.replace(
          "[VIDEO_ID]",
          videoId,
        )}`;
        this.setState({ url });
        return url;
      } else {
        toast.error("Invalid video url");
      }
    }
  };

  setStatusValues = (status, callback) => {
    this.setState({
      navigationStatus: status,
      surveyScreen:
        get(status, "isFastFlow", false) && getPartnerName() === "beatBread",
    });
    this.statusDataCallback = callback;
  };

  handleSkipSurvey = () => {
    this.setState({ surveyScreen: false });
  };

  getSurveyData = () => {
    if (
      get(this.state, "selectedArtist") &&
      get(this.state.navigationStatus, "isFastFlow")
    ) {
      if (
        get(this.state, "selectedArtist.status") === ARTISTS_RANGE.IN_RANGE &&
        get(this.state, "selectedArtist.estimatedOffer.offer")
      ) {
        return {
          advance_min: `$${numberWithCommas(
            get(this.state, "selectedArtist.estimatedOffer.offer.min"),
          )}`,
          advance_max: `$${numberWithCommas(
            get(this.state, "selectedArtist.estimatedOffer.offer.max"),
          )}`,
          email_address: this.state.email,
        };
      }
      return { email_address: this.state.email };
    }
    return undefined;
  };

  operationsEmail = () =>
    getPartnerName() === "beatBread"
      ? "ops@beatbread.com"
      : "operations@chordcash.com";

  renderFastFlowText = () => {
    return (
      <div>
        <div className={styles.fastFlowText}>
          <ol>
            {!get(this.state.navigationStatus, "reports", false) && (
              <li>Log back in on a computer and upload reports</li>
            )}
            <li>
              <span>
                Add {this.operationsEmail()} with temporary view access to your{" "}
                <a href="https://artists.spotify.com/home" target="_blank">
                  Spotify For Artists
                </a>
              </span>
            </li>
            <li>We’ll review your reports</li>
            <li>We may come back to you with additional questions </li>
            <li>We'll email you when your offers are ready </li>
            <li>Choose an offer and move into our contracting process </li>
          </ol>
        </div>
        <div className={styles.learnMoreText}>
          Want to learn more about {get(this.context, "displayName", "")} in the
          meantime?
        </div>
        {getPartnerName() === "beatBread" ? (
          <a
            href={`${process.env.REACT_APP_HOMEPAGE_URL}${KNOWLEDGE_CENTER}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btn}
          >
            Visit the Knowledge Center
          </a>
        ) : (
          <a
            href={get(this.context, "thankYouCTA.actionUrl")}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btn}
          >
            {get(this.context, "thankYouCTA.buttonText")}
          </a>
        )}
      </div>
    );
  };

  renderThankYouScreen = () => (
    <div className={styles.offerMainContainer}>
      <div
        className={`${styles.leftContainer} ${
          get(this.state.navigationStatus, "isFastFlow")
            ? styles.fastLeftContainer
            : ""
        }`}
      >
        {get(this.state.navigationStatus, "isFastFlow") &&
          getPartnerName() === "beatBread" && (
            <div className={styles.checkIcon}>
              <CheckMarkSpike />
            </div>
          )}
        <div className={styles.submittingText}>
          {get(this.state.navigationStatus, "isFastFlow")
            ? "Thank you! Here’s what to expect next:"
            : "Thank you for submitting your info!"}
        </div>
        {get(this.state.navigationStatus, "isFastFlow", false) ? (
          this.renderFastFlowText()
        ) : (
          <>
            <div className={styles.ourTeamText}>{SUBMISSION_TEXT}</div>
            <div className={styles.emailText}>
              We’ll send you an email at <span>{this.state.email}</span> when
              your confirmed offers are ready.
            </div>
          </>
        )}
      </div>
      {getPartnerName() === "beatBread" && (
        <div className={styles.rightContainer}>
          <div
            className={`${styles.fundingText} ${
              get(this.state.navigationStatus, "isFastFlow", false)
                ? styles.fastFundingText
                : ""
            }`}
          >
            {get(this.state.navigationStatus, "isFastFlow", false) ? (
              <>
                <div>SPOTIFY FOR ARTISTS:</div>
                <div className={styles.musicText}>
                  Share S4A with <span>{this.operationsEmail()}</span> for
                  faster delivery of your confirmed offers
                </div>
              </>
            ) : (
              "Learn more about the Funding Process"
            )}
          </div>
          <div
            className={`${styles.videoDiv} ${
              get(this.state.navigationStatus, "isFastFlow", false)
                ? styles.fastVideoDiv
                : ""
            }`}
          >
            {get(this.state.navigationStatus, "isFastFlow", false) ? (
              <GetIframeVideo url="https://www.youtube.com/shorts/0gJH4A9y8iY" />
            ) : (
              <iframe
                src={this.state.url}
                title="How it Works"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              ></iframe>
            )}
          </div>
        </div>
      )}
      {getPartnerName() === "chordCash" &&
        get(this.state.navigationStatus, "isFastFlow") && (
          <div className={styles.rightContainer}>
            <div className={`${styles.fundingText} ${styles.fastFundingText}`}>
              <div>SPOTIFY FOR ARTISTS:</div>
              <div className={styles.musicText}>
                Share S4A with <span>{this.operationsEmail()}</span> for faster
                delivery of your confirmed offers
              </div>
            </div>
            <div className={`${styles.videoDiv} ${styles.fastVideoDiv}`}>
              <GetIframeVideo url="https://www.youtube.com/shorts/sQS7TYP5_q8" />
            </div>
          </div>
        )}
    </div>
  );

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu
          {...this.props}
          getStatus={this.setStatusValues}
          updateUserData={this.handleEmailValue}
        />
        <div className={styles.funnelContainer}>
          <LoginHeader
            headerTitle={
              this.state.surveyScreen
                ? "One more thing before you leave..."
                : NAVIGATION_TEXTS[GENERATING_OFFERS].title
            }
            selectedArtist={this.state.selectedArtist}
            switchDistributorForFunding={get(
              this.state,
              "navigationStatus.switchDistributorForFunding",
            )}
          />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  {this.state.surveyScreen && (
                    <div className={styles.surveyTitleContainer}>
                      <div className={styles.text}>
                        <h3>Have a moment?</h3>
                        <p>Help us improve by taking a quick survey:</p>
                      </div>
                      <button onClick={this.handleSkipSurvey}>
                        <span>No Thanks, Skip Survey</span>
                        <DoubleArrowRight />
                      </button>
                    </div>
                  )}
                  <div
                    className={`${styles.generatingOffersContainer} ${
                      this.state.surveyScreen && this.getSurveyData()
                        ? styles.surveyContainer
                        : ""
                    }`}
                  >
                    {this.state.surveyScreen
                      ? this.getSurveyData() && (
                          <SurveySparrow data={this.getSurveyData()} />
                        )
                      : this.renderThankYouScreen()}
                  </div>
                  {this.state.surveyScreen && (
                    <button
                      onClick={this.handleSkipSurvey}
                      className={styles.surveySkipBtn}
                    >
                      <span>No Thanks, Skip Survey</span>
                      <DoubleArrowRight />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading ||
          (!get(this.state, "navigationStatus.streamingData") && (
            <Loader light backgroundNone />
          ))}
      </div>
    );
  }
}

export default GeneratingOffers;
GeneratingOffers.contextType = ThemeContext;
