import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

const FormikForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  ...otherProps
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      {...otherProps}
    >
      {() => <>{children}</>}
    </Formik>
  );
};

FormikForm.propTypes = {
  initialValues: PropTypes.any,
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
};

export default FormikForm;
