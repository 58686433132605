import { Component } from "react";
import { findDOMNode } from "react-dom";

class ScrollToError extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.submitCount !== this.props.submitCount &&
      !this.props.isValid
    ) {
      if (Object.keys(this.props.errors)[0] === this.props.name) {
        findDOMNode(this.props.inputRef.current).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }
  render() {
    return null;
  }
}

export default ScrollToError;
