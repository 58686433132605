export const ERR = "error";
export const UPLOADED = "uploaded";
export const FULFILLED = "fulfilled";
export const PENDING = "pending";

export const OTHER_DISTRIBUTOR = "other";

export const VALID_FILE_TYPE = [
  "pdf",
  "xls",
  "xlsx",
  "csv",
  "tsv",
  "txt",
  "numbers",
];

export const MAX_SIZE_IN_MB = 100;
export const MAX_REPORTS_SIZE = 1024 * 1024 * MAX_SIZE_IN_MB;

export const MOGUL_WHITELABEL = "usemogul";
export const MOGUL_REPORT_DOWNLOAD_LINK = "https://help.usemogul.com/en/articles/9043820-how-to-download-your-earnings-files-from-mogul"
