import React from "react";
import { flatMapDeep, get } from "lodash";
import Select from "react-select";
import styles from "./PartnerSelect.module.scss";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import Image from "../../../component/Image";
import { numberWithCommas } from "./helper";
import { CURRENT_DISTRIBUTOR_LABEL, DEFAULT_PARTNER } from "./constants";

const PartnerSelect = (props) => {
  const getOptions = () =>
    get(props, "availablePartners", []).map((o) => ({
      label: o === DEFAULT_PARTNER ? CURRENT_DISTRIBUTOR_LABEL : o,
      value: o,
    }));

  const getSelected = () =>
    props.selectedPartner && {
      label:
        get(props, "selectedPartner") === DEFAULT_PARTNER
          ? CURRENT_DISTRIBUTOR_LABEL
          : get(props, "selectedPartner"),
      value: get(props, "selectedPartner"),
    };

  const getMaxAmount = (val) => {
    const obj = flatMapDeep(get(props, `advanceData.${val}`));
    const maxWork = get(obj, obj.length - 1, {});
    if (get(props, "isCashFlow")) {
      return numberWithCommas(
        get(
          maxWork,
          `${Math.max(
            ...Object.keys(maxWork),
          )}.0.0.total_advance_green`,
        ),
      );
    }
    return numberWithCommas(
      get(maxWork, `${Math.max(...Object.keys(maxWork))}.0.0.total_advance`),
    );
  };

  const formatOptionLabel = (op) => (
    <div className={styles.option}>
      {op.value !== DEFAULT_PARTNER && (
        <Image
          src={get(props, `partnersOptions.${op.value}.logo`)}
          alt={op.value}
        />
      )}
      <span>{op.label}</span>
      <span className={styles.amount}>
        up to <span>${getMaxAmount(op.value)}</span>
      </span>
    </div>
  );

  return (
    <div className={styles.container}>
      <p className={styles.subTitle}>Want a bigger advance?</p>
      <p className={styles.title}>
        Get up to{" "}
        <span>
          ${numberWithCommas(Math.round(get(props, "advanceDifference", 0)))}{" "}
          more
        </span>{" "}
        by changing distributors
        {get(props, "availablePartners.length") === 2 &&
          ` to ${get(props, "availablePartners.1")}`}
        .
      </p>
      <Select
        classNamePrefix="distributor"
        styles={distributorSelectStyle}
        onChange={get(props, "setSelectedPartner")}
        options={getOptions()}
        placeholder={`Select a Distributor`}
        value={getSelected()}
        components={{ IndicatorSeparator: null }}
        formatOptionLabel={formatOptionLabel}
        aria-label="partner-select-input"
      />
      <button
        className={styles.linkBtn}
        onClick={get(props, "togglePartnerCompareTool")}
      >
        Want to compare all your offers?
      </button>
    </div>
  );
};

export default PartnerSelect;
