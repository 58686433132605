import React, { Component } from "react";
import Collapse from "@kunukn/react-collapse";
import LoginHeader from "../../../../../src/component/LoginHeader";
import Footer from "../../../../../src/component/Footer";
import Navigator from "../../../../../src/component/Navigation/Navigator";
import styles from "../Misc.module.scss";
import { ReactComponent as MoneySvg } from "../../../../assets/logos/money.svg";
import { ReactComponent as CustomizationSvg } from "../../../../assets/logos/customization.svg";
import { ReactComponent as OwnershipSvg } from "../../../../assets/logos/ownership.svg";
import { ReactComponent as PartnersSvg } from "../../../../assets/logos/partners.svg";

class WhyBeatBread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // index of all 19 faq items. Initially all items are collapsed
      faqVisible: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  }

  componentDidMount() {
    document.title = "beatBread: Why beatBread";
  }

  toggleFaqContent = (itemIndex) => {
    const updatedFaqs = this.state.faqVisible.map((val, index) => {
      if (index === itemIndex) {
        val = !val;
      }
      return val;
    });
    this.setState({ faqVisible: updatedFaqs });
  };

  render() {
    return (
      <>
        <div className="theme-bg-dark">
          <LoginHeader />
          <div className={styles.whyBeatbreadContainer}>
            <div className={styles.titleBeatbread}>
              <h2>Why beatBread is different</h2>
              <Navigator {...this.props} />
            </div>
            <div className={styles.titleBeatbread}>
              <h1>Why beatBread is different</h1>
            </div>
            <div className={styles.wrapperGrey}>
              <div className={`${styles.get} ${styles.content}`}>
                <div>
                  <MoneySvg />
                  Get an advance up to 8X your annual streaming income.
                </div>
                <div>
                  <OwnershipSvg />
                  Keep 100% ownership of your masters, publishing, touring,
                  merch and synch.
                </div>
                <div>
                  <CustomizationSvg />
                  Customize your contract term length, content, and monthly
                  income levels.
                </div>
                <div>
                  <PartnersSvg />
                  Use your own distributors, service, marketing and production
                  partners.
                </div>
              </div>
            </div>
            <div className={styles.wrapperBlue}>
              <div className={`${styles.content} ${styles.howitworks}`}>
                <h2>How it works.</h2>
                <iframe
                  src="https://www.youtube.com/embed/Z6Y41RymM7A"
                  title="get your advance"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <p>
                  <span>Get your advance in just a couple of days.</span>
                  <br />
                  1. Provide us your Spotify artist name
                  <br />
                  2. We gather your streaming and social data in minutes
                  <br />
                  3. You choose contract terms that are right for you
                  <br />
                  4. We synch with your distributor to confirm your data
                  <br />
                  5. You review and sign our standardized eContract
                  <br />
                  6. We wire your advance
                </p>
              </div>
            </div>
            <div className={`${styles.comparison} ${styles.content}`}>
              <h1>How beatBread is different</h1>
              <p>
                beatBread is not a distributor, marketing company, or a label.
                We are a platform that provides funding for musicians, while
                allowing them to choose their partners and keep 100% ownership.
              </p>
              <table className={styles.comparisonTable}>
                <thead>
                  <tr>
                    <th></th>
                    <th>beatBread</th>
                    <th>Major Labels</th>
                    <th>Full-service Distributors</th>
                    <th>Artist Services</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={styles.table_section}>
                    <td>Benefits to Artist</td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Make advances on catalog </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                  </tr>
                  <tr>
                    <td>Make advances on new releases </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>Sometimes </td>
                    <td>No </td>
                  </tr>
                  <tr>
                    <td>Give artist flexibility on marketing partners </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                  </tr>
                  <tr>
                    <td>Give artist choice of distribution partners </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                  </tr>
                  <tr>
                    <td>Provides distribution services </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                  </tr>
                  <tr>
                    <td>Provides marketing services </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>Sometimes </td>
                    <td className={styles.yesCell}>Yes </td>
                  </tr>
                  <tr className={styles.sep}>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr className={styles.table_section}>
                    <td>Typical Economic Terms</td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Let artist keep ownership of masters </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td className={styles.yesCell}>Yes </td>
                  </tr>
                  <tr>
                    <td>Let artist keep merch and physical income </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>NA </td>
                  </tr>
                  <tr>
                    <td>Artist keeps all synch and publishing income </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td>Sometimes </td>
                    <td>NA </td>
                  </tr>
                  <tr>
                    <td>Artist keeps all touring income </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>No </td>
                    <td className={styles.yesCell}>Yes </td>
                    <td>NA </td>
                  </tr>
                  <tr>
                    <td>Length of Term </td>
                    <td>1-5 years </td>
                    <td>Forever </td>
                    <td>1-10 years </td>
                    <td>1-3 years </td>
                  </tr>
                  <tr>
                    <td>Typical Royalty to Artist during term </td>
                    <td>60 to 90% </td>
                    <td>18-25% </td>
                    <td>60-85% </td>
                    <td>NA </td>
                  </tr>
                  <tr>
                    <td>Artist share of income after term </td>
                    <td>100% </td>
                    <td>18-25% </td>
                    <td>80-90% </td>
                    <td>100% </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={`${styles.spotlight} ${styles.jones}`}>
              <div className={styles.content}>
                <div className={`${styles.testimonial} ${styles.content}`}>
                  <p className={styles.quote}>
                    "I love being able to stay independent and fund my own album
                    rollout so I can maintain maximum leverage without selling
                    out my vision"
                  </p>
                  <p>
                    - Ajani Jones
                    <br />
                    65,000 monthly listeners
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles.spotlight} ${styles.ryno}`}>
              <div className={styles.content}>
                <div className={`${styles.testimonial} ${styles.rynoContent}`}>
                  <p className={styles.quote}>
                    "beatBread is the ultimate path for a DIY artist! It has
                    given me opportunity to manage my career and make music the
                    way I want!"
                  </p>
                  <p>
                    - RYNO
                    <br />
                    10,612 monthly listeners
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles.faqs} ${styles.content}`}>
              <h2>FAQs</h2>
              <button
                onClick={() => this.toggleFaqContent(0)}
                data-testid="faq1Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[0] && styles.active
                }`}
              >
                Who can get funding?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[0]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Artists who have released music on major DSPs (Spotify, Apple
                  Music, YouTube, Amazon, QQ) and have between 10,000 and
                  4,000,000 monthly listeners.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(1)}
                data-testid="faq2Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[1] && styles.active
                }`}
              >
                What if I don't repay my advance in the term?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[1]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Most artists will repay their bB advance right when the term
                  ends, or a few months before. That's how our data science team
                  models the deals we offer. But if your music doesn't generate
                  enough income to repay your advance during the term, we'll
                  collect until you are repaid, and no longer. We won't add any
                  balance to your advance if you are late, and we won't charge
                  any interest or penalties. And, if you aren't repaid a year or
                  three past your term (depending on your original deal, the
                  extra period will be no more than 3 more years), the term will
                  be over anyway. Tough luck for our investors, but best luck to
                  you... 100% of the streaming income will revert to you and you
                  are on your way.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(2)}
                data-testid="faq3Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[2] && styles.active
                }`}
              >
                What if there is an artist services company or indie label that
                I love and that loves me? Can I still get a beatBread advance?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[2]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  We love indie labels and artist service companies. They
                  provide great services that we don’t. If your prospective
                  indie label partner is willing, we can provide funding to you
                  and cut them in on the deal. If they help take your career to
                  the next level, it’s great for the investors that we bring to
                  the table. We are more than happy to work out an advance that
                  helps both you and your partners succeed.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(3)}
                data-testid="faq4Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[3] && styles.active
                }`}
              >
                What if I get a label offer that is just too good to pass up in
                the middle of my term?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[3]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Great question. We don’t want to hold you back. Our
                  standardized contract includes a "buyout" clause that will let
                  you buy out of the rest of the term with those big label
                  bucks. Your buyout price is based on a formula that’s set
                  ahead of time so there’s no doubt or need to play negotiation
                  games. It’s based on how much you’ve repaid, how much revenue
                  you’ve generated in the term, and how much time you have left
                  on your term.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(4)}
                data-testid="faq5Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[4] && styles.active
                }`}
              >
                Do I need to release new music to receive funding?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[4]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  No. We are happy to advance funds against your existing
                  catalog, but many artists will find that the funding amounts
                  we can provide are significantly larger if they plan to
                  release new music in the next 6 to 12 months.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(5)}
                data-testid="faq6Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[5] && styles.active
                }`}
              >
                Can I stay with my current distributor?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[5]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Usually, yes. We will never tell you that you have to work
                  with one and only one distributor. In some cases, we may ask
                  you to move your catalog if your distributor isn’t able to
                  meet our criteria, but we work with most of the leading
                  distributors and DIY platforms. And, if we do have to move
                  your catalog, our staff is skilled, deeply experienced and
                  knowledgeable on the ins and outs of digital distribution,
                  metadata and playlisting.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(6)}
                data-testid="faq7Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[6] && styles.active
                }`}
              >
                When do I get paid?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[6]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  A large share of your advance will be paid within a week of
                  signing your contract. The remainder will be paid when you
                  release new music, if its covered in the deal. Any "flow
                  through" income you are due before your advance is repaid will
                  be paid within 7 business days after we receive it from your
                  distributor.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(7)}
                data-testid="faq8Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[7] && styles.active
                }`}
              >
                How do you decide how big an advance to give?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[7]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Two things determine the size of your advance. First, your
                  data. Your streaming data and a whole lot more data beyond
                  that is used to make an estimate of your future earnings.
                  Second, your advance is driven the terms you choose. You are
                  in control. You choose if your deal will include some or all
                  of your catalog, and how many unreleased tracks it will
                  include (or if it will include no new tracks at all). You
                  choose how many years you want to share income with our
                  investors. Finally, you choose how much of your streaming
                  income you need to keep during the term, even before your
                  advance is repaid. All those tradeoffs impact the size of your
                  advance, and all of them are up to you.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(8)}
                data-testid="faq9Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[8] && styles.active
                }`}
              >
                Is this a loan? Will it effect my credit?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[8]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  No, and No. Our advances are structured as a purchase of a
                  limited portion of your revenues for a limited time. They
                  aren't loans and they won't impact your credit.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(9)}
                data-testid="faq10Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[9] && styles.active
                }`}
              >
                Can I get funded for songs on which I’m only a collaborator?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[9]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  In many situations, yes. We’ll just need to know what
                  percentage of master income you get from each track and be
                  properly set up with the distribution company handling that
                  track.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(10)}
                data-testid="faq11Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[10] && styles.active
                }`}
              >
                Does beatBread offer any promotional services?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[10]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Nope. BeatBread is a platform that provides funding. We
                  simplify the "money part" of being a professional musician and
                  help artists keep control of their art and their careers. Who
                  you work with for marketing is up to you. We believe in
                  artists, independence, and, most of all, we believe in
                  independent artists.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(11)}
                data-testid="faq12Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[11] && styles.active
                }`}
              >
                Who are you guys?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[11]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  beatBread is a lucky combination of music business
                  professionals, data scientists, software developers and
                  financial executives. We not only love music, we want the
                  people who create music to have more control, more ownership
                  and more flexibility to make more great music, on their own
                  terms.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(12)}
                data-testid="faq13Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[12] && styles.active
                }`}
              >
                Advancing money to artists? Isn’t that a sure way to lose all
                your money really fast?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[12]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  No, we don't think so. We took hundreds of millions of data
                  points on tens of thousands of artists to build some really
                  smart algorithms that predict an artist’s future earnings
                  because we think we can make smart bets on artists, we can
                  also offer better terms (higher royalties, faster timeframe to
                  get back to 100% control of revenues) than a traditional label
                  deal.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(13)}
                data-testid="faq14Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[13] && styles.active
                }`}
              >
                Should I send you my music?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[13]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  We are music fans and are always on the lookout for
                  recommendations for our personal playlists, but please don’t
                  bother preparing files for us to listen to. We rely on data to
                  tell us what we can fund and how much we can offer you. We
                  save money that would otherwise go to a huge staff of scouts
                  and music industry insiders and plough those savings into the
                  money we give to artists. If you have awesome music and fewer
                  than 10,000 monthly listeners, we’re probably not right for
                  you. A more traditional funding route may be the right fit,
                  and that’s perfectly OK.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(14)}
                data-testid="faq15Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[14] && styles.active
                }`}
              >
                Do I need to have a manager in order to receive funding?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[14]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  No, if you have been successful on your own, we believe you
                  can continue to do that.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(15)}
                data-testid="faq16Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[15] && styles.active
                }`}
              >
                What if I don't own my music?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[15]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  It depends. We may be able to figure out a way to work with
                  your label or distributor. Let’s talk.​
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(16)}
                data-testid="faq17Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[16] && styles.active
                }`}
              >
                Which genres qualify?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[16]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  We work with any and all genres. Classical, Trap, Waka, Jazz,
                  Christian Pop, Prog Rock, Canadian Country, K-Pop, Hip Hop,
                  Dance, Pop, Assyrian Folk, Latin Rap, Sufi, Deep Trance,
                  Ranchera, Bhajan…Norwegian Death Metal… you name it….we are
                  open to making an advance if the data is right.​
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(17)}
                data-testid="faq18Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[17] && styles.active
                }`}
              >
                Can I receive additional funding if I’m going to release new
                music before my term is complete?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[17]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  Absolutely – just contact us for a new offer on additional
                  releases... You can take your funding in smaller chunks to
                  keep your options open if you like.
                </p>
              </Collapse>

              <button
                onClick={() => this.toggleFaqContent(18)}
                data-testid="faq19Click"
                className={`${styles.collapsible} ${
                  this.state.faqVisible[18] && styles.active
                }`}
              >
                beatBread? Do you attack baguettes? Seriously, what’s with the
                name?
              </button>
              <Collapse
                transition={`height 200ms cubic-bezier(.4, 0, .2, 1)`}
                isOpen={this.state.faqVisible[18]}
              >
                <p className={`${styles.collapsibleContent}`}>
                  We get asked that a lot. "beat" is like beats in music.
                  "bread" is like "dough", money, moolah, bucks, ducats,
                  shekels, benjamins. Money for music...beatBread. See how we
                  did that?
                </p>
              </Collapse>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default WhyBeatBread;
