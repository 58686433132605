import React from "react";
import { get } from "lodash";
import styles from "./ActiveArtistRedirection.module.scss";
import request from "../../../utils/request";
import {
  ACCEPT_OFFER,
  ADMIN_DASHBOARD,
  API_URL,
  ARTIST_API,
  CONFIRM_OWNERSHIP,
  ERROR_SERVER_DOWN,
  FUNDING,
  GENERATING_OFFERS,
  GET_EVEN_MORE,
  HOMEPAGE_CONTENT,
  INVITE_BIDDERS,
  ME,
  NAVIGATION_STATUS_API,
  OFFER_SUMMARY,
  POSTED_TO_NETWORK,
  POST_CONFIRMATION,
  PUBLISHING_REPORTS,
  SEND_REPORTS,
  SET_TARGET_ADVANCE,
  UPCOMING_WORKS,
  USER_API,
  WAITING_ROOM,
  WELCOME_BACK,
} from "../constants";
import {
  STATUS_BIG,
  STATUS_ERR,
  STATUS_FAILED,
  STATUS_NEW,
  STATUS_PENDING,
  STATUS_SMALL,
  STATUS_TOO_BIG,
  STATUS_TOO_SMALL,
} from "../Dashboard/constants";
import Loader from "../../../component/Loader";
import StorageService from "../../../utils/StorageService";
import { logout, setDataDogUser } from "../../../utils/Helper";
import {
  CHOOSE_PATH_FIELDS,
  FAST_FLOW_STEPS,
} from "../Auth/LinkAnotherArtist/constant";

class ActiveArtistRedirection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.artistActivityRedirection();
  }

  getChoosePathRedirectScreen(navigationData) {
    if (
      get(navigationData, "value.data.publishingRequestId") &&
      !get(navigationData, "value.data.requestId")
    ) {
      return PUBLISHING_REPORTS;
    } else {
      return SEND_REPORTS;
    }
  }

  artistActivityRedirection = () => {
    const data = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${NAVIGATION_STATUS_API}`;
    const requestMe = `${API_URL}${USER_API}${ME}`;
    const promises = [request(requestURL, data)];
    get(this.props, "location.state.fromLogin") &&
      promises.push(request(requestMe, data));

    Promise.allSettled(promises)
      .then((res) => {
        const [navigationData, userData] = res;
        StorageService.set("role", get(userData, "value.data.role"));
        get(this.props, "location.state.fromLogin") &&
          StorageService.set(
            "activeArtist",
            get(userData, "value.data.activeArtist.name"),
            { id: get(userData, "value.data.activeArtist._id") },
          );
        if (
          get(userData, "status") === "fulfilled" &&
          get(userData, "value.status") &&
          get(userData, "value.data.role") === "admin"
        ) {
          const token = StorageService.get("jwtToken");
          StorageService.set("customToken", token, {
            hash: false,
          });
          this.props.history.push(ADMIN_DASHBOARD);
          return true;
        }
        get(this.props, "location.state.fromLogin") &&
          setDataDogUser(
            get(userData, "value.data.activeArtist._id"),
            get(userData, "value.data.activeArtist.name"),
            get(userData, "value.data.email.value"),
          );
        if (
          get(userData, "status") === "fulfilled" &&
          get(userData, "value.status") &&
          get(userData, "value.data.role") === "subAdmin"
        ) {
          const role = get(userData, "value.data.role");
          const token = StorageService.get("jwtToken");
          StorageService.set("customToken", token, {
            hash: false,
          });
          StorageService.set("role", role);
          this.props.history.push(HOMEPAGE_CONTENT);
          return true;
        }
        if (
          get(navigationData, "status") === "fulfilled" &&
          get(navigationData, "value.status")
        ) {
          if (
            get(navigationData, "value.data.isFastFlow") &&
            [
              FAST_FLOW_STEPS.INCOME_VERIFIED,
              FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
              FAST_FLOW_STEPS.PENDING,
            ].indexOf(get(navigationData, "value.data.artistStatus")) !== -1
          ) {
            if (
              get(navigationData, "value.data.artistStatus") ===
                FAST_FLOW_STEPS.PENDING &&
              !get(navigationData, "value.data.isJwtTokenGenerated")
            ) {
              this.props.history.push(WAITING_ROOM);
            } else if (get(navigationData, "value.data.reports")) {
              if (get(navigationData, "value.data.confirmOwnership")) {
                this.props.history.push(CONFIRM_OWNERSHIP);
              } else {
                this.props.history.push(GENERATING_OFFERS);
              }
            } else if (
              get(navigationData, "value.data.publishingRequestId") &&
              !get(navigationData, "value.data.requestId")
            ) {
              this.props.history.push(PUBLISHING_REPORTS);
            } else {
              this.props.history.push(SEND_REPORTS);
            }
          } else if (
            [
              STATUS_PENDING,
              STATUS_BIG,
              STATUS_SMALL,
              STATUS_NEW,
              STATUS_FAILED,
              STATUS_ERR,
              STATUS_TOO_BIG,
              STATUS_TOO_SMALL,
            ].indexOf(get(navigationData, "value.data.artistStatus")) !== -1
          ) {
            this.props.history.push(WAITING_ROOM);
          } else if (
            !get(navigationData, "value.data.offer_approved") &&
            !get(navigationData, "value.data.finishingTouch")
          ) {
            const path =
              get(navigationData, "value.data.choosePath") ===
              CHOOSE_PATH_FIELDS().OPTIONS[0].value
                ? this.getChoosePathRedirectScreen(navigationData)
                : FUNDING;
            this.props.history.push({
              pathname: path,
              state: {
                fromDashboard: true,
                navigationData: get(navigationData, "value.data"),
              },
            });
          } else if (
            !get(navigationData, "value.data.offer_approved") &&
            get(navigationData, "value.data.finishingTouch")
          ) {
            this.props.history.push({
              pathname: GENERATING_OFFERS,
              state: { fromDashboard: true },
            });
          } else if (!get(navigationData, "value.data.isMarketPlace")) {
            if (
              get(navigationData, "value.data.adjustTerms") &&
              get(navigationData, "value.data.offerSummary")
            ) {
              this.props.history.push({
                pathname: ACCEPT_OFFER,
                state: { fromDashboard: true },
              });
            } else if (
              get(navigationData, "value.data.offerSummary") &&
              !get(navigationData, "value.data.adjustTerms") &&
              !get(navigationData, "value.data.isAccepted")
            ) {
              this.props.history.push({
                pathname: OFFER_SUMMARY,
                state: { fromDashboard: true },
              });
            } else {
              this.props.history.push({
                pathname: WELCOME_BACK,
                state: { fromDashboard: true },
              });
            }
          } else {
            if (get(navigationData, "value.data.artistRegistration")) {
              this.props.history.push(POSTED_TO_NETWORK);
            } else if (get(navigationData, "value.data.postConfirmation")) {
              this.props.history.push(POST_CONFIRMATION);
            } else if (get(navigationData, "value.data.inviteBidder")) {
              this.props.history.push(POST_CONFIRMATION);
            } else if (get(navigationData, "value.data.artistProfile")) {
              this.props.history.push(INVITE_BIDDERS);
            } else if (get(navigationData, "value.data.targetOffer")) {
              this.props.history.push(UPCOMING_WORKS);
            } else if (get(navigationData, "value.data.getEvenMore")) {
              this.props.history.push(SET_TARGET_ADVANCE);
            } else {
              this.props.history.push(GET_EVEN_MORE);
            }
          }
          return true;
        } else if (
          get(navigationData, "status") === "fulfilled" &&
          !get(navigationData, "value.status")
        ) {
          logout();
          window.location.reload();
          return false;
        }
        this.props.history.push(ERROR_SERVER_DOWN);
      })
      .catch((err) => {});
  };
  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.leftBox}></div>
          <div className={styles.rightBox}>
            <div className={styles.headerBox}></div>
          </div>
        </div>
        {this.state.loading && <Loader isOverlayBlur />}
      </>
    );
  }
}

export default ActiveArtistRedirection;
