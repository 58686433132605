import styles from "../../styles/global.scss";
export const sliderStyle = {
  handle: {
    height: 20,
    width: 20,
    background:  `radial-gradient(${styles.sliderColor} 40%, #ffffff 40%)`,
    marginTop: -7,
    marginLeft: 2,
    borderColor: "transparent",
    boxShadow: `0px 3px 6px #6148D948`,
    border: `3px solid ${styles.sliderColor}`
  },
  track: { backgroundColor: styles.sliderColor , height:8 },
  dot: {
    bottom: -8,
    marginLeft: -6,
    height: 17,
    width: 17,
    borderRadius: 10,
    border:`3px solid ${styles.light}`,
    backgroundColor: '#ffffff',
  },
  activeDot: { backgroundColor: '#ffffff', border: `3px solid ${styles.sliderColor}` },
  rail: {
    height: 8
  }
};
