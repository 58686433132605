import React, { Component } from "react";
import { setTitle } from "../../../../component/ThemeManager/helper";
import styles from "./UpcomingProjects.module.scss";
import classes from "../../YourAdvance/YourAdvance.module.scss";
import socialStyles from "./SocialNetworks.module.scss";
import {
  IMG_EXTENSIONS_REGEX,
  IMG_MAX_SIZE,
  RANGE_MAX,
  RANGE_MIN,
  RANGE_STEP,
} from "../../MyAccount/constants";
import AvatarEditor from "react-avatar-editor";
import dataURItoBlob from "../../MyAccount/dataURItoBlob";
import StorageService from "../../../../utils/StorageService";
import HorizontalMenu from "../../../../component/HorizontalMenu";
import LoginHeader from "../../../../component/LoginHeader";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { CAMERA } from "../../../../component/MaterialIcons/constants";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import Image from "../../../../component/Image";
import { ReactComponent as EditIcon } from "../../../../assets/logos/edit-icon.svg";
import {
  API_URL,
  ARTIST_API,
  ARTIST_BIO_MAX_CHARACTERS,
  INVITE_BIDDERS,
  READ_ONLY_TEXT_LENGTH,
  SAVE_UPCOMING_PROJECTS,
  SOCIAL_NETWORK,
  TOP_TRACK,
  UPDATE_MARKETING_STATUS,
  USER_API,
} from "../../constants";
import request from "../../../../utils/request";
import { GetErrorMessage } from "../../helper";
import { toast } from "react-toastify";
import _, { get } from "lodash";
import { ReactComponent as InstaIcon } from "../../../../img/SocialMediaIcons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../../../img/SocialMediaIcons/youtube.svg";
import { ReactComponent as SpotifyIcon } from "../../../../img/SocialMediaIcons/spotify.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/logos/trash-icon.svg";
import { ReactComponent as UserIcon } from "../../../../img/user.svg";
import ReactRange from "../../../../component/Range/ReactRange";
import ReactModal from "react-modal";
import FormField from "../../../../component/FormField/FormField";
import { Form, Formik } from "formik";
import {
  ADD,
  ALPHA_WITH_APOSTROPHE_HYPHEN,
  COLLABORATOR_TYPES,
  DEFAULT_SOCIAL_COUNT,
  DELETE,
  INITIAL_PROJECT_COLLABORATOR,
  PROJECT_COLLA_MAX_VIEW_CHAR,
  PROJECT_DETAIL_MAX_VIEW_CHAR,
  STEP_ONE,
  STEP_TWO,
} from "./constant";
import {
  getAdvanceOptionalCount,
  numberWithCommas,
} from "../../YourAdvance/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import Loader from "../../../../component/Loader";
import * as Yup from "yup";
import { GuaranteedModal, nothanksNavigationStatusUpdate } from "../helper";

class UpcomingProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offerStage: {},
      profileImg: "",
      uploadedImg: "",
      artistName: "",
      bio: "",
      bioModal: false,
      isEditBio: false,
      typeError: false,
      sizeError: false,
      imageModal: false,
      imgScale: RANGE_MIN,
      seeWhyModalOpen: false,
      projectDetails: "",
      projectCollaborators: INITIAL_PROJECT_COLLABORATOR,
      addProjectModal: false,
      garunteeOfferModal: false,
      socialCounts: DEFAULT_SOCIAL_COUNT,
      projectDetailStep: 1,
      showMarketPopup: false,
    };
  }
  componentDidUpdate() {
    setTitle("Artist Profile", this.context);
  }

  componentDidMount() {
    this.getDataForArtist();
  }

  getDataForArtist = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SOCIAL_NETWORK}?isSocialFollowersNeeded=true`;
    const topTrackRequestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;

    const promises = [request(requestURL, data)];
    promises.push(request(topTrackRequestUrl, data));
    Promise.allSettled(promises)
      .then((res) => {
        this.setState({ loading: false });
        const [socialNetworkData, offerData] = res;
        if (
          get(socialNetworkData, "status") === "fulfilled" &&
          get(socialNetworkData, "value.status")
        ) {
          this.setState({
            bio: get(socialNetworkData.value, "data.bio", ""),
            socialCounts: get(
              socialNetworkData.value,
              "data.socialCounts",
              DEFAULT_SOCIAL_COUNT,
            ),
          });
        }
        if (
          get(offerData, "status") === "fulfilled" &&
          get(offerData, "value.status")
        ) {
          const projectAndCollaborators = get(
            offerData.value,
            "data.projectAndCollaborators",
            {},
          );
          const projectCollaborators = projectAndCollaborators
            ?.projectCollaborators?.length
            ? projectAndCollaborators?.projectCollaborators
            : INITIAL_PROJECT_COLLABORATOR;
          const projectDetails = projectAndCollaborators?.projectDetails || "";
          this.setState({
            offerStage: offerData.value.data.offerStage,
            offer: get(offerData.value, "data.offer", {}),
            artistName: get(offerData.value, "data.name", ""),
            profileImg: get(offerData.value, "data.profilePicture[0].url", ""),
            projectDetails,
            projectCollaborators,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  artistBioValidationSchema = Yup.object().shape({
    bio: Yup.string()
      .label("Artist Bio")
      .required("Please enter Artist Bio")
      .max(
        ARTIST_BIO_MAX_CHARACTERS,
        `Artist Bio cannot exceed ${ARTIST_BIO_MAX_CHARACTERS} characters`,
      ),
  });

  upcomingWorkValidationSchema = Yup.object().shape({
    projectDetails: Yup.string()
      .required("Please enter Upcoming project details")
      .max(
        ARTIST_BIO_MAX_CHARACTERS,
        `Project details cannot exceed ${ARTIST_BIO_MAX_CHARACTERS} characters`,
      ),
    projectCollaborators: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .matches(ALPHA_WITH_APOSTROPHE_HYPHEN, "Please enter a valid name"),
        role: Yup.string().required("Role is required"),
      }),
    ),
  });

  getArtistBio = () => {
    let bioText = this.state.bio;
    bioText = bioText.substring(0, READ_ONLY_TEXT_LENGTH);
    return (
      <p className={socialStyles.infoProfile}>
        {" "}
        {bioText}{" "}
        {this.state.bio.length > READ_ONLY_TEXT_LENGTH && (
          <span
            className={socialStyles.readMoreText}
            data-testid="readBioModal"
            onClick={() => this.handleBioModalOpen(false)}
          >
            Read More
          </span>
        )}
      </p>
    );
  };

  saveArtistBio = (values) => {
    if (this.state.isEditBio) {
      const data = {
        method: "POST",
        body: {
          bio: values.bio,
        },
      };
      const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SOCIAL_NETWORK}`;
      request(requestURL, data)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setState({
              bioModal: false,
              isEditBio: false,
              bio: values.bio,
            });
            toast.success(get(res, "message"));
            return true;
          }
          toast.error(get(res, "message"));
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    } else {
      return this.setState({ isEditBio: false, bioModal: false });
    }
  };

  saveImageAPICall = (blob, image) => {
    const formData = new FormData();
    formData.append("profilePicture", blob);
    const data = {
      method: "PUT",
      body: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SOCIAL_NETWORK}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({
          loading: false,
          imgScale: RANGE_MIN,
          imageModal: false,
        });
        if (res.status) {
          toast.success(get(res, "message"));
          this.setState({ profileImg: image, uploadedImg: "" });
          StorageService.set("profileImg", res.data.profilePicture);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({
          loading: false,
          imgScale: RANGE_MIN,
          imageModal: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  validateSteps = (nextStep, validateForm, handleSubmit) => {
    validateForm().then((val) => {
      if (this.state.projectDetailStep === STEP_ONE) {
        !_.size(val.projectDetails)
          ? this.setState({ projectDetailStep: nextStep })
          : handleSubmit();
      } else {
        this.setState({ projectDetailStep: nextStep });
      }
    });
  };

  updateTotalCollaborators = (
    type,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    index,
  ) => {
    const projectCollaborators = [...values.projectCollaborators];
    if (type === "add") {
      validateForm().then((val) => {
        !_.size(val.projectCollaborators)
          ? projectCollaborators.push({ name: "", role: "" })
          : handleSubmit();
        setFieldValue("projectCollaborators", projectCollaborators);
      });
    }
    if (type === "delete" && projectCollaborators.length) {
      projectCollaborators.splice(index, 1);
      setFieldValue("projectCollaborators", projectCollaborators);
    }
  };

  saveUpcomingWorks = (
    values,
    fromikCallbackObj,
    isDeleteWorks = false,
    isSkipStepTwo = false,
  ) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: {
        projectDetails: values.projectDetails,
        projectCollaborators: values.projectCollaborators,
      },
    };
    if (isSkipStepTwo) {
      data.body = {
        projectDetails: values.projectDetails,
      };
    }
    if (isDeleteWorks) data.body = {};
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SAVE_UPCOMING_PROJECTS}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const updateState = {
            addProjectModal: false,
            projectDetailStep: STEP_ONE,
            projectDetails: values.projectDetails,
          };
          if (!isSkipStepTwo) {
            updateState.projectCollaborators = values.projectCollaborators;
          }
          if (isDeleteWorks) {
            updateState.projectCollaborators = INITIAL_PROJECT_COLLABORATOR;
            updateState.projectDetails = "";
          }
          this.setState({ ...updateState });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  updateUpcomingWorkStatus = () => {
    this.setState({ loading: true });
    const payload = {
      artistProfile: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(INVITE_BIDDERS);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  navigationStatusUpdate = nothanksNavigationStatusUpdate.bind(this);

  handleSaveImage = () => {
    this.setState({ loading: true });
    if (this.editor) {
      const image = this.editor.getImage().toDataURL();

      const blob = dataURItoBlob(image);
      this.saveImageAPICall(blob, image);
    }
  };

  handleBioModalOpen = (isEdit) => {
    this.setState({ bioModal: true, isEditBio: isEdit });
  };

  handleBioModalCancel = () => {
    this.setState({ bioModal: false, isEditBio: false });
  };

  onChangeFileHandler = (event) => {
    this.setState({ typeError: false, sizeError: false });
    const file = event.target.files;

    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file);
    }

    if (validateFile) {
      const reader = new FileReader();
      reader.readAsDataURL(validateFile[0]);
      reader.onloadend = (e) => {
        this.setState({
          uploadedImg: e.target.result,
          imageModal: true,
        });
      };
    }
    event.target.value = null;
  };

  checkImageValidation = (file) => {
    const fileType = IMG_EXTENSIONS_REGEX;

    if (!fileType.test(file[0].type)) {
      this.setState({ typeError: true });
      return false;
    }

    if (file[0].size > IMG_MAX_SIZE) {
      this.setState({ sizeError: true });
      return false;
    }
    return file;
  };

  onScaleChange = (values) => {
    this.setState({ imgScale: values[0] });
  };

  setEditorRef = (editor) => (this.editor = editor);

  openSeeWhyModal = () => {
    this.setState({ seeWhyModalOpen: true });
  };

  toggleAddProjectModal = () => {
    this.setState({
      addProjectModal: !this.state.addProjectModal,
      projectDetailStep: STEP_ONE,
    });
  };

  toggleMarketPopup = () => {
    this.setState({ showMarketPopup: !this.state.showMarketPopup });
  };

  handleCancel = () => {
    this.setState({
      seeWhyModalOpen: false,
      imageModal: false,
      imgScale: RANGE_MIN,
    });
  };

  seeWhyModal = () => (
    <ReactModal
      isOpen={this.state.seeWhyModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.seeWhyModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.header}>
        <p className={styles.title}>
          Our platform gathers offers from potential investors in a sealed offer
          process
        </p>
      </div>
      <div className={styles.seeWhyContainer}>
        <p>
          Investors make one best and final offer on all of, or a portion of
          your deal.
        </p>
        <p>
          We do this to ensure that potential investors can’t game the process
          or collude to give you less. Research has shown that ‘sealed bids’
          generate higher prices for sellers than open auctions.
        </p>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.modalBtn}
            data-testid="saveAdjustTerms"
            onClick={this.handleCancel}
          >
            Continue
          </button>
        </div>
      </div>
    </ReactModal>
  );

  bioModal = () => (
    <ReactModal
      isOpen={this.state.bioModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleBioModalCancel}
      className={socialStyles.bioModal}
      overlayClassName={socialStyles.modalOverlay}
    >
      <div
        className={socialStyles.header}
      >{`${this.state.artistName} Bio`}</div>
      <div className={socialStyles.chatForm}>
        <Formik
          initialValues={{ bio: this.state.bio }}
          enableReinitialize
          validationSchema={this.artistBioValidationSchema}
          onSubmit={this.saveArtistBio}
          data-testid="bioFormRender"
        >
          {({ values, dirty }) => (
            <Form>
              {this.state.isEditBio ? (
                <div className="form-group form-group-m0 mb-0">
                  <FormField
                    name="bio"
                    as="textarea"
                    placeholder="Artist Bio."
                    className={socialStyles.textarea}
                    maxCharacters={ARTIST_BIO_MAX_CHARACTERS}
                    value={values.bio}
                  />
                </div>
              ) : (
                <div className={socialStyles.bioDetails}>{this.state.bio}</div>
              )}
              <div className={socialStyles.modalBtnContainer}>
                <button
                  disabled={this.state.isEditBio && !dirty}
                  className={socialStyles.modalBtn}
                  type="submit"
                >
                  Done
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  );

  imageModal = () => (
    <ReactModal
      isOpen={this.state.imageModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={socialStyles.imageModal}
      overlayClassName={socialStyles.modalOverlay}
    >
      <AvatarEditor
        ref={this.setEditorRef}
        image={this.state.uploadedImg}
        width={200}
        height={200}
        scale={this.state.imgScale}
        borderRadius={150}
      />
      <ReactRange
        values={[this.state.imgScale]}
        max={RANGE_MAX}
        min={RANGE_MIN}
        step={RANGE_STEP}
        onChange={this.onScaleChange}
        thumbText={["Scale", ""]}
      />
      <div className={socialStyles.modalBtnContainer}>
        <button
          className={socialStyles.modalBtn}
          data-testid="saveImage"
          onClick={this.handleSaveImage}
        >
          Save
        </button>
        <button
          className={`${socialStyles.modalBtn} ${socialStyles.cancelBtn}`}
          data-testid="cancelImage"
          onClick={this.handleCancel}
        >
          Cancel
        </button>
      </div>
    </ReactModal>
  );

  upcomingProjectModal = () => (
    <ReactModal
      isOpen={this.state.addProjectModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.toggleAddProjectModal}
      className={styles.projectModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.header}>
        {this.state.projectDetailStep === STEP_ONE
          ? "Details for Potential Investors"
          : "Collaborators & Partners (optional)"}
      </div>
      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            projectDetails: this.state.projectDetails,
            projectCollaborators: this.state.projectCollaborators,
          }}
          enableReinitialize
          validationSchema={this.upcomingWorkValidationSchema}
          onSubmit={this.saveUpcomingWorks}
          data-testid="upcomingWorksRender"
        >
          {({ values, validateForm, handleSubmit, setFieldValue }) => (
            <Form>
              <div
                className={`${styles.worksContainer} ${
                  this.state.projectDetailStep === STEP_ONE
                    ? styles.stepOne
                    : ""
                }`}
              >
                {this.state.projectDetailStep === STEP_ONE ? (
                  <FormField
                    name="projectDetails"
                    data-testid="projectDetails"
                    as="textarea"
                    placeholder="What would you like investors to know? (eg. upcoming tours, details on next project, new collaborators, etc.)"
                    className={socialStyles.textarea}
                    maxCharacters={ARTIST_BIO_MAX_CHARACTERS}
                    value={values.projectDetails}
                  />
                ) : (
                  <>
                    {values.projectCollaborators.map((ele, index) => (
                      <>
                        <div
                          className={styles.partnersContainer}
                          key={`collaborator-${index}`}
                        >
                          <div className={styles.partnersInputs}>
                            <div className={styles.fields}>
                              <div
                                className={`form-group ${styles.fieldInputs}`}
                              >
                                <FormField
                                  name={`projectCollaborators.${index}.name`}
                                  placeholder="Add Collaborator"
                                  icon={<UserIcon />}
                                  as="icon"
                                  iconComponent
                                  style={`${styles.fieldStyle}`}
                                  isBoxInput
                                />
                              </div>
                              <div
                                className={`form-group ${styles.fieldInputs}`}
                              >
                                <FormField
                                  name={`projectCollaborators[${index}].role`}
                                  as="select"
                                  placeholder="Select Role"
                                  options={COLLABORATOR_TYPES}
                                  isBoxInput
                                  menuPosition
                                  components={{ IndicatorSeparator: null }}
                                  classNamePrefix="selectField"
                                />
                              </div>
                            </div>
                            {values.projectCollaborators.length > 1 && (
                              <div className={styles.deletePartner}>
                                <DeleteIcon
                                  data-testid={`delete-partner-${index}`}
                                  onClick={() =>
                                    this.updateTotalCollaborators(
                                      DELETE,
                                      values,
                                      setFieldValue,
                                      validateForm,
                                      handleSubmit,
                                      index,
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                    {this.state.projectDetailStep === STEP_TWO &&
                      values.projectCollaborators.length < 10 && (
                        <div className="d-flex">
                          <span
                            className={styles.plusIcon}
                            onClick={() =>
                              this.updateTotalCollaborators(
                                ADD,
                                values,
                                setFieldValue,
                                validateForm,
                                handleSubmit,
                              )
                            }
                            data-testid="add-new-btn"
                          >
                            +
                          </span>
                          <span>Add a new Collaborator</span>
                        </div>
                      )}
                  </>
                )}
              </div>

              <div className={styles.modalFooter}>
                <div className={styles.stepContainer}>
                  <span
                    className={`${styles.circle} ${styles.active} ${
                      this.state.projectDetailStep === STEP_TWO
                        ? styles.cursorPoint
                        : ""
                    }`}
                    data-testid="step-one-link"
                    onClick={() =>
                      this.validateSteps(STEP_ONE, validateForm, handleSubmit)
                    }
                  ></span>
                  <span
                    className={`${styles.circle} ${
                      this.state.projectDetailStep === STEP_TWO
                        ? styles.active
                        : ""
                    }`}
                  ></span>
                </div>

                <div className={styles.btnWrapper}>
                  {this.state.projectDetailStep === STEP_ONE ? (
                    <button
                      className={styles.modalBtn}
                      type="button"
                      data-testid="next-btn"
                      onClick={() =>
                        this.validateSteps(STEP_TWO, validateForm, handleSubmit)
                      }
                    >
                      Next
                    </button>
                  ) : (
                    <>
                      <div className={styles.addNSkip}>
                        <button
                          className={styles.secondaryBtn}
                          type="button"
                          data-testid="skip-step-two-btn"
                          onClick={() =>
                            this.saveUpcomingWorks(
                              values,
                              undefined,
                              false,
                              true,
                            )
                          }
                        >
                          Skip
                        </button>
                      </div>
                      <button className={styles.modalBtn} type="submit">
                        Confirm Details
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  );

  renderUpcomingProject = () => {
    let projectDetails = this.state.projectDetails || "";
    const collaborators =
      this.state.projectCollaborators &&
      this.state.projectCollaborators.map((obj) => {
        return obj.name;
      });
    projectDetails = projectDetails.substring(0, PROJECT_DETAIL_MAX_VIEW_CHAR);
    const collaboratorsString = collaborators
      ? collaborators.join(", ").substring(0, PROJECT_COLLA_MAX_VIEW_CHAR)
      : "";
    return (
      <div className={styles.upcomingProject}>
        <div className={styles.header}>
          Upcoming Projects, Partners & Collaborators
        </div>
        {this.state.projectDetails ? (
          <div className={styles.projectSummary}>
            <span className={styles.projectText}>
              {projectDetails}
              {this.state.projectDetails.length >
                PROJECT_DETAIL_MAX_VIEW_CHAR && "..."}
            </span>
            {!!collaboratorsString.length && (
              <>
                <span className={styles.partnerLabel}>
                  {" "}
                  Partners & Key Collaborators:{" "}
                </span>
                <span className={styles.partnerText}>
                  {collaboratorsString}
                  {collaboratorsString.length > PROJECT_COLLA_MAX_VIEW_CHAR &&
                    "..."}
                </span>
              </>
            )}
            <div className={styles.iconContainer}>
              <EditIcon
                onClick={this.toggleAddProjectModal}
                data-testid="edit-upcoming-works"
                className={styles.primary}
              />
              <DeleteIcon
                onClick={() => this.saveUpcomingWorks({}, {}, true, false)}
                data-testid="delete-upcoming-works"
              />
            </div>
          </div>
        ) : (
          <button
            className={`${styles.secondary}`}
            data-testid="add-projects"
            onClick={this.toggleAddProjectModal}
          >
            PROVIDE DETAIL
          </button>
        )}
      </div>
    );
  };

  render() {
    const { socialCounts, offer } = this.state;
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu
          offerStage={this.state.offerStage}
          getStatus={this.getSidebarStatus}
          {...this.props}
        />
        <div className={classes.funnelContainer}>
          <LoginHeader headerTitle="Artist Profile & Upcoming Projects" />
          <div className={styles.pageContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  <div className={styles.detailContainer}>
                    <div className={styles.profileContainer}>
                      <div className={styles.artistContainer}>
                        <div className={socialStyles.spotifyProfile}>
                          <div className={socialStyles.profileContainer}>
                            <div className={styles.artistProfile}>
                              <p className={styles.title}>
                                Your Artist Profile
                              </p>
                              <p
                                className={styles.editProfileLink}
                                data-testid="editBioModal"
                                onClick={() => this.handleBioModalOpen(true)}
                              >
                                <EditIcon />
                                Edit Profile
                              </p>
                            </div>
                            <div className={socialStyles.bioContainer}>
                              <div className={socialStyles.imgContainer}>
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  title=""
                                  multiple={false}
                                  onChange={this.onChangeFileHandler}
                                />
                                <Image
                                  src={this.state.profileImg}
                                  alt="artist"
                                  imageType={IMAGE_TYPE.PROFILE}
                                />
                                <div className={socialStyles.iconContainer}>
                                  <SvgIcons icon={CAMERA} />
                                </div>
                              </div>
                              <div className={socialStyles.infoContainer}>
                                <div className={socialStyles.artistNameBox}>
                                  {this.state.artistName}
                                </div>
                                {this.getArtistBio()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.socialContainer}>
                        <div className={styles.socialInfo}>
                          <p className={styles.socialTitle}>Social Info</p>
                          <div className={styles.socialDetails}>
                            <SpotifyIcon />
                            <p>
                              <span className={styles.importantText}>
                                {numberWithCommas(
                                  get(socialCounts, "spotify", 0),
                                ) || 0}
                              </span>{" "}
                              Spotify monthly listeners
                            </p>
                          </div>
                          <div className={styles.socialDetails}>
                            <InstaIcon />
                            <p>
                              <span className={styles.importantText}>
                                {numberWithCommas(
                                  get(socialCounts, "instagram", 0),
                                ) || 0}
                              </span>{" "}
                              Instagram followers
                            </p>
                          </div>
                          <div className={styles.socialDetails}>
                            <YoutubeIcon />
                            <p>
                              <span className={styles.importantText}>
                                {numberWithCommas(
                                  get(socialCounts, "youtube", 0),
                                ) || 0}
                              </span>{" "}
                              Youtube subscribers
                            </p>
                          </div>
                        </div>
                        <div className={`${styles.advanceContainer} `}>
                          <p className={styles.socialTitle}>
                            Advance Structure
                          </p>
                          {offer && (
                            <ul>
                              <li
                                className={
                                  !offer?.catalog_advance ? "d-none" : ""
                                }
                              >
                                <div className="d-flex">
                                  <span>
                                    Catalog +{" "}
                                    {getAdvanceOptionalCount(offer.works)} new
                                    songs
                                  </span>
                                </div>
                              </li>
                              <li
                                className={
                                  !offer?.option_advance ? "d-none" : ""
                                }
                              >
                                <div className="d-flex">
                                  <span>
                                    Optional advance for{" "}
                                    {getAdvanceOptionalCount(offer.works, true)}{" "}
                                    more tracks
                                  </span>
                                </div>
                              </li>
                              <li className={!offer?.royalty ? "d-none" : ""}>
                                <div className="d-flex">
                                  <span>
                                    {parseInt(get(offer, "royalty", 0))}%
                                    Recoupement Rate
                                  </span>
                                </div>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.projectContainer}>
                      <div className={styles.detailHelperContainer}>
                        <p className={styles.text}>
                          We’ll share your profile info and details about
                          upcoming projects with investors, but never your
                          target offer.{" "}
                          <span
                            className={styles.link}
                            data-testid="see-why-modal"
                            onClick={this.openSeeWhyModal}
                          >
                            See why
                          </span>
                        </p>
                      </div>
                      {this.renderUpcomingProject()}
                    </div>
                  </div>
                  <div className={styles.footerContainer}>
                    <span
                      className={styles.btnLink}
                      data-testid="garuntee-offer-link"
                      onClick={this.toggleMarketPopup}
                    >
                      No thanks, I’ll take the guaranteed offer
                    </span>
                    <button
                      className={styles.primary}
                      data-testid="continue-btn"
                      onClick={this.updateUpcomingWorkStatus}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.loading && <Loader light backgroundNone />}
          {this.seeWhyModal()}
          {this.bioModal()}
          {this.imageModal()}
          {this.upcomingProjectModal()}
          <GuaranteedModal
            isOpen={this.state.showMarketPopup}
            toggleMarketPopup={this.toggleMarketPopup}
            navigationStatusUpdate={this.navigationStatusUpdate}
            context={this.context}
            totalAdvance={get(this.state.offer, "total_advance", 0)}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

UpcomingProjects.contextType = ThemeContext;
export default UpcomingProjects;
