export const BEATBREAD_TERMS_OF_SERVICE =
  "https://www.beatbread.com/terms_of_use";

export const CHORDCASH_TERMS_OF_SERVICE =
  "https://www.chordcash.com/termsofuse";

export const BEATBREAD_PRIVACY_POLICY = "https://app.beatbread.com/privacy";

export const CHORDCASH_PRIVACY_POLICY = "https://app.chordcash.com/privacy";

export const FORBIDDEN = "forbidden";
export const ACK_PAGE = "acknowledgementPage";
export const USER_ALREADY_REGISTERED = "USER_ALREADY_REGISTERED";
export const NEW_USER_REGISTERED = "NEW_USER_REGISTERED";
export const USER_REGISTERED_NEW_ARTIST_ADDED =
  "USER_REGISTERED_NEW_ARTIST_ADDED";
export const USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED =
  "USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED";
export const USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED =
  "USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED";
export const MAX_ARTIST_LINKED = "MAX_ARTIST_LINKED";
export const MAX_ARTIST_LINKED_NOT_REGISTERED =
  "MAX_ARTIST_LINKED_NOT_REGISTERED";
export const ARTIST_ALREADY_LINKED = "ARTIST_ALREADY_LINKED";
export const ERRORED = "ERRORED";
export const PENDING = "OFFER_PENDING";
export const MAINTENANCE = "maintenance";
export const LOGIN_SCREEN = "LOGIN_SCREEN";
export const CC_LITE_SLUGNAME = "funding";
