import React from "react";
import _, { get, capitalize } from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styles from "./AdminDashboard.module.scss";
import Switch from "./Switch";
import {
  ARTISTS_RANGE,
  ARTISTS_TABLE_COLUMN,
  PIPELINE_STAGES,
  PIPELINE_STAGES_LABELS,
  PENDING_ARTISTS_TABLE_COLUMN,
  MAX_REPROCESS,
} from "./constants";
import CustomLink from "./CustomLink";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import {
  PROFILE_ICON,
  VIEW_REPORTS_ICON,
  SYNC_ICON,
} from "../../../component/MaterialIcons/constants";
import generalColors from "../../../styles/global.scss";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { STATUS_PENDING } from "../Dashboard/constants";
import { ROLE_OPTIONS } from "../MyAccount/constants";

const classes = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
class UserRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubRowOpen: false,
      artistsCount: this.props.artist.maxLinkedArtist,
    };
  }

  /**
   * isSourceActive
   * @returns - true or false based on if source of user is active.
   */
  isSourceActive = () => {
    const { artist } = this.props;
    return artist.isSourceActive;
  };
  /**
   * getCollapsibleIcon
   * @returns appropriate icon if row if collapsed and not collapsed
   */
  getCollapsibleIcon = () => {
    const { isSubRowOpen } = this.state;
    return (
      <IconButton
        aria-label="expand artist"
        size="small"
        onClick={() => this.setState({ isSubRowOpen: !isSubRowOpen })}
        data-testid="CollapsibleIcon"
      >
        {isSubRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    );
  };
  /**
   * getProfileIcon
   * @returns If artist has set the profile icon, then returns icon otherwise returns svg profile icon
   */
  getProfileIcon = () => {
    const { artist } = this.props;
    return (
      <div className={styles.tableProfile}>
        <p>
          {
            _.defaultTo(_.find(ROLE_OPTIONS, { value: artist.role }), {})
              .displayLabel
          }
        </p>
      </div>
    );
  };
  /**
   * isFirstNameLastNameAvailable
   * @param {object} artist
   * @returns firstname and lastname are available or not
   */
  isFirstNameLastNameAvailable = (artist) =>
    artist.firstName !== null &&
    artist.firstName !== undefined &&
    artist.lastName !== null &&
    artist.lastName !== undefined;

  /**
   * getUsername
   * @returns If artist has set the username, then returns username otherwise returns -
   */
  getUsername = () => {
    const { artist, handleUserRedirection } = this.props;
    const email = get(artist, "email");
    return (
      <div className={styles.tableUsernameContainer}>
        <CustomLink
          onClick={handleUserRedirection}
          key={artist._id}
          disabled={get(artist, "isDeleted.value") || !this.isSourceActive()}
          className={`
             ${styles.artistDetails} ${!artist.active ? styles.pending : ""}`}
        >
          {artist.active && this.isFirstNameLastNameAvailable(artist)
            ? `${artist.firstName} ${artist.lastName}`
            : `-`}
        </CustomLink>
        <div>
          {email && email !== null && email !== undefined
            ? get(artist, "email.value", "-")
            : "-"}
        </div>
      </div>
    );
  };
  /**
   * getInRangeArtistsColumnMessage
   * @param {number} numOfArtists
   * @param {number} totalArtists
   * @returns formatted message needed to display in the table
   */
  getInRangeArtistsColumnMessage = (numOfArtists, totalArtists) => {
    return `${numOfArtists} of ${totalArtists} artists`;
  };
  /**
   * getInRangeArtists - calculates  number of in-range artists from total artists
   * @returns
   */
  getInRangeArtists = () => {
    const {
      artist: { maxLinkedArtist, linkedArtist },
    } = this.props;
    const filterInRangeArtists = linkedArtist.filter(
      (artist) => artist.status && artist.status === ARTISTS_RANGE.IN_RANGE,
    );
    return this.getInRangeArtistsColumnMessage(
      filterInRangeArtists.length,
      maxLinkedArtist,
    );
  };

  /**
   * fetchIpLocation
   * @returns location of user
   */
  fetchIpLocation = () => {
    const { artist } = this.props;
    let location = null;

    let country = "-";
    if (
      get(artist, "geoLocation.city") &&
      get(artist, "geoLocation.city") !== "null"
    ) {
      location = capitalize(get(artist, "geoLocation.city"));
    }
    if (
      !location &&
      get(artist, "geoLocation.region") &&
      get(artist, "geoLocation.region") !== "null"
    ) {
      location = capitalize(get(artist, "geoLocation.region"));
    }
    if (
      get(artist, "geoLocation.country") &&
      get(artist, "geoLocation.country") !== "null"
    ) {
      country = capitalize(get(artist, "geoLocation.country"));
    }
    return location ? `${location}, ${country}` : country;
  };
  /**
   * getIP
   * @returns Ip address of user
   */
  getIP = () => {
    const { artist } = this.props;
    const iP = get(artist, "ip");
    return (
      <div className={styles.tableIpLoginContainer}>
        <div>{iP && iP !== null && iP !== undefined ? iP : "-"}</div>
        <div>{this.fetchIpLocation()}</div>
      </div>
    );
  };
  /**
   * getLastLogin
   * @returns Last login of user
   */
  getLastLogin = () => {
    const { artist } = this.props;
    const lastLogin = get(artist, "lastLogin");
    const createdDate = get(artist, "createdAt");
    return (
      <div className={styles.tableIpLoginContainer}>
        <div>
          {createdDate && createdDate !== null && createdDate !== undefined
            ? moment(createdDate).format("MM/DD/YYYY HH:mm")
            : "-"}
        </div>
        <div>
          {lastLogin && lastLogin !== null && lastLogin !== undefined
            ? moment(lastLogin).format("MM/DD/YYYY HH:mm")
            : "-"}
        </div>
      </div>
    );
  };

  isInactivePendingArtistExists = () =>
    !this.props.artist.active &&
    !!_.find(this.props.artist.linkedArtist, { status: STATUS_PENDING });

  /**
   * getActions - Set limit of artists, disable the user
   * @returns UI for actions
   */
  getActions = () => {
    const { artist, onSwitchToggle, incrementArtistLimit } = this.props;
    const artistUserList = get(artist, "isDeleted.value")
      ? styles.disabledPoint
      : null;

    return (
      artist.active && (
        <div className={styles.actionsContainer}>
          <div className={styles.toggleSwitch}>
            <Switch
              id={artist._id}
              title="Enable/Disable User"
              isOn={!get(artist, "isDeleted.value")}
              onColor={generalColors.primaryColor}
              data-testid="switch"
              handleToggle={() => this.isSourceActive() && onSwitchToggle()}
            />
            <div className={artistUserList}>
              <div
                className={`${styles.artistsInput} ${
                  get(artist, "isDeleted.value") && styles.disabledPoint
                }`}
              >
                <input
                  type="number"
                  name="artists"
                  value={parseInt(this.state.artistsCount)}
                  data-testid="artistsInput"
                  onChange={(e) => {
                    this.setState({
                      artistsCount: parseInt(e.target.value),
                    });
                  }}
                  className={
                    (!this.state.artistsCount || this.state.artistsCount < 1) &&
                    styles.invalidInput
                  }
                />
              </div>
              <div
                className={
                  (!this.state.artistsCount ||
                    this.state.artistsCount < 1 ||
                    this.state.artistsCount === artist.maxLinkedArtist) &&
                  styles.disabledPoint
                }
              >
                <i
                  className="fa fa-check-circle"
                  title="Change Artists"
                  data-testid="Increment"
                  onClick={() =>
                    this.isSourceActive() &&
                    incrementArtistLimit(this.state.artistsCount)
                  }
                ></i>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  /**
   * getArtistProfileIcon
   * @param {object} artist
   * @returns profile icon of artist in parameter
   */
  getArtistProfileIcon = (artist) => {
    const profilePicture = get(
      artist,
      `profilePicture[${get(artist, "profilePicture", [{}]).length - 1}].url`,
      "",
    );
    return (
      <div className={styles.artistProfile}>
        {profilePicture ? (
          <Image
            src={profilePicture}
            alt="profile"
            imageType={IMAGE_TYPE.PROFILE}
          />
        ) : (
          <SvgIcons icon={PROFILE_ICON} className={styles.altImage} />
        )}
      </div>
    );
  };

  /**
   * getArtistName
   * @param {object} artist
   * @returns name of artist in parameter
   */
  getArtistName = (artist) => {
    const name = get(artist, "name");
    return name && name !== null && name !== undefined ? name : "-";
  };

  /**
   * getArtistChartMetricId
   * @param {object} artist
   * @returns chart metric of artist in parameter
   */
  getArtistChartMetricId = (artist) => {
    const chartMetricId = get(artist, "chartmetricId");
    return chartMetricId &&
      chartMetricId !== null &&
      chartMetricId !== undefined
      ? chartMetricId
      : "-";
  };

  /**
   * getArtistRange
   * @param {object} artist
   * @returns range of artist in parameter
   */
  getArtistRange = (artist) => {
    const range = get(artist, "status");
    return range && range !== null && range !== undefined ? range : "-";
  };

  /**
   * getPipelineStatusForObjectValue - calculates status for Verify funding and Customize funds.
   * This is a function which calculates the status for object values. (i.e API returns object value for Verify funding and Customize funds)
   * @param {object} offerStage
   * @param {number} key
   * @returns array with object of label and status of Verify funding and Customize funds.
   */

  getPipelineStatusForObjectValue = (offerStage, key) => {
    const stageVerificationStatus = [];
    if (
      offerStage.verificationStatus[key].value !==
      PIPELINE_STAGES[key].stagePendingStatus[0]
    ) {
      if (
        PIPELINE_STAGES[key].stageCompletedStatus.some(
          (currentValue) =>
            currentValue === offerStage.verificationStatus[key].value,
        )
      ) {
        stageVerificationStatus.push({
          label: PIPELINE_STAGES[key].label,
          status: true,
        });
      }
    }
    if (
      offerStage.verificationStatus[key].value ===
      PIPELINE_STAGES[key].stagePendingStatus[0]
    ) {
      if (
        PIPELINE_STAGES[key].stageCompletedStatus.every(
          (currentValue) =>
            currentValue !== offerStage.verificationStatus[key].value,
        )
      ) {
        stageVerificationStatus.push({
          label: PIPELINE_STAGES[key].label,
          status: false,
        });
      }
    }
    if (stageVerificationStatus.length > 0) {
      return stageVerificationStatus;
    }
  };

  /**
   * getPipelineStatusForBooleanValue - calculates status for Report Sent and Profile.
   * This is a function which calculates the status for boolean values. (i.e API returns boolean value for Report Sent and Profile stages)
   * @param {object} offerStage
   * @param {number} key
   * @returns array with object of label and status of Report Sent and Profile.
   */
  getPipelineStatusForBooleanValue = (offerStage, key) => {
    const stageVerificationStatus = [];
    if (
      offerStage.verificationStatus[key] !==
      PIPELINE_STAGES[key].stagePendingStatus[0]
    ) {
      if (
        PIPELINE_STAGES[key].stageCompletedStatus.some(
          (currentValue) => currentValue === offerStage.verificationStatus[key],
        )
      ) {
        stageVerificationStatus.push({
          label: PIPELINE_STAGES[key].label,
          status: true,
        });
      }
    }
    if (
      offerStage.verificationStatus[key] ===
      PIPELINE_STAGES[key].stagePendingStatus[0]
    ) {
      if (
        PIPELINE_STAGES[key].stageCompletedStatus.every(
          (currentValue) => currentValue !== offerStage.verificationStatus[key],
        )
      ) {
        stageVerificationStatus.push({
          label: PIPELINE_STAGES[key].label,
          status: false,
        });
      }
    }
    if (stageVerificationStatus.length > 0) {
      return stageVerificationStatus;
    }
  };
  /**
   * getFinalStatusFromValue
   * @param {array} stageVerificationStatus
   * @returns final stage of artist
   */
  getFinalStatusFromValue = (stageVerificationStatus) => {
    // last stage
    if (
      stageVerificationStatus[stageVerificationStatus.length - 1].status ===
      true
    ) {
      return stageVerificationStatus[stageVerificationStatus.length - 1].label;
    }
    // first stage
    if (stageVerificationStatus[0].status === false) {
      return stageVerificationStatus[0].label;
    }
    for (let index = 0; index < stageVerificationStatus.length; index++) {
      // between stages
      if (stageVerificationStatus[index + 1].status === false) {
        return stageVerificationStatus[index].label;
      }
    }
  };

  /**
   * getArtistPipelineStatus
   * @param {object} artist
   * @returns pipeline status of artist in parameter
   */
  getArtistPipelineStatus = (artist) => {
    const { offerStage } = artist;
    if (offerStage && offerStage.verificationStatus) {
      const stageVerificationStatus = [];
      Object.keys(PIPELINE_STAGES).forEach((key) => {
        if (
          offerStage.verificationStatus[key] &&
          offerStage.verificationStatus[key].value
        ) {
          const updatedValues = this.getPipelineStatusForObjectValue(
            offerStage,
            key,
          );
          updatedValues && stageVerificationStatus.push(...updatedValues);
        } else {
          const updatedValues = this.getPipelineStatusForBooleanValue(
            offerStage,
            key,
          );
          updatedValues && stageVerificationStatus.push(...updatedValues);
        }
      });
      if (stageVerificationStatus.length > 0) {
        return this.getFinalStatusFromValue(stageVerificationStatus);
      }
    } else {
      return PIPELINE_STAGES_LABELS[0];
    }
  };
  getArtistAddedDate = (artist) => {
    const addedDate = get(artist, "createdAt");
    return addedDate && addedDate !== null && addedDate !== undefined
      ? moment(addedDate).format("MM/DD/YYYY HH:mm")
      : "-";
  };

  /**
   * getArtistLastUpdate
   * @param {object} artist
   * @returns last update of artist in parameter
   */
  getArtistLastUpdate = (artist) => {
    const updatedAt = get(artist, "updatedAt");
    return (
      <div className={styles.tableIpLoginContainer}>
        <div>{this.getArtistAddedDate(artist)}</div>
        <div>
          {updatedAt && updatedAt !== null && updatedAt !== undefined
            ? moment(updatedAt).format("MM/DD/YYYY HH:mm")
            : "-"}
        </div>
      </div>
    );
  };

  renderCancelApproval = (artist) =>
    artist.offer_approved && (
      <i
        className={`fa fa-file-text-o ${styles.cancelIcon}`}
        title={`Revoke Approval`}
        onClick={() => {
          this.props.revokeArtistAction(artist);
        }}
      ></i>
    );
  /**
   * getArtistActions -  Artists Actions
   * @param {object} artist
   * @returns actions of artist in parameter
   */
  getArtistActions = (artist, user) => {
    const { renderDeleteArtistPopup, viewReports } = this.props;
    return (
      <div className={styles.artistActions}>
        {artist.active ? (
          <>
            <p
              onClick={() => {
                !get(user, "isDeleted.value") && viewReports(artist);
              }}
              data-testid="ViewReports"
              title="View Reports"
            >
              <SvgIcons icon={VIEW_REPORTS_ICON} />
            </p>
            {renderDeleteArtistPopup(artist, user)}
            {this.renderCancelApproval(artist)}
          </>
        ) : (
          <>
            <button
              className={styles.offerCallBtn}
              disabled={artist.reprocessCount >= MAX_REPROCESS}
              title={`Re-initiate the offer generation`}
              onClick={() => {
                this.props.offerReGeneration(artist, user);
              }}
            >
              <SvgIcons icon={SYNC_ICON} />
            </button>
            {artist.status !== STATUS_PENDING && (
              <>{renderDeleteArtistPopup(artist, user)}</>
            )}
          </>
        )}
      </div>
    );
  };
  getLastDate = (date) =>
    date ? moment(date).format("MM/DD/YYYY HH:mm") : "-";
  renderArtistDetails = () => {
    const {
      artist: { linkedArtist },
      artist: user,
    } = this.props;
    return linkedArtist.map((artist) => {
      return (
        <TableRow
          className={`${classes.root} ${styles.artistTable} ${
            get(user, "isDeleted.value") ? styles.userAccountDeactivated : ""
          }`}
          key={artist._id}
        >
          <TableCell>{this.getArtistProfileIcon(artist)}</TableCell>
          <TableCell>{this.getArtistName(artist)}</TableCell>
          <TableCell>{this.getArtistChartMetricId(artist)}</TableCell>
          <TableCell>{this.getArtistRange(artist)}</TableCell>
          {!this.isInactivePendingArtistExists() ? (
            <>
              <TableCell>{this.getArtistPipelineStatus(artist)}</TableCell>
              <TableCell>{this.getArtistLastUpdate(artist)}</TableCell>
            </>
          ) : (
            <>
              <TableCell>
                <div>{this.getArtistAddedDate(artist)}</div>
              </TableCell>
              <TableCell>
                <div className={styles.offerInfo}>
                  <p>{artist.reprocessCount || 0}</p>
                  <p>{this.getLastDate(artist.lastReprocessedAt)}</p>
                </div>
              </TableCell>
            </>
          )}
          <TableCell>{this.getArtistActions(artist, user)}</TableCell>
        </TableRow>
      );
    });
  };
  renderNoArtist = () => {
    return (
      <div className={styles.noArtists}>
        <p>No Artists To Show</p>
      </div>
    );
  };

  renderTableHeader = () =>
    this.isInactivePendingArtistExists()
      ? PENDING_ARTISTS_TABLE_COLUMN.map((column) => (
          <TableCell {...column.props} key={column.key}>
            {column.name}
          </TableCell>
        ))
      : ARTISTS_TABLE_COLUMN.map((column) => (
          <TableCell {...column.props} key={column.key}>
            {column.name}
          </TableCell>
        ));

  render() {
    const { isSubRowOpen } = this.state;
    const {
      artist,
      artist: { linkedArtist },
    } = this.props;
    return (
      <React.Fragment>
        <TableRow
          className={`${classes.root} ${
            !get(artist, "isDeleted.value") && this.isSourceActive()
              ? ""
              : styles.sourceDisabled
          }`}
          key={artist._id}
        >
          <TableCell>
            {this.isSourceActive() && this.getCollapsibleIcon()}
          </TableCell>
          <TableCell>{this.getProfileIcon()}</TableCell>
          <TableCell>{this.getUsername()}</TableCell>
          <TableCell>{this.getInRangeArtists()}</TableCell>
          <TableCell>{this.getIP()}</TableCell>
          <TableCell>{this.getLastLogin()}</TableCell>
          <TableCell>{this.getActions()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            {isSubRowOpen && (
              <Box component="span">
                <>
                  {linkedArtist.length === 0 ? (
                    this.renderNoArtist()
                  ) : (
                    <Table size="small" aria-label="artists">
                      <TableHead>
                        <TableRow
                          className={`${classes.root} ${styles.artistTable}`}
                        >
                          {this.renderTableHeader()}
                        </TableRow>
                      </TableHead>
                      <TableBody>{this.renderArtistDetails()}</TableBody>
                    </Table>
                  )}
                </>
              </Box>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

export default UserRows;
