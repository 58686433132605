import React, { Component } from "react";
import Select from "react-select";
import LoginHeader from "../../../component/LoginHeader";
import { getPartnerName } from "../../../component/ThemeManager/helper";
import AuthTokenService from "../../../utils/AuthTokenService";
import StorageService from "../../../utils/StorageService";
import styles from ".././AdminDashboard/AdminDashboard.module.scss";
import LeftPanel from "../AdminDashboard/LeftPanel";
import selectStyle from "../../../component/UploadFiles/selectStyle";
import {
  FIELD_NAMES,
  HOMEPAGE_CONTENT_COLUMNS,
  ALL_HOMEPAGE_DEFAULT_CATEGORY,
  CONTENT_TYPE,
  VIDEO_CATEGORY,
  ARTICLE_CATEGORY_OPTIONS,
  NEWS_OPTIONS,
} from "./constants";
import FormField from "../../../component/FormField/FormField";
import { Form } from "react-bootstrap";
import FormikForm from "../../../component/Form/FormikForm";
import Loader from "../../../component/Loader";
import { ReactComponent as DeleteIcon } from "../../../assets//logos/trash-icon.svg";
import generalColors from "../../../styles/global.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  ADMIN,
  API_URL,
  CONTENT_MANAGEMENT,
  HOMEPAGE,
  HOMEPAGE_CONTENT_EDIT,
  HOMEPAGE_CONTENT_LIST,
  HOMEPAGE_CONTENT_MANAGEMENT,
  HOMEPAGE_CONTENT_NEW,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { debounce, get } from "lodash";
import { GetErrorMessage } from "../helper";
import classes from "./HomePageContent.module.scss";
import ReactModal from "react-modal";
import { Formik } from "formik";
import Switch from "../AdminDashboard/Switch";
import Pagination from "react-js-pagination";
import moment from "moment";
import { ReactComponent as EmptyStar } from "../../../assets/logos/empty_star.svg";
import { ReactComponent as FilledStar } from "../../../assets/logos/filled_star.svg";
import { basicPasswordModal } from "./helper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
let activePage = 1;
const useDefaultToken = false;
class HomePageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      jwtToken: StorageService.get("jwtToken"),
      selectedType: CONTENT_TYPE[0],
      categoryOptions: ALL_HOMEPAGE_DEFAULT_CATEGORY,
      selectedCategory: ALL_HOMEPAGE_DEFAULT_CATEGORY[0],
      searchValue: "",
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 6,
      list: [],
      deleteModal: false,
      deleteId: null,
      featuredModal: false,
      featuredId: null,
      order1: "desc",
      order2: "desc",
      sortType: "create",
      isFeatured: false,
      enabledModal: false,
      enabledId: null,
    };
  }
  componentDidMount() {
    this.getContentList();
    document.title = `${getPartnerName()}: Homepage Content`;
    activePage = 1;
    AuthTokenService.storeToken(StorageService.get("customToken"));
    this.setState({
      jwtToken: StorageService.get("jwtToken"),
    });
  }

  delayedSearchResults = debounce(() => this.getContentList(), 500);

  disableContent = (values, setFieldValue) => {
    this.setState({ loading: true });
    const payload = {
      id: this.state.enabledId,
      isActive: this.state.isActive,
      adminPassword: values[FIELD_NAMES.ADMIN_PASSWORD.NAME],
    };
    const data = {
      method: "PATCH",
      body: payload,
    };
    const requestUrl = `${API_URL}${HOMEPAGE_CONTENT_MANAGEMENT}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false, enabledId: null, enabledModal: false });
        setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
        if (res.status) {
          toast.success(get(res, "message"));
          this.setState({ loading: false }, () => {
            this.getContentList();
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
  };
  getSearchValue = () => {
    return get(this.state.searchValue, "length") !== 0
      ? `&search=${encodeURIComponent(this.state.searchValue)}&searchType=title`
      : "";
  };
  getSortValue = () => {
    if (this.state.sortType === "create") {
      return this.state.order1 === "asc" ? `createdAt` : `-createdAt`;
    } else {
      return this.state.order2 === "asc" ? `publishDate` : `-publishDate`;
    }
  };
  getContentList = () => {
    this.setState({ searchLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${HOMEPAGE_CONTENT_LIST}?limit=20&page=${activePage}&type=${get(
      this.state.selectedType,
      "value",
    )}&category=${get(
      this.state.selectedCategory,
      "value",
    )}${this.getSearchValue()}&sort=${this.getSortValue()}`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ searchLoading: false });
        if (res.status) {
          this.setState({
            list: res.data.list,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ searchLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  handlePageChange(pageNumber) {
    activePage = pageNumber;
    this.getContentList();
  }

  categoryFilterChange = (option) => {
    this.setState({ selectedCategory: option }, this.getContentList);
    activePage = 1;
  };
  typeFilterChange = (option) => {
    this.setState(
      { selectedType: option },
      this.getContentList,
      this.getCategoryOptions(option),
    );

    activePage = 1;
  };
  actionRender = (details) => (
    <div className={classes.actionIcon} data-testid="actionContainer">
      <span
        data-testid="edit"
        onClick={() =>
          this.props.history.push({
            pathname: HOMEPAGE_CONTENT_EDIT,

            state: { post: details },
          })
        }
        className={`${classes.edit}`}
      >
        Edit
      </span>

      <DeleteIcon
        data-testId="delete"
        onClick={() => {
          this.hanldleOpenPasswordModal(details._id);
        }}
      />
      <Switch
        id={details && details._id && details._id}
        title="Enable/Disable content"
        isOn={details && details.isActive && details.isActive}
        onColor={generalColors.primaryColor}
        data-test="switch"
        handleToggle={() => this.disableContentModal(details)}
      />
    </div>
  );
  disableContentModal = (details) => {
    this.setState({
      enabledModal: true,
      isActive: !details.isActive,
      enabledId: details._id,
    });
  };
  disabledCloseContentModal = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ enabledModal: false });
  };
  handleClosePasswordBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ deleteModal: false });
  };
  hanldleOpenPasswordModal = (id) => {
    this.setState({ deleteModal: true, deleteId: id });
  };

  deleteModal = () => {
    return (
      <Formik
        initialValues={{ adminPassword: "" }}
        enableReinitialize
        data-testid="formRender"
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ReactModal
              isOpen={this.state.deleteModal}
              shouldCloseOnOverlayClick
              shouldCloseOnEsc
              onRequestClose={() => this.handleClosePasswordBox(setFieldValue)}
              className={styles.imageModal}
              overlayClassName={styles.modalOverlay}
              data-testid="handleMOdal"
            >
              <h2 className={styles.deletTitle}>Delete this Post?</h2>
              <p>
                This action cannot be undone. Please input the password to
                confirm these changes:
              </p>
              <div
                className={`${styles.changeTo} form-group justify-content-center ${classes.width}`}
              >
                <FormField
                  name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
                  label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
                  placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
                  as="password"
                />
              </div>

              <div
                className={`${styles.modalBtnContainer} ${styles.marginTop}`}
              >
                <button
                  className={styles.secondary}
                  type="button"
                  onClick={() => this.handleClosePasswordBox(setFieldValue)}
                  data-testid="cancel"
                >
                  Cancel
                </button>
                <button
                  className={styles.primary}
                  disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
                  onClick={() => this.handelDeleteContent(values)}
                  type="submit"
                  data-testid="submit"
                >
                  Submit
                </button>
              </div>
            </ReactModal>
          </Form>
        )}
      </Formik>
    );
  };
  handelDeleteContent = (values) => {
    this.setState({ loading: true });
    const payload = {
      adminPassword: values.adminPassword,
      id: get(this.state, "deleteId"),
    };
    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${HOMEPAGE_CONTENT_MANAGEMENT}`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false, deleteModal: false });
        if (res.status) {
          this.getContentList();

          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  getCategoryOptions = (type) => {
    switch (type.value) {
      case CONTENT_TYPE[0].value:
        return this.setState({
          categoryOptions: ALL_HOMEPAGE_DEFAULT_CATEGORY,
          selectedCategory: ALL_HOMEPAGE_DEFAULT_CATEGORY[0],
        });
      case CONTENT_TYPE[1].value:
        return this.setState({
          categoryOptions: ARTICLE_CATEGORY_OPTIONS,
          selectedCategory: ALL_HOMEPAGE_DEFAULT_CATEGORY[0],
        });
      case CONTENT_TYPE[2].value:
        return this.setState({
          categoryOptions: NEWS_OPTIONS,
          selectedCategory: ALL_HOMEPAGE_DEFAULT_CATEGORY[0],
        });
      case CONTENT_TYPE[3].value:
        return this.setState({
          categoryOptions: VIDEO_CATEGORY,
          selectedCategory: ALL_HOMEPAGE_DEFAULT_CATEGORY[0],
        });
      default:
        return this.setState({
          categoryOptions: ALL_HOMEPAGE_DEFAULT_CATEGORY,
        });
    }
  };

  handleCloseFeaturedBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ featuredModal: false });
  };
  handleOpenFeaturedBox = (id, isFeatured) => {
    this.setState({
      featuredModal: true,
      featuredId: id,
      isFeatured,
    });
  };

  handelFeaturedContent = (values, setFieldValue) => {
    this.setState({ loading: true });
    const payload = {
      adminPassword: values.adminPassword,
      id: get(this.state, "featuredId"),
      isFeatured: this.state.isFeatured,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestUrl = `${API_URL}${ADMIN}${HOMEPAGE}${CONTENT_MANAGEMENT}`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false, featuredModal: false });
        setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
        if (res.status) {
          this.getContentList();
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  renderFeaturedDiv = (articleDetails) => {
    return !get(articleDetails, "isFeatured", false) ? (
      <div
        className={classes.iconContainer}
        onClick={() =>
          get(articleDetails, "isActive") &&
          this.handleOpenFeaturedBox(get(articleDetails, "_id"), true)
        }
        data-testid="empty"
      >
        <EmptyStar />
      </div>
    ) : (
      <div
        className={classes.iconContainer}
        onClick={() =>
          get(articleDetails, "isActive") &&
          this.handleOpenFeaturedBox(get(articleDetails, "_id"), false)
        }
        data-testid="filled"
      >
        <FilledStar />
      </div>
    );
  };
  createSortHandler = (type) => {
    if (type === "Publish Date") {
      this.setState({ sortType: "pdate" });
      if (this.state.order2 === "asc") {
        this.setState(
          {
            order2: "desc",
          },
          this.getContentList,
        );
      } else {
        this.setState(
          {
            order2: "asc",
          },
          this.getContentList,
        );
      }
    } else {
      this.setState({ sortType: "create" });
      if (this.state.order1 === "asc") {
        this.setState(
          {
            order1: "desc",
          },
          this.getContentList,
        );
      } else {
        this.setState(
          {
            order1: "asc",
          },
          this.getContentList,
        );
      }
    }
  };
  isNeedToSort = (type) => {
    return type === "Date Created" || type === "Publish Date";
  };
  getOrder = (type) => {
    return type === "Date Created" ? this.state.order1 : this.state.order2;
  };
  renderContentTabularView = () => {
    const isDisplaySearched = this.state.list.length > 0;
    return (
      <>
        <TableContainer
          component={Paper}
          className={styles.mainTable}
          data-testid="UserRows"
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {HOMEPAGE_CONTENT_COLUMNS.map((column) => (
                  <TableCell
                    {...column.props}
                    key={column.key}
                    sortDirection={
                      this.isNeedToSort(column.name)
                        ? this.getOrder(column.name)
                        : false
                    }
                  >
                    {this.isNeedToSort(column.name) ? (
                      <TableSortLabel
                        direction={this.getOrder(column.name)}
                        onClick={() => this.createSortHandler(column.name)}
                      >
                        {column.name}
                      </TableSortLabel>
                    ) : (
                      column.name
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {get(this.state, "list").map((articleDetails) => (
                <TableRow
                  key={
                    articleDetails && articleDetails._id && articleDetails._id
                  }
                  data-testId="contentRow"
                >
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableDelete
                    }`}
                  >
                    {get(articleDetails, "type") !== "News" &&
                    get(articleDetails, "type") !== CONTENT_TYPE[3].value
                      ? this.renderFeaturedDiv(articleDetails)
                      : "-"}
                  </TableCell>

                  <TableCell>
                    <div>
                      {get(articleDetails, "image") ? (
                        <img src={get(articleDetails, "image")} alt="" />
                      ) : (
                        "-"
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableContent
                    }`}
                  >
                    {get(articleDetails, "title")}
                  </TableCell>
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableContent
                    }`}
                  >
                    {" "}
                    {get(articleDetails, "createdAt") &&
                    get(articleDetails, "createdAt") !== null &&
                    get(articleDetails, "createdAt") !== undefined
                      ? moment(get(articleDetails, "createdAt")).format(
                          "MM/DD/YYYY HH:mm",
                        )
                      : "-"}
                  </TableCell>
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableContent
                    }`}
                  >
                    {get(articleDetails, "publishDate") &&
                    get(articleDetails, "publishDate") !== null &&
                    get(articleDetails, "publishDate") !== undefined
                      ? moment(get(articleDetails, "publishDate")).format(
                          "MM/DD/YYYY",
                        )
                      : "-"}
                  </TableCell>
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableContent
                    }`}
                  >
                    {get(articleDetails, "type")}
                  </TableCell>
                  <TableCell
                    className={`${
                      !get(articleDetails, "isActive") && classes.disableContent
                    }`}
                  >
                    {get(articleDetails, "category")}
                  </TableCell>
                  <TableCell>{this.actionRender(articleDetails)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {get(this.state.list, "length") === 0 && (
            <div className={styles.noDataContainer}>
              <span>No Data</span>
            </div>
          )}
          {this.state.searchLoading && <Loader isAdmin />}
        </TableContainer>

        {isDisplaySearched && (
          <div className={styles.adminPagination}>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              activeClass={styles.active}
            />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <LoginHeader
          activePage={activePage - 1}
          userToken={this.state.jwtToken}
        />
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${styles.font}`}
            >
              <h1>beatBread Homepage Content</h1>
              <div className={`${styles.searchContainer} ${styles.mobile}`}>
                <button
                  className={`${styles.createBtn} ${styles.width}`}
                  onClick={() => this.props.history.push(HOMEPAGE_CONTENT_NEW)}
                  data-testid="createBtn"
                >
                  Create new
                </button>
              </div>
            </div>
            <div className={`${styles.filterBox} ${styles.padding}`}>
              <div>
                <h4>Type: </h4>
                <Select
                  isSearchable
                  classNamePrefix="filter"
                  styles={selectStyle}
                  onChange={this.typeFilterChange}
                  value={this.state.selectedType}
                  options={CONTENT_TYPE}
                  placeholder="Type"
                />
              </div>
              <div>
                <h4>Category: </h4>
                <Select
                  isSearchable
                  classNamePrefix="status"
                  styles={selectStyle}
                  onChange={this.categoryFilterChange}
                  value={this.state.selectedCategory}
                  options={this.state.categoryOptions}
                  placeholder="Category"
                />
              </div>
              <div className={styles.searchContainer}>
                <FormikForm
                  initialValues={{
                    searchbar: "",
                  }}
                >
                  <Form onKeyDown={this.keyEvent}>
                    <div
                      className={`form-group ${styles.adminSearch} ${styles.marginBottom}`}
                    >
                      <i className="fa fa-search"></i>
                      <FormField
                        name="searchbar"
                        placeholder="Search by post name"
                        type="input"
                        value={this.state.searchValue}
                        onChange={(e) => {
                          this.setState(
                            {
                              searchValue: e.target.value,
                            },
                            () => {
                              if (
                                this.state.searchValue.length > 2 ||
                                this.state.searchValue.length === 0
                              ) {
                                this.delayedSearchResults();
                              }
                              if (this.state.searchValue.length === 0) {
                                this.setState({ searchType: null });
                              }
                            },
                          );
                        }}
                        data-testid="SearchValue"
                      />
                    </div>
                  </Form>
                </FormikForm>
              </div>
            </div>
            <div
              className={`${styles.artistContainer} ${styles.contentContainer} ${classes.articleImg}`}
            >
              {this.renderContentTabularView()}
            </div>
          </div>
        </div>
        {this.deleteModal()}
        {basicPasswordModal(
          this.state.featuredModal,
          `Make this Post ${
            this.state.isFeatured ? `Featured` : `UnFeatured`
          }?`,
          "This action will replace the currently featured post. Please input the password to confirm these changes:",
          this.handelFeaturedContent,
          this.handleCloseFeaturedBox,
        )}
        {basicPasswordModal(
          this.state.enabledModal,
          `${this.state.isActive ? `Show` : `Hide`} this Post?`,
          `This action will make this post ${
            this.state.isActive ? `visible` : `invisible`
          } to users. Please input the password to confirm these changes:`,
          this.disableContent,
          this.disabledCloseContentModal,
        )}
        {this.state.loading && <Loader />}
      </>
    );
  }
}

export default HomePageContent;
