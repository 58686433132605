import React, { Component, createRef } from "react";
import ReactModal from "react-modal";
import styles from "./TimeoutPopup.module.scss";
import { ReactComponent as WarningIcon } from "../../img/warning-icon.svg";
import { autoLogout, logout } from "../../utils/Helper";
import Loader from "../Loader";
import {
  ADMIN,
  API_URL,
  REFRESH_TOKEN,
  USER_API,
} from "../../routes/pages/constants";
import request from "../../utils/request";
import AuthTokenService from "../../utils/AuthTokenService";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../routes/pages/helper";
import { get, invoke } from "lodash";
import StorageService from "../../utils/StorageService";

export class TimeoutPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: 0,
      loading: false,
    };
    this.Ref = createRef(null);
  }

  startTimer = () => {
    if (this.state.timer > 0) {
      this.setState({ timer: this.state.timer - 1 });
    } else {
      clearInterval(this.Ref.current);
      autoLogout.call(this);
    }
  };

  clearTimer = (seconds) => {
    this.setState({
      timer: seconds,
    });
    const id = setInterval(() => {
      this.startTimer();
    }, 1000);
    this.Ref.current = id;
  };

  componentDidMount() {
    this.clearTimer(get(this.props, "leftTime", 30));
  }

  handleApi = () => {
    clearInterval(this.Ref.current);
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };

    const isAdmin = window.location.pathname.includes(ADMIN);
    const requestUrl = `${API_URL}${USER_API}${REFRESH_TOKEN}`;
    request(requestUrl, data, !isAdmin)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (isAdmin) {
            StorageService.set("customToken", res.data.accessToken, {
              hash: false,
            });
          }
          AuthTokenService.storeToken(res.data.accessToken);
          invoke(this.props, "cancelExpireModal");
          invoke(this.props, "setExpireTime");
          return true;
        }
        toast.error(get(res, "message"));
        autoLogout.call(this);
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
        autoLogout.call(this);
      });
  };

  render() {
    return (
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.props.onRequestClose}
        className={styles.detailsPopupContainer}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.header}>
          <span className={styles.icon}>
            <WarningIcon />
          </span>
          <p>Your session is about to expire!</p>
        </div>
        <span>
          To stay logged in, please click continue. Otherwise you will be
          automatically logged out in:
        </span>
        <p>{Math.round(this.state.timer)} seconds</p>
        <div className={styles.buttonContainer}>
          <button className={styles.secondary} onClick={logout}>
            Log out
          </button>
          <button onClick={this.handleApi} data-testid="btn-continue">
            Continue
          </button>
        </div>
        {this.state.loading && <Loader />}
      </ReactModal>
    );
  }
}
