import React, { Component } from "react";
import PropTypes from "prop-types";
import _, { get, invoke } from "lodash";
import htmlParser from "html-react-parser";
import * as Yup from "yup";
import Dropzone from "./Dropzone";
import styles from "./UploadFiles.module.scss";
import popupStyles from "../../routes/pages/SendReports/SendReports.module.scss";
import { distributorSelectStyle } from "./selectStyle";
import Progress from "./Progress";
import {
  USER_API,
  API_URL,
  REPORT,
  ARTIST_API,
  PUBLISHER_REPORTS_API,
} from "../../routes/pages/constants";
import AuthTokenService from "../../utils/AuthTokenService";
import Axios from "axios";
import { userExists } from "../../utils/Helper";
import {
  ERR,
  UPLOADED,
  PENDING,
  VALID_FILE_TYPE,
  MAX_REPORTS_SIZE,
  MAX_SIZE_IN_MB,
  OTHER_DISTRIBUTOR,
} from "./constants";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Select from "react-select";
import { toast } from "react-toastify";
import { ReactComponent as TrashIcon } from "../../assets/logos/trash-icon.svg";
import { ReactComponent as NeedHelpIcon } from "../../assets/logos/help-icon.svg";
import ReactModal from "react-modal";

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successUploadCount: 0,
      isRename: false,
      isOpen: false,
      isContactOpen: false,
      inputValue: "",
      isClickOnClose: false,
      isUpdated: false,
      selectedDistributor: null,
      isFastFlow: false,
      isFileAcceptaceModalOpen: false,
    };
    this.otherNameRef = React.createRef();
    this.uploadRef = React.createRef();
  }

  findPublishingSelectOption = () =>
    get(this.props, "distributorInfo.isOther")
      ? _.find(get(this.props, "selectOptions"), {
          value: OTHER_DISTRIBUTOR,
        })
      : null;

  componentDidMount() {
    this.updateStateData();
  }

  componentDidUpdate(prevProps) {
    const current = this.uploadRef.current;
    current.scrollTop = current.clientHeight - current.scrollHeight;
    if (
      get(prevProps, "distributorInfo._id") !==
      get(this.props, "distributorInfo._id")
    ) {
      this.updateStateData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      get(nextProps, "distributorInfo.isOther") &&
      !get(nextProps, "distributorInfo.isEditMode")
    ) {
      return true;
    }
  }

  updateStateData = () => {
    const uploadProgress = {};
    const fileList = this.props.files.map((file) => {
      uploadProgress[file.originalName] = {
        state: UPLOADED,
        deleteKey: get(this.props, "isPublishing") ? file._id : file.key,
        percentage: 100,
      };
      return { name: file.originalName, size: 0 };
    });
    this.setState({
      files: fileList,
      successUploadCount: fileList.length,
      uploadProgress: { ...uploadProgress },
      isUpdated: false,
      selectedDistributor:
        _.find(get(this.props, "selectOptions"), {
          label: get(this.props, "distributorInfo.name"),
        }) ||
        (get(this.props, "isPublishing")
          ? this.findPublishingSelectOption()
          : _.find(get(this.props, "selectOptions"), {
              value: OTHER_DISTRIBUTOR,
            })),
      isFastFlow: this.props.isFastFlow,
    });
  };

  onFilesAdded = (files) => {
    const fileList = files.filter(
      (file) => !this.state.uploadProgress[file.name],
    );
    this.setState(
      (prevState) => ({
        files: prevState.files.concat(fileList),
      }),
      this.uploadFiles,
    );
  };

  cancelRequest = (file) => {
    if (!this.state.uploading) {
      this.props.deleteReport(
        this.state.uploadProgress[file.name].deleteKey,
        file.name,
        this.props.distributorInfo._id,
      );
      const { uploadProgress, files } = this.state;
      delete uploadProgress[file.name];
      _.remove(files, (f) => f.name === file.name);
      this.setState({
        uploadProgress,
        successUploadCount: this.state.successUploadCount - 1,
      });
    } else {
      this.state.uploadProgress[file.name].cancel();
      const copy = { ...this.state.uploadProgress };
      copy[file.name] = {
        state: ERR,
        percentage: 100,
        msg: "Cancelled",
      };
      this.setState({ uploadProgress: copy });
    }
  };

  renderProgress = (file) => {
    const uploadProgress = this.state.uploadProgress[file.name];
    return (
      <div
        className={`${styles.progressWrapper} ${
          [UPLOADED, ERR].indexOf(get(uploadProgress, "state", "")) !== -1
            ? "d-none"
            : ""
        }`}
      >
        <>
          <Progress
            progress={uploadProgress.percentage || 0}
            status={uploadProgress.state}
          />
        </>
      </div>
    );
  };

  sendUploadRequest = (file) => {
    const formData = new FormData();
    if (get(this.props, "isPublishing")) {
      formData.append("publisherReport", file, file.name);
      formData.append("publisher", get(this.props.distributorInfo, "_id", ""));
      formData.append(
        "isOther",
        get(this.props.distributorInfo, "isOther", false),
      );
    } else {
      formData.append("report", file, file.name);
    }
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${
      get(this.props, "isPublishing") ? PUBLISHER_REPORTS_API : REPORT
    }`;
    if (userExists()) {
      Axios.defaults.headers.common.Authorization = `${AuthTokenService.get()}`;
    } else {
      delete Axios.defaults.headers.common.Authorization;
    }
    const source = Axios.CancelToken.source();
    return invoke(
      Axios,
      get(this.props, "isPublishing") ? "post" : "put",
      requestURL,
      formData,
      {
        onUploadProgress: (ProgressEvent) => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: PENDING,
            cancel: source.cancel,
            percentage: Math.round(
              (ProgressEvent.loaded / ProgressEvent.total) * 100,
            ),
          };
          this.setState({ uploadProgress: copy, isUpdated: true });
        },
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: source.token,
        params: !get(this.props, "isPublishing") && {
          reportFromDistributor: this.props.radioSelection,
          distributorId: get(this.props.distributorInfo, "_id", ""),
          isOther: get(this.props.distributorInfo, "isOther", false),
        },
      },
    );
  };

  uploadFiles = () => {
    this.setState({
      uploading: true,
    });
    const promises = [];
    this.state.files.forEach((file) => {
      const fileExtension = file.name.split(".");
      if (file.size > MAX_REPORTS_SIZE) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: `File size exceeded (${MAX_SIZE_IN_MB}MB)`,
        };
      } else if (
        fileExtension.length < 2 ||
        VALID_FILE_TYPE.indexOf(fileExtension.pop().toLowerCase()) === -1
      ) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: "File type not accepted",
        };
      }
      if (
        !this.state.uploadProgress[file.name] &&
        file.size <= MAX_REPORTS_SIZE
      ) {
        promises.push(this.sendUploadRequest(file));
      }
    });

    Promise.allSettled(promises)
      .then((responses) => {
        responses.forEach((res) => {
          if (res.status === "fulfilled") {
            if (get(this.props, "isPublishing")) {
              this.state.uploadProgress[
                get(res, "value.config.data").get("publisherReport").name
              ].deleteKey = get(res, "value.data.data._id");
              this.state.uploadProgress[
                get(res, "value.config.data").get("publisherReport").name
              ].state = UPLOADED;
              const file = {
                key: get(res, "value.data.data._id"),
                _id: get(res, "value.data.data._id"),
                originalName: get(res, "value.config.data").get(
                  "publisherReport",
                ).name,
                sent: false,
              };
              this.props.addReport(this.props.distributorInfo, file);
            } else {
              this.state.uploadProgress[
                get(res, "value.config.data").get("report").name
              ].deleteKey = get(res, "value.data.data._id");
              this.state.uploadProgress[
                get(res, "value.config.data").get("report").name
              ].state = UPLOADED;
              const file = {
                key: get(res, "value.data.data._id"),
                originalName: get(res, "value.config.data").get("report").name,
                sent: false,
              };
              this.props.addReport(this.props.distributorInfo, file);
            }
            this.setState({
              successUploadCount: this.state.successUploadCount + 1,
            });
          } else {
            if (get(res, "reason.response.data.message")) {
              toast.error(get(res, "reason.response.data.message"));
            }
            if (get(res, "reason.config.data")) {
              this.state.uploadProgress[
                get(res, "reason.config.data").get("report").name
              ].state = ERR;
            }
          }
        });
        this.setState({ uploading: false });
      })
      .catch();
  };

  fileText = () => (this.state.successUploadCount === 1 ? "file" : "files");

  unsentFileListing = () => (
    <div className={styles.upload}>
      <div className={styles.content}>
        {!this.state.files.length ? (
          <></>
        ) : (
          <>
            {!!this.state.successUploadCount && (
              <p className={styles.fileCount}>
                {this.state.successUploadCount} {this.fileText()} uploaded
                successfully!
              </p>
            )}
            <div className={styles.files}>
              {this.state.files.map((file, index) => {
                const uploadProgress = this.state.uploadProgress[file.name];
                return (
                  uploadProgress && (
                    <div
                      key={`${file.name}-${index}`}
                      className={`${styles.row} ${
                        uploadProgress.state === ERR ? styles.error : ""
                      }`}
                      title={file.name}
                    >
                      <span className={styles.fileNameContainer}>
                        <span className={styles.fileName}>{file.name}</span>
                        <span
                          className={`${styles.checkIcon} ${
                            uploadProgress.state === ERR ||
                            (uploadProgress.percentage === 100 &&
                              this.state.uploading)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            this.cancelRequest(file);
                          }}
                        >
                          <TrashIcon />
                        </span>
                        {get(this.state.uploadProgress[file.name], "msg") && (
                          <span className={styles.msgError}>
                            {get(this.state.uploadProgress[file.name], "msg")}
                            <i
                              className={`${styles.helpBtn} fa fa-question-circle`}
                              onClick={() =>
                                invoke(this.props, "handleToggleContact")
                              }
                              title={`Help`}
                            ></i>
                          </span>
                        )}
                      </span>
                      {this.renderProgress(file)}
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );

  infoDistributorText = () =>
    get(this.props, "distributorInfo.name") && (
      <>
        {" "}
        from{" "}
        <span className="textBold">
          {get(this.props, "distributorInfo.name")}
        </span>
      </>
    );

  toogleFileAcceptance = () => {
    this.setState({
      isFileAcceptaceModalOpen: !this.state.isFileAcceptaceModalOpen,
    });
  };

  getUploadConfirmFunction = () => this.props.handleClose;

  toggleInstructionCollapse = () => {
    document
      .getElementsByClassName(popupStyles.instructionInfoContainer)[0]
      .classList.toggle(popupStyles.showInstruction);
  };

  validationSchema = Yup.object().shape({
    otherName: Yup.string().required("required field"),
  });

  handleClose = () => {
    this.props.handleClose();
  };

  selectDistributorDropDown = () => (
    <div className={popupStyles.logoDetails}>
      <label>
        {get(this.props, "isPublishing")
          ? "Select a PRO or Publisher"
          : "Selected Distributor"}
        :
      </label>
      <Select
        classNamePrefix="distributor"
        styles={distributorSelectStyle}
        onChange={this.props.onDistributorChange}
        options={this.props.selectOptions}
        placeholder={`Select a ${
          get(this.props, "isPublishing") ? "Publisher/PRO" : "Distributor"
        }`}
        value={this.state.selectedDistributor}
        components={{ IndicatorSeparator: null }}
        formatOptionLabel={(val) => (
          <div className={popupStyles.option}>
            {get(val, "logoUrl") && (
              <img
                src={get(val, "logoUrl")}
                alt={`${get(val, "label")}-logo`}
              />
            )}{" "}
            <span>{get(val, "label")}</span>
          </div>
        )}
        isDisabled={this.state.uploading}
      />
      {get(this.props, "isPublishing") &&
        !get(this.props, "totalFiles") &&
        !this.state.uploading && (
          <p
            onClick={get(this.props, "handleSendReports")}
            className={popupStyles.backBtn}
          >
            I don’t have reports from a PRO or Publisher
          </p>
        )}
      {!!get(this.props, "totalFiles") &&
        !get(this.state, "successUploadCount") &&
        !this.state.uploading && (
          <p onClick={this.props.handleClose} className={popupStyles.backBtn}>
            Show all uploaded reports
          </p>
        )}
    </div>
  );

  render() {
    const { distributorInfo } = this.props;
    return (
      <div className={popupStyles.uploadModalContent} ref={this.uploadRef}>
        <div className={popupStyles.infoAndUploadContainer}>
          <div className={popupStyles.distributorInfo}>
            {this.selectDistributorDropDown()}
            <div
              className={`${popupStyles.instructionInfoContainer}  ${popupStyles.showInstruction}`}
            >
              {get(distributorInfo, "isOther") ? (
                <div className={popupStyles.otherContent}>
                  <p>
                    We are looking for an excel-type file (csv, tsv, xls, etc)
                    with detailed data that shows at least:
                  </p>
                  <ul>
                    <li>Track Title &amp; Artist Name</li>
                    <li>Track ISRC</li>
                    <li>Number of Streams</li>
                    <li>Date those streams occurred</li>
                    <li>Revenue generated by each of those streams</li>
                    <li>Platform where those streams occurred</li>
                    <li>Country where those streams occurred</li>
                  </ul>
                </div>
              ) : (
                <>
                  <h4
                    className={popupStyles.infoTitle}
                    onClick={this.toggleInstructionCollapse}
                  >
                    How to get your {get(distributorInfo, "name", "")} reports
                  </h4>
                  <div className={popupStyles.instructionsContent}>
                    {htmlParser(get(distributorInfo, "instructionsHTML", ""))}
                  </div>
                </>
              )}
            </div>
            <div
              className={`${popupStyles.helpTextContainer} ${
                popupStyles.showInstruction
              } ${get(this.props, "isPublishing") ? "d-none" : ""}`}
            >
              <p>
                If you are having trouble finding the right report from your
                distributor,{" "}
                <span
                  className={popupStyles.helpTextLink}
                  data-testid="contact-us-drawer"
                  onClick={() => invoke(this.props, "handleToggleContact")}
                >
                  please reach out to us
                </span>
              </p>
            </div>
            {!!get(this.props, "totalFiles") && !this.state.uploading && (
              <div
                className={`${popupStyles.backCtn} ${
                  get(this.props, "isPublishing") ? popupStyles.publish : ""
                }`}
              >
                <p
                  onClick={this.props.handleClose}
                  className={popupStyles.backBtn}
                >
                  Back
                </p>
              </div>
            )}
          </div>
          <div
            className={`${popupStyles.uploadContainer} ${
              !get(this.state, "files", []).length ? popupStyles.initialBox : ""
            }`}
          >
            <div className={popupStyles.uploadBox}>
              <Dropzone
                disabled={
                  this.state.uploading || !this.state.selectedDistributor
                }
                onFilesAdded={this.onFilesAdded}
                successUploadCount={this.state.successUploadCount}
              />
            </div>
            {this.unsentFileListing()}
            <div className={popupStyles.modalLink}>
              <p
                data-testid="open-files-modal"
                onClick={this.toogleFileAcceptance}
              >
                What file types do we accept?
                <span>
                  <NeedHelpIcon />
                </span>
              </p>
            </div>
            {this.state.successUploadCount ? (
              <>
                <div className={popupStyles.infoText}>
                  <p>
                    Have you uploaded 6 months of detailed earnings reports
                    {this.infoDistributorText()}?
                  </p>
                </div>
                <button
                  onClick={this.props.handleClose}
                  className={popupStyles.uploadBtn}
                  disabled={
                    !this.state.successUploadCount || this.state.uploading
                  }
                >
                  Confirm
                </button>
              </>
            ) : null}
            {!!get(this.props, "totalFiles") && !this.state.uploading && (
              <div className={popupStyles.backMobileCtn}>
                <p
                  onClick={this.props.handleClose}
                  className={popupStyles.backBtn}
                >
                  Back
                </p>
              </div>
            )}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.isFileAcceptaceModalOpen}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          onRequestClose={this.toogleFileAcceptance}
          className={popupStyles.fileTypePopupContainer}
          overlayClassName={popupStyles.modalOverlay}
        >
          <div
            className={`${popupStyles.fileFormatInfo} ${
              get(this.props, "isPublishing")
                ? popupStyles.publishingFileInfo
                : ""
            }`}
          >
            <span
              className={popupStyles.closeBtn}
              onClick={this.toogleFileAcceptance}
              data-testid="span-close"
            >
              &times;
            </span>
            <div className={popupStyles.acceptableInfo}>
              <h4>Acceptable File Formats</h4>
              <ul className={popupStyles.formatList}>
                <li>Microsoft Excel (.xls, xlsx, .csv, .tsv.)</li>
                <li>Text File (.txt)</li>
                <li>Apple Numbers (.numbers)</li>
              </ul>
            </div>
            <div className={popupStyles.notAcceptableInfo}>
              <h4>Files Formats we do not accept</h4>
              <ul className={popupStyles.formatList}>
                <li>.jpg or .png</li>
                <li>Screenshots</li>
                <li>Any other image format</li>
              </ul>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
UploadFiles.contextType = ThemeContext;
export default UploadFiles;

UploadFiles.contextTypes = {
  reportFields: PropTypes.object,
};
