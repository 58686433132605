import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styles from "./OverLay.module.scss";

const OverLay = ({ className, ...props }) => {
  const BackDrop = () => (
    <div className={styles.overlay} onClick={props.onClose}></div>
  );

  return (
    <div>
      <>
        <Drawer
          disableEnforceFocus
          className={`${styles.drawer} ${className ? className : ""}`}
          BackdropComponent={BackDrop}
          {...props}
        >
          {props.component ? props.component : props.children}
        </Drawer>
      </>
    </div>
  );
};
export default OverLay;
