import React from "react";
import { Route, Redirect } from "react-router";
import PropTypes from "prop-types";
import { userExists } from "../utils/Helper";
import { LOGIN } from "./pages/constants";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        userExists() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
            }}
          />
        )
      }
      data-testid="routeRender"
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
};
export default PrivateRoute;
