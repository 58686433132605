import React, { Component } from "react";
import Lottie from "react-lottie";
import lottieData from "../../assets/logos/splashScreen.json";
import styles from "./Loader.module.scss";
import { getSubDomain } from "../ThemeManager/helper";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import bbLogo from "../../img/icons/bb-blue.png";
import Image from "../Image";
import { IMAGE_TYPE } from "../Image/constants";

class SplashLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loading !== this.props.loading &&
      this.props.loading === true
    ) {
      this.setState({ mounted: true });
    }
  }

  unMountSplash = () => {
    this.setState({ mounted: false });
  };

  render() {
    return (
      this.state.mounted && (
        <div
          className={`${styles.splashContainer} ${!this.props.loading && styles.hide
            }`}
          onTransitionEnd={this.unMountSplash}
        >
          {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 ? (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: lottieData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isClickToPauseDisabled
              speed={200}
              isPaused={!this.props.loading}
            />
          ) : (
            <Image
              src={bbLogo}
              alt="logo"
              imageType={IMAGE_TYPE.LOGO} />
          )}
        </div>
      )
    );
  }
}

export default SplashLoader;
