import React, { Component } from "react";
import { get } from "lodash";
import ReactModal from "react-modal";
import generalColors from "../../../styles/global.scss";
import styles from "./EmailPreferences.module.scss";
import {
  LOGIN,
  API_URL,
  USER_API,
  EMAIL_PREFERENCES_API,
  EMAIL_PREFERENCES,
} from "../constants";
import Loader from "../../../component/Loader";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  setTitle,
  getPartnerName,
} from "../../../component/ThemeManager/helper";
import { userExists } from "../../../utils/Helper";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import Switch from "../AdminDashboard/Switch";
import { CHORDCASH_PARTNER, MARKETING_PREFERENCE } from "./constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Image from "../../../component/Image";

export const EmailPreferencesWrapper = () => (
  <div className={styles.emailPreferenceContainer}>
    <EmailPreferences pageStyle />
  </div>
);

class EmailPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: {
        [MARKETING_PREFERENCE]: true,
      },
      email: "",
      userId: "",
      isPopupOpen: false,
      loading: false,
      isUpdatePreference: false,
    };
  }
  componentDidMount() {
    this.checkOnLoadData();
  }

  componentDidUpdate() {
    setTitle("Email Preferences", this.context);
  }

  checkOnLoadData = () => {
    const params = new URLSearchParams(window.location.search);
    if (userExists()) {
      this.getPreferenceDetails();
    } else if (params.get("userId")) {
      this.getPreferenceDetails(params.get("userId"));
    } else {
      this.props.history.push(LOGIN);
    }
  };

  getPreferenceDetails = (userId = "") => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${EMAIL_PREFERENCES_API}${
      userId && `?userId=${userId}`
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const preferences = get(res.data, "emailPreferences", {});
          this.setState({
            preferences: {
              [MARKETING_PREFERENCE]: preferences[MARKETING_PREFERENCE],
            },
            email: res.data.email,
            isUpdatePreference: preferences[MARKETING_PREFERENCE],
            userId,
          });
          return true;
        }
        toast.error(get(res, "message"));

        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleSubmit = (optOutOfAll) => {
    this.setState({ loading: true, isPopupOpen: false });
    const payload = {
      userId: this.state.userId,
      emailPreferences: this.state.preferences,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${EMAIL_PREFERENCES_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            isUpdatePreference: this.state.preferences[MARKETING_PREFERENCE],
          });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getDisplayName = () =>
    getPartnerName() === CHORDCASH_PARTNER &&
    `${get(this.context, "displayName", "")} `;

  handlePreferenceChange = (e, key) => {
    const { preferences } = this.state;
    const checked = e.target.checked;
    preferences[key] = checked;
    this.setState({ preferences });
  };

  handleTogglePopup = () => {
    this.setState({ isPopupOpen: !this.state.isPopupOpen });
  };

  optOutConfirmation = () => (
    <ReactModal
      isOpen={this.state.isPopupOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.handleTogglePopup}
      className={styles.imageModal}
      overlayClassName={`${styles.modalOverlay}`}
    >
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <h3>Are you sure?</h3>
        </div>
        <p>
          You will not be able to resubscribe to {getPartnerName()} marketing
          emails. Are you sure you wish to unsubscribe?
        </p>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            onClick={() => {
              this.handleSubmit();
            }}
            data-testid="handleSubmit"
          >
            UNSUBSCRIBE
          </button>
        </div>
      </div>
    </ReactModal>
  );
  loginRedirect = () => this.props.history.push(LOGIN);

  render() {
    return (
      <div
        className={` ${styles.container} ${
          this.props.pageStyle && styles.containerStyle
        } `}
      >
        <div
          className={` ${styles.pageContent} ${
            this.props.pageStyle && styles.containerPageContent
          } `}
        >
          <div
            className={styles.logoContainer}
            data-testid="div-logo"
            onClick={
              this.props.toggleDrawer
                ? this.props.toggleDrawer(false)
                : this.loginRedirect
            }
          >
            <div
              className={styles.closeIcon}
              data-testid="div-icon"
              onClick={
                this.props.toggleDrawer
                  ? this.props.toggleDrawer(false)
                  : this.loginRedirect
              }
            >
              <KeyboardBackspaceIcon />
            </div>
            <Image
              src={get(this.context, "emailLogo", "")}
              className={styles.logo}
              alt="Logo"
            />
          </div>
          {userExists() && (
            <div className={styles.title}>
              <h2>
                <span>Email</span> Preferences
              </h2>
            </div>
          )}
          <div
            className={` ${styles.header} ${
              this.props.pageStyle && styles.containerHeader
            } `}
          >
            <h2 className={`${!userExists() ? styles.visible : ""}`}>
              <span> Email </span>Preferences
            </h2>
            <p>{this.state.email}</p>
          </div>
          <div
            className={` ${styles.preferenceContainer} ${
              this.props.pageStyle && styles.emailContainer
            } `}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>Transactional Emails</h4>

                    <p className={styles.subText}>
                      These emails will always be sent to you for important
                      updates on your account or actions triggered by you
                      (forgot password, adding an artist, etc)
                    </p>
                  </td>
                  <td className={styles.primary}> Yes</td>
                </tr>
                <tr>
                  <td>
                    <h4>{this.getDisplayName()}Marketing Emails</h4>

                    <p className={styles.subText}>
                      Newsletters that give you more information about what{" "}
                      {getPartnerName()} can offer you
                    </p>
                  </td>
                  <td className={styles.primary}>
                    {this.state.isUpdatePreference ? (
                      <Switch
                        id={`${EMAIL_PREFERENCES}${MARKETING_PREFERENCE}`}
                        isOn={!!this.state.preferences[MARKETING_PREFERENCE]}
                        handleToggle={(e) => {
                          this.handlePreferenceChange(e, MARKETING_PREFERENCE);
                        }}
                        data-testid="preferenceSwitch"
                        onColor={generalColors.primaryColor}
                      />
                    ) : (
                      "No"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.isUpdatePreference && (
              <div
                className={` ${styles.buttonContainer} ${
                  this.props.pageStyle && styles.preferenceButtonContainer
                } `}
              >
                <button
                  className={styles.secondary}
                  data-testid="popupOpen"
                  disabled={this.state.preferences[MARKETING_PREFERENCE]}
                  onClick={this.handleTogglePopup}
                >
                  Save Preferences
                </button>
              </div>
            )}
          </div>
        </div>
        {this.optOutConfirmation()}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

EmailPreferences.contextType = ThemeContext;
export default EmailPreferences;
