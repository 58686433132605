import request from "../../utils/request";
import { get, invoke, set } from "lodash";
import { USER_API, ME, API_URL } from "../../routes/pages/constants";
import StorageService from "../../utils/StorageService";
import { HEADER_ARTIST_LISTING_COUNT } from "../LoginHeader/constants";
import { STATUS_PENDING } from "../../routes/pages/Dashboard/constants";

export function fetchAccountImg() {
  const reqData = {
    method: "GET",
  };
  const requestURL = `${API_URL}${USER_API}${ME}?sortBy=-createdAt`;
  request(requestURL, reqData)
    .then((res) => {
      if (res.status) {
        if (res.data.activeArtist) {
          const imgUrlLength = res.data.activeArtist.profilePicture.length - 1;
          StorageService.set(
            "profileImg",
            get(res.data, `activeArtist.profilePicture[${imgUrlLength}].url`),
          );
          StorageService.set("activeArtist", res.data.activeArtist.name, {
            id: res.data.activeArtist._id,
          });
          if (
            get(res.data, "activeArtist.status") === STATUS_PENDING &&
            !get(res, "data.activeArtist.isJwtTokenGenerated")
          ) {
            if (get(res.data, "activeArtist.isFastFlow")) {
              invoke(
                this,
                "getGeneratingOfferStatus",
                get(res, "data.activeArtist", {}),
              );
            } else {
              this.registerEventSource(res.data.activeArtist._id);
            }
          }
        }
        const selectedArtist = get(res, "data.activeArtist", {});
        set(selectedArtist, "phone", get(res, "data.phone"));
        const artists = get(res, "data.linkedArtist", []).slice(
          0,
          HEADER_ARTIST_LISTING_COUNT,
        );
        if (res.data.role === "admin") {
          StorageService.set("role", res.data.role);
        }

        this.setState({
          imgUrl: res.data.profilePicture,
          role: res.data.role,
          userEmail: res.data.email.value,
          artists,
          selectedArtist,
        });

        this.props.updateUserData(selectedArtist, res.data.email.value);
        return res.data;
      }
    })
    .catch((err) => {});
}
