import React, { Component } from "react";
import { get, debounce } from "lodash";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./index.scss";
import Select from "react-select/async";
import { Form } from "formik";
import FormikForm from "../../../../component/Form/FormikForm";
import styles from "./LinkAnotherArtist.module.scss";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  DASHBOARD,
  ADD_ARTIST_FAST_FLOW,
  AUTH,
  FUNDING_OPTION,
  SEND_REPORTS,
  HOME,
} from "../../constants";
import { toast } from "react-toastify";
import Loader from "../../../../component/Loader";
import request from "../../../../utils/request";
import autofillStyle from "../Getfunded/autofillStyle";
import { formatOptionLabel } from "../Getfunded/formatOptionLabel";
import {
  NoOptionsMessage,
  LoadingMessage,
  SingleValue,
  getLabel,
  getOptions,
  handleChange,
  handleRemoveArtist,
  onSearchTypeChange,
} from "../Getfunded/helper";
import { GetErrorMessage } from "../../helper";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Image from "../../../../component/Image";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FormField from "../../../../component/FormField/FormField";
import {
  ESTIMATES_FIELD_NAMES,
  FAST_FLOW_DATA,
  FAST_FLOW_STEPS,
  MAX_POLLING_TIME,
  POLLING_INTERVAL,
} from "./constant";
import ReactTooltip from "react-tooltip";
import {
  FinalHoldTightWidget,
  HoldTightWidget,
  IncomeNotVerified,
  IncomeVerified,
  LongerThanExpected,
  RequestFailedWidget,
  TooBigWidget,
  TooSmallWidget,
} from "./FastFlowAckSlider";
import { USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED } from "../SignUp/constant";
import AuthTokenService from "../../../../utils/AuthTokenService";

class LinkAnohterArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      identifier: "phone",
      selectOptions: [],
      id: "",
      name: "",
      image: "",
      searchType: "name",
      showAckScreen: false,
      step: "",
      requestId: "",
      artistId: "",
      streamingIncomeCounter: 0,
      showstreamingInput: false,
      isGeneratingOffer: false,
    };
    this.getOptions = debounce(getOptions.bind(this), 600);
  }

  componentDidUpdate() {
    setTitle("Link Another Artist", this.context);
  }

  getArtistOfferingStatus = (isCallAgain = false) => {
    let pollingCount = 0;
    const apiMethod = {
      method: "GET",
    };
    const stopPolling = (step, artistAccessToken) => {
      clearInterval(pollingTimer);
      this.setState({ step });
      this.props.handleFastFlowStep(step);
      if (artistAccessToken) {
        AuthTokenService.storeToken(artistAccessToken);
      }
      if (step === FAST_FLOW_STEPS.IN_RANGE && artistAccessToken) {
        this.props.history.push(HOME);
      }
    };

    const pollArtistStatus = async () => {
      try {
        if (this.state.isGeneratingOffer) {
          pollingCount++;
          return;
        }
        this.setState({ isGeneratingOffer: true });
        const artistResponse = await request(
          `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}?requestId=${this.state.requestId}&artistId=${this.state.artistId}`,
          apiMethod,
        );
        this.setState({ isGeneratingOffer: false });
        if (!get(artistResponse, "status")) {
          stopPolling(FAST_FLOW_STEPS.ERROR);
          return;
        }
        const artistStatus = get(artistResponse, "data.artistStatus");
        if (artistStatus !== FAST_FLOW_STEPS.PENDING) {
          stopPolling(
            artistStatus,
            get(artistResponse, "data.artistAccessToken"),
          );
        } else if (pollingCount >= MAX_POLLING_TIME / POLLING_INTERVAL) {
          stopPolling(
            isCallAgain
              ? FAST_FLOW_STEPS.LONGER_THAN_EXPECTED_FINAL
              : FAST_FLOW_STEPS.LONGER_THAN_EXPECTED,
          );
        }
        pollingCount++;
      } catch (error) {
        this.setState({ isGeneratingOffer: true });
        stopPolling(FAST_FLOW_STEPS.ERROR);
      }
    };
    pollArtistStatus();
    const pollingTimer = setInterval(pollArtistStatus, POLLING_INTERVAL);
    this.pollingTimer = pollingTimer;
  };

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      spotifyId: this.state.id,
      streamingIncome: values.amount,
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${ADD_ARTIST_FAST_FLOW}`;
    const data = {
      method: "POST",
      body: payload,
    };
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (!res.status) {
          toast.error(get(res, "message"));
          return [];
        }
        if (
          get(res.data, "signup_status") ===
          USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED
        ) {
          this.setState(
            {
              showAckScreen: true,
              step: FAST_FLOW_STEPS.PENDING,
              artistId: get(res.data, "beatbread_artist_id"),
              requestId: get(res.data, "request_id"),
              amount: values.amount,
            },
            () => {
              this.props.handleFastFlowStep(FAST_FLOW_STEPS.PENDING);
              this.getArtistOfferingStatus(false);
            },
          );
          return true;
        } else {
          toast.success(get(res, "message"));
          this.props.handleDrawer(DASHBOARD);
        }
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
  };
  getStateValue = () => {
    return this.state.id && this.state.name
      ? {
          value: this.state.id,
          label: this.state.name,
          imageUrl: this.state.image,
        }
      : null;
  };

  renderLabel = (labelText, infoText) => (
    <div className={styles.flex}>
      <div className={styles.labelText}>{labelText}</div>
      {infoText && (
        <>
          <i
            className={styles.infoIcon}
            data-tip
            data-for={labelText}
            data-place="top"
          ></i>
          <ReactTooltip
            delayHide={100}
            class={styles.tooltip}
            effect="solid"
            place="top"
            id={labelText}
          >
            {infoText}
          </ReactTooltip>
        </>
      )}
    </div>
  );

  validationSchema = () => {
    return Yup.object().shape({
      identifier: Yup.string().label("Artist Name"),
      amount: Yup.number()
        .required()
        .integer()
        .min(1)
        .label("Streaming Income"),
    });
  };

  calledApiFail = () => {
    this.setState({ step: FAST_FLOW_STEPS.LONGER_THAN_EXPECTED });
  };

  handleCallAgain = () => {
    this.setState({ step: FAST_FLOW_STEPS.PENDING_AGAIN }, () => {
      this.getArtistOfferingStatus(true);
    });
  };

  uploadReports = async () => {
    this.setState({ loading: true });
    const apiData = {
      method: "GET",
    };
    request(
      `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}?requestId=${this.state.requestId}&artistId=${this.state.artistId}&isJWT=true`,
      apiData,
    )
      .then((res) => {
        const artistAccessToken = get(res, "data.artistAccessToken");
        if (get(res, "status") && artistAccessToken) {
          AuthTokenService.storeToken(artistAccessToken);
          this.setState({ loading: false });
          if (get(this.props, "location.pathname") !== SEND_REPORTS) {
            this.props.history.push(SEND_REPORTS);
            return;
          }
          this.props.history.push(HOME);
        } else {
          this.setState({ step: FAST_FLOW_STEPS.ERROR, loading: false });
          this.props.handleFastFlowStep(FAST_FLOW_STEPS.ERROR);
        }
      })
      .catch((err) => {
        this.setState({ step: FAST_FLOW_STEPS.ERROR, loading: false });
        this.props.handleFastFlowStep(FAST_FLOW_STEPS.ERROR);
      });
  };

  handleStreamingIncome = (res) => {
    if (get(res, "status")) {
      if (get(res, "data.streamingIncomeCounter") > 2) {
        this.setState({
          step: FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
          streamingIncomeCounter: get(res, "data.streamingIncomeCounter"),
        });
      } else {
        this.setState({ step: FAST_FLOW_STEPS.PENDING }, () => {
          this.getArtistOfferingStatus(false);
        });
      }
    } else {
      this.setState({
        loading: false,
        step: FAST_FLOW_STEPS.ERROR,
      });
      this.props.handleFastFlowStep(FAST_FLOW_STEPS.ERROR);
    }
  };

  handleRequestFailedWidgetClick = () => {
    this.setState({
      loading: false,
      showAckScreen: false,
      amount: 0,
      streamingIncomeCounter: 0,
      step: "",
      artistId: "",
      requestId: "",
      id: "",
      name: "",
      image: "",
    });
    this.props.handleFastFlowStep("");
  };

  renderSteps = () => {
    switch (this.state.step) {
      case FAST_FLOW_STEPS.PENDING:
        return <HoldTightWidget calledApiFail={this.calledApiFail} />;
      case FAST_FLOW_STEPS.LONGER_THAN_EXPECTED:
        return (
          <LongerThanExpected
            title={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.TITLE}
            subtitle={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.SUBTITLE}
            linkText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.LINK_TEXT}
            firstBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.FIRST_BTN}
            secondBtnText={
              FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.SECOND_BTN
            }
            isFinalExpected={false}
            onClick={this.handleCallAgain}
            uploadReports={this.uploadReports}
          />
        );
      case FAST_FLOW_STEPS.PENDING_AGAIN:
        return <FinalHoldTightWidget />;
      case FAST_FLOW_STEPS.LONGER_THAN_EXPECTED_FINAL:
        return (
          <LongerThanExpected
            title={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.TITLE}
            subtitle={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.SUBTITLE}
            linkText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.LINK_TEXT}
            firstBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.FIRST_BTN}
            secondBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.SECOND_BTN}
            isFinalExpected={true}
            uploadReports={this.uploadReports}
          />
        );
      case FAST_FLOW_STEPS.INCOME_VERIFIED:
        return (
          <IncomeVerified
            monthlyIncome={this.state.amount}
            requestId={this.state.requestId}
            artistId={this.state.artistId}
            streamingIncomeCounter={this.state.streamingIncomeCounter}
            showstreamingInput={this.state.showstreamingInput}
            uploadReports={this.uploadReports}
            handleStreamingIncome={this.handleStreamingIncome}
          />
        );
      case FAST_FLOW_STEPS.INCOME_NOT_VERIFIED:
        return <IncomeNotVerified uploadReports={this.uploadReports} />;
      case FAST_FLOW_STEPS.FAILED:
      case FAST_FLOW_STEPS.ERROR:
        return (
          <RequestFailedWidget onClick={this.handleRequestFailedWidgetClick} />
        );
      case FAST_FLOW_STEPS["2SMALL"]:
      case FAST_FLOW_STEPS["2NEW"]:
        return <TooSmallWidget artistName={this.state.name} />;
      case FAST_FLOW_STEPS["2BIG"]:
        return <TooBigWidget artistName={this.state.name} />;
      default:
        break;
    }
  };

  handleToggleClose = () => {
    if (this.state.step !== "") {
      this.props.toggleDrawer(false, true);
    } else {
      this.props.toggleDrawer(false);
    }
  };

  renderSearchToggleText = () =>
    this.state.searchType === "name"
      ? "Use Spotify ID instead"
      : "I prefer artist name";

  renderPlaceholder = () => (this.state.searchType === "id" ? "Spotify" : "");

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.logoContainer} onClick={this.handleToggleClose}>
          <div
            className={styles.closeIcon}
            data-testid="closeIcon"
            onClick={() => this.props.handleDrawer(DASHBOARD)}
          >
            <KeyboardBackspaceIcon />
          </div>
          <Image
            src={get(this.context, "emailLogo", "")}
            className={styles.logo}
            alt="Logo"
          />
        </div>
        {!this.state.showAckScreen ? (
          <div className={styles.mainContainer}>
            <div className={styles.subContainer}>
              <FormikForm
                initialValues={{
                  identifier: "",
                  amount: 0,
                }}
                validationSchema={this.validationSchema()}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                <div className={styles.title}>
                  <h4
                    onClick={() => this.props.handleDrawer(DASHBOARD)}
                    data-testid="leftIcon"
                  >
                    <ChevronLeftIcon />
                    Back to my Artists
                  </h4>
                  <h3>Add an Artist</h3>
                </div>
                <Form>
                  <div className={styles.formContainer}>
                    <div className="form-group mb-0">
                      <div className={styles.spotifySearch}>
                        <Select
                          maxMenuHeight={176}
                          id="artistSelect"
                          data-test="SpotifySelect"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isClearable={false}
                          value={this.getStateValue()}
                          loadOptions={(inputValue, callback) =>
                            this.getOptions.call(this, inputValue, callback)
                          }
                          onChange={(e) => handleChange.call(this, e)}
                          formatOptionLabel={formatOptionLabel}
                          name="identifier"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: (prop) =>
                              SingleValue(
                                prop,
                                (props) => handleRemoveArtist.call(this, props),
                                this.state.image,
                                this.state.searchType,
                              ),
                            NoOptionsMessage,
                            LoadingMessage,
                          }}
                          styles={autofillStyle}
                          placeholder={`${this.renderPlaceholder()} Artist ${getLabel(
                            this.state.searchType,
                          )}`}
                        />
                      </div>

                      <Link className={styles.emailorphone} to={"#"}>
                        <span
                          data-test="SearchType"
                          onClick={() => onSearchTypeChange.call(this)}
                        >
                          {this.renderSearchToggleText()}
                        </span>
                      </Link>
                    </div>
                    <div className={`form-group mb-0`}>
                      <FormField
                        name={ESTIMATES_FIELD_NAMES.AMOUNT.NAME}
                        as="numeric"
                        defaultValue={0}
                        placeholder={ESTIMATES_FIELD_NAMES.AMOUNT.PLACEHOLDER}
                        label={this.renderLabel(
                          ESTIMATES_FIELD_NAMES.AMOUNT.LABEL,
                          ESTIMATES_FIELD_NAMES.AMOUNT.TOOLTIP,
                        )}
                        containerClass={styles.mainInputContainer}
                        errorClass={styles.errorClass}
                        prefix="$"
                        suffix="  USD"
                        autoComplete="off"
                        noFloating
                      />
                    </div>
                  </div>
                  <div className={`form-group mb-0 ${styles.btnContainer}`}>
                    <button
                      className={styles.cancelBtn}
                      type="button"
                      name="button"
                      onClick={() => this.props.handleDrawer(DASHBOARD)}
                      data-testid="cancelBtn"
                    >
                      Cancel
                    </button>
                    <button
                      className={styles.btn}
                      disabled={this.state.id === ""}
                      type="submit"
                      name="submit"
                    >
                      Add Artist
                    </button>
                  </div>
                </Form>
              </FormikForm>
            </div>
            {this.state.loading && <Loader />}
          </div>
        ) : (
          this.renderSteps()
        )}
      </div>
    );
  }
}

LinkAnohterArtist.contextType = ThemeContext;
export default LinkAnohterArtist;
