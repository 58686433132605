import React, { Component, createRef } from "react";
import { get } from "lodash";
import * as Yup from "yup";
import styles from "./AcceptOffer.module.scss";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { getSubDomain, setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  BB_SUBDOMAINS,
  DEFAULT_BB_THEME,
} from "../../../component/ThemeManager/constants";
import { ErrorMessage, Form, Formik } from "formik";
import FormField from "../../../component/FormField/FormField";
import {
  BOOLEAN_RADIO,
  COUNTRY_USA,
  ENTITY_OPTIONS,
  FORM_STEPS,
  FOUND_US_OPTIONS,
  GUIDE_URL,
  ONBOARDING_INITIAL_DATA,
  PLAID_WINDOW_CHECK_TIMER,
  TRACK_OWN_OPTIONS,
} from "./constants";
import {
  ACCEPT_OFFER,
  API_URL,
  ARTIST_API,
  MARKETPLACE_ONBOARDING,
  PLAID_IDENTITY_VERIFICATION,
  ONBOARDING_DETAILS,
  POSTED_TO_NETWORK,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import Loader from "../../../component/Loader";
import { EMAIL_REGEX, PHONE_REGEX } from "../Auth/constants";
import { ReactComponent as AttachIcon } from "../../../assets/logos/attach-file-icon.svg";
import { ALPHA_WITH_APOSTROPHE_HYPHEN } from "../PostInvestorNetwork/UpcomingWorks/constant";

class ArtistOnboardingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: ONBOARDING_INITIAL_DATA,
      currentFormStep: FORM_STEPS.STEP1,
      plaidStatus: "",
      btnLoading: false,
      userCountry: "",
      userPhone: "",
      userEmail: "",
    };
    this.plaidWindowInterval = null;
    this.fileUploadRef = createRef();
    this.publicityPhotoUpload = createRef();
    this.phoneRef = createRef();
  }

  componentDidUpdate() {
    setTitle("Onboarding Form", this.context);
  }

  componentDidMount() {
    this.getFormDetails();
  }

  checkUniqueEmail = (value) => value !== this.state.userEmail;
  checkUniquePhone = (value) => value !== this.state.userPhone;

  stepTwoValidations = {
    is: (entityType) => entityType === ENTITY_OPTIONS[1].value,
    then: Yup.string()
      .required()
      .test(
        "unique",
        "Email address must be unique. Please enter the artist's email.",
        this.checkUniqueEmail,
      )
      .test(
        "unique",
        "Phone number must be unique. Please enter the artist's phone number.",
        this.checkUniquePhone,
      ),
  };

  otherFieldValidation = {
    is: (dealTracksOwnBy) => dealTracksOwnBy === TRACK_OWN_OPTIONS[3].value,
    then: Yup.string().required(),
  };

  formValidationSchema = Yup.object().shape({
    entityType: Yup.string().required().label(`"I am"`),
    isTrackOnLease: Yup.string().required().label("This"),
    dealTracksOwnBy: Yup.string().required().label("This"),
    dealTracksOwnByOther: Yup.string()
      .label("This")
      .when("dealTracksOwnBy", this.otherFieldValidation),
    w8benPDF: Yup.mixed().label("This").required(),
    publicityPhoto: Yup.mixed().label("Publicity Photo"),
    foundUsFrom: Yup.string().required().label("Hear about us"),
    name: Yup.string()
      .matches(ALPHA_WITH_APOSTROPHE_HYPHEN, "Please enter valid name")
      .label("Name")
      .when("entityType", this.stepTwoValidations),
    email: Yup.string()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address")
      .when("entityType", this.stepTwoValidations),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Please enter valid phone")
      .label("Phone number")
      .when("entityType", this.stepTwoValidations)
      .test("is_right_country", "Please enter valid phone", (value) => {
        const phone = get(this.phoneRef, "current.values.phone");
        const code = get(this.phoneRef, "current.values.countryCode");
        const type = get(this.phoneRef, "current.values.entityType");
        if (
          type === ENTITY_OPTIONS[1].value &&
          (code === "1" || code === "44")
        ) {
          return phone.length - code.length === 10 ? true : false;
        }
        return true;
      }),
  });

  setDataInForm = (data) => {
    const initialValues = {};
    for (const key in ONBOARDING_INITIAL_DATA) {
      initialValues[key] = data[key];
      if (key === "dealTracksOwnBy" && data[key]) {
        const selectedOption = TRACK_OWN_OPTIONS.filter(
          (option) => option.value === data[key],
        );
        if (!selectedOption.length) {
          initialValues[key] = TRACK_OWN_OPTIONS[3].value;
          initialValues["dealTracksOwnByOther"] = data[key];
        }
      }
      if (key === "dealTracksOwnByOther") {
        initialValues["dealTracksOwnByOther"] =
          initialValues["dealTracksOwnBy"] === TRACK_OWN_OPTIONS[3].value
            ? data["dealTracksOwnBy"]
            : "";
      }
      if (typeof data[key] === "boolean") {
        initialValues[key] = data[key] ? "1" : "0";
      }
      if (key === "phone") {
        initialValues[key] = `${get(data, "countryCode", "")}${get(
          data,
          key,
          "",
        )}`;
      }
    }
    this.setState({ initialValues });
  };

  getFormDetails = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.status) {
          const data = get(
            res.data,
            "onboardingDetails",
            ONBOARDING_INITIAL_DATA,
          );
          this.setState({
            userCountry: get(res.data, "country"),
            userPhone: `${get(res.data, "phone.countryCode", "")}${get(
              res.data,
              "phone.value",
              "",
            )}`,
            userEmail: get(res.data, "email", ""),
          });
          this.setDataInForm(data);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  getPlaidStatue = () => {
    this.setState({ btnLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${PLAID_IDENTITY_VERIFICATION}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ btnLoading: false });
        if (res && res.status) {
          this.setState({ plaidStatus: get(res.data, "status", "") });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ btnLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  checkPlaidWindow = (plaidWindow) => {
    if (plaidWindow.closed) {
      this.state.plaidStatus !== "success" && this.getPlaidStatue();
      this.setState({ loading: false, currentFormStep: FORM_STEPS.STEP3 });
      clearInterval(this.plaidWindowInterval);
    }
  };

  initiateIdentityVerification = () => {
    this.proceedToNextStep(false);
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${PLAID_IDENTITY_VERIFICATION}`;
    request(requestUrl, data)
      .then((res) => {
        if (res && res.status) {
          this.setState({ plaidStatus: get(res.data, "status", "") });
          const plaidWindow = window.open(
            get(res.data, "url", ""),
            "plaidWindow",
            "width=500,height=700",
          );
          this.plaidWindowInterval = setInterval(
            this.checkPlaidWindow.bind(this, plaidWindow),
            PLAID_WINDOW_CHECK_TIMER,
          );
          return true;
        }
        this.setState({ loading: false });
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  proceedToNextStep = (redirection) => {
    redirection && this.setState({ currentFormStep: FORM_STEPS.STEP2 });
  };

  validateCurrentStep = async (formProperties) => {
    const fields = Object.keys(ONBOARDING_INITIAL_DATA);
    const errors = await formProperties.validateForm();
    switch (this.state.currentFormStep) {
      case FORM_STEPS.STEP1:
        if (errors[fields[0]]) {
          formProperties.setFieldTouched(fields[0], true, true);
          return false;
        }
        formProperties.values[fields[0]] === ENTITY_OPTIONS[0].value
          ? this.initiateIdentityVerification()
          : this.proceedToNextStep(true);
        break;
      case FORM_STEPS.STEP2:
        if (errors[fields[1]] || errors[fields[2]] || errors[fields[3]]) {
          formProperties.setFieldTouched(fields[1], true, true);
          formProperties.setFieldTouched(fields[2], true, true);
          formProperties.setFieldTouched(fields[3], true, true);
          return false;
        }
        this.setState({ currentFormStep: FORM_STEPS.STEP3 });
        break;
      case FORM_STEPS.STEP3:
        if (
          [
            errors[fields[5]],
            errors[fields[6]],
            errors[fields[7]],
            errors[fields[8]],
            errors[fields[9]],
            errors[fields[10]],
          ].reduce((prev, next) => prev || next, false)
        ) {
          formProperties.setFieldTouched(fields[5], true, true);
          formProperties.setFieldTouched(fields[6], true, true);
          formProperties.setFieldTouched(fields[7], true, true);
          formProperties.setFieldTouched(fields[8], true, true);
          formProperties.setFieldTouched(fields[9], true, true);
          formProperties.setFieldTouched(fields[10], true, true);
          return false;
        }
        formProperties.handleSubmit();
        break;
      default:
        break;
    }
  };

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const formKeys = Object.keys(ONBOARDING_INITIAL_DATA);
    const formData = new FormData();
    formKeys.forEach((element) => {
      formData.append(element, values[element]);
      if (element === formKeys[5]) {
        formData.delete(element);
        formData.append(element, `${!!+values[element]}`);
      }
      if (element === formKeys[6]) {
        formData.delete(element);
        formData.append(
          element,
          values[element] === TRACK_OWN_OPTIONS[3].value
            ? values[formKeys[7]]
            : values[element],
        );
      }
      if (element === formKeys[3]) {
        formData.delete(element);
        formData.append(
          element,
          values[element].replace(values[formKeys[4]], ""),
        );
      }
      if (
        [formKeys[7], formKeys[8], formKeys[9]].indexOf(element) !== -1 &&
        typeof values[element] === "string"
      ) {
        formData.delete(element);
      }
    });

    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.status) {
          this.props.history.replace(
            get(this.props.location, "pathname") === MARKETPLACE_ONBOARDING
              ? POSTED_TO_NETWORK
              : ACCEPT_OFFER,
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  formHeaderRender = () => (
    <h2 className={styles.formTitle}>
      <Image
        src={get(this.context, "emailLogo")}
        alt={"logo"}
        imageType={IMAGE_TYPE.LOGO}
        className={`${
          get(this.context, "slugName") !== DEFAULT_BB_THEME.slugName
            ? styles.hide
            : ""
        }`}
      />
      <p>
        Final <span>Onboarding</span>
      </p>
    </h2>
  );

  goBack = (formProperties) => {
    get(formProperties, "values.entityType", "") === ENTITY_OPTIONS[0].value
      ? this.setState({ currentFormStep: FORM_STEPS.STEP1 })
      : this.setState({
          currentFormStep: `step${+this.state.currentFormStep.charAt(4) - 1}`,
        });
  };

  buttonContainerRender = (formProperties) => (
    <div className={styles.btnContainer}>
      {this.state.currentFormStep !== FORM_STEPS.STEP1 && (
        <div className={styles.stepDotsContainer}>
          <span
            className={`${
              this.state.currentFormStep === FORM_STEPS.STEP2
                ? styles.active
                : ""
            }`}
          ></span>
          <span
            className={`${
              this.state.currentFormStep === FORM_STEPS.STEP3
                ? styles.active
                : ""
            }`}
          ></span>
        </div>
      )}
      <div className={styles.nextBackContainer}>
        {this.state.currentFormStep !== FORM_STEPS.STEP1 && (
          <button
            className={styles.secondary}
            onClick={() => this.goBack(formProperties)}
            data-testid="backButton"
          >
            Back
          </button>
        )}
        <button
          type="button"
          onClick={() => this.validateCurrentStep(formProperties)}
          disabled={this.state.btnLoading}
          data-testid="nextButton"
        >
          {this.state.btnLoading && (
            <span className={`spinner-border spinner-border-sm`} />
          )}{" "}
          Next step
        </button>
      </div>
    </div>
  );

  onFileUpload = () => {
    this.fileUploadRef.current.click();
  };

  onPublicityPhotoUpload = () => {
    this.publicityPhotoUpload.current.click();
  };

  renderW8Field = (formProperties) => {
    let currentBEN = GUIDE_URL.W8_BEN;
    switch (get(formProperties, "values.dealTracksOwnBy")) {
      case TRACK_OWN_OPTIONS[1].value:
      case TRACK_OWN_OPTIONS[2].value:
        currentBEN = GUIDE_URL.W8_BEN_E;
        break;
      default:
        break;
    }
    if (this.state.userCountry === COUNTRY_USA) {
      currentBEN = GUIDE_URL.W9_US;
    }
    return (
      <>
        <label className={styles.labelTitle}>{currentBEN.label}</label>
        <label className={`${styles.smallText} m-0`}>
          This form can be downloaded here:{" "}
        </label>
        <div className={styles.w8Container}>
          <a
            href={currentBEN.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkText}
          >
            {currentBEN.url}
          </a>
          <div className={styles.fileUpload} onClick={this.onFileUpload}>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              name="w8benPDF"
              ref={this.fileUploadRef}
              onChange={(e) => {
                formProperties.setFieldValue("w8benPDF", e.target.files[0]);
              }}
            />
            <AttachIcon />
            <span>Attach File</span>
          </div>
          <div
            className={`invalid-feedback d-flex position-absolute w-auto ${styles.errorClass}`}
          >
            <ErrorMessage name="w8benPDF" />
          </div>
        </div>
      </>
    );
  };

  formStepRender = (formProperties) => {
    switch (this.state.currentFormStep) {
      case FORM_STEPS.STEP1:
        return (
          <>
            {this.formHeaderRender()}
            <p className={styles.contentText}>
              Please identify which of the following you are, and we will take
              you through the appropriate onboarding process.
            </p>
            <label className={styles.labelTitle} htmlFor="entityType">
              I am
            </label>
            <FormField
              as="radio"
              name="entityType"
              radioValueList={ENTITY_OPTIONS}
            />
            {this.buttonContainerRender(formProperties)}
          </>
        );
      case FORM_STEPS.STEP2:
        if (
          get(formProperties, "values.entityType", "") ===
          ENTITY_OPTIONS[0].value
        ) {
          return <></>;
        }
        return (
          <>
            {this.formHeaderRender()}
            <p className={styles.contentText}>
              Please fill out the form below & let us know if you have any
              questions!
            </p>
            <p className={styles.contentText}>
              The contact information provided should belong to the artist /
              entity who owns the tracks included in this deal.
            </p>
            <div className={`form-group mb-0`}>
              <FormField
                name="name"
                placeholder="Enter Name"
                type="text"
                label="Artist / Owner's Legal Name(as printed on ID)"
              />
            </div>
            <div className={`form-group mb-2`}>
              <FormField
                name="email"
                placeholder="Enter Email"
                type="text"
                label={<span>Artist / Owner's E-Mail Address</span>}
              />
            </div>
            <div className={`form-group mb-2`}>
              <FormField
                name="phone"
                placeholder="Enter Number"
                as="phone"
                label={<span>Artist / Owner's Phone Number</span>}
              />
            </div>

            {this.buttonContainerRender(formProperties)}
          </>
        );
      case FORM_STEPS.STEP3:
        return (
          <>
            {this.formHeaderRender()}
            <p className={styles.contentText}>
              We just need a few more items to finalize your advance!
            </p>
            <label className={styles.labelTitle} htmlFor="isTrackOnLease">
              Does the artist have a current or existing advance on these tracks
              with any other partner?
            </label>
            <FormField
              as="radio"
              name="isTrackOnLease"
              radioValueList={BOOLEAN_RADIO}
            />
            <div className={`form-group mb-0 ${styles.sentenceCase}`}>
              <FormField
                as="select"
                name="dealTracksOwnBy"
                options={TRACK_OWN_OPTIONS}
                label="Who owns the tracks included in this deal?"
              />
            </div>
            {get(formProperties, "values.dealTracksOwnBy") ===
              TRACK_OWN_OPTIONS[3].value && (
              <div className={`form-group ${styles.showPlaceholder}`}>
                <FormField
                  name="dealTracksOwnByOther"
                  placeholder="Who owns the tracks included in this deal?"
                />
              </div>
            )}
            {get(formProperties, "values.dealTracksOwnBy") && (
              <>
                {this.renderW8Field(formProperties)}
                {get(formProperties, "values.w8benPDF.name") && (
                  <div className={styles.filePreview}>
                    <AttachIcon />
                    {get(formProperties, "values.w8benPDF.name")}
                  </div>
                )}
              </>
            )}
            <>
              <label className={styles.labelTitle}>Publicity Photo</label>
              <div className={styles.w8Container}>
                <div
                  className={`${styles.fileUpload} ${styles.publicity}`}
                  onClick={this.onPublicityPhotoUpload}
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="publicityPhoto"
                    ref={this.publicityPhotoUpload}
                    onChange={(e) => {
                      formProperties.setFieldValue(
                        "publicityPhoto",
                        e.target.files[0],
                      );
                    }}
                  />
                  <AttachIcon />
                  <span>Attach File</span>
                </div>
                <div
                  className={`invalid-feedback d-flex position-absolute w-auto ${styles.errorClass}`}
                >
                  <ErrorMessage name="publicityPhoto" />
                </div>
              </div>
              {get(formProperties, "values.publicityPhoto.name") && (
                <div className={`${styles.filePreview} ${styles.publicity}`}>
                  <AttachIcon />
                  {get(formProperties, "values.publicityPhoto.name")}
                </div>
              )}
            </>
            <div
              className={`form-group ${styles.sentenceCase} ${styles.separator}`}
            >
              <FormField
                as="select"
                name="foundUsFrom"
                placeholder="Select"
                label="How did you hear about us?"
                options={FOUND_US_OPTIONS}
                components={{ IndicatorSeparator: null }}
              />
            </div>
            {this.buttonContainerRender(formProperties)}
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    return (
      <>
        <div
          className={`${styles.onboardingContainer} ${
            BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
              ? styles.backgroundImage
              : ""
          }`}
        >
          <div className={styles.headerContainer}>
            <Image
              src={get(this.context, "appLogo")}
              alt="logo"
              imageType={IMAGE_TYPE.LOGO}
              className={styles.instanceLogo}
            />
          </div>
          <div className={styles.subContainer}>
            <div className={styles.formContainer}>
              <Formik
                initialValues={this.state.initialValues}
                enableReinitialize
                validationSchema={this.formValidationSchema}
                onSubmit={this.handleSubmit}
                data-testid="formRender"
                innerRef={this.phoneRef}
              >
                {({
                  values,
                  validateForm,
                  setFieldValue,
                  setFieldError,
                  setFieldTouched,
                  handleSubmit,
                  errors,
                }) => (
                  <Form>
                    {this.formStepRender({
                      values,
                      errors,
                      validateForm,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      handleSubmit,
                    })}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </>
    );
  }
}

ArtistOnboardingForm.contextType = ThemeContext;
export default ArtistOnboardingForm;
