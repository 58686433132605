import React from "react";
import { Route, Redirect } from "react-router";
import PropTypes from "prop-types";
import { userExists } from "../utils/Helper";
import { HOME } from "./pages/constants";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !userExists() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: HOME,
            state: { fromLogin: true },
          }}
        />
      )
    }
    data-testid="routeRender"
  />
);
AuthRoute.propTypes = {
  component: PropTypes.any,
};
export default AuthRoute;
