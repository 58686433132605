import React from "react";
import styles from "./FastFlowAckSlider.module.scss";
import { RIGHTS_TYPES } from "../../YourAdvance/constants";

export const ESTIMATES_FIELD_NAMES = {
  AMOUNT: {
    NAME: "amount",
    LABEL: "How much streaming income did this artist receive last month?",
    PLACEHOLDER: "$USD",
    TOOLTIP:
      "An approximate number is fine but the more accurate this value is the more accurate your advance estimates will be.",
    VALIDATION_MSG_MIN: "Streaming income cannot be less than 0",
    VALIDATION_MSG_TYPE: "Streaming income must be a number",
  },
  CONTACT_NAME: {
    NAME: "name",
    LABEL: "Contact Name",
    PLACEHOLDER: "First Last",
    TOOLTIP: "contact name",
  },
  EMAIL: {
    NAME: "email",
    LABEL: "Email",
    PLACEHOLDER: "you@example.com",
    TOOLTIP: "Email",
    VALIDATION_MSG: "Please enter valid email only",
  },
  PHONE_NUMBER: {
    NAME: "phone",
    LABEL: "Phone Number",
    PLACEHOLDER: "Phone Number",
    TOOLTIP: "Phone Number",
    VALIDATION_MSG: "Please enter valid phone number only",
  },
  PASSWORD: {
    NAME: "password",
    LABEL: "Create Password",
    PLACEHOLDER: "Create Password",
  },
  RELATIONSHIP_WITH_ARTIST: {
    NAME: "relationshipWithArtist",
    LABEL: "What's your relationship with this artist?",
    PLACEHOLDER: "I am..",
    VALIDATION_MSG: "Please select a relationship",
  },
  MAJOR_DISTRIBUTOR: {
    NAME: "majorDistributor",
    LABEL: "Who distributes the majority of this artist's music?",
    PLACEHOLDER: "Select an Option",
    VALIDATION_MSG: "Please select a distributor",
  },
  SWITCH_DISTRIBUTOR: {
    NAME: "switchDistributor",
    LABEL: "Is this artist able to switch distributors?",
    PLACEHOLDER: "Select an Option",
  },
};

export const MAJOR_DISTRIBUTOR_OPTIONS = [
  { value: "A Major Label", label: "A Major Label" },
  { value: "I don't know", label: "I don't know" },
  { value: "Other", label: "Other" },
];

export const FAST_FLOW_STEPS = {
  LONGER_THAN_EXPECTED: "LongerThanExpected",
  LONGER_THAN_EXPECTED_FINAL: "LongerThanExpectedFinish",
  INCOME_VERIFIED: "revenue_warning",
  INCOME_NOT_VERIFIED: "revenue_danger",
  REVIEW_ESTIMATE: "ReviewEstimate",
  UPLOAD_REPORTS: "UploadReports",
  SERVICE_DOWN: "ServiceDown",
  STREAMING_INCOME: "StreamingIncome",
  PENDING: "pending",
  IN_RANGE: "in_range",
  FAILED: "failed",
  "2SMALL": "too_small",
  "2BIG": "too_big",
  "2NEW": "2NEW",
  ERROR: "error",
  PENDING_AGAIN: "pending_again",
  CHOOSE_PATH: "choose_path",
  REQUEST_CALL: "request_call",
};

export const FAST_FLOW_DATA = {
  LONGER_THAN_EXPECTED_BEFORE: {
    TITLE: "Still Working",
    SUBTITLE:
      "We’re still working on generating your estimates.  You can upload reports now and we’ll send you confirmed offers instead.",
    LINK_TEXT: "Would you like to continue waiting for estimates?",
    FIRST_BTN: "NO, UPLOAD REPORTS",
    SECOND_BTN: "YES, WAIT",
  },
  LONGER_THAN_EXPECTED: {
    TITLE: "Longer than Expected",
    SUBTITLE:
      "We encountered unexpected delays while generating your estimates. Rest assured, we’ll continue working on it. You can upload reports now and we’ll send you confirmed offers instead, or you can read more about beatBread and what we have to offer.",
    LINK_TEXT: "We’ll email you when your estimates are ready!",
    FIRST_BTN: "LEARN MORE",
    SECOND_BTN: "UPLOAD REPORTS",
  },
};

export const POLLING_INTERVAL = 2000;
export const MAX_POLLING_TIME = 20000;
export const A_BIT_LONGER = 5000;
export const KNOWLEDGE_CENTER = "/knowledgecenter";

export const RELATIONSHIP_OPTIONS = [
  { value: "The artist", label: "The artist" },
  { value: "The manager", label: "The manager" },
  { value: "The lawyer", label: "The lawyer" },
  { value: "The distributor", label: "The distributor" },
  { value: "The label", label: "The label" },
  { value: "A fan", label: "A fan" },
];

export const CHOOSE_PATH_FIELDS = (rightsType = RIGHTS_TYPES[1].value) => {
  return {
    NAME: "path",
    LABEL: "",
    OPTIONS: [
      {
        label: (
          <span className={styles.radioLabel}>
            <span>{`Upload ${
              rightsType === RIGHTS_TYPES[0].value
                ? "Publishing"
                : "Distribution"
            } Reports`}</span>
            <span>
              This is the fastest way to get confirmed offers within two
              business days.
            </span>
          </span>
        ),
        value: "uploadReport",
      },
      {
        label: (
          <span className={styles.radioLabel}>
            <span>Get Estimates</span>
            <span>
              {`Check out advance estimates before uploading your ${
                rightsType === RIGHTS_TYPES[0].value
                  ? "publishing"
                  : "distribution"
              }
                reports`}
            </span>
          </span>
        ),
        value: "getEstimates",
      },
      {
        label: (
          <span className={styles.radioLabel}>
            <span>Request a Call</span>
            <span>
              Have questions about our funding process or options? A team member
              will email you to set up a call.
            </span>
          </span>
        ),
        value: "requestCall",
      },
    ],
  };
};
