export const RANGE_MIN = 1;
export const RANGE_MAX = 2;
export const RANGE_STEP = 0.05;

export const IMG_MAX_SIZE = 4194304;
export const IMG_EXTENSIONS_REGEX = /^(.*?).(jpg|JPG|jpeg|JPEG|png|PNG)$/;

export const ERR_IMG_TYPE = 0;
export const ERR_IMG_SIZE = 1;
export const IMG_VALIDATE = 2;

export const ROLE_OPTIONS = [
  { displayLabel: "Artist", value: "artist", label: "An Artist" },
  { displayLabel: "Manager", value: "manager", label: "A Manager" },
  {
    displayLabel: "Distributor",
    value: "distributor",
    label: "A Distributor",
  },
  { displayLabel: "Indie Label", value: "label", label: "An Indie Label" },
  {
    displayLabel: "Artist Team Member",
    value: "member",
    label: "A Member of Artist Team",
  },
  { displayLabel: "Other", value: "other", label: "Other" },
];

export const PLACES_API_KEY = process.env.REACT_APP_PLACES_KEY;
