import React, { Component } from "react";
import segment from "../../utils/segment.js";
import { invoke } from "lodash";

const EMPTY_EVENT = [{
	type: '',
	name: '',
	status: false,
}]

function Segment(WrappedComponent, props) {

	return class extends Component {
		constructor() {
			super();
			this.state = {
				events: props?.events || EMPTY_EVENT,
			};
		}

		updateEvent = (name, status) => {
			const newEvents = this.state.events.map((e) => {
				if (e.name === name) {
					return {
						...e,
						status: status || e.status,
					};
				} else {
					return e;
				}
			});
			this.setState({events: newEvents});
		}
	
		fireEvent = (name,args) => {
			const event = this.state.events.find(a => a.name === name);
			if (!event?.status && event?.type && event?.name) {
				invoke(segment, `${event.type}${event?.name ? `.${event.name}` : ''}`, args || null);
				this.updateEvent(name,true);
			}
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					updateEvent={this.updateEvent}
					fireEvent={this.fireEvent}
				/>
			)
		}
	}

}

export default Segment;
