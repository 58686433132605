export const DEFAULT_SOCIAL_COUNT = {
    youtube: 0,
    spotify: 0,
    instagram: 0
};
export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const COLLABORATOR_TYPES = [
    { displayLabel: "Featured Artist", label: "Featured Artist", value: "featured_artist" },
    { displayLabel: "Producer / A&R", label: "Producer / A&R", value: "producer" },
    { displayLabel: "Distributor", label: "Distributor", value: "distributor" },
    { displayLabel: "Independent Label", label: "Independent Label", value: "independent_label" },
    { displayLabel: "Marketing Partner", label: "Marketing Partner", value: "marketing" },
    { displayLabel: "Other", label: "Other", value: "other" },
]
export const ALPHA_WITH_APOSTROPHE_HYPHEN = /^[a-zA-Z'.\s-]*$/;
export const ALPHA_WITH_APOSTROPHE_HYPHEN_FULLNAME = /^[a-zA-Z'-]+(?: [a-zA-Z'-]+)+$/;
export const INITIAL_PROJECT_COLLABORATOR = [
    {
        name: "",
        role: ""
    }
];
export const ADD = "add";
export const DELETE = "delete";
export const PROJECT_DETAIL_MAX_VIEW_CHAR = 400;
export const PROJECT_COLLA_MAX_VIEW_CHAR = 100;
