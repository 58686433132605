export const userSearchStyle = {
  singleValue: (provided) => ({
    ...provided,
    alignItems: "center",
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    outline: "transparent",
    borderColor: "#bbbbbb",
    backgroundColor: "#fff",
    borderRadius: 2,
    borderBottom: state.selectProps.inputValue.length ? 0 : "1px solid #bbbbbb",
    padding: "13px 15px",
    "&:hover": { borderColor: "none", outline: "none" },
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
    color: "#000",
    cursor: "text",
  }),

  input: (base) => ({
    ...base,
    color: "#000",
  }),

  menu: (base, state) => ({
    ...base,
    borderColor: state.selectProps.inputValue.length
      ? "#bbbbbb"
      : "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    outline: "none",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    paddingBottom: state.selectProps.inputValue.length ? "10px" : "0",
    backgroundColor: state.selectProps.inputValue.length
      ? "#fff"
      : "transparent",
    width: state.selectProps.inputValue.length ? "100%" : "0",
  }),

  option: (base) => ({
    ...base,
    backgroundColor: "#fff",
    padding: "7px 20px",
    "&:active": { backgroundColor: "transparent" },
  }),

  menuList: (base) => ({
    ...base,
    paddingTop: 10,
    marginTop: "-1px",
    backgroundColor: "#fff",
  }),
};

export const artistDropDownStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.selectProps.menuIsOpen && "0px 0px 6px 0px #00000012",
    clickPath: state.selectProps.menuIsOpen && "inset(-6px -6px 0px -6px)",
    outline: "transparent",
    border: "none",
    color: "black",
    paddingTop: 10,
  }),
  menu: (base, state) => {
    return {
      ...base,
      borderTop: "none",
      boxShadow: "0 4px 6px 0 rgba(200, 200, 200, 0.7)",
      clickPath: "inset(0px -6px -7px -7px)",
      borderRadiusTopLeft: 0,
      borderRadiusTopRight: 0,
      marginTop: 0,
      paddingBottom: 10,
      paddingTop: 10,
    };
  },

  option: (provided, state) => ({
    ...provided,
    padding: "8px 18px",
    fontSize: 12,
    backgroundColor: state.isSelected && state.isFocused && "white",
  }),
};

const autofillStyle = {
  singleValue: (provided) => ({
    ...provided,
    alignItems: "center",
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    outline: "transparent",
    borderColor: "#bbbbbb",
    backgroundColor: "#fff",
    borderRadius: 2,
    borderBottom: state.selectProps.inputValue.length ? 0 : "1px solid #bbbbbb",
    padding: "13px 15px",
    "&:hover": { borderColor: "none", outline: "none" },
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
    color: "#000",
    cursor: "text",
  }),

  input: (base) => ({
    ...base,
    color: "#000",
  }),

  menu: (base, state) => ({
    ...base,
    borderColor: state.selectProps.inputValue.length
      ? "#bbbbbb"
      : "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    outline: "none",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    paddingBottom: state.selectProps.inputValue.length ? "10px" : "0",
    backgroundColor: state.selectProps.inputValue.length
      ? "#fff"
      : "transparent",
    width: state.selectProps.inputValue.length ? "100%" : "0",
    "&::before": state.selectProps.inputValue.length && {
      content: `' '`,
      position: "absolute",
      width: "90%",
      border: "1px solid transparent",
      borderTopColor: "#383838",
      zIndex: 1,
      transform: "translate(5%,-10px)",
    },
  }),

  option: (base) => ({
    ...base,
    backgroundColor: "#fff",
    padding: "7px 20px",
    "&:active": { backgroundColor: "transparent" },
  }),

  menuList: (base) => ({
    ...base,
    paddingTop: 10,
    marginTop: "-1px",
    backgroundColor: "#fff",
  }),
};

export default autofillStyle;
