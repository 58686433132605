import React, { Component } from "react";
import { get, invoke } from "lodash";
import { Link } from "react-router-dom";
import { Form } from "formik";
import * as Yup from "yup";
import base from "base-64";
import StorageService from "../../../../utils/StorageService";
import LoginSideBar from "../../../../component/LoginSideBar";
import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  SIGNIN_API,
  FORGOT_PASS,
  LOGIN,
  HOME,
  FF_SECURE_LOGIN,
  ACK_REDIRECT,
  ARTIST_FUNDING,
} from "../../constants";
import AuthTokenService from "../../../../utils/AuthTokenService";
import AuthCookies from "../../../../utils/setCookies";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import {
  PASS_UPPER_LOWER_REGEX,
  PASS_NUM_SPECIAL_REGEX,
  EMAIL_REGEX,
} from "../constants";
import request from "../../../../utils/request";
import { forbiddenCheck, GetErrorMessage, isChordCashLite } from "../../helper";
import {
  getSubDomain,
  extractHostname,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import PropTypes from "prop-types";
import segment from "../../../../utils/segment";
import { LEFTNAVIGATION_ICONS } from "../../../../component/HorizontalMenu/constants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logout: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    forbiddenCheck();
    AuthCookies.delete("artistDetails");
    AuthCookies.delete("userId");
    if (get(this.props, "location.state.autoLogin", false)) {
      toast.error(
        "Your session has expired. Please log in again to continue.",
        {
          autoClose: false,
        },
      );
      this.props.history.push({
        pathname: LOGIN,
        state: {
          autoLogin: false,
        },
      });
    }
    get(this.props, "location.state.logout") && this.setState({ logout: true });
    if (get(this.props, "match.params.token")) {
      const query = base.decode(get(this.props, "match.params.token"));
      const params = new URLSearchParams(query);
      if (
        params.has("timestamp") &&
        Math.floor((Date.now() - parseInt(params.get("timestamp"))) / 1000) <=
          60
      ) {
        StorageService.delete("jwtToken");
        AuthTokenService.storeToken(params.get("token"));
        StorageService.set(
          "isAdminInMaintenance",
          params.get("isAdmin") || false,
          { hash: false },
        );
        this.props.history.replace({
          pathname: HOME,
          state: { fromLogin: true },
        });
      } else {
        this.props.history.replace(LOGIN);
      }
    }
  }

  componentDidUpdate() {
    setTitle("Login", this.context);
  }

  passwordValidate = Yup.string()
    .required()
    .min(8, "Please enter valid password between 8 to 20 characters")
    .max(20, "Please enter valid password between 8 to 20 characters")
    .matches(
      PASS_UPPER_LOWER_REGEX,
      "Password should have at least 1 uppercase letter & 1 lowercase letter.",
    )
    .matches(
      PASS_NUM_SPECIAL_REGEX,
      "Password should have at least 1 number & 1 special character.",
    )
    .label("Password");

  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    password: this.passwordValidate,
  });

  loginUser = (data) => {
    if (getSubDomain() === extractHostname(data.landingPageUrl)) {
      AuthTokenService.storeToken(data.artistAccessToken);
      this.props.history.push({ pathname: HOME, state: { fromLogin: true } });
      return true;
    }
    const redirectToken = base.encode(
      `?token=${data.artistAccessToken}&timestamp=${Date.now()}`,
    );
    window.location.replace(`${data.landingPageUrl}${LOGIN}/${redirectToken}`);
  };

  handelSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      emailOrPhone: values.email,
      password: values.password,
    };
    invoke(this.context, "set", payload);
    invoke(this.props, "info.update", payload);
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNIN_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          segment.track.loginApp(values);
          segment.storage.set(
            values?.email || null,
            res?.data?.artist_id || null,
            res?.data?.userId || null,
            get(res.data, "isFastflow"),
          );
          segment.identify(res.data.userId);
          if (res.data && res.data.isActive === false) {
            this.setState(
              {
                landingPageUrl: res.data.landingPageUrl,
                artistRange: res.data.artist_range,
                userId: res.data.userId,
                verificationCode: res.data.verificationCode,
                artistId: res.data.artist_id,
                image: res?.data?.profile_picture?.[2]?.url,
                name: res.data.artist_name,
                isOpen: true,
                isFastFlow: get(res.data, "isFastflow"),
                resEmail: values.email,
              },
              () =>
                segment.storage.set(
                  this.state.resEmail,
                  this.state.artistId,
                  this.state.userId,
                ),
            );
            return;
          }
          AuthCookies.delete("userId");
          AuthCookies.delete("instaUser");
          AuthCookies.delete("ytUser");
          StorageService.delete("spotify_id");
          StorageService.delete("spotify_label");
          StorageService.delete("spotify_url");
          StorageService.delete("isAdminInMaintenance");
          if (res.data) {
            segment.storage.set(values.email, null, res.data.userId);
          }
          this.loginUser(res.data);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getErrText = () =>
    this.state.isFastFlow
      ? "Click below to login via secure login link"
      : "Click below to enter your details and create a password";
  getRedirectLink = () =>
    this.state.isFastFlow ? this.secureLogin : this.forgetUser;
  secureLogin = () => {
    window.top.location.replace(
      `${
        this.state.landingPageUrl
      }${FF_SECURE_LOGIN}?emailId=${encodeURIComponent(this.state.resEmail)}`,
    );
  };
  forgetUser = () => {
    window.top.location.href = `${this.state.landingPageUrl}${ACK_REDIRECT}?name=${this.state.name}&profile=${this.state.image}&status=${this.state.artistRange}&artistId=${this.state.artistId}&_id=${this.state.userId}&code=${this.state.verificationCode}`;
  };

  signupUrl = () =>
    get(this.context, "slugName") === "app"
      ? `${get(this.context, "whitelistUrl.0")}${ARTIST_FUNDING}`
      : get(this.context, "whitelistUrl.0");

  renderLoginMessage = () =>
    this.state.logout ? (
      <>
        <h1>You are logged out.</h1>
        <p className={styles.subTitle}>Login below</p>
      </>
    ) : (
      <div className={styles.subTitle}>Please log in to continue</div>
    );

  render() {
    return (
      <div className={styles.layoutContainer}>
        <LoginSideBar {...this.props} />
        <div className={styles.funnelContainer}>
          <Header {...this.props} />
          <div className={styles.loginMainContent}>
            <div className={styles.loginContent}>
              {!this.state.isOpen ? (
                <FormikForm
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={this.validationSchemaEmail}
                  onSubmit={this.handelSubmit}
                >
                  <div className={styles.mainTitleContainer}>
                    <div className={styles.iconContainer}>
                      <LEFTNAVIGATION_ICONS.WELCOME />
                    </div>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>Welcome Back!</div>
                      {this.renderLoginMessage()}
                    </div>
                  </div>
                  <div className={styles.formContainer}>
                    <Form>
                      <div className={`form-group mb-0`}>
                        <FormField
                          name="email"
                          placeholder="Email address"
                          type="text"
                          label="Email address"
                        />
                      </div>
                      <div className="form-group">
                        <FormField
                          name="password"
                          placeholder="Password"
                          as="password"
                          label="Password"
                        />
                      </div>
                      <div className={styles.footerBtn}>
                        <div className="form-group">
                          <Link
                            className={styles.forgotPassword}
                            to={FORGOT_PASS}
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className={styles.submitBtn}>
                          {!isChordCashLite() && (
                            <div className={styles.signUpDiv}>
                              <p>
                                Don’t have an account?
                                <a href={this.signupUrl()}>
                                  <br />
                                  <span data-testid="signup-link">Sign Up</span>
                                </a>
                              </p>
                            </div>
                          )}
                          <button
                            type="submit"
                            disabled={this.state.loading}
                            className={styles.btn}
                          >
                            LET'S GO!
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </FormikForm>
              ) : (
                <div className={styles.errorContainer}>
                  <div>
                    <h3>Oops! Your account setup is incomplete.</h3>
                    <p>{this.getErrText()}</p>
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      data-testid="redirectBtn"
                      onClick={this.getRedirectLink()}
                    >
                      LET’S GO
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

Login.contextType = ThemeContext;
export default Login;

Login.contextTypes = {
  whitelistUrl: PropTypes.array,
  slugName: PropTypes.string,
};
