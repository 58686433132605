import React, { Component } from "react";
import { get } from "lodash";
import Header from "../../../../component/Header";
import styles from "./GetFundedAuth.module.scss";
import authStyles from "../Auth.module.scss";
import { GETFUNDED_AUTH, LOGIN } from "../../constants";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import { checkUserData } from "../GetFundedAuth/helper";
import Loader from "../../../../component/Loader";
import Register from "../Register";
import { getShortNumber } from "../../YourAdvance/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import {
  getSubDomain,
  setTitle,
  getPartnerName,
} from "../../../../component/ThemeManager/helper";
class GetFundedAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.checkOnLoadData();
  }

  componentDidUpdate() {
    setTitle("Register", this.context);
  }

  checkOnLoadData = () => {
    const preData = get(this.props, "location.state.data");
    if (preData) {
      checkUserData.call(this);
    } else {
      this.props.history.push(LOGIN);
    }
  };

  render() {
    return (
      <div className="theme-bg-dark">
        <Header active={GETFUNDED_AUTH} />
        <div className={`${styles.getfundedContent}`}>
          <div className={styles.header}>
            <h2>{get(this.context, "displayName")}</h2>
            <p>
              {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 &&
                `powered by chordCash`}
            </p>
          </div>
          <div className={styles.formBody}>
            <div className={`${styles.profileDetail}`}>
              {this.props &&
              get(this.props, "location.state.data.profilePicture") ? (
                <Image
                  className={styles.getFundedAuthContent_profileImg}
                  src={get(
                    this.props,
                    "location.state.data.profilePicture[0].url"
                  )}
                  alt="Profile"
                  testId="ProfileImage"
                  imageType={IMAGE_TYPE.PROFILE}
                />
              ) : (
                <SvgIcons
                  icon={PROFILE_ICON}
                  className={styles.getFundedAuthContent_profileImgIcon}
                />
              )}
              <div className={styles.preHeading}>
                <p>
                  {this.props &&
                    get(this.props, "location.state.data.artistName")}
                </p>
                {this.props &&
                get(this.props, "location.state.data.monthlyListeners") ? (
                  <p>
                    {getShortNumber(
                      get(this.props, "location.state.data.monthlyListeners")
                    )}{" "}
                    monthly listeners
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`${authStyles.formContainer} ${styles.formContainer} pt-2`}
            >
              <h1 className={styles.getFundedAuthContent_heading}>
                Create a {getPartnerName()} account to see your funding options
              </h1>
              <Register userId={this.state.user_id} {...this.props} />
            </div>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

GetFundedAuth.contextType = ThemeContext;
export default GetFundedAuth;
