import { Form, Formik } from "formik";
import React, { Component, createRef } from "react";
import Loader from "../../../component/Loader";
import LoginHeader from "../../../component/LoginHeader";
import LeftPanel from "../AdminDashboard/LeftPanel";
import styles from "./HomepageContentForm.module.scss";
import whiteLabelStyles from "../AdminDashboard/WhiteLabel/WhiteLabel.module.scss";
import FormField from "../../../component/FormField/FormField";
import {
  CATEGORY_OPTIONS,
  CONTENT_TYPE,
  FIELD_INITIAL_VALUES,
  FIELD_NAMES,
  IMG_MAX_SIZE,
  NEWS_CATEGORY_OPTIONS,
  TAG_OPTIONS,
} from "./constant";
import CreatableSelect from "react-select/creatable";
import { tagStyle } from "../../../component/UploadFiles/selectStyle";
import ImageUpload from "../AdminDashboard/WhiteLabel/ImageUpload";
import {
  FAVICON_EXTENSIONS_REGEX,
  FAVICON_MAX_SIZE,
  FAVICON_RATIO_LIMIT,
  IMG_RATIO_LIMIT,
  IMG_NOT_AVAILABLE_CASES,
} from "../AdminDashboard/WhiteLabel/helper";
import { IMG_EXTENSIONS_REGEX } from "../MyAccount/constants";
import { toast } from "react-toastify";
import {
  getLabel,
  getLabelAndValue,
  getTags,
  getTitle,
  validationSchema,
} from "./helper";
import { get } from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import {
  ADMIN,
  API_URL,
  TAG_CATEGORY,
  HOMEPAGE,
  HOMEPAGE_CONTENT_EDIT,
  HOMEPAGE_CONTENT_NEW,
  CONTENT_MANAGEMENT,
  HOMEPAGE_CONTENT,
} from "../constants";
import request from "../../../utils/request";
import { GetErrorMessage } from "../helper";
import moment from "moment";
import ReactSelect from "react-select";
import styleVariables from "../../../styles/global.scss";
import Switch from "../AdminDashboard/Switch";

const useDefaultToken = false;

class HomepageContentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isEdit: false,
      initialValues: FIELD_INITIAL_VALUES,
      thumbnailLogo: { src: "", fileName: "" },
      thumbnailLogoSizeError: false,
      thumbnailLogoTypeError: false,
      thumbnailLogoDimensionError: false,
      categories: getLabelAndValue(CATEGORY_OPTIONS),
      tags: getLabelAndValue(TAG_OPTIONS),
      isVideo: true,
    };
    this.blogRef = createRef(null);
  }

  componentDidMount() {
    const {
      history: {
        location: { state },
      },
    } = this.props;

    document.title = `beatBread: ${
      get(state, "post._id") ? `Edit` : `Create`
    } Post`;
    this.getCategoryAndTags();
    this.validateForEdit();
  }

  getCategoryAndTags = () => {
    this.setState({
      loading: true,
    });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${HOMEPAGE}${TAG_CATEGORY}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            tags: getLabelAndValue(TAG_OPTIONS).concat(
              getLabelAndValue(get(res.data, "tag")),
            ),
          });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  validateForEdit = () => {
    const {
      history: {
        location: { pathname, state },
      },
    } = this.props;
    if (pathname.includes(HOMEPAGE_CONTENT_EDIT) && get(state, "post._id")) {
      this.setState({
        isEdit: true,
        loading: true,
      });
      const data = {
        method: "GET",
      };
      const requestUrl = `${API_URL}${ADMIN}${HOMEPAGE}${CONTENT_MANAGEMENT}?id=${get(
        state,
        "post._id",
      )}`;
      request(requestUrl, data, useDefaultToken)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setState({
              thumbnailLogo: {
                src: get(res.data, "image", ""),
                fileName: get(res.data, "image", "").split("/").pop().trim(),
              },
            });
            this.setState({
              prevCategory: get(res, "data.category"),
              isVideo: get(res, "data.isDisplayThumbnail", true),
            });
            const values = this.setValuesFromAPI(res);
            this.setState({ initialValues: values.initialValues });
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    } else {
      this.setState({ isEdit: false });
      this.props.history.push(HOMEPAGE_CONTENT_NEW);
    }
  };

  setValuesFromAPI = (res) => {
    const { data } = res;
    return {
      initialValues: {
        [FIELD_NAMES.TYPE.NAME]: data.type,
        [FIELD_NAMES.CATEGORY.NAME]: {
          label: data.category,
          value: data.category,
        },
        [FIELD_NAMES.NEW_CATEGORY.NAME]: {
          label: data.category,
          value: data.category,
        },
        [FIELD_NAMES.TAG.NAME]: getLabelAndValue(data.tag),
        [FIELD_NAMES.TITLE.NAME]: data.title,
        [FIELD_NAMES.URL.NAME]: data.sourceUrl,
        [FIELD_NAMES.PUBLICATION_DATE.NAME]: data.publishDate,
        [FIELD_NAMES.BLOG_CONTENT.NAME]: data.body,
        [FIELD_NAMES.IS_ACTIVE.NAME]: data.isActive,
        [FIELD_NAMES.AUTHOR.NAME]: data.author,
        [FIELD_NAMES.BRIEF.NAME]: data.description,
        [FIELD_NAMES.WHAT_CHANGE.NAME]: get(data, "questionAnswer[0].answer"),
        [FIELD_NAMES.WHEN.NAME]: get(data, "questionAnswer[1].answer"),
        [FIELD_NAMES.WHAT_IMPORTANT.NAME]: get(
          data,
          "questionAnswer[2].answer",
        ),
        [FIELD_NAMES.WHAT_DO.NAME]: get(data, "questionAnswer[3].answer"),
        [FIELD_NAMES.GET_HELP.NAME]: get(data, "questionAnswer[4].answer"),
        isFeatured: get(data, "isFeatured"),
        [FIELD_NAMES.LISTENER.NAME]: data.author,
      },
    };
  };

  onChangeCategory = (e, setFieldValue, setTouched, resetForm) => {
    setTouched({});
    this.setState({
      thumbnailLogo: {
        src: "",
        fileName: "",
      },
      thumbnailLogoSizeError: false,
      thumbnailLogoTypeError: false,
      thumbnailLogoDimensionError: false,
    });
    resetForm();
    setFieldValue(FIELD_NAMES.TYPE.NAME, e.target.value);
  };
  onChangeNewsCategory = (e, setFieldValue, setTouched, resetForm) => {
    setTouched({});
    resetForm();
    setFieldValue(FIELD_NAMES.NEW_CATEGORY.NAME, e);
  };

  renderPostContent = (values) => (
    <div className={`${styles.mainContainer} ${styles.articleContainer}`}>
      <div className={styles.leftContainer}>
        <h1>Post Content</h1>
        <div className={`form-group`}>
          <FormField
            name={FIELD_NAMES.WHAT_CHANGE.NAME}
            type="text"
            as={"textarea"}
            placeholder={FIELD_NAMES.WHAT_CHANGE.PLACEHOLDER}
            label={`${FIELD_NAMES.WHAT_CHANGE.LABEL}*`}
          />
        </div>
        <div className={`form-group`}>
          <FormField
            name={FIELD_NAMES.WHEN.NAME}
            type="text"
            as={"textarea"}
            placeholder={FIELD_NAMES.WHEN.PLACEHOLDER}
            label={`${FIELD_NAMES.WHEN.LABEL}*`}
          />
        </div>

        <div className={`form-group `}>
          <FormField
            name={FIELD_NAMES.WHAT_IMPORTANT.NAME}
            type="text"
            as={"textarea"}
            placeholder={FIELD_NAMES.WHAT_IMPORTANT.PLACEHOLDER}
            label={`${FIELD_NAMES.WHAT_IMPORTANT.LABEL}*`}
          />
        </div>
        <div className={`form-group `}>
          <FormField
            name={FIELD_NAMES.WHAT_DO.NAME}
            type="text"
            as={"textarea"}
            placeholder={FIELD_NAMES.WHAT_DO.PLACEHOLDER}
            label={`${FIELD_NAMES.WHAT_DO.LABEL}*`}
          />
        </div>
        <div className={`form-group `}>
          <FormField
            name={FIELD_NAMES.GET_HELP.NAME}
            type="text"
            as={"textarea"}
            placeholder={FIELD_NAMES.GET_HELP.PLACEHOLDER}
            label={`${FIELD_NAMES.GET_HELP.LABEL}*`}
          />
        </div>
      </div>
    </div>
  );
  isFeatured = (isFeatured, currentCategory, prevCategory) => {
    return isFeatured && currentCategory !== prevCategory;
  };
  renderTypeOfPostSection = (
    values,
    setFieldValue,
    errors,
    touched,
    setTouched,
    resetForm,
  ) => (
    <div className={`${styles.mainContainer} ${styles.typeContainer}`}>
      <h1>Type of Post:</h1>
      <div className={styles.radioField}>
        <span>Content Type*:</span>
        <FormField
          as="radio"
          radioValueList={CONTENT_TYPE}
          name="type"
          disabled={this.state.isEdit}
          onChange={(e) => {
            this.onChangeCategory(e, setFieldValue, setTouched, resetForm);
          }}
          data-testid="id-type"
        />
      </div>
      {values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value && (
        <div className={styles.categoryField}>
          <span>{`${FIELD_NAMES.CATEGORY.LABEL}*:`}</span>
          <ReactSelect
            options={getLabelAndValue(CATEGORY_OPTIONS)}
            isClearable
            value={values[FIELD_NAMES.CATEGORY.NAME]}
            onChange={(e) => {
              setFieldValue(FIELD_NAMES.CATEGORY.NAME, e);
            }}
            placeholder={FIELD_NAMES.CATEGORY.PLACEHOLDER}
            data-testid="test-category"
          />
          <span className={styles.error}>
            {touched[FIELD_NAMES.CATEGORY.NAME] &&
              errors[FIELD_NAMES.CATEGORY.NAME]}
          </span>
          <span className={styles.error}>
            {this.isFeatured(
              values.isFeatured,
              get(values[FIELD_NAMES.CATEGORY.NAME], "value"),
              this.state.prevCategory,
            ) && `Can not update category as this post is featured`}
          </span>
        </div>
      )}
      {values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[0].value && (
        <>
          <div className={styles.categoryField}>
            <span>{`${FIELD_NAMES.CATEGORY.LABEL}*:`}</span>
            <ReactSelect
              options={getLabelAndValue(NEWS_CATEGORY_OPTIONS)}
              isClearable
              isDisabled={this.state.isEdit}
              value={values[FIELD_NAMES.NEW_CATEGORY.NAME]}
              onChange={(e) =>
                this.onChangeNewsCategory(
                  e,
                  setFieldValue,
                  setTouched,
                  resetForm,
                )
              }
              placeholder={FIELD_NAMES.NEW_CATEGORY.PLACEHOLDER}
              data-testid="id-type1"
            />
            <span className={styles.error}>
              {touched[FIELD_NAMES.CATEGORY.NAME] &&
                errors[FIELD_NAMES.CATEGORY.NAME]}
            </span>
          </div>
        </>
      )}
      {(values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value ||
        (values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[0].value &&
          values.newscategory.value === NEWS_CATEGORY_OPTIONS[1])) && (
        <div className={styles.tagField}>
          <span>{`${FIELD_NAMES.TAG.LABEL}*:`}</span>
          <CreatableSelect
            options={this.state.tags}
            isMulti
            placeholder={FIELD_NAMES.TAG.PLACEHOLDER}
            styles={tagStyle}
            value={values[FIELD_NAMES.TAG.NAME]}
            onChange={(e) => setFieldValue(FIELD_NAMES.TAG.NAME, e)}
            data-testid="test-tag"
          />
          <span className={styles.error}>
            {touched[FIELD_NAMES.TAG.NAME] && errors[FIELD_NAMES.TAG.NAME]}
          </span>
        </div>
      )}
    </div>
  );

  checkImageValidation = (file, type) => {
    this.setState({
      thumbnailLogoSizeError: false,
      thumbnailLogoTypeError: false,
      thumbnailLogoDimensionError: false,
    });
    const fileType =
      type === "favicon" ? FAVICON_EXTENSIONS_REGEX : IMG_EXTENSIONS_REGEX;
    if (!fileType.test(file[0].type)) {
      this.setState({ [`${type}TypeError`]: true });
      return false;
    }
    const maxSize = type === "favicon" ? FAVICON_MAX_SIZE : IMG_MAX_SIZE;
    if (file[0].size > maxSize) {
      this.setState({ [`${type}SizeError`]: true });
      return false;
    }
    return file;
  };

  onChangeFileHandler = (event, type) => {
    const file = event.target.files;
    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file, type);
    }
    if (validateFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file[0]);
      const fileToSave = file[0];
      img.onload = () => {
        const ratio = img.width / img.height;
        const minRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MIN : IMG_RATIO_LIMIT.MIN;
        const maxRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MAX : IMG_RATIO_LIMIT.MAX;
        if (!(ratio >= minRatio && ratio <= maxRatio) && type === "favicon") {
          this.setState({ [`${type}DimensionError`]: true });
        } else {
          this.setState({
            [type]: {
              fileName: fileToSave && fileToSave.name,
              src: img.src,
            },
          });
          window.URL.revokeObjectURL(file[0]);
        }
      };
    }
    event.target.value = null;
  };

  renderArticleSection = (values) => (
    <div className={`${styles.mainContainer} ${styles.kcContainer}`}>
      <div className={styles.titleDiv}>
        <h1>{getTitle(values.type)}</h1>
        {values.type === CONTENT_TYPE[1].value && (
          <div className={styles.toggleDiv}>
            <div className={styles.leftToggle}>
              <p>Display Thumbnail in Templates</p>
              <span>(HINT: Toggled ON for articles and OFF for videos)</span>
            </div>
            <Switch
              id="toggle-video"
              title="Video"
              isOn={this.state.isVideo}
              onColor={styleVariables.primaryColor}
              data-testid="switch"
              handleToggle={() => this.handleToggle()}
            />
          </div>
        )}
      </div>
      <div className={styles.articleContainer}>
        <div className={styles.leftContainer}>
          <div className={`form-group`}>
            <FormField
              name={FIELD_NAMES.TITLE.NAME}
              type="text"
              as={"input"}
              placeholder={get(getLabel(values.type), "title")}
              label={get(getLabel(values.type), "title")}
            />
          </div>
          {values.newscategory.value !== NEWS_CATEGORY_OPTIONS[1] &&
            values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[2].value && (
              <div className={`form-group`}>
                <FormField
                  name={FIELD_NAMES.AUTHOR.NAME}
                  type="text"
                  as={"input"}
                  placeholder={get(getLabel(values.type), "author")}
                  label={get(getLabel(values.type), "author")}
                  data-testid="title-field"
                />
              </div>
            )}
          {values.newscategory.value === NEWS_CATEGORY_OPTIONS[1] && (
            <div className={styles.fieldContainer}>
              <div className={`form-group ${styles.maxWidth}`}>
                <FormField
                  name={FIELD_NAMES.AUTHOR.NAME}
                  type="text"
                  as={"input"}
                  placeholder={get(getLabel(values.type), "author")}
                  label={get(getLabel(values.type), "author")}
                />
              </div>
              <div className={`form-group ${styles.maxWidth}`}>
                <FormField
                  name={FIELD_NAMES.PUBLICATION_DATE.NAME}
                  type="date"
                  as={"input"}
                  placeholder={FIELD_NAMES.PUBLICATION_DATE.PLACEHOLDER}
                  label={`${FIELD_NAMES.PUBLICATION_DATE.LABEL}*`}
                />
              </div>
            </div>
          )}
          {values.newscategory.value !== NEWS_CATEGORY_OPTIONS[1] &&
            values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[2].value && (
              <div className={`form-group`}>
                <FormField
                  name={FIELD_NAMES.URL.NAME}
                  type="text"
                  as={"input"}
                  placeholder={get(getLabel(values.type), "url")}
                  label={get(getLabel(values.type), "url")}
                />
              </div>
            )}
          {values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[2].value && (
            <div className={`form-group`}>
              <FormField
                name={FIELD_NAMES.LISTENER.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.LISTENER.PLACEHOLDER}
                label={`${FIELD_NAMES.LISTENER.LABEL}*`}
                data-testid="listener-field"
              />
            </div>
          )}
          <div className={`form-group`}>
            <FormField
              name={FIELD_NAMES.BRIEF.NAME}
              type="text"
              as={"textarea"}
              placeholder={`${FIELD_NAMES.BRIEF.PLACEHOLDER}*`}
              label={`${FIELD_NAMES.BRIEF.LABEL}*`}
            />
          </div>
          {values.newscategory.value !== NEWS_CATEGORY_OPTIONS[1] && (
            <div
              className={`form-group ${whiteLabelStyles.formGroup} ${styles.dateContainer}`}
            >
              <FormField
                name={FIELD_NAMES.PUBLICATION_DATE.NAME}
                type="date"
                as={"input"}
                placeholder={FIELD_NAMES.PUBLICATION_DATE.PLACEHOLDER}
                label={`${FIELD_NAMES.PUBLICATION_DATE.LABEL}*`}
              />
            </div>
          )}
        </div>
        {values.type !== CONTENT_TYPE[0].value && (
          <div
            className={`${styles.rightContainer} ${styles.customFileUpload}`}
          >
            <div className={styles.imageDiv}>
              <ImageUpload
                accept=".jpg, .jpeg, .png"
                uploadType="Thumbnail"
                maxSize="250 KB"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "thumbnailLogo")}
                editLogoMessage="Edit Thumbnail"
                imageAvailable={this.state.thumbnailLogo.src}
                buttonText={`+ Add Post Thumbnail${
                  values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[0].value
                    ? `*`
                    : ``
                }`}
                sizeError={this.state.thumbnailLogoSizeError}
                typeError={this.state.thumbnailLogoTypeError}
                dimensionError={this.state.thumbnailLogoDimensionError}
                data-testid="EmailLogo"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  handleToggle = () => this.setState({ isVideo: !this.state.isVideo });

  editorInit = (placeholder) => ({
    placeholder,
    height: 400,
    width: "100%",
    menubar: "edit view insert format help",
    branding: false,
    extended_valid_elements: "style",
    custom_elements: "style",
    plugins: ["advlist lists link preview", "code", "media table paste code "],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic forecolor backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat ",
    toolbar_sticky: true,
    toolbar_mode: "sliding",
    content_style: `body { font-family:poppins; font-size:14px; color:${styleVariables.primaryColor} }`,
  });

  getHtmlEditor = (value, initialValue, setFieldValue, field, innerRef) => (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onInit={(evt, editor) => (innerRef.current = editor)}
      onEditorChange={(e) => {
        setFieldValue(field.NAME, e);
      }}
      value={value[FIELD_NAMES.BLOG_CONTENT.NAME]}
      init={this.editorInit(field.PLACEHOLDER)}
    />
  );

  renderBlogSection = (value, initialValue, setFieldValue, innerRef) => (
    <div className={`${styles.mainContainer} ${styles.typeContainer}`}>
      <h1>{FIELD_NAMES.BLOG_CONTENT.LABEL}</h1>
      <div className={whiteLabelStyles.sidebarText}>
        <div className={whiteLabelStyles.subContainer}>
          <div
            className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
          >
            {this.getHtmlEditor(
              value,
              initialValue,
              setFieldValue,
              FIELD_NAMES.BLOG_CONTENT,
              innerRef,
            )}
            <FormField
              name={FIELD_NAMES.BLOG_CONTENT.NAME}
              type="text"
              as={"textMsg"}
              data-testid="test-blog"
            />
          </div>
        </div>
      </div>
    </div>
  );

  checkLogoError = () => {
    const {
      thumbnailLogoSizeError,
      thumbnailLogoTypeError,
      thumbnailLogoDimensionError,
    } = this.state;
    return (
      thumbnailLogoDimensionError ||
      thumbnailLogoSizeError ||
      thumbnailLogoTypeError
    );
  };

  renderSubmitButton = (values, validateForm, handleSubmit) => {
    const { loading, isEdit, thumbnailLogo } = this.state;
    const areLogosUploaded = IMG_NOT_AVAILABLE_CASES.some(
      (imgCase) => thumbnailLogo.src === imgCase,
    );

    const isNotLogoForNews =
      values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[0].value &&
      (areLogosUploaded || this.checkLogoError());
    const isButtonDisabled =
      loading ||
      isNotLogoForNews ||
      this.isFeatured(
        values.isFeatured,
        get(values[FIELD_NAMES.CATEGORY.NAME], "value"),
        this.state.prevCategory,
      );
    return (
      <div className={whiteLabelStyles.saveButton}>
        <button
          type="button"
          disabled={loading}
          className="btn btn-link"
          onClick={() => {
            toast.info("Data entered in this form will not be saved.");
            this.props.history.push(HOMEPAGE_CONTENT);
          }}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className={isButtonDisabled && whiteLabelStyles.disabledSubmitButton}
          disabled={isButtonDisabled}
          data-testid="test-submit"
        >
          {isEdit ? "Submit Changes" : "Create Post"}
        </button>
      </div>
    );
  };

  checkIfImageChanged = (type) => {
    if (this.state[type].src.includes("blob")) {
      return true;
    }
    return false;
  };
  getImageBlob = async (type) => {
    const payload = {
      blob: true,
    };
    return await request(this.state[type].src, payload).then((r) => r);
  };

  onSubmit = async (values) => {
    const formData = new FormData();
    const {
      history: {
        location: { state },
      },
    } = this.props;

    const { isEdit, thumbnailLogo } = this.state;

    if (values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[0].value) {
      const hasThumbnailLogoChanged = this.checkIfImageChanged("thumbnailLogo");
      if (hasThumbnailLogoChanged) {
        const thumbnailLogoBlob = await this.getImageBlob("thumbnailLogo");
        formData.append("image", thumbnailLogoBlob, thumbnailLogo.fileName);
      }
    }

    formData.append("type", values[FIELD_NAMES.TYPE.NAME]);

    values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value &&
      formData.append(
        "category",
        get(values[FIELD_NAMES.CATEGORY.NAME], "value"),
      );

    values.newscategory.value === NEWS_CATEGORY_OPTIONS[0] &&
      values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[0].value &&
      formData.append("category", NEWS_CATEGORY_OPTIONS[0]);

    values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[2].value &&
      formData.append("category", "Case Studies");

    values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value &&
      formData.append("isDisplayThumbnail", this.state.isVideo);

    (values.newscategory.value === NEWS_CATEGORY_OPTIONS[1] ||
      values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value) &&
      formData.append(
        "tag",
        JSON.stringify(getTags(values[FIELD_NAMES.TAG.NAME])),
      );

    formData.append("title", values[FIELD_NAMES.TITLE.NAME]);

    values.newscategory.value !== NEWS_CATEGORY_OPTIONS[1] &&
      values[FIELD_NAMES.TYPE.NAME] !== CONTENT_TYPE[2].value &&
      values[FIELD_NAMES.URL.NAME] &&
      formData.append("sourceUrl", values[FIELD_NAMES.URL.NAME]);

    formData.append("isActive", values[FIELD_NAMES.IS_ACTIVE.NAME] || false);

    formData.append(
      "publishDate",
      moment(values[FIELD_NAMES.PUBLICATION_DATE.NAME]).format("YYYY-MM-DD"),
    );

    (values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[1].value ||
      values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[2].value) &&
      formData.append("body", values[FIELD_NAMES.BLOG_CONTENT.NAME]);

    formData.append(
      "author",
      values[FIELD_NAMES.TYPE.NAME] === CONTENT_TYPE[2].value
        ? values[FIELD_NAMES.LISTENER.NAME]
        : values[FIELD_NAMES.AUTHOR.NAME],
    );

    formData.append("description", values[FIELD_NAMES.BRIEF.NAME]);

    if (isEdit) {
      formData.append("id", state.post._id);
    }
    const questionAnswer = [
      {
        question: FIELD_NAMES.WHAT_CHANGE.LABEL,
        answer: values[FIELD_NAMES.WHAT_CHANGE.NAME],
      },
      {
        question: FIELD_NAMES.WHEN.LABEL,
        answer: values[FIELD_NAMES.WHEN.NAME],
      },
      {
        question: FIELD_NAMES.WHAT_IMPORTANT.LABEL,
        answer: values[FIELD_NAMES.WHAT_IMPORTANT.NAME],
      },
      {
        question: FIELD_NAMES.WHAT_DO.LABEL,
        answer: values[FIELD_NAMES.WHAT_DO.NAME],
      },
      {
        question: FIELD_NAMES.GET_HELP.LABEL,
        answer: values[FIELD_NAMES.GET_HELP.NAME],
      },
    ];

    if (values.newscategory.value === NEWS_CATEGORY_OPTIONS[1]) {
      formData.append(
        "category",
        get(values[FIELD_NAMES.NEW_CATEGORY.NAME], "value"),
      );
      formData.append(`questionAnswer`, JSON.stringify(questionAnswer));
    }

    this.setState({
      loading: true,
    });

    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${ADMIN}${HOMEPAGE}${CONTENT_MANAGEMENT}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(
            `Post is successfully ${isEdit ? `updated!` : `created!`}`,
          );
          this.props.history.push(HOMEPAGE_CONTENT);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  render() {
    const { loading, isEdit } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>{isEdit ? "Edit" : "New"} Post</h1>
            </div>
            <div className={whiteLabelStyles.contentContainer}>
              <Formik
                initialValues={this.state.initialValues}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
                data-testid="formRender"
              >
                {({
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                  setTouched,
                  resetForm,
                }) => (
                  <Form>
                    {this.renderTypeOfPostSection(
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      setTouched,
                      resetForm,
                    )}
                    {this.renderArticleSection(values)}
                    {values.type !== CONTENT_TYPE[0].value &&
                      this.renderBlogSection(
                        values,
                        this.state.initialValues[FIELD_NAMES.BLOG_CONTENT.NAME],
                        setFieldValue,
                        this.blogRef,
                      )}
                    {values.newscategory.value === NEWS_CATEGORY_OPTIONS[1] &&
                      this.renderPostContent(values)}
                    {this.renderSubmitButton(
                      values,
                      validateForm,
                      handleSubmit,
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {loading && <Loader data-testid="Loader" />}
      </>
    );
  }
}

export default HomepageContentForm;
