import React from "react";
import { toast } from "react-toastify";
import { get } from "lodash";
import request from "../../../../utils/request";
import Odometer from "react-odometerjs";
import ReactModal from "react-modal";
import { GetErrorMessage } from "../../helper";
import {
  API_URL,
  ARTIST_API,
  OFFERS_API,
  TOP_TRACK,
  UPCOMING_WORKS,
  UPDATE_MARKETING_STATUS,
  USER_API,
} from "../../constants";
import styles from "./SetTargetAdvance.module.scss";
import classes from "../../YourAdvance/YourAdvance.module.scss";
import Loader from "../../../../component/Loader";
import HorizontalMenu from "../../../../component/HorizontalMenu";
import LoginHeader from "../../../../component/LoginHeader";
import {
  changeSliderCallback,
  getAdvanceOptionalCount,
  getSliderComponent,
  numberWithCommas,
} from "../../YourAdvance/helper";
import ReactRange from "../../../../component/Range/ReactRange";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import {
  GuaranteedModal,
  nothanksNavigationStatusUpdate,
  renderChancesOfOffer,
} from "../helper";
import { MULTIPLIER, ROUND_AMOUNT, STEPS } from "./constants";
import Tooltip from "../../../../component/Tooltip/Tooltip";

class SetTargetAdvance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offerData: {},
      marketDefaultOffer: {},
      value: 0,
      advanceData: {},
      defaultOffer: {},
      currentData: {},
      newTracks: 0,
      term: 1,
      catalogIncome: 0,
      releaseIncome: 0,
      showAdjustDeal: false,
      sliderValue: 0,
      showMarketPopup: false,
    };
  }

  componentDidUpdate() {
    setTitle("What’s your target advance?", this.context);
  }

  componentDidMount() {
    this.getOfferData();
  }

  getOfferData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            offerData: get(res.data, "offer", {}),
            marketDefaultOffer: get(res.data, "offerStage.defaultOffer", {}),
            sliderValue:
              get(res.data, "offerStage.sliderValue") ||
              get(res.data, "offerStage.defaultOffer.totalAdvance", 0),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getAdjustOffers = (loadDefault) => {
    this.setState({ loading: true, showDetails: false });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
          showAdjustDeal: true,
        });
        if (res.status) {
          this.setState(
            {
              advanceData: res.data.offers,
              defaultOffer: res.data.defaultOffer,
            },
            () => changeSliderCallback.bind(this)(loadDefault),
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  saveFundingAdvance = () => {
    this.setState({ loading: true });
    const payload = {
      offer: this.state.currentData,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            offerData: get(res.data, "offer", {}),
            marketDefaultOffer: get(res.data, "offerStage.defaultOffer", {}),
            sliderValue: get(
              res.data,
              "offerStage.defaultOffer.totalAdvance",
              0,
            ),
            showAdjustDeal: false,
          });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  saveMinimumTerm = () => {
    this.setState({ loading: true });
    const payload = {
      targetOffer: true,
      sliderValue: this.state.sliderValue,
      defaultOffer: this.state.marketDefaultOffer,
      offer: this.state.offerData,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(UPCOMING_WORKS);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getOfferCalculatedValue = (multiplierString, sliderValue) => {
    const { offerData } = this.state;
    if (!get(offerData, "option_advance") && !get(offerData, "nr_advance")) {
      return sliderValue * +!!get(offerData, multiplierString);
    }
    if (!get(offerData, "option_advance")) {
      return (
        get(offerData, multiplierString, 0) +
        Math.round(
          (sliderValue - get(offerData, "total_advance", 0)) *
            get(MULTIPLIER.new_release, multiplierString),
        )
      );
    }
    return (
      get(offerData, multiplierString, 0) +
      Math.round(
        (sliderValue - get(offerData, "total_advance", 0)) *
          get(MULTIPLIER.option_release, multiplierString),
      )
    );
  };

  getCurrentChancesRender = () => {
    if (
      get(this.state.marketDefaultOffer, "totalAdvance") ===
      get(this.state.offerData, "total_advance")
    ) {
      return (
        <div className={`${styles.customThumb} ${styles.guaranteed}`}>
          <span className={styles.primaryText}>Guaranteed</span>
        </div>
      );
    }
    if (
      get(this.state.marketDefaultOffer, "totalAdvance") <
      get(this.state.offerData, "minimum_breakpoint_one")
    ) {
      return (
        <div className={`${styles.customThumb} ${styles.pointOne}`}>
          Up to <span className={styles.primaryText}>80%</span> chance of
          success
        </div>
      );
    }
    if (
      get(this.state.marketDefaultOffer, "totalAdvance") <
      get(this.state.offerData, "minimum_breakpoint_two")
    ) {
      return (
        <div className={`${styles.customThumb} ${styles.pointTwo}`}>
          Up to <span className={styles.primaryText}>50%</span> chance of
          success
        </div>
      );
    }
    if (
      get(this.state.marketDefaultOffer, "totalAdvance") <
      get(this.state.offerData, "minimum_breakpoint_three")
    ) {
      return (
        <div className={`${styles.customThumb} ${styles.pointThree}`}>
          Up to <span className={styles.primaryText}>20%</span> chance of
          success
        </div>
      );
    }
    return (
      <div className={`${styles.customThumb} ${styles.pointFour}`}>
        Up to <span className={styles.primaryText}>10%</span> chance of success
      </div>
    );
  };

  navigationStatusUpdate = nothanksNavigationStatusUpdate.bind(this);

  toggleMarketPopup = () => {
    this.setState({ showMarketPopup: !this.state.showMarketPopup });
  };

  toggleAdjustPopup = () => {
    this.setState((prevState) => ({
      showAdjustDeal: !prevState.showAdjustDeal,
    }));
  };

  toggleDetailsPopup = () => {
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  toggleMoreInfoPopup = () => {
    this.setState((prevState) => ({
      showMoreInfoPopup: !prevState.showMoreInfoPopup,
    }));
  };

  resetAdjustTermOffer = () => {
    this.setState(
      { currentData: this.state.defaultOffer },
      changeSliderCallback.bind(this)(true, false),
    );
  };

  adjustTermsModalRender = () => (
    <ReactModal
      isOpen={this.state.showAdjustDeal}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.toggleAdjustPopup}
      className={styles.adjustModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.header}>
        <div className={styles.title}>Adjust Offer Terms</div>
        <div className={styles.preview}>
          <p className={styles.primaryText}>
            Guaranteed Offer:{" "}
            <span>
              $
              <Odometer
                value={get(this.state, "currentData.total_advance", 0)}
                format="(,ddd).dd"
                duration={400}
              />
            </span>
          </p>
          <p>
            Recoupment Rate:{" "}
            <span>
              <Odometer
                value={parseInt(get(this.state, "currentData.royalty", 0))}
                format="(,ddd).dd"
                duration={400}
              />
              %
            </span>
          </p>
        </div>
      </div>
      <div className={`${classes.sliderContainer} ${styles.sliderContainer}`}>
        {!!Object.keys(get(this.state, "advanceData", {})).length &&
          getSliderComponent.bind(this)()}
      </div>
      <div className={styles.modalBtnContainer}>
        <button
          className={styles.resetBtn}
          data-testid="resetAdjustTerms"
          onClick={this.resetAdjustTermOffer}
        >
          Reset Changes
        </button>
        <button
          className={styles.modalBtn}
          data-testid="saveAdjustTerms"
          onClick={this.saveFundingAdvance}
        >
          Save
        </button>
      </div>
    </ReactModal>
  );

  detailsPopup = () => (
    <ReactModal
      isOpen={this.state.showDetails}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.toggleDetailsPopup}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.offerContainer}>
        <span className={styles.closeBtn} onClick={this.toggleDetailsPopup}>
          &times;
        </span>
        <div className={styles.totalContent}>
          <span className={styles.label}>Your Target Offer</span>
          {renderChancesOfOffer(
            get(this.state.marketDefaultOffer, "totalAdvance"),
            this.state.offerData,
          )}
        </div>
        <div className={styles.estimateContainer}>
          <div className={styles.totalEstimate}>
            <label>Advance Total</label>
            <p className={styles.totalNumber}>
              $
              {numberWithCommas(
                get(this.state.marketDefaultOffer, "totalAdvance", 0),
              )}{" "}
            </p>
            <span>or more</span>
          </div>
          <div className={styles.offerDetails}>
            <div className={styles.importantText}>
              {get(this.state.offerData, "works")}
            </div>
            <div>
              <label>Recoupment Rate</label>
              <p className={styles.recoupmentRate}>
                {parseInt(get(this.state.offerData, "royalty", 0))}%
              </p>
            </div>
          </div>
          <div className={styles.termContainer}>
            <p>
              <span className={styles.importantText}>
                {this.state.offerData.term} Year Term,
              </span>{" "}
              {this.state.offerData.flow_through}% flow through artist income
            </p>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <ul>
            <li
              className={
                !get(this.state.marketDefaultOffer, "upfront") ? "d-none" : ""
              }
            >
              <div className="d-flex">
                <span>Upfront advance</span>
                <div className={styles.value}>
                  $
                  {numberWithCommas(
                    get(this.state.marketDefaultOffer, "upfront", 0),
                  )}
                </div>
              </div>
            </li>
            <li
              className={
                !get(this.state.marketDefaultOffer, "newRelease")
                  ? "d-none"
                  : ""
              }
            >
              <div className="d-flex">
                <span>
                  Advance on release of{" "}
                  {getAdvanceOptionalCount(this.state.offerData.works)} new
                  tracks
                </span>
                <div className={styles.value}>
                  $
                  {numberWithCommas(
                    get(this.state.marketDefaultOffer, "newRelease"),
                  )}
                </div>
              </div>
            </li>
            <li
              className={
                !get(this.state.marketDefaultOffer, "optionalRelease")
                  ? "d-none"
                  : ""
              }
            >
              <div className="d-flex">
                <span>
                  Optional advance for{" "}
                  {getAdvanceOptionalCount(this.state.offerData.works, true)}{" "}
                  more tracks
                </span>
                <div className={styles.value}>
                  $
                  {numberWithCommas(
                    get(this.state.marketDefaultOffer, "optionalRelease"),
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={styles.btnContainer}>
          <button
            data-testid="getOfferCall"
            onClick={() => this.getAdjustOffers(true)}
          >
            Adjust Deal Structure
          </button>
          <button className={styles.btnPrimary} onClick={this.saveMinimumTerm}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );

  moreInfoPopupRender = () => (
    <ReactModal
      isOpen={this.state.showMoreInfoPopup}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.toggleMoreInfoPopup}
      className={styles.marketModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.primaryHeader}>
        <p>We won't share your minimum terms with Investors</p>
      </div>
      <div className={styles.infoText}>
        <p>
          Where you set the slider cannot impact the amount you get, only your
          chances of receiving an offer.
        </p>
        <p>
          No matter where you set the slider, we'll always get you the best
          terms possible.
        </p>
      </div>
      <div className={styles.btnContainer}>
        <button onClick={this.toggleMoreInfoPopup}>Got it!</button>
      </div>
    </ReactModal>
  );

  exponentialValue = (value) => {
    const minValue = get(this.state.offerData, "total_advance", 0);
    const maxValue = get(
      this.state.offerData,
      "minimum_breakpoint_four",
      minValue + 1,
    );
    if (value === minValue || value === maxValue) return value;
    const calculatedValue =
      Math.round(
        Math.exp(
          ((value - minValue) * (Math.log(maxValue) - Math.log(minValue))) /
            (maxValue - minValue) +
            Math.log(minValue),
        ) / ROUND_AMOUNT,
      ) * ROUND_AMOUNT;
    if (calculatedValue < minValue) return minValue;
    if (calculatedValue > maxValue) return maxValue;
    return calculatedValue;
  };

  onSliderFinalChange = (values) => {
    const finalValue = this.exponentialValue(values[0]);
    this.setState((prevState) => ({
      sliderValue: values[0],
      marketDefaultOffer: {
        ...prevState.marketDefaultOffer,
        totalAdvance: finalValue,
        upfront: this.getOfferCalculatedValue("catalog_advance", finalValue),
        newRelease: this.getOfferCalculatedValue("nr_advance", finalValue),
        optionalRelease: this.getOfferCalculatedValue(
          "option_advance",
          finalValue,
        ),
      },
    }));
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader headerTitle={"Set Your Minimum"} />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  <div className={styles.titleHeader}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>
                        <h3>Set your Minimum </h3>
                        <i
                          className={styles.infoIcon}
                          onClick={this.toggleMoreInfoPopup}
                        />
                      </div>
                      <p>
                        Tell us the lowest advance you're willing to accept and
                        we will maximize your offer(s) with our Investor
                        Network.
                      </p>
                    </div>
                    <div className={styles.helpText}>
                      <p>
                        We never share your minimum with investors. We work to
                        get you the best terms possible.
                      </p>
                    </div>
                  </div>
                  <div className={styles.helpText}>
                    <p>
                      We never share your minimum with investors. We work to get
                      you the best terms possible.
                    </p>
                  </div>
                  <div className={styles.sliderOfferContainer}>
                    <div className={styles.targetOfferContainer}>
                      <div className={styles.targetOffer}>
                        <label className={styles.floatingLabel}>
                          Target Advance
                        </label>
                        {!!get(this.state.offerData, "total_advance") &&
                          !!get(
                            this.state.marketDefaultOffer,
                            "totalAdvance",
                          ) && (
                            <>
                              <div className={styles.amountHeadContainer}>
                                <div className={styles.amountTitle}>
                                  <p>At Least</p>
                                </div>
                                <div className={styles.marketPlaceAmount}>
                                  $
                                  {
                                    <Odometer
                                      value={get(
                                        this.state.marketDefaultOffer,
                                        "totalAdvance",
                                        0,
                                      )}
                                      format="(,ddd).dd"
                                      duration={400}
                                    />
                                  }
                                </div>
                              </div>
                              <div className={styles.sliderContainer}>
                                <ReactRange
                                  className={styles.reactRange}
                                  trackBackgroundClassName={
                                    styles.sliderBackground
                                  }
                                  values={[this.state.sliderValue]}
                                  onFinalChange={this.onSliderFinalChange}
                                  min={get(
                                    this.state.offerData,
                                    "total_advance",
                                    0,
                                  )}
                                  max={get(
                                    this.state.offerData,
                                    "minimum_breakpoint_four",
                                    get(
                                      this.state.offerData,
                                      "total_advance",
                                      0,
                                    ) + 1,
                                  )}
                                  step={STEPS}
                                  customThumbRender={this.getCurrentChancesRender()}
                                  hideValue
                                  showMinMax
                                  unit="$"
                                />
                              </div>
                            </>
                          )}
                      </div>
                      <div className={styles.scopeDetails}>
                        <div className={styles.scope}>
                          <p className={styles.title}>Included Music</p>
                          <p>{get(this.state.offerData, "works")}</p>
                        </div>
                        <div>
                          <p className={styles.countText}>
                            <span> Recoupment rate </span>
                            <Tooltip
                              place="top"
                              light
                              id="royaltyPercent"
                              delay={200}
                              content={
                                <span>
                                  This is the percentage of your distribution
                                  revenue that we collect that goes toward
                                  recouping your advance.
                                </span>
                              }
                            />
                          </p>
                          <p>{get(this.state.marketDefaultOffer, "royalty")}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.calculationContainer}>
                      <div className={styles.offerStructureContainer}>
                        <label className={styles.floatingLabel}>
                          Offer structure
                        </label>
                        <div className={styles.offerStructureValues}>
                          <div>
                            <p>
                              $
                              <Odometer
                                value={get(
                                  this.state.marketDefaultOffer,
                                  "upfront",
                                  0,
                                )}
                                format="(,ddd).dd"
                                duration={400}
                              />
                            </p>
                            <p>Upfront advance</p>
                          </div>
                          {!!get(
                            this.state.marketDefaultOffer,
                            "newRelease",
                            0,
                          ) && (
                            <div>
                              <p>
                                $
                                <Odometer
                                  value={get(
                                    this.state.marketDefaultOffer,
                                    "newRelease",
                                    0,
                                  )}
                                  format="(,ddd).dd"
                                  duration={400}
                                />
                              </p>
                              <p>New release advance</p>
                            </div>
                          )}
                          {!!get(
                            this.state.marketDefaultOffer,
                            "optionalRelease",
                            0,
                          ) && (
                            <div>
                              <p>
                                $
                                <Odometer
                                  value={get(
                                    this.state.marketDefaultOffer,
                                    "optionalRelease",
                                    0,
                                  )}
                                  format="(,ddd).dd"
                                  duration={400}
                                />
                              </p>
                              <p>Optional advance</p>
                            </div>
                          )}
                          <div>
                            <p>{get(this.state.offerData, "term", 0)} Year</p>
                            <p>Term length</p>
                          </div>
                        </div>
                        <div className={styles.linkContainer}>
                          <span
                            data-testid="adjustButton"
                            onClick={() => this.getAdjustOffers(true)}
                          >
                            Update Deal Structure
                          </span>
                        </div>
                      </div>
                      <div className={styles.artistShareContainer}>
                        <label className={styles.floatingLabel}>
                          Artist share of income
                        </label>
                        <div className={styles.offerStructureValues}>
                          <div>
                            <p>
                              {get(
                                this.state.offerData,
                                "artist_share_pre_recoup",
                                0,
                              )}
                              %
                            </p>
                            <p>Before recoupment</p>
                          </div>
                          <div>
                            <p>
                              {get(
                                this.state.offerData,
                                "artist_share_post_recoup",
                                0,
                              )}
                              %
                            </p>
                            <p>
                              If recouped before{" "}
                              <span>
                                {get(this.state.offerData, "term", 0)} years
                              </span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {get(
                                this.state.offerData,
                                "income_catalog_post_term",
                                0,
                              )}
                              %
                            </p>
                            <p>At the end of deal</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <span
                      className={styles.btnLink}
                      data-testid="garuntee-offer-link"
                      onClick={this.toggleMarketPopup}
                    >
                      No thanks, I'll take the guaranteed offer
                    </span>
                    <button
                      className={styles.btnPrimary}
                      onClick={this.toggleDetailsPopup}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.moreInfoPopupRender()}
            {this.adjustTermsModalRender()}
            {this.detailsPopup()}
            <GuaranteedModal
              isOpen={this.state.showMarketPopup}
              toggleMarketPopup={this.toggleMarketPopup}
              navigationStatusUpdate={this.navigationStatusUpdate}
              context={this.context}
              totalAdvance={get(this.state.offerData, "total_advance", 0)}
              history={this.props.history}
            />
            {this.state.loading && <Loader light backgroundNone />}
          </div>
        </div>
      </div>
    );
  }
}

SetTargetAdvance.contextType = ThemeContext;
export default SetTargetAdvance;
