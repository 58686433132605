import { getPartnerName } from "../../../component/ThemeManager/helper";

export const MIN_VALUE = 0;
export const MAX_VALUE = 100;
export const RANGE_STEP = 1;
export const TERM_MIN = 1;

export const CATALOG_SLIDER = 0;
export const TERM_SLIDER = 1;
export const C_INC_SLIDER = 2;
export const R_INC_SLIDER = 3;

export const RANGE_MULTIPLY = 0.25;
export const OTHER_VALUE = 60;
export const MIN_MULTIPLIER_VALUE = 0.5;
export const MAX_MULTIPLIER_VALUE = 2.5;
export const INCOME_SLIDER_STEP = 50;

export const INIT_TREND_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export const CUMULATIVE_MAX_PERCENT = 0.8;
export const MIN_TRACK_DISPLAY = 5;
export const OTHER_ISRC = "111";
export const TERM_20 = 20;

export const WIDGET_RENDER = `
<head>
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/chunk.css" rel="stylesheet" />
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/main.chunk.css" rel="stylesheet" />
    <style>
        .getFundedContainer {
            padding: 0;
        }
        .select__input input {
            letter-spacing: -0.3px;
        }
    </style>
</head>
<body>
    <div id="getFunded" class="getFundedContainer"></div>
    <script async>
        window.GET_FUNDED_API_URL = "${process.env.REACT_APP_API_URL}";
        window.WIDGET_HEADLINE = "Sign up";
    </script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/main.chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/runtime-main.js" async></script>
</body>
`;

export const VERIFY_DATA = "Verify Data";

export const VERIFY_DATA_NOTE =
  "If you overestimate your monthly income here the estimates you see may be much higher than our confirmed offers which are based on your actual income and additional data we collect after you upload your distribution reports.";

export const CASHFLOW_OPTIONS = [
  {
    label: "(lower advance)",
    tooltip: "Deal ends at recoupment and all income returns to the Artist.",
  },
  {
    label: "(standard advance)",
    tooltip:
      "Deal extends, and Artist receives a portion of all income until the end of the term.",
  },
  {
    label: "(higher advance)",
    tooltip: `${
      getPartnerName() === "beatBread" ? `beatBread` : `chordCash`
    } continues to collect all income until the end of the term.`,
  },
];
export const CASHFLOW = {
  LOWER: "lower_advance",
  STANDARD: "standard_advance",
  HIGHER: "high_advance",
};

export const CATALOG_TYPE = ["Catalog Only"];

export const ESTIMATED_TOOLTIP_TEXT =
  "This is just an estimate and confirmed funding options will be made available after you upload your distribution reports.";

export const DEFAULT_PARTNER = "beatBread";
export const DEFAULT_RIGHTS = "both";

export const CURRENT_DISTRIBUTOR_LABEL = "Current Distributor";

export const SOUND_EXCHANGE = "soundexchange";

export const TYPE_PRO = "PRO";

export const PUBLISHING_FIELDS = {
  PRO: {
    NAME: "PRO",
    LABEL:
      "Which performing rights organization (PRO) is this artist affiliated with?",
    PLACEHOLDER: "Select Organization",
  },
  PUBLISHER: {
    NAME: "artistPublisher",
    LABEL: "Who is this artist’s publisher?",
    PLACEHOLDER: "Select Organization",
  },
  INCOME: {
    NAME: "recent_revenue",
    LABEL:
      "Approximately how much total publishing income did this artist receive last quarter?",
    PLACEHOLDER: "Enter a dollar amount",
  },
};

export const PRO_OTHER_OPTION = {
  label: "Other",
  value: "other",
};

export const PUBLISHING_DEFAULT_OPTION = {
  label: "This artist isn't published",
  value: "This artist isn't published",
};

export const POR_DEFAULT_OPTION = {
  label: "This artist doesn’t have a PRO",
  value: "I don't have a PRO",
};

export const PUBLISHING_INITIAL_VALUES = {
  [PUBLISHING_FIELDS.PRO.NAME]: "",
  [PUBLISHING_FIELDS.PUBLISHER.NAME]: "",
  [PUBLISHING_FIELDS.INCOME.NAME]: "",
};

export const RIGHTS_TYPES = [
  { label: "Publishing Only", value: "publishing" },
  { label: "Sound Recording Only", value: "masters" },
  { label: "Both", value: "both" },
];

export const PUBLISHING_STREAMING_INCOME_MULTIPLIER = 10;

export const SWITCH_MIN = 0.1;
export const SWITCH_MAX = 0.3;

export const MAX_UPLOAD_COUNT = 10;

export const OTHER_OFFER_FIELDS = {
  TYPE: {
    NAME: "offerType",
    LABEL: "Type of Offer",
    PLACEHOLDER: "Select One",
    OPTIONS: [
      { label: "Catalog Sale", value: "Catalog Sale" },
      { label: "Record Label Deal", value: "Record Label Deal" },
      { label: "Advance from Distributor", value: "Advance from Distributor" },
      {
        label: "Third Party Streaming Advance",
        value: "Third Party Streaming Advance",
      },
      { label: "Publishing Advance", value: "Publishing Advance" },
    ],
  },
  DEFAULT_FORM: {
    totalOffer: null,
    offerType: null,
    termLength: "",
    distributionFee: "",
    offerFrom: "",
  },
  TOTAL_OFFER: {
    NAME: "totalOffer",
    LABEL: "Total Offer",
    PLACEHOLDER: "Enter Amount",
    VALIDATION_MSG_TYPE: "Total Offer must be positive number",
  },
  TERM_LENGTH: {
    NAME: "termLength",
    LABEL: "Term Length",
    PLACEHOLDER: "Enter Length",
    VALIDATION_MSG_TYPE:
      "Please enter valid term length of maximum 20 characters",
  },
  DISTRIBUTION_FEE: {
    NAME: "distributionFee",
    LABEL: "Distribution Fee",
    PLACEHOLDER: "Enter Any Fees included",
    VALIDATION_MSG_TYPE:
      "Please enter valid distribution fees of maximum 20 characters",
  },
  OFFER_FROM: {
    NAME: "offerFrom",
    LABEL: "Who is this offer from?",
    PLACEHOLDER: "Enter Name",
    VALIDATION_MSG_TYPE:
      "Please enter valid offer from of maximum 20 characters",
  },
};
