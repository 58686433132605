import React from "react";
import {
  Visibility,
  VisibilityOff,
  Menu,
  Info,
  AccountCircle,
  MoreVert,
  MusicVideoSharp,
  CheckCircle,
  CloudUpload,
  Language,
  Email,
  Description,
  DeleteRounded,
  GetApp,
  Edit,
  AddCircleOutline,
  RemoveCircleOutline,
  Sync,
  ArrowForward,
  ArrowBack,
  Check,
  Close,
  PhotoCamera,
  Settings,
  PlayArrowRounded,
} from "@material-ui/icons";
import {
  VISIBILITY,
  VISIBILITY_OFF,
  MENU_ICON,
  INFO_ICON,
  PROFILE_ICON,
  MORE_VERT,
  MUSIC_ICON,
  SUCCESS_ICON,
  CLOUD_UPLOAD,
  LANGUAGE,
  EMAIL_ICON,
  DELETE_ICON,
  DOWNLOAD_ICON,
  VIEW_REPORTS_ICON,
  EDIT_ICON,
  PLUS_ICON,
  MINUS_ICON,
  SYNC_ICON,
  ARROW_FORWARD,
  ARROW_BACK,
  CLOSE,
  CHECK,
  CAMERA,
  SETTINGS_ICON,
  PLAY_ARROW_ROUNDED,
} from "./constants";

const SvgIcons = ({ icon, className, ...otherProps }) => {
  const getIconComponent = (Component) => (
    <Component className={className} {...otherProps} />
  );

  switch (icon) {
    case VISIBILITY:
      return getIconComponent(Visibility);
    case VISIBILITY_OFF:
      return getIconComponent(VisibilityOff);
    case MENU_ICON:
      return getIconComponent(Menu);
    case INFO_ICON:
      return getIconComponent(Info);
    case MORE_VERT:
      return getIconComponent(MoreVert);
    case PROFILE_ICON:
      return getIconComponent(AccountCircle);
    case MUSIC_ICON:
      return getIconComponent(MusicVideoSharp);
    case SUCCESS_ICON:
      return getIconComponent(CheckCircle);
    case CLOUD_UPLOAD:
      return getIconComponent(CloudUpload);
    case LANGUAGE:
      return getIconComponent(Language);
    case EMAIL_ICON:
      return getIconComponent(Email);
    case VIEW_REPORTS_ICON:
      return getIconComponent(Description);
    case DELETE_ICON:
      return getIconComponent(DeleteRounded);
    case DOWNLOAD_ICON:
      return getIconComponent(GetApp);
    case EDIT_ICON:
      return getIconComponent(Edit);
    case PLUS_ICON:
      return getIconComponent(AddCircleOutline);
    case MINUS_ICON:
      return getIconComponent(RemoveCircleOutline);
    case SYNC_ICON:
      return getIconComponent(Sync);
    case ARROW_FORWARD:
      return getIconComponent(ArrowForward);
    case ARROW_BACK:
      return getIconComponent(ArrowBack);
    case CLOSE:
      return getIconComponent(Close);
    case CHECK:
      return getIconComponent(Check);
    case CAMERA:
      return getIconComponent(PhotoCamera);
    case SETTINGS_ICON:
      return getIconComponent(Settings);
    case PLAY_ARROW_ROUNDED:
      return getIconComponent(PlayArrowRounded);
    default:
      return null;
  }
};

export default SvgIcons;
