import React, { useEffect } from "react";
import { initSparrowSurvey } from "../../../utils/Helper";
import { get } from "lodash";

const SurveySparrow = (props) => {
  useEffect(() => {
    initSparrowSurvey(get(props, "data"), get(props, "isRejected", false));
    return () => {
      const surveyWidget = document.getElementById("ss-widget");
      get(surveyWidget, "parentElement.tagName") === "BODY" &&
        document.body.removeChild(surveyWidget);
    };
  }, []);
  return (
    <div>
      <div id="ss_survey_widget"></div>
    </div>
  );
};

export default SurveySparrow;
