import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "./CompareDealChart.module.scss";
import { CHART_MAX_INDEX, LINES_COLORS } from "./constants";
import { get } from "lodash";
import { useMediaQuery } from "../../../utils/Helper";

function DealBarChart({ data, filter }) {
  const [chartData, setChartData] = useState([]);
  const category = get(filter, "category.value", "");
  const song = get(filter, "song", null);
  const isMobile = useMediaQuery("(max-width:767px)");
  useEffect(() => {
    const groupedData = data.reduce((acc, deal) => {
      if (deal.cashflows[category] && deal.cashflows[category].n_new[song]) {
        const artistCfs = deal.cashflows[category].n_new[song]["artist_cfs"];
        artistCfs.forEach((value, index) => {
          if (!acc[index]) acc[index] = { index };
          acc[index][deal.title] = value < 0 ? 0 : Math.floor(value);
        });
      }
      return acc;
    }, []);
    setChartData(groupedData);
  }, [isMobile, data, category, song]);

  const maxIndex = chartData.length
    ? Math.max(...chartData.map((item) => item.index))
    : 0;

  const currentYear = new Date().getFullYear();
  const yearsRange = [...Array(maxIndex + 1)].map((_, i) => {
    const shortYear = (currentYear + i).toString().slice(-2);
    return `${shortYear}'`;
  });

  const getInterval = (maxIndex) => {
    let interval;
    if (isMobile) {
      interval =
        maxIndex > CHART_MAX_INDEX[0] ? CHART_MAX_INDEX[1] : CHART_MAX_INDEX[2];
    } else {
      interval =
        maxIndex > CHART_MAX_INDEX[0] ? CHART_MAX_INDEX[2] : CHART_MAX_INDEX[3];
    }
    return interval;
  };

  const getXAxisFontSize = (maxIndex) => {
    let fontSize;
    if (isMobile) {
      fontSize = "12px";
    } else {
      fontSize = maxIndex > CHART_MAX_INDEX[0] ? "15px" : "20px";
    }
    return fontSize;
  };

  return (
    <ResponsiveContainer height={425}>
      <BarChart
        data={chartData}
        barSize={isMobile ? 20 : 30}
        data-testid="chart-bar"
        {...(isMobile ? { margin: { left: -5 } } : {})}
      >
        <XAxis
          dataKey="index"
          tickLine={false}
          axisLine={false}
          domain={[0, maxIndex]}
          tick={{
            fontSize: getXAxisFontSize(maxIndex),
            textAnchor: "end",
          }}
          tickFormatter={(index) => yearsRange[index]}
          interval={getInterval(maxIndex)}
          ticks={
            maxIndex > 20
              ? [...Array(maxIndex + 1).keys()].filter(
                  (_, i) => i % (isMobile ? 3 : 2) === 0,
                )
              : [...Array(maxIndex + 1).keys()]
          }
        />
        <YAxis
          tickLine={false}
          tickFormatter={(value) =>
            value > 0 ? `$${Math.floor(value / 1000)}K` : ""
          }
          label={{
            value: "Dollar Amount",
            className: styles.yAxisLabel,
            angle: -90,
            dx: isMobile ? -20 : -30,
          }}
          tick={{
            fontSize: isMobile ? "10px" : "14px",
          }}
          axisLine={false}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          labelFormatter={() => ""}
          formatter={(value, name) => [
            `$${new Intl.NumberFormat().format(value)}`,
            name,
          ]}
        />
        <Legend iconType="circle" />
        {Object.keys(chartData[0] || {})
          .filter((key) => key !== "index")
          .map((title, idx) => (
            <Bar
              key={title}
              dataKey={title}
              fill={LINES_COLORS[idx % LINES_COLORS.length]}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default DealBarChart;
