import React from "react";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import styles from "./Getfunded.module.scss";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import Image from "../../../../component/Image";

export const formatOptionLabel = ({ label, imageUrl }) => (
  <div className={styles.selectOption}>
    <div className={`d-flex align-items-center`}>
      <div className={styles.profile}>
        {imageUrl ? (
          <Image src={imageUrl} alt="artist_profile" imageType={IMAGE_TYPE.PROFILE} />
        ) : (
            <SvgIcons icon={PROFILE_ICON} />
          )}
      </div>
      <div>
        <p>{label}</p>
      </div>
    </div>
    <button type="button" className="btn">
      Select
    </button>
  </div>
);



