/* eslint-disable implicit-arrow-linebreak */
import { get, invoke, min } from "lodash";
import request from "./request";
import {
  API_URL,
  SEGMENT_DATA,
  FUNDING, // /customize-funding
} from "../routes/pages/constants";
import StorageService from "./StorageService";

const IS_TEST_REGEX = /(\+|beatbread|growexx|chordcash)/i;
const parseBoolean = (inBool) => {
	if (typeof inBool === 'string') {
		if (inBool.toLowerCase() === 'true') {
			return true;
		} else if (inBool.toLowerCase() === 'false') {
			return false;
		} else {
			return inBool;
		}
	} else {
		return inBool
	}
}
const parseString = (inString) => {
  if (typeof inString === 'string') {
    if (inString === 'null') {
      return null;
    } else if (inString === 'undefined') {
      return undefined;
    } else if (inString === 'NaN') {
      return NaN;
    } else if (inString === 'true' || inString === 'false') {
      return parseBoolean(inString)
    } else {
      return inString;
    }
  } else {
    return inString;
  }
}
const isTest = (inEmail) => StorageService.get("customToken") || IS_TEST_REGEX.test(inEmail);
const setData = (inEmail,inArtistId,inUserId,inFast) => {
  localStorage.setItem('bb_segment_user_id',inUserId);
  if (inEmail) {
    localStorage.setItem('bb_segment_is_test',isTest(inEmail));
  }
  if (inArtistId) {
    localStorage.setItem('bb_segment_artist_id',inArtistId);
  }
  if (inFast) {
    localStorage.setItem('bb_segment_fast',inFast);
  }
}


const updateArtist = (inArtistId) => {
  if (inArtistId) {
    localStorage.setItem('bb_segment_artist_id',inArtistId);
  }
}

const isFast = (inUserId) => {
  try {
    const fast = parseString(localStorage.getItem('bb_segment_fast'));
    if (fast) {
      return fast;
    } else {
      const data = {
        method: "GET",
      };
      const userId = inUserId || parseString(localStorage.getItem('bb_segment_user_id'));
      if (!fast && fast !== false && userId) {
        const requestURL = `${API_URL}${SEGMENT_DATA}?id=${userId}`;
        return request(requestURL, data)
        .then((res) => {
          if (res.status) {
            if (res.data) {
              const activeArtist = JSON.parse(localStorage.getItem('activeArtist'));
              const fast = activeArtist.body ? res.data.artists.find(a => a.name.toLowerCase() === activeArtist.body.toLowerCase()).fast : null;
              localStorage.setItem('bb_segment_fast',fast);
              return parseBoolean(fast);
            } else {
              return null;
            }
          } else {
            return null;
          };
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
      }
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

const getDataAPI = (key,value,inEmail,complex) => {
  const data = {
    method: "GET",
  };
  const userId = parseString(localStorage.getItem('bb_segment_user_id'));
  let query = ''
  if (key && key?.length > 0 && value) {
    query = `?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  } else if (inEmail) {
    query = `?email=${inEmail}`;
  } else if (userId) {
    query = `?id=${userId}`;
  } else if (localStorage.getItem('ajs_user_id')) {
    query = `?id=${localStorage.getItem('ajs_user_id').replace(/"/gmi,'')}`;
  }
  const requestURL = `${API_URL}${SEGMENT_DATA}${query}`;
  return request(requestURL, data)
  .then((res) => {
    if (res.status) {
      if (res.data) {
        if (complex) {
          return res.data;
        } else {
          const activeArtist = JSON.parse(localStorage.getItem('activeArtist'));
          const isAdminInMaintenance = JSON.parse(localStorage.getItem('isAdminInMaintenance'));
          let artistId = null;
          let isTestAccount = res?.data?.isTest;
          if (activeArtist?.opts?.id) {
            artistId = activeArtist.opts.id;
          } else if (activeArtist?.body) {
            artistId = res.data.artists.find(a => a.name.toLowerCase() === activeArtist.body.toLowerCase()).id;
          }
          if (isAdminInMaintenance) {
            isTestAccount = isAdminInMaintenance?.body || null;
          }
          return {
            artistId: artistId,
            isTest: isTestAccount,
          }
        }
      } else {
        if (complex) {
          return null;
        } else {
          return {
            artistId: parseString(localStorage.getItem('bb_segment_artist_id')) || null,
            isTest: inEmail ? isTest(inEmail) : null,
          }
        }
      }
    } else {
      if (complex) {
        return null;
      } else {
        return {
          artistId: parseString(localStorage.getItem('bb_segment_artist_id')) || null,
          isTest: inEmail ? isTest(inEmail) : null,
        }
      }
    }
  })
  .catch((error) => {
    console.log(error);
    if (complex) {
      return null;
    } else {
      return {
        artistId: parseString(localStorage.getItem('bb_segment_artist_id')) || null,
        isTest: inEmail ? isTest(inEmail) : null,
      }
    }
  });
}

const getDataStorage = async () => {
  let artistId = '';
  let isTestAccount = false;
  let fast = false;
  artistId = parseString(localStorage.getItem('bb_segment_artist_id'));
  isTestAccount = parseString(localStorage.getItem('bb_segment_is_test'));
  fast = parseString(localStorage.getItem('bb_segment_fast'));
  if (!artistId || (!isTestAccount && isTestAccount !== false) || (!fast && fast !== false)) {
    const userId = parseString(localStorage.getItem('bb_segment_user_id'));
    const res = await getDataAPI('id',userId);
    artistId = parseString(res?.artistId) || null;
    isTestAccount = parseString(res?.isTest);
    fast = parseString(res?.fast);
    localStorage.setItem('bb_segment_artist_id',artistId);
    localStorage.setItem('bb_segment_is_test',isTestAccount);
    localStorage.setItem('bb_segment_fast',fast);
  }
  return {
    ...artistId ? {artistId: parseString(artistId)} : {},
    isTest: parseBoolean(isTestAccount),
  };
}

const getData = async (api,...options) => {
  if (api) {
    const [key,value,complex] = options;
    return await getDataAPI(key,value,complex)
  } else {
    return await getDataStorage();
  }
}

const minMaxAdvance = (data,fast=false) => {
  let minAdvance = -1;
  let maxAdvance = -1;
  if (data?.advanceData || data?.advanceData?.[data?.state?.selectedPartner]) {
    if (fast) {
      const initialAdvanceData = data?.advanceData;
      const min = Object.values(initialAdvanceData).map((e) => {
        return e?.min || Object.values(e).map(e1 => {
          return e1?.min || Object.values(e1).map(e2 => {
            return e2?.min || Object.values(e2).map(e3 => {
              return e3?.min || Object.values(e3).map(e4 => {
                return e4?.min
              })
            })
          })
        })
      }).flat(4);
      const max = Object.values(initialAdvanceData).map((e) => {
        return e?.min || Object.values(e).map(e1 => {
          return e1?.min || Object.values(e1).map(e2 => {
            return e2?.min || Object.values(e2).map(e3 => {
              return e3?.min || Object.values(e3).map(e4 => {
                return e4?.min
              })
            })
          })
        })
      }).flat(4);
      minAdvance = Math.min(...min);
      maxAdvance = Math.max(...max);
    } else {
      const initialAdvanceData = data?.advanceData?.[data?.state?.selectedPartner] || data?.advanceData;
      let advance = Object.values(initialAdvanceData).map((e) => {
        return e?.total_advance || Object.values(e).map(e1 => {
          return e1?.total_advance || Object.values(e1).map(e2 => {
            return e2?.total_advance || Object.values(e2).map(e3 => {
              return e3?.total_advance || Object.values(e3).map(e4 => {
                return e4?.total_advance
              })
            })
          })
        })
      }).flat(4);
      advance = invoke(advance, 'filter', v=>!!v);
      minAdvance = Math.min(...advance);
      maxAdvance = Math.max(...advance);
    }
  }
  return {
    minAdvance: minAdvance,
    maxAdvance: maxAdvance,
  };
}

const advanceBreakdown = (data,fast=false) => {
  if (fast) {
    const { min, max } = data.currentData;
    return {
      selectedMinAdvance: min,
      selectedMaxAdvance: max,
    }
  } else {
    const { catalog_advance, nr_advance, option_advance } = data.currentData;
    return {
      catalogAdvance: catalog_advance,
      newReleaseAdvance: nr_advance,
      optionAdvance: option_advance,
    }
  }
}

const USE_API = true;
const OPTIONS = [];

const segment = {
  page: () => invoke(window, "analytics.page"),
  identify: async (userId, traits={}, options={}) => invoke(window, "analytics.identify", userId, {
    ...await getData(USE_API,OPTIONS),
    ...traits,
  }, {
    ...options,
  }),
  track: {
    viewedQuote:  async (data,fast=false) => {
      const { minAdvance, maxAdvance } = minMaxAdvance(data,fast);
      invoke(window, "analytics.track", "Viewed Quote", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: fast ? "fast_v1" : "legacy",
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
      })
    },
    customizedFunding: async (data, options = {}, inFast = false) => {
      const fast = typeof parseString(inFast) === 'boolean' ? parseString(inFast) : await isFast();
      const { minAdvance, maxAdvance } = minMaxAdvance(data,fast);
      const ab = advanceBreakdown(data,fast);
      invoke(window, "analytics.track", "Customized Funding", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        adjustedWorks:
          get(data, `defaultOffer.${fast ? 'display' : 'works'}`) !== get(data, `currentData.${fast ? 'display' : 'works'}`),
        worksVersion: [
          {
            previousValue: get(data, `defaultOffer.${fast ? 'display' : 'works'}`),
            adjustedValue: get(data, `currentData.${fast ? 'display' : 'works'}`),
          },
        ],
        adjustedTermLength:
          get(data, `defaultOffer.${fast ? 'term' : 'term'}`) !==
          get(data, `currentData.${fast ? 'term' : 'term'}`),
        termLengthVersion: [
          {
            previousValue: get(data, `defaultOffer.${fast ? 'term' : 'term'}`),
            adjustedValue: get(data, `currentData.${fast ? 'term' : 'term'}`),
          },
        ],
        adjustedFlowthrough:
          get(data, `defaultOffer.${fast ? 'flowthrough' : 'future'}`) !==
          get(data, `currentData.${fast ? 'flowthrough' : 'future'}`),
        flowthroughVersion: [
          {
            previousValue: get(data, `defaultOffer.${fast ? 'flowthrough' : 'future'}`),
            adjustedValue: get(data, `currentData.${fast ? 'flowthrough' : 'future'}`),
          },
        ],
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
        ...ab,
        ...fast ? { interestedInPublishingAdvance: get(data, 'toggle') } : {},
        ...fast && get(data, "addPublisher") ?
          { rightType: get(data, 'currentData.rights_type') }
          : "",
        userPath: fast ? "fast_v1" : "legacy",
        customerLifecycleStage: "qualified_lead",
        funnelStage: "customized_funding",
        leadStatus: "in_progress",
      },
        options,
      );
    },
    submitForReview: async (data,fast=false) =>
      invoke(window, "analytics.track", "Submit For Review", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        reportDetails: get(data, "files"),
        upload6MonthOfReports: get(data, "uploadConfirmation"),
        deleteReportFromDistirbutor: get(data, "deleteReportFromDistirbutor"),
        deleteDistributor: get(data, "deleteDistributor"),
        userPath: await isFast() ? "fast_v1" : "legacy",
        customerLifecycleStage: "opportunity",
        funnelStage: "uploaded_reports",
        leadStatus: "open_deal",
      }),
    verifiedData: async (data,fast=false) =>
      invoke(window, "analytics.track", "Verified Data", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        adjustedOwnership: get(data, "isSliderChange"),
        isrcVersion: get(data, "topTracks", [
          { isrc: 0, previousValue: 0, adjustedValue: 0 },
        ])?.map((e, i) => ({
          isrc: e?.isrc,
          previousValue: get(data, "resetTopTracks")?.[i]
            ?.streaming_info_share,
          adjustedValue: e?.streaming_info_share,
        })),
        userPath: await isFast() ? "fast_v1" : "legacy",
        customerLifecycleStage: "qualified_lead",
        funnelStage: "verified_data",
        leadStatus: "in_progress",
      }),
    createdAccount: async (fast=false) =>
      invoke(window, "analytics.track", "Created Account", {
        source: "beatBread App",
        ...await getData(USE_API, OPTIONS),
        userPath: "fast_v1",
        customerLifecycleStage: "qualified_lead",
        funnelStage: "signed_up",
        leadStatus: "new_single_artist",
      }),
    viewedComparisonTable: async (fast=false) =>
      invoke(window, "analytics.track", "Viewed Comparison Table", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: fast ? "fast_v1" : "legacy",
        customerLifecycleStage: "opportunity",
        funnelStage: "viewed_offers",
        leadStatus: "open_deal",
      }),
    viewedFinalOffer:  async (data,fast=false) => {
      const { minAdvance, maxAdvance } = minMaxAdvance(data,fast);
      invoke(window, "analytics.track", "Viewed Final Offer", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: fast ? "fast_v1" : "legacy",
        dealStatus: "offer_received",
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
      })
    },
    selectedFinalOffer: async (data,fast=false) => {
      const { minAdvance, maxAdvance } = minMaxAdvance(data,fast);
      const ab = advanceBreakdown(data,fast);
      invoke(window, "analytics.track", "Selected Final Offer", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        adjustedConfirmedWorks:
          get(data, "defaultOffer.works") !== get(data, "currentData.works"),
        confirmedWorksVersion: [
          {
            previousValue: get(data, "defaultOffer.works"),
            adjustedValue: get(data, "currentData.works"),
          },
        ],
        adjustedConfirmedTermLength:
          get(data, "defaultOffer.term") !==
          get(data, "currentData.term"),
        confirmedTermLengthVersion: [
          {
            previousValue: get(data, "defaultOffer.term"),
            adjustedValue: get(data, "currentData.term"),
          },
        ],
        adjustedConfirmedFlowthrough:
          get(data, "defaultOffer.future") !==
          get(data, "currentData.future"),
        confirmedFlowthroughVersion: [
          {
            previousValue: get(data, "defaultOffer.future"),
            adjustedValue: get(data, "currentData.future"),
          },
        ],
        userPath: await isFast() ? "fast_v1" : "legacy",
        selectedPartner: get(data, "selectedPartner"),
        ...ab,
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
        customerLifecycleStage: "opportunity",
        funnelStage: "viewed_offers",
        leadStatus: "open_deal",
        dealStatus: "offer_accepted",
      })
    },
    proceededToOnboarding: async (v2=false) =>
      invoke(window, "analytics.track", "Proceeded To Onboarding", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: await isFast() ? "fast_v1" : "legacy",
        customerLifecycleStage: "opportunity",
        funnelStage: "uploaded_reports",
        leadStatus: "open_deal",
        dealStatus: "offer_approved",
        finalCheckoutVersion: v2 ? 2.0 : undefined,
      }),
    backToSelectConfirmedOffer: async (fast=false) =>
      invoke(window, "analytics.track", "Back To Select Confirmed Offer", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: await isFast() ? "fast_v1" : "legacy",
        customerLifecycleStage: "opportunity",
        funnelStage: "selected_offers",
        leadStatus: "open_deal",
        dealStatus: "offer_accepted",
      }),
    loginApp: async (data,fast=false) =>
      invoke(window, "analytics.track", "Logged In", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        emailAddress: get(data, "emailOrPhone"),
        userPath: await isFast() ? "fast_v1" : "legacy",
        customerLifecycleStage: "qualified_lead",
        funnelStage: "signed_up",
        leadStatus: "open_multi_artist",
      }),
    addPublishing: async (options = {}, fast = false) =>
      invoke(window, "analytics.track", "Clicked Publishing", {
        ...await getData(USE_API, OPTIONS),
        userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    publisherAdded: async (options = {}, fast = false) =>
      invoke(window, "analytics.track", "Added Publishing", {
        ...await getData(USE_API, OPTIONS),
        userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    serviceDown: async (options = {}, fast = false) =>
      invoke(window, "analytics.track", "Service Down", {
        ...await getData(USE_API, OPTIONS),
        userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    artistTooBig: async (options={},fast=false) =>
      invoke(window, "analytics.track", "Artist Too Big", {
        ...await getData(USE_API,OPTIONS),
        userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    artistTooSmall: async (options={},fast=false) =>
      invoke(window, "analytics.track", "Artist Too Small", {
        ...await getData(USE_API,OPTIONS),
        userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    artistFailed: async (options={},fast=false) =>
        invoke(window, "analytics.track", "Artist Failed", {
          ...await getData(USE_API,OPTIONS),
          userPath: fast ? 'fast_v1' : 'legacy',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    offersPending: async (options={},fast=false) =>
      invoke(window, "analytics.track", "Offers Pending", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
      }, {
        ...options,
        source: 'beatBread App',
      }),
    incomeWarning: async (options = {}) => {
      invoke(window, "analytics.track", "Income Warning", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'signed_up',
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    incomeOverreported: async (options = {}) => {
      invoke(window, "analytics.track", "Income Overreported", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'signed_up',
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    quoteDelayed: async (options = {}) => {
      invoke(window, "analytics.track", "Quote Delayed", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'signed_up',
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    decision: async (path) => {
      invoke(window, "analytics.track", `Decision: ${path}`, {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
      }, {
        source: 'beatBread App'
      })
    },
    skippedVideo: async (options = {}) => {
      invoke(window, "analytics.track", "Skipped Video", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0"
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    recommendedOffers: async (options = {}) => {
      invoke(window, "analytics.track", "Chose Recommended Offers", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0"
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    buildYourOffers: async (options = {}) => {
      invoke(window, "analytics.track", "Chose To Build Your Offers", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0"
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    preferredAmount: async (options = {}) => {
      invoke(window, "analytics.track", "Preferred Amount", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
        preferredAmount: get(options,"preferredAmount"),
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    preferredWorks: async (options = {}) => {
      invoke(window, "analytics.track", "Preferred Works", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
        preferredWorks: get(options,"preferredWorks"),
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    selectedFinalOffer: async (options = {}) => {
      invoke(window, "analytics.track", "Selected Final Offer", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    proceedCustomizeOffer: async (options = {}) => {
      invoke(window, "analytics.track", "Proceeded To Customize Offer", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    viewedFinalOfferV2: async (options = {}) => {
      invoke(window, "analytics.track", "Viewed Final Offer", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
    comparedOffers: async (options = {}) => {
      invoke(window, "analytics.track", "Compared Offers", {
        ...await getData(USE_API, OPTIONS),
        userPath: 'fast_v1',
        funnelStage: 'viewed_offers',
        customerLifecycleStage: "opportunity",
        leadStatus: "open_deal",
        finalCheckoutVersion: "2.0",
      }, {
        ...options,
        source: 'beatBread App'
      })
    },
  },
  storage: {
    set: (inEmail,inArtistId,inUserId,inFast) => setData(inEmail,inArtistId,inUserId,inFast),
    get: (api,...options) => getData(api,options),
    update: (inArtistId) => updateArtist(inArtistId,),
  },
};

export default segment;
