import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./AcknowledgementScreen.module.scss";
import StorageService from "../../../../utils/StorageService";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import {
  getSubDomain,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import Register from "../Register/Register";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import HTMLReactParser from "html-react-parser";

class AcknowledgementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistDetails: StorageService.get("ackArtistDetails", {
        localStorage: true,
      }),
      profileUrl: StorageService.get("ackArtistImg"),
    };
  }

  componentDidUpdate() {
    setTitle("", this.context);
  }

  goToURL = () => {
    window.location.href = get(this.context, "acknowledgementCTA.actionUrl");
  };

  partnerUi = () => get(this.state, "artistDetails.isPartner");
  render() {
    return (
      <div
        className={`${styles.container} ${
          BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
            ? styles.backgroundImage
            : ""
        }`}
      >
        <div className={styles.headerContainer}>
          <Image
            src={get(this.context, "appLogo")}
            alt="logo"
            imageType={IMAGE_TYPE.LOGO}
            className={styles.instanceLogo}
          />
        </div>
        <div className={`${styles.subContainer}`}>
          <div className={styles.details}>
            <div
              className={`${styles.registerBox} ${
                this.partnerUi() &&
                !get(this.context, "isResetPassword") &&
                styles.borderRight
              }`}
            >
              <Register
                artistId={get(this.state.artistDetails, "artistId")}
                verificationCode={get(this.state.artistDetails, "code")}
                className={`${styles.registerContainer} ${
                  this.partnerUi() &&
                  !get(this.context, "isResetPassword") &&
                  styles.borderTop
                }`}
                userId={get(this.state.artistDetails, "userId")}
                ackScreen={true}
                {...this.props}
              />
            </div>
            <div
              className={`${styles.getFundedFastContainer} ${
                this.partnerUi() &&
                !get(this.context, "isResetPassword") &&
                !get(this.context, "acknowledgementCTA.buttonText") &&
                styles.displayNone
              } `}
            >
              {!this.partnerUi() ? (
                <div className={styles.getFundedBody}>
                  <div className={styles.getFundedFastHeader}>
                    <span>Get Funded Fast</span>
                    <p>See confirmed offers in a few quick steps:</p>
                  </div>
                  <div className={styles.getFundedFastDetails}>
                    <ol>
                      <li>Verify your data</li>
                      <li>
                        Choose the Advance structure that is right for you
                      </li>
                      <li>Upload your distribution reports for review</li>
                      <li>Receive your confirmed offers</li>
                    </ol>
                  </div>
                </div>
              ) : (
                <>
                  {get(this.context, "isResetPassword") &&
                    get(this.context, "resetPassword") && (
                      <div className={styles.getFundedBody}>
                        {HTMLReactParser(get(this.context, "resetPassword"))}
                      </div>
                    )}
                </>
              )}

              <div className={styles.getFundedFooter}>
                {get(this.context, "acknowledgementCTA.buttonText") && (
                  <div className={styles.acknowledgementCTA}>
                    <p className={styles.btn} onClick={this.goToURL}>
                      {get(this.context, "acknowledgementCTA.buttonText")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AcknowledgementScreen.contextType = ThemeContext;
export default AcknowledgementScreen;

AcknowledgementScreen.contextTypes = {
  acknowledgementCTA: PropTypes.object,
};
