import React, { useEffect, useState } from "react";
import style from "./ShareOtherOffers.module.scss";
import { get, invoke } from "lodash";
import ReactModal from "react-modal";
import { Form, Formik } from "formik";
import { OTHER_OFFER_FIELDS } from "./constants";
import * as Yup from "yup";
import FormField from "../../../component/FormField/FormField";
import { API_URL, ARTIST_API, USER_API, OTHER_OFFERS } from "../constants";
import request from "../../../utils/request";
import Loader from "../../../component/Loader";
import { ReactComponent as DollarIcon } from "../../../assets/logos/IconDollar.svg";
import { ReactComponent as TERMLEN } from "../../../assets/logos/IconClock.svg";
import { toast } from "react-toastify";
import ShareOtherReports from "./ShareOtherReports";

const ShareOtherOffers = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otherOfferData, setOtherOfferData] = useState({});
  const [isUpload, setIsUpload] = useState(false);

  const getSharedOtherOffer = () => {
    setIsSubmitting(true);
    const data = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFERS}`;
    request(requestURL, data)
      .then((res) => {
        setIsSubmitting(false);
        setOtherOfferData(get(res, "data", {}));
      })
      .catch((err) => {
        toast.error(get(err, "message"));
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      getSharedOtherOffer();
    }
  }, [props.isOpen]);

  const getValidateOtherOffers = () =>
    Yup.object().shape({
      offerType: Yup.string()
        .nullable()
        .required()
        .label(OTHER_OFFER_FIELDS.TYPE.LABEL),
      totalOffer: Yup.number()
        .required()
        .nullable()
        .min(0, OTHER_OFFER_FIELDS.TOTAL_OFFER.VALIDATION_MSG_TYPE)
        .label(OTHER_OFFER_FIELDS.TOTAL_OFFER.LABEL),
      termLength: Yup.string()
        .max(20, OTHER_OFFER_FIELDS.TERM_LENGTH.VALIDATION_MSG_TYPE)
        .label(OTHER_OFFER_FIELDS.TERM_LENGTH.LABEL),
      distributionFee: Yup.string()
        .nullable()
        .max(20, OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.VALIDATION_MSG_TYPE),
      offerFrom: Yup.string()
        .nullable()
        .max(20, OTHER_OFFER_FIELDS.OFFER_FROM.VALIDATION_MSG_TYPE),
    });

  const saveShareOtherOffer = (values) => {
    setIsSubmitting(true);
    const payload = {
      [OTHER_OFFER_FIELDS.TYPE.NAME]: get(values, OTHER_OFFER_FIELDS.TYPE.NAME),
      [OTHER_OFFER_FIELDS.TOTAL_OFFER.NAME]: get(
        values,
        OTHER_OFFER_FIELDS.TOTAL_OFFER.NAME,
      ),
      [OTHER_OFFER_FIELDS.OFFER_FROM.NAME]: get(
        values,
        OTHER_OFFER_FIELDS.OFFER_FROM.NAME,
      ),
    };
    if (
      get(values, OTHER_OFFER_FIELDS.TYPE.NAME) ===
      OTHER_OFFER_FIELDS.TYPE.OPTIONS[2].value
    ) {
      payload[OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.NAME] = get(
        values,
        OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.NAME,
      );
      payload[OTHER_OFFER_FIELDS.TERM_LENGTH.NAME] = get(
        values,
        OTHER_OFFER_FIELDS.TERM_LENGTH.NAME,
      );
    }
    if (
      get(values, OTHER_OFFER_FIELDS.TYPE.NAME) ===
      OTHER_OFFER_FIELDS.TYPE.OPTIONS[3].value
    ) {
      payload[OTHER_OFFER_FIELDS.TERM_LENGTH.NAME] = get(
        values,
        OTHER_OFFER_FIELDS.TERM_LENGTH.NAME,
      );
    }
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFERS}`;
    request(requestURL, data)
      .then((res) => {
        setIsSubmitting(false);
        if (get(res, "status")) {
          toast.success(res.message);
          invoke(props, "onRequestClose");
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        toast.error(get(err, "message"));
        setIsSubmitting(false);
        return false;
      });
  };

  const renderOtherOffersForm = (values) => (
    <>
      <div className="form-group mb-0">
        <FormField
          name={OTHER_OFFER_FIELDS.TYPE.NAME}
          label={
            <div className={style.labelContainer}>
              <span>{OTHER_OFFER_FIELDS.TYPE.LABEL}</span>
            </div>
          }
          as="select"
          placeholder={OTHER_OFFER_FIELDS.TYPE.PLACEHOLDER}
          className={style.inputClass}
          options={OTHER_OFFER_FIELDS.TYPE.OPTIONS}
          autoComplete="off"
          containerClass={style.offerType}
          classNamePrefix="offerType"
        />
      </div>
      <div className="form-group mb-0">
        <FormField
          name={OTHER_OFFER_FIELDS.TOTAL_OFFER.NAME}
          label={
            <div className={style.labelContainer}>
              <span>{OTHER_OFFER_FIELDS.TOTAL_OFFER.LABEL}</span>
            </div>
          }
          as="numeric"
          placeholder={OTHER_OFFER_FIELDS.TOTAL_OFFER.PLACEHOLDER}
          className={style.inputClass}
          autoComplete="off"
          icon={<DollarIcon />}
        />
      </div>
      {(get(values, OTHER_OFFER_FIELDS.TYPE.NAME) ===
        OTHER_OFFER_FIELDS.TYPE.OPTIONS[2].value ||
        get(values, OTHER_OFFER_FIELDS.TYPE.NAME) ===
          OTHER_OFFER_FIELDS.TYPE.OPTIONS[3].value) && (
        <div className="form-group mb-0">
          <FormField
            name={OTHER_OFFER_FIELDS.TERM_LENGTH.NAME}
            label={
              <div className={style.labelContainer}>
                <span>{OTHER_OFFER_FIELDS.TERM_LENGTH.LABEL}</span>
                <div className={style.extraFormField}>(Optional)</div>
              </div>
            }
            placeholder={OTHER_OFFER_FIELDS.TERM_LENGTH.PLACEHOLDER}
            className={style.inputClass}
            mainDiv={style.mainInputContainer}
            autoComplete="off"
            icon={<TERMLEN />}
          />
        </div>
      )}
      {get(values, OTHER_OFFER_FIELDS.TYPE.NAME) ===
        OTHER_OFFER_FIELDS.TYPE.OPTIONS[2].value && (
        <div className="form-group mb-0">
          <FormField
            name={OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.NAME}
            label={
              <div className={style.labelContainer}>
                <span>{OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.LABEL}</span>
                <div className={style.extraFormField}>(Optional)</div>
              </div>
            }
            placeholder={OTHER_OFFER_FIELDS.DISTRIBUTION_FEE.PLACEHOLDER}
            className={`${style.inputClass} ${style.optinalField}`}
            autoComplete="off"
          />
        </div>
      )}
      <div className="form-group mb-0">
        <FormField
          name={OTHER_OFFER_FIELDS.OFFER_FROM.NAME}
          label={
            <div className={style.labelContainer}>
              <span>{OTHER_OFFER_FIELDS.OFFER_FROM.LABEL}</span>
              <div className={style.extraFormField}>(Optional)</div>
            </div>
          }
          placeholder={OTHER_OFFER_FIELDS.OFFER_FROM.PLACEHOLDER}
          className={`${style.inputClass} ${style.optinalField}`}
          autoComplete="off"
        />
      </div>
      <div className={style.btnContainer}>
        <button
          className={style.btnPrimary}
          type="submit"
          name="submit"
          disabled={isSubmitting}
          data-testid="submit-btn"
        >
          SUBMIT
          {isSubmitting && (
            <span className={style.spin}>
              <Loader />
            </span>
          )}
        </button>
      </div>
    </>
  );

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      className={style.otherOfferForm}
      overlayClassName={style.modalOverlay}
    >
      {!isUpload ? (
        <div className={style.shareOfferContainer}>
          <button
            data-testid="otherform-close"
            className={style.closeBtn}
            onClick={props.onRequestClose}
          >
            +
          </button>
          <div className={style.headerContainer}>
            <h1 className={style.header}>Share Your Offer</h1>
            <div className={style.subHeader}>
              This will help us challenge the network and generate the most
              compelling alternative funding options for you.
            </div>
          </div>
          <div className={style.formContainer}>
            <Formik
              initialValues={{
                ...OTHER_OFFER_FIELDS.DEFAULT_FORM,
                ...otherOfferData,
              }}
              validationSchema={getValidateOtherOffers()}
              onSubmit={saveShareOtherOffer}
              enableReinitialize
            >
              {({ values }) => <Form>{renderOtherOffersForm(values)}</Form>}
            </Formik>
          </div>
          <button
            data-testid="upload-section"
            onClick={() => setIsUpload(true)}
            className={style.linkBtn}
          >
            Upload Your Offer Instead
          </button>
        </div>
      ) : (
        <div className={style.uploadSection}>
          <span
            className={style.closeBtn}
            onClick={get(props, "onRequestClose")}
          >
            +
          </span>
          <div className={style.headerContainer}>
            <h1 className={style.header}>Share Your Offer</h1>
            <div className={style.subHeader}>
              Upload any current advance or catalog purchase offers you already
              have so we can leverage them to get you even better terms from our
              funding network.
            </div>
          </div>
          <ShareOtherReports
            onRequestClose={get(props, "onRequestClose")}
            goBack={setIsUpload}
          />
        </div>
      )}
    </ReactModal>
  );
};

export default ShareOtherOffers;
