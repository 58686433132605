import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { get, invoke, set } from "lodash";
import { ErrorMessage, Form, Formik } from "formik";
import { ALPHA_WITH_APOSTROPHE_HYPHEN, ALPHA_WITH_APOSTROPHE_HYPHEN_FULLNAME } from "../PostInvestorNetwork/UpcomingWorks/constant";
import { EMAIL_REGEX, PHONE_REGEX } from "../Auth/constants";
import { DEFAULT_BB_THEME } from "../../../component/ThemeManager/constants";
import { ReactComponent as AttachIcon } from "../../../assets/logos/attach-file-icon.svg";
import { ReactComponent as BBLogo } from "../../../assets/logos/beatBreadLogo.svg";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import styles from "./AcceptOffer.module.scss";
import {
  BOOLEAN_RADIO,
  ENTITY_OPTIONS,
  FORM_STEPS,
  ONBOARD_ARTIST_STEPS,
  ONBOARD_REPRESENTATIVE_STEPS,
  ONBOARDING_INITIAL_DATA,
  TRACK_OWNER_OPTIONS,
} from "./constants";
import FormField from "../../../component/FormField/FormField";
import {
  API_URL,
  ARTIST_API,
  ONBOARDING_DETAILS,
  USER_API,
} from "../constants";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import request from "../../../utils/request";

const OnboardForm = (props) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [currentFormStep, setCurrentFormStep] = useState(FORM_STEPS.STEP1);

  const publicityPhotoRef = useRef();
  const distributionAgreementRef = useRef();

  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    if (get(props, "onboardData.entityType") === ENTITY_OPTIONS[0].value) {
      if ((props, "onboardData.artistName")) {
        setCurrentFormStep(FORM_STEPS.STEP3);
      }
    } else if (
      get(props, "onboardData.entityType") === ENTITY_OPTIONS[1].value
    ) {
      if (get(props, "onboardData.artistName")) {
        setCurrentFormStep(FORM_STEPS.STEP4);
      } else if (get(props, "onboardData.representativeEmail")) {
        setCurrentFormStep(FORM_STEPS.STEP3);
      }
    }
  }, [get(props, "onboardData")]);

  const formValidationSchema = () =>
    Yup.object().shape({
      entityType: Yup.string().required().label(`"I am"`),
      representativeName: Yup.string().when("entityType", {
        is: (entityType) => entityType === ENTITY_OPTIONS[1].value,
        then: Yup.string()
          .required()
          .matches(ALPHA_WITH_APOSTROPHE_HYPHEN, "Please enter valid name")
          .label("Name of Artist Representative"),
      }),
      representativeEmail: Yup.string().when("entityType", {
        is: (entityType) => entityType === ENTITY_OPTIONS[1].value,
        then: Yup.string()
          .required()
          .matches(EMAIL_REGEX, "Please enter valid email only")
          .label("Artist Representative Email Address"),
      }),
      type: Yup.string().required().label("This"),
      companyIncorporated: Yup.string()
        .label(`This`)
        .when(["type", "entityType"], {
          is: (type) => type === TRACK_OWNER_OPTIONS[1].value,
          then: Yup.string().required().label("This"),
        }),
      rightHolderName: Yup.string()
        .label(`This`)
        .when(["type", "entityType"], {
          is: (type) => type === TRACK_OWNER_OPTIONS[1].value,
          then: Yup.string().required().label("This"),
        }),
      artistName: Yup.string()
        .required()
        .matches(ALPHA_WITH_APOSTROPHE_HYPHEN_FULLNAME, "Please enter a first and last name")
        .label("Name"),
      artistEmail: Yup.string()
        .required()
        .matches(EMAIL_REGEX, "Please enter valid email only")
        .label("Email address")
        .when("entityType", {
          is: (entityType) => entityType === ENTITY_OPTIONS[1].value,
          then: Yup.string()
            .required()
            .test(
              "unique",
              "Email address must be unique. Please enter the artist's email.",
              function (val) {
                return val !== get(this, "parent.representativeEmail");
              },
            ),
        }),
      phone: Yup.string()
        .required()
        .matches(PHONE_REGEX, "Please enter valid phone")
        .label("Phone number"),
      isCurrentAdvanceWithOtherPartner: Yup.string()
        .nullable()
        .required()
        .label(`This`),
      distributionAgreement: Yup.mixed().nullable().label("This"),
      publicityPhoto: Yup.mixed()
        .nullable()
        .label("Publicity Photo")
    });

  const goBack = () => {
    setCurrentFormStep((prevStep) => `step${+prevStep.charAt(4) - 1}`);
  };

  const handleUploadClick = (ref) => () => {
    invoke(ref.current, "click");
  };

  const buttonContainerRender = (formProperties) => {
    const formSteps =
      get(formProperties, "values.entityType") === ENTITY_OPTIONS[0].value
        ? Object.keys(ONBOARD_ARTIST_STEPS)
        : Object.keys(ONBOARD_REPRESENTATIVE_STEPS);
    return (
      <div className={styles.btnContainer}>
        <div className={styles.stepMobileContainer}>
          {currentFormStep !== FORM_STEPS.STEP1 && (
            <div className={styles.stepDotsContainer}>
              {formSteps.map(
                (step, i) =>
                  i !== 0 && (
                    <span
                      key={`step-indicator-${i}`}
                      className={`${
                        currentFormStep === step ? styles.active : ""
                      }`}
                    ></span>
                  ),
              )}
            </div>
          )}
          {[FORM_STEPS.STEP1, FORM_STEPS.STEP2].indexOf(currentFormStep) ===
            -1 && (
            <button
              className={styles.secondary}
              onClick={() => goBack(formProperties)}
              data-testid="backButton"
            >
              Back
            </button>
          )}
        </div>
        <div className={styles.nextBackContainer}>
          {[FORM_STEPS.STEP1, FORM_STEPS.STEP2].indexOf(currentFormStep) ===
            -1 && (
            <button
              className={styles.secondary}
              onClick={() => goBack(formProperties)}
              data-testid="backButton"
            >
              Back
            </button>
          )}
          <button
            type="button"
            onClick={() => validateCurrentStep(formProperties)}
            disabled={btnLoading}
            data-testid="nextButton"
          >
            {btnLoading && (
              <span className={`spinner-border spinner-border-sm`} />
            )}{" "}
            Next
          </button>
        </div>
      </div>
    );
  };

  const proceedToNextStep = (formProperties) => {
    if (
      (get(formProperties, "values.entityType") === ENTITY_OPTIONS[0].value &&
        currentFormStep === FORM_STEPS.STEP3) ||
      (get(formProperties, "values.entityType") === ENTITY_OPTIONS[1].value &&
        currentFormStep === FORM_STEPS.STEP4)
    ) {
      setCurrentFormStep(FORM_STEPS.STEP_ACK);
      return false;
    }
    setCurrentFormStep((prevStep) => `step${+prevStep.charAt(4) + 1}`);
  };

  const handleSaveData = (formProperties) => {
    setBtnLoading(true);
    let method = "POST";
    let payload = {};
    if (get(formProperties, "values.entityType") === ENTITY_OPTIONS[0].value) {
      switch (currentFormStep) {
        case FORM_STEPS.STEP2:
          Object.keys(get(ONBOARD_ARTIST_STEPS, `${FORM_STEPS.STEP2}`, {})).map(
            (key) => set(payload, key, get(formProperties, `values.${key}`)),
          );
          set(
            payload,
            "phone",
            invoke(
              payload,
              "phone.replace",
              get(payload, "countryCode", ""),
              "",
            ),
          );
          set(payload, "entityType", get(formProperties, "values.entityType"));
          set(
            payload,
            "isCurrentAdvanceWithOtherPartner",
            !!+get(payload, "isCurrentAdvanceWithOtherPartner"),
          );
          set(payload, "step", 1);
          break;
        default:
          method = "PUT";
          payload = new FormData();
          if (get(formProperties, "values.distributionAgreement")) {
            payload.append(
              "distributionAgreement",
              get(formProperties, "values.distributionAgreement"),
              get(formProperties, "values.distributionAgreement.name"),
            );
          }
          if (get(formProperties, "values.publicityPhoto")) {
            payload.append(
              "publicityPhoto",
              get(formProperties, "values.publicityPhoto"),
              get(formProperties, "values.publicityPhoto.name"),
            );
          }
          break;
      }
    } else {
      switch (currentFormStep) {
        case FORM_STEPS.STEP2:
          Object.keys(
            get(ONBOARD_REPRESENTATIVE_STEPS, `${FORM_STEPS.STEP2}`, {}),
          ).map((key) =>
            set(payload, key, get(formProperties, `values.${key}`)),
          );
          set(payload, "entityType", get(formProperties, "values.entityType"));
          set(payload, "step", 1);
          break;
        case FORM_STEPS.STEP3:
          Object.keys(
            get(ONBOARD_REPRESENTATIVE_STEPS, `${FORM_STEPS.STEP3}`, {}),
          ).map((key) =>
            set(payload, key, get(formProperties, `values.${key}`)),
          );
          set(payload, "entityType", get(formProperties, "values.entityType"));
          set(
            payload,
            "phone",
            invoke(
              payload,
              "phone.replace",
              get(payload, "countryCode", ""),
              "",
            ),
          );
          set(
            payload,
            "isCurrentAdvanceWithOtherPartner",
            !!+get(payload, "isCurrentAdvanceWithOtherPartner"),
          );
          set(payload, "step", 2);
          break;
        default:
          method = "PUT";
          payload = new FormData();
          if (get(formProperties, "values.distributionAgreement")) {
            payload.append(
              "distributionAgreement",
              get(formProperties, "values.distributionAgreement"),
              get(formProperties, "values.distributionAgreement.name"),
            );
          }
          if (get(formProperties, "values.publicityPhoto")) {
            payload.append(
              "publicityPhoto",
              get(formProperties, "values.publicityPhoto"),
              get(formProperties, "values.publicityPhoto.name"),
            );
          }
          break;
      }
    }
    const data = {
      method,
      body: payload,
      headers: method === "PUT" && { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        setBtnLoading(false);
        if (get(res, "status")) {
          proceedToNextStep(formProperties);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        setBtnLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  const validateCurrentStep = async (formProperties) => {
    const errors = await invoke(formProperties, "validateForm");
    switch (currentFormStep) {
      case FORM_STEPS.STEP1:
        if (get(errors, "entityType")) {
          invoke(formProperties, "setFieldTouched", "entityType");
          return false;
        }
        if (
          get(formProperties, "values.entityType") === ENTITY_OPTIONS[1].value
        ) {
          invoke(
            formProperties,
            "setFieldValue",
            "representativeEmail",
            get(formProperties, "values.artistEmail"),
          );
          invoke(formProperties, "setFieldValue", "artistEmail", "");
        }
        proceedToNextStep(formProperties);
        break;
      default:
        const onboardObject =
          get(formProperties, "values.entityType") === ENTITY_OPTIONS[0].value
            ? ONBOARD_ARTIST_STEPS
            : ONBOARD_REPRESENTATIVE_STEPS;
        const err = Object.keys(
          get(onboardObject, `${currentFormStep}`, {}),
        ).filter((key) => get(errors, key));
        if (get(err, "length")) {
          Object.keys(get(onboardObject, `${currentFormStep}`, {})).map((key) =>
            invoke(formProperties, "setFieldTouched", key),
          );
          return false;
        }
        handleSaveData(formProperties);
        break;
    }
  };

  const formHeaderRender = () => (
    <h2 className={styles.formTitle}>
      {get(themeCtx, "slugName") === DEFAULT_BB_THEME.slugName && (
        <div className={styles.logoContainer}>
          <BBLogo />
        </div>
      )}
      {currentFormStep !== FORM_STEPS.STEP_ACK ? (
        <p>
          Final <span>Onboarding</span>
        </p>
      ) : (
        <p>
          Last <span>Step</span>
        </p>
      )}
    </h2>
  );

  const artistEntityForm = (formProperties) => {
    const isArtist =
      get(formProperties, "values.entityType") === ENTITY_OPTIONS[0].value;
    return (
      <>
        <div className={`form-group mb-0 mt-3 ${styles.sentenceCase}`}>
          <FormField
            as="select"
            name="type"
            options={TRACK_OWNER_OPTIONS}
            label="Does an individual or company own the rights to these tracks?"
          />
        </div>
        {get(formProperties, "values.type") === TRACK_OWNER_OPTIONS[1].value ? (
          <>
            <div className={`form-group mb-0 ${styles.sentenceCase}`}>
              <FormField
                name="companyIncorporated"
                placeholder="Delaware"
                type="text"
                label="Where is this company incorporated?"
                containerClass={styles.helperContainer}
                renderHelperLabel={
                  <p className={styles.helperLabel}>
                    If incorporated in the U.S enter the state, otherwise enter
                    the country
                  </p>
                }
              />
            </div>
            <div className={`form-group mb-0`}>
              <FormField
                name="rightHolderName"
                placeholder="Enter Name"
                type="text"
                label="Company Name"
                containerClass={styles.helperContainer}
                renderHelperLabel={
                  <p className={styles.helperLabel}>
                    This is the entity that owns the included recordings
                  </p>
                }
              />
            </div>
            <div className={`form-group mb-0`}>
              <FormField
                name="artistName"
                placeholder="Enter Full Name"
                type="text"
                label="Authorized Individual"
                containerClass={styles.helperContainer}
                renderHelperLabel={
                  <p className={styles.helperLabel}>
                    This is the legal name of an authorized individual of the
                    entity. If the artist owns the company it would be the
                    artists legal name.
                  </p>
                }
              />
            </div>
          </>
        ) : (
          <div className={`form-group mb-0`}>
            <FormField
              name="artistName"
              placeholder="Enter Full Name"
              type="text"
              label="Authorized Individual"
              containerClass={styles.helperContainer}
              renderHelperLabel={
                <p className={styles.helperLabel}>
                  This is the legal name of the artist or the individual who owns the tracks.
                </p>
              }
            />
          </div>
        )}
        <div className={`form-group mb-2`}>
          <FormField
            name="artistEmail"
            placeholder="artist@gmail.com"
            type="text"
            label="Authorized Individual's Email"
            containerClass={styles.helperContainer}
            renderHelperLabel={
              <p className={styles.helperLabel}>
                {isArtist
                  ? "This is the email you will need to complete onboarding"
                  : "This is the email that the artist will need access to complete onboarding"}
              </p>
            }
          />
        </div>
        <div className={`form-group mb-2`}>
          <FormField
            name="phone"
            placeholder="Enter Number"
            as="phone"
            label={"Phone Number"}
            containerClass={styles.helperContainer}
            renderHelperLabel={
              <p className={styles.helperLabel}>
                Please list the phone number of the person/entity whose bank
                information is being submitted on this form
              </p>
            }
          />
        </div>
        <div className={`form-group mb-0 ${styles.sentenceCase}`}>
          <FormField
            as="select"
            name="isCurrentAdvanceWithOtherPartner"
            options={BOOLEAN_RADIO}
            label="Is there a current advance on these tracks with any other partner?"
          />
        </div>
      </>
    );
  };

  const uploadFormRender = (formProperties) => (
    <>
      {formHeaderRender()}
      <p className={styles.contentTextTitle}>Documentation</p>
      <div className={styles.fileUploadContainer}>
        <label className={styles.labelTitle}>
          <span>Distribution Agreement</span>
          <p>
            If you have one, please upload a copy of your current distribution
            agreement.
          </p>
        </label>
        <div className={styles.w8Container}>
          <div
            className={`${styles.fileUpload} ${styles.publicity}`}
            onClick={handleUploadClick(distributionAgreementRef)}
          >
            <input
              type="file"
              name="distributionAgreement"
              ref={distributionAgreementRef}
              onChange={(e) => {
                formProperties.setFieldValue(
                  "distributionAgreement",
                  e.target.files[0],
                );
              }}
            />
            <AttachIcon />
            <span>Attach File</span>
          </div>
          {get(formProperties, "values.distributionAgreement.name") && (
            <div className={`${styles.filePreview} ${styles.publicity}`}>
              <AttachIcon />
              {get(formProperties, "values.distributionAgreement.name")}
            </div>
          )}
          <div
            className={`invalid-feedback d-flex position-absolute w-auto ${styles.errorClass}`}
          >
            <ErrorMessage name="distributionAgreement" />
          </div>
        </div>
      </div>
      <div className={styles.fileUploadContainer}>
        <label className={styles.labelTitle}>
          <span>Publicity Photo</span>
          <p>
            To be used for{" "}
            {get(themeCtx, "slugName") === "app" ? "beatBread" : "chordCash"}{" "}
            marketing (.jpg, .jpeg, .png, .gif, .ico only)
          </p>
        </label>
        <div className={styles.w8Container}>
          <div
            className={`${styles.fileUpload} ${styles.publicity}`}
            onClick={handleUploadClick(publicityPhotoRef)}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              name="publicityPhoto"
              ref={publicityPhotoRef}
              onChange={(e) => {
                formProperties.setFieldValue(
                  "publicityPhoto",
                  e.target.files[0],
                );
              }}
            />
            <AttachIcon />
            <span>Attach File</span>
          </div>
          {get(formProperties, "values.publicityPhoto.name") && (
            <div className={`${styles.filePreview} ${styles.publicity}`}>
              <AttachIcon />
              {get(formProperties, "values.publicityPhoto.name")}
            </div>
          )}
          <div
            className={`invalid-feedback d-flex position-absolute w-auto ${styles.errorClass}`}
          >
            <ErrorMessage name="publicityPhoto" />
          </div>
        </div>
      </div>
      {buttonContainerRender(formProperties)}
    </>
  );

  const formStepRender = (formProperties) => {
    switch (currentFormStep) {
      case FORM_STEPS.STEP1:
        return (
          <>
            {formHeaderRender()}
            <p className={styles.contentText}>
              We just need a few more items to finalize your advance!Please
              identify which of the following you are, and we will take you
              through the appropriate onboarding process.
            </p>
            <label className={styles.labelTitle} htmlFor="entityType">
              I am...
            </label>
            <FormField
              as="radio"
              name="entityType"
              radioValueList={ENTITY_OPTIONS}
            />
            {buttonContainerRender(formProperties)}
          </>
        );
      case FORM_STEPS.STEP2:
        if (
          get(formProperties, "values.entityType", "") ===
          ENTITY_OPTIONS[0].value
        ) {
          return (
            <>
              {formHeaderRender()}
              {artistEntityForm(formProperties)}
              {buttonContainerRender(formProperties)}
            </>
          );
        }
        return (
          <>
            {formHeaderRender()}
            <div className={`form-group mb-0 mt-3`}>
              <FormField
                name="representativeName"
                placeholder="Enter Contact Name"
                type="text"
                label="Name of Artist Representative"
              />
            </div>
            <div className={`form-group mb-2`}>
              <FormField
                name="representativeEmail"
                placeholder="Enter Email Address"
                type="text"
                label="Artist Representative Email Address"
              />
            </div>
            {buttonContainerRender(formProperties)}
          </>
        );
      case FORM_STEPS.STEP3:
        if (
          get(formProperties, "values.entityType", "") ===
          ENTITY_OPTIONS[0].value
        ) {
          return uploadFormRender(formProperties);
        }
        return (
          <>
            {formHeaderRender()}
            {artistEntityForm(formProperties)}
            {buttonContainerRender(formProperties)}
          </>
        );
      case FORM_STEPS.STEP4:
        return uploadFormRender(formProperties);
      default:
        return (
          <>
            {formHeaderRender()}
            {get(formProperties, "values.entityType", "") ===
            ENTITY_OPTIONS[0].value ? (
              <>
                <p className="mt-2">
                  We have sent an email to{" "}
                  <span className="textBold">
                    {get(formProperties, "values.artistEmail")}
                  </span>{" "}
                  from no-reply@chordcash.com.
                </p>
                <p>
                  Click on the link in that email to enter our chordCash portal
                  to securely complete your onboarding by providing banking details
                  and completing ID verification.
                </p>
              </>
            ) : (
              <>
                <p className="mt-2">
                  We have sent{" "}
                  <span className="textBold">
                    {get(formProperties, "values.artistName")}
                  </span>{" "}
                  an email at{" "}
                  <span className="textBold">
                    {get(formProperties, "values.artistEmail")}
                  </span>
                  . The email is from no-reply@chordcash.com.
                </p>
                <p>
                  They will need to click on the link in the email to enter our chordCash
                  portal to securely complete their onboarding by providing banking details and
                  completing ID verification.
                </p>
              </>
            )}
          </>
        );
    }
  };

  return (
    <div className={styles.formContainer}>
      <button className={styles.closeBtn} onClick={get(props, "handleClose")}>
        &times;
      </button>
      <Formik
        initialValues={{
          ...ONBOARDING_INITIAL_DATA,
          ...get(props, "onboardData", {}),
        }}
        enableReinitialize
        validationSchema={formValidationSchema()}
        data-testid="formRender"
      >
        {({
          values,
          validateForm,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          errors,
        }) => (
          <Form>
            {formStepRender({
              values,
              errors,
              validateForm,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            })}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OnboardForm;
