import React, { Component } from "react";
import styles from "./Header.module.scss";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Image from "../Image";
import { IMAGE_TYPE } from "../Image/constants";
import { get } from "lodash";
import { CHANGE_PASS, FORGOT_PASS, LOGIN, PRIVACY } from "../../routes/pages/constants";
import { AuthSideBar } from "../LoginSideBar/constants";

class Header extends Component {
  constructor (props) {
    super(props);
  }

  handleImageClick = () => {
    const urlArr = get(this.context, "whitelistUrl", []);
    const URL = urlArr[0];
    if (typeof URL !== "undefined") {
      window.location.href = URL;
    }
  }

  getHeaderTitle = () => {
    if (get(this.props, 'history.location.pathname') === LOGIN) {
      return AuthSideBar.LOGIN;
    } else if (get(this.props, 'history.location.pathname') === FORGOT_PASS) {
      return AuthSideBar.FORGOTPASS;
    } else if (get(this.props, 'history.location.pathname').includes(CHANGE_PASS)) {
      return AuthSideBar.CHANGEPASS;
    } else if (get(this.props, 'history.location.pathname') === PRIVACY) {
      return AuthSideBar.PRIVACYPOLICY;
    } else {
      return "";
    }
  }

  render () {
    return (
      <div className={styles.headerComponent}>
        <div className={styles.container}>
          <div
            onClick={this.handleImageClick}
            className={styles.LogoTitle}
            data-testid="logoClick"
          >
            <Image
              src={get(this.context, "appLogo")}
              alt="logo"
              className={styles.bBLogo}
              imageType={IMAGE_TYPE.LOGO}
            />
          </div>
          <div className={styles.headerTitle}>
            {this.getHeaderTitle()}
          </div>
        </div>
      </div>
    );
  }
}

Header.contextType = ThemeContext;
export default Header;
