import React, { Component } from "react";
import { get } from "lodash";
import StorageService from "../../utils/StorageService";
import SplashLoader from "../Loader/SplashLoader";
import themeColors, { DEFAULT_BB_THEME } from "./constants";
import {
  API_URL,
  ADMIN_WHITELABEL_API,
  INSTANCE,
  MAINTENANCE_PAGE,
  ADMIN_LOGIN,
  ACK_ERROR,
  ACK_HIGH,
  ACK_LOW,
  ACK_NEW,
  ACK_REDIRECT,
  ACK_IN_RANGE_1,
  ACK_IN_RANGE_2,
  ACK_IN_RANGE_3,
} from "../../routes/pages/constants";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../routes/pages/helper";
import { getConditionalTheme, getPartnerName, hexToRgb } from "./helper";

const ThemeContext = React.createContext(DEFAULT_BB_THEME);

class ThemeManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashScreen: false,
      theme: DEFAULT_BB_THEME,
    };
  }

  componentDidMount() {
    this.getSourceInstance();
    this.setFavIcon(true);
  }

  setFavIcon = (isFirstTime) => {
    const links = document.querySelectorAll("link[rel~='icon']");
    const socialLink = document.querySelectorAll(
      "link[rel~='apple-touch-icon']",
    )[0];
    if (get(this.state.theme, "favicon")) {
      links[0].href = get(this.state.theme, "favicon");
      links[1].href = get(this.state.theme, "favicon");
      socialLink.href = get(this.state.theme, "favicon");
      return true;
    }
    if (getPartnerName() === "chordCash") {
      links[0].href = isFirstTime
        ? "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        : "/cc-favicon.png";
      links[1].href = isFirstTime
        ? "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        : "/cc-favicon-192x192.png";
    }
  };

  checkExistingTheme = () => {
    const theme = StorageService.get("siteTheme", { localStorage: true });
    this.setState({ theme }, this.setFavIcon);
    for (const key in themeColors) {
      if (typeof themeColors[key] === "object") {
        for (const innerKey in themeColors[key]) {
          document.documentElement.style.setProperty(
            themeColors[key][innerKey],
            theme[key][innerKey],
          );
          document.documentElement.style.setProperty(
            `${themeColors[key][innerKey]}-rgb`,
            hexToRgb(theme[key][innerKey]),
          );
        }
      } else {
        document.documentElement.style.setProperty(
          themeColors[key],
          theme[key],
        );
        document.documentElement.style.setProperty(
          `${themeColors[key]}-rgb`,
          hexToRgb(theme[key]),
        );
      }
    }
  };

  isAppInMaintenance = (themeData) =>
    get(themeData, "maintenanceMode.isUnderMaintenance") &&
    get(themeData, "maintenanceMode.allApps.status");

  setNewTheme = (themeData) => {
    StorageService.set("siteTheme", themeData, {
      hash: true,
      stringify: true,
      localStorage: true,
    });
    const routes = [
      ADMIN_LOGIN,
      ACK_ERROR,
      ACK_HIGH,
      ACK_LOW,
      ACK_NEW,
      ACK_REDIRECT,
      ACK_IN_RANGE_1,
      ACK_IN_RANGE_2,
      ACK_IN_RANGE_3,
      MAINTENANCE_PAGE,
    ];
    if (
      this.isAppInMaintenance(themeData) &&
      routes.indexOf(window.location.pathname) === -1 &&
      !StorageService.get("customToken") &&
      !StorageService.get("isAdminInMaintenance")
    ) {
      window.location.replace(MAINTENANCE_PAGE);
    }
    this.checkExistingTheme();
  };

  getSourceInstance = () => {
    this.setState({ splashScreen: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}${INSTANCE}`;

    request(requestUrl, data)
      .then((res) => {
        this.setState({ splashScreen: false });
        if (res.status) {
          this.setState({ theme: res.data });
          this.setNewTheme(res.data);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({
          splashScreen: false,
          theme: {
            ...DEFAULT_BB_THEME,
            ...getConditionalTheme(),
          },
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  render() {
    return (
      <>
        <SplashLoader loading={this.state.splashScreen} />
        <ThemeContext.Provider value={this.state.theme}>
          {this.props.children}
        </ThemeContext.Provider>
      </>
    );
  }
}

export default ThemeManager;
export { ThemeContext };
