import { get } from "lodash";
import { LOGIN } from "../../constants";

export function checkUserData() {
  const userData = get(this.props, "location.state.data");
  this.setState(
    {
      user_id: userData && userData.userId,
    },
    () => {
      !this.state.user_id && this.props.history.push(LOGIN);
    }
  );
}
