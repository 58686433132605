import StorageService from "../../../../utils/StorageService";
import {
  STATUS_BIG,
  STATUS_SMALL,
  STATUS_IN_RANGE_1,
  STATUS_IN_RANGE_2,
  STATUS_IN_RANGE_3,
  STATUS_IN_RANGE_4,
  STATUS_ERR,
  STATUS_FAILED,
  STATUS_NEW,
} from "../../Dashboard/constants";
import {
  ACK_HIGH,
  ACK_LOW,
  ACK_IN_RANGE_1,
  ACK_IN_RANGE_2,
  ACK_IN_RANGE_3,
  ACK_ERROR,
  ACK_NEW,
  ACK_IN_RANGE_4,
} from "../../constants";

const AckRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  StorageService.set(
    "ackArtistDetails",
    {
      name: params.get("name"),
      artistId: params.get("artistId"),
      userId: params.get("_id"),
      idUser: params.get("userId"),
      code: params.get("code"),
      userEmail: params.get("userEmail"),
      status: params.get("status"),
      isPartner: params.get("isPartner"),
    },
    {
      stringify: true,
      localStorage: true,
    },
  );
  StorageService.set("ackArtistImg", params.get("profile"));
  switch (params.get("status")) {
    case STATUS_IN_RANGE_1:
      window.location.href = ACK_IN_RANGE_1;
      break;
    case STATUS_IN_RANGE_2:
      window.location.href = ACK_IN_RANGE_2;
      break;
    case STATUS_IN_RANGE_3:
      window.location.href = ACK_IN_RANGE_3;
      break;
    case STATUS_IN_RANGE_4:
      window.location.href = ACK_IN_RANGE_4;
      break;
    case STATUS_BIG:
      window.location.href = ACK_HIGH;
      break;
    case STATUS_SMALL:
      window.location.href = ACK_LOW;
      break;
    case STATUS_NEW:
      window.location.href = ACK_NEW;
      break;
    case STATUS_ERR:
    case STATUS_FAILED:
    default:
      window.location.href = ACK_ERROR;
      break;
  }
};

export default AckRedirect;
