import React, { Component } from "react";
import styles from "./Dropzone.module.scss";
import { ReactComponent as DropFileLogo } from "../../assets/logos/drop-file-logo.svg";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { highlight: false };
    this.fileInputRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  };

  fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  onFilesAdded = (evt) => {
    if (this.props.disabled) return;
    const files = evt.target.files;
    const array = this.fileListToArray(files);
    this.props.onFilesAdded(array);
    this.fileInputRef.current.value = "";
  };

  onDragOver = (evt) => {
    evt.preventDefault();
    if (this.props.disabled) return;

    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (event) => {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;

    const array = this.fileListToArray(files);
    this.props.onFilesAdded(array);

    this.setState({ highlight: false });
  };

  render() {
    return (
      <div
        className={`${styles.dropzone} ${
          this.props.disabled ? styles.cursorDefault : styles.cursorPointer
        } ${this.state.highlight ? styles.dropHighlight : ""}`}
        onClick={this.openFileDialog}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        title={this.props.dummyArtist ? "Sign Up to Upload" : ""}
      >
        <DropFileLogo className={styles.icon} />
        <input
          ref={this.fileInputRef}
          className={styles.fileInput}
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <span>
          <span className={styles.dragText}>
            {this.props.label || "Drag and drop your reports or"}
          </span>
          <br />
          <span className={styles.highlight}>Browse your files</span>
        </span>
        {this.props.disabled && (
          <div
            className={`${styles.loadingOverlay} ${
              this.props.dummyArtist ? styles.dummy : ""
            }`}
            title={this.props.dummyArtist ? "Sign Up to Upload" : ""}
          />
        )}
      </div>
    );
  }
}

export default Dropzone;
