import React, { useEffect, useState } from "react";
import { get, invoke } from "lodash";
import htmlParser from "html-react-parser";
import ReactModal from "react-modal";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import styles from "./PartnerComparisonTool.module.scss";
import Image from "../../../component/Image";
import { getModifiedValue, numberWithCommas } from "./helper";
import { CASHFLOW, DEFAULT_PARTNER } from "./constants";
import { useMediaQuery } from "../../../utils/Helper";

const PartnerComparisonTool = (props) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [partners, setPartners] = useState(get(props, "availablePartners", []));
  const [pinnedPartner, setPinnedPartner] = useState(
    get(props, "availablePartners[0]"),
  );

  const currentDataPath = `${get(props, "currentData.works")}.${get(
    props,
    "currentData.term",
  )}.${get(props, "currentData.flow_through")}.${get(
    props,
    "currentData.future",
  )}`;

  const handleSort = (arr, pinName) => {
    const sorted = arr
      .filter((p) => p !== pinName)
      .sort((a, b) => {
        const advanceA = getModifiedValue(
          get(props, `advanceData.${a}.${currentDataPath}`, {}),
          get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
        ).total_advance;
        const advanceB = getModifiedValue(
          get(props, `advanceData.${b}.${currentDataPath}`, {}),
          get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
        ).total_advance;
        return advanceB - advanceA;
      });
    return [pinName, ...sorted];
  };

  useEffect(() => {
    const sortedPartners = handleSort(
      get(props, "availablePartners", []),
      pinnedPartner,
    );
    setPartners(sortedPartners);
  }, [pinnedPartner, get(props, "availablePartners")]);
  const rowHeader = [
    {
      title: (
        <div className={styles.columnHeader}>
          <span>{get(props, `currentData.term`)} year term</span>
          <span>Total Advance</span>
        </div>
      ),
      render: (p, partnerName) => (
        <div className={styles.firstRow}>
          <div className={styles.logoContainer}>
            {(get(p, "partner") || partnerName) !== DEFAULT_PARTNER && (
              <Image
                src={
                  get(p, "logo") ||
                  get(props, `partnersOptions.${partnerName}.logo`)
                }
              />
            )}
            <span>
              {(get(p, "partner") || partnerName) === DEFAULT_PARTNER
                ? "Current Distributor"
                : get(p, "partner") || partnerName}
            </span>
          </div>
          <span className={styles.total}>
            {!p
              ? "N/A"
              : `$
            ${numberWithCommas(
              getModifiedValue(
                p,
                get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
              ).total_advance,
            )}`}
          </span>
          <button
            className={styles.offerBtn}
            disabled={!p || get(p, "partner") === pinnedPartner}
            onClick={() => setPinnedPartner(get(p, "partner"))}
          >
            {get(p, "partner") === pinnedPartner ? "Pinned" : "Pin"}
          </button>
        </div>
      ),
    },
    {
      title: "Upfront Advance",
      render: (p) =>
        !p
          ? "N/A"
          : `$${numberWithCommas(
              getModifiedValue(
                p,
                get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
              ).catalog_advance,
            )}`,
    },
    {
      title: "New Release Advance",
      render: (p) =>
        getModifiedValue(
          p,
          get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
        ).nr_advance
          ? `$${numberWithCommas(
              getModifiedValue(
                p,
                get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
              ).nr_advance,
            )}`
          : "N/A",
    },
    {
      title: "Option Advance",
      render: (p) =>
        getModifiedValue(
          p,
          get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
        ).option_advance
          ? `$${numberWithCommas(
              getModifiedValue(
                p,
                get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
              ).option_advance,
            )}`
          : "N/A",
    },
    {
      title: "Recoupment Rate",
      render: (p) =>
        getModifiedValue(
          p,
          get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
        ).royalty
          ? `${
              getModifiedValue(
                p,
                get(props, "cashflow_option_selected", CASHFLOW.STANDARD),
              ).royalty
            }%`
          : "N/A",
    },
    {
      title: "Distribution Fee",
      render: (p) =>
        get(p, "partner") === DEFAULT_PARTNER ? "Current Fee" : get(p, "fees"),
    },
    {
      title: "Features",
      render: (p) =>
        get(p, "featuresHTML") ? (
          <button
            className={styles.linkBtn}
            onClick={() => {
              invoke(props, "toggleFeatureModal", get(p, "partner"));
            }}
          >
            View Features
          </button>
        ) : (
          "N/A"
        ),
    },
    {
      title: "",
      render: (p) => (
        <button
          className={styles.offerBtn}
          disabled={!p}
          onClick={() => {
            invoke(props, "setSelectedPartner", { value: get(p, "partner") });
            invoke(props, "togglePartnerCompareTool");
          }}
        >
          View Offers
        </button>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.titleContainer}>
          <h2>Compare beatBread Offers</h2>
          <p>
            Compare the features of the partner plans that are available below:
          </p>
        </div>
        <div className={styles.comparisonContent}>
          <TableContainer className={styles.tableContainerDesktop}>
            <Table
              style={{
                width: isMobile
                  ? `${
                      (100 / 3) *
                      (get(props, "availablePartners.length", 0) + 1)
                    }%`
                  : "100%",
              }}
            >
              <TableBody>
                {rowHeader.map((el, i) => (
                  <TableRow key={`table-row-${i}`}>
                    <TableCell>{el.title}</TableCell>
                    {partners.map((partner) => (
                      <TableCell
                        className={styles.header}
                        key={`row-${i}-${partner}`}
                      >
                        {rowHeader[i].render(
                          get(
                            props,
                            `advanceData.${partner}.${currentDataPath}`,
                          ),
                          partner,
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={styles.btnContainer}>
          <button onClick={get(props, "togglePartnerCompareTool")}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartnerComparisonTool;

export const FeatureDataModal = (props) => (
  <ReactModal
    isOpen={get(props, "isOpen")}
    shouldCloseOnOverlayClick
    shouldCloseOnEsc
    onRequestClose={get(props, "toggleFeatureModal")}
    className={styles.featureDataModal}
    overlayClassName={styles.modalOverlay}
  >
    <div className={styles.modalContent}>
      <span
        className={styles.closeBtn}
        onClick={get(props, "toggleFeatureModal")}
        data-testid="span-close"
      >
        &times;
      </span>
      <div className={styles.firstContainer}>
        {get(props, "featureData.partner")}'s Features and Services:
      </div>
      <div className={styles.secondContainer}>
        {htmlParser(get(props, "featureData.html", ""))}
      </div>
    </div>
  </ReactModal>
);
