import React, { useContext, useState } from "react";
import { get } from "lodash";
import {
  FACEBOOK_PLATFORM,
  FAILED_TO_FETCH,
  INSTAGRAM_PLATFORM,
  WIKIPEDIA_PLATFORM,
  YOUTUBE_PLATFORM,
  API_URL,
} from "./constants";
import request from "../../utils/request";
import { ErrorContext } from "../../component/ErrorPopup/ErrorPopup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export const GetErrorMessage = (props) => {
  const values = useContext(ErrorContext);
  const [err, setErr] = useState(props.err);
  const errMessage = "Internal Server Error";
  const [called, setCalled] = useState(false);

  React.useEffect(() => {
    async function checkJson() {
      const error =
        get(props.err, "response", "") && !called && !values.isOpen
          ? await props.err.response.json()
          : props.err;
      setErr(error);
    }
    checkJson();
  }, [called, props.err, values.isOpen]);

  if (get(err, "message") === errMessage && !called) {
    try {
      toast.dismiss();
      setCalled(true);
      values.setIsOpen();
    } catch (e) {
      console.log(e);
    }
    return <></>;
  }
  if (err.message && get(err, "message") !== errMessage) {
    toast.update(get(props.toastProps, "toastProps.toastId", ""), {
      className: "",
    });
  }
  if (get(err, "message") === "Failed to fetch") {
    return <>{FAILED_TO_FETCH}</>;
  }

  return <>{get(err, "message")}</>;
};

GetErrorMessage.contextTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

export const getErrorMessage = GetErrorMessage;

export const getSocialMediaPayload = (
  instagramUrl,
  facebookUrl,
  youtubeUrl,
  wikipediaUrl,
) => {
  const socialMedia = [];
  if (instagramUrl) {
    socialMedia.push({
      platform: INSTAGRAM_PLATFORM,
      url: instagramUrl,
    });
  }
  if (facebookUrl) {
    socialMedia.push({
      platform: FACEBOOK_PLATFORM,
      url: facebookUrl,
    });
  }
  if (youtubeUrl) {
    socialMedia.push({
      platform: YOUTUBE_PLATFORM,
      url: youtubeUrl,
    });
  }
  if (wikipediaUrl) {
    socialMedia.push({
      platform: WIKIPEDIA_PLATFORM,
      url: wikipediaUrl,
    });
  }
  return socialMedia;
};

export const forbiddenCheck = () => {
  const data = {
    method: "GET",
  };
  const requestURL = `${API_URL}`;
  request(requestURL, data).catch();
};

export const hubSpotTracking = () => {
  const _hsq = window._hsq || [];
  _hsq.push([
    "setPath",
    `${window.location.pathname}${window.location.search}${window.location.hash}`,
  ]);
  _hsq.push(["trackPageView"]);
};

export const isChordCashLite = () =>
  window.location.hostname.includes("funding");

export const YT_VIDEO_ID_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)|shorts?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;

export const YT_THUMBNAIL_URL =
  "https://img.youtube.com/vi/[VIDEO_ID]/sddefault.jpg";

export const YT_THUMBNAIL_SMALL =
  "https://img.youtube.com/vi/[VIDEO_ID]/mqdefault.jpg";
export const YT_EMBED_URL =
  "https://www.youtube-nocookie.com/embed/[VIDEO_ID]?rel=0&autoplay=1&modestbranding=1";

export const GetIframeVideo = (props) => (
  <iframe
    className={props.className}
    title={props.title}
    srcDoc={`<style>
      body, .full {
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      object-fit: cover;
      cursor: pointer;
      align-items:center;
      }
      </style>
      <div
      onClick="window.parent.postMessage('videoPlayed','*');
       window.location.replace('${YT_EMBED_URL.replace(
         "[VIDEO_ID]",
         props.url.match(YT_VIDEO_ID_REGEX).pop(),
       )}');"
      class='full'
      >
      <img
      src='${(props.small ? YT_THUMBNAIL_SMALL : YT_THUMBNAIL_URL).replace(
        "[VIDEO_ID]",
        props.url.match(YT_VIDEO_ID_REGEX).pop(),
      )}'
      class='full'
      loading='lazy'
      />
      <svg width="91" style='position: relative;' height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 45C0.5 20.1472 20.6472 0 45.5 0C70.3528 0 90.5 20.1472 90.5 45C90.5 69.8528 70.3528 90 45.5 90C20.6472 90 0.5 69.8528 0.5 45Z" fill="white"></path>
      <path d="M60.8131 43.2292L39.8113 32.1541C39.4032 31.9414 38.9302 31.8198 38.4422 31.8021C37.9542 31.7843 37.4692 31.8712 37.0382 32.0534C36.6072 32.2357 36.2462 32.5066 35.9931 32.8377C35.74 33.1687 35.6043 33.5477 35.6001 33.9348V56.0564C35.6006 56.4449 35.7342 56.826 35.9867 57.1591C36.2392 57.4921 36.6011 57.7646 37.0338 57.9474C37.4665 58.1303 37.9537 58.2166 38.4435 58.1971C38.9333 58.1777 39.4072 58.0532 39.8146 57.8371L60.8164 46.7619C61.1809 46.5669 61.4794 46.304 61.6858 45.9962C61.8922 45.6885 62.0001 45.3454 62.0001 44.9969C62.0001 44.6484 61.8922 44.3053 61.6858 43.9975C61.4794 43.6898 61.1809 43.4269 60.8164 43.2319L60.8131 43.2292Z" fill="#5849A6"></path>
      </svg>

      </div>

      `}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  />
);
