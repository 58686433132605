import React, { Component } from "react";
import { get } from "lodash";
import authStyles from "../Auth.module.scss";
import base from "base-64";
import Header from "../../../../component/Header";
import { API_URL, AUTH, LOGIN, FF_VALIDATE, HOME } from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import request from "../../../../utils/request";
import { GetErrorMessage } from "../../helper";
import {
  extractHostname,
  getSubDomain,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import AuthCookies from "../../../../utils/setCookies";
import StorageService from "../../../../utils/StorageService";
import AuthTokenService from "../../../../utils/AuthTokenService";

class FFValidateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.userVerification();
  }

  componentDidUpdate() {
    setTitle("Secure Verification", this.context);
  }

  userVerification = () => {
    const payload = {
      token: get(this.props, "match.params.key"),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FF_VALIDATE}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });

        if (!res.status) {
          this.setState({ error: get(res, "message") });
          toast.error(get(res, "message"));
          this.props.history.push(LOGIN);
          return;
        }
        AuthCookies.delete("userId");
        AuthCookies.delete("instaUser");
        AuthCookies.delete("ytUser");
        StorageService.delete("spotify_id");
        StorageService.delete("spotify_label");
        StorageService.delete("spotify_url");
        StorageService.delete("isAdminInMaintenance");
        this.redirectLogin(res.data);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(LOGIN);
      });
  };

  redirectLogin = (data) => {
    if (getSubDomain() === extractHostname(data.landingPageUrl)) {
      AuthTokenService.storeToken(data.artistAccessToken);
      this.props.history.push({ pathname: HOME, state: { fromLogin: true } });
      return true;
    }
    const redirectToken = base.encode(
      `?token=${data.artistAccessToken}&timestamp=${Date.now()}`,
    );
    window.location.replace(`${data.landingPageUrl}${LOGIN}/${redirectToken}`);
  };

  render() {
    return (
      <div className="theme-bg-dark">
        <Header />
        <div className={authStyles.verifyContent}></div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

FFValidateUser.contextType = ThemeContext;
export default FFValidateUser;
