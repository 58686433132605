import {
  CONTENT_TYPE,
  FIELD_NAMES,
  HELPER,
  MAX_CHARECTERS,
  NEWS_CATEGORY_OPTIONS,
  URL_REGEX,
} from "./constant";
import * as Yup from "yup";
import { get } from "lodash";

export const getLabel = (type) => {
  switch (type) {
    case CONTENT_TYPE[0].value:
      return HELPER.NEWS;
    case CONTENT_TYPE[1].value:
      return HELPER.BLOG;
    case CONTENT_TYPE[2].value:
      return HELPER.VIDEO;
    default:
      return HELPER.NEWS;
  }
};

export const getTitle = (type) => {
  switch (type) {
    case CONTENT_TYPE[0].value:
      return "About Post";
    case CONTENT_TYPE[1].value:
      return "Blog Info";
    case CONTENT_TYPE[2].value:
      return "Testimonial Info";
    default:
      return "About Post";
  }
};

export function checkBlogLength(value = "") {
  if (value && this) {
    if (value.trim().split(/\s+/).length < 26) {
      return true;
    }
  }
  return false;
}

export const validationSchema = Yup.object().shape({
  [FIELD_NAMES.CATEGORY.NAME]: Yup.object()
    .label(FIELD_NAMES.CATEGORY.LABEL)
    .nullable()
    .when("type", {
      is: (type) =>
        type !== CONTENT_TYPE[0].value && type !== CONTENT_TYPE[2].value,
      then: Yup.object().required(),
    }),
  [FIELD_NAMES.TAG.NAME]: Yup.array()
    .label(FIELD_NAMES.TAG.LABEL)
    .nullable()
    .when(["newscategory", "type"], {
      is: (newscategory, type) =>
        newscategory.value === NEWS_CATEGORY_OPTIONS[1] ||
        type === CONTENT_TYPE[1].value,
      then: Yup.array().required(),
    }),
  [FIELD_NAMES.TITLE.NAME]: Yup.string().label("Title").required(),
  [FIELD_NAMES.URL.NAME]: Yup.string()
    .label("URL")
    .nullable()
    .when(["newscategory", "type"], {
      is: (newscategory, type) =>
        newscategory.value !== NEWS_CATEGORY_OPTIONS[1] &&
        type !== CONTENT_TYPE[2].value,
      then: Yup.string().matches(URL_REGEX, "Invalid URL"),
    }),
  [FIELD_NAMES.BLOG_CONTENT.NAME]: Yup.string()
    .label(FIELD_NAMES.BLOG_CONTENT.LABEL)
    .when("type", {
      is: (type) =>
        type === CONTENT_TYPE[1].value || type === CONTENT_TYPE[2].value,
      then: Yup.string().required(),
    }),
  [FIELD_NAMES.PUBLICATION_DATE.NAME]: Yup.string()
    .label(FIELD_NAMES.PUBLICATION_DATE.LABEL)
    .required(),
  [FIELD_NAMES.AUTHOR.NAME]: Yup.string()
    .label(FIELD_NAMES.AUTHOR.LABEL)
    .when("type", {
      is: (type) => type !== CONTENT_TYPE[2].value,
      then: Yup.string().required(),
    }),
  [FIELD_NAMES.BRIEF.NAME]: Yup.string()
    .label("Brief Description")
    .required()
    .test(
      "check-length",
      "Brief Description should be less than 25 words",
      checkBlogLength,
    ),
  [FIELD_NAMES.GET_HELP.NAME]: Yup.string()
    .label("This")
    .when("newscategory", {
      is: (newscategory) => newscategory.value === NEWS_CATEGORY_OPTIONS[1],
      then: Yup.string()
        .required()
        .max(MAX_CHARECTERS, "value cannot exceed 100 characters."),
    }),
  [FIELD_NAMES.WHAT_CHANGE.NAME]: Yup.string()
    .label("This")
    .when("newscategory", {
      is: (newscategory) => newscategory.value === NEWS_CATEGORY_OPTIONS[1],
      then: Yup.string()
        .required()
        .max(MAX_CHARECTERS, "value cannot exceed 100 characters."),
    }),
  [FIELD_NAMES.WHEN.NAME]: Yup.string()
    .label("This")
    .when("newscategory", {
      is: (newscategory) => newscategory.value === NEWS_CATEGORY_OPTIONS[1],
      then: Yup.string()
        .required()
        .max(MAX_CHARECTERS, "value cannot exceed 100 characters."),
    }),
  [FIELD_NAMES.WHAT_IMPORTANT.NAME]: Yup.string()
    .label("This")
    .when("newscategory", {
      is: (newscategory) => newscategory.value === NEWS_CATEGORY_OPTIONS[1],
      then: Yup.string()
        .required()
        .max(MAX_CHARECTERS, "value cannot exceed 100 characters."),
    }),
  [FIELD_NAMES.WHAT_DO.NAME]: Yup.string()
    .label("This")
    .when("newscategory", {
      is: (newscategory) => newscategory.value === NEWS_CATEGORY_OPTIONS[1],
      then: Yup.string()
        .required()
        .max(MAX_CHARECTERS, "value cannot exceed 100 characters."),
    }),
  [FIELD_NAMES.LISTENER.NAME]: Yup.string()
    .label(FIELD_NAMES.LISTENER.LABEL)
    .when("type", {
      is: (type) => type === CONTENT_TYPE[2].value,
      then: Yup.string()
        .label(FIELD_NAMES.LISTENER.LABEL)
        .required()
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
});

export const getTags = (tagArr = []) => {
  const arr = [];
  tagArr.forEach((val) => {
    const tag =
      `${get(val, "value")}`.charAt(0).toUpperCase() +
      `${get(val, "value")}`.slice(1);
    arr.push(tag);
  });
  return arr;
};

export const getLabelAndValue = (strArr = []) => {
  const newArr = [];
  strArr.forEach((val) => {
    const newObj = { label: val, value: val };
    newArr.push(newObj);
  });
  return newArr;
};
