import React, { Component } from "react";
import ReactModal from "react-modal";
import { YT_VIDEO_ID_REGEX } from "../../routes/pages/Auth/constants";
import {
  BEATBREAD_MARKETPLACE_CALENDLY_LINK,
  CHORDCASH_CALENDLY_LINK,
  INSTRUCTIONAL_YT_VIDEO,
  YT_EMBED_URL,
  YT_THUMBNAIL_URL,
} from "../../routes/pages/constants";
import { PLAY_ARROW_ROUNDED } from "../MaterialIcons/constants";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import { getSubDomain } from "../ThemeManager/helper";
import styles from "./NeedHelpPopup.module.scss";

export class NeedHelpPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEmbedVideo: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ showEmbedVideo: false });
    }
  }
  setShowEmbedVideo = () => {
    this.setState({ showEmbedVideo: true });
  };
  videoContentRender = () => (
    <>
      {!this.state.showEmbedVideo ? (
        <div
          className={styles.videoThumbnail}
          onClick={this.setShowEmbedVideo}
          data-testid="div-image"
        >
          <img
            src={YT_THUMBNAIL_URL.replace(
              "[VIDEO_ID]",
              INSTRUCTIONAL_YT_VIDEO.match(YT_VIDEO_ID_REGEX).pop()
            )}
            alt="video thumbnail"
          />
          <span className={styles.playContainer}>
            <SvgIcons icon={PLAY_ARROW_ROUNDED} />
          </span>
        </div>
      ) : (
        <div className={styles.videoThumbnail}>
          <iframe
            src={YT_EMBED_URL.replace(
              "[VIDEO_ID]",
              INSTRUCTIONAL_YT_VIDEO.match(YT_VIDEO_ID_REGEX).pop()
            )}
            title="Instruction Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </div>
      )}
    </>
  );

  getCalendalyLink = () =>
    BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
      ? BEATBREAD_MARKETPLACE_CALENDLY_LINK
      : CHORDCASH_CALENDLY_LINK;

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.props.onRequestClose}
        className={styles.detailsPopupContainer}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.offerContainer}>
          <span
            className={styles.closeBtn}
            onClick={this.props.onRequestClose}
            data-testid="span-close"
          >
            &times;
          </span>
          <div className={styles.firstContainer}>
            <p className={styles.name}>Help center</p>
            <span>
              Have general questions about what comes next? Watch this short
              video
            </span>
            {this.videoContentRender()}
            <div className={styles.orElem}>or</div>
          </div>
          <div className={styles.secondContainer}>
            <span>Want to speak with beatBread team?</span>
            <a
              className={styles.requestCallBtn}
              href={this.getCalendalyLink()}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="a-call"
            >
              Request a call
            </a>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default NeedHelpPopup;
