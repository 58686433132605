import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./ConfirmOwnership.module.scss";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import * as Yup from "yup";
import {
  Avatar,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  ACTIVE_DISTRIBUTORS,
  API_URL,
  ARTIST_API,
  GENERATING_OFFERS,
  HOME,
  OWNERSHIP_TRACKS,
  USER_API,
} from "../constants";
import { cloneDeep, get, invoke, set } from "lodash";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import { ReactComponent as MusicNote } from "../../../assets/logos/leftNavigationMusicIcon.svg";
import { ReactComponent as PercentIcon } from "../../../assets/logos/IconPercent.svg";
import { ReactComponent as EditIcon } from "../../../assets/logos/edit-icon.svg";
import { ReactComponent as Warning } from "../../../assets/logos/warning.svg";
import { ReactComponent as AlertIcon } from "../../../assets/logos/Alert.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/logos/dropDownArrow.svg";
import moment from "moment";
import request from "../../../utils/request";
import FormField from "../../../component/FormField/FormField";
import Switch from "../AdminDashboard/Switch";
import { Formik } from "formik";
import ReactModal from "react-modal";
import Loader from "../../../component/Loader";
import ContactPopup from "../../../component/ContactPopup";
import { useInView } from "react-intersection-observer";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { setTitle } from "../../../component/ThemeManager/helper";

const CustomRow = ({
  form,
  track,
  i,
  isSelected,
  handleRowCheckboxChange,
  navigationData,
  distributorList,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.01,
  });
  return (
    <TableRow
      className={`${isSelected ? styles.activeRow : ""}`}
      innerRef={ref}
    >
      {!!inView && (
        <>
          <TableCell>
            <div className={styles.trackBasicContainer}>
              {!get(navigationData, "confirmTrackOwnershipStep") && (
                <Checkbox
                  color="primary"
                  checked={isSelected}
                  onChange={handleRowCheckboxChange(i)}
                  data-testid="select-one"
                />
              )}
              <Avatar src={get(track, "image_url")}>
                <MusicNote />
              </Avatar>
              <div className={styles.nameContainer}>
                <h4>{get(track, "name", "-")}</h4>
                <p>{get(track, "release_date")}</p>
              </div>
            </div>
          </TableCell>
          <TableCell>{get(track, "isrc", "-")}</TableCell>
          <TableCell>
            <div className={`form-group m-0 ${styles.share}`}>
              <FormField
                as="numeric"
                name={`data.${i}.ownership`}
                placeholder="-"
                icon={<PercentIcon />}
                isAllowed={(v) =>
                  (get(v, "floatValue") <= 100 && get(v, "floatValue") >= 0) ||
                  !get(v, "floatValue")
                }
                disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className={`form-group m-0 ${styles.distributor}`}>
              <FormField
                as="select"
                name={`data.${i}.distributor`}
                placeholder="Add Distributor"
                options={distributorList}
                components={{ DropdownIndicator: null }}
                classNamePrefix="dist"
                formatOptionLabel={(op) => (
                  <div className={styles.option}>
                    {get(op, "logoUrl") && (
                      <img
                        src={get(op, "logoUrl")}
                        alt={`${get(op, "label")}-logo`}
                      />
                    )}{" "}
                    <span>{get(op, "label")}</span>
                  </div>
                )}
                disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.include}>
              <Switch
                isOn={
                  !!get(form, `values.data.${i}.ownership`) &&
                  get(form, `values.data.${i}.isIncluded`)
                }
                handleToggle={(e) => {
                  invoke(
                    form,
                    "setFieldValue",
                    `data.${i}.isIncluded`,
                    get(e, "target.checked"),
                  );
                }}
                disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
              />
              {!get(form, `values.data.${i}.ownership`) && <Warning />}
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const CustomRowMobile = ({
  form,
  track,
  i,
  isSelected,
  handleRowCheckboxChange,
  navigationData,
  distributorList,
  collapseOpen,
  setCollapseOpen,
}) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.01 });
  return (
    <>
      <TableRow
        key={`${get(track, "isrc")}-${i}-mob`}
        className={`${isSelected ? styles.activeRow : ""}`}
        innerRef={ref}
      >
        {!!inView && (
          <>
            <TableCell>
              <div className={styles.trackBasicContainer}>
                {!get(navigationData, "confirmTrackOwnershipStep") && (
                  <Checkbox
                    color="primary"
                    checked={isSelected}
                    onChange={handleRowCheckboxChange(i)}
                  />
                )}
                <Avatar src={get(track, "image_url")}>
                  <MusicNote />
                </Avatar>
                <div className={styles.nameContainer}>
                  <h4>{get(track, "name", "-")}</h4>
                  <p>{get(track, "release_date")}</p>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.include}>
                {!get(form, `values.data.${i}.ownership`) && <Warning />}
                <IconButton
                  onClick={() => {
                    setCollapseOpen((p) => ({ ...p, [i]: !get(p, i) }));
                  }}
                  className={styles.iconBtn}
                >
                  <DropDownIcon
                    className={`${get(collapseOpen, i) ? styles.open : ""}`}
                  />
                </IconButton>
              </div>
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow
        key={`${get(track, "isrc")}-${i}-mob-collapse`}
        className={`${isSelected ? styles.activeRow : ""} ${
          styles.collapsedRow
        }`}
      >
        {!!inView && (
          <TableCell colSpan={2}>
            <Collapse in={!!get(collapseOpen, i)}>
              <Table className={styles.collapseContainer}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <label>ISRC</label>
                    </TableCell>
                    <TableCell>
                      {get(form, `values.data.${i}.isrc`, "-")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <label>Song Ownership</label>
                    </TableCell>
                    <TableCell>
                      <div className={`form-group m-0 ${styles.share}`}>
                        <FormField
                          as="numeric"
                          name={`data.${i}.ownership`}
                          placeholder="-"
                          icon={<PercentIcon />}
                          isAllowed={(v) =>
                            (get(v, "floatValue") <= 100 &&
                              get(v, "floatValue") >= 0) ||
                            !get(v, "floatValue")
                          }
                          disabled={
                            !!get(navigationData, "confirmTrackOwnershipStep")
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <label>Distributor</label>
                    </TableCell>
                    <TableCell>
                      <div className={`form-group m-0 ${styles.distributor}`}>
                        <FormField
                          as="select"
                          name={`data.${i}.distributor`}
                          placeholder="Add Distributor"
                          options={distributorList}
                          components={{ DropdownIndicator: null }}
                          classNamePrefix="dist"
                          formatOptionLabel={(op) => (
                            <div className={styles.option}>
                              {get(op, "logoUrl") && (
                                <img
                                  src={get(op, "logoUrl")}
                                  alt={`${get(op, "label")}-logo`}
                                />
                              )}{" "}
                              <span>{get(op, "label")}</span>
                            </div>
                          )}
                          disabled={
                            !!get(navigationData, "confirmTrackOwnershipStep")
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <label>Include in deal</label>
                    </TableCell>
                    <TableCell>
                      <div className={styles.include}>
                        <Switch
                          isOn={
                            !!get(form, `values.data.${i}.ownership`) &&
                            get(form, `values.data.${i}.isIncluded`)
                          }
                          handleToggle={(e) => {
                            invoke(
                              form,
                              "setFieldValue",
                              `data.${i}.isIncluded`,
                              get(e, "target.checked"),
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

const ConfirmOwnership = (props) => {
  const [tracks, setTracks] = useState([]);
  const [selectedTracks, setSelectedTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setFormOpen] = useState(false);
  const [isAckOpen, setAckOpen] = useState(false);
  const [isContactOpen, setContactOpen] = useState(false);
  const [navigationData, setNavigationData] = useState({});
  const [includedCount, setIncludedCount] = useState(0);
  const [distributorList, setDistributorList] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState({});
  const navigationRef = useRef();

  const themeCtx = useContext(ThemeContext);

  const getAllTracks = () => {
    setLoading(true);
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OWNERSHIP_TRACKS}`;
    request(requestUrl, data)
      .then((res) => {
        setLoading(false);
        if (get(res, "status")) {
          setTracks(
            invoke(res, "data.map", (track) => ({
              ...track,
              release_date: get(track, "release_date")
                ? moment(get(track, "release_date")).format("D MMM YYYY")
                : "-",
              isIncluded: true,
            })),
          );
          setSelectedTracks(Array(get(res, "data.length", [])).fill(false));
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  const getActiveDistributors = () => {
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVE_DISTRIBUTORS}`;
    request(requestUrl, data)
      .then((res) => {
        if (get(res, "status")) {
          const options = invoke(res, "data.map", (option) => ({
            ...option,
            label: get(option, "name"),
            value: get(option, "name"),
          }));
          setDistributorList([{ label: "Other", value: "Other" }, ...options]);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  useEffect(() => {
    setTitle("Confirm Ownership", themeCtx);
    getActiveDistributors();
  }, []);

  const handleAllCheckboxChange = (e) => {
    const newSelected = tracks.map(() => get(e, "target.checked"));
    setSelectedTracks(newSelected);
  };

  const renderTrackHeader = () => {
    const isAllSelected = selectedCount && selectedCount === tracks.length;
    const renderText = isAllSelected
      ? `Edit All`
      : `Edit ${selectedCount} Selected`;
    return (
      <div className={styles.trackHeaderCtn}>
        <span className={styles.trackTitle}>Track</span>
        <div className={styles.editCtn}>
          {!get(navigationData, "confirmTrackOwnershipStep") && (
            <Checkbox
              color="primary"
              indeterminate={isSelectAllIndeterminate}
              checked={selectedTracks.every((isSelected) => isSelected)}
              onChange={handleAllCheckboxChange}
              data-testid="select-all"
            />
          )}
          {!get(navigationData, "confirmTrackOwnershipStep") && (
            <span className={styles.checkTitle}>
              {isAllSelected ? "Deselect All" : "Select All"}
            </span>
          )}
          {selectedCount ? (
            <span className={styles.checkTitle}>
              {renderText} <EditIcon onClick={toggleFormModal} />
            </span>
          ) : null}
        </div>
      </div>
    );
  };

  const renderTrackHeaderMobile = () => {
    const isAllSelected = selectedCount && selectedCount === tracks.length;
    const renderText = isAllSelected
      ? `Edit All`
      : `Edit ${selectedCount} Selected`;
    return (
      <div className={styles.trackHeaderCtn}>
        <div className={styles.editCtn}>
          {!get(navigationData, "confirmTrackOwnershipStep") && (
            <Checkbox
              color="primary"
              indeterminate={isSelectAllIndeterminate}
              checked={selectedTracks.every((isSelected) => isSelected)}
              onChange={handleAllCheckboxChange}
            />
          )}
          {!get(navigationData, "confirmTrackOwnershipStep") && (
            <span className={styles.checkTitle}>
              {isAllSelected ? "Deselect All" : "Select All"}
            </span>
          )}
          {selectedCount ? (
            <span className={styles.checkTitle}>
              {renderText} <EditIcon onClick={toggleFormModal} />
            </span>
          ) : null}
        </div>
      </div>
    );
  };

  const handleRowCheckboxChange = (i) => (e) => {
    setSelectedTracks((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[i] = get(e, "target.checked");
      return newSelected;
    });
  };

  const isSelectAllIndeterminate =
    selectedTracks.some((isSelected) => isSelected) &&
    !selectedTracks.every((isSelected) => isSelected);

  const selectedCount = selectedTracks.reduce(
    (cnt, curr) => (curr ? cnt + 1 : cnt),
    0,
  );

  const toggleFormModal = () => {
    setFormOpen((p) => !p);
  };

  const renderTableData = (form) =>
    invoke(tracks, "map", (track, i) => (
      <CustomRow
        i={i}
        form={form}
        track={track}
        isSelected={get(selectedTracks, i, false)}
        key={`${get(track, "isrc")}-${i}`}
        handleRowCheckboxChange={handleRowCheckboxChange}
        navigationData={navigationData}
        distributorList={distributorList}
      />
    ));

  const renderTableMobData = (form) =>
    invoke(tracks, "map", (track, i) => (
      <CustomRowMobile
        form={form}
        track={track}
        i={i}
        isSelected={get(selectedTracks, i, false)}
        key={`${get(track, "isrc")}-${i}-mob`}
        handleRowCheckboxChange={handleRowCheckboxChange}
        navigationData={navigationData}
        distributorList={distributorList}
        collapseOpen={collapseOpen}
        setCollapseOpen={setCollapseOpen}
      />
    ));

  const setStatusValues = (v, cb) => {
    setNavigationData(v);
    navigationRef.current = cb;
    if (!get(v, "confirmOwnership")) {
      invoke(props, "history.push", HOME);
      return false;
    }
    getAllTracks();
  };

  const handleUpdateSelected = (form) => {
    const data = cloneDeep(get(form, "values.data", []));
    invoke(selectedTracks, `map`, (selected, i) => {
      if (selected) {
        typeof get(form, "values.global.ownership") === "number" &&
          set(data, `${i}.ownership`, get(form, "values.global.ownership"));
        get(form, "values.global.distributor") &&
          set(data, `${i}.distributor`, get(form, "values.global.distributor"));
        typeof get(form, "values.global.isIncluded") === "boolean" &&
          set(data, `${i}.isIncluded`, get(form, "values.global.isIncluded"));
      }
    });
    invoke(form, "setValues", { data });
    toggleFormModal();
  };

  const handleSubmit = (values, form, ...otherProps) => {
    setLoading(true);
    const payload = {
      tracksOwnership: invoke(values, "data.map", (v, i) => ({
        isrc: get(v, "isrc", ""),
        ownership: get(v, "isIncluded") ? get(v, "ownership") || 0 : 0,
        distributor: get(v, "distributor"),
        deal_id: get(v, "deal_id"),
        ownership_source:
          get(form, `initialValues.data.${i}.ownership`) !== get(v, "ownership")
            ? "artist"
            : undefined,
      })),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OWNERSHIP_TRACKS}`;
    request(requestUrl, data)
      .then((res) => {
        if (get(res, "status")) {
          invoke(props, "history.push", GENERATING_OFFERS);
          setAckOpen(false);
          toast.success(get(res, "message"));
          return true;
        }
        setLoading(false);
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  const renderFormModal = (form) => (
    <ReactModal
      isOpen={isFormOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={toggleFormModal}
      className={styles.editModal}
      overlayClassName={styles.modalOverlay}
    >
      <span className={styles.closeBtn} onClick={toggleFormModal}>
        &times;
      </span>
      <h2>Edit {selectedCount} Selected Songs</h2>
      <div className={styles.formContainer}>
        <div className="form-group">
          <label>Song Ownership</label>
          <FormField
            name={`global.ownership`}
            as="numeric"
            placeholder="Add Percentage"
            icon={<PercentIcon />}
            isAllowed={(v) =>
              (get(v, "floatValue") <= 100 && get(v, "floatValue") >= 0) ||
              !get(v, "floatValue")
            }
            disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
          />
        </div>
        <div className={`form-group ${styles.distributor}`}>
          <label>Distributor</label>
          <FormField
            as="select"
            name={`global.distributor`}
            options={distributorList}
            placeholder="Add Distributor"
            components={{ DropdownIndicator: null }}
            classNamePrefix="dist"
            formatOptionLabel={(op) => (
              <div className={styles.option}>
                {get(op, "logoUrl") && (
                  <img
                    src={get(op, "logoUrl")}
                    alt={`${get(op, "label")}-logo`}
                  />
                )}{" "}
                <span>{get(op, "label")}</span>
              </div>
            )}
            disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
          />
        </div>
        <div className={`form-group ${styles.distributor}`}>
          <label>Included in Deal</label>
          <FormField
            as="select"
            name={`global.isIncluded`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            placeholder="Select Option"
            components={{ DropdownIndicator: null }}
            classNamePrefix="dist"
            disabled={!!get(navigationData, "confirmTrackOwnershipStep")}
          />
        </div>
      </div>
      <div className={styles.btnContainer}>
        <button
          type="button"
          className={styles.primary}
          onClick={() => handleUpdateSelected(form)}
        >
          Save Edits
        </button>
      </div>
    </ReactModal>
  );

  const toggleAckModal = (form) => {
    if (get(navigationData, "confirmTrackOwnershipStep")) {
      invoke(props, "history.push", GENERATING_OFFERS);
      return false;
    }
    if (!get(form, "values")) {
      setAckOpen((p) => !p);
      return false;
    }
    const included = invoke(
      form,
      "values.data.filter",
      (v) => !!get(v, `ownership`) && get(v, `isIncluded`),
    );
    if (get(included, "length") !== get(form, "values.data.length")) {
      setAckOpen(true);
      setIncludedCount(get(included, "length", 0));
      return false;
    }
    handleSubmit(get(form, "values"), form);
  };

  const toggleContactPopup = () => setContactOpen((p) => !p);

  const renderAckModal = (form) => (
    <ReactModal
      isOpen={isAckOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={toggleAckModal}
      className={styles.editModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.ackContent}>
        <AlertIcon />
        <h2>Incomplete Ownership</h2>
        <p>
          {" "}
          There are {get(selectedTracks, "length") - includedCount} songs that
          are still missing their ownership percentages.
        </p>
        <p>
          Without these percentages, we cannot include the songs in your
          advance.
        </p>
      </div>
      <div className={styles.btnContainer}>
        <button
          type="button"
          className={styles.primary}
          onClick={toggleAckModal}
        >
          Update Ownership
        </button>
        <button
          type="submit"
          onClick={() => handleSubmit(get(form, "values"), form)}
        >
          No thanks, remove from Advance
        </button>
      </div>
    </ReactModal>
  );

  return (
    <div className={styles.layoutContainer}>
      <HorizontalMenu getStatus={setStatusValues} {...props} />
      <div className={styles.funnelContainer}>
        <LoginHeader headerTitle={"Confirm Ownership"} {...props} />
        <div className={styles.pageContainer}>
          <div className={`${styles.mainContainer}`}>
            <div className={`${styles.scrollContainer}`}>
              <div className={styles.container}>
                <div className={styles.titleContainer}>
                  <h3>Review Songs</h3>
                  <p>
                    The tracks below are the tracks that we confirmed from your
                    Spotify data and submitted reports. Verify your ownership
                    and confirm which tracks you would like to include in the
                    deal.
                  </p>
                </div>
                <div className={styles.tableContainer}>
                  <Formik
                    initialValues={{ data: cloneDeep(tracks) }}
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      data: Yup.array().of(
                        Yup.object().shape({
                          isrc: Yup.string(),
                          ownership: Yup.number()
                            .max(100)
                            .min(0)
                            .nullable()
                            .label("Share of income"),
                          distributor: Yup.string()
                            .nullable()
                            .label("Distributor"),
                          isIncluded: Yup.mixed(),
                        }),
                      ),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      setFieldValue,
                      setValues,
                      handleSubmit,
                      initialValues,
                    }) => (
                      <>
                        <Table className={styles.table}>
                          <TableHead>
                            <TableRow className={styles.headerRow}>
                              <TableCell>{renderTrackHeader()}</TableCell>
                              <TableCell>ISRC</TableCell>
                              <TableCell width={150}>
                                Artist’s Share of Income
                              </TableCell>
                              <TableCell>Distributor</TableCell>
                              <TableCell>Included In Deal</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {renderTableData({ values, setFieldValue })}
                          </TableBody>
                        </Table>
                        <Table className={`${styles.table} ${styles.mobTable}`}>
                          <TableHead>
                            <TableRow className={styles.headerRow}>
                              <TableCell>
                                <span className={styles.trackTitle}>Track</span>
                              </TableCell>
                              <TableCell>Needs Review</TableCell>
                            </TableRow>
                            {!get(
                              navigationData,
                              "confirmTrackOwnershipStep",
                            ) && (
                              <TableRow className={styles.headerRow}>
                                <TableCell
                                  className={styles.mobileCheckboxCell}
                                  colSpan={2}
                                >
                                  {renderTrackHeaderMobile()}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                          <TableBody>
                            {renderTableMobData({ values, setFieldValue })}
                          </TableBody>
                        </Table>
                        {renderFormModal({ values, setFieldValue, setValues })}
                        {renderAckModal({
                          values,
                          setFieldValue,
                          setValues,
                          initialValues,
                        })}
                        <div className={styles.footerContainer}>
                          <div className={styles.contactText}>
                            <p>If something doesn’t look right,</p>
                            <span
                              className={styles.btnLink}
                              onClick={toggleContactPopup}
                            >
                              Contact Us
                            </span>
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              toggleAckModal({
                                values,
                                initialValues,
                              })
                            }
                          >
                            Confirm
                          </button>
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactPopup
        isOpen={isContactOpen}
        onRequestClose={toggleContactPopup}
      />
      {loading && <Loader />}
    </div>
  );
};

export default ConfirmOwnership;
