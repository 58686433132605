import React, { Component } from "react";
import { get } from "lodash";
import authStyles from "../Auth.module.scss";
import Header from "../../../../component/Header";
import {
  API_URL,
  AUTH,
  VERIFICATION,
  GETFUNDED_AUTH,
  LOGIN,
  ACK_REDIRECT,
} from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import request from "../../../../utils/request";
import { GetErrorMessage, getErrorMessage } from "../../helper";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.userVerification();
  }

  componentDidUpdate() {
    setTitle("Verify User", this.context);
  }

  userVerification = () => {
    const payload = {
      code: get(this.props, "match.params.key"),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${VERIFICATION}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });

        if (!res.status) {
          this.setState({ error: get(res, "message") });
          toast.error(get(res, "message"));
          this.props.history.push(LOGIN);
          return [];
        }
        if (res.data.isPartnerUser || res.data.isPartnerArtist) {
          this.props.history.push({
            pathname: ACK_REDIRECT,
            search: `?name=${res.data.artistName}&artistId=${
              res.data.artistId
            }&userId=${res.data.userId}&code=${payload.code}&userEmail=${
              res.data.email
            }&status=${res.data.status}&profile=${
              res.data.profileUrl
            }&isPartner=${res.data.isPartnerUser || res.data.isPartnerArtist}`,
          });
        }

        this.props.history.push({
          pathname: GETFUNDED_AUTH,
          state: {
            data: {
              artistName: res.data.artistName,
              monthlyListeners: res.data.monthlyListeners,
              profilePicture: res.data.profilePicture,
              artistId: res.data.artistId,
              userId: res.data.userId,
              verificationCode: payload.code,
            },
          },
        });
      })
      .catch((err) => {
        this.setState({ loading: false, error: getErrorMessage(err) });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  render() {
    return (
      <div className="theme-bg-dark">
        <Header />
        <div className={authStyles.verifyContent}></div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

VerifyUser.contextType = ThemeContext;
export default VerifyUser;
