import StorageService from "./StorageService";
import axios from "axios";

const STORAGE_USER = "user";
const STORAGE_USER_ROLE = "role";
const STORAGE_KEY = "jwtToken";
const CUSTOM_KEY = "customToken";

const AuthTokenService = {
  /**
   * check the user loging token is exists of not.
   * @auther Innovify
   * @developer Vishakha
   */
  exists() {
    return StorageService.exists(STORAGE_KEY);
  },
  /**
   *  init to store token, if token is not exist
   * @auther Innovify
   * @developer Vishakha
   */
  init(token) {
    if (!this.exists()) {
      this.storeToken(token);
    }
    this.applyTokenHeaders();
    return true;
  },

  applyTokenHeaders() {
    if (this.exists()) {
      axios.defaults.headers.common.Authorization = `Bearer ${this.get()}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  },
  /**
   *  strore token in the localstorage of browser
   * @auther Innovify
   * @developer Vishakha
   */
  storeToken(token) {
    StorageService.set(STORAGE_KEY, token, {
      hash: true,
    });
    this.init();
  },
  /**
   * get token for the localstorage
   * @auther Innovify
   * @developer Vishakha
   */
  get() {
    return StorageService.get(STORAGE_KEY);
  },
  /**
   * clear token set, this method called on logout.
   * @auther Innovify
   */
  clear() {
    // Clearing the whole localstore on logout should be moved
    // into it's own auth status/action handler service
    StorageService.delete(STORAGE_USER);
    StorageService.delete(STORAGE_USER_ROLE);
    StorageService.delete(CUSTOM_KEY);
    return StorageService.delete(STORAGE_KEY);
  },
};

export default AuthTokenService;
