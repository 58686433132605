import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import _, { get, invoke, set } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import Tooltip from "../../../component/Tooltip/Tooltip";
import Select from "react-select";
import ReactModal from "react-modal";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import { FormControlLabel, FormControl, Checkbox } from "@material-ui/core";
import classes from "../YourAdvance/YourAdvance.module.scss";
import styles from "./SendReports.module.scss";
import {
  getRadioInformationText,
  getRadioText,
  OTHER_DISTRIBUTOR,
  areBothFieldsExist,
  isOneFieldRequired,
  OVERALL_STATUS,
} from "./constants";
import {
  SEND_REPORT_FAST_FLOW_NOTE_MSG,
  SEND_REPORT_MSG,
} from "../infoIconMsg";
import LoginHeader from "../../../component/LoginHeader";
import UploadFiles from "../../../component/UploadFiles/UploadFiles";
import actionStyles from "../../../component/UploadFiles/UploadFiles.module.scss";
import {
  TOP_TRACK,
  ARTIST_API,
  USER_API,
  API_URL,
  DASHBOARD,
  REPORT,
  SEND_REPORTS_API,
  FUNDING,
  ACTIVE_DISTRIBUTORS,
  DISTRIBUTORS_API,
  DISTRIBUTOR_REPORTS_API,
  DEMO_DASHBOARD,
  EXIT_DEMO,
  TRACK_REASON_UPDATE,
  SEND_REPORTS,
  ERROR_SERVER_DOWN,
  LINK_SPOTIFY,
  GENERATING_OFFERS,
  HOME,
  PUBLISHING_REPORTS,
  PUBLISHER_REPORTS_API,
  PROS_API,
  PUBLISHER_OFFER_API,
  INFO_API,
} from "../constants";
import request from "../../../utils/request";
import { GetErrorMessage } from "../helper";
import Loader from "../../../component/Loader";
import { VERIFIED } from "../../../component/MenuBar/constants";
import HorizontalMenu from "../../../component/HorizontalMenu";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  setTitle,
  getPartnerName,
} from "../../../component/ThemeManager/helper";
import FormikForm from "../../../component/Form/FormikForm";
import { Form } from "formik";
import FormField from "../../../component/FormField/FormField";
import { FIELD_REGEX } from "../AdminDashboard/WhiteLabel/constants";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import {
  AckSuccessModal,
  AckReportIssueModal,
  AckTrackIssueModal,
  AckErrorModal,
  PublishingConfirmationModal,
} from "./helper";
import ContactPopup from "../../../component/ContactPopup";
import { ReactComponent as ClipBoardIcon } from "../../../img/streamingData.svg";
import { ReactComponent as DistributorIcon } from "../../../assets/logos/distributorReportsIcon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/logos/trash-icon.svg";
import { ReactComponent as CheckboxFilled } from "../../../assets/logos/checkbox-checked.svg";
import { ReactComponent as CheckboxEmpty } from "../../../assets/logos/checkbox-empty.svg";
import { NAVIGATION_TEXTS } from "../../../component/HorizontalMenu/constants";
import segment from "../../../utils/segment";
import {
  CHOOSE_PATH_FIELDS,
  FAST_FLOW_STEPS,
} from "../Auth/LinkAnotherArtist/constant";
import { RIGHTS_TYPES, SOUND_EXCHANGE } from "../YourAdvance/constants";
import {
  MOGUL_REPORT_DOWNLOAD_LINK,
  MOGUL_WHITELABEL,
} from "../../../component/UploadFiles/constants";

class SendReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTime: false,
      selectOptions: [],
      files: {},
      offerStage: {},
      loading: false,
      shortLoading: false,
      uploadModal: false,
      shareReports: "yes",
      initialValues: {
        label1: "",
        label2: "",
        atLeastOne: "",
      },
      distributorInfo: {
        id: "",
        name: "",
        instructionsUrl: "",
        instructionsHTML: "",
        logoUrl: "",
      },
      selectedDistributor: "",
      mobileModal: true,
      uploadConfirmation: false,
      deleteReport: {},
      guideData: {},
      isSignup: false,
      ackSuccessModal: false,
      ackReportIssueModal: false,
      ackTrackIssueModal: false,
      ackErrorModal: false,
      reportIssueData: [],
      failedReports: [],
      trackIssueData: [],
      isContactOpen: false,
      uploadedCount: 0,
      submittedCount: 0,
      otherNameVal: "",
      selectedArtist: null,
      navigationStatus: {},
      selectedPublisherId: null,
      addPublisher: false,
      estimatedOffer: {},
      publishModal: false,
      publishModalType: undefined,
      deleteDistributor: false,
      deleteReportFromDistirbutor: false,
      requestId: "",
      publishingRequestId: "",
    };
    this.formRef = React.createRef();
    this.getData = this.getData.bind(this);
    this.navigationCallback = null;
  }

  getData(val) {
    this.setState({ otherNameVal: val });
  }
  componentDidUpdate(prevProps, prevState) {
    setTitle("Upload Reports", this.context);
    if (
      get(this.context, "reportFields.isDistributor") === "No" &&
      prevState.shareReports === "yes"
    ) {
      this.setState({ shareReports: "no" });
    }
    if (!_.isEqual(prevState.files, this.state.files)) {
      let uploadedCount = 0,
        submittedCount = 0;
      Object.values(this.state.files).forEach((distributor) => {
        get(distributor, "reports", []).forEach((report) => {
          report.sent ? submittedCount++ : uploadedCount++;
        });
      });
      this.setState({ uploadedCount, submittedCount });
    }
  }

  componentDidMount() {
    this.getStatusData();
  }

  isPublishing = () =>
    get(this.props, "location.pathname") === PUBLISHING_REPORTS;

  getStatusData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false&reportAnalytics=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          if (
            this.isPublishing() &&
            (!get(res, "data.addPublisher") ||
              get(res, "data.estimatedOffer.offer.rights_type") ===
                RIGHTS_TYPES[1].value)
          ) {
            invoke(this.props, "history.push", HOME);
            return false;
          }
          this.setState({
            navigationStatus: {
              ...this.state.navigationStatus,
              isFastFlow: get(res.data, "isFastFlow", false),
            },
            offerStage: res.data.offerStage,
            selectedPublisherId: get(res, "data.selectedPublisherId", null),
            addPublisher: get(res, "data.addPublisher", false),
            requestId: get(res, "data.requestId", ""),
            publishingRequestId: get(res, "data.publishingRequestId", ""),
            estimatedOffer: get(res, "data.estimatedOffer", {}),
            artistProfile: {
              url: get(
                res.data,
                `profilePicture[${
                  get(res.data, "profilePicture", [{}]).length - 1
                }].url`,
                "",
              ),
              name: res.data.name,
            },
          });
          this.checkStatus(
            get(res.data, "offerStage.verificationStatus.funding.value"),
            res.data,
          );
          switch (get(res.data, "reportAnalytics.status")) {
            case OVERALL_STATUS[2]:
              this.setState({
                ackTrackIssueModal: false,
                trackIssueData: get(
                  res.data,
                  "reportAnalytics.missing_isrc",
                  [],
                ),
              });
              break;
            default:
              break;
          }
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  handleRedirection = () => {
    if (get(this.state.navigationStatus, "isFastFlow", false)) {
      this.props.history.push(GENERATING_OFFERS);
      return true;
    }
    this.props.history.push(LINK_SPOTIFY);
    return false;
  };

  checkStatus = (fundingData, data) => {
    if (
      fundingData === VERIFIED ||
      (([
        FAST_FLOW_STEPS.INCOME_VERIFIED,
        FAST_FLOW_STEPS.INCOME_NOT_VERIFIED,
        FAST_FLOW_STEPS.PENDING,
      ].indexOf(get(data, "artistStatus")) !== -1 ||
        get(data, "choosePath") === CHOOSE_PATH_FIELDS().OPTIONS[0].value) &&
        get(data, "isFastFlow"))
    ) {
      this.setState({ loading: true });
      if (get(this.props.history.location, "state.redirectMsg")) {
        toast.error(get(this.props.history.location, "state.redirectMsg"));
        const state = { ...this.props.history.location.state };
        delete state.redirectMsg;
        this.props.history.replace({ ...this.props.history.location, state });
      }
      this.getActiveDistributors();
      return true;
    }
    this.props.history.push({
      pathname: FUNDING,
      state: { redirectMsg: "First, please save your funding options." },
    });
  };

  getActiveDistributors = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? PROS_API : ACTIVE_DISTRIBUTORS
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const options = res.data.map((option) => {
            return {
              label: get(option, "name", ""),
              value: get(option, "_id"),
              logoUrl: get(option, "logoUrl"),
            };
          });
          options.push({ label: "Other", value: OTHER_DISTRIBUTOR, name: "" });
          this.setState({ selectOptions: options });
          this.getUploadedFiles();
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  setFieldValues = (data) => {
    const initialValues = this.state.initialValues;
    data.forEach((field) => {
      if (get(this.context, "reportFields.label1") === field.name) {
        initialValues.label1 = field.value;
      }
      if (get(this.context, "reportFields.label2") === field.name) {
        initialValues.label2 = field.value;
      }
    });
    return initialValues;
  };

  handleCloseModalWithData = () =>
    this.setState(
      { ackReportIssueModal: false, ackTrackIssueModal: false },
      this.getUploadedFiles,
    );

  getUploadedFiles = () => {
    this.setState({
      loading: true,
    });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? PUBLISHER_REPORTS_API : REPORT
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const initialValues = this.setFieldValues(
            get(res, "data.reportFields", []),
          );
          this.setState({
            files: this.isPublishing()
              ? get(res, "data")
              : get(res.data, "reports"),
            initialValues,
          });
          const option =
            _.find(this.state.selectOptions, {
              [this.isPublishing() ? "value" : "label"]: this.isPublishing()
                ? get(this.state, "selectedPublisherId")
                : get(res, "data.majorDistributor"),
            }) ||
            (this.isPublishing()
              ? null
              : _.find(get(this.state, "selectOptions"), {
                  value: OTHER_DISTRIBUTOR,
                }));
          this.isPublishing() && this.onDistributorChange(option);
          !this.isPublishing() &&
            get(res, "data.majorDistributor") &&
            this.onDistributorChange(option);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  onDistributorChange = (option, isOther) => {
    if (get(option, "value") === OTHER_DISTRIBUTOR || isOther === true) {
      const distributorInfo = {
        _id: option.name,
        name: option.name,
        otherName: `other: ${option.name}`,
        isOther: true,
      };
      distributorInfo.isEditMode = isOther === true;
      this.setState({
        distributorInfo,
        uploadModal: true,
        selectedDistributor: null,
      });
      return true;
    }
    if (this.isPublishing() && !option) {
      this.setState({
        uploadModal: true,
      });
      return true;
    }
    this.setState({ loading: true, selectedDistributor: null });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? `${PROS_API}${INFO_API}` : DISTRIBUTORS_API
    }?id=${option.value}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const { instructionsUrl, instructionsHTML, logoUrl, name } = res.data;
          const distributorInfo = {
            _id: res.data._id,
            name,
            instructionsUrl,
            instructionsHTML,
            logoUrl,
          };
          this.setState({ uploadModal: true, distributorInfo });
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(DASHBOARD);
      });
  };

  validationSchema = () => {
    const validationObject = {};
    const fieldsData = [];
    if (get(this.context, "reportFields.label1")) {
      validationObject.label1 = Yup.string()
        .trim()
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          `Invalid input. Please provide valid input.`,
        )
        .max(300)
        .label(`${get(this.context, "reportFields.label1", "")}`);
      fieldsData.push(`${get(this.context, "reportFields.label1", "")}`);
      if (get(this.context, "reportFields.isField1Required")) {
        validationObject.label1 = Yup.string()
          .required("*Required Field")
          .trim()
          .matches(
            FIELD_REGEX.CTA_BUTTON_TEXT,
            `Invalid input. Please provide valid input.`,
          )
          .max(300)
          .label(`${get(this.context, "reportFields.label1", "")}`);
      }
    }
    if (get(this.context, "reportFields.label2")) {
      validationObject.label2 = Yup.string()
        .trim()
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          `Invalid input. Please provide valid input.`,
        )
        .max(300)
        .label(`${get(this.context, "reportFields.label2", "")}`);
      fieldsData.push(`${get(this.context, "reportFields.label2", "")}`);
      if (get(this.context, "reportFields.isField2Required")) {
        validationObject.label2 = Yup.string()
          .required("*Required Field")
          .trim()
          .matches(
            FIELD_REGEX.CTA_BUTTON_TEXT,
            `Invalid input. Please provide valid input.`,
          )
          .max(300)
          .label(`${get(this.context, "reportFields.label2", "")}`);
      }
    }

    if (
      !get(this.context, "reportFields.isField1Required") &&
      !get(this.context, "reportFields.isField2Required") &&
      fieldsData.length
    ) {
      const msg =
        fieldsData.length > 1
          ? `Please provide either ${fieldsData[0]} or ${fieldsData[1]}`
          : `${fieldsData[0]} is required field`;
      validationObject.atLeastOne = Yup.string().when(["label1", "label2"], {
        is: (v1, v2) => !v1 && !v2,
        then: Yup.string().required(msg),
      });
    }
    return Yup.object().shape({
      ...validationObject,
    });
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  updateOthersList = (key, distributorId, oldDistributorId) => {
    const files = JSON.parse(JSON.stringify(this.state.files));
    const data = _.remove(
      files[oldDistributorId].reports,
      (file) => key.indexOf(file.key) !== -1,
    );
    !files[oldDistributorId].reports.length && delete files[oldDistributorId];
    const existingReports = get(files, `${distributorId}.reports`, []);
    set(files, `${distributorId}.reports`, [...existingReports, ...data]);
    files[distributorId]._id = distributorId;
    files[distributorId].name = distributorId;
    files[distributorId].isOther = true;
    this.setState({ files });
  };

  deleteAllReport = (distributorId, isOther) => {
    this.setState({
      loading: true,
      deleteDistributor: true,
    });
    let payload = isOther ? { otherName: distributorId } : { distributorId };
    if (this.isPublishing()) {
      if (isOther) {
        payload = { isOther };
      } else {
        payload = { publisherId: distributorId };
      }
    }
    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? PUBLISHER_REPORTS_API : DISTRIBUTOR_REPORTS_API
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const files = JSON.parse(JSON.stringify(this.state.files));
          delete files[distributorId];
          this.setState({ files });
          if (this.isPublishing() && !_.size(files)) this.onDistributorChange();
          toast.success("Deleted successfully");
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  deleteReport = (key, fileName, distributorId, isSent) => {
    this.setState({
      loading: true,
      deleteReportFromDistirbutor: true,
    });
    const payload = this.isPublishing()
      ? { publisherReportId: key }
      : {
          key,
        };

    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? PUBLISHER_REPORTS_API : REPORT
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const files = JSON.parse(JSON.stringify(this.state.files));
          _.remove(files[distributorId].reports, {
            [this.isPublishing() ? "_id" : "key"]: key,
          });
          !files[distributorId].reports.length && delete files[distributorId];
          this.setState({ files });
          toast.success(`File "${fileName}" deleted successfully.`);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        return false;
      });
  };

  addReport = (distributorInfo, file) => {
    const { distributorInfo: currentDistributor } = this.state;
    const files = JSON.parse(JSON.stringify(this.state.files));
    const existingReports = get(files, `${distributorInfo._id}.reports`, []);
    set(files, `${distributorInfo._id}.reports`, [...existingReports, file]);
    files[distributorInfo._id]._id = currentDistributor._id;
    files[distributorInfo._id].name = currentDistributor.name;
    files[distributorInfo._id].logoUrl = currentDistributor.logoUrl;
    files[distributorInfo._id].isOther = currentDistributor.isOther;
    this.setState({ files });
  };

  handleCloseReportsModal = () => {
    this.setState({
      uploadModal: false,
      mobileModal: false,
      ackReportIssueModal: false,
    });
  };

  handleMobileCloseReportsModal = () => this.setState({ mobileModal: false });

  getFilesData = (distributorInfo) => {
    return get(this.state, `files.${get(distributorInfo, "_id")}.reports`, []);
  };

  uploadReportsModal = () => {
    const { distributorInfo } = this.state;
    return (
      <div className={styles.overlayClass}>
        <UploadFiles
          files={this.getFilesData(distributorInfo)}
          handleClose={this.handleCloseReportsModal}
          distributorInfo={distributorInfo}
          deleteReport={this.deleteReport}
          addReport={this.addReport}
          offerStage={this.state.offerStage}
          radioSelection={this.state.shareReports}
          setLoading={this.setLoading}
          isUploadReports={true}
          selectOptions={this.state.selectOptions}
          updateOthersList={this.updateOthersList}
          isFastFlow={get(this.state.navigationStatus, "isFastFlow")}
          onDistributorChange={this.onDistributorChange}
          totalFiles={_.size(this.state.files)}
          isPublishing={this.isPublishing()}
          handleSendReports={this.handleSendReports}
          handleToggleContact={this.handleToggleContact}
          {...this.props}
        />
      </div>
    );
  };

  mobileScreenModal = () => {
    return (
      <ReactModal
        isOpen={this.state.mobileModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleMobileCloseReportsModal}
        className={styles.mobileModal}
        overlayClassName={styles.mobileModalOverlay}
      >
        <div>
          <span
            className={styles.closeBtn}
            onClick={this.handleMobileCloseReportsModal}
          >
            &times;
          </span>
          <h2>Heads up!</h2>
          <p>
            You will find the next few steps much easier on a computer than on a
            phone.
          </p>
          <p>
            We suggest you login to your {getPartnerName()} account from a
            laptop or desktop to continue.
          </p>
          <div className={styles.btnContainer}>
            <button
              className={styles.primary}
              onClick={this.handleMobileCloseReportsModal}
            >
              Got it
            </button>
          </div>
        </div>
      </ReactModal>
    );
  };

  renderRadioUI = () => {
    const { shareReports } = this.state;
    const displayName = get(this.context, "displayName", "");
    return (
      <>
        <p>{getRadioInformationText(displayName)}</p>
        <FormControl component="fieldset">
          <FormControlLabel
            onChange={(e) => {
              this.setState({
                shareReports: e.target.checked ? "yes" : "no",
              });
            }}
            data-testid="SendReportsRadio"
            value={shareReports}
            label={getRadioText(displayName).trueValue}
            control={
              <Checkbox
                checked={shareReports === "yes"}
                className={styles.checkbox}
                icon={<CheckboxEmpty />}
                checkedIcon={<CheckboxFilled />}
              />
            }
            className={shareReports === "yes" ? styles.checked : styles.unchecked}
          />
        </FormControl>
      </>
    );
  };

  fieldsRender = () => {
    const { reportFields } = this.context;
    return (
      this.state.shareReports === "yes" && (
        <FormikForm
          initialValues={this.state.initialValues}
          enableReinitialize
          validationSchema={this.validationSchema}
          innerRef={this.formRef}
          onSubmit={() => {}}
        >
          <Form className={styles.form}>
            {areBothFieldsExist(this.context) &&
              !isOneFieldRequired(this.context) && (
                <div className="form-group  w-100 m-0">
                  <div className={styles.formInfoText}>
                    Please fill out at least one of these fields
                  </div>
                </div>
              )}
            {get(reportFields, "label1") && (
              <div
                className={`form-group d-flex align-items-center ${styles.spacingBottom}`}
              >
                <FormField
                  name="label1"
                  placeholder={get(reportFields, "label1", "")}
                  label={`${get(reportFields, "label1", "")}${
                    get(reportFields, "isField1Required") ||
                    !areBothFieldsExist(this.context)
                      ? " *"
                      : ""
                  }`}
                />
                {get(reportFields, "tooltip1") && (
                  <>
                    <Tooltip
                      place="top"
                      light
                      id="fieldLabel1"
                      delay={100}
                      content={get(reportFields, "tooltip1")}
                    />
                  </>
                )}
              </div>
            )}
            {get(reportFields, "label2") && (
              <div className="form-group  d-flex align-items-center">
                <FormField
                  name="label2"
                  placeholder={get(reportFields, "label2", "")}
                  label={`${get(reportFields, "label2", "")}${
                    get(reportFields, "isField2Required") ||
                    !areBothFieldsExist(this.context)
                      ? " *"
                      : ""
                  }`}
                />
                {get(reportFields, "tooltip2") && (
                  <>
                    <Tooltip
                      place="top"
                      light
                      id="fieldLabel2"
                      delay={100}
                      content={get(reportFields, "tooltip2")}
                    />
                  </>
                )}
              </div>
            )}
            <div className="form-group  w-100 m-0">
              <FormField
                name={"atLeastOne"}
                type="text"
                as={"textMsg"}
                errorClass={styles.error}
                containerClass={styles.containerClass}
              />
            </div>
          </Form>
        </FormikForm>
      )
    );
  };

  getFieldObject = (formRef, reportFields, label) =>
    get(reportFields, label)
      ? [
          {
            name: `${get(reportFields, label)}`,
            value: `${formRef.values[label]}`,
          },
        ]
      : [];

  getSubmitAction = () => {
    const rightsType = get(this.state, "navigationStatus.rightsType");
    const isBothIdAvailable =
      !!get(this.state, "requestId") &&
      !!get(this.state, "publishingRequestId");
    const initialRightsType = get(
      this.state,
      "navigationStatus.initialRightsType",
    );
    const publishModalType =
      rightsType === RIGHTS_TYPES[0].value
        ? RIGHTS_TYPES[1].value
        : RIGHTS_TYPES[0].value;
    if (get(this.state, "navigationStatus.addPublisher") && isBothIdAvailable) {
      if (initialRightsType === RIGHTS_TYPES[0].value) {
        if (this.isPublishing() && rightsType === RIGHTS_TYPES[0].value) {
          this.setState({ publishModal: true, publishModalType });
          return false;
        }
      } else if (!this.isPublishing() && rightsType === RIGHTS_TYPES[1].value) {
        this.setState({ publishModal: true, publishModalType });
        return false;
      }
    }
    this.handleSendReports();
  };

  handleSendReports = async () => {
    const body = { reportFields: [] };
    const { reportFields } = this.context;
    let formRef = this.formRef.current;
    if (
      get(reportFields, "isDistributor") === "Yes" &&
      this.state.shareReports === "yes" &&
      formRef
    ) {
      await formRef.submitForm();
      formRef = this.formRef.current;
      if (!formRef.isValid) {
        return false;
      }
      const fields = [
        ...this.getFieldObject(formRef, reportFields, "label1"),
        ...this.getFieldObject(formRef, reportFields, "label2"),
      ];
      body.reportFields = fields;
    }

    this.setState({
      [_.size(this.state.files) ? "shortLoading" : "loading"]: true,
      publishModal: false,
    });
    body.reportFromDistributor = this.state.shareReports;
    body.isDistributor = get(this.state.navigationStatus, "isFastFlow", false)
      ? "No"
      : get(reportFields, "isDistributor");
    const data = {
      method: "POST",
      body,
    };
    const publisherData = {
      method: "PUT",
      body: { skipPublisherReport: !_.size(this.state.files) },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      this.isPublishing() ? PUBLISHER_REPORTS_API : SEND_REPORTS_API
    }`;
    request(requestUrl, this.isPublishing() ? publisherData : data)
      .then((res) => {
        this.setState({ shortLoading: false, loading: false });
        if (res.status) {
          segment.track.submitForReview(this.state);
          toast.success(get(res, "message"));
          if (get(this.state.navigationStatus, "isFastFlow", false)) {
            if (
              get(this.state, "navigationStatus.initialRightsType") ===
              RIGHTS_TYPES[0].value
            ) {
              if (this.isPublishing()) {
                this.props.history.push(SEND_REPORTS);
                return true;
              }
              this.props.history.push(GENERATING_OFFERS);
              return true;
            }
            if (
              this.isPublishing() ||
              (get(this.state, "navigationStatus.addPublisher") &&
                get(
                  this.state,
                  "navigationStatus.rightsType",
                  get(this.state, "navigationStatus.initialRightsType"),
                ) === RIGHTS_TYPES[1].value) ||
              !get(this.state, "navigationStatus.addPublisher")
            ) {
              this.props.history.push(GENERATING_OFFERS);
              return true;
            }
            this.props.history.push(PUBLISHING_REPORTS);
            return true;
          }
          this.props.history.push(LINK_SPOTIFY);
          toast.success(get(res, "message"));
          return true;
        }
        this.setState({ ackErrorModal: true });
        return false;
      })
      .catch((err) => {
        this.setState({ shortLoading: false, loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  exitDemo = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EXIT_DEMO}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(DEMO_DASHBOARD);
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  handlePublishingAction = (action) => {
    if (!action) {
      this.handleSendReports();
      return false;
    }
    this.setState({ loading: true });
    const data = {
      method: "PATCH",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${PUBLISHER_OFFER_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const navigationStatus = {
            ...get(this.state, "navigationStatus", {}),
          };
          set(
            navigationStatus,
            "rightsType",
            get(res, "data.rights_type", RIGHTS_TYPES[2].value),
          );
          this.setState({ navigationStatus });
          this.handleSendReports();
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  renderActions = () => {
    return (
      (!!this.state.uploadedCount || !this.state.submittedCount) && (
        <div className={`${actionStyles.uploadButtons}`}>
          <FormControl
            component="fieldset"
            className={actionStyles.actionCheck}
          >
            <FormControlLabel
              onChange={(e) => {
                this.setState({
                  uploadConfirmation: e.target.checked,
                });
              }}
              value={this.state.uploadConfirmation}
              label={
                this.isPublishing()
                  ? `I've uploaded 6 months of reports for each work that I want to include in my advance offer`
                  : `I’ ve uploaded 6 months of reports for each track that I want to include in my advance offer`
              }
              control={
                <Checkbox
                  checked={this.state.uploadConfirmation}
                  className={actionStyles.checkbox}
                  icon={<CheckboxEmpty />}
                  checkedIcon={<CheckboxFilled />}
                />
              }
              className={this.state.uploadConfirmation ? styles.checked : styles.unchecked}
            />
          </FormControl>
          <div className={`${actionStyles.uploadReportsButtonContainer} `}>
            <button
              className={actionStyles.primary}
              disabled={
                (!_.size(this.state.files) &&
                  (this.state.shareReports === "no" ||
                    get(this.state.navigationStatus, "isFastFlow"))) ||
                !this.state.uploadConfirmation
              }
              onClick={this.getSubmitAction}
            >
              Submit for review
            </button>
          </div>
        </div>
      )
    );
  };

  reportDistributorLogo = (distributor) =>
    distributor.logoUrl ? (
      <div className={styles.imgContainer}>
        <Image
          src={distributor.logoUrl}
          alt={distributor.name}
          imageType={IMAGE_TYPE.LOGO}
        />
      </div>
    ) : (
      <h2>{distributor.name}</h2>
    );

  getSubmittedAndUploadedReports = (distributor) => {
    const submitted = [];
    get(distributor, "reports", []).forEach((report) => {
      report.sent && submitted.push(report.submittedDate);
    });
    const submittedCount = submitted.length;
    const uploadedCount =
      get(distributor, "reports", []).length - submittedCount;
    const submittedDate = _.compact(submitted).sort().pop();

    return (
      <>
        {!!uploadedCount && (
          <span>
            {uploadedCount} file{uploadedCount > 1 && "s"} uploaded
          </span>
        )}
        {!!submittedCount && (
          <span>
            {submittedCount} file{submittedCount > 1 && "s"} submitted{" "}
            {!!submittedDate &&
              `on ${moment(submittedDate).format("MM/DD/YYYY")}`}
          </span>
        )}
      </>
    );
  };

  otherTitleRender = (distributor) => (
    <h2>
      Other {this.isPublishing() ? "Publisher" : "Distributor"}
      {distributor.name && `: ${distributor.name}`}
    </h2>
  );

  renderReportList = () => {
    const distributors = _.values(this.state.files);
    return (
      <div className={styles.distList}>
        {distributors.map((distributor) => (
          <div className={styles.reportContainer} key={distributor._id}>
            <div
              className={`${styles.reportDistributor} ${
                get(this.state.navigationStatus, "isFastFlow")
                  ? styles.fastReportDistributor
                  : ""
              }`}
            >
              {distributor.isOther
                ? this.otherTitleRender(distributor)
                : this.reportDistributorLogo(distributor)}
              <p
                className={`${styles.reportListCount} ${
                  this.state.navigationStatus.isFastFlow &&
                  styles.fastReportList
                }`}
              >
                {this.getSubmittedAndUploadedReports(distributor)}
              </p>
              <div className={styles.actionContainer}>
                <span
                  className={styles.addMore}
                  onClick={() =>
                    this.onDistributorChange(
                      {
                        value: distributor._id,
                        name: distributor.name,
                        label: distributor.name,
                      },
                      distributor.isOther,
                    )
                  }
                >
                  <span className={styles.plus}>+</span>{" "}
                  <span className={styles.linkText}>
                    Add More {distributor.name} Reports
                  </span>
                </span>
                <span>
                  <TrashIcon
                    title="delete all"
                    onClick={() => {
                      this.deleteAllReport(
                        distributor._id,
                        distributor.isOther,
                      );
                    }}
                    data-testid="deleteAll"
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  handleTrackReasonUpdate = (values) => {
    this.setState({ loading: true });
    const payload = { missing_tracks: [...this.state.trackIssueData] };
    payload.missing_tracks = payload.missing_tracks.map((track, index) => ({
      ...track,
      reason: get(values, `tracks.${index}.reason`, ""),
    }));
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TRACK_REASON_UPDATE}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.handleRedirection();
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleToggleContact = () => {
    this.setState({ isContactOpen: !this.state.isContactOpen });
  };

  getSelectLabel = () =>
    this.isPublishing() ? "Publisher or another PRO" : "different distributor";

  selectDistributorTextRender = () =>
    this.state.submittedCount
      ? `Want to submit more reports?`
      : `Have more reports from a ${this.getSelectLabel()}? Select another below.`;

  updateUserData = (val) => {
    this.setState({ selectedArtist: val });
  };

  setNavigationStatus = (status, callback) => {
    this.setState({ navigationStatus: status });
    this.navigationCallback = callback;
  };

  sendReportContainer = () => {
    return (
      <>
        {!get(this.state.navigationStatus, "isFastFlow", false) && (
          <div className={styles.pageHeadContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                <h1>
                  Upload Reports
                  <Tooltip
                    place="top"
                    light
                    id="send_reports"
                    delay={100}
                    content={SEND_REPORT_MSG}
                  />
                </h1>
              </div>
              <div className={`${styles.subtitle} `}>
                {get(this.context, "reportFields.isDistributor") === "Yes" ? (
                  <p>
                    If there are any tracks you'd like included in your advance
                    offer that are on another distributor, or you would like to
                    manually upload your {get(this.context, "displayName", "")}{" "}
                    reports, please upload those earnings reports below.
                    <Tooltip
                      place="top"
                      light
                      id="send_reports_subtext"
                      delay={100}
                      content={`Any tracks included in your advance calculation that are not currently distributed by ${get(
                        this.context,
                        "displayName",
                      )} must be transferred before we can release your advance`}
                    />
                  </p>
                ) : (
                  <p>
                    We need the last 6 months of detailed reports covering all
                    tracks you want included in your advance offer.
                    {get(this.context, "slugName") === SOUND_EXCHANGE && (
                      <span className="textBold">
                        {" "}
                        You may be eligible for larger advance when you also
                        upload your SoundExchange reports for your "Rights
                        Owner" share
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.rightInfoText}>
              <ClipBoardIcon className={styles.clipBoardIcon} />
              <span>{SEND_REPORT_FAST_FLOW_NOTE_MSG}</span>
            </div>
          </div>
        )}
        <div className={styles.container}>
          {get(this.context, "reportFields.isDistributor") === "Yes" &&
            !get(this.state.navigationStatus, "isFastFlow", false) && (
              <>
                <div className={`${styles.radioContainer} `}>
                  {this.renderRadioUI()}
                  {this.fieldsRender()}
                </div>
              </>
            )}
          <div className={styles.sendReportsSubContainer}>
            {!!Object.keys(this.state.files).length && (
              <div
                className={`${styles.uploadReportListing} ${
                  get(this.state.navigationStatus, "isFastFlow")
                    ? styles.fastUploadReportListing
                    : ""
                } `}
              >
                <p
                  className={`${styles.reportListTitle} ${
                    get(this.state.navigationStatus, "isFastFlow")
                      ? styles.fastReportListTitle
                      : ""
                  } `}
                >
                  Uploaded Reports
                </p>
                {this.renderReportList()}
              </div>
            )}
            <div
              className={`${styles.selectDistributorContainer} ${
                !Object.keys(this.state.files).length ? styles.initial : ""
              } `}
            >
              {!Object.keys(this.state.files).length ? (
                <>
                  <div className={styles.reportInitialLogo}>
                    <DistributorIcon />
                  </div>
                  <p className={styles.subTitle}>
                    Pick your distributor(s) from the dropdown to upload reports
                  </p>
                </>
              ) : (
                <p className={styles.subTitle}>
                  {this.selectDistributorTextRender()}
                </p>
              )}
              <div
                className={`${styles.dropDownContainer} ${
                  this.state.navigationStatus.isFastFlow &&
                  styles.fastDropDownContainer
                } `}
              >
                <Select
                  classNamePrefix="distributor"
                  styles={distributorSelectStyle}
                  onChange={this.onDistributorChange}
                  options={this.state.selectOptions}
                  placeholder={`Select a ${
                    this.isPublishing() ? "Publisher/PRO" : "Distributor"
                  }`}
                  value={this.state.selectedDistributor}
                  components={{ IndicatorSeparator: null }}
                  formatOptionLabel={(val) => (
                    <div className={styles.option}>
                      {get(val, "logoUrl") && (
                        <img
                          src={get(val, "logoUrl")}
                          alt={`${get(val, "label")}-logo`}
                        />
                      )}{" "}
                      <span>{get(val, "label")}</span>
                    </div>
                  )}
                />
              </div>
            </div>
            {this.renderActions()}
          </div>
        </div>
      </>
    );
  };

  explainContainer = () => {
    return (
      <>
        {get(this.state.navigationStatus, "isFastFlow", false) && (
          <>
            <div className={styles.explainerContainer}>
              <p className={styles.header}>What do we need?</p>
              <p className={styles.content}>
                Please include 6 months of reports for each track that you want
                to include in your advance offer.
              </p>
            </div>
            {[SOUND_EXCHANGE, MOGUL_WHITELABEL].indexOf(
              get(this.context, "slugName"),
            ) !== -1 && (
              <div className={styles.soundExchangeDiv}>
                {get(this.context, "slugName") !== SOUND_EXCHANGE && (
                  <>
                    You may be eligible for larger advance when you also upload
                    your SoundExchange reports for your "Rights Owner" share.
                  </>
                )}
                {get(this.context, "slugName") !== MOGUL_WHITELABEL && (
                  <>
                    See how to download your reports from your Mogul account{" "}
                    <a
                      className={styles.mogulReportDlGuideLink}
                      href={MOGUL_REPORT_DOWNLOAD_LINK}
                      target="_blank"
                    >
                      here.
                    </a>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu
          offerStage={this.state.offerStage}
          {...this.props}
          updateUserData={this.updateUserData}
          getStatus={this.setNavigationStatus}
        />
        <div className={classes.funnelContainer}>
          <LoginHeader
            headerTitle={
              NAVIGATION_TEXTS[
                get(this.props, "location.pathname", SEND_REPORTS)
              ].pageTitle
            }
            selectedArtist={this.state.selectedArtist}
            switchDistributorForFunding={get(
              this.state,
              "navigationStatus.switchDistributorForFunding",
            )}
          />
          <div
            className={`${styles.pageContainer} ${styles.dataReviewContainer} ${styles.sendReportsContainer} `}
          >
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                {this.explainContainer()}
                {!this.state.uploadModal
                  ? this.sendReportContainer()
                  : this.uploadReportsModal()}
              </div>
            </div>
            {this.mobileScreenModal()}
            <AckSuccessModal
              isOpen={this.state.ackSuccessModal}
              history={this.props.history}
              handleRedirection={this.handleRedirection}
            />
            <AckReportIssueModal
              isOpen={this.state.ackReportIssueModal}
              reportData={this.state.reportIssueData}
              failedReports={this.state.failedReports}
              handleClose={this.handleCloseModalWithData}
              handleToggleContact={this.handleToggleContact}
              history={this.props.history}
              handleRedirection={this.handleRedirection}
            />
            <AckTrackIssueModal
              isOpen={this.state.ackTrackIssueModal}
              trackData={this.state.trackIssueData}
              handleClose={this.handleCloseModalWithData}
              handleSubmitApi={this.handleTrackReasonUpdate}
              handleToggleContact={this.handleToggleContact}
              history={this.props.history}
              handleRedirection={this.handleRedirection}
            />
            <AckErrorModal
              isOpen={this.state.ackErrorModal}
              history={this.props.history}
              handleRedirection={this.handleRedirection}
            />
            <PublishingConfirmationModal
              isOpen={this.state.publishModal}
              handleAction={this.handlePublishingAction}
              type={this.state.publishModalType}
            />
            <ContactPopup
              isOpen={this.state.isContactOpen}
              onRequestClose={this.handleToggleContact}
            />
            {this.state.loading && <Loader light backgroundNone />}
            {this.state.shortLoading && (
              <ShortCallLoader
                loaderTitle="Processing your reports."
                text="This may take up to 1 minute"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
SendReports.contextType = ThemeContext;

export default SendReports;

SendReports.contextTypes = {
  reportFields: PropTypes.object,
};
