import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "./CompareDealChart.module.scss";
import { CHART_MAX_INDEX, LINES_COLORS } from "./constants";
import { get } from "lodash";
import { useMediaQuery } from "../../../utils/Helper";

function DealLineChart({ data, filter }) {
  const [chartData, setChartData] = useState([]);
  const category = get(filter, "category.value", "");
  const song = get(filter, "song", null);
  const isMobile = useMediaQuery("(max-width:767px)");
  useEffect(() => {
    const chartData = data.flatMap((deal) => {
      if (deal.cashflows[category] && deal.cashflows[category].n_new[song]) {
        const cumArtistCfs =
          deal.cashflows[category].n_new[song]["cum_artist_cfs"];
        return cumArtistCfs.map((value, index) => ({
          index,
          distributor: deal.title,
          cum_artist_cfs: value < 0 ? 0 : Math.floor(value),
        }));
      }
      return [];
    });
    setChartData(chartData);
  }, [data, category, song]);

  const maxIndex =
    chartData.length > 0 ? Math.max(...chartData.map((item) => item.index)) : 0;

  const currentYear = new Date().getFullYear();
  const yearsRange = [...Array(maxIndex + 1)].map((_, i) => {
    const shortYear = (currentYear + i).toString().slice(-2);
    return `${shortYear}'`;
  });

  const getInterval = (maxIndex) => {
    let interval;
    if (isMobile) {
      interval =
        maxIndex > CHART_MAX_INDEX[0] ? CHART_MAX_INDEX[1] : CHART_MAX_INDEX[2];
    } else {
      interval =
        maxIndex > CHART_MAX_INDEX[0] ? CHART_MAX_INDEX[2] : CHART_MAX_INDEX[3];
    }
    return interval;
  };

  const getXAxisFontSize = (maxIndex) => {
    let fontSize;
    if (isMobile) {
      fontSize = "12px";
    } else {
      fontSize = maxIndex > CHART_MAX_INDEX[0] ? "15px" : "20px";
    }
    return fontSize;
  };

  return (
    <ResponsiveContainer height={425}>
      <LineChart
        data={chartData}
        {...(isMobile ? { margin: { left: -5 } } : {})}
      >
        <XAxis
          dataKey="index"
          type="number"
          domain={[0, maxIndex]}
          tick={{
            fontSize: getXAxisFontSize(maxIndex),
            textAnchor: "end",
          }}
          tickFormatter={(index) => yearsRange[index]}
          tickLine={false}
          axisLine={false}
          interval={getInterval(maxIndex)}
          ticks={
            maxIndex > 20
              ? [...Array(maxIndex + 1).keys()].filter(
                  (_, i) => i % (isMobile ? 3 : 2) === 0,
                )
              : [...Array(maxIndex + 1).keys()]
          }
        />
        <YAxis
          allowDecimals={false}
          tickFormatter={(value) =>
            value > 0 ? `$${Math.floor(value / 1000)}K` : ""
          }
          label={{
            value: "Dollar Amount",
            className: styles.yAxisLabel,
            angle: -90,
            dx: isMobile ? -20 : -30,
          }}
          tickLine={false}
          axisLine={false}
          tick={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          labelFormatter={() => ""}
          formatter={(value, name) => [
            `$${new Intl.NumberFormat().format(value)}`,
            name,
          ]}
        />
        <Legend iconType="circle" wrapperStyle={{ marginTop: 20 }} />
        {Array.from(new Set(chartData.map((item) => item.distributor))).map(
          (distributor, idx) => (
            <Line
              key={distributor}
              type="monotone"
              dataKey="cum_artist_cfs"
              data={chartData.filter(
                (item) => item.distributor === distributor,
              )}
              stroke={(() => {
                if (idx === 0) return LINES_COLORS[0];
                if (idx === 1) return LINES_COLORS[1];
                return LINES_COLORS[2];
              })()}
              strokeWidth={4}
              dot={false}
              name={distributor}
            />
          ),
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default DealLineChart;
