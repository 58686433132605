import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { sliderStyle } from "./constants";

class InputRangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChangeRange = (value) => {
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  };

  onChangeCompleteRange = (value) => {
    this.props.onAfterChange && this.props.onAfterChange(value);
  };

  render() {
    return (
      <Slider
        min={this.props.min}
        max={this.props.max}
        marks={this.props.marks}
        step={this.props.step}
        dots={this.props.dots}
        defaultValue={this.props.defaultValue}
        value={this.state.value}
        onChange={this.onChangeRange}
        onAfterChange={this.onChangeCompleteRange}
        handleStyle={sliderStyle.handle}
        railStyle={sliderStyle.rail}
        trackStyle={sliderStyle.track}
        dotStyle={sliderStyle.dot}
        activeDotStyle={sliderStyle.activeDot}
        style={{
          backgroundColor: "transparent",
          marginBottom: 30,
        }}
        disabled={this.props.disabled}
      />
    );
  }
}

export default InputRangeSlider;
