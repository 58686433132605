import React, { Component } from "react";
import { get, debounce } from "lodash";
import ReactModal from "react-modal";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Form } from "formik";
import CustomLink from "../AdminDashboard/CustomLink";
import whiteLabelStyles from "../AdminDashboard/WhiteLabel/WhiteLabel.module.scss";
import styles from "../AdminDashboard/AdminDashboard.module.scss";
import Switch from "../AdminDashboard/Switch";
import LeftPanel from "../AdminDashboard/LeftPanel";
import {
  API_URL,
  PUBLISHER_API,
  PUBLISHERS_EDIT,
  PUBLISHERS_CREATE,
} from "../constants";
import { GetErrorMessage } from "../helper";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import request from "../../../utils/request";
import { PROFILE_ICON } from "../../../component/MaterialIcons/constants";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import FormikForm from "../../../component/Form/FormikForm";
import FormField from "../../../component/FormField/FormField";
import generalColors from "../../../styles/global.scss";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { getPartnerName } from "../../../component/ThemeManager/helper";
import { PUBLISHER_TABLE_COLUMN } from "./constants";

const useDefaultToken = false;
class Publishers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      publishers: [],
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 6,
      searchValue: "",
      switchModal: false,
      editAccount: "",
    };
    this.delayedSearchResults = debounce(this.getPublishers, 500);
  }

  componentDidMount() {
    document.title = `${getPartnerName()}: PRO / Publishers`;
    this.getPublishers();
  }

  handleAccount = (type, publisherData = null) => {
    if (type === "new") {
      this.props.history.push(PUBLISHERS_CREATE);
    } else {
      this.props.history.push({
        pathname: PUBLISHERS_EDIT,
        state: {
          publisherData,
        },
      });
    }
  };

  getPublishers = () => {
    const { activePage, searchValue } = this.state;
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${PUBLISHER_API}?limit=20&page=${activePage}&sort=name&searchPublisher=${searchValue}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            publishers: res.data.list,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total,
          });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  showSwitchModal = (publisher) => {
    this.setState({
      switchModal: true,
      editAccount: publisher,
    });
  };

  handlePageChange(pageNumber) {
    const { activePage } = this.state;
    if (activePage !== pageNumber) {
      this.setState(
        {
          activePage: pageNumber,
        },
        () => {
          this.getPublishers();
        },
      );
    }
  }

  editAccount = () => {
    this.setState({ loading: true });
    const payload = {
      id: get(this.state, "editAccount._id", ""),
      isActive: !get(this.state, "editAccount.isActive"),
    };
    const data = {
      method: "PATCH",
      body: payload,
    };
    const requestUrl = `${API_URL}${PUBLISHER_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          this.setState(
            { loading: false, editAccount: "", switchModal: false },
            () => {
              this.getPublishers();
            },
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false, editAccount: "", switchModal: false });
      });
  };

  switchModal = () => {
    const { switchModal } = this.state;
    const publisherName = get(this.state, "editAccount.name", "");
    return (
      <ReactModal
        isOpen={switchModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCancel}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          Are you sure you want to
          {get(this.state, "editAccount.isActive") ? " disable " : " enable "}
          publisher&nbsp;
          <span
            className={whiteLabelStyles.dialogSlugName}
          >{`${publisherName}`}</span>
          ?
        </h3>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            onClick={this.editAccount}
            data-testid="SwitchYes"
          >
            Yes
          </button>
          <button
            className={styles.secondary}
            onClick={() => {
              this.setState({ switchModal: false, editAccount: "" });
            }}
            data-testid="SwitchNo"
          >
            No
          </button>
        </div>
      </ReactModal>
    );
  };

  getPublishersProfileIcon = (publisher) => {
    const { publisherLogo } = publisher;
    return (
      <div className={whiteLabelStyles.whiteLabelProfile}>
        {publisherLogo ? (
          <Image src={publisherLogo} alt="logo" imageType={IMAGE_TYPE.LOGO} />
        ) : (
          <SvgIcons icon={PROFILE_ICON} className={styles.altImage} />
        )}
      </div>
    );
  };

  getPublishersName = (publisher) => {
    const isActive = get(publisher, "isActive");
    return (
      <CustomLink
        onClick={() => this.handleAccount("edit", publisher)}
        key={publisher._id}
        className={`${whiteLabelStyles.slugName} ${
          isActive ? "" : whiteLabelStyles.disabledSlugName
        }`}
        disabled={!isActive}
      >
        <p >{publisher.name}</p>
      </CustomLink>
    );
  };

  getPublishersType = (publisher) => {
    const isActive = get(publisher, "isActive");
    return (
      <p className={`${whiteLabelStyles.slugName} ${
        isActive ? "" : whiteLabelStyles.disabledSlugName
      }`}>{publisher.type}</p>
    );
  };

  getPublishersActions = (publisher) => {
    return (
      <Switch
        id={get(publisher, "_id")}
        title="Enable/Disable publisher"
        isOn={get(publisher, "isActive", false)}
        onColor={generalColors.primaryColor}
        data-test="switch"
        handleToggle={() => this.showSwitchModal(publisher)}
      />
    );
  };

  getPublishersRows = (publisher) => {
    return (
      <TableRow key={get(publisher, "_id")}>
        <TableCell></TableCell>
        <TableCell>{this.getPublishersProfileIcon(publisher)}</TableCell>
        <TableCell>{this.getPublishersName(publisher)}</TableCell>
        <TableCell>{this.getPublishersType(publisher)}</TableCell>
        <TableCell>{this.getPublishersActions(publisher)}</TableCell>
      </TableRow>
    );
  };

  renderPublishersTabularView = () => {
    const {
      publishers,
      activePage,
      itemsCountPerPage,
      totalItemsCount,
      pageRangeDisplayed,
    } = this.state;

    return (
      <center>
        <TableContainer
          component={Paper}
          className={whiteLabelStyles.whiteLabelTable}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {PUBLISHER_TABLE_COLUMN.map((column) => (
                  <TableCell {...column.props} key={column.key}>
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {publishers.map((publisher) =>
                  this.getPublishersRows(publisher),
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className={`${styles.adminPagination} ${whiteLabelStyles.whiteLabelPagination}`}
        >
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={this.handlePageChange.bind(this)}
            activeClass={styles.active}
          />
        </div>
      </center>
    );
  };

  render() {
    const { loading, searchValue } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>PRO / Publishers</h1>
            </div>
            <center>
              <div className={whiteLabelStyles.searchContainer}>
                <FormikForm
                  initialValues={{
                    whiteLabelSearchbar: "",
                  }}
                >
                  <Form>
                    <div
                      className={`form-group ${whiteLabelStyles.formMargin0}`}
                    >
                      <FormField
                        name="whiteLabelSearchbar"
                        placeholder="Search publishers"
                        type="input"
                        value={searchValue}
                        onChange={(e) => {
                          this.setState(
                            {
                              searchValue: e.target.value,
                            },
                            () => {
                              if (
                                this.state.searchValue.length > 2 ||
                                this.state.searchValue.length === 0
                              ) {
                                this.delayedSearchResults();
                              }
                            },
                          );
                        }}
                        data-testid="SearchValue"
                      />
                    </div>
                  </Form>
                </FormikForm>
                <button
                  onClick={() => this.handleAccount("new")}
                  data-testid="NewPublisher"
                >
                  Create New
                </button>
              </div>
            </center>
            {this.renderPublishersTabularView()}
            {this.switchModal()}
          </div>
        </div>
        {loading && <Loader />}
      </>
    );
  }
}

export default Publishers;
