import React, { Component } from "react";
import { get } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import styles from "./Dashboard.module.scss";
import Loader from "../../../component/Loader";
import { ReactComponent as ClockSvg } from "../../../assets/logos/clock-regular.svg";
import {
  API_URL,
  USER_API,
  ME,
  ARTIST_API,
  ACTIVATE_API,
  ADMIN_DASHBOARD,
  OFFER_CONTRACT_PDF,
  DEMO_DASHBOARD,
  HOME,
} from "../constants";
import CustomLink from "./CustomLink";
import request from "../../../utils/request";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../component/MaterialIcons/constants";
import AuthTokenService from "../../../utils/AuthTokenService";
import StorageService from "../../../utils/StorageService";
import ContactPopup from "../../../component/ContactPopup";
import {
  STATUS_BIG,
  STATUS_FAILED,
  STATUS_PENDING,
  STATUS_SMALL,
  STATUS_ERR,
  STATUS_NEW,
  DASHBOARD_ICONS,
} from "./constants";
import ArtistStatusInfoPopup from "./ArtistStatusInfoPopup";
import { GetErrorMessage } from "../helper";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import Image from "../../../component/Image";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import dataURItoBlob from "../MyAccount/dataURItoBlob";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { LINK_ANOTHER_ARTIST } from "../../../component/LoginHeader/constants";
import { FAST_FLOW_STEPS } from "../Auth/LinkAnotherArtist/constant";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userToken: "",
      artistList: [],
      newLinkCount: 0,
      isOpen: false,
      isPopupOpen: false,
      statusType: null,
      artistName: "",
      isDrawerOpen: false,
      currentId: 0,
    };
  }

  componentDidMount() {
    this.getLinkedArtists();
  }

  componentDidUpdate() {
    setTitle("Dashboard", this.context);
  }

  getLinkedArtists = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ME}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (
            res.data.role === "admin" &&
            !StorageService.get("customToken") &&
            this.props.history.location.artistId === undefined
          ) {
            const token = StorageService.get("jwtToken");
            StorageService.set("customToken", token, {
              hash: false,
            });
          }
          if (
            res.data.role === "admin" &&
            this.props.history.location.artistId === undefined
          ) {
            this.props.history.push(ADMIN_DASHBOARD);
          }
          if (res.data.role === "demo") {
            this.props.history.push(DEMO_DASHBOARD);
          }
          this.setState({
            artistList: res.data.linkedArtist,
            newLinkCount:
              res.data.maxLinkedArtist - res.data.linkedArtist.length,
            currentId: res.data.activeArtist?._id,
          });
          this.props.addArtist();
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setArtistToken = (artistId, isApproved = false) => {
    this.setState({ loading: true });
    const payload = { id: artistId };
    const data = {
      method: "POST",
      body: payload,
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVATE_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          AuthTokenService.storeToken(res.data.accessToken);
          this.props.toggleDrawer && this.props.toggleDrawer(false)();
          this.props.history.push(HOME);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
  };

  getOfferPdfData = (artistId) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: { artistId },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_CONTRACT_PDF}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (
            res.data.approved_offer_pdf &&
            get(res.data, "approved_offer_pdf", "").includes("base64")
          ) {
            const blob = dataURItoBlob(res.data.approved_offer_pdf);
            const pdfUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = pdfUrl;
            a.download = pdfUrl.split("/").pop() || "offer.pdf";
            a.style.display = "none";
            a.target = "_blank";
            document.body.append(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(pdfUrl);
          }
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  onOpenInfoPopup = (type, name) => {
    this.setState({ isPopupOpen: true, statusType: type, artistName: name });
  };

  onInfoPopupClose = () => {
    this.setState({ isPopupOpen: false });
  };

  fetchArtistLink = (artist, children, callback) => (
    <CustomLink
      className={`${styles.artistDetails}
                  ${
                    !artist.active || this.isLinkDisabled(artist)
                      ? styles.pending
                      : ""
                  }
                  ${
                    this.state.artistList.length &&
                    this.state.artistList.length > 6
                      ? styles.pending
                      : ""
                  }
                  ${
                    this.isActiveArtist(artist, this.state.currentId)
                      ? styles.active
                      : ""
                  } `}
      onClick={
        callback ? () => callback() : () => this.setArtistToken(artist._id)
      }
      key={artist._id}
      data-testid="artistClick"
    >
      <div className={styles.profile}>
        {get(
          artist,
          `profilePicture[${
            get(artist, "profilePicture", [{}]).length - 1
          }].url`,
          "",
        ) ? (
          <Image
            src={get(
              artist,
              `profilePicture[${
                get(artist, "profilePicture", [{}]).length - 1
              }].url`,
              "",
            )}
            alt="profile"
            imageType={IMAGE_TYPE.PROFILE}
          />
        ) : (
          <SvgIcons icon={PROFILE_ICON} className={styles.altImage} />
        )}
      </div>
      {children}
    </CustomLink>
  );

  fetchArtistItem = (artist) => {
    if (!artist.active) {
      if (artist.status === STATUS_FAILED) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>{artist.name}</div>
              <span>Selected</span>
            </p>{" "}
            <p className={styles.failedText}>
              There seems to be an issue gathering data for this artist. We are
              looking into this and will contact you shortly to resolve this
              issue.
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
      if (artist.status === STATUS_PENDING) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>
                <ClockSvg />
                {artist.name}
              </div>
              <span>Selected</span>
            </p>
            <p className={styles.subText}>
              We are gathering data for this artist. You will be notified when
              your estimate is ready.
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
      if ( [
        STATUS_SMALL,
        FAST_FLOW_STEPS["2SMALL"]
      ].indexOf(artist.status) !== -1) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>{artist.name}</div>
              <span>Selected</span>
            </p>
            <p className={styles.subText}>
              Our data indicates that the streaming history for {artist.name}{" "}
              does not have characteristics that our investors like to see to
              support an investment. &nbsp;
              <span
                data-testid="span-small"
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.onOpenInfoPopup(STATUS_SMALL, artist.name);
                }}
                className={styles.moreInfoText}
              >
                <DASHBOARD_ICONS.TOOLTIP />
              </span>
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
      if (
        [
          STATUS_BIG,
          FAST_FLOW_STEPS["2BIG"]
        ].indexOf(artist.status) !== -1) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>{artist.name}</div>
              <span>Selected</span>
            </p>
            <p className={styles.subText}>
              Congratulations! {artist.name} is in the top 1% globally in their
              streaming performance. &nbsp;
              <span
                data-testid="span-big"
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.onOpenInfoPopup(STATUS_BIG, artist.name);
                }}
                className={styles.moreInfoText}
              >
                <DASHBOARD_ICONS.TOOLTIP />
              </span>
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
      if (artist.status === STATUS_ERR) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>{artist.name}</div>
              <span>Selected</span>
            </p>
            <p className={styles.subText}>
              We are experiencing delays. Please bear with us.
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
      if (
        [
          STATUS_NEW,
          FAST_FLOW_STEPS["2NEW"]
        ].indexOf(artist.status) !== -1) {
        const htmlElem = (
          <div className={styles.failedSection}>
            <p className={styles.name}>
              <div>{artist.name}</div>
              <span>Selected</span>
            </p>{" "}
            <p className={styles.subText}>
              Our data indicates that the streaming history for {artist.name}{" "}
              does not have characteristics that our investors like to see to
              support an investment.{" "}
              <span
                data-testid="span-new"
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.onOpenInfoPopup(STATUS_NEW, artist.name);
                }}
                className={styles.moreInfoText}
              >
                <DASHBOARD_ICONS.TOOLTIP />
              </span>
            </p>
          </div>
        );
        return this.fetchArtistLink(artist, htmlElem);
      }
    }
    if (get(artist.offerStage, "defaultOffer.isAccepted", false)) {
      const htmlElem = (
        <div className={styles.failedSection}>
          <p className={styles.name}>
            <div>{artist.name}</div>
            <span>Selected</span>
          </p>{" "}
          <p className={styles.subText}>
            Congratulations! You have accepted an offer on{" "}
            {moment(get(artist.offerStage, "defaultOffer.acceptedDate")).format(
              "M/D/YYYY",
            )}
            .{" "}
            <span
              className={styles.moreInfoText}
              data-testid="downloadContract"
              onClick={() => this.getOfferPdfData(artist._id)}
            >
              Download offer summary
            </span>
          </p>
        </div>
      );
      return this.fetchArtistLink(artist, htmlElem);
    }
    if (this.isValidApproved(artist)) {
      const htmlElem = (
        <div className={styles.failedSection}>
          <p className={styles.name}>
            <div>{artist.name}</div>
            <span>Selected</span>
          </p>{" "}
          <p className={styles.subText}>
            Your offer is ready to be finalized. Valid through{" "}
            {moment(get(artist.offerStage, "defaultOffer.expire")).format(
              "M/D/YYYY",
            )}
            . <span className={styles.moreInfoText}>Customize final offer</span>
          </p>
        </div>
      );
      return this.fetchArtistLink(artist, htmlElem, () => {
        this.setArtistToken(artist._id, true);
      });
    }
    const htmlElem = (
      <p className={styles.name}>
        <div>{artist.name}</div>
        <span>Selected</span>
      </p>
    );
    return this.fetchArtistLink(artist, htmlElem);
  };

  isValidApproved = (artist) => get(artist, "offer_approved", false);

  isLinkDisabled = (artist) =>
    this.isValidApproved(artist) ||
    get(artist.offerStage, "defaultOffer.isAccepted", false);

  isActiveArtist = (artist, id) => {
    if (artist._id === id) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          <div
            className={styles.logoContainer}
            onClick={this.props.toggleDrawer && this.props.toggleDrawer(false)}
            data-testid="div-logo"
          >
            <div
              className={styles.closeIcon}
              onClick={
                this.props.toggleDrawer && this.props.toggleDrawer(false)
              }
              data-testid="div-icon"
            >
              <KeyboardBackspaceIcon />
            </div>
            <Image
              src={get(this.context, "emailLogo", "")}
              className={styles.logo}
              alt="Logo"
            />
          </div>
          <div className={styles.subContainer}>
            <div className={styles.title}>
              <h1>
                <span>My</span> Artists
              </h1>
            </div>
            <div className={styles.artistContainer}>
              {this.state.artistList.map((artist) => {
                return this.fetchArtistItem(artist);
              })}
            </div>
          </div>
        </div>
        {this.state.newLinkCount ? (
          <div
            onClick={() =>
              this.props.handleDrawer &&
              this.props.handleDrawer(LINK_ANOTHER_ARTIST)
            }
            className={`${styles.linkArtist}`}
            data-testid="add-div"
          >
            <div>+</div>
            <div>
              <p className={styles.name}>Link a new artist</p>
            </div>
          </div>
        ) : (
          <CustomLink disabled className={`${styles.disabledParent} `}>
            <div className={styles.disabledDiv}>
              <div className={styles.subTextDis}>
                You have reached the limit for the number of allowed artists for
                this account.{" "}
              </div>
              <div onClick={this.handleOpen} className={styles.contactUsDis}>
                Please contact us to request additional artist slots.
              </div>
            </div>
          </CustomLink>
        )}
        {this.state.loading && <Loader />}
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
        <ArtistStatusInfoPopup
          isPopupOpen={this.state.isPopupOpen}
          onInfoPopupClose={this.onInfoPopupClose}
          type={this.state.statusType}
          artistName={this.state.artistName}
        />
      </>
    );
  }
}

Dashboard.contextType = ThemeContext;
export default Dashboard;
