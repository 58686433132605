import React, { Component, createRef } from "react";
import { get, find } from "lodash";
import PropTypes from "prop-types";
import { Field, ErrorMessage, FieldArray } from "formik";
import Select from "react-select";
import SvgIcons from "../MaterialIcons/SvgIcons";
import {
  MINUS_ICON,
  PLUS_ICON,
  VISIBILITY,
  VISIBILITY_OFF,
  CHECK,
  CLOSE,
} from "../MaterialIcons/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";
import styles from "./FormField.module.scss";
import ScrollToError from "./ScrollToError";
import { getPartnerName, getSubDomain } from "../ThemeManager/helper";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import { CHORDCASH_PRIVACY } from "../../routes/pages/constants";
import { selectFormStyle, formStyle } from "../UploadFiles/selectStyle";
import { NumericFormat } from "react-number-format";

class FormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconPass: VISIBILITY,
      passType: "password",
      country: "",
      labelActive: false,
    };
    this.inputRef = createRef();
  }

  changeInputType = () => {
    this.state.passType === "password"
      ? this.setState({ passType: "text", iconPass: VISIBILITY_OFF })
      : this.setState({ passType: "password", iconPass: VISIBILITY });
  };

  focusChange = (e) => {
    if (get(e, "type") === "focus") {
      this.setState({ labelActive: true });
    } else if (get(e, "target.value")) {
      this.setState({ labelActive: true });
    } else {
      this.setState({ labelActive: false });
    }
  };

  /**
   * renderLabelUI - renders label in dom
   * @param {string} arrayLabel - this param is for array sub fields
   * @returns if label is present renders label otherwise nothing
   */
  renderLabelUI = (arrayLabel) => {
    const { label, as, icon, name, labelClassName } = this.props;
    if (label || arrayLabel) {
      return (
        <label
          className={`${!this.props.noFloating ? "floating" : ""} ${
            icon ? "icon" : ""
          } ${
            this.state.labelActive || ["phone", "select"].indexOf(as) !== -1
              ? "active"
              : ""
          } ${labelClassName ? labelClassName : ""}`}
          htmlFor={name}
        >
          {label || arrayLabel}
        </label>
      );
    }
    return <></>;
  };

  renderIcon = () =>
    this.props.icon ? (
      <span className={styles.componentIcon}>{this.props.icon}</span>
    ) : null;

  getSvgIcons = (error) => {
    return (
      <SvgIcons
        className={`${styles.validationIcon} ${error && styles.errorIcon}`}
        icon={error ? CLOSE : CHECK}
      />
    );
  };

  renderLengthUI = () => {
    const { maxCharacters, value } = this.props;
    return (
      <p
        className={styles.maxLengthClass}
      >{`${value.length}/${maxCharacters}`}</p>
    );
  };

  rendericonComponent = () => {
    const { icon } = this.props;
    return <span className={styles.iconComponent}>{icon}</span>;
  };

  /**
   * renderErrorUI
   * @param {string} name
   * @returns error message
   */
  renderErrorUI = (name) => {
    const { showErrorWithIcon, error, touched, value } = this.props;
    return showErrorWithIcon ? (
      touched && value && this.getSvgIcons(error)
    ) : (
      <ErrorMessage
        name={name}
        render={(error) =>
          typeof error === "string" && (
            <div
              className={`invalid-feedback d-flex position-absolute w-auto ${this.props.errorClass}`}
              style={{ marginRight: 40, lineHeight: "15px" }}
            >
              {error}
            </div>
          )
        }
      />
    );
  };
  /**
   * renderArrayField
   * @returns renders array field
   */
  renderArrayField = () => {
    const {
      arrayValueLabel,
      arrayLabel,
      addButtonText,
      fieldContainerClass,
      subFieldsContainerClass,
      addButtonClass,
      name,
      placeholder,
      type,
      subFieldInitialValue,
      maxSize,
    } = this.props;
    return (
      <FieldArray
        name={name}
        render={({ remove, push }) => {
          return (
            <div className={`${fieldContainerClass}`}>
              {arrayValueLabel.length > 0 &&
                arrayValueLabel.map((field, fieldIndex) => (
                  <div key={fieldIndex}>
                    <div className={`${subFieldsContainerClass} form-group`}>
                      <div className="mb-4">
                        <Field name={`${name}.${fieldIndex}`}>
                          {({ field }) => {
                            return (
                              <>
                                <input
                                  type={type}
                                  placeholder={`${placeholder} #${
                                    fieldIndex + 1
                                  }`}
                                  {...field}
                                />
                                {this.renderLabelUI(
                                  `${arrayLabel} #${fieldIndex + 1}*`,
                                )}
                                {this.renderErrorUI(`${name}.${fieldIndex}`)}
                              </>
                            );
                          }}
                        </Field>
                      </div>
                      <button type="button" onClick={() => remove(fieldIndex)}>
                        <SvgIcons icon={MINUS_ICON} />
                      </button>
                    </div>
                  </div>
                ))}
              {maxSize && arrayValueLabel.length < maxSize && (
                <button
                  type="button"
                  className={addButtonClass}
                  onClick={() => push(subFieldInitialValue)}
                >
                  <SvgIcons icon={PLUS_ICON} />
                  <span>{addButtonText}</span>
                </button>
              )}
            </div>
          );
        }}
      />
    );
  };
  getFieldComponent = () => {
    const {
      type,
      name,
      placeholder,
      className,
      style,
      as = "input",
      icon,
      onChangeHandle,
      isBoxInput,
      showErrorWithIcon,
      options = [],
      menuPosition,
      radioValueList,
      selectedCountry,
      handleRadioChange,
      val,
      ...otherProps
    } = this.props;
    switch (as) {
      case "select":
        return (
          <Field name={name} {...otherProps} data-testid="selectField">
            {({ field, form }) => (
              <Select
                options={options}
                placeholder={placeholder}
                value={find(options, {
                  value: field.value || this.props.value,
                })}
                onChange={(e) => {
                  form.setFieldValue(name, e.value);
                }}
                styles={isBoxInput ? formStyle : selectFormStyle}
                isDisabled={otherProps.disabled}
                className={`${isBoxInput && styles.boxInput}`}
                menuPosition={menuPosition && "fixed"}
                {...otherProps}
              />
            )}
          </Field>
        );
      case "textarea":
      case "input":
        return (
          <Field
            type={type}
            name={name}
            placeholder={placeholder}
            className={`${className} ${isBoxInput && styles.boxInput}`}
            style={style}
            as={as}
            {...otherProps}
          />
        );
      case "color":
        return (
          <Field name={name} {...otherProps}>
            {({ field }) => (
              <input
                type={type}
                className={className}
                style={style}
                placeholder={placeholder}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChangeHandle && onChangeHandle(e.target.value);
                }}
              />
            )}
          </Field>
        );
      case "icon":
        return (
          <Field name={name} {...otherProps}>
            {({ field }) => (
              <input
                className={`${className} ${styles.iconBg} ${
                  isBoxInput && styles.boxInput
                } ${showErrorWithIcon && styles.paddingValidation}`}
                style={{
                  backgroundImage: `url('${icon}')`,
                }}
                placeholder={placeholder}
                {...field}
              />
            )}
          </Field>
        );
      case "phone":
        return (
          <Field name={name} {...otherProps}>
            {({ field, form }) => (
              <>
                <PhoneInput
                  value={field.value || this.props.value}
                  dropdownClass={styles.dropdownClass}
                  containerClass={styles.containerClass}
                  country={`us`}
                  inputClass={styles.inputClass}
                  onChange={(number, country) => {
                    form.setFieldValue(as, number);
                    form.setFieldValue("countryCode", country.dialCode);
                  }}
                  onBlur={(e) => {
                    form.setFieldTouched(as, true);
                  }}
                  inputProps={{
                    className: styles.inputClass,
                    name: field.name,
                  }}
                  placeholder={placeholder}
                />
              </>
            )}
          </Field>
        );
      case "password":
        return (
          <Field name={name} {...otherProps}>
            {({ field }) => (
              <div className={styles.container}>
                <input
                  type={this.state.passType}
                  className={className}
                  style={style}
                  placeholder={placeholder}
                  onFocus={this.focusChange}
                  {...field}
                  onBlur={(e) => {
                    field.onBlur(e);
                    this.focusChange(e);
                  }}
                />
                <i onClick={this.changeInputType}>
                  <SvgIcons
                    icon={this.state.iconPass}
                    className={`${styles.icon}`}
                  />
                </i>
              </div>
            )}
          </Field>
        );
      case "array":
        return this.renderArrayField();
      case "checkbox":
        return (
          <Field
            type={type}
            name={name}
            className={className}
            placeholder={placeholder}
            style={style}
            as={as}
            {...otherProps}
          >
            <>
              <input
                type={as}
                className={`${className} "mr-3"`}
                name={name}
                id={`${name}`}
              />
              <label htmlFor={`${name}`} className="mr-1">
                I agree to {getPartnerName()}'s{" "}
                {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 ? (
                  <a href="/privacy" target="_blank">
                    privacy policy
                  </a>
                ) : (
                  <a
                    href={CHORDCASH_PRIVACY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                )}
              </label>
            </>
          </Field>
        );
      case "radio":
        return (
          <Field
            type={type}
            name={name}
            className={className}
            style={style}
            as={as}
            {...otherProps}
          >
            {({ field, form }) => (
              <>
                {radioValueList.map((list, index) => {
                  const randomId = Math.floor(Math.random() * 100);
                  return (
                    <div className={styles.radioInput}>
                      <input
                        {...field}
                        {...otherProps}
                        type={as}
                        className={`${className}`}
                        name={name}
                        id={`${list.label}${list.value}-${randomId}`}
                        value={list.value}
                        checked={field.value === list.value}
                        disabled={get(list, "disabled")}
                      />
                      <label
                        htmlFor={`${list.label}${list.value}-${randomId}`}
                        className={styles.label}
                      >
                        {list.label}
                      </label>
                    </div>
                  );
                })}
              </>
            )}
          </Field>
        );
      case "textMsg":
        return (
          <div id={otherProps.dataTestId} ref={this.inputRef}>
            <Field
              name={name}
              render={({ field, form: { errors, ...formikProps } }) => (
                <ScrollToError
                  name={name}
                  field={field}
                  errors={errors}
                  inputRef={this.inputRef}
                  {...formikProps}
                />
              )}
            ></Field>
          </div>
        );
      case "numeric":
        return (
          <Field name={name} {...otherProps}>
            {({ field, form }) => (
              <>
                <NumericFormat
                  value={field.value ?? this.props.value}
                  name={name}
                  className={`${className}`}
                  placeholder={placeholder}
                  onBlur={() => {
                    form.setFieldTouched(name, true);
                  }}
                  onValueChange={(val) => {
                    form.setFieldValue(name, val.floatValue ?? "");
                  }}
                  style={style}
                  thousandSeparator
                  valueIsNumericString
                  decimalScale={2}
                  {...otherProps}
                />
              </>
            )}
          </Field>
        );
      default:
        return null;
    }
  };

  render() {
    const { name, hideError } = this.props;
    return (
      <div
        className={`mb-4 ${this.props.containerClass} 
        ${styles.fieldContainer} ${
          this.props.icon ? styles.inputWithIcon : ""
        }`}
      >
        {this.getFieldComponent()}
        {this.props.maxCharacters && this.renderLengthUI()}
        {this.props.iconComponent && this.rendericonComponent()}
        {this.renderLabelUI()}
        {get(this.props, "renderHelperLabel")}
        {!hideError && this.renderErrorUI(name)}
        {this.renderIcon()}
      </div>
    );
  }
}

FormField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  as: PropTypes.string,
  options: PropTypes.array,
};

export default FormField;
