import React from "react";
import { get } from "lodash";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import { ReactComponent as ClipBoardIcon } from "../../../img/streamingData.svg";
import {
  NAVIGATION_TEXTS,
  NAV_STATUS,
} from "../../../component/HorizontalMenu/constants";
import {
  LINK_SPOTIFY,
  API_URL,
  USER_API,
  ARTIST_API,
  UPDATE_SPOTIFY_PROFILE_API,
  GENERATING_OFFERS,
  HOME,
} from "../constants";
import styles from "./SpotifyForArtists.module.scss";
import Loader from "../../../component/Loader";
import { ReactComponent as SpotifyLogo } from "../../../assets/logos/spotify-full-logo.svg";
import Image from "../../../component/Image";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";

class SpotifyForArtists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: {},
      selectedArtist: null,
    };
    this.statusDataCallback = null;
  }

  componentDidUpdate() {
    setTitle("Spotify For Artists", this.context);
  }

  linkStatusRender = () => {
    switch (get(this.state.status, "spotifyStatus")) {
      case NAV_STATUS.LINK_PENDING:
        return (
          <p className={styles.pending}>
            Connection {NAVIGATION_TEXTS[LINK_SPOTIFY].status[1]}
          </p>
        );
      case NAV_STATUS.LINKED:
        return (
          <p className={styles.success}>
            {NAVIGATION_TEXTS[LINK_SPOTIFY].status[2]}
          </p>
        );
      default:
        return (
          <>
            <p className={styles.isLargeScreen}>
              {NAVIGATION_TEXTS[LINK_SPOTIFY].status[0]}
            </p>
            <p className={styles.isSmallScreen}>
              We only need “Read Only” Access for a temporary period
            </p>
          </>
        );
    }
  };

  setStatusValues = (status, callback) => {
    this.setState({ status: { ...status }, loading: false });
    this.statusDataCallback = callback;
    status.isFastFlow && this.props.history.replace(HOME);
  };

  spotifyConnectionRequest = (val) => {
    const payload = val
      ? { finishingTouch: true, spotifyStatus: "Request Spotify Connection" }
      : { finishingTouch: true };
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_SPOTIFY_PROFILE_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(GENERATING_OFFERS);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  isRequestedOrLinked = () =>
    [NAV_STATUS.LINK_PENDING, NAV_STATUS.LINKED].indexOf(
      this.state.status.spotifyStatus,
    ) !== -1;

  updateUserData = (val) => {
    this.setState({ selectedArtist: val });
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu
          {...this.props}
          getStatus={this.setStatusValues}
          updateUserData={this.updateUserData}
        />
        <div className={styles.funnelContainer}>
          <LoginHeader
            headerTitle={NAVIGATION_TEXTS[LINK_SPOTIFY].title}
            selectedArtist={this.state.selectedArtist}
          />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.pageHeadContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.title}>
                      <h1>Link Artist Account</h1>
                    </div>
                  </div>
                  <div className={styles.rightInfoText}>
                    <ClipBoardIcon className={styles.clipBoardIcon} />
                    <span>
                      Granting temporary read-only access to Spotify for Artists
                      helps us get you the best deal possible, fast.
                    </span>
                  </div>
                </div>
                <div className={styles.container}>
                  <div className={styles.statusCardContainer}>
                    <div className={styles.connectionContainer}>
                      <div className={styles.alignmentContainer}>
                        <div className={styles.spotifyCardContainer}>
                          <div className={styles.spotifyCard}>
                            <SpotifyLogo />
                            <span> for Artists</span>
                          </div>
                          {!this.isRequestedOrLinked() && (
                            <p className={styles.spotifyInfoText}>
                              We only need “Read Only” Access for a temporary
                              period
                            </p>
                          )}
                        </div>
                        <div className={styles.connectionLineContainer}>
                          <div className={styles.connectionLine} />
                          {this.linkStatusRender()}
                        </div>
                        <div className={styles.labelCardContainer}>
                          <div className={styles.labelCard}>
                            <Image
                              src={get(this.context, "appLogo")}
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.isRequestedOrLinked() && (
                      <div className={styles.btnContainer}>
                        <button
                          className={styles.skipBtn}
                          onClick={() => this.spotifyConnectionRequest(false)}
                          data-testid="skip-btn"
                        >
                          Skip for now
                        </button>
                        <button
                          className={styles.primaryBtn}
                          onClick={() => this.spotifyConnectionRequest(true)}
                          data-testid="connection-btn"
                        >
                          Request a connection
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading && <Loader light backgroundNone />}
          </div>
        </div>
      </div>
    );
  }
}

export default SpotifyForArtists;
SpotifyForArtists.contextType = ThemeContext;
