import React, { Component } from "react";
import { get } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoginHeader from "../../../component/LoginHeader";
import styles from "./ManageBlacklist.module.scss";
import Loader from "../../../component/Loader";
import ReactModal from "react-modal";
import { API_URL, ADMIN_IP_API } from "../constants";
import request from "../../../utils/request";
import Pagination from "react-js-pagination";
import Navigator from "../../../component/Navigation/Navigator";
import FormikForm from "../../../component/Form/FormikForm";
import { Form } from "formik";
import FormField from "../../../component/FormField/FormField";
import { IP_REGEX } from "../Auth/constants";
import LeftPanel from "../AdminDashboard/LeftPanel";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";

class ManageBlacklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipList: [],
      ipDetailModal: false,
      activePage: 1,
      selectedIp: "",
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      pageRangeDisplayed: 6,
    };
  }

  componentDidMount() {
    this.getIpBlacklist();
  }

  componentDidUpdate() {
    setTitle("Manage Blacklist", this.context);
  }

  getIpBlacklist = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN_IP_API}?limit=${
      this.state.itemsCountPerPage
    }&page=${this.state.activePage - 1}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            ipList: res.data.list,
            totalItemsCount: res.data.total,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(get(err, "message"));
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, this.getIpBlacklist);
  }

  handleDelete = () => {
    this.setState({ loading: true, ipDetailModal: false });
    const data = {
      method: "DELETE",
    };
    const requestUrl = `${API_URL}${ADMIN_IP_API}?ip=${this.state.selectedIp}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.getIpBlacklist();
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(get(err, "message"));
      });
  };

  viewIpModal = () => (
    <ReactModal
      isOpen={this.state.ipDetailModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCloseIpDetails}
      className={styles.deleteModal}
      overlayClassName={styles.modalOverlay}
    >
      <h3 className={`${styles.modalContainer} `}>
        Are you sure you want to remove IP {this.state.selectedIp} from
        blacklist?
      </h3>
      <div className={`${styles.modalBtnContainer} ${styles.modalContainer}`}>
        <button className={styles.primary} onClick={this.handleDelete}>
          Yes
        </button>
        <button
          className={styles.secondary}
          onClick={this.handleCloseIpDetails}
        >
          No
        </button>
      </div>
    </ReactModal>
  );

  handleOpenIpDetails = (ipAddress) =>
    this.setState({ ipDetailModal: true, selectedIp: ipAddress });

  handleCloseIpDetails = () =>
    this.setState({ ipDetailModal: false, selectedIp: "" });

  validationSchema = Yup.object().shape({
    ipAddress: Yup.string()
      .required(" ")
      .matches(IP_REGEX, "Please enter valid IP address")
      .label("IP address"),
  });

  handleAddIp = (values, actions) => {
    this.setState({ loading: true });
    const payload = {
      ip: values.ipAddress,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${ADMIN_IP_API}`;
    request(requestUrl, data, false)
      .then((res) => {
        actions.resetForm();
        this.setState({ loading: false });
        if (res.status) {
          this.getIpBlacklist();
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(get(err, "message"));
      });
  };

  rederIpList = () => {
    return (
      <>
        <div className={styles.ipListContainer}>
          <div className={styles.addIp}>
            <FormikForm
              initialValues={{
                ipAddress: "",
              }}
              validationSchema={this.validationSchema}
              onSubmit={this.handleAddIp}
            >
              <Form>
                <div className={`form-group ${styles.formMargin0} mb-0 py-0`}>
                  <FormField
                    name="ipAddress"
                    placeholder="Enter IP address"
                    type="text"
                    label="Add IP to block"
                  />
                </div>
                <div className="form-group mb-0 p-0">
                  <button type="submit" disabled={this.state.loading}>
                    Add
                  </button>
                </div>
              </Form>
            </FormikForm>
          </div>
          {this.state.ipList.length ? (
            <div className={styles.ipListBody}>
              {this.state.ipList.map((ipAddress) => (
                <div className={styles.ipList} key={ipAddress._id}>
                  <p className={styles.ipText}>{ipAddress.ip}</p>
                  <p
                    className={styles.btnLink}
                    onClick={() => {
                      this.handleOpenIpDetails(ipAddress.ip);
                    }}
                  >
                    <i
                      className={`fa fa-trash fa-lg ${styles.deleteIcon}`}
                      title="Delete"
                    ></i>{" "}
                    Remove
                  </p>
                </div>
              ))}
              <div className={styles.adminPagination}>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onChange={this.handlePageChange.bind(this)}
                  activeClass={styles.active}
                />
              </div>
            </div>
          ) : (
            <p className={`m-0 p-5`}>No Data to show</p>
          )}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div className={`${styles.title} ${styles.innerTitle}`}>
              <h1>Manage Blacklist</h1>
            </div>
            {this.rederIpList()}
          </div>
        </div>
        {this.viewIpModal()}
        {this.state.loading && <Loader />}
      </>
    );
  }
}

ManageBlacklist.contextType = ThemeContext;
export default ManageBlacklist;
