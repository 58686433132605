import React, { Component } from "react";
import styles from "../Misc.module.scss";
import ContactPopup from "../../../../component/ContactPopup";
import {
  getPartnerName,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class ErrorForbidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidUpdate() {
    setTitle("Forbidden", this.context);
  }
  handleOpen = (e) => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };
  render() {
    return (
      <>
        <div className="theme-bg-dark">
          <div className={styles.errorContainer}>
            <div>
              <h1>403</h1>
            </div>
            <div>
              <h3>Forbidden</h3>
            </div>
            <a href="/" onClick={this.handleOpen} className={styles.homeLink}>
              Contact {getPartnerName()}
            </a>
            <a
              href={`https://${getPartnerName()}.com`}
              className={styles.homeLink}
            >
              Return Home
            </a>
          </div>
        </div>
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
      </>
    );
  }
}

ErrorForbidden.contextType = ThemeContext;
export default ErrorForbidden;
