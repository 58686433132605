import { ReactComponent as ReviewLogo } from "../../../assets/logos/TimelineButton.svg";
import { ReactComponent as GroupMail } from "../../../assets/logos/GroupMail.svg";
import { ReactComponent as GroupShare } from "../../../assets/logos/GroupShare.svg";
import { ReactComponent as GroupHammer } from "../../../assets/logos/GroupHammer.svg";
import { ReactComponent as GroupPen } from "../../../assets/logos/GroupPen.svg";
import { ReactComponent as GroupDollar } from "../../../assets/logos/GroupDollar.svg";

export const FUNDING_EARLY_TOOLTIP =
  "Timing of your advance payment may vary based on your deal and your distributor";
export const ADJUST_DEAL_TOOLTIP =
  "Choose this option if you'd like to see other confirmed offers.";
export const FINAL_CHECKOUT = "Confirmed Offers";

export const ASSETS = {
  ReviewLogo,
  GroupMail,
  GroupDollar,
  GroupShare,
  GroupPen,
  GroupHammer,
};

export const INITIAL_TOOLTIP_STATE = {
  setOne: false,
  setTwo: false,
  setThree: false,
  setFour: false,
  setFive: false,
};
