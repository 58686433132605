import React from "react";

const CustomLink = ({
  disabled,
  children,
  className,
  onClick,
  ...otherProps
}) => {
  return (
    <>
      {disabled ? (
        <div className={className} {...otherProps}>
          {children}
        </div>
      ) : (
        <div className={className} onClick={onClick} {...otherProps}>
          {children}
        </div>
      )}
    </>
  );
};

export default CustomLink;
