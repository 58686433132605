import { ReactComponent as Tooltip } from "../../../assets/logos/np.svg";

export const STATUS_FAILED = "failed";
export const STATUS_BIG = "big";
export const STATUS_SMALL = "small";
export const STATUS_PENDING = "pending";
export const STATUS_NEW = "new";
export const STATUS_ERR = "error";
export const STATUS_IN_RANGE_1 = "in_range1";
export const STATUS_IN_RANGE_2 = "in_range2";
export const STATUS_IN_RANGE_3 = "in_range3";
export const STATUS_IN_RANGE_4 = "in_range4";
export const STATUS_COMPLETED = "completed";
export const STATUS_TOO_BIG = "too_big";
export const STATUS_TOO_SMALL = "too_small";

export const DASHBOARD_ICONS = {
  TOOLTIP: Tooltip,
};
