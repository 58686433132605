import React from "react";
import { get } from "lodash";
import styles from "./SetTargetAdvance/SetTargetAdvance.module.scss";
import moreStyles from "./GetEvenMore/GetEvenMore.module.scss";
import { ReactComponent as CheckLogo } from "../../../assets/logos/market-right-tick-logo.svg";
import { FORM_SUBMIT_SUCCESS } from "./ArtistOnboarding/constants";
import { ReactComponent as MarketStoreIcon } from "../../../assets/logos/marketplace.svg";
import {
  API_URL,
  ARTIST_API,
  OFFER_SUMMARY,
  UPDATE_MARKETING_STATUS,
  USER_API,
  ONBOARDING_FORM_REDIRECTION,
} from "../constants";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import request from "../../../utils/request";

export const renderChancesOfOffer = (targetValue, offer) => {
  if (targetValue === get(offer, "total_advance")) {
    return (
      <div className={`${styles.chancesContainer} ${styles.guaranteed}`}>
        <CheckLogo />
        <p>Guaranteed</p>
      </div>
    );
  }
  if (targetValue < get(offer, "minimum_breakpoint_one")) {
    return (
      <div className={`${styles.chancesContainer} ${styles.pointOne}`}>
        <CheckLogo />
        <p>Less than 80% chance of success</p>
      </div>
    );
  }
  if (targetValue < get(offer, "minimum_breakpoint_two")) {
    return (
      <div className={`${styles.chancesContainer} ${styles.pointTwo}`}>
        <CheckLogo />
        <p>Less than 50% chance of success</p>
      </div>
    );
  }
  if (targetValue < get(offer, "minimum_breakpoint_three")) {
    return (
      <div className={`${styles.chancesContainer} ${styles.pointThree}`}>
        <CheckLogo />
        <p>Less than 20% chance of success</p>
      </div>
    );
  }
  return (
    <div className={`${styles.chancesContainer} ${styles.pointFour}`}>
      <CheckLogo />
      <p>Less than 10% chance of success</p>
    </div>
  );
};

export const OnboardingEmbedConditional = (props) =>
  window.location.pathname === ONBOARDING_FORM_REDIRECTION ? (
    <div className={styles.emptyScreen}>
      {window.parent.postMessage(FORM_SUBMIT_SUCCESS, "*")}
    </div>
  ) : (
    props.children
  );

export function nothanksNavigationStatusUpdate(payload, redirectScreen) {
  this.setState({ loading: true });
  const data = {
    method: "POST",
    body: payload,
  };
  const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
  request(requestUrl, data)
    .then((res) => {
      this.setState({ loading: false });
      if (res.status) {
        this.props.history.push(redirectScreen);
        return true;
      }
      toast.error(get(res, "message"));
    })
    .catch((err) => {
      this.setState({ loading: false });
      toast.error(
        (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
        {
          className: "toast_hidden",
        },
      );
    });
}

export const GuaranteedModal = ({
  isOpen,
  toggleMarketPopup,
  navigationStatusUpdate,
  context,
  totalAdvance,
}) => (
  <ReactModal
    isOpen={isOpen}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    onRequestClose={toggleMarketPopup}
    className={moreStyles.marketModal}
    overlayClassName={moreStyles.modalOverlay}
  >
    <div className={moreStyles.logoContainer}>
      <MarketStoreIcon />
    </div>
    <div className={moreStyles.header}>
      <p>
        2 in 3 artists get more money in the <span>Investor Network</span>
      </p>
    </div>
    <div className={moreStyles.infoText}>
      <p>
        {get(context, "displayName", "beatBread")} has relationships with a
        network of music insiders, qualified investors and large institutions
        who invest in music and may be willing to offer you more than the base
        advance amount generated by our algorithm.
      </p>
    </div>
    <div className={moreStyles.btnContainer}>
      <button onClick={toggleMarketPopup}>Back to Investor Network</button>
      <button
        className={moreStyles.linkBtn}
        data-testid="navigationOfferSummary"
        onClick={() => {
          navigationStatusUpdate({ finalCheckout: true }, OFFER_SUMMARY);
        }}
      >
        No thanks, I’ll take the confirmed offer
      </button>
    </div>
  </ReactModal>
);
