import React, { Component } from "react";
import styles from "./WaitingRoom.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import { ARTISTS_RANGE } from "../AdminDashboard/constants";

import Loader from "../../../component/Loader";
import { ReactComponent as SorryIcon } from "../../../assets/logos/sorry.svg";
import { ReactComponent as GraphSvg } from "../../../assets/logos/graph.svg";
import HorizontalMenu from "../../../component/HorizontalMenu";
import OverLay from "../../../component/OverLay";
import MyAccount from "../MyAccount/MyAccount";
import classes from "../YourAdvance/YourAdvance.module.scss";
import loaderIcon from "../../../img/loading-bars.gif";
import { logoutFromWaitingRoom } from "../../../utils/Helper";
import ContactPopup from "../../../component/ContactPopup";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { getSubDomain, setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { get } from "lodash";

class WaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      loading: false,
      selectedArtistName: "",
      selectedArtistStatus: null,
      isDrawerOpen: false,
      profileStatus: "",
      isOpen: false,
    };
  }

  componentDidUpdate() {
    setTitle("Generating Data", this.context);
  }
  handleClose = () => {
    this.setState({ isOpen: false });
  };
  handleContactPopup = () => {
    this.setState({ isOpen: true });
  };

  formContainerFn = () => {
    return (
      <div className={styles.formContainer}>
        <div className={styles.formField}>
          <span className={styles.span}>Email</span>
          <span className={styles.email}>{this.state.email}</span>
        </div>
        <div className={styles.btnContainer}>
          <button
            type="button"
            className={styles.btnColor}
            data-testid="confirm"
            onClick={this.toggleDrawer(true)}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  artistWaitingRoom = (selectedArtistName, selectedArtistStatus) => {
    switch (selectedArtistStatus) {
      case ARTISTS_RANGE.TOO_SMALL:
      case ARTISTS_RANGE.SMALL:
      case ARTISTS_RANGE.NEW:
        if(this.props?.fireEvent) {
          this.props.fireEvent('artistTooSmall', {case: selectedArtistStatus});
        }
        // segment.track.artistTooSmall({case: selectedArtistStatus});
        return (
          <div className={styles.overViewContainers}>
            <div
              className={`${styles.overViewContainer} ${styles.smallOverview}`}
            >
              <div className={styles.subOverView}>
                <div className={`${styles.sorryIcon} ${styles.smileIcon}`}>
                  <SorryIcon />
                </div>

                <p className={styles.smallTitle}>
                  If you have generated a minimum of US $100 per month during
                  the last 6 months, please follow up with us via email, and
                  include the following:
                </p>
                <ul>
                  <li>Your name</li>
                  <li>
                    Your “artist name” as it appears on major streaming services{" "}
                  </li>
                  <li>
                    Your detailed distribution reports from the last 6 months
                    showing total streaming revenues and stream counts (xls. Csv
                    txt files only. We can’t read screenshots){" "}
                  </li>
                </ul>
                <p>
                  <i>
                    Due to the volume of emails we receive, inquiries without
                    this information are unlikely to receive a response.
                  </i>
                </p>
              </div>
            </div>

            <span className={styles.separator}></span>

            <div className={styles.inputContainer}>
              <div className={styles.subOverView}>
                <p>
                  We’ll email you at the address below when better funding
                  options become available.
                </p>

                {this.formContainerFn()}
              </div>
            </div>
          </div>
        );
      case ARTISTS_RANGE.FAILED:
        if(this.props?.fireEvent) {
          this.props.fireEvent('artistFailed', {case: selectedArtistStatus});
        }
        // segment.track.artistFailed({case: selectedArtistStatus});
      case ARTISTS_RANGE.PENDING:
        if (selectedArtistStatus === ARTISTS_RANGE.PENDING) {
          if(this.props?.fireEvent) {
            this.props.fireEvent('offersPending', {case: selectedArtistStatus});
          }
          // segment.track.offersPending({case: selectedArtistStatus});
        }
        return (
          <>
            <div className={styles.overViewContainers}>
              <div
                className={`${styles.overViewContainer} ${styles.overView} ${styles.publicProfile}`}
              >
                <div
                  className={`${styles.subOverView} ${styles.pendingSubOverView}`}
                >
                  <div className={styles.loaderIcon}>
                    {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 && (
                      <img src={loaderIcon} alt="loaderIcon" />
                    )}
                  </div>
                  <h2>Our system is gathering your streaming data.</h2>
                  <p className={styles.pendingPara}>
                    We’ll send you an email at{" "}
                    <span className={styles.artistName}>
                      {" "}
                      {this.state.email}
                    </span>{" "}
                    as soon as it is ready.
                  </p>
                  <div className={styles.pendingBtnContainer}>
                    <button
                      type="button"
                      className={styles.btnColor}
                      data-testid="confirm"
                      onClick={this.toggleDrawer(true)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>

              <span className={styles.separator}></span>

              <div className={styles.inputContainer}>
                {this.state.profileStatus === true ? (
                  <div className={styles.subOverView}>
                    <h2>Next Steps…</h2>
                    <div className={styles.stepsListing}>
                      <ol>
                        <li>Verify the data we provided is correct</li>
                        <li>
                          Customize the Advance Structure that is right for you
                        </li>
                        <li>Upload your distribution reports for review</li>
                        <li>Receive your confirmed offers</li>
                      </ol>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${styles.subOverView} ${styles.secondOverView}`}
                  >
                    <div className={styles.pendingButtonContainer}>
                      <button
                        className={`${styles.updateLinkBtn} ${styles.noThanksBtn}`}
                        data-testid="logOutBtn"
                        onClick={() => {
                          logoutFromWaitingRoom.call(this, true);
                        }}
                      >
                        LOG OUT
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      case ARTISTS_RANGE.TOO_BIG:
      case ARTISTS_RANGE.BIG:
        if(this.props?.fireEvent) {
          this.props.fireEvent('artistTooBig', {case: selectedArtistStatus});
        }
        // segment.track.artistTooBig({case: selectedArtistStatus});
        return (
          <div className={styles.overViewContainers}>
            <div className={styles.overViewContainer}>
              <div className={`${styles.subOverView}  ${styles.headingBig}`}>
                <div className={styles.sorryIcon}>
                  <GraphSvg />
                </div>
                <h2>Tell us more</h2>
                <p>
                  <span className={styles.artistName}>
                    {" "}
                    {`${selectedArtistName}`}
                  </span>{" "}
                  is in the top 1% globally in their streaming performance. We
                  find that advance structures for artists this large tend to be
                  complex, so to get the most out of our investor network,{" "}
                  {
                    <span
                      onClick={this.handleContactPopup}
                      className={styles.contactUsLink}
                    >
                      we’ll need to work together
                    </span>
                  }{" "}
                  to walk through the details of your advance and customize your
                  offer.
                </p>
              </div>
            </div>

            <span className={styles.separator}></span>

            <div className={styles.inputContainer}>
              <div className={`${styles.subOverView}  ${styles.headingBig}`}>
                <h2>Let’s chat!</h2>
                <p>
                  A {get(this.context, "displayName")} expert will reach out to
                  the email address below to help you customize your advance
                  shortly.
                </p>

                {this.formContainerFn()}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  toggleDrawer = (open) => () => {
    this.setState({
      isDrawerOpen: open,
    });
  };
  updateUserData = (selectedArtist, userEmail, userPhone) => {
    this.setState({
      email: userEmail,
      selectedArtistName: selectedArtist.name,
      selectedArtistStatus: selectedArtist.status,
    });
  };
  getSocialProfileStatus = (profileStatus) => {
    this.setState({
      profileStatus: get(profileStatus, "publicProfile", false),
    });
  };
  drawerComponent = (callback) => {
    this.callbackComponent = callback;
  };
  headerTitle = () => {
    switch (this.state.selectedArtistStatus) {
      case ARTISTS_RANGE.SMALL:
      case ARTISTS_RANGE.NEW:
        return "Advance Not Available";
      case ARTISTS_RANGE.BIG:
        return "Let’s Connect";
      default:
        return "Generating Data";
    }
  };

  render() {
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu
          {...this.props}
          getStatus={(profileStatus) =>
            this.getSocialProfileStatus(profileStatus)
          }
          updateUserData={this.updateUserData}
        />
        <div className={classes.funnelContainer}>
          <LoginHeader
            headerTitle={this.headerTitle()}
            drawerComponent={this.drawerComponent}
          />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  {this.artistWaitingRoom(
                    this.state.selectedArtistName,
                    this.state.selectedArtistStatus,
                  )}
                </div>

                <OverLay
                  anchor={"left"}
                  component={
                    <MyAccount
                      toggleDrawer={this.toggleDrawer}
                      handleDrawer={this.callbackComponent}
                      {...this.props}
                    />
                  }
                  open={this.state.isDrawerOpen}
                  onClose={this.toggleDrawer(false)}
                />
                <ContactPopup
                  isOpen={this.state.isOpen}
                  onRequestClose={this.handleClose}
                />
                {this.state.loading && <Loader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
WaitingRoom.contextType = ThemeContext;
export default WaitingRoom;
