import React, { Component } from "react";
import { get } from "lodash";
import { Form } from "formik";
import * as Yup from "yup";
import ReactTooltip from "react-tooltip";

import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  FORGOT_PASS_API,
  CODE_API,
  CHANGE_PASS_API,
  LOGIN,
} from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import { PASS_UPPER_LOWER_REGEX, PASS_NUM_SPECIAL_REGEX } from "../constants";
import request from "../../../../utils/request";
import { GetErrorMessage, getErrorMessage } from "../../helper";
import { userExists } from "../../../../utils/Helper";
import LoginHeader from "../../../../component/LoginHeader";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import LoginSideBar from "../../../../component/LoginSideBar";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: false,
      loading: false,
      passwordKey: "",
      error: "",
    };
  }

  componentDidMount() {
    this.checkPasswordKey();
  }

  componentDidUpdate() {
    setTitle("Change Password", this.context);
  }

  checkPasswordKey = () => {
    this.setState({ initialLoading: true });

    const payload = {
      code: get(this.props, "match.params.key"),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FORGOT_PASS_API}${CODE_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ initialLoading: false });
        if (!res.status) {
          this.setState({ error: get(res, "message") });
          toast.error(get(res, "message"));
        }
      })
      .catch((err) => {
        this.setState({ initialLoading: false, error: getErrorMessage(err) });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(8, "Please enter valid password between 8 to 20 characters")
      .max(20, "Please enter valid password between 8 to 20 characters")
      .matches(
        PASS_UPPER_LOWER_REGEX,
        "Password should have at least 1 uppercase letter & 1 lowercase letter.",
      )
      .matches(
        PASS_NUM_SPECIAL_REGEX,
        "Password should have at least 1 number & 1 special character.",
      )
      .label("Password"),
  });

  handelSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      code: get(this.props, "match.params.key"),
      password: values.password,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FORGOT_PASS_API}${CHANGE_PASS_API}`;

    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(res.message);
          this.props.history.push(LOGIN);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getPasswordForm = () =>
    this.state.error ? (
      <p className={`${styles.subTitle} text-danger`}>{this.state.error}</p>
    ) : (
      <>
        <div className="form-group mb-0">
          <i
            className={`infoIcon ${styles.infoIcon}`}
            data-tip
            data-for="info"
          ></i>
          <ReactTooltip
            className={styles.tooltip}
            id="info"
            type="dark"
            effect="solid"
            place="top"
          >
            <div className={`textSize14`}>
              <span className="font-weight-bold">
                Password should fulfil following conditions:
              </span>
              <li>Minimum 8 and Maximum 20 characters</li>
              <li>
                At least 1 uppercase and 1 lowercase alphabet is mandatory
              </li>
              <li>At least 1 special character and 1 number is mandatory</li>
            </div>
          </ReactTooltip>
          <FormField
            name="password"
            placeholder="New Password"
            as="password"
            label="Enter new password"
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            disabled={this.state.loading}
            className={`${styles.btn} ${styles.forgotResetPasswordBtn}`}
          >
            Save new password
          </button>
        </div>
      </>
    );

  render() {
    return (
      <div className={styles.layoutContainer}>
        <LoginSideBar  {...this.props} />
        <div className={styles.funnelContainer}>
          {userExists() ? <LoginHeader /> : <Header {...this.props} />}
          <div className={styles.loginMainContent}>
            <div className={styles.loginContent}>
              <FormikForm
                initialValues={{ password: "" }}
                validationSchema={this.validationSchema}
                onSubmit={this.handelSubmit}
              >
                <div className={`${styles.formContainer} ${styles.formWithoutLogo}`}>
                  <Form>
                    <h1>Change your password</h1>
                    {!this.state.initialLoading && this.getPasswordForm()}
                  </Form>
                </div>
              </FormikForm>
            </div>
          </div>
        </div>
        {(this.state.loading || this.state.initialLoading) && <Loader />}
      </div>
    );
  }
}
ChangePassword.contextType = ThemeContext;
export default ChangePassword;
