import React from "react";
import { IMAGE_TYPE } from "./constants";
import bbLogo from "../../img/icons/bb-blue.png";
import fallbackProfilePicture from "../../img/fallbackProfilePicture.svg";
import fallbackCCProfilePicture from "../../img/fallbackCCProfilePicture.png";
import { getPartnerName } from "../ThemeManager/helper";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoadError: false,
    };
  }

  /**
   * onImageError
   * @param {object} e
   * sets fallback profile image as src
   */
  onImageError = (e) => {
    const { imageLoadError } = this.state;
    const { imageType } = this.props;
    if (imageLoadError === false) {
      if (imageType === IMAGE_TYPE.PROFILE) {
        e.target.src = fallbackProfilePicture;
      } else {
        e.target.src =
          getPartnerName() === "chordCash" ? fallbackCCProfilePicture : bbLogo;
      }
      this.setState({
        imageLoadError: true,
      });
    }
  };

  render() {
    const { src, alt, className, testId } = this.props;
    return (
      <img
        src={
          src ||
          (this.props.imageType === IMAGE_TYPE.PROFILE
            ? fallbackProfilePicture
            : bbLogo)
        }
        alt={alt}
        onError={this.onImageError}
        className={className}
        data-testid={testId}
      />
    );
  }
}

export default Image;
