import React from "react";
import styles from "./Progress.module.scss";
import { ERR } from "./constants";

const Progress = (props) => (
  <div className={styles.progressBar}>
    <div
      className={`${styles.progress} ${
        props.status === ERR ? styles.err : styles.pending
      } ${props.progress === 100 ? styles.success : ""}`}
      style={{ width: props.progress + "%" }}
    ></div>
  </div>
);

export default Progress;
