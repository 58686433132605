import React, { Component } from "react";
import styles from "./ShareOtherOffers.module.scss";
import Axios from "axios";
import { get, invoke, remove } from "lodash";
import {
  ERR,
  MAX_REPORTS_SIZE,
  MAX_SIZE_IN_MB,
  PENDING,
  UPLOADED,
  VALID_FILE_TYPE,
} from "../../../component/UploadFiles/constants";
import Dropzone from "../../../component/UploadFiles/Dropzone";
import Progress from "../../../component/UploadFiles/Progress";
import {
  API_URL,
  ARTIST_API,
  OTHER_OFFER_DOCUMENT,
  USER_API,
} from "../constants";
import AuthTokenService from "../../../utils/AuthTokenService";
import { ReactComponent as TrashIcon } from "../../../assets/logos/trash-icon.svg";
import { userExists } from "../../../utils/Helper";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import request from "../../../utils/request";
import Loader from "../../../component/Loader";
import { MAX_UPLOAD_COUNT } from "./constants";

class ShareOtherReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successUploadCount: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.getReportsList();
  }

  getReportsList = () => {
    this.setState({
      loading: true,
    });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFER_DOCUMENT}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const uploadProgress = {};
          const fileList = invoke(res, "data.map", (file) => {
            uploadProgress[file.fileName] = {
              state: UPLOADED,
              deleteKey: file.key,
              percentage: 100,
            };
            return { name: file.fileName, size: 0 };
          });
          this.setState({
            files: fileList,
            successUploadCount: fileList.length,
            uploadProgress: { ...uploadProgress },
          });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  onFilesAdded = (files) => {
    const fileList = files.filter(
      (file) => !this.state.uploadProgress[file.name],
    );
    this.setState(
      (prevState) => ({
        files: prevState.files.concat(fileList),
      }),
      this.uploadFiles,
    );
  };

  deleteReport = (file) => {
    this.setState({
      loading: true,
    });
    const payload = {
      key: get(this.state.uploadProgress[file.name], `deleteKey`),
    };
    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFER_DOCUMENT}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const { uploadProgress, files } = this.state;
          delete uploadProgress[file.name];
          remove(files, (f) => f.name === file.name);
          this.setState({
            files,
            uploadProgress,
            successUploadCount: this.state.successUploadCount - 1,
          });
          toast.success(`File "${file.name}" deleted successfully.`);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        return false;
      });
  };

  cancelRequest = (file) => {
    if (!this.state.uploading) {
      this.deleteReport(file);
    } else {
      invoke(this.state.uploadProgress, `${file.name}.cancel`);
      const copy = { ...this.state.uploadProgress };
      copy[file.name] = {
        state: ERR,
        percentage: 100,
        msg: "Cancelled",
      };
      this.setState({ uploadProgress: copy });
    }
  };

  renderProgress = (file) => {
    const uploadProgress = this.state.uploadProgress[file.name];
    return (
      <div
        className={`${styles.progressWrapper} ${
          [UPLOADED, ERR].indexOf(get(uploadProgress, "state", "")) !== -1
            ? "d-none"
            : ""
        }`}
      >
        <>
          <Progress
            progress={uploadProgress.percentage || 0}
            status={uploadProgress.state}
          />
        </>
      </div>
    );
  };

  sendUploadRequest = (file) => {
    const formData = new FormData();
    formData.append("offerDoc", file, file.name);
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFER_DOCUMENT}`;
    if (userExists()) {
      Axios.defaults.headers.common.Authorization = `${AuthTokenService.get()}`;
    } else {
      delete Axios.defaults.headers.common.Authorization;
    }
    const source = Axios.CancelToken.source();

    return Axios.put(requestURL, formData, {
      onUploadProgress: (ProgressEvent) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = {
          state: PENDING,
          cancel: source.cancel,
          percentage: Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100,
          ),
        };
        this.setState({ uploadProgress: copy });
      },
      headers: { "Content-Type": "multipart/form-data" },
      cancelToken: source.token,
      params: {},
    });
  };

  uploadFiles = () => {
    this.setState({
      uploading: true,
    });
    const promises = [];
    let maxUploaded = 0;
    this.state.files.forEach((file, i) => {
      const fileExtension = file.name.split(".");
      if (file.size > MAX_REPORTS_SIZE) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: `File size exceeded (${MAX_SIZE_IN_MB}MB)`,
        };
      } else if (
        fileExtension.length < 2 ||
        VALID_FILE_TYPE.indexOf(fileExtension.pop().toLowerCase()) === -1
      ) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: "File type not accepted",
        };
      }
      if (get(this.state.uploadProgress[file.name], "state") !== ERR)
        maxUploaded++;
      if (maxUploaded > MAX_UPLOAD_COUNT) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: `Max ${MAX_UPLOAD_COUNT} files are allowed`,
        };
      }
      if (
        !this.state.uploadProgress[file.name] &&
        file.size <= MAX_REPORTS_SIZE
      ) {
        promises.push(this.sendUploadRequest(file));
      }
    });

    Promise.allSettled(promises)
      .then((responses) => {
        responses.forEach((res) => {
          if (res.status === "fulfilled") {
            this.state.uploadProgress[
              get(res, "value.config.data").get("offerDoc").name
            ].deleteKey = get(res, "value.data.data.key");
            this.state.uploadProgress[
              get(res, "value.config.data").get("offerDoc").name
            ].state = UPLOADED;
            this.setState({
              successUploadCount: this.state.successUploadCount + 1,
            });
          } else {
            if (get(res, "reason.response.data.message")) {
              toast.error(get(res, "reason.response.data.message"));
            }
            if (get(res, "reason.config.data")) {
              this.state.uploadProgress[
                get(res, "reason.config.data").get("offerDoc").name
              ].state = ERR;
            }
          }
        });
        this.setState({ uploading: false });
      })
      .catch();
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OTHER_OFFER_DOCUMENT}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          invoke(this.props, "onRequestClose");
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        return false;
      });
  };

  fileText = () => (this.state.successUploadCount === 1 ? "file" : "files");

  unsentFileListing = () => (
    <div className={styles.upload}>
      <div className={styles.content}>
        {!this.state.files.length ? (
          <></>
        ) : (
          <>
            {!!this.state.successUploadCount && (
              <p className={styles.fileCount}>
                {this.state.successUploadCount} {this.fileText()} uploaded
                successfully
              </p>
            )}
            <div className={styles.files}>
              {this.state.files.map((file, index) => {
                const uploadProgress = this.state.uploadProgress[file.name];
                return (
                  uploadProgress && (
                    <div
                      key={`${file.name}-${index}`}
                      className={`${styles.row} ${
                        uploadProgress.state === ERR ? styles.error : ""
                      }`}
                      title={file.name}
                    >
                      <span className={styles.fileNameContainer}>
                        <span className={styles.fileName}>{file.name}</span>
                        <span
                          className={`${styles.checkIcon} ${
                            uploadProgress.state === ERR ||
                            (uploadProgress.percentage === 100 &&
                              this.state.uploading)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            this.cancelRequest(file);
                          }}
                        >
                          <TrashIcon />
                        </span>
                        {get(this.state.uploadProgress[file.name], "msg") && (
                          <span className={styles.msgError}>
                            {get(this.state.uploadProgress[file.name], "msg")}
                          </span>
                        )}
                      </span>
                      {this.renderProgress(file)}
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );

  render() {
    return (
      <div className={styles.uploadContainer}>
        <div className={styles.uploadBox}>
          <Dropzone
            disabled={
              this.state.uploading ||
              this.state.successUploadCount >= MAX_UPLOAD_COUNT
            }
            onFilesAdded={this.onFilesAdded}
            successUploadCount={this.state.successUploadCount}
            label="Drag and drop or"
          />
        </div>
        {this.unsentFileListing()}
        <button
          disabled={!this.state.successUploadCount}
          className={styles.submitBtn}
          onClick={this.handleSubmit}
        >
          Submit
        </button>
        <button
          className={styles.linkBtn}
          data-testid="backBtn"
          onClick={() => {
            invoke(this.props, "goBack", false);
          }}
        >
          Back
        </button>
        {this.state.loading && <Loader className={styles.loader} />}
      </div>
    );
  }
}

export default ShareOtherReports;
