export const CHORDCASH_PARTNER = "chordCash";

export const UPDATES_PREFERENCE = "offerUpdate";
export const MARKETING_PREFERENCE = "marketing";
export const ALL_PREFERENCE = "allOfChordCash";

export const FALSE_PREFERENCES = {
  [UPDATES_PREFERENCE]: false,
  [MARKETING_PREFERENCE]: false,
  [ALL_PREFERENCE]: false,
};
