import React, { Component } from "react";
import { get } from "lodash";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form } from "formik";
import styles from "./ContactPopup.module.scss";
import FormikForm from "../Form/FormikForm";
import request from "../../utils/request";
import { API_URL, USER_API, CHAT_API, ME } from "../../routes/pages/constants";
import { EMAIL_REGEX } from "../../routes/pages/Auth/constants";
import FormField from "../FormField/FormField";
import { userExists } from "../../utils/Helper";
import { GetErrorMessage } from "../../routes/pages/helper";
import OverLay from "../OverLay";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Image from "../Image";
import { ThemeContext } from "../ThemeManager/ThemeManager";

class ContactPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
    };
  }

  componentWillMount() {
    ReactModal.setAppElement("body");
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.getDefaultEmail();
    }
  }

  getDefaultEmail = () => {
    if (userExists() && this.props.isOpen) {
      this.setState({ loading: true });
      const data = {
        method: "GET",
      };
      const requestURL = `${API_URL}${USER_API}${ME}`;
      request(requestURL, data)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setState({ email: get(res.data, "email.value") });
            return true;
          }
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    }
  };
  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    message: Yup.string().required().label("Message"),
  });

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      email: values.email,
      message: values.message,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${CHAT_API}`;
    request(requestURL, data)
      .then((res) => {
        if (res.status) {
          this.props.onRequestClose();
          toast.success(get(res, "message"));
          return true;
        }
        this.setState({ loading: false });
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  render() {
    return (
      <>
        <OverLay
          anchor={"left"}
          open={this.props.isOpen}
          onClose={this.props.onRequestClose}
        >
          <div className={styles.mainContainer}>
            <div
              className={styles.logoContainer}
              onClick={this.props.onRequestClose}
            >
              <div
                className={styles.closeIcon}
                onClick={this.props.onRequestClose}
              >
                <KeyboardBackspaceIcon />
              </div>
              <Image
                src={get(this.context, "emailLogo", "")}
                className={styles.logo}
                alt="Logo"
              />
            </div>
            <div className={styles.modalBody}>
              <FormikForm
                initialValues={{ email: this.state.email, message: "" }}
                enableReinitialize
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
              >
                <Form>
                  <div>
                    <div className={styles.title}>
                      <h3>
                        <span>Contact</span> Us
                      </h3>
                    </div>
                    <div
                      className={`form-group form-group-m0 mb-0 ${styles.emailField}`}
                    >
                      <FormField
                        name="email"
                        placeholder="Email address"
                        label="Email address"
                      />
                    </div>
                    <div className={`${styles.issueBox}`}>
                      <label>Message</label>
                      <FormField
                        name="message"
                        as="textarea"
                        placeholder="Please provide any information we can use to help you, including the artist’s name and what device you are using."
                      />
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      type="reset"
                      onClick={this.props.onRequestClose}
                      className={styles.btnLink}
                    >
                      Cancel
                    </button>
                    <button type="submit" disabled={this.state.loading}>
                      <span>Send Message</span>
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </button>
                  </div>
                </Form>
              </FormikForm>
            </div>
          </div>
        </OverLay>
      </>
    );
  }
}
ContactPopup.contextType = ThemeContext;

export default ContactPopup;
