import React, { Component } from "react";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import { get } from "lodash";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import classes from "../YourAdvance/YourAdvance.module.scss";
import styles from "./ArtistDashboard.module.scss";
import { setTitle } from "../../../component/ThemeManager/helper";
import {
  numberWithCommas,
  getAdvanceOptionalCount,
} from "../YourAdvance/helper";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  UPDATE_MARKETING_STATUS,
  POST_CONFIRMATION,
  MARKETPLACE_ONBOARDING,
} from "../constants";
import { GetErrorMessage } from "../helper";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ASSETS, INITIAL_TOOLTIP_STATE } from "./constants";
import Loader from "../../../component/Loader";
import { renderChancesOfOffer } from "../PostInvestorNetwork/helper";

class ArtistDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      submitLoader: false,
      offerStage: {},
      offer: {},
      offerMessage: "",
      adjustTermsModal: false,
      advanceData: {},
      defaultOffer: {},
      currentData: {},
      newTracks: 0,
      term: 1,
      catalogIncome: 0,
      releaseIncome: 0,
      isContactModal: false,
      btnClick: false,
      setOpen: INITIAL_TOOLTIP_STATE,
      navigationStatus: {},
    };
  }

  componentDidUpdate() {
    setTitle(
      window.location.pathname.includes(POST_CONFIRMATION)
        ? "Post Confirmation"
        : "Posted To Network",
      this.context,
    );
  }

  componentDidMount() {
    this.getOfferData();
  }

  getOfferData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.status) {
          this.setState({
            offerStage: get(res.data, "offerStage.defaultOffer", {}),
            offer: get(res.data, "offer", {}),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  checkNavigationStatus = () => {
    get(this.state.navigationStatus, "postConfirmation")
      ? this.props.history.push(MARKETPLACE_ONBOARDING)
      : this.handleSubmit();
  };

  handleSubmit = () => {
    this.setState({ submitLoader: true });
    const payload = {
      postConfirmation: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (res && res.status) {
          this.props.history.push(MARKETPLACE_ONBOARDING);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleTooltipOpen = (value) => {
    const setOpen = {
      ...INITIAL_TOOLTIP_STATE,
      [value]: !this.state.setOpen[value],
    };
    this.setState({
      setOpen,
    });
  };

  reviewMobile = () => (
    <div className={styles.reviewMob}>
      <div className={styles.reviewMobAsset}>
        <div
          data-testid="btn-one"
          className={`
            ${styles.reviewMobLogo}
            ${this.state.setOpen.setOne ? styles.big : ""}
          `}
          onClick={() => {
            this.handleTooltipOpen("setOne");
          }}
        >
          <ASSETS.GroupPen />
        </div>
        <div className={styles.reviewMobText}>Review & Sign</div>
      </div>
      <div className={styles.mobParent}>
        <div>
          <div
            className={
              this.state.setOpen.setOne ? styles.mobLine2 : styles.mobLine1
            }
          ></div>
          <div
            className={this.state.setOpen.setOne ? styles.mobLineArrow : ""}
          ></div>
          <div
            className={this.state.setOpen.setOne ? styles.mobLine : ""}
          ></div>
        </div>
        <div
          className={this.state.setOpen.setOne ? styles.mobLineText : "d-none"}
        >
          Review the Posting Summary and Advance Agreement. It will be sent to
          you via email.
        </div>
      </div>

      <div className={styles.reviewMobAsset}>
        <div
          data-testid="btn-two"
          className={`
            ${styles.reviewMobLogo}
            ${this.state.setOpen.setTwo ? styles.big : ""}
          `}
          onClick={() => {
            this.handleTooltipOpen("setTwo");
          }}
        >
          <ASSETS.GroupShare />
        </div>
        <div className={styles.reviewMobText}>Post to Network</div>
      </div>
      <div className={styles.mobParent}>
        <div>
          <div
            className={
              this.state.setOpen.setTwo ? styles.mobLine2 : styles.mobLine1
            }
          ></div>
          <div
            className={this.state.setOpen.setTwo ? styles.mobLineArrow : ""}
          ></div>
          <div
            className={this.state.setOpen.setTwo ? styles.mobLine : ""}
          ></div>
        </div>
        <div
          className={this.state.setOpen.setTwo ? styles.mobLineText : "d-none"}
        >
          We share the included track details and other info you provided with
          investors from our network and those you may have invited.
        </div>
      </div>

      <div className={styles.reviewMobAsset}>
        <div
          data-testid="btn-three"
          className={`
            ${styles.reviewMobLogo}
            ${this.state.setOpen.setThree ? styles.big : ""}
          `}
          onClick={() => {
            this.handleTooltipOpen("setThree");
          }}
        >
          <ASSETS.GroupHammer />
        </div>
        <div className={styles.reviewMobText}>Investors Bid</div>
      </div>
      <div className={styles.mobParent}>
        <div>
          <div
            className={
              this.state.setOpen.setThree ? styles.mobLine2 : styles.mobLine1
            }
          ></div>
          <div
            className={this.state.setOpen.setThree ? styles.mobLineArrow : ""}
          ></div>
          <div
            className={this.state.setOpen.setThree ? styles.mobLine : ""}
          ></div>
        </div>
        <div
          className={
            this.state.setOpen.setThree ? styles.mobLineText : "d-none"
          }
        >
          Investors make their “best and final offer” on all or some of your
          advance. They won’t be able to see your minimum, or other investors
          offers, so you get genuine offers from serious investors.
        </div>
      </div>

      <div className={styles.reviewMobAsset}>
        <div
          data-testid="btn-four"
          className={`
            ${styles.reviewMobLogo}
            ${this.state.setOpen.setFour ? styles.big : ""}
          `}
          onClick={() => {
            this.handleTooltipOpen("setFour");
          }}
        >
          <ASSETS.ReviewLogo />
        </div>
        <div className={styles.reviewMobText}>Review</div>
      </div>
      <div className={styles.mobParent}>
        <div>
          <div
            className={
              this.state.setOpen.setFour ? styles.mobLine2 : styles.mobLine1
            }
          ></div>
          <div
            className={this.state.setOpen.setFour ? styles.mobLineArrow : ""}
          ></div>
          <div
            className={this.state.setOpen.setFour ? styles.mobLine : ""}
          ></div>
        </div>
        <div
          className={this.state.setOpen.setFour ? styles.mobLineText : "d-none"}
        >
          We review all offers and put together a group of investors who
          collectively give you the best advance possible.
        </div>
      </div>

      <div className={styles.reviewMobAsset}>
        <div
          data-testid="btn-five"
          className={`
            ${styles.reviewMobLogo}
            ${this.state.setOpen.setFive ? styles.big : ""}
          `}
          onClick={() => {
            this.handleTooltipOpen("setFive");
          }}
        >
          <ASSETS.GroupDollar />
        </div>
        <div className={styles.reviewMobText}>Get Funding</div>
      </div>
      <div className={styles.mobParent}>
        <div></div>
        <div
          className={
            this.state.setOpen.setFive ? styles.mobLineTextLast : "d-none"
          }
        >
          If we exceed your target, we'll walk you though final steps to get
          your advance within a few days. If we don't meet your minimum, you can
          repost in a few weeks.
        </div>
      </div>
    </div>
  );

  setNavigationStatus = (data) => {
    this.setState({ navigationStatus: data });
  };

  render() {
    const { offer, offerStage } = this.state;
    const reviewText =
      "Review the Posting Summary and Advance Agreement. It will be sent to you via email.";
    const postNetwork =
      "We share the included track details and other info you provided with investors from our network and those you may have invited.";
    const investorBid = `Investors make their “best and final offer” on all or some of your advance. They won’t be able to see your minimum, or other investors offers, so you get genuine offers from serious investors.`;
    const reviewAll = `We review all offers and put together a group of investors who collectively give you the best advance possible.`;
    const getFunding = `If we exceed your target, we'll walk you though final steps to get your advance within a few days. If we don't meet your minimum, you can repost in a few weeks.`;

    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          touch: {
            fontSize: "14px",
            fontWeight: "900",
            "@media (max-width: 767px)": {
              fontSize: "12px",
            },
          },
          arrow: {
            color: "black",
          },
          popper: {
            zIndex: 0,
          },
          tooltip: {
            fontSize: "15px",
            color: "white",
            backgroundColor: "black",
            fontWeight: "900",
            maxWidth: "300px",
            "@media (max-width: 767px)": {
              maxWidth: "300px",
            },
          },
        },
      },
    });

    const headerTitle = window.location.pathname.includes(POST_CONFIRMATION)
      ? "Post Confirmation"
      : "Congratulations!";

    return (
      <>
        <div className={classes.layoutContainer}>
          <HorizontalMenu
            {...this.props}
            getStatus={this.setNavigationStatus}
            offerStage={this.state.offerStage}
          />
          <div className={classes.funnelContainer}>
            <LoginHeader headerTitle={headerTitle} {...this.props} />
            <div className={styles.main}>
              <div className={styles.container}>
                {this.state.submitLoader && <Loader light backgroundNone />}
                {this.state.loading && <Loader light backgroundNone />}
                <div className={styles.summaryContainer}>
                  <div
                    className={`${styles.detailsContainer} ${
                      this.state.loading ? "d-none" : ""
                    }`}
                  >
                    <div className={styles.offerContainer}>
                      <div className={styles.totalContent}>
                        <span className={styles.label}>
                          Minimum Target Advance
                        </span>
                        {renderChancesOfOffer(
                          get(offerStage, "totalAdvance"),
                          offer,
                        )}
                      </div>
                      <div className={styles.estimateContainer}>
                        <div className={styles.totalEstimate}>
                          <label>Total Target Advance</label>
                          <p className={styles.totalNumber}>
                            $
                            {numberWithCommas(
                              get(offerStage, "totalAdvance", 0),
                            )}{" "}
                            <p className={styles.more}>or more</p>
                          </p>
                        </div>
                        <div className={styles.offerDetails}>
                          <label>{get(offer, "works", "")}</label>
                          <p className={styles.recoupmentRate}>
                            <span>Recoupment Rate </span>
                            <span className={styles.recoupmentValue}>
                              {parseInt(get(offerStage, "royalty", 0))}%
                            </span>
                          </p>
                        </div>
                        <div className={styles.termContainer}>
                          <p>
                            <span className={styles.importantText}>
                              {offer.term} Year Term,
                            </span>{" "}
                            {offer.flow_through}% flow through all income
                          </p>
                        </div>
                      </div>
                      <div className={styles.contentContainer}>
                        <ul>
                          <li
                            className={
                              !get(offerStage, "upfront") ? "d-none" : ""
                            }
                          >
                            <div className="d-flex">
                              <span>Upfront advance</span>
                              <div className={styles.value}>
                                $
                                {numberWithCommas(
                                  get(offerStage, "upfront", 0),
                                )}
                              </div>
                            </div>
                          </li>
                          <li
                            className={
                              !get(offerStage, "newRelease", 0) ? "d-none" : ""
                            }
                          >
                            <div className="d-flex">
                              <span>
                                Advance on release of{" "}
                                {getAdvanceOptionalCount(offer.works)} new
                                tracks
                              </span>
                              <div className={styles.value}>
                                $
                                {numberWithCommas(
                                  get(offerStage, "newRelease", 0),
                                )}
                              </div>
                            </div>
                          </li>
                          <li
                            className={
                              !get(offerStage, "optionalRelease", 0)
                                ? "d-none"
                                : ""
                            }
                          >
                            <div className="d-flex">
                              <span>
                                Optional advance for{" "}
                                {getAdvanceOptionalCount(offer.works, true)}{" "}
                                more tracks
                              </span>
                              <div className={styles.value}>
                                $
                                {numberWithCommas(
                                  get(offerStage, "optionalRelease", 0),
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.actionContainer}>
                      {window.location.pathname.includes(POST_CONFIRMATION) ? (
                        <>
                          <div className={styles.actionHeader}>
                            Post Your Terms
                          </div>
                          <div className={styles.contactUsContainer}>
                            <p>
                              We'll send you a summary of your minimum terms and
                              a copy of the advance agreement.
                            </p>
                            <p>
                              If we can't meet or exceed your target within 2
                              weeks, then there is no commitment from you.
                            </p>
                          </div>
                          <div className={styles.acceptOfferContainer}>
                            <p>Happy with these terms?</p>
                            <button
                              onClick={this.checkNavigationStatus}
                              className={styles.primary}
                              disabled={this.state.loading}
                              data-testid="confirmTerms"
                            >
                              Yes, Continue
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.actionHeaderPost}>
                            <div>
                              <ASSETS.GroupMail />
                            </div>
                            <span className={styles.postText}>
                              Look out for an email with your contract in the
                              next 2 business days.
                            </span>
                          </div>
                          <div className={styles.contactUsContainerPost}>
                            <p>
                              If we can't meet or exceed your target within 15
                              days, then there is no commitment from you.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.last}
                ${
                  window.location.pathname.includes(POST_CONFIRMATION)
                    ? styles.posted
                    : ""
                }`}
              >
                <div className={styles.next}>
                  <h3>What Happens Next?</h3>
                  <h6 className={styles.maximize}>
                    {get(this.context, "displayName")} will do everything we can
                    to maximize your advance.
                  </h6>
                </div>
                <div className={styles.reviewMain}>
                  <div className={styles.review}>
                    <div className={styles.reviewSign}>
                      <div className={styles.reviewSignImg}>
                        <div className={styles.parent}></div>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="top"
                            open={this.state.setOpen.setOne}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={reviewText}
                          >
                            <div
                              data-testid="btn-wone"
                              className={`
                              ${styles.assets}
                              ${this.state.setOpen.setOne ? styles.big : ""}
                            `}
                              onClick={() => this.handleTooltipOpen("setOne")}
                            >
                              <ASSETS.GroupPen />
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                          <div className={styles.arrow}></div>
                        </div>
                      </div>
                      <div className={styles.iconText}>Review & Sign</div>
                    </div>

                    <div className={styles.reviewSign}>
                      <div className={styles.reviewSignImg}>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                        </div>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="top"
                            open={this.state.setOpen.setTwo}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={postNetwork}
                          >
                            <div
                              data-testid="btn-wtwo"
                              className={`
                              ${styles.assets}
                              ${this.state.setOpen.setTwo ? styles.big : ""}
                            `}
                              onClick={() => this.handleTooltipOpen("setTwo")}
                            >
                              <ASSETS.GroupShare />
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                          <div className={styles.arrow}></div>
                        </div>
                      </div>
                      <div className={styles.iconText}>Post to Network</div>
                    </div>

                    <div className={styles.reviewSign}>
                      <div className={styles.reviewSignImg}>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                        </div>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="top"
                            open={this.state.setOpen.setThree}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={investorBid}
                          >
                            <div
                              data-testid="btn-wthree"
                              className={`
                              ${styles.assets}
                              ${this.state.setOpen.setThree ? styles.big : ""}
                            `}
                              onClick={() => this.handleTooltipOpen("setThree")}
                            >
                              <ASSETS.GroupHammer />
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                          <div className={styles.arrow}></div>
                        </div>
                      </div>
                      <div className={styles.iconText}>Investors Bid</div>
                    </div>

                    <div className={styles.reviewSign}>
                      <div className={styles.reviewSignImg}>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                        </div>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="top"
                            open={this.state.setOpen.setFour}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={reviewAll}
                          >
                            <div
                              data-testid="btn-wfour"
                              className={`
                              ${styles.assets}
                              ${this.state.setOpen.setFour ? styles.big : ""}
                            `}
                              onClick={() => this.handleTooltipOpen("setFour")}
                            >
                              <ASSETS.ReviewLogo />
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                          <div className={styles.arrow}></div>
                        </div>
                      </div>
                      <div className={styles.iconText}>Review</div>
                    </div>

                    <div className={styles.reviewSign}>
                      <div className={styles.reviewSignImg}>
                        <div className={styles.parent}>
                          <div className={styles.line}></div>
                        </div>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            placement="top"
                            open={this.state.setOpen.setFive}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={getFunding}
                          >
                            <div
                              data-testid="btn-wfive"
                              className={`
                              ${styles.assets}
                              ${this.state.setOpen.setFive ? styles.big : ""}
                            `}
                              onClick={() => this.handleTooltipOpen("setFive")}
                            >
                              <ASSETS.GroupDollar />
                            </div>
                          </Tooltip>
                        </MuiThemeProvider>
                        <div className={styles.parent}></div>
                      </div>
                      <div className={styles.iconText}>Get Funding</div>
                    </div>
                  </div>
                  <p className={styles.clickEach}>
                    Click each icon for more details about what to expect.
                  </p>
                </div>
                <div>{this.reviewMobile()}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ArtistDashboard.contextType = ThemeContext;
export default ArtistDashboard;
