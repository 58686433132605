import React from "react";
import toolstyles from "./Tooltip.module.scss";
import ReactTooltip from "react-tooltip";
import { ReactComponent as Help } from "../../assets/logos/help-icon.svg";
import { get } from "lodash";

const Tooltip = (props) => (
  <div className={toolstyles.toolgroup}>
    <i
      className={
        props.dark
          ? `${toolstyles.infoIcon} ${toolstyles.dark}`
          : `${toolstyles.infoIcon} ${toolstyles.light}`
      }
      data-for={props.id}
      data-tip
      onClick={get(props, "onClick")}
    >
      {" "}
      <Help />{" "}
    </i>
    {props.content && (
      <ReactTooltip
        className={
          props.dark
            ? `${toolstyles.tooltipInstance} ${toolstyles.dark}`
            : `${toolstyles.tooltipInstance} ${toolstyles.light}`
        }
        id={props.id}
        delayHide={props.delay || 0}
        type="dark"
        effect="solid"
        place={props.place || "top"}
      >
        <div className={toolstyles.tooltipText}>{props.content}</div>
      </ReactTooltip>
    )}
  </div>
);

export default Tooltip;
