import React, { Component } from "react";
import { get } from "lodash";
import Loader from "../../../component/Loader";
import { toast } from "react-toastify";
import request from "../../../utils/request";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  DASHBOARD,
  ACTIVATE_API,
  HOME,
} from "../constants";
import { GetErrorMessage } from "../helper";
import AuthTokenService from "../../../utils/AuthTokenService";

class ArtistVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setArtistToken();
  }

  setArtistToken = () => {
    const params = new URLSearchParams(window.location.search);

    this.setState({ loading: true });
    const payload = { id: params.get("id") };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVATE_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          AuthTokenService.storeToken(res.data.accessToken);
          this.props.history.push(HOME);
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
        this.props.history.push(DASHBOARD);
      });
  };
  render() {
    return <div>{this.state.loading && <Loader />}</div>;
  }
}

export default ArtistVerify;
