import { Form, Formik } from "formik";
import React from "react";
import ReactModal from "react-modal";
import { FIELD_NAMES } from "./constants";
import styles from ".././AdminDashboard/AdminDashboard.module.scss";
import classes from "./HomePageContent.module.scss";
import FormField from "../../../component/FormField/FormField";

export const basicPasswordModal = (
  open,
  title,
  subTitle,
  handleSubmit,
  handleClose,
) => {
  return (
    <Formik
      initialValues={{ adminPassword: "" }}
      enableReinitialize
      data-testid="formRender1"
    >
      {({ values, setFieldValue }) => (
        <Form>
          <ReactModal
            isOpen={open}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={() => handleClose(setFieldValue)}
            className={styles.imageModal}
            overlayClassName={styles.modalOverlay}
            data-testid="handleMOdal"
          >
            <h2 className={styles.deletTitle}>{title}</h2>
            <p>{subTitle}</p>
            <div
              className={`${styles.changeTo} form-group justify-content-center ${classes.width}`}
            >
              <FormField
                name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
                label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
                placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
                as="password"
              />
            </div>

            <div className={`${styles.modalBtnContainer} ${styles.marginTop}`}>
              <button
                className={styles.secondary}
                type="button"
                onClick={() => handleClose(setFieldValue)}
                data-testid="cancel"
              >
                Cancel
              </button>
              <button
                className={styles.primary}
                disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
                onClick={() => handleSubmit(values, setFieldValue)}
                type="submit"
                data-testid="submit"
              >
                Submit
              </button>
            </div>
          </ReactModal>
        </Form>
      )}
    </Formik>
  );
};
