import React, { useContext } from "react";
import { get } from "lodash";
import {
  CATALOG_SLIDER,
  TERM_SLIDER,
  C_INC_SLIDER,
  R_INC_SLIDER,
  WIDGET_RENDER,
  TERM_MIN,
  MIN_VALUE,
  MAX_VALUE,
  CASHFLOW_OPTIONS,
  CASHFLOW,
  CATALOG_TYPE,
  DEFAULT_PARTNER,
  TERM_20,
  DEFAULT_RIGHTS,
} from "./constants";
import styles from "./YourAdvance.module.scss";
import {
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import htmlParser from "html-react-parser";
import {
  TUNE_MY_DATA,
  FUNDING,
  SEND_REPORTS,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY,
} from "../constants";
import {
  CASHFLOW_CONTENT,
  INCLUDED_CONTENT,
  SHARE_OF_CATALOG,
  TERM,
} from "../infoIconMsg";
import Tooltip from "../../../component/Tooltip/Tooltip";
import InputRangeSlider from "../../../component/Range/InputRangeSlider";
import ReactModal from "react-modal";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { getSubDomain } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import SurveySparrow from "../GeneratingOffers/SurveySparrow";

export const getShortNumber = (num) => {
  if (Math.abs(num) >= 1.0e9) {
    return `${Math.round((num / 1.0e9) * 10) / 10}b`;
  }

  if (Math.abs(num) >= 1.0e6) {
    return `${Math.round((num / 1.0e6) * 10) / 10}m`;
  }

  if (Math.abs(num) >= 1.0e3) {
    return `${Math.round((num / 1.0e3) * 10) / 10}k`;
  }

  return num;
};

export const formatTrendData = (data) => data.map((value) => ({ value }));

const getObjectData = (data, index) => data[Object.keys(data)[index]];

const getObjectLength = (data) => Object.keys(data).length - 1;

const getValidData = (data, key) => {
  if (key === null) {
    return getObjectData(data, 0);
  }
  if (data[key]) {
    return data[key];
  }
  for (const item in data) {
    if (item > key) {
      return data[item];
    }
  }
  return getObjectData(data, 0);
};

export const getMaxTermValue = (data, partner, flow_through) => {
  let newData = data;
  if (get(partner, "isPartners") && data[get(partner, `value`)]) {
    newData = data[get(partner, `value`)];
  }
  if (get(partner, "isPublisher") && data[get(partner, "publisherVal")]) {
    newData = data[get(partner, `publisherVal`)];
  }
  try {
    const catalogData = getObjectData(newData, flow_through);
    const last = getObjectLength(catalogData);
    return Object.keys(catalogData)[last];
  } catch (err) {}
};

export const getMaxValue = (
  data,
  partner,
  sliderIndex,
  flow_through,
  term,
  cIncome,
) => {
  let newData = data;
  if (get(partner, "isPartners") && data[get(partner, `value`)]) {
    newData = data[get(partner, `value`)];
  }
  if (get(partner, "isPublisher") && data[get(partner, "publisherVal")]) {
    newData = data[get(partner, `publisherVal`)];
  }
  try {
    if (sliderIndex === CATALOG_SLIDER) return getObjectLength(newData);
    const catalogData = getObjectData(newData, flow_through);
    if (sliderIndex === TERM_SLIDER) return getObjectLength(catalogData);
    const termData = getValidData(catalogData, term);
    if (sliderIndex === C_INC_SLIDER) return getObjectLength(termData);
    const cIncomeData = getValidData(termData, cIncome);
    if (sliderIndex === R_INC_SLIDER) return getObjectLength(cIncomeData);
  } catch (err) {
    console.error(err);
    window.location.href = `${ERROR_SERVER_DOWN}?err=1`;
  }
};

const marksMapping = (data) => {
  const marks = {};
  Object.keys(data).map((key) => {
    marks[key] = "";
    return true;
  });
  return marks;
};

export const getAdvanceKeys = (
  data,
  partner,
  sliderIndex,
  flow_through,
  term,
  cIncome,
) => {
  let newData = data;
  if (get(partner, "isPartners") && data[get(partner, `value`)]) {
    newData = data[get(partner, `value`)];
  }
  if (get(partner, "isPublisher") && data[get(partner, "publisherVal")]) {
    newData = data[get(partner, `publisherVal`)];
  }
  try {
    const catalogData = getObjectData(newData, flow_through);
    if (sliderIndex === TERM_SLIDER) return marksMapping(catalogData);
    const termData = getValidData(catalogData, term);
    if (sliderIndex === C_INC_SLIDER) return marksMapping(termData);
    const cIncomeData = getValidData(termData, cIncome);
    if (sliderIndex === R_INC_SLIDER) return marksMapping(cIncomeData);
  } catch (err) {}
};

export const getCurrentCombinationValue = (
  data,
  flow_through,
  term,
  cIncome,
  rIncome,
  isFastFlow,
  isPartners,
  selectedPartner,
  otherProps,
) => {
  let newData = data;
  if (isPartners && get(data, selectedPartner)) {
    newData = data[selectedPartner];
  }
  if (get(otherProps, "isPublisher") && data[get(otherProps, "publisherVal")]) {
    newData = data[get(otherProps, "publisherVal")];
  }
  const catalogData = getObjectData(newData, flow_through);
  const termData = getValidData(catalogData, term);
  const cIncomeData = getValidData(termData, cIncome);

  if (isFastFlow) {
    return cIncomeData;
  }

  return Object.keys(cIncomeData).includes("null")
    ? cIncomeData[null]
    : getValidData(cIncomeData, rIncome);
};

export const getNewReleaseLabel = (data, future) =>
  future !== null && <>,&nbsp;New Releases: {data}%</>;

export const getDefaultOffer = (defaultOffer, data, isFastFlow, otherData) => {
  if (isFastFlow) {
    if (
      get(otherData, "isPublisher") &&
      get(data, get(defaultOffer, "rights_type"))
    ) {
      return {
        newTracks: Object.keys(
          get(data, get(defaultOffer, "rights_type")),
        ).indexOf(defaultOffer.works),
        term: defaultOffer.term,
        flowthrough: defaultOffer.flowthrough,
      };
    }
    return {
      newTracks: Object.keys(data).indexOf(defaultOffer.works),
      term: defaultOffer.term,
      flowthrough: defaultOffer.flowthrough,
    };
  } else if (
    get(otherData, "isPartners") &&
    get(data, get(defaultOffer, "partner"))
  ) {
    return {
      selectedPartner: get(defaultOffer, "partner", null),
      newTracks: Object.keys(get(data, get(defaultOffer, "partner"))).indexOf(
        defaultOffer.works,
      ),
      term: defaultOffer.term,
      catalogIncome: defaultOffer.flow_through,
      releaseIncome: defaultOffer.future,
    };
  } else {
    return {
      newTracks: Object.keys(data).indexOf(defaultOffer.works),
      term: defaultOffer.term,
      catalogIncome: defaultOffer.flow_through,
      releaseIncome: defaultOffer.future,
    };
  }
};

export const numberWithCommas = (num = 0) =>
  num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getAdvanceOptionalCount = (work = "", isOptional) => {
  const split = work.split("+");
  const result = isOptional
    ? get(split, "2", "").match(/\d+/)
    : get(split, "1", "").match(/\d+/);
  return get(result, "0", 0);
};

export class FunnelCollapseGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.widgetRenderArea = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSignup !== this.props.isSignup &&
      this.props.isSignup === true
    ) {
      this.setState({ isOpen: true });
    }
    if (
      prevProps.isConfirm !== this.props.isConfirm &&
      this.props.isConfirm === true
    ) {
      this.setState({ isOpen: false });
    }
  }

  isCurrentScreen = (path) =>
    get(this.props, "history.location.pathname") === path;

  addScript = () => {
    const frame = get(this.widgetRenderArea.current, "contentWindow.document");
    frame.open();
    frame.write(WIDGET_RENDER);
    frame.close();
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div
        className={`${styles.artistCollapse} ${isOpen ? styles.open : ""} ${
          this.props.isConfirm ? styles.maxW : ""
        }`}
      >
        <span
          className={styles.arrow}
          onClick={() =>
            this.setState({ isOpen: this.props.isSignup || !isOpen })
          }
        />
        <div
          className={`${this.props.isConfirm ? styles.scrollContainer : ""}`}
        >
          <Collapse
            in={isOpen || this.isCurrentScreen(TUNE_MY_DATA)}
            mountOnEnter
            unmountOnExit
          >
            <p
              className={`${styles.collapseTitle} ${
                this.isCurrentScreen(TUNE_MY_DATA) ? styles.activeGuide : ""
              }`}
            >
              1. Verification
            </p>
          </Collapse>
          <Collapse
            in={isOpen && this.isCurrentScreen(TUNE_MY_DATA)}
            mountOnEnter
            unmountOnExit
            className={`${styles.collapseContent} ${
              this.props.isConfirm && styles.inactiveGuide
            }`}
          >
            {htmlParser(
              get(this.props, "guideData.funnelGuideVerification2", ""),
            )}
          </Collapse>
          <Collapse
            in={isOpen && this.isCurrentScreen(TUNE_MY_DATA)}
            mountOnEnter
            unmountOnExit
            className={`${styles.collapseContent} ${
              !this.props.isConfirm && styles.inactiveGuide
            }`}
          >
            {htmlParser(
              get(this.props, "guideData.funnelGuideIncomeVerification", ""),
            )}
          </Collapse>
          <Collapse
            in={isOpen || this.isCurrentScreen(FUNDING)}
            mountOnEnter
            unmountOnExit
          >
            <p
              className={`${styles.collapseTitle} ${
                this.isCurrentScreen(FUNDING) ? styles.activeGuide : ""
              }`}
            >
              2. Customization
            </p>
          </Collapse>
          <Collapse
            in={isOpen && this.isCurrentScreen(FUNDING)}
            mountOnEnter
            unmountOnExit
            className={styles.collapseContent}
          >
            {htmlParser(
              get(this.props, "guideData.funnelGuideCustomization", ""),
            )}
          </Collapse>
          <Collapse
            in={isOpen || this.isCurrentScreen(SEND_REPORTS)}
            mountOnEnter
            unmountOnExit
          >
            <p
              className={`${styles.collapseTitle} ${
                this.isCurrentScreen(SEND_REPORTS) && !this.props.isSignup
                  ? styles.activeGuide
                  : ""
              }`}
            >
              3. Send Reports
            </p>
          </Collapse>
          <Collapse
            in={
              isOpen &&
              this.isCurrentScreen(SEND_REPORTS) &&
              !this.props.isSignup
            }
            mountOnEnter
            unmountOnExit
            className={styles.collapseContent}
          >
            {htmlParser(
              get(this.props, "guideData.funnelGuideSendReports", ""),
            )}
          </Collapse>
          <Collapse
            in={isOpen && this.props.isSignup}
            mountOnEnter
            unmountOnExit
            onEntered={this.addScript}
            onExited={this.state.removeFunc}
            className={styles.collapseContent}
          >
            <div id="getFunded" className="getFundedContainer">
              <iframe
                title="Widget"
                id="widgetRenderArea"
                ref={this.widgetRenderArea}
              ></iframe>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export function changeSliderCallback(loadDefault) {
  try {
    const isFastFlow = this.checkIsFastFlow && this.checkIsFastFlow();
    const isPartners = !!get(this.state, "availablePartners.length");
    const isPublisher = !!get(this.state, "addPublisher");
    const data = getCurrentCombinationValue(
      this.state.advanceData,
      this.state.newTracks,
      this.state.term,
      isFastFlow ? this.state.flowthrough : this.state.catalogIncome,
      this.state.releaseIncome,
      isFastFlow,
      isPartners,
      get(this.state, "selectedPartner", DEFAULT_PARTNER),
      {
        isPublisher,
        publisherVal: get(this, "state.rightsType"),
      },
    );
    if (isFastFlow) {
      this.setState({
        term: data.term,
        flowthrough: data.flowthrough,
        display: data.display,
      });
    } else {
      this.setState({
        term: data.term,
        catalogIncome: data.flow_through,
        releaseIncome: data.future,
      });
    }
    this.setState(
      {
        currentData: data,
      },
      () =>
        (get(this.props, "history.location.pathname") === FUNDING ||
          get(this.props, "history.location.pathname") === OFFER_SUMMARY) &&
        this.getSaveFundingAdvance(),
    );
    if (loadDefault) {
      if (isFastFlow) {
        const { newTracks, term, flowthrough } = getDefaultOffer(
          this.state.defaultOffer,
          this.state.advanceData,
          isFastFlow,
          { isPublisher },
        );
        this.setState({ newTracks, term, flowthrough }, changeSliderCallback);
      } else {
        const { newTracks, term, catalogIncome, releaseIncome } =
          getDefaultOffer(
            this.state.defaultOffer,
            this.state.advanceData,
            isFastFlow,
            { isPartners },
          );
        this.setState(
          { newTracks, term, catalogIncome, releaseIncome },
          changeSliderCallback,
        );
      }
    }
  } catch (err) {}
}

const renderTermValue = (term, isFastFlow) => {
  if (isFastFlow) {
    return Number(term) === 7 ? 8 : term;
  }
  return term;
};

export function getSliderComponent() {
  const isFastFlow = this.checkIsFastFlow && this.checkIsFastFlow();
  const isPartners = !!get(this.state, "availablePartners.length");
  const isPublisher = get(this.state, "addPublisher", false);
  return (
    <div>
      <div className={styles.sliderBox}>
        <p
          className={`${styles.contentname} ${
            this.state.navigationStatus.isFastFlow ? styles.fastContentName : ""
          }`}
        >
          <div className={styles.sliderData}>
            <p>Included Music</p>
            <Tooltip
              delay={100}
              place="top"
              id="included_content"
              light
              content={INCLUDED_CONTENT}
            />
            <span>
              {" "}
              {isFastFlow
                ? get(this.state.currentData, "display", "")
                : get(this.state.currentData, "works", "")}
            </span>
          </div>
        </p>
        <InputRangeSlider
          min={MIN_VALUE}
          max={getMaxValue(
            this.state.advanceData,
            {
              isPartners,
              isPublisher,
              value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
              publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
            },
            CATALOG_SLIDER,
          )}
          dots
          defaultValue={this.state.newTracks}
          value={this.state.newTracks}
          onAfterChange={(val) => {
            if (
              val !== CATALOG_TYPE[0] &&
              get(this.state, "defaultOffer.cashflow_option_selected", "") ===
                CASHFLOW.LOWER
            ) {
              this.setState(
                (prevState) => ({
                  newTracks: val,
                  defaultOffer: {
                    ...prevState.defaultOffer,
                    cashflow_option_selected: CASHFLOW.STANDARD,
                  },
                }),
                changeSliderCallback,
              );
            } else {
              this.setState({ newTracks: val }, changeSliderCallback);
            }
          }}
          disabled={
            !getMaxValue(
              this.state.advanceData,
              {
                isPartners,
                isPublisher,
                value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
                publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
              },
              CATALOG_SLIDER,
            )
          }
          data-testid="test-min"
        />
      </div>
      <div className={styles.sliderBox}>
        <p
          className={`${styles.contentname} ${
            this.state.navigationStatus.isFastFlow ? styles.fastContentName : ""
          }`}
        >
          <div className={styles.sliderData}>
            <p>Length of Contract {isPartners && "*"}</p>
            <Tooltip delay={100} place="top" id="term" light content={TERM} />
            <span> {renderTermValue(this.state.term, isFastFlow)} years</span>
          </div>
        </p>
        <InputRangeSlider
          min={TERM_MIN}
          max={getMaxTermValue(
            this.state.advanceData,
            {
              isPartners,
              isPublisher,
              value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
              publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
            },
            this.state.newTracks,
          )}
          marks={getAdvanceKeys(
            this.state.advanceData,
            {
              isPartners,
              isPublisher,
              value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
              publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
            },
            TERM_SLIDER,
            this.state.newTracks,
          )}
          defaultValue={this.state.term}
          step={null}
          value={this.state.term}
          data-testid="test-term"
          onAfterChange={(val) => {
            if (val === TERM_20) {
              this.setState(
                (prevState) => ({
                  term: val,
                  defaultOffer: {
                    ...prevState.defaultOffer,
                    cashflow_option_selected: CASHFLOW.HIGHER,
                  },
                }),
                changeSliderCallback,
              );
            } else {
              this.setState({ term: val }, changeSliderCallback);
            }
          }}
          disabled={
            !getMaxValue(
              this.state.advanceData,
              {
                isPartners,
                isPublisher,
                value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
                publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
              },
              TERM_SLIDER,
              this.state.newTracks,
            )
          }
        />
      </div>
      <div className={`${styles.sliderBox} ${styles.lastSliderBox}`}>
        <p
          className={`${styles.contentname} ${
            this.state.navigationStatus.isFastFlow ? styles.fastContentName : ""
          }`}
        >
          <div className={styles.sliderData}>
            <p>Income paid to artist during the term</p>
            <Tooltip
              delay={100}
              place="top"
              id="share_of_catalog"
              light
              content={SHARE_OF_CATALOG}
            />
            <span>
              {" "}
              {isFastFlow ? this.state.flowthrough : this.state.catalogIncome}%
            </span>
          </div>
        </p>
        <InputRangeSlider
          min={MIN_VALUE}
          max={MAX_VALUE}
          marks={getAdvanceKeys(
            this.state.advanceData,
            {
              isPartners,
              isPublisher,
              value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
              publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
            },
            C_INC_SLIDER,
            this.state.newTracks,
            this.state.term,
          )}
          defaultValue={
            isFastFlow ? this.state.flowthrough : this.state.catalogIncome
          }
          step={null}
          value={isFastFlow ? this.state.flowthrough : this.state.catalogIncome}
          onAfterChange={(val) => {
            if (isFastFlow) {
              this.setState({ flowthrough: val }, changeSliderCallback);
            } else {
              this.setState({ catalogIncome: val }, changeSliderCallback);
            }
          }}
          disabled={
            !getMaxValue(
              this.state.advanceData,
              {
                isPartners,
                isPublisher,
                value: get(this.state, "selectedPartner") || DEFAULT_PARTNER,
                publisherVal: get(this, "state.rightsType") || DEFAULT_RIGHTS,
              },
              C_INC_SLIDER,
              this.state.newTracks,
              this.state.term,
            )
          }
        />
      </div>
      {isModifiedFlow(this.props, this.state.isCashFlow) && (
        <div className={`${styles.sliderBox} ${styles.cashflow}`}>
          <p className={styles.contentname}>
            <div className={styles.sliderData}>
              <p>
                If Advance Is Recouped Before{" "}
                <span className={styles.cashflowTerm}>
                  {" "}
                  {this.state.term} years,
                </span>{" "}
                Artist Receives:
              </p>
              <Tooltip
                delay={100}
                place="top"
                id="cashflow"
                light
                content={CASHFLOW_CONTENT}
              />
            </div>
          </p>
          <div className={styles.cashflowDiv}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={
                this.state.term === TERM_20
                  ? CASHFLOW.HIGHER
                  : CASHFLOW.STANDARD
              }
              className={styles.radioGroup}
              onChange={(e) => {
                const cashFlow = e.target.value;
                this.setState(
                  (prevState) => ({
                    defaultOffer: {
                      ...prevState.defaultOffer,
                      cashflow_option_selected: cashFlow,
                    },
                  }),
                  changeSliderCallback,
                );
              }}
              value={
                get(this.state, "defaultOffer.cashflow_option_selected") ||
                CASHFLOW.STANDARD
              }
              data-testid="id-radio"
            >
              <FormControlLabel
                value={CASHFLOW.LOWER}
                control={<Radio />}
                label={getCashflowDiv(
                  get(this.state, "currentData.income_catalog_post_term", 0),
                  0,
                  this.state.currentData?.works !== CATALOG_TYPE[0],
                )}
                labelPlacement="start"
                className={`${styles.radio} ${
                  get(
                    this.state,
                    "defaultOffer.cashflow_option_selected",
                    CASHFLOW.STANDARD,
                  ) === CASHFLOW.LOWER && styles.active
                }`}
                disabled={
                  this.state.currentData?.works !== CATALOG_TYPE[0] ||
                  this.state.term === TERM_20
                }
              />
              <FormControlLabel
                value={CASHFLOW.STANDARD}
                control={<Radio />}
                label={getCashflowDiv(
                  get(this.state, "currentData.artist_share_post_recoup", 0),
                  1,
                )}
                labelPlacement="start"
                className={`${styles.radio} ${
                  get(
                    this.state,
                    "defaultOffer.cashflow_option_selected",
                    CASHFLOW.STANDARD,
                  ) === CASHFLOW.STANDARD && styles.active
                }`}
                disabled={this.state.term === TERM_20}
              />
              <FormControlLabel
                value={CASHFLOW.HIGHER}
                control={<Radio />}
                label={getCashflowDiv(
                  get(this.state, "currentData.artist_share_pre_recoup", 0),
                  2,
                )}
                labelPlacement="start"
                className={`${styles.radio} ${
                  get(
                    this.state,
                    "defaultOffer.cashflow_option_selected",
                    CASHFLOW.STANDARD,
                  ) === CASHFLOW.HIGHER && styles.active
                }`}
              />
            </RadioGroup>
          </div>
        </div>
      )}
    </div>
  );
}

export const getCashflowDiv = (value, id, disabled = false) => (
  <div className={styles.mainDiv}>
    <p>
      <div>
        <span>{value}%</span> of Income{" "}
      </div>
      <Tooltip
        delay={100}
        place="top"
        id={`term${id}`}
        light
        content={CASHFLOW_OPTIONS[id].tooltip}
      />
    </p>
    <span className={styles.option}>{CASHFLOW_OPTIONS[id].label}</span>
  </div>
);

export const isModifiedFlow = (props, isCashFlow) =>
  get(props, "history.location.pathname") === OFFER_SUMMARY && isCashFlow
    ? true
    : false;

export const getModifiedValue = (props, cashflow_option_selected) => {
  switch (cashflow_option_selected) {
    case CASHFLOW.LOWER:
      return {
        catalog_advance: get(props, "catalog_advance_blue", 0),
        nr_advance: get(props, "nr_advance_blue", 0),
        option_advance: get(props, "option_advance_blue", 0),
        total_advance: get(props, "total_advance_blue", 0),
        post_recoup: get(props, "income_catalog_post_term", 0),
        royalty: get(props, "royalty_blue", 0) * 100,
      };
    case CASHFLOW.HIGHER:
      return {
        catalog_advance: get(props, "catalog_advance_green", 0),
        nr_advance: get(props, "nr_advance_green", 0),
        option_advance: get(props, "option_advance_green", 0),
        total_advance: get(props, "total_advance_green", 0),
        post_recoup: get(props, "artist_share_pre_recoup", 0),
        royalty: get(props, "royalty_green", 0) * 100,
      };
    case CASHFLOW.STANDARD:
      return {
        catalog_advance: get(props, "catalog_advance", 0),
        nr_advance: get(props, "nr_advance", 0),
        option_advance: get(props, "option_advance", 0),
        total_advance: get(props, "total_advance", 0),
        post_recoup: get(props, "artist_share_post_recoup", 0),
        royalty: parseInt(get(props, "royalty", 0)),
      };
    default:
      return {
        catalog_advance: get(props, "catalog_advance", 0),
        nr_advance: get(props, "nr_advance", 0),
        option_advance: get(props, "option_advance", 0),
        total_advance: get(props, "total_advance", 0),
        post_recoup: get(props, "artist_share_post_recoup", 0),
        royalty: parseInt(get(props, "royalty", 0)),
      };
  }
};

export const PUBLISHING_TOGGLE_TERM_VALUE = 3;
export const PUBLISHING_TOGGLE_MIN_VALUE = 15000;
export const PUBLISH_ADVANCE_MIN_VALUE = 10000;
export const TERM_20_YEAR_MIN_VALUE = 40000;

export const checkTermCondition = (val) =>
  Number(get(val, "flow_through.240.0.min")) > TERM_20_YEAR_MIN_VALUE ||
  Number(get(val, "c.240.0.min")) > TERM_20_YEAR_MIN_VALUE;

export const checkPublishAdvance = (val) =>
  (Number(get(val, "flow_through.12.0.min")) > PUBLISH_ADVANCE_MIN_VALUE ||
    Number(get(val, "c.12.0.min")) > PUBLISH_ADVANCE_MIN_VALUE) &&
  BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1;

export const FinePrintModal = (props) => {
  const themeCtx = useContext(ThemeContext);
  const getText = () =>
    get(window, "location.pathname") === OFFER_SUMMARY ? (
      <p className={styles.lastFooter}>
        Artist royalties apply to streaming income and SoundExchange revenues,
        but do not apply to revenue from physical formats (vinyl, CD,
        cassettes).
      </p>
    ) : (
      <p className={styles.lastFooter}>
        Advance terms are estimated based on publicly available data and
        ownership information provided. Final terms will be made after
        confirming key information with your distributor. Advance terms may
        increase or decrease after this confirmation step. Artist royalties
        apply to digital distribution income and SoundExchange revenues, but do
        not apply to revenue from physical formats (vinyl, CD, cassettes).
      </p>
    );
  return (
    <ReactModal
      isOpen={get(props, "isOpen")}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={get(props, "onRequestClose")}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.moreFundingmodalOverlay}
    >
      <div className={styles.offerContainer}>
        <button
          data-testid="fine-print-close"
          className={styles.closeBtn}
          onClick={get(props, "onRequestClose")}
        >
          &times;
        </button>
        <div className={styles.finePrintText}>
          {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 ? (
            <>
              {getText()}
              <p>
                A fee of $278 plus 2.8% of your advance will be added to your
                advance balance to be recouped out of your digital distribution
                income.
              </p>
            </>
          ) : (
            <p className={styles.whitelabelFinePrint}>
              {get(themeCtx, "finePrintText")}
            </p>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export const RejectOfferSurveyModal = (props) => (
  <ReactModal
    isOpen={get(props, "isOpen")}
    shouldCloseOnOverlayClick
    shouldCloseOnEsc
    onRequestClose={get(props, "onRequestClose")}
    className={styles.rejectPopupContainer}
    overlayClassName={styles.moreFundingmodalOverlay}
  >
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.titleCtn}>
          <p className={styles.heading}>Not interested in your offer?</p>
          <p className={styles.subHeading}>Help us understand why</p>
        </div>
        <button
          data-testid="reject-offer-close"
          className={styles.closeBtn}
          onClick={get(props, "onRequestClose")}
        >
          &times;
        </button>
      </div>
      <div className={styles.surveyContainer}>
        <SurveySparrow
          data={{
            email_address: get(props, "email"),
            artist_name: get(props, "name"),
          }}
          isRejected={true}
        />
      </div>
    </div>
  </ReactModal>
);
