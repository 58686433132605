import base from "base-64";

const getDocument = () =>
  typeof document === "undefined"
    ? {
        querySelector() {
          return null;
        },
      }
    : document;
const AuthCookies = {
  set(key, value, opts = {}) {
    let storedValue = value;
    if (opts.stringify) {
      storedValue = encodeURIComponent(JSON.stringify(storedValue));
    }
    if (opts.hash) {
      storedValue = base.encode(storedValue);
    }
    try {
      const d = new Date();
      d.setTime(d.getTime() + 3600000);
      const expires = `expires=${d.toUTCString()}`;
      getDocument().cookie = `${key}=${JSON.stringify({
        opts,
        body: storedValue,
      })};${expires};path=/`;
    } catch (err) {
      throw err;
    }
  },
  get(key) {
    const name = `${key}=`;
    const decodedCookie = getDocument().cookie;
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const data = JSON.parse(c.substring(name.length, c.length));
        let { body } = data;
        if (data.opts && data.opts.hash) {
          body = base.decode(body);
        }
        if (data.opts && data.opts.stringify) {
          body = JSON.parse(decodeURIComponent(body));
        }
        return body;
      }
    }
    return false;
  },
  delete(key, opts = {}) {
    const d = new Date();
    getDocument().cookie = `${key}=${JSON.stringify({
      opts,
      body: "",
    })}; path=/; expires=${d};`;
    localStorage.removeItem(key);
  },
};

export default AuthCookies;
