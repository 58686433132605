import React, { Component } from "react";
import { get } from "lodash";
import styles from "./LoginSideBar.module.scss";
import PropTypes from "prop-types";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Image from "../Image";
import { CHANGE_PASS, FORGOT_PASS, LOGIN, PRIVACY } from "../../routes/pages/constants";
import { Link } from "react-router-dom";
import { NAVIGATION_ICONS, mobileNavigationStyle } from "../../component/HorizontalMenu/constants";
import ReactSelect from "react-select";
import { AuthSideBar } from "./constants";


class LoginSideBar extends Component {
    constructor (props) {
        super(props);
    }

    handleImageClick = () => {
        const urlArr = get(this.context, "whitelistUrl", []);
        const URL = urlArr[0];
        if (typeof URL !== "undefined") {
            window.location.href = URL;
        }
    };

    handleNavigateScreen = (option) => {
        if (option.value !== "OPEN_MODAL") {
            this.props.history.push(option.value);
        }
    };

    getMobileSelectMenu = () => {
        if (get(this.props, 'history.location.pathname') === LOGIN) {
            return AuthSideBar.LOGIN;
        } else if (get(this.props, 'history.location.pathname') === FORGOT_PASS) {
            return AuthSideBar.FORGOTPASS;
        } else if (get(this.props, 'history.location.pathname').includes(CHANGE_PASS)) {
            return AuthSideBar.CHANGEPASS;
        } else if (get(this.props, 'history.location.pathname') === PRIVACY) {
            return AuthSideBar.PRIVACYPOLICY;
        } else {
            return "";
        }
    };

    render () {
        return (
            <>
                <div className={`${styles.container}`}>
                    <div className={styles.sideBarContainer}>
                        <div
                            onClick={this.handleImageClick}
                            className={styles.logoContainer}
                            data-testid="logoClick"
                        >
                            <Image
                                src={get(this.context, "appLogo", "")}
                                className={styles.logo}
                                alt="Logo"
                            />
                        </div>
                        {!(get(this.context, "maintenanceMode.isUnderMaintenance") &&
                            get(this.context, "maintenanceMode.allApps.status")) &&
                            (<>
                                <div className={styles.menuContainer}>
                                    <Link
                                        to={LOGIN}
                                        className={`${styles.menuItem} ${get(this.props, 'history.location.pathname') === LOGIN && styles.active
                                            }`}>
                                        <div className={styles.options}>
                                            <div className={styles.iconContainer}>
                                                <NAVIGATION_ICONS.LOGIN />
                                            </div>
                                            <p className={styles.linkTitle}>Log In</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className={styles.mobileMenuContainer}>
                                    <ReactSelect
                                        options={[{
                                            label: AuthSideBar.LOGIN,
                                            value: LOGIN
                                        }]}
                                        classNamePrefix="navigation"
                                        onChange={this.handleNavigateScreen}
                                        isSearchable={false}
                                        value={{
                                            label: this.getMobileSelectMenu(),
                                            value: get(this.props, 'history.location.pathname'),
                                        }}
                                        components={{ IndicatorSeparator: null }}
                                        styles={mobileNavigationStyle}
                                    />
                                </div>
                            </>)}
                    </div>
                </div >
            </>
        )
    }
}

LoginSideBar.contextType = ThemeContext;

export default LoginSideBar;

LoginSideBar.contextTypes = {
    whitelistUrl: PropTypes.array,
};
