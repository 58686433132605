import React, { Component } from "react";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import { getSubDomain } from "../ThemeManager/helper";
import { errorPopup } from "./helper";

const ErrorContext = React.createContext({});

export class ErrorPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      setIsOpen: null,
    };
  }

  componentDidMount() {
    this.setState({ setIsOpen: this.setIsOpen });
  }

  setIsOpen = () => {
    this.setState({ isOpen: true });
  };

  setIsClose = () => {
    this.setState({ isOpen: false });
  };

  getDomainName = () => {
    return BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
      ? "beatbread.com"
      : "chordcash.com";
  };

  render() {
    return (
      <>
        <ErrorContext.Provider value={this.state}>
          {this.props.children}
        </ErrorContext.Provider>
        {errorPopup.bind(this)()}
      </>
    );
  }
}

export default ErrorPopup;
export { ErrorContext };
