import React from "react";
import styles from "./Loader.module.scss";
import { ReactComponent as CcLoader } from "../../assets/logos/loader-animation.svg";
import bbLoader from "../../img/loading-bars.gif";
import { getSubDomain } from "../ThemeManager/helper";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";

const ShortCallLoader = ({ text, loaderTitle, icon, defaultLoader }) => {
  return (
    <div className={`${styles.container} ${styles.themeBg}`}>
      <div className={styles.box}>
        <div className={styles.textContent}>
          <div>{icon && <img src={icon} alt="loaderIcon" />}</div>
          {!!defaultLoader && (
            <div className={styles.logoContainer}>
              {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 ? (
                <CcLoader />
              ) : (
                <img src={bbLoader} alt="loaderIcon" />
              )}
            </div>
          )}
          <h3>{`${loaderTitle || "Just a moment."}`}</h3>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default ShortCallLoader;
