/* eslint-disable no-useless-escape */
import React from "react";
import * as Yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import whiteLabelStyles from "./WhiteLabel.module.scss";
import { DEFAULT_BB_THEME } from "../../../../component/ThemeManager/constants";
import FormField from "../../../../component/FormField/FormField";
import {
  FIELD_NAMES,
  MAPPING_FIELDS,
  GENERAL_FIELD_VALIDATION_MESSAGE,
  FIELD_MAX_CHARACTERS,
  FIELD_REGEX,
  COLOR_CODES,
  ASSETS_IDS,
  DEFAULT_RESETPASSWORD_LIST_HTML,
} from "./constants";
import generalColors from "../../../../styles/global.scss";
import { ADMIN_WHITELABEL_EDIT_ACCOUNT } from "../../constants";
import { ReactComponent as IconMonitor } from "../../../../assets/logos/emailAssets/iconMonitor.svg";
import { ReactComponent as IconSlider } from "../../../../assets/logos/emailAssets/iconSlider.svg";
import { ReactComponent as IconDiamond } from "../../../../assets/logos/emailAssets/iconDiamond.svg";
import { ReactComponent as IconLocker } from "../../../../assets/logos/emailAssets/iconLocker.svg";
import { ReactComponent as IconCash } from "../../../../assets/logos/emailAssets/iconCash.svg";
import { ReactComponent as IconPunctuation } from "../../../../assets/logos/emailAssets/iconPunctuation.svg";
import Switch from "../Switch";
import { get, cloneDeep } from "lodash";

let FIELD_VALIDATION_MESSAGES = {};

export const maxCharsValidMessage = (label, maxChars) =>
  `${label} cannot exceed ${maxChars} characters`;

export const IMG_EXTENSIONS_REGEX =
  /^(.*?).(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG|svg\+xml)$/;
export const FAVICON_EXTENSIONS_REGEX =
  /^(.*?).(jpg|JPG|jpeg|JPEG|png|PNG|svg|SVG|svg\+xml|gif|GIF|ico|ICO)$/;
export const PDF_REGEX = /^(.*?).(pdf|PDF)$/;
export const IMG_MAX_SIZE = 250000;
export const FAVICON_MAX_SIZE = 5000;
export const INSTRUCTION_MAX_SIZE = 5000000;
export const IMG_RATIO_LIMIT = {
  MAX: 3,
  MIN: 0.4,
};
export const FAVICON_RATIO_LIMIT = { MAX: 1, MIN: 1 };
export const IMG_VALIDATION_MESSAGES = {
  SIZE: "File size should be less than 250KB",
  TYPE: "File type should be jpg / jpeg, png, svg only",
  DIMENSION: "Aspect ratio should be 3:1",
  FAVICON_SIZE: "File size should be less than 5KB",
  FAVICON_TYPE: "File type should be jpg / jpeg, png, svg, gif, ico only",
  FAVICON_DIMENSION: "Aspect ratio should be 1:1",
};
export const PDF_VALIDATION_MESSAGE = {
  SIZE: "File size should be less than 5MB",
  TYPE: "File type should be PDF only",
};

export const IMG_NOT_AVAILABLE_CASES = ["", null, undefined];

export const URL_PREFIX = "http://";

export const DOCUMENT_TITLE = {
  EDIT_DOCUMENT: "beatBread: Edit Whitelabel",
  CREATE_DOCUMENT: "beatBread: Create Whitelabel",
};

export const checkForTooltipValue = (tooltipValue) =>
  tooltipValue && tooltipValue.length > 0;
/**
 * setFieldsMapping
 * @param {string} mapping
 * @param {object} data
 * @returns
 */
export const setFieldsMapping = (mapping, data) => {
  const fieldValidationObject = {};
  for (
    let index = 0;
    index <
    FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS.length;
    index++
  ) {
    if (mapping === MAPPING_FIELDS.VALIDATION_MESSAGE) {
      fieldValidationObject[index] = {
        LABEL: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index].LABEL.LABEL}`,
        TOOLTIP: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index].TOOLTIP.LABEL}`,
      };
    }
    if (mapping === MAPPING_FIELDS.INITIAL_VALUES) {
      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].LABEL.NAME
      ] = "";
      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].TOOLTIP.NAME
      ] = "";
      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].REQUIRED.NAME
      ] = true;
    }
    if (mapping === MAPPING_FIELDS.VALIDATION_SCHEMA) {
      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].LABEL.NAME
      ] = Yup.string()
        .when(
          [
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
              .TOOLTIP.NAME,
          ],
          {
            is: checkForTooltipValue,
            then: Yup.string().required(),
          },
        )
        .label(
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .LABEL.LABEL,
        )
        .max(
          FIELD_MAX_CHARACTERS.REPORT_ADDITIONAL_FIELDS.FIELDS.LABEL,
          maxCharsValidMessage(
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
              .LABEL.LABEL,
            FIELD_MAX_CHARACTERS.REPORT_ADDITIONAL_FIELDS.FIELDS.LABEL,
          ),
        )
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          FIELD_VALIDATION_MESSAGES.REPORT_ADDITIONAL_FIELDS.INDIVIDUAL_FIELDS[
            index
          ].LABEL,
        );

      //tooltip

      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].TOOLTIP.NAME
      ] = Yup.string()
        .label(
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .TOOLTIP.LABEL,
        )
        .max(
          FIELD_MAX_CHARACTERS.REPORT_ADDITIONAL_FIELDS.FIELDS.TOOLTIP,
          maxCharsValidMessage(
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
              .TOOLTIP.LABEL,
            FIELD_MAX_CHARACTERS.REPORT_ADDITIONAL_FIELDS.FIELDS.TOOLTIP,
          ),
        )
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          FIELD_VALIDATION_MESSAGES.REPORT_ADDITIONAL_FIELDS.INDIVIDUAL_FIELDS[
            index
          ].TOOLTIP,
        );
    }
    if (mapping === MAPPING_FIELDS.API_VALUES) {
      const label = data[`reportFields`][`label${index + 1}`];
      const tooltip = data[`reportFields`][`tooltip${index + 1}`];
      const isRequired = data[`reportFields`][`isField${index + 1}Required`];
      fieldValidationObject[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
          index
        ].REQUIRED.NAME
      ] = isRequired === undefined || isRequired;
      if (label) {
        fieldValidationObject[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
            index
          ].LABEL.NAME
        ] = label;
        if (tooltip) {
          fieldValidationObject[
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
              index
            ].TOOLTIP.NAME
          ] = tooltip;
        } else {
          fieldValidationObject[
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
              index
            ].TOOLTIP.NAME
          ] = "";
        }
      } else {
        fieldValidationObject[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
            index
          ].LABEL.NAME
        ] = "";
        fieldValidationObject[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[
            index
          ].TOOLTIP.NAME
        ] = "";
      }
    }
  }
  return fieldValidationObject;
};

FIELD_VALIDATION_MESSAGES = {
  DISPLAY_NAME: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DISPLAY_NAME.LABEL}`,
  SLUG_NAME: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.SLUG_NAME.LABEL}`,
  CTA_DEFAULT_VALUE: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.CTA_DEFAULT_VALUE.LABEL}`,
  CTA_BUTTON_TEXT: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.CTA_BUTTON_TEXT.LABEL}`,
  CTA_ACTION_URL: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.CTA_ACTION_URL.LABEL}`,
  LANDING_PAGE_URL: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.LANDING_PAGE_URL.LABEL}`,
  FINE_PRINT_TEXT: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.FINE_PRINT_TEXT.LABEL}`,
  SIDEBAR_TEXT: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.SIDEBAR_TEXT.LABEL}`,
  OFFER_SUMMARY_TEXT: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.OFFER_SUMMARY_TEXT.LABEL}`,
  GET_CONTRACT_US_URL: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.GET_CONTRACT_US_URL.LABEL}`,
  GET_CONTRACT_INTERNATIONAL_URL: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.LABEL}`,
  REPORT_ADDITIONAL_FIELDS: {
    INDIVIDUAL_FIELDS: setFieldsMapping(MAPPING_FIELDS.VALIDATION_MESSAGE),
  },
  WHITE_LISTED_URLS: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.WHITE_LISTED_URLS.LABEL}`,
};

export const objToArray = (colorObj) => {
  const colorArr = [];
  for (const key in colorObj) {
    colorArr.push({ label: key, value: colorObj[key] });
  }
  return colorArr;
};

export const arrayToObj = (arr) => {
  const obj = {};
  arr.forEach((value) => {
    obj[value.label] = value.value;
  });
  return obj;
};

const BASIC_COLOR_ARRAY = [
  { label: "Color 1", value: "#5636D0" },
  {
    label: "Color 2",
    value: "#000000",
  },
  {
    label: "Color 3",
    value: "#FFFFFF",
  },
];

export const FIELD_INITIAL_VALUES = {
  // name and logo
  [FIELD_NAMES.DISPLAY_NAME.NAME]: "",
  [FIELD_NAMES.SLUG_NAME.NAME]: "",

  // thank - you CTA
  [FIELD_NAMES.CTA_DEFAULT_VALUE.NAME]:
    "Thank you for completing your application. Our team will analyze the reports you uploaded and we will be in touch within 2 business days. In the meantime, please check out our FAQs.",
  [FIELD_NAMES.CTA_BUTTON_TEXT.NAME]: "Read our FAQs",
  [FIELD_NAMES.CTA_ACTION_URL.NAME]: "https://[INSERT SLUG].chordcash.com/faqs",

  [FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME]: "",
  [FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME]: "",

  // wordpress domain url
  [FIELD_NAMES.LANDING_PAGE_URL.NAME]:
    "https://app.[INSERT SLUG].chordcash.com",

  // whitelisted urls
  [FIELD_NAMES.WHITE_LISTED_URLS.NAME]: ["https://[INSERT SLUG].chordcash.com"],

  // send reports additional fields
  ...setFieldsMapping(MAPPING_FIELDS.INITIAL_VALUES),

  // fine print text
  [FIELD_NAMES.FINE_PRINT_TEXT.NAME]:
    "Advance terms are estimated based on publicly available data and ownership information provided. Final terms will be made after confirming key information with your distributor. Advance terms may increase or decrease after this confirmation step. Artist royalties apply to streaming income and SoundExchange revenues, but do not apply to revenue from physical formats (vinyl, CD, cassettes). A fee of $278 plus 2.8% of your advance will be added to your advance balance to be recouped out of your streaming income.",

  // sidebar text
  [FIELD_NAMES.SIDEBAR_TEXT.NAME]: "",

  // Offer summary text
  [FIELD_NAMES.OFFER_SUMMARY_TEXT
    .NAME]: `<p style="background-color: lightgrey; padding: 20px;">These advance amounts are based on the terms you chose earlier. You can still adjust these terms by clicking the "Adjust my terms" link below.</p>`,

  [FIELD_NAMES.GET_CONTRACT_US_URL.NAME]:
    "https://forms.monday.com/forms/7d0fdb5f9cc6f99acbe4ad7f8a86c6b8?r=use1",
  [FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME]:
    "https://forms.monday.com/forms/295dbb79aff9ad529318a3b649481a7b?r=use1",
  // color-codes
  colorCodes: {
    general: {
      primaryColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      ctaFontColor: DEFAULT_BB_THEME.ctaFontColor,
    },
    email: {
      headerFooterColor: DEFAULT_BB_THEME.blackColor,
      ctaColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      fontColorOne: DEFAULT_BB_THEME.blackColor,
      fontColorTwo: DEFAULT_BB_THEME.whiteColor,
      boxColor: DEFAULT_BB_THEME.blackColor,
      iconColor: DEFAULT_BB_THEME.updatedPrimaryColor,
    },
    register: {
      mainBackgroundColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      headerBackgroundColor: DEFAULT_BB_THEME.blackColor,
      headerFontColor: DEFAULT_BB_THEME.whiteColor,
      accentTextColor: DEFAULT_BB_THEME.blackColor,
      ctaColor: DEFAULT_BB_THEME.updatedPrimaryColor,
    },
    mainFunnel: {
      sidebar: {
        headerFooterAccentColor: DEFAULT_BB_THEME.updatedPrimaryColor,
        primaryColor: DEFAULT_BB_THEME.blackColor,
        fontColorOne: DEFAULT_BB_THEME.whiteColor,
        fontColorTwo: DEFAULT_BB_THEME.whiteColor,
        toolTipIconColor: DEFAULT_BB_THEME.updatedPrimaryColor,
        textIconColor: DEFAULT_BB_THEME.whiteColor,
        cardButtonColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      },
      progressionStatus: {
        accentColor: DEFAULT_BB_THEME.whiteColor,
        reviewUpdatesColor: DEFAULT_BB_THEME.whiteColor,
      },
      alertsAndToolTips: {
        toolTipsBackground: DEFAULT_BB_THEME.updatedPrimaryColor,
        toolTipsFontColor: DEFAULT_BB_THEME.blackColor,
        alertBackground: DEFAULT_BB_THEME.blackColor,
        alertFontColor: DEFAULT_BB_THEME.whiteColor,
      },
      content: {
        sliderColor: DEFAULT_BB_THEME.updatedPrimaryColor,
        helpTextColor: DEFAULT_BB_THEME.updatedPrimaryColor,
        fundCtaFontColor: DEFAULT_BB_THEME.whiteColor,
        fundCtaBgColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      },
      spotify: {
        partnerCardBgColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      },
      login: {
        navigationBgColor: DEFAULT_BB_THEME.whiteColor,
        navigationTextColor: DEFAULT_BB_THEME.blackColor,
        bgColor: DEFAULT_BB_THEME.updatedPrimaryColor,
      },
    },
  },
  [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
  [FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME]: false,
  [FIELD_NAMES.RESET_PASSWORD_MODAL.NAME]: "",
  [FIELD_NAMES.COLOR_SETS.NAME]: BASIC_COLOR_ARRAY,
};

export const DOMAIN_NAMES = { BEATBREAD: "beatbread", CHORDCASH: "chordcash" };

export const renderEachAdditionalField = (
  field,
  key,
  values,
  setFieldValue,
) => {
  return (
    <>
      {/* Label */}
      <div
        className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        key={`${key}_label`}
      >
        <FormField
          name={field.LABEL.NAME}
          type="text"
          placeholder={
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.LABEL.PLACEHOLDER
          }
          label={`${field.LABEL.LABEL}`}
        />
      </div>
      {/* Tooltip */}
      <div
        className={`form-group ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        key={`${key}_tooltip`}
      >
        <FormField
          as="textarea"
          name={field.TOOLTIP.NAME}
          type="text"
          placeholder={
            FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.TOOLTIP.PLACEHOLDER
          }
          label={`${field.TOOLTIP.LABEL}`}
        />
      </div>
      {values[field.LABEL.NAME] ? (
        <div
          className={`form-group ${whiteLabelStyles.formGroup} ${whiteLabelStyles.switchFormGroup}`}
          key={`${key}_switch`}
        >
          <span>{field.REQUIRED.LABEL}</span>
          <Switch
            id={`requiredToggle${key}`}
            title="Required/Optional field toggle"
            isOn={values[field.REQUIRED.NAME]}
            onColor={generalColors.primaryColor}
            data-testid={`requiredToggle${key}`}
            labelClassName={whiteLabelStyles.requiredSwitch}
            handleToggle={(e) => {
              setFieldValue(field.REQUIRED.NAME, !values[field.REQUIRED.NAME]);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export const getDomainName = (url) => {
  const match = url.match(FIELD_REGEX.UNIQUE_DOMAIN_NAME);
  if (match && match.length > 1) {
    return match[1];
  }
};

export function checkForUniqueURLs(value) {
  if (value && this && this.parent && this.parent.length > 0) {
    const domainForValue = getDomainName(value.toLowerCase());
    const filteredValues = this.parent.filter((currentValues) => {
      const currentDomainName =
        currentValues && getDomainName(currentValues.toLowerCase());
      return currentValues && currentDomainName === domainForValue;
    });
    if (filteredValues.length > 1) {
      return false;
    }
  }
  return true;
}

export function checkForColorLabelValidation(value) {
  if (value && this && this.parent) {
    const result = get(this, "from[1].value.colorSet", []).filter(
      (color) => color.label.toLowerCase() === value.toLowerCase(),
    );
    if (result && result.length > 1) {
      return false;
    }
  }
  return true;
}
export function checkForColorValueValidation(value) {
  if (value && this && this.parent) {
    const result = get(this, "from[1].value.colorSet", []).filter(
      (color) => color.value.toLowerCase() === value.toLowerCase(),
    );
    if (result && result.length > 1) {
      return false;
    }
  }
  return true;
}
const HEX_REGEX = /^#[a-zA-Z0-9]{0,6}$/;

const colorValidation = Yup.object().shape({
  label: Yup.string()
    .required(FIELD_NAMES.COLOR_SETS.FIELD.COLOR.REQ_MSG)
    .label(FIELD_NAMES.COLOR_SETS.FIELD.COLOR.LABEL)
    .max(30, FIELD_NAMES.COLOR_SETS.FIELD.COLOR.MAX_MSG)
    .test(
      "is-unique-name",
      FIELD_NAMES.COLOR_SETS.FIELD.COLOR.UNIQUE_MSG,
      checkForColorLabelValidation,
    ),
  value: Yup.string()
    .required()
    .label(FIELD_NAMES.COLOR_SETS.FIELD.HEX_CODE.LABEL)
    .max(
      FIELD_NAMES.COLOR_SETS.FIELD.HEX_CODE.FIELD_MAX_CHARACTERS,
      maxCharsValidMessage(
        "Hex Code",
        FIELD_NAMES.COLOR_SETS.FIELD.HEX_CODE.FIELD_MAX_CHARACTERS - 1,
      ),
    )
    .matches(HEX_REGEX, FIELD_NAMES.COLOR_SETS.FIELD.HEX_CODE.FORMAT_MSG)
    .test(
      "is-unique-code",
      "Hex Code should be unique",
      checkForColorValueValidation,
    ),
});

export const validationSchema = Yup.object().shape({
  [FIELD_NAMES.DISPLAY_NAME.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.DISPLAY_NAME.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.DISPLAY_NAME,
      maxCharsValidMessage(
        FIELD_NAMES.DISPLAY_NAME.LABEL,
        FIELD_MAX_CHARACTERS.DISPLAY_NAME,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.DISPLAY_NAME,
    ),
  [FIELD_NAMES.SLUG_NAME.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.SLUG_NAME.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.SLUG_NAME,
      maxCharsValidMessage(
        FIELD_NAMES.SLUG_NAME.LABEL,
        FIELD_MAX_CHARACTERS.SLUG_NAME,
      ),
    )
    .matches(FIELD_REGEX.SLUG_NAME, FIELD_VALIDATION_MESSAGES.SLUG_NAME),
  [FIELD_NAMES.CTA_DEFAULT_VALUE.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.CTA_DEFAULT_VALUE.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.CTA_DEFAULT_VALUE,
      maxCharsValidMessage(
        FIELD_NAMES.CTA_DEFAULT_VALUE.LABEL,
        FIELD_MAX_CHARACTERS.CTA_DEFAULT_VALUE,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.CTA_DEFAULT_VALUE,
    ),
  [FIELD_NAMES.CTA_BUTTON_TEXT.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.CTA_BUTTON_TEXT.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.CTA_BUTTON_TEXT,
      maxCharsValidMessage(
        FIELD_NAMES.CTA_BUTTON_TEXT.LABEL,
        FIELD_MAX_CHARACTERS.CTA_BUTTON_TEXT,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.CTA_BUTTON_TEXT,
    ),
  [FIELD_NAMES.CTA_ACTION_URL.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.CTA_ACTION_URL.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.CTA_ACTION_URL,
      maxCharsValidMessage(
        FIELD_NAMES.CTA_ACTION_URL.LABEL,
        FIELD_MAX_CHARACTERS.CTA_ACTION_URL,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_ACTION_URL,
      FIELD_VALIDATION_MESSAGES.CTA_ACTION_URL,
    ),
  [FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME]: Yup.string()
    .label(FIELD_NAMES.ACK_SCREEN_CTA_TEXT.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.CTA_BUTTON_TEXT,
      maxCharsValidMessage(
        FIELD_NAMES.ACK_SCREEN_CTA_TEXT.LABEL,
        FIELD_MAX_CHARACTERS.CTA_BUTTON_TEXT,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.CTA_BUTTON_TEXT,
    ),
  [FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME]: Yup.string()
    .when([FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME], {
      is: (val) => !!val,
      then: Yup.string().required(),
    })
    .label(FIELD_NAMES.ACK_SCREEN_CTA_URL.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.CTA_ACTION_URL,
      maxCharsValidMessage(
        FIELD_NAMES.ACK_SCREEN_CTA_URL.LABEL,
        FIELD_MAX_CHARACTERS.CTA_ACTION_URL,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_ACTION_URL,
      FIELD_VALIDATION_MESSAGES.CTA_ACTION_URL,
    ),
  [FIELD_NAMES.LANDING_PAGE_URL.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.LANDING_PAGE_URL.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.LANDING_PAGE_URL,
      maxCharsValidMessage(
        FIELD_NAMES.LANDING_PAGE_URL.LABEL,
        FIELD_MAX_CHARACTERS.LANDING_PAGE_URL,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_ACTION_URL,
      FIELD_VALIDATION_MESSAGES.LANDING_PAGE_URL,
    ),
  ...setFieldsMapping(MAPPING_FIELDS.VALIDATION_SCHEMA),
  [FIELD_NAMES.WHITE_LISTED_URLS.NAME]: Yup.array().of(
    Yup.string()
      .required()
      .label(FIELD_NAMES.WHITE_LISTED_URLS.LABEL)
      .max(
        FIELD_MAX_CHARACTERS.WHITE_LISTED_URLS,
        maxCharsValidMessage(
          FIELD_NAMES.WHITE_LISTED_URLS.LABEL,
          FIELD_MAX_CHARACTERS.WHITE_LISTED_URLS,
        ),
      )
      .matches(
        FIELD_REGEX.CTA_ACTION_URL,
        FIELD_VALIDATION_MESSAGES.WHITE_LISTED_URLS,
      )
      .test(
        FIELD_NAMES.WHITE_LISTED_URLS.UNIQUE_URL_VALIDATION_NAME,
        FIELD_NAMES.WHITE_LISTED_URLS.UNIQUE_URL_MESSAGE,
        checkForUniqueURLs,
      ),
  ),
  [FIELD_NAMES.FINE_PRINT_TEXT.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.FINE_PRINT_TEXT.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.FINE_PRINT_TEXT,
      maxCharsValidMessage(
        FIELD_NAMES.FINE_PRINT_TEXT.LABEL,
        FIELD_MAX_CHARACTERS.FINE_PRINT_TEXT,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.FINE_PRINT_TEXT,
    ),
  [FIELD_NAMES.SIDEBAR_TEXT.NAME]: Yup.string()
    .label(FIELD_NAMES.SIDEBAR_TEXT.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.SIDEBAR_TEXT,
      maxCharsValidMessage(
        FIELD_NAMES.SIDEBAR_TEXT.LABEL,
        FIELD_MAX_CHARACTERS.SIDEBAR_TEXT,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.SIDEBAR_TEXT,
    ),
  [FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.OFFER_SUMMARY_TEXT.LABEL)
    .max(
      FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT,
      maxCharsValidMessage(
        FIELD_NAMES.OFFER_SUMMARY_TEXT.LABEL,
        FIELD_MAX_CHARACTERS.OFFER_SUMMARY_TEXT,
      ),
    )
    .matches(
      FIELD_REGEX.CTA_BUTTON_TEXT,
      FIELD_VALIDATION_MESSAGES.OFFER_SUMMARY_TEXT,
    ),
  [FIELD_NAMES.GET_CONTRACT_US_URL.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.GET_CONTRACT_US_URL.LABEL)
    .matches(
      FIELD_REGEX.CTA_ACTION_URL,
      FIELD_VALIDATION_MESSAGES.GET_CONTRACT_US_URL,
    ),
  [FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME]: Yup.string()
    .required()
    .label(FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.LABEL)
    .matches(
      FIELD_REGEX.CTA_ACTION_URL,
      FIELD_VALIDATION_MESSAGES.GET_CONTRACT_INTERNATIONAL_URL,
    ),
  [FIELD_NAMES.ADMIN_PASSWORD.NAME]: Yup.string().label(
    FIELD_NAMES.ADMIN_PASSWORD.LABEL,
  ),
  [FIELD_NAMES.RESET_PASSWORD_MODAL.NAME]: Yup.string().when(
    [FIELD_NAMES.RESET_PASSWORD_MODAL_SWITCH.NAME],
    {
      is: true,
      then: Yup.string()
        .required()
        .label(FIELD_NAMES.RESET_PASSWORD_MODAL.LABEL)
        .max(
          FIELD_MAX_CHARACTERS.RESET_PASSWORD_MODAL,
          maxCharsValidMessage(
            FIELD_NAMES.RESET_PASSWORD_MODAL.LABEL,
            FIELD_MAX_CHARACTERS.RESET_PASSWORD_MODAL,
          ),
        ),
    },
  ),
  [FIELD_NAMES.COLOR_SETS.NAME]: Yup.array().of(colorValidation),
});

export const checkForAdditionalFields = (values) => {
  let areValuesAdded = false;
  const areAllValuesAdded = [];
  for (
    let index = 0;
    index <
    FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS.length;
    index++
  ) {
    const label =
      values[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
          .LABEL.NAME
      ];
    const tooltip =
      values[
        FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
          .TOOLTIP.NAME
      ];
    if (label && label.length > 0) {
      areValuesAdded = true;
      areAllValuesAdded[index] = true;
    } else if (tooltip && tooltip.length > 0) {
      areValuesAdded = true;
      areAllValuesAdded[index] = false;
    }
  }
  if (!areValuesAdded) {
    return true;
  } else {
    return !areAllValuesAdded.includes(false);
  }
};

export { FIELD_VALIDATION_MESSAGES };

export const setTitle = (props) => {
  const {
    history: {
      location: { pathname },
    },
  } = props;
  if (pathname === ADMIN_WHITELABEL_EDIT_ACCOUNT) {
    document.title = DOCUMENT_TITLE.EDIT_DOCUMENT;
  } else {
    document.title = DOCUMENT_TITLE.CREATE_DOCUMENT;
  }
};
/**
 * renderColorSubFields - function to render color fields with sub section
 * @param {array} subField
 * @returns single color code sub section with further sub color fields
 */
export const renderColorSubFields = (
  subField,
  index,
  values,
  setFieldValue,
  errors,
) => {
  return (
    <div className={whiteLabelStyles.colorFieldsContainer} key={index}>
      <p>{subField.label}</p>
      {subField.subFields.map((field, index) => (
        <div className={whiteLabelStyles.colorFields} key={index}>
          <p>{field.label}</p>
          <div>
            {renderColorDropDown(
              field,
              get(values, field.key),
              setFieldValue,
              values[FIELD_NAMES.COLOR_SETS.NAME],
              errors,
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const conditionalHandleCall = (key, value, onChangeHandle) => {
  if (key === COLOR_CODES[1].subFields[5].key) {
    onChangeHandle(value);
  } else if (key === COLOR_CODES[1].subFields[4].key) {
    onChangeHandle("", value);
  }
};

/**
 * renderColorField - function to render color fields without sub section
 * @param {object} subField
 * @returns single color code sub section
 */
export const renderColorField = (
  subField,
  index,
  onChangeHandle,
  values,
  setFieldValue,
  errors,
) => {
  return (
    <div className={whiteLabelStyles.colorField} key={index}>
      <p>{subField.label}</p>
      <div>
        {renderColorDropDown(
          subField,
          get(values, subField.key),
          setFieldValue,
          values[FIELD_NAMES.COLOR_SETS.NAME],
          errors,
          onChangeHandle,
        )}
      </div>
    </div>
  );
};

export const renderReportAdditionalFields = (values, setFieldValue) => {
  return (
    <div className={whiteLabelStyles.reportAdditionalFields}>
      <p>{FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.LABEL}</p>
      <p>({FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.EXTRA_TEXT})</p>
      <div className={whiteLabelStyles.subContainer}>
        {FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS.map(
          (field, key) =>
            renderEachAdditionalField(field, key, values, setFieldValue),
        )}
      </div>
    </div>
  );
};

export const renderFinePrintText = () => {
  return (
    <div className={whiteLabelStyles.finePrintText}>
      <p>{FIELD_NAMES.FINE_PRINT_TEXT.LABEL}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group 
                      ${whiteLabelStyles.formGroup}
                      ${whiteLabelStyles.slugInstruction} 
                      ${whiteLabelStyles.urlPlaceholder}`}
        >
          <p>{FIELD_NAMES.FINE_PRINT_TEXT.EXTRA_TEXT}</p>
          <FormField
            name={FIELD_NAMES.FINE_PRINT_TEXT.NAME}
            type="text"
            placeholder={FIELD_NAMES.FINE_PRINT_TEXT.PLACEHOLDER}
            data-testid="LandingPageURL"
            label={`${FIELD_NAMES.FINE_PRINT_TEXT.LABEL_TO_DISPLAY}*`}
          />
        </div>
      </div>
    </div>
  );
};

const editorInit = (field) => ({
  placeholder: field.PLACEHOLDER,
  height: 300,
  width: "100%",
  menubar: "edit view insert format help",
  branding: false,
  extended_valid_elements: "style",
  custom_elements: "style",
  plugins: ["advlist lists link preview", "code", "media table paste code "],
  toolbar: `${
    field.SETUP ? "customButton | " : ""
  } undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat`,
  toolbar_sticky: true,
  toolbar_mode: "sliding",
  content_style: "body { font-family:Montserrat; font-size:14px }",
  setup: field.SETUP ? editorSetup : null,
});

const getHtmlEditor = (initialValue, setFieldValue, innerRef, field) => (
  <Editor
    apiKey={process.env.REACT_APP_TINYMCE_KEY}
    onInit={(evt, editor) => (innerRef.current = editor)}
    initialValue={initialValue}
    onEditorChange={(e) => {
      setFieldValue(field.NAME, e);
    }}
    init={editorInit(field)}
    data-testid="test-editor"
  />
);
export const renderSidebarText = (initialValue, setFieldValue, innerRef) => {
  return (
    <div className={whiteLabelStyles.sidebarText}>
      <p>{FIELD_NAMES.SIDEBAR_TEXT.LABEL}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <p>{FIELD_NAMES.SIDEBAR_TEXT.EXTRA_TEXT}</p>
          {getHtmlEditor(
            initialValue,
            setFieldValue,
            innerRef,
            FIELD_NAMES.SIDEBAR_TEXT,
          )}
          <FormField
            name={FIELD_NAMES.SIDEBAR_TEXT.NAME}
            type="text"
            as={"textMsg"}
          />
        </div>
      </div>
    </div>
  );
};

export const renderOfferSummaryText = (
  initialValue,
  setFieldValue,
  innerRef,
) => {
  return (
    <div className={whiteLabelStyles.sidebarText}>
      <p>{FIELD_NAMES.OFFER_SUMMARY_TEXT.LABEL}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group ${whiteLabelStyles.formGroup} ${whiteLabelStyles.padding} d-block`}
        >
          {getHtmlEditor(
            initialValue,
            setFieldValue,
            innerRef,
            FIELD_NAMES.OFFER_SUMMARY_TEXT,
          )}
          <FormField
            name={FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME}
            type="text"
            as={"textMsg"}
          />
        </div>
      </div>
    </div>
  );
};

export const renderContractUrl = () => {
  return (
    <div className={whiteLabelStyles.sidebarText}>
      <p>{FIELD_NAMES.GET_CONTRACT_US_URL.EXTRA_TEXT}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <FormField
            name={FIELD_NAMES.GET_CONTRACT_US_URL.NAME}
            type="text"
            placeholder={FIELD_NAMES.GET_CONTRACT_US_URL.PLACEHOLDER}
            label={`${FIELD_NAMES.GET_CONTRACT_US_URL.LABEL_TO_DISPLAY}`}
          />
        </div>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <FormField
            name={FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME}
            type="text"
            placeholder={FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.PLACEHOLDER}
            label={`${FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.LABEL_TO_DISPLAY}`}
          />
        </div>
      </div>
    </div>
  );
};
const formatColorDropDown = ({ label, value }) => (
  <div className={whiteLabelStyles.dropDownStyle}>
    <div
      style={{ backgroundColor: value }}
      className={whiteLabelStyles.colorContainer}
    />
    <div className={whiteLabelStyles.main}>{label}</div>
  </div>
);
const renderColorDropDown = (
  field,
  values,
  setFieldValue,
  optionArray,
  errors,
  onChangeHandle,
) => (
  <FormField
    as="select"
    name={`${field.key}`}
    id="artistSelect"
    data-testid="colorSelect"
    options={optionArray}
    disabled={errors && errors.length > 0}
    value={getCurrentColor(values, optionArray)}
    onChange={(val) => {
      setFieldValue(get(field, "key"), val.value);
      onChangeHandle &&
        conditionalHandleCall(field.key, val.value, onChangeHandle);
    }}
    getOptionLabel={formatColorDropDown}
    formatOptionLabel={formatColorDropDown}
    placeholder={field.label}
  />
);

const getCurrentColor = (values, optionArray) => {
  const isExits = optionArray.find((obj) => obj.value === values);
  if (isExits) {
    return isExits;
  }
  return get(optionArray, "[0]");
};

export const renderSvgAssets = () => (
  <div className="d-none">
    <IconMonitor id={ASSETS_IDS.iconMonitor} width="118" height="118" />
    <IconSlider id={ASSETS_IDS.iconSlider} width="118" height="118" />
    <IconDiamond id={ASSETS_IDS.iconDiamond} width="118" height="118" />
    <IconLocker id={ASSETS_IDS.iconLocker} width="118" height="118" />
    <IconCash id={ASSETS_IDS.iconCash} width="118" height="118" />
    <IconPunctuation id={ASSETS_IDS.iconPunctuation} width="254" height="190" />
  </div>
);

export const renderThankYouCta = () => {
  return (
    <div className={whiteLabelStyles.thankYouCTAContainer}>
      <p>Thank-you CTA</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group ${whiteLabelStyles.formGroup} 
                                        ${whiteLabelStyles.slugInstruction}`}
        >
          <p>{FIELD_NAMES.CTA_DEFAULT_VALUE.EXTRA_TEXT}</p>
          <FormField
            name={FIELD_NAMES.CTA_DEFAULT_VALUE.NAME}
            as="textarea"
            placeholder={FIELD_NAMES.CTA_DEFAULT_VALUE.PLACEHOLDER}
            label={`${FIELD_NAMES.CTA_DEFAULT_VALUE.LABEL}*`}
          />
        </div>
        <div className={`form-group ${whiteLabelStyles.formGroup}`}>
          <FormField
            name={FIELD_NAMES.CTA_BUTTON_TEXT.NAME}
            as="input"
            placeholder={FIELD_NAMES.CTA_BUTTON_TEXT.PLACEHOLDER}
            label={`${FIELD_NAMES.CTA_BUTTON_TEXT.LABEL}*`}
          />
        </div>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <p>{FIELD_NAMES.CTA_ACTION_URL.EXTRA_TEXT}</p>
          <FormField
            name={FIELD_NAMES.CTA_ACTION_URL.NAME}
            type="text"
            placeholder={FIELD_NAMES.CTA_ACTION_URL.PLACEHOLDER}
            data-testid="CtaActionURL"
            label={`${FIELD_NAMES.CTA_ACTION_URL.LABEL}*`}
          />
        </div>
      </div>
    </div>
  );
};

export const renderAckScreenCta = (values) => {
  return (
    <div className={whiteLabelStyles.thankYouCTAContainer}>
      <p>Acknowledgement Screen CTA</p>
      <div className={whiteLabelStyles.subContainer}>
        <div className={`form-group ${whiteLabelStyles.formGroup}`}>
          <FormField
            name={FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME}
            as="input"
            placeholder={FIELD_NAMES.ACK_SCREEN_CTA_TEXT.PLACEHOLDER}
            label={`${FIELD_NAMES.ACK_SCREEN_CTA_TEXT.LABEL}`}
          />
        </div>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <FormField
            name={FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME}
            type="text"
            placeholder={FIELD_NAMES.ACK_SCREEN_CTA_URL.PLACEHOLDER}
            label={`${FIELD_NAMES.ACK_SCREEN_CTA_URL.LABEL}${
              values[FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME] ? "*" : ""
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export const renderLandingPageURL = (values) => (
  <div className={whiteLabelStyles.wpDomainURL}>
    <p>{FIELD_NAMES.LANDING_PAGE_URL.LABEL}</p>
    <div className={whiteLabelStyles.subContainer}>
      <div
        className={`form-group 
                      ${whiteLabelStyles.formGroup}
                      ${whiteLabelStyles.slugInstruction} 
                      ${whiteLabelStyles.urlPlaceholder}`}
      >
        <p>{FIELD_NAMES.LANDING_PAGE_URL.EXTRA_TEXT}</p>
        <FormField
          name={FIELD_NAMES.LANDING_PAGE_URL.NAME}
          type="text"
          placeholder={FIELD_NAMES.LANDING_PAGE_URL.PLACEHOLDER}
          data-testid="LandingPageURL"
          label={`${FIELD_NAMES.LANDING_PAGE_URL.LABEL_TO_DISPLAY}*`}
        />
      </div>
      <p className={whiteLabelStyles.whiteListedURLHeader}>
        {FIELD_NAMES.WHITE_LISTED_URLS.EXTRA_TEXT}
      </p>
      <div
        className={`form-group 
                      ${whiteLabelStyles.formGroup}
                      ${whiteLabelStyles.slugInstruction} 
                      ${whiteLabelStyles.urlPlaceholder}`}
      >
        <FormField
          name={FIELD_NAMES.WHITE_LISTED_URLS.NAME}
          arrayLabel={`${FIELD_NAMES.WHITE_LISTED_URLS.LABEL}`}
          arrayValueLabel={values[FIELD_NAMES.WHITE_LISTED_URLS.NAME]}
          subFieldInitialValue={
            FIELD_NAMES.WHITE_LISTED_URLS.SUB_FIELD_INITIAL_VALUE
          }
          placeholder={`${FIELD_NAMES.WHITE_LISTED_URLS.PLACEHOLDER}`}
          addButtonText={`${FIELD_NAMES.WHITE_LISTED_URLS.ADD_BUTTON_TEXT}`}
          as="array"
          fieldContainerClass={whiteLabelStyles.arrayContainer}
          subFieldsContainerClass={whiteLabelStyles.arraySubFieldsContainer}
          addButtonClass={whiteLabelStyles.addURLButton}
          type="text"
          maxSize={FIELD_NAMES.WHITE_LISTED_URLS.MAX_URLS}
        />
      </div>
    </div>
  </div>
);

export const renderResetPasswordModal = (
  initialValue,
  setFieldValue,
  innerRef,
) => {
  return (
    <div className={whiteLabelStyles.sidebarText}>
      <p>{FIELD_NAMES.RESET_PASSWORD_MODAL.LABEL}</p>
      <div className={whiteLabelStyles.subContainer}>
        <div
          className={`form-group  ${whiteLabelStyles.formGroup} ${whiteLabelStyles.slugInstruction} ${whiteLabelStyles.urlPlaceholder}`}
        >
          <p>{FIELD_NAMES.RESET_PASSWORD_MODAL.EXTRA_TEXT}</p>
          {getHtmlEditor(
            initialValue,
            setFieldValue,
            innerRef,
            FIELD_NAMES.RESET_PASSWORD_MODAL,
          )}
          <FormField
            name={FIELD_NAMES.RESET_PASSWORD_MODAL.NAME}
            type="text"
            as={"textMsg"}
          />
        </div>
      </div>
    </div>
  );
};

const editorSetup = (editor) => {
  editor.ui.registry.addButton("customButton", {
    text: "Default Right Pane List",
    onAction: (_) => {
      editor.insertContent(DEFAULT_RESETPASSWORD_LIST_HTML);
    },
  });
};

const getColorObj = (index) => {
  return { value: "#FFFFFF", label: `Color ${index}` };
};
export const addNewColor = (values, setFieldValue) => {
  const colors = values[FIELD_NAMES.COLOR_SETS.NAME];
  colors.push(getColorObj(values[FIELD_NAMES.COLOR_SETS.NAME].length + 1));
  setFieldValue(FIELD_NAMES.COLOR_SETS.NAME, colors);
};

export const removeColor = (index, values, setFieldValue) => {
  const newColorSet = values[FIELD_NAMES.COLOR_SETS.NAME].filter(
    (_, i) => i !== index,
  );
  setFieldValue(FIELD_NAMES.COLOR_SETS.NAME, newColorSet);
  const colorCode = { ...values.colorCodes };
  const oldValue = get(values[FIELD_NAMES.COLOR_SETS.NAME], `[${index}].value`);
  const newValue = getReplacedColor(values[FIELD_NAMES.COLOR_SETS.NAME], index);
  const updatedObj = checkForObjectValueforRemove(
    colorCode,
    oldValue,
    get(newValue, "value"),
  );
  setFieldValue("colorCodes", updatedObj);
};

export const getDeletedColor = (colorSet, index) => {
  return get(colorSet, `[${index}].label`);
};

export const getReplacedColor = (colorSet, index) => {
  return index !== 0 ? get(colorSet, `[0]`) : get(colorSet, `[${index + 1}]`);
};

export const getTouchedValue = (values) => {
  const arr = [];
  values.forEach((val) => {
    arr.push({ label: true, value: true });
  });
  return arr;
};

export const checkForObjectValue = (obj, value) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === value) {
        return true;
      } else if (typeof obj[key] === "object") {
        if (checkForObjectValue(obj[key], value)) {
          return true;
        }
      }
    }
  }
  return false;
};
export const checkForObjectValueforRemove = (newObj, value, newValue) => {
  const obj = cloneDeep(newObj);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (`${obj[key]}`.toLowerCase() === `${value}`.toLowerCase()) {
        obj[key] = newValue;
      } else if (typeof obj[key] === "object") {
        obj[key] = checkForObjectValueforRemove(obj[key], value, newValue);
      }
    }
  }
  return obj;
};
