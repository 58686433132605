export const DEFAULT_FLOW_THROUGH = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const DEFAULT_TERMS = [1, 2, 3, 5, 8, 20];

export const DEFAULT_ROYALTY = [50, 60, 70, 80, 90];

export const OFFERSUMMARY_STEPS = [
  "MAIN_STEP",
  "MUSIC_QUESTION",
  "FUNDING_QUESTION",
];

export const CONFIRMED_OFFER_QUESTIONS = {
  MAIN_QUESTION: "How do you want to choose your confirmed offers?",
  MUSIC_QUESTION: "What works do you want to include in your advance?",
  FUNDING_SLIDER_QUESTION: "About how much funding are you looking for?",
};

export const DESCRIPTION_OF_QUESTIONS = {
  MUSIC_QUESTION:
    "Including unreleased songs will increase the amount of funding you are able to receive.",
  FUNDING_SLIDER_QUESTION:
    "We will show you a few options that meet your funding needs.",
};

export const MAIN_QUESTION_OPTIONS = [
  {
    OPTION: "get recommended offers",
    DESCRIPTION: "Answer a few questions to find the deal best for you",
  },
  {
    OPTION: "build your offer",
    DESCRIPTION: "Fully customize every term in your deal",
  },
];

export const MUSIC_OPTION = [
  { OPTION: "just catalog" },
  { OPTION: "catalog + new music" },
];

export const SLIDER_STEP_RANGE = [25000, 100000, 500000];

export const DEAL_LIST = ["Deal One", "Deal Two", "Deal Three"];

export const COMPARE_DEAL_STEPS = [1, 2];

export const RECOMMENDED_ROUND_AMOUNT = 1000;

export const LINES_COLORS = [
  "rgba(var(--sidebar-primary-background-color-rgb),60%)",
  "rgba(var(--sidebar-primary-background-color-rgb),30%)",
  "rgba(var(--sidebar-primary-background-color-rgb),100%)",
];

export const SUBMIT_BUTTON = [
  'Total Income',
  'Income Per Year'
]

export const DEAL_CATEGORY_FILTER = [
  { 'q0.1': 'Low' },
  { 'q0.5': 'Expected' },
  { 'q0.9': 'High' }
]

export const CHART_MAX_INDEX = [20, 3, 1, 0];
