import React, { Component } from "react";
import ReactModal from "react-modal";
import { get } from "lodash";
import styles from "../../../component/ContactPopup/ContactPopup.module.scss";
import { STATUS_BIG, STATUS_SMALL, STATUS_NEW } from "./constants";
import style from "./Dashboard.module.scss";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
class ArtistStatusInfoPopup extends Component {
  componentWillMount() {
    ReactModal.setAppElement("body");
  }
  fetchPopupContent = () => {
    if (this.props.type === STATUS_BIG) {
      return (
        <div className={style.infoPopupContent}>
          Congratulations! {this.props.artistName} is in the top 1% globally in
          their streaming performance. We find that advance structures for
          artists this large can be a bit more complex than average, so we need
          to walk you through the details and help you customize your offer and
          get the most out of our investor network
          <br />
          <br />
          Look out for an email from a team member or book a call by clicking{" "}
          <a
            href={get(this.context, "thankYouCTA.actionUrl")}
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>
          <br />
          <br />
          Thank you.
        </div>
      );
    }
    if (this.props.type === STATUS_SMALL) {
      return (
        <div className={style.infoPopupContent}>
          Our data indicates that the streaming history for{" "}
          {this.props.artistName} does not have characteristics that our
          investors like to see to support an investment. If you think this is
          an error, please email us including the following:
          <br />
          <br />
          <ul>
            <li>Your name and phone number</li>
            <li>
              Your "artist name" as it appears on major streaming services
            </li>
            <li>
              Your detailed distribution reports from the last 4-6 months
              showing total streaming revenues and stream counts
            </li>
          </ul>
          Due to the volume of emails we receive, enquiries without this
          information are unlikely to receive a response.
          <br />
          <br />
          Thank you.
        </div>
      );
    }
    if (this.props.type === STATUS_NEW) {
      return (
        <div className={style.infoPopupContent}>
          Our data indicates that the streaming history for{" "}
          {this.props.artistName} does not have characteristics that our
          investors like to see to support an investment. If you think this is
          an error, please email us including the following:
          <br />
          <br />
          <ul>
            <li>Your name and phone number</li>
            <li>
              Your "artist name" as it appears on major streaming services
            </li>
            <li>
              Your detailed distribution reports from the last 4-6 months
              showing total streaming revenues and stream counts
            </li>
          </ul>
          Due to the volume of emails we receive, enquiries without this
          information are unlikely to receive a response.
          <br />
          <br />
          Thank you.
        </div>
      );
    }
    return;
  };
  render() {
    return (
      <>
        <ReactModal
          isOpen={this.props.isPopupOpen}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={this.props.onInfoPopupClose}
          className={styles.contactModal}
          overlayClassName={`${styles.modalOverlay}`}
        >
          <div className={styles.modalBody}>
            <div className={styles.chatForm}>{this.fetchPopupContent()}</div>
          </div>
        </ReactModal>
      </>
    );
  }
}
ArtistStatusInfoPopup.contextType = ThemeContext;
export default ArtistStatusInfoPopup;
