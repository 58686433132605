import React, { Component } from "react";
import styles from "./LoggedOut.module.scss";
import { ARTISTS_RANGE } from "../AdminDashboard/constants";
import { ReactComponent as HelpSvg } from "../../../assets/logos/helpSvg.svg";

import Image from "../../../component/Image";
import { get } from "lodash";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { Link } from "react-router-dom";
import { LOGIN } from "../constants";
import ContactPopup from "../../../component/ContactPopup";

class LoggedOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isOfferReady: false,
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false, isOfferReady: false });
  };

  handleContactPopup = () => {
    this.setState({ isOpen: true });
  };

  artistLoggedOut = (status) => {
    switch (status) {
      case ARTISTS_RANGE.SMALL:
      case ARTISTS_RANGE.NEW:
        return (
          <>
            <div className={styles.pageContainer}>
              <div className={styles.containersm} data-testid="div-small">
                <h3>Thanks for visiting {get(this.context, "displayName")}</h3>
                <h4>We look forward to speaking with you soon!</h4>
              </div>
            </div>
          </>
        );

      case ARTISTS_RANGE.BIG:
        return (
          <>
            <div className={styles.pageContainer}>
              <div className={styles.containerbig} data-testid="div-big">
                <h3>Thanks for visiting {get(this.context, "displayName")}</h3>
                <h4>
                  Keep an eye out for an email from us with more information.
                </h4>
              </div>
            </div>
          </>
        );

      case ARTISTS_RANGE.PENDING:
      case ARTISTS_RANGE.FAILED:
        return (
          <>
            <div className={styles.pageContainer}>
              <div className={styles.container} data-testid="div-pending">
                <div className={styles.firstContainer}>
                  <h3>
                    Thanks for visiting {get(this.context, "displayName")}
                  </h3>
                  <h4>
                    Keep an eye out for an email from us when your data is
                    ready.
                  </h4>
                </div>
                <div className={styles.secondContainer}>
                  <h5>The next 3 steps:</h5>
                  <div className={styles.getFundedFastDetails}>
                    <ol>
                      <li>Verify the data we pulled is correct</li>
                      <li>
                        Customize the Advance Structure that is right for you
                      </li>
                      <li>Upload your distribution reports for review</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className={styles.pageContainer}>
              <div className={styles.container} data-testid="div-default"></div>
            </div>
          </>
        );
    }
  };

  render() {
    const status = get(this.props, "location.state.detail.status")
      ? get(this.props, "location.state.detail.status")
      : get(this.props, "location.state.detail");

    return (
      <>
        <div className={styles.main}>
          <div className={styles.leftpanel}>
            <Link to={LOGIN} className={styles.logoContainer}>
              <Image
                src={get(this.context, "appLogo")}
                className={styles.logo}
                alt="Logo"
              />
            </Link>
            <div className={styles.textlogin}>
              <div
                className={styles.lefttext}
                onClick={this.handleContactPopup}
              >
                <h3>
                  <HelpSvg /> <p>Need Help?</p>
                </h3>
              </div>
              <div className={styles.loginleft}>
                <Link to={LOGIN} className={styles.loginBtn}>
                  Login
                </Link>
              </div>
            </div>
          </div>
          <ContactPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleClose}
          />
          <div className={styles.rightpanel}>
            <div className={styles.headerpanel}>
              <div className={styles.text}>
                <h3 onClick={this.handleContactPopup}>
                  <HelpSvg /> <p>Need Help?</p>
                </h3>
              </div>
              <div className={styles.login}>
                <Link to={LOGIN} className={styles.loginBtn}>
                  Login
                </Link>
              </div>
            </div>
            <div className={styles.test}>{this.artistLoggedOut(status)}</div>
          </div>
        </div>
      </>
    );
  }
}

LoggedOut.contextType = ThemeContext;
export default LoggedOut;
