import React from "react";
import styles from "./SignupFastFlow.module.scss";

export const RIGHTS_TYPE_INIT_DATA = { rightsType: "" };
export const MASTERS_INIT_DATA = {
  amount: "",
  majorDistributor: null,
};

export const SWITCH_DIST_OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I’m not sure", value: "I’m not sure" },
];

export const PUBLISHING_INIT_DATA = {
  PRO: null,
  artistPublisher: null,
  recent_revenue: "",
};
export const USER_INIT_DATA = {
  name: "",
  email: "",
  phone: "",
  password: "",
  checkAgreement: false,
  relationshipWithArtist: null
};

export const ARTIST_ENROLLMENT_INITIAL_DATA = {
  ...USER_INIT_DATA,
  ...MASTERS_INIT_DATA,
};

export const SIGNUP_STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
};

export const SIGNUP_FORM_FIELDS = {
  RIGHTS_TYPE: {
    NAME: "rightsType",
    LABEL: "Type of Advance",
    OPTIONS: [
      {
        label: (
          <span className={styles.radioLabel}>
            <span>Sound Recording</span>
            <span>This includes income generated from streaming</span>
          </span>
        ),
        value: "masters",
      },
      {
        label: (
          <span className={styles.radioLabel}>
            <span>Publishing</span>
            <span>
              This includes income generated from your music copyright
            </span>
          </span>
        ),
        value: "publishing",
      },
      {
        label: (
          <span className={styles.radioLabel}>
            <span>Both</span>
          </span>
        ),
        value: "both",
      },
    ],
  },
};

export const BB_INITIAL_FORM = {
  [SIGNUP_STEPS.STEP1]: { ...RIGHTS_TYPE_INIT_DATA },
  [SIGNUP_STEPS.STEP2]: { ...MASTERS_INIT_DATA },
  [SIGNUP_STEPS.STEP3]: { ...PUBLISHING_INIT_DATA },
  [SIGNUP_STEPS.STEP4]: { ...USER_INIT_DATA },
};

export const CC_INITIAL_FORM = {
  [SIGNUP_STEPS.STEP2]: { ...MASTERS_INIT_DATA },
  [SIGNUP_STEPS.STEP4]: { ...USER_INIT_DATA },
};

export const DISPLAY_ALL_PATH_CONDITION = 3000;
