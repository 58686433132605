import React, { Component } from "react";
import styles from "../Misc.module.scss";
import { Link } from "react-router-dom";
import { HOME } from "../../constants";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
class ErrorPage extends Component {
  componentDidUpdate() {
    setTitle("Error", this.context);
  }
  render() {
    return (
      <>
        <div className="theme-bg-dark">
          <div className={styles.errorContainer}>
            <div>
              <h1>404</h1>
            </div>
            <div>
              <h3>Page not found</h3>
            </div>
            <Link to={HOME} className={styles.homeLink}>
              Return Home
            </Link>
          </div>
        </div>
      </>
    );
  }
}

ErrorPage.contextType = ThemeContext;
export default ErrorPage;
