import React from "react";
import { get } from "lodash";
import HorizontalMenu from "../../../../component/HorizontalMenu";
import Odometer from "react-odometerjs";
import LoginHeader from "../../../../component/LoginHeader";
import styles from "./GetEvenMore.module.scss";
import ReactModal from "react-modal";
import moment from "moment";
import Loader from "../../../../component/Loader";
import {
  setTitle,
  getSubDomain,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import { ReactComponent as TickBadgeIcon } from "../../../../assets/logos/tick-badge-icon.svg";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PLAY_ARROW_ROUNDED } from "../../../../component/MaterialIcons/constants";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import {
  CHORDCASH_CALENDLY_LINK,
  BEATBREAD_MARKETPLACE_CALENDLY_LINK,
  TOP_TRACK,
  ARTIST_API,
  USER_API,
  API_URL,
  YT_THUMBNAIL_URL,
  INSTRUCTIONAL_YT_VIDEO,
  YT_EMBED_URL,
  OFFERS_API,
  SET_TARGET_ADVANCE,
} from "../../constants";
import request from "../../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../helper";
import {
  numberWithCommas,
  getAdvanceOptionalCount,
  getSliderComponent,
  changeSliderCallback,
} from "../../YourAdvance/helper";
import classes from "../../YourAdvance/YourAdvance.module.scss";
import { YT_VIDEO_ID_REGEX } from "../../Auth/constants";
import { ReactComponent as MarketTuneIcon } from "../../../../assets/logos/market-tune-icon.svg";
import { ReactComponent as MarketEmailIcon } from "../../../../assets/logos/market-email-icon.svg";
import { ReactComponent as MarketInvestorsOffer } from "../../../../assets/logos/market-investors-offer.svg";
import { ReactComponent as MarketCalendarIcon } from "../../../../assets/logos/market-calendar.svg";
import { GuaranteedModal, nothanksNavigationStatusUpdate } from "../helper";

class GetEvenMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offerData: {},
      showDetails: false,
      showEmbedVideo: false,
      showAdjustDeal: false,
      showMarketPopup: false,
      showMoreAboutPopup: false,
      advanceData: {},
      defaultOffer: {},
      currentData: {},
      newTracks: 0,
      term: 1,
      catalogIncome: 0,
      releaseIncome: 0,
      navigationStatus: {},
    };
  }

  componentDidUpdate() {
    setTitle("Get Even More", this.context);
  }

  componentDidMount() {
    this.getOfferData();
  }

  getOfferData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({ offerData: get(res.data, "offer", {}) });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleDetailsPopup = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  toggleAdjustPopup = () => {
    this.setState({ showAdjustDeal: !this.state.showAdjustDeal });
  };

  toggleMarketPopup = () => {
    this.setState({ showMarketPopup: !this.state.showMarketPopup });
  };

  toggleMoreAboutPopup = () => {
    this.setState({ showMoreAboutPopup: !this.state.showMoreAboutPopup });
  };

  setShowEmbedVideo = () => {
    this.setState({ showEmbedVideo: true });
  };

  detailsPopup = () => (
    <ReactModal
      isOpen={this.state.showDetails}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.toggleDetailsPopup}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.offerContainer}>
        <span className={styles.closeBtn} onClick={this.toggleDetailsPopup}>
          &times;
        </span>
        <div className={styles.totalContent}>
          <span className={styles.label}>Confirmed Offer Minimum</span>
          <span className={styles.expireDate}>
            Valid through{" "}
            {moment(get(this.state.offerData, "expire")).format("MMM DD, YYYY")}
          </span>
        </div>
        <div className={styles.estimateContainer}>
          <div className={styles.totalEstimate}>
            <label>Estimated Total</label>
            <p className={styles.totalNumber}>
              ${numberWithCommas(get(this.state.offerData, "total_advance", 0))}{" "}
            </p>
          </div>
          <div className={styles.offerDetails}>
            <label>Recoupment Rate</label>
            <p className={styles.recoupmentRate}>
              {parseInt(get(this.state.offerData, "royalty", 0))}%
            </p>
          </div>
          <div className={styles.termContainer}>
            <p>
              <span className={styles.importantText}>
                {this.state.offerData.term} Year Term,
              </span>{" "}
              {this.state.offerData.flow_through}% flow through all income
            </p>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <ul>
            <li
              className={!this.state.offerData.catalog_advance ? "d-none" : ""}
            >
              <div className="d-flex">
                <span>Upfront advance</span>
                <div className={styles.value}>
                  ${numberWithCommas(this.state.offerData.catalog_advance)}
                </div>
              </div>
            </li>
            <li className={!this.state.offerData.nr_advance ? "d-none" : ""}>
              <div className="d-flex">
                <span>
                  Advance on release of{" "}
                  {getAdvanceOptionalCount(this.state.offerData.works)} new
                  tracks
                </span>
                <div className={styles.value}>
                  ${numberWithCommas(this.state.offerData.nr_advance)}
                </div>
              </div>
            </li>
            <li
              className={!this.state.offerData.option_advance ? "d-none" : ""}
            >
              <div className="d-flex">
                <span>
                  Optional advance for{" "}
                  {getAdvanceOptionalCount(this.state.offerData.works, true)}{" "}
                  more tracks
                </span>
                <div className={styles.value}>
                  ${numberWithCommas(this.state.offerData.option_advance)}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={styles.btnContainer}>
          <button
            data-testid="getOfferCall"
            onClick={() => this.getAdjustOffers(true)}
          >
            Adjust Deal Structure
          </button>
        </div>
      </div>
    </ReactModal>
  );

  getAdjustOffers = (loadDefault) => {
    this.setState({ loading: true, showDetails: false });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
          showAdjustDeal: true,
        });
        if (res.status) {
          this.setState(
            {
              advanceData: res.data.offers,
              defaultOffer: res.data.defaultOffer,
            },
            () => changeSliderCallback.bind(this)(loadDefault),
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  resetAdjustTermOffer = () => {
    this.setState(
      { currentData: this.state.defaultOffer },
      changeSliderCallback.bind(this)(true, false),
    );
  };

  saveFundingAdvance = () => {
    this.setState({ loading: true });
    const payload = {
      offer: this.state.currentData,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            offerData: get(res.data, "offer", {}),
            showAdjustDeal: false,
          });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  adjustTermsModalRender = () => (
    <ReactModal
      isOpen={this.state.showAdjustDeal}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.toggleAdjustPopup}
      className={styles.adjustModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.header}>
        <div className={styles.title}>Adjust Offer Terms</div>
        <div className={styles.preview}>
          <p className={styles.primaryText}>
            Guaranteed Offer:{" "}
            <span>
              $
              <Odometer
                value={get(this.state, "currentData.total_advance", 0)}
                format="(,ddd).dd"
                duration={400}
              />
            </span>
          </p>
          <p>
            Recoupment Rate:{" "}
            <span>
              <Odometer
                value={parseInt(get(this.state, "currentData.royalty", 0))}
                format="(,ddd).dd"
                duration={400}
              />
              %
            </span>
          </p>
        </div>
      </div>
      <div className={`${classes.sliderContainer} ${styles.sliderContainer}`}>
        {!!Object.keys(get(this.state, "advanceData", {})).length &&
          getSliderComponent.bind(this)()}
      </div>
      <div className={styles.modalBtnContainer}>
        <button
          className={styles.resetBtn}
          data-testid="resetAdjustTerms"
          onClick={this.resetAdjustTermOffer}
        >
          Reset Changes
        </button>
        <button
          className={styles.modalBtn}
          data-testid="saveAdjustTerms"
          onClick={this.saveFundingAdvance}
        >
          Save
        </button>
      </div>
    </ReactModal>
  );

  navigationStatusUpdate = nothanksNavigationStatusUpdate.bind(this);

  moreAboutPopupRender = () => (
    <ReactModal
      isOpen={this.state.showMoreAboutPopup}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.toggleMoreAboutPopup}
      className={styles.marketModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.primaryHeader}>
        <p>
          Our platform gathers offers from potential investors in a sealed offer
          process
        </p>
      </div>
      <div className={styles.infoText}>
        <p>
          Investors make one single best and final offer on all of, or a portion
          of your deal.
        </p>
        <p>
          We do this to ensure that potential investors can't game the process
          or collude to give you less. Research has shown that 'sealed bids'
          generate higher prices for sellers than open auctions.
        </p>
      </div>
      <div className={styles.btnContainer}>
        <button onClick={this.toggleMoreAboutPopup}>Continue</button>
      </div>
    </ReactModal>
  );

  setNavigationStatus = (status) => {
    this.setState({ navigationStatus: status });
  };

  videoContentRender = () => (
    <>
      <p className={styles.title}>
        Use our Investor Network to get higher offers and better terms.
      </p>
      <p>Watch this short video, then schedule a call with us to learn more.</p>
      {!this.state.showEmbedVideo ? (
        <div className={styles.videoThumbnail} onClick={this.setShowEmbedVideo}>
          <img
            src={YT_THUMBNAIL_URL.replace(
              "[VIDEO_ID]",
              INSTRUCTIONAL_YT_VIDEO.match(YT_VIDEO_ID_REGEX).pop(),
            )}
            alt="video thumbnail"
          />
          <span className={styles.playContainer}>
            <SvgIcons icon={PLAY_ARROW_ROUNDED} />
          </span>
        </div>
      ) : (
        <div className={styles.videoThumbnail}>
          <iframe
            src={YT_EMBED_URL.replace(
              "[VIDEO_ID]",
              INSTRUCTIONAL_YT_VIDEO.match(YT_VIDEO_ID_REGEX).pop(),
            )}
            title="Instruction Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </div>
      )}
    </>
  );

  getCalendalyLink = () =>
    BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
      ? BEATBREAD_MARKETPLACE_CALENDLY_LINK
      : CHORDCASH_CALENDLY_LINK;

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu getStatus={this.setNavigationStatus} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader headerTitle={"Congratulations!"} />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  <div className={styles.badgeContainer}>
                    <TickBadgeIcon />
                  </div>
                  <div className={styles.getEvenMoreContent}>
                    <div className={styles.detailsContainer}>
                      <div className={styles.offerContainer}>
                        <p className={styles.title}>Your confirmed offer is</p>
                        <div className={styles.amountContainer}>
                          <p className={styles.dollarAmount}>
                            $
                            {numberWithCommas(
                              get(this.state.offerData, "total_advance", 0),
                            )}
                          </p>
                          <p className={styles.offerDetail}>OFFER DETAILS </p>
                          <span className={styles.separator}>———</span>
                          <div className={styles.desktopContentContainer}>
                            <ul>
                              <li
                                className={
                                  !this.state.offerData.catalog_advance
                                    ? "d-none"
                                    : ""
                                }
                              >
                                <div className="d-flex">
                                  <span>Upfront advance</span>
                                  <div className={styles.value}>
                                    $
                                    {numberWithCommas(
                                      this.state.offerData.catalog_advance,
                                    )}
                                  </div>
                                </div>
                              </li>
                              <li
                                className={
                                  !this.state.offerData.nr_advance
                                    ? "d-none"
                                    : ""
                                }
                              >
                                <div className="d-flex">
                                  <span>
                                    Advance on release of{" "}
                                    {getAdvanceOptionalCount(
                                      this.state.offerData.works,
                                    )}{" "}
                                    new tracks
                                  </span>
                                  <div className={styles.value}>
                                    $
                                    {numberWithCommas(
                                      this.state.offerData.nr_advance,
                                    )}
                                  </div>
                                </div>
                              </li>
                              <li
                                className={
                                  !this.state.offerData.option_advance
                                    ? "d-none"
                                    : ""
                                }
                              >
                                <div className="d-flex">
                                  <span>
                                    Optional advance for{" "}
                                    {getAdvanceOptionalCount(
                                      this.state.offerData.works,
                                      true,
                                    )}{" "}
                                    more tracks
                                  </span>
                                  <div className={styles.value}>
                                    $
                                    {numberWithCommas(
                                      this.state.offerData.option_advance,
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <span
                            className={styles.linkBtn}
                            onClick={this.toggleDetailsPopup}
                          >
                            See More
                          </span>
                        </div>
                        <p>but we can help you get even more.</p>
                      </div>
                      <div className={styles.videoSectionContainer}>
                        {get(
                          this.state.navigationStatus,
                          "isMarketPlaceEnabled",
                        ) ? (
                          <>
                            <p className={styles.title}>
                              Get even better funding options in just a few
                              steps…
                            </p>
                            <div className={styles.cardContainer}>
                              <div className={styles.card}>
                                <div className={styles.logoContainer}>
                                  <MarketTuneIcon />
                                </div>
                                <p>
                                  <span>1. Set your minimum</span> terms for
                                  investors to meet (or exceed).
                                </p>
                              </div>
                              <div className={styles.card}>
                                <div className={styles.logoContainer}>
                                  <MarketEmailIcon />
                                </div>
                                <p>
                                  <span>2. Build a profile</span> to share with
                                  your contacts and our Investor Network.
                                </p>
                              </div>
                            </div>
                            <div className={styles.cardContainer}>
                              <div className={styles.card}>
                                <div className={styles.logoContainer}>
                                  <MarketInvestorsOffer />
                                </div>
                                <p>
                                  <span>3. Investors make offers</span> on all
                                  of, or a portion of, your deal.We will get the
                                  best funding group and handle investor
                                  contracts.
                                </p>
                              </div>
                              <div
                                className={`${styles.card} ${styles.lastCard}`}
                              >
                                <div className={styles.logoContainer}>
                                  <MarketCalendarIcon />
                                </div>
                                <p>
                                  <span>
                                    4. We'll get you an answer in about a week.
                                  </span>{" "}
                                  There is no commitment if we don't beat your
                                  minimum terms.
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          this.videoContentRender()
                        )}
                      </div>
                    </div>
                    <div className={styles.btnContainer}>
                      <div className={styles.linkBtnContainer}>
                        <p className={styles.containerTitle}>
                          This offer is not final.
                        </p>
                        <span className={styles.contentBody}>
                          {" "}
                          You may{" "}
                          <span onClick={this.toggleMarketPopup}>
                            accept this offer now
                          </span>{" "}
                          to finalize your deal; adjust the deal structure; or
                          schedule a call to learn how you can get more.
                        </span>
                      </div>
                      {get(
                        this.state.navigationStatus,
                        "isMarketPlaceEnabled",
                      ) ? (
                        <div className={styles.adjustButtonContainer}>
                          <button
                            className={`${styles.requestCallBtn} ${styles.offerBtn}`}
                            data-testid="adjustOffer"
                            onClick={() => this.getAdjustOffers(true)}
                          >
                            ADJUST OFFER DETAILS
                          </button>
                          <button
                            className={`${styles.requestCallBtn} ${styles.requestBtn}`}
                            data-testid="submitButton"
                            onClick={() => {
                              this.navigationStatusUpdate(
                                { getEvenMore: true },
                                SET_TARGET_ADVANCE,
                              );
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      ) : (
                        <div className={styles.offerDetailBtn}>
                          <button
                            className={`${styles.requestCallBtn} ${styles.offerBtn}`}
                            data-testid="adjustOffer1"
                            onClick={() => this.getAdjustOffers(true)}
                          >
                            ADJUST OFFER DETAILS
                          </button>
                          <a
                            className={`${styles.requestCallBtn} ${styles.requestButton}`}
                            href={this.getCalendalyLink()}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            SCHEDULE CALL
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading && <Loader light backgroundNone />}
            {this.detailsPopup()}
            {this.adjustTermsModalRender()}
            {this.moreAboutPopupRender()}
            <GuaranteedModal
              isOpen={this.state.showMarketPopup}
              toggleMarketPopup={this.toggleMarketPopup}
              navigationStatusUpdate={this.navigationStatusUpdate}
              context={this.context}
              totalAdvance={get(this.state.offerData, "total_advance", 0)}
              history={this.props.history}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GetEvenMore;
GetEvenMore.contextType = ThemeContext;
