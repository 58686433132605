import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "./pages/Auth/Login";
import GetFundedAuth from "./pages/Auth/GetFundedAuth";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import "react-toastify/dist/ReactToastify.css";
import "../styles/global.scss";
import {
  LOGIN,
  HOME,
  GETFUNDED,
  GETFUNDED_AUTH,
  ERROR_PAGE,
  FORGOT_PASS,
  CHANGE_PASS,
  MY_ACCOUNT,
  SEND_REPORTS,
  FUNDING,
  VERIFY,
  LINK_ANOTHER_ARTIST,
  DASHBOARD,
  CONTACT_BEATBREAD_GET_FUNDED,
  SUCCESS,
  WHY_BEATBREAD,
  ADMIN_DASHBOARD,
  ERROR_SERVER_DOWN,
  ERROR_FORBIDDEN,
  TUNE_MY_DATA,
  ACK_HIGH,
  ACK_LOW,
  ACK_ERROR,
  ACK_NEW,
  ACK_REDIRECT,
  ACK_IN_RANGE_1,
  ACK_IN_RANGE_2,
  ACK_IN_RANGE_3,
  ADMIN_BLACKLIST,
  ADMIN_WHITELABEL,
  ADMIN_WHITELABEL_NEW_ACCOUNT,
  ADMIN_WHITELABEL_EDIT_ACCOUNT,
  ADMIN_LOGIN,
  ADMIN_MAINTENANCE_MODE,
  MAINTENANCE_PAGE,
  DISTRIBUTORS,
  DISTRIBUTORS_EDIT,
  DISTRIBUTORS_CREATE,
  EMAIL_PREFERENCES,
  ADJUST_TERMS,
  OFFER_SUMMARY,
  WELCOME_BACK,
  ARTIST_VERIFY,
  ACCEPT_OFFER,
  DEMO_ARTIST,
  DEMO_ARTIST_CREATE,
  DEMO_ARTIST_EDIT,
  DEMO_DASHBOARD,
  LOGGED_OUT,
  WAITING_ROOM,
  LINK_SPOTIFY,
  GET_EVEN_MORE,
  INVITE_BIDDERS,
  INVITE_BIDDERS_PREVIEW,
  POST_CONFIRMATION,
  SET_TARGET_ADVANCE,
  UPCOMING_WORKS,
  POSTED_TO_NETWORK,
  FINAL_CHECKOUT_ONBOARDING,
  MARKETPLACE_ONBOARDING,
  ACK_IN_RANGE_4,
  HOMEPAGE_CONTENT,
  HOMEPAGE_CONTENT_NEW,
  HOMEPAGE_CONTENT_EDIT,
  SIGNUP,
  GENERATING_OFFERS,
  FF_SECURE_LOGIN,
  FF_VALIDATE,
  PUBLISHERS,
  PUBLISHERS_CREATE,
  PUBLISHERS_EDIT,
  PUBLISHING_REPORTS,
  CONFIRM_OWNERSHIP,
  CONFIRMED_OFFERS_V2,
} from "./pages/constants";
import MyAccount from "./pages/MyAccount";
import YourAdvance from "./pages/YourAdvance";
import WelcomeBack from "./pages/WelcomeBack";
import VerifyUser from "./pages/Auth/VerifyUser/VerifyUser";
import LinkAnotherArtist from "./pages/Auth/LinkAnotherArtist";
import ContactForFund from "./pages/YourAdvance/ContactForFund";
import Success from "./pages/YourAdvance/Success";
import { ErrorPage, PrivacyPolicy, WhyBeatBread } from "./pages/Misc";
import {
  whiteLabelListing,
  AdminDashboard,
  whiteLabelAccount,
} from "./pages/AdminDashboard";
import ErrorServerDown from "./pages/Misc/ErrorPage/ErrorServerDown";
import ErrorForbidden from "./pages/Misc/ErrorPage/ErrorForbidden";
import ManageBlacklist from "./pages/ManageBlacklist/ManageBlacklist";
import GetFunded from "./pages/Auth/Getfunded";
import TuneMyData from "./pages/YourAdvance/TuneMyData";
import SendReports from "./pages/SendReports";
import AcknowledgementScreen from "./pages/Auth/AcknowledgementScreen";
import AckRedirect from "./pages/Auth/AcknowledgementScreen/AckRedirect";
import ThemeManager from "../component/ThemeManager/ThemeManager";
import MaintenanceMode from "./pages/MaintenanceMode";
import SiteMaintenancePage from "./pages/Misc/ErrorPage/SiteMaintenancePage";
import Distributors from "./pages/Distributors";
import DistributorForm from "./pages/Distributors/DistributorForm";
import ArtistVerify from "./pages/OfferSummary/ArtistVerify";
import AcceptOffer from "./pages/AcceptOffer/AcceptOffer";
import DemoArtists from "./pages/DemoArtists/DemoArtists";
import DemoArtistForm from "./pages/DemoArtists/DemoArtistForm";
import DemoDashboard from "./pages/DemoArtists/DemoDashboard";
import { hubSpotTracking } from "./pages/helper";
import LoggedOut from "./pages/LoggedOut";
import WaitingRoom from "./pages/WaitingRoom/WaitingRoom";
import SpotifyForArtists from "./pages/SpotifyForArtists";
import GetEvenMore from "./pages/PostInvestorNetwork/GetEvenMore";
import UpcomingProjects from "./pages/PostInvestorNetwork/UpcomingWorks/UpcomingProjects";
import ActiveArtistRedirection from "./pages/ActiveArtistRedirection";
import InviteBidders from "./pages/PostInvestorNetwork/InviteBidders";
import InviteBidderReview from "./pages/PostInvestorNetwork/InviteBidderReview";
import ArtistDashboard from "./pages/ArtistDashboard/ArtistDashboard";
import SetTargetAdvance from "./pages/PostInvestorNetwork/SetTargetAdvance/SetTargetAdvance";
import { OnboardingEmbedConditional } from "./pages/PostInvestorNetwork/helper";
import ArtistOnboardingForm from "./pages/AcceptOffer/ArtistOnboardingForm";
import ErrorPopup from "../component/ErrorPopup/ErrorPopup";
import { EmailPreferencesWrapper } from "./pages/EmailPreferences/EmailPreferences";
import { initDataDog, initiateHotjar } from "../utils/Helper";
import HomePageContent from "./pages/HomePageContent/HomePageContent";
import HomepageContentForm from "./pages/HomepageContentForm";
import GeneratingOffers from "./pages/GeneratingOffers";
import segment from "../utils/segment";
import FFValidateUser from "./pages/Auth/FFValidateUser/FFValidateUser";
import FFSecureLogin from "./pages/Auth/FFSecureLogin";
import Segment from "../component/Segment/withSegment";
import Publishers from "./pages/Publishers";
import PublisherForm from "./pages/Publishers/PublisherForm";
import SignupFastFlow from "./pages/Auth/SignupFastFlow";
import ConfirmOwnership from "./pages/ConfirmOwnership";
import ConfirmedOffers from "./pages/ConfirmedOffers";

const WaitingRoomWithSegment = Segment(WaitingRoom, {
  events: [
    {
      type: "track",
      name: "artistTooSmall",
    },
    {
      type: "track",
      name: "artistFailed",
    },
    {
      type: "track",
      name: "offersPending",
    },
    {
      type: "track",
      name: "artistTooBig",
    },
  ],
});

function Page() {
  const [page, setPage] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (!navigator.userAgent.includes("DatadogSynthetics")) {
      if (page !== location.pathname) {
        segment.page();
        setPage(location.pathname);
      }
    }
  }, [location, page]);
  return <></>;
}

export class MainRoutes extends React.Component {
  constructor() {
    super();
    this.updateInfo = (newInfo) => {
      this.setState((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          ...newInfo,
        },
      }));
    };
    this.state = {
      info: {
        update: this.updateInfo,
      },
    };
  }

  componentDidMount() {
    this.unListen = this.props.history.listen(hubSpotTracking);
    initDataDog();
    initiateHotjar();
  }

  componentWillUnmount() {
    this.unListen();
  }
  render() {
    return (
      <OnboardingEmbedConditional>
        <>
          <Page />
          <ErrorPopup>
            <ToastContainer
              className="Toast"
              type="default"
              position={toast.POSITION.TOP_RIGHT}
              autoClose={4000}
              hideProgressBar
              pauseOnHover
            />
            <ThemeManager>
              <Switch>
                <Route path="/privacy" component={PrivacyPolicy} exact />
                <Route path={ERROR_PAGE} component={ErrorPage} exact />
                <Route
                  path={ERROR_FORBIDDEN}
                  component={ErrorForbidden}
                  exact
                />
                <Route
                  path={ERROR_SERVER_DOWN}
                  component={ErrorServerDown}
                  exact
                />
                <Route
                  path={MAINTENANCE_PAGE}
                  component={SiteMaintenancePage}
                  exact
                />
                <PrivateRoute
                  exact
                  path={`${HOME}`}
                  component={ActiveArtistRedirection}
                />
                <Route
                  exact
                  path={`${LOGIN}/:token`}
                  render={(props) => (
                    <Login {...props} info={this.state.info} />
                  )}
                ></Route>
                <AuthRoute exact path={`${LOGIN}`}>
                  <Login {...this.props} info={this.state.info} />
                </AuthRoute>
                <AuthRoute exact path={`${ADMIN_LOGIN}`}>
                  <Login {...this.props} info={this.state.info} />
                </AuthRoute>
                <AuthRoute exact path={`${GETFUNDED}`} component={GetFunded} />
                <PrivateRoute
                  exact
                  path={`${LINK_ANOTHER_ARTIST}`}
                  component={LinkAnotherArtist}
                />
                <AuthRoute
                  exact
                  path={`${GETFUNDED_AUTH}`}
                  component={GetFundedAuth}
                />
                <Route
                  exact
                  path={`${FF_SECURE_LOGIN}`}
                  component={FFSecureLogin}
                />
                <Route
                  exact
                  path={`${FORGOT_PASS}`}
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path={`${CHANGE_PASS}/:key`}
                  component={ChangePassword}
                />
                <AuthRoute
                  exact
                  path={`${VERIFY}/:key`}
                  component={VerifyUser}
                />
                <AuthRoute
                  exact
                  path={`${FF_VALIDATE}/:key`}
                  component={FFValidateUser}
                />

                <PrivateRoute path={MY_ACCOUNT} component={MyAccount} />
                <PrivateRoute
                  path={DASHBOARD}
                  component={ActiveArtistRedirection}
                />
                <PrivateRoute
                  path={ADMIN_DASHBOARD}
                  component={AdminDashboard}
                />
                <PrivateRoute
                  path={ADMIN_BLACKLIST}
                  component={ManageBlacklist}
                />
                <PrivateRoute
                  path={WAITING_ROOM}
                  component={WaitingRoomWithSegment}
                />
                <AuthRoute path={LOGGED_OUT} component={LoggedOut} />
                <PrivateRoute
                  exact
                  path={ADMIN_WHITELABEL}
                  component={whiteLabelListing}
                />
                <PrivateRoute
                  exact
                  path={ADMIN_WHITELABEL_NEW_ACCOUNT}
                  component={whiteLabelAccount}
                />
                <PrivateRoute
                  exact
                  path={ADMIN_WHITELABEL_EDIT_ACCOUNT}
                  component={whiteLabelAccount}
                />
                <PrivateRoute
                  exact
                  path={ADMIN_MAINTENANCE_MODE}
                  component={MaintenanceMode}
                />
                <PrivateRoute
                  exact
                  path={DISTRIBUTORS}
                  component={Distributors}
                />
                <PrivateRoute
                  exact
                  path={DISTRIBUTORS_CREATE}
                  component={DistributorForm}
                />
                <PrivateRoute
                  exact
                  path={DISTRIBUTORS_EDIT}
                  component={DistributorForm}
                />
                <PrivateRoute
                  exact
                  path={DEMO_ARTIST}
                  component={DemoArtists}
                />
                <PrivateRoute
                  exact
                  path={HOMEPAGE_CONTENT}
                  component={HomePageContent}
                />
                <PrivateRoute
                  exact
                  path={DEMO_ARTIST_CREATE}
                  component={DemoArtistForm}
                />
                <PrivateRoute
                  exact
                  path={DEMO_ARTIST_EDIT}
                  component={DemoArtistForm}
                />
                <Route
                  exact
                  path={`${DEMO_DASHBOARD}/:id?`}
                  component={DemoDashboard}
                />
                <PrivateRoute path={FUNDING} component={YourAdvance} />
                <PrivateRoute path={TUNE_MY_DATA}>
                  <TuneMyData {...this.props} info={this.state.info} />
                </PrivateRoute>
                <PrivateRoute path={SEND_REPORTS} component={SendReports} />
                <PrivateRoute
                  path={PUBLISHING_REPORTS}
                  component={SendReports}
                  key={"publishing"}
                />
                <PrivateRoute
                  exact
                  path={GENERATING_OFFERS}
                  component={GeneratingOffers}
                />
                <PrivateRoute
                  exact
                  path={CONFIRM_OWNERSHIP}
                  component={ConfirmOwnership}
                />
                <PrivateRoute path={WELCOME_BACK} component={WelcomeBack} />
                <PrivateRoute path={OFFER_SUMMARY} component={YourAdvance} />
                <PrivateRoute
                  path={CONFIRMED_OFFERS_V2}
                  component={ConfirmedOffers}
                />
                <PrivateRoute path={ADJUST_TERMS} component={YourAdvance} />
                <PrivateRoute path={ARTIST_VERIFY} component={ArtistVerify} />
                <PrivateRoute
                  exact
                  path={ACCEPT_OFFER}
                  component={AcceptOffer}
                />
                <PrivateRoute
                  exact
                  path={FINAL_CHECKOUT_ONBOARDING}
                  component={ArtistOnboardingForm}
                />
                <PrivateRoute
                  path={LINK_SPOTIFY}
                  component={SpotifyForArtists}
                />
                <PrivateRoute path={GET_EVEN_MORE} component={GetEvenMore} />
                <PrivateRoute
                  path={INVITE_BIDDERS_PREVIEW}
                  component={InviteBidderReview}
                />
                <PrivateRoute
                  path={SET_TARGET_ADVANCE}
                  component={SetTargetAdvance}
                />
                <PrivateRoute
                  path={CONTACT_BEATBREAD_GET_FUNDED}
                  component={ContactForFund}
                />
                <PrivateRoute
                  path={POST_CONFIRMATION}
                  component={ArtistDashboard}
                />
                <PrivateRoute
                  path={POSTED_TO_NETWORK}
                  component={ArtistDashboard}
                />
                <PrivateRoute
                  path={MARKETPLACE_ONBOARDING}
                  component={ArtistOnboardingForm}
                />
                <Route
                  path={ACK_IN_RANGE_1}
                  component={AcknowledgementScreen}
                />
                <Route
                  path={ACK_IN_RANGE_2}
                  component={AcknowledgementScreen}
                />
                <Route
                  path={ACK_IN_RANGE_3}
                  component={AcknowledgementScreen}
                />
                <Route
                  path={ACK_IN_RANGE_4}
                  component={AcknowledgementScreen}
                />
                <Route path={ACK_HIGH} component={AcknowledgementScreen} />
                <Route path={ACK_LOW} component={AcknowledgementScreen} />
                <Route path={ACK_ERROR} component={AcknowledgementScreen} />
                <Route path={ACK_NEW} component={AcknowledgementScreen} />
                <Route path={ACK_REDIRECT} component={AckRedirect} />
                <Route
                  path={EMAIL_PREFERENCES}
                  component={EmailPreferencesWrapper}
                />
                <PrivateRoute path={SUCCESS} component={Success} />
                <PrivateRoute path={WHY_BEATBREAD} component={WhyBeatBread} />
                <PrivateRoute path={INVITE_BIDDERS} component={InviteBidders} />
                <PrivateRoute
                  path={UPCOMING_WORKS}
                  component={UpcomingProjects}
                />
                <PrivateRoute
                  exact
                  path={HOMEPAGE_CONTENT_NEW}
                  component={HomepageContentForm}
                />

                <PrivateRoute
                  exact
                  path={HOMEPAGE_CONTENT_EDIT}
                  component={HomepageContentForm}
                />
                <PrivateRoute exact path={PUBLISHERS} component={Publishers} />
                <PrivateRoute
                  exact
                  path={PUBLISHERS_CREATE}
                  component={PublisherForm}
                />
                <PrivateRoute
                  exact
                  path={PUBLISHERS_EDIT}
                  component={PublisherForm}
                />
                <Route
                  exact
                  path={`${SIGNUP}/:spotifyId/:requestId/:agentId?`}
                  component={SignupFastFlow}
                />
                <Redirect to={ERROR_PAGE} />
              </Switch>
            </ThemeManager>
          </ErrorPopup>
        </>
      </OnboardingEmbedConditional>
    );
  }
}

export default withRouter(MainRoutes);
