import { ReactComponent as GeneratingDataIcon } from "../../assets/logos/generatingIcon.svg";
import { ReactComponent as StreamingIcon } from "../../assets/logos/leftNavigationStreamingIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/logos/artist.svg";
import { ReactComponent as ReportIcon } from "../../assets/logos/leftNavigationReportIcon.svg";
import { ReactComponent as PublishingReportIcon } from "../../assets/logos/leftNavigationPublishingIcon.svg";
import { ReactComponent as MusicIcon } from "../../assets/logos/leftNavigationMusicIcon.svg";
import { ReactComponent as WelcomeIcon } from "../../assets/logos/leftNavigationWelcomeIcon.svg";
import { ReactComponent as MoneyIcon } from "../../assets/logos/money-dollar.svg";
import { ReactComponent as DialIcon } from "../../assets/logos/np_dial.svg";
import { ReactComponent as InviteIcon } from "../../assets/logos/np_invite-user.svg";
import { ReactComponent as LogInIcon } from "../../assets/logos/leftNavigationLoginIcon.svg";

import {
  TUNE_MY_DATA,
  SEND_REPORTS,
  FUNDING,
  LINK_SPOTIFY,
  OFFER_SUMMARY,
  ACCEPT_OFFER,
  GET_EVEN_MORE,
  SET_TARGET_ADVANCE,
  UPCOMING_WORKS,
  INVITE_BIDDERS,
  POST_CONFIRMATION,
  ARTIST_REGISTRATION,
  POSTED_TO_NETWORK,
  DATA_REVIEW,
  WAITING_ROOM,
  GENERATING_OFFERS,
  WELCOME_BACK,
  PUBLISHING_REPORTS,
  CONFIRM_OWNERSHIP,
  CONFIRMED_OFFERS_V2,
} from "../../routes/pages/constants";
import globalStyles from "../../styles/variables.scss";
import { FAST_FLOW_STEPS } from "../../routes/pages/Auth/LinkAnotherArtist/constant";

export const VERIFICATION_STATUS = {
  streamingData: 0,
  funding: 1,
  reports: 2,
  publishingReports: 3,
  generatingOffers: 4,
};

export const VERIFICATION_STATUS_ROUTE = {
  [TUNE_MY_DATA]: VERIFICATION_STATUS.streamingData,
  [SEND_REPORTS]: VERIFICATION_STATUS.reports,
  [FUNDING]: VERIFICATION_STATUS.funding,
  [GENERATING_OFFERS]: VERIFICATION_STATUS.offers,
};

export const NAVIGATION_TEXTS = {
  [TUNE_MY_DATA]: {
    title: "Streaming Data",
    status: ["Generating...", "Not yet verified", "Verified", "Verified"],
  },
  [FUNDING]: {
    title: "Customize Funding",
    status: ["Ready", "Ready", "Customized", "Customized"],
  },
  [SEND_REPORTS]: {
    title: "Upload Distribution Reports",
    pageTitle: "Distribution Reports",
    status: [
      "Not yet Submitted",
      "Not yet Submitted",
      "Submitted",
      "Missing Data",
    ],
  },
  [PUBLISHING_REPORTS]: {
    title: "Upload Publishing Reports",
    pageTitle: "Publishing Reports",
    status: [],
  },
  [LINK_SPOTIFY]: {
    title: "Spotify For Artists",
    status: ["Not Linked", "Requested", "Connected", "Connected"],
  },
  [GENERATING_OFFERS]: {
    title: "Next Steps",
    status: [],
  },
  [CONFIRM_OWNERSHIP]: {
    title: "Confirm Ownership",
    status: [],
  },
  [WELCOME_BACK]: {
    title: "Welcome Back!",
    status: [],
  },
  [OFFER_SUMMARY]: {
    title: "Confirmed Offers",
    status: [],
  },
  [CONFIRMED_OFFERS_V2]: {
    title: "Confirmed Offers",
    status: [],
  },
  [ACCEPT_OFFER]: {
    title: "Review Offer Letter",
    status: [],
  },
  [GET_EVEN_MORE]: {
    title: "Get Even More",
    status: [],
  },
  [SET_TARGET_ADVANCE]: {
    title: "Set Target Advance",
    status: [],
  },
  [UPCOMING_WORKS]: {
    title: "Artist Profile",
    status: [],
  },
  [INVITE_BIDDERS]: {
    title: "Invite Bidders",
    status: [],
  },
  [POST_CONFIRMATION]: {
    title: "Confirmation",
    status: [],
  },
  [ARTIST_REGISTRATION]: {
    title: "Artist Registration",
    status: [],
  },
  [POSTED_TO_NETWORK]: {
    title: "Posted To Network",
    status: [],
  },
};

export const NAVIGATION_ICONS = {
  STREAMING: StreamingIcon,
  GENERATING: GeneratingDataIcon,
  REPORTS: ReportIcon,
  PUBLISHING_REPORTS: PublishingReportIcon,
  PROFILE: ProfileIcon,
  SPOTIFY: MusicIcon,
  DIAL: DialIcon,
  INVITE: InviteIcon,
  LOGIN: LogInIcon,
};

export const LEFTNAVIGATION_ICONS = {
  MONEY: MoneyIcon,
  PROFILE: ProfileIcon,
  GENERATING_OFFERS: GeneratingDataIcon,
  WELCOME: WelcomeIcon,
  CONFIRM_OWNERSHIP: MusicIcon,
};

export const NAV_STATUS = {
  PENDING: "pending",
  IN_RANGE: "in_range",
  LINK_PENDING: "link_pending",
  LINKED: "account_linked",
  TOO_BIG: "too_big",
  TOO_SMALL: "too_small",
};

export const FAST_FLOW = {
  WAITING: "Generating Data",
  FUNDING: "Review Estimates",
};

export const mobileNavigationStyle = {
  control: (provided) => ({
    ...provided,
    border: "none",
    outline: "transparent",
    borderRadius: 0,
    margin: 0,
    boxShadow: "none",
    fontSize: 14,
    backgroundColor: globalStyles.sidebarPrimaryBackgroundColor,
    "&:hover": { outline: "none" },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: globalStyles.sidebarFontColor1,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: globalStyles.sidebarFontColor1,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    overflowY: "auto",
    zIndex: 2,
    boxShadow: "none",
    backgroundColor: globalStyles.sidebarPrimaryBackgroundColor,
    color: globalStyles.sidebarFontColor1,
    marginTop: 0,
  }),
};

export const ROUTES = {
  WAITING_ROOM: [WAITING_ROOM],
  FUNNEL: [
    DATA_REVIEW,
    TUNE_MY_DATA,
    FUNDING,
    SEND_REPORTS,
    PUBLISHING_REPORTS,
    LINK_SPOTIFY,
    GENERATING_OFFERS,
    CONFIRM_OWNERSHIP,
  ],
  FINAL_CHECKOUT: [
    WELCOME_BACK,
    OFFER_SUMMARY,
    CONFIRMED_OFFERS_V2,
    ACCEPT_OFFER,
  ],
  MARKETPLACE: [
    GET_EVEN_MORE,
    SET_TARGET_ADVANCE,
    UPCOMING_WORKS,
    INVITE_BIDDERS,
    POST_CONFIRMATION,
  ],
  MARKETPLACE_DONE: [POSTED_TO_NETWORK],
};

export const ARTIST_OUT_RANGE_STATUS_LIST = [
  "",
  FAST_FLOW_STEPS["2SMALL"],
  FAST_FLOW_STEPS["2NEW"],
  FAST_FLOW_STEPS["2BIG"],
  FAST_FLOW_STEPS.ERROR,
  FAST_FLOW_STEPS.FAILED,
];
