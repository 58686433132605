import React from "react";
import styles from "./LeftPanel.module.scss";
import {
  ADMIN_DASHBOARD,
  ADMIN_BLACKLIST,
  ADMIN_WHITELABEL,
  ADMIN_MAINTENANCE_MODE,
  DISTRIBUTORS,
  DEMO_ARTIST,
  HOMEPAGE_CONTENT,
  PUBLISHERS,
} from "../constants";
import StorageService from "../../../utils/StorageService";

const LeftPanel = (props) => {
  const goToUsersListing = () => props.history.push(ADMIN_DASHBOARD);
  const goToBlacklistListing = () => props.history.push(ADMIN_BLACKLIST);
  const goToWhiteLabel = () => props.history.push(ADMIN_WHITELABEL);
  const goToMaintenance = () => props.history.push(ADMIN_MAINTENANCE_MODE);
  const goToDistributors = () => props.history.push(DISTRIBUTORS);
  const goToDemoArtists = () => props.history.push(DEMO_ARTIST);
  const goToHomePageContent = () => props.history.push(HOMEPAGE_CONTENT);
  const goToPublishers = () => props.history.push(PUBLISHERS);

  return (
    <div className={styles.leftPanel}>
      <h1>Admin</h1>
      {StorageService.get("role") === "subAdmin" ? (
        <div className={styles.menuItems}>
          <p
            className={
              props.location.pathname.includes(HOMEPAGE_CONTENT) &&
              styles.active
            }
            onClick={goToHomePageContent}
          >
            Homepage Content
          </p>
        </div>
      ) : (
        <div className={styles.menuItems}>
          <p
            className={
              props.location.pathname === ADMIN_DASHBOARD && styles.active
            }
            onClick={goToUsersListing}
          >
            Users
          </p>
          <p
            className={
              props.location.pathname === ADMIN_BLACKLIST && styles.active
            }
            onClick={goToBlacklistListing}
          >
            Manage Blacklist
          </p>
          <p
            className={
              props.location.pathname.includes(ADMIN_WHITELABEL) &&
              styles.active
            }
            onClick={goToWhiteLabel}
          >
            Whitelabel Accounts
          </p>
          <p
            className={
              props.location.pathname.includes(ADMIN_MAINTENANCE_MODE) &&
              styles.active
            }
            onClick={goToMaintenance}
          >
            Maintenance Mode
          </p>
          <p
            className={
              props.location.pathname.includes(DISTRIBUTORS) && styles.active
            }
            onClick={goToDistributors}
          >
            Distributors
          </p>
          <p
            className={
              props.location.pathname.includes(PUBLISHERS) && styles.active
            }
            onClick={goToPublishers}
          >
            PRO / Publishers
          </p>
          <p
            className={
              props.location.pathname.includes(DEMO_ARTIST) && styles.active
            }
            onClick={goToDemoArtists}
          >
            Demo Artists
          </p>
          <p
            className={
              props.location.pathname.includes(HOMEPAGE_CONTENT) &&
              styles.active
            }
            onClick={goToHomePageContent}
          >
            Homepage Content
          </p>
        </div>
      )}
    </div>
  );
};

export default LeftPanel;
