import React, { useRef } from "react";
import "./Switch.scss";
import { invoke } from "lodash";

const Switch = ({ id, isOn, handleToggle, onColor, title, labelClassName, disabled = false }) => {
  const switchRef = useRef();
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={id}
        disabled={!!disabled}
        ref={switchRef}
        type="checkbox"
      />
      <label
        title={title}
        style={{ background: isOn && onColor }}
        className={`react-switch-label ${labelClassName}`}
        data-testid="toggle-switch"
        onClick={() => {
          invoke(switchRef, "current.click");
        }}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
