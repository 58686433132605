import React from "react";
import { setTitle } from "../../../component/ThemeManager/helper";
import Loader from "../../../component/Loader";
import _, { get } from "lodash";
import htmlParser from "html-react-parser";
import {
  API_URL,
  ADMIN,
  ARTIST_API,
  DEMO_ARTIST_API,
  DEMO_DASHBOARD,
  ACTIVE_DUMMY_ARTIST,
  USER_API,
  TUNE_MY_DATA,
} from "../constants";
import { toast } from "react-toastify";
import request from "../../../utils/request";
import LoginHeader from "../../../component/LoginHeader";
import styles from "./DemoDashboard.module.scss";
import Navigator from "../../../component/Navigation/Navigator";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { Tabs, Tab, Collapse } from "@material-ui/core";
import { getShortNumber } from "../YourAdvance/helper";
import AuthTokenService from "../../../utils/AuthTokenService";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { ARROW_FORWARD } from "../../../component/MaterialIcons/constants";
import { GetErrorMessage } from "../helper";

class DemoDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      demoArtists: [],
      selectedArtist: "",
      selectedArtistIndex: 0,
      musicData: {},
      artistCollapse: false,
    };
  }

  componentDidUpdate() {
    setTitle("Dashboard", this.context);
  }

  componentDidMount() {
    this.getArtistsList();
  }

  getArtistsList = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${DEMO_ARTIST_API}?isActiveDummyArtist=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const exists = _.findIndex(get(res, "data", []), {
            _id: get(this.props, "match.params.id"),
          });
          this.setState(
            {
              demoArtists: get(res, "data", []),
              selectedArtist:
                exists !== -1
                  ? get(this.props, "match.params.id")
                  : get(res, "data[0]._id"),
              selectedArtistIndex: exists === -1 ? 0 : exists,
            },
            this.getArtistDetails,
          );
          this.props.history.push(DEMO_DASHBOARD);
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getArtistDetails = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${DEMO_ARTIST_API}?artistId=${this.state.selectedArtist}&musicData=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            musicData: get(res.data, "musicData"),
          });
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  activateArtist = () => {
    this.setState({ loading: true });
    const { demoArtists, selectedArtistIndex } = this.state;
    const data = {
      method: "POST",
      body: { id: get(demoArtists[selectedArtistIndex], "_id", "") },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVE_DUMMY_ARTIST}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          AuthTokenService.storeToken(res.data.accessToken);
          this.props.history.push({
            pathname: TUNE_MY_DATA,
            state: { fromDashboard: true, isDemo: true },
          });
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  onTabChange = (e, value) => {
    this.setState(
      {
        selectedArtist: value,
        selectedArtistIndex: _.findIndex(this.state.demoArtists, {
          _id: value,
        }),
      },
      this.getArtistDetails,
    );
  };

  getGenreAndName = (artist) =>
    `${get(artist, "musicGenre")} | ${get(artist, "name", "")
      .split(" ")
      .pop()}`;

  render() {
    const { demoArtists, selectedArtistIndex, musicData } = this.state;
    return (
      <>
        <LoginHeader isDemo isDemoDashboard />
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Artist Demo</h2>
            <Navigator isDemo {...this.props} />
          </div>
          <div className={styles.subContainer}>
            {this.state.demoArtists.length ? (
              <div className={styles.tabContainer}>
                <Tabs
                  value={this.state.selectedArtist}
                  variant="scrollable"
                  scrollButtons="off"
                  onChange={this.onTabChange}
                >
                  {this.state.demoArtists.map((artist) => (
                    <Tab
                      label={this.getGenreAndName(artist)}
                      key={artist._id}
                      value={artist._id}
                      className={styles.tab}
                    />
                  ))}
                </Tabs>
                <div className={styles.artistDetailsContainer}>
                  <div className={styles.profile}>
                    <img
                      className={styles.imageContainer}
                      src={get(
                        demoArtists[selectedArtistIndex],
                        `profilePicture[${
                          get(
                            demoArtists[selectedArtistIndex],
                            "profilePicture",
                            [{}],
                          ).length - 1
                        }].url`,
                        "",
                      )}
                      alt="Profile"
                    />
                    <div className={styles.name}>
                      <p>
                        {get(demoArtists[selectedArtistIndex], "musicGenre")}
                      </p>
                      <h2>{get(demoArtists[selectedArtistIndex], "name")}</h2>
                    </div>
                  </div>
                  <div className={styles.summary}>
                    <div className={styles.artistProfile}>
                      <h4 className={styles.heading}>Artist Profile</h4>
                      <div>
                        {htmlParser(
                          get(
                            demoArtists[selectedArtistIndex],
                            "profileSummary",
                            "",
                          ),
                        )}
                      </div>
                    </div>
                    <div className={styles.musicDetails}>
                      <h4 className={styles.heading}>My Music</h4>
                      <div className={styles.musicAnalytics}>
                        <div className={styles.subSections}>
                          <p className={styles.heading}>Top Regions</p>
                          <p className={styles.content}>
                            {get(musicData, "top_regions", []).map(
                              (region, i) => (i === 0 ? region : `, ${region}`),
                            )}
                          </p>
                        </div>
                        <div className={styles.subSections}>
                          <p className={styles.heading}>Monthly Listeners</p>
                          <p className={styles.content}>
                            {getShortNumber(
                              get(
                                musicData,
                                "metrics.monthly_listeners.count",
                                0,
                              ),
                            )}
                            <span> | </span>
                            {`${get(
                              musicData,
                              "metrics.monthly_listeners.growth_percent",
                              0,
                            )}% Growth`}
                          </p>
                        </div>
                        <div className={styles.subSections}>
                          <p className={styles.heading}>YouTube Subscribers</p>
                          <p className={styles.content}>
                            {getShortNumber(
                              get(
                                musicData,
                                "metrics.youtube_subscribers.count",
                                0,
                              ),
                            )}
                            <span> | </span>
                            {`${get(
                              musicData,
                              "metrics.youtube_subscribers.growth_percent",
                              0,
                            )}% Growth`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.btnContainer}>
              <button onClick={this.activateArtist}>
                See Demo Using This Artist <SvgIcons icon={ARROW_FORWARD} />
              </button>
            </div>
          </div>
          <div
            className={`${styles.artistCollapse} ${
              this.state.artistCollapse ? styles.open : ""
            }`}
          >
            <span
              className={styles.arrow}
              onClick={() =>
                this.setState({ artistCollapse: !this.state.artistCollapse })
              }
            />
            <p className={styles.collapseTitle}>Artist Profile</p>
            <Collapse in={this.state.artistCollapse} mountOnEnter unmountOnExit>
              {htmlParser(
                get(demoArtists[selectedArtistIndex], "profileGuide", ""),
              )}
            </Collapse>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </>
    );
  }
}

DemoDashboard.contextType = ThemeContext;
export default DemoDashboard;
