import React, { Component } from "react";
import HorizontalMenu from "../../../../component/HorizontalMenu";
import LoginHeader from "../../../../component/LoginHeader";
import { setTitle } from "../../../../component/ThemeManager/helper";
import styles from "./InviteBidderReview.module.scss";
import { ReactComponent as TextMessage } from "../../../../assets/logos/np_text-message.svg";
import { ARTIST_BIO_MAX_CHARACTERS } from "./constant";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import Image from "../../../../component/Image";
import ReactModal from "react-modal";
import { Form, Formik } from "formik";
import FormField from "../../../../component/FormField/FormField";
import {
  API_URL,
  ARTIST_API,
  BIDDERS,
  INVITE_BIDDERS,
  ME,
  POST_CONFIRMATION,
  UPDATE_MARKETING_STATUS,
  USER_API,
} from "../../constants";
import request from "../../../../utils/request";
import { get } from "lodash";
import * as Yup from "yup";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../helper";
import StorageService from "../../../../utils/StorageService";

export class InviteBidderReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dataLoading: false,
      customModal: false,
      navigationStatus: {},
      bidderDetails: [],
      customMessage: "",
      initalMessage: "",
      selectedArtist: "",
      works: "",
    };
  }

  componentDidUpdate() {
    setTitle("Preview Invite Bidder", this.context);
  }

  componentDidMount() {
    this.getInitialData();
    this.getBidderData();
  }

  getInitialData = () => {
    this.setState({ dataLoading: true });
    const data = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${ME}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ dataLoading: false });
        if (res.status) {
          this.setState(
            {
              selectedArtist: get(res.data, "activeArtist.name", ""),
              works: get(
                res.data,
                "activeArtist.marketPlaceOffer.offer.works",
                "",
              ),
            },
            this.getInitialMessage,
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ dataLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  getBidderData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${BIDDERS}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            bidderDetails: get(res.data, "inviteBidder.bidderDetails", []),
            customMessage: get(res.data, "inviteBidder.customMessage", ""),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setNavigationStatus = (status) => {
    this.setState({ navigationStatus: status });
  };

  handleCustomMessageOpen = () => {
    this.setState({ customModal: true });
  };
  handleCustomMessageCancel = () => {
    this.setState({ customModal: false });
  };

  bidderInviteValidationSchema = Yup.object().shape({
    customMessage: Yup.string()
      .label("Bidder Invitation")
      .required("Please Enter Preview Message")
      .max(
        ARTIST_BIO_MAX_CHARACTERS,
        `Invitation Preview can not exceed ${ARTIST_BIO_MAX_CHARACTERS} characters`,
      ),
  });

  saveInvitationMessage = (value) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: {
        customMessage: value.customMessage,
      },
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${BIDDERS}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            customModal: false,
            customMessage: value.customMessage,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  customModal = () => (
    <ReactModal
      isOpen={this.state.customModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCustomMessageCancel}
      className={styles.customModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.header}>Add a Custom Message</div>
      <div className={styles.chatForm}>
        <Formik
          initialValues={{ customMessage: this.state.customMessage }}
          enableReinitialize
          validationSchema={this.bidderInviteValidationSchema}
          onSubmit={this.saveInvitationMessage}
          data-testid="inviteRender"
        >
          {({ values, dirty }) => (
            <Form>
              <div className="form-group form-group-m0 mb-0">
                <FormField
                  name="customMessage"
                  as="textarea"
                  placeholder="Bidder Invitation"
                  className={styles.textarea}
                  maxCharacters={ARTIST_BIO_MAX_CHARACTERS}
                  value={values.customMessage}
                />
                <span>{dirty}</span>
              </div>

              <div className={styles.modalBtnContainer}>
                <button
                  disabled={!dirty}
                  className={styles.modalBtn}
                  type="submit"
                  data-testid="btn-submit"
                >
                  Done
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  );
  renderImage = () => (
    <div className={styles.image}>
      <Image
        src={StorageService.get("profileImg")}
        alt="artist_profile"
        imageType="profile"
      />
    </div>
  );
  getInitialMessage = () => {
    const works = this.state.works.replace(/[+]/g, "plus");
    const message = (
      <>
        <p>
          This is an invitation to bid on a share of the revenues from{" "}
          {this.state.selectedArtist}’s {works}. This process will take place
          inside our Investor Network which you can read more about{" "}
          <span className={styles.span}>here</span>.
        </p>
        <p>
          Further details on the opportunity are attached to this email. Please
          take a look and if you have questions,{" "}
          <span className={styles.span}>schedule a call</span>
          {" "}with a representative.
        </p>
      </>
    );

    this.setState({ initalMessage: message });
  };
  renderEmailInvitePreview = () => (
    <div className={styles.emailInvite}>
      <span className={styles.inviteHeader}>Email Invitation Preview</span>
      <div className={styles.emailInviteContent}>
        {this.renderImage()}
        <p className={styles.name}>
          {this.state.bidderDetails.length > 0 &&
            this.state.bidderDetails[0].name}
          ,
        </p>
        <span className={styles.previewMessage}>
          <p>{this.state.initalMessage}</p>
          <p>{this.state.customMessage}</p>
        </span>
      </div>
      <div
        className={styles.message}
        onClick={this.handleCustomMessageOpen}
        data-testid="btn-msg"
      >
        <TextMessage />
        <span>Add a Custom Message</span>
      </div>
    </div>
  );
  renderEmailSent = () => (
    <div className={styles.emailSent}>
      <span className={styles.inviteHeader}>Invitation Will Be Sent To</span>
      <div className={styles.emailContent}>
        {this.state.bidderDetails.length > 0 &&
          this.state.bidderDetails.map((data, index) => (
            <div className={styles.inviteContent}>
              <span className={styles.name}>{data.name}</span>
              <span className={styles.email}>{data.email}</span>
              <span className={styles.email}>{data.role}</span>
            </div>
          ))}
      </div>
      <div className={styles.message}>
        <span
          data-testid="btn-invite"
          onClick={() => this.props.history.push(INVITE_BIDDERS)}
        >
          Edit or add more Invitations
        </span>
      </div>
    </div>
  );

  navigationStatusUpdate = (payload, redirectScreen) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(redirectScreen);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu getStatus={this.setNavigationStatus} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader headerTitle={"Want to Invite Additional Investors?"} />
          <div className={styles.pageContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  <div className={styles.inviteBidderContent}>
                    <div className={styles.firstContainer}>
                      <span>
                        You may add a custom message to your invitation. If not,
                        complete your submission by clicking below
                      </span>
                    </div>
                    <div className={styles.secondContainer}>
                      {this.renderEmailInvitePreview()}
                      {this.renderEmailSent()}
                    </div>
                    <div className={styles.thirdContainer}>
                      <span
                        data-testid="btn-bidder"
                        onClick={() => this.props.history.push(INVITE_BIDDERS)}
                      >
                        Go back
                      </span>
                      <button
                        className={styles.simpleButton}
                        data-testid="btn-post"
                        onClick={() =>
                          this.navigationStatusUpdate(
                            { inviteBidder: true },
                            POST_CONFIRMATION,
                          )
                        }
                      >
                        COMPLETE SUBMISSION
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.dataLoading) && (
            <Loader light backgroundNone />
          )}
          {this.customModal()}
        </div>
      </div>
    );
  }
}

export default InviteBidderReview;
InviteBidderReview.contextType = ThemeContext;
