export const CONTENT_TYPE = [
  { label: "beatBread News", value: "News" },
  { label: "Knowledge Center", value: "Articles" },
  { label: "Artist Testimonials", value: "Testimonials" },
];
export const CATEGORY_OPTIONS = [
  "Advancing Independence",
  "Artists Like You",
  "Learn More",
];
export const NEWS_CATEGORY_OPTIONS = ["Press", "Release Notes"];

export const VIDEO_CATEGORY = [
  "About beatBread",
  "Artist testimonials",
  "Funding inspiration",
  "How to",
];
export const TAG_OPTIONS = [
  "FAQs",
  "How-to",
  "Leaks/Videos",
  "Hip-Hop Artists",
  "Bands",
  "R&B",
  "Indie Pop",
  "Artist Successes",
  "Featured Artists",
  "Managers",
  "Independent Artists",
  "Labels",
];

export const FIELD_NAMES = {
  TYPE: {
    NAME: "type",
  },
  CATEGORY: {
    NAME: "category",
    LABEL: "Category",
    PLACEHOLDER: "Select Category",
  },
  NEW_CATEGORY: {
    NAME: "newscategory",
    LABEL: "Category",
    PLACEHOLDER: "Select Category",
  },
  TAG: {
    NAME: "tags",
    LABEL: "Article Tags",
    PLACEHOLDER: "To Search Tags Begin Typing...",
  },
  TITLE: {
    NAME: "title",
  },
  URL: {
    NAME: "url",
  },
  PUBLICATION_DATE: {
    NAME: "pdate",
    LABEL: "Publication Date",
    PLACEHOLDER: "Publication Date*",
  },
  BLOG_CONTENT: {
    NAME: "blogSection",
    LABEL: "Blog Content",
    PLACEHOLDER: "Enter blog data",
  },

  IS_ACTIVE: {
    NAME: "isActive",
  },

  ADMIN_PASSWORD: {
    NAME: "adminPassword",
    LABEL: "Password",
    PLACEHOLDER: "Password",
  },
  AUTHOR: {
    NAME: "author",
    LABEL: "Author",
  },
  BRIEF: {
    NAME: "brief",
    LABEL: "Brief Description(25 Words Or Less)",
    PLACEHOLDER: "Brief Description(25 Words Or Less)",
  },
  WHAT_CHANGE: {
    NAME: "whatChange",
    LABEL: "What's changed?",
    PLACEHOLDER: "What's changed?",
  },
  WHEN: {
    NAME: "when",
    LABEL: "When did it change?",
    PLACEHOLDER: "When did it change?",
  },
  WHAT_IMPORTANT: {
    NAME: "whatImportant",
    LABEL: "What's important or valuable about this change?",
    PLACEHOLDER: "What's important or valuable about this change?",
  },
  WHAT_DO: {
    NAME: "whatDo",
    LABEL: "What do I need to do?",
    PLACEHOLDER: "What do I need to do?",
  },
  GET_HELP: {
    NAME: "getHelp",
    LABEL: "How do I learn more or get help?",
    PLACEHOLDER: "How do I learn more or get help?",
  },
  LISTENER: {
    NAME: "listener",
    LABEL: "Monthly Listener",
    PLACEHOLDER: "Enter Monthly Listener",
  },
};

export const HELPER = {
  NEWS: {
    title: "Title*",
    url: "URL",
    author: "Author*",
  },
  BLOG: {
    title: "Title*",
    url: "URL",
    author: "Author*",
  },
  VIDEO: {
    title: "Title*",
    url: "URL*",
  },
};

export const URL_REGEX =
  "^http(s)?://(www.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$";

export const YOUTUBE_URL_REGEX = "^(https?://)?(www.youtube.com|youtu.be)/.+$";

export const FIELD_INITIAL_VALUES = {
  [FIELD_NAMES.TYPE.NAME]: CONTENT_TYPE[0].value,
  [FIELD_NAMES.CATEGORY.NAME]: null,
  [FIELD_NAMES.NEW_CATEGORY.NAME]: { label: "Press", value: "Press" },
  [FIELD_NAMES.TAG.NAME]: [],
  [FIELD_NAMES.TITLE.NAME]: "",
  [FIELD_NAMES.URL.NAME]: "",
  [FIELD_NAMES.PUBLICATION_DATE.NAME]: "",
  [FIELD_NAMES.BLOG_CONTENT.NAME]: "",
  [FIELD_NAMES.AUTHOR.NAME]: "",
  [FIELD_NAMES.BRIEF.NAME]: "",
  [FIELD_NAMES.WHAT_CHANGE.NAME]: "",
  [FIELD_NAMES.WHEN.NAME]: "",
  [FIELD_NAMES.WHAT_IMPORTANT.NAME]: "",
  [FIELD_NAMES.WHAT_DO.NAME]: "",
  [FIELD_NAMES.GET_HELP.NAME]: "",
  [FIELD_NAMES.LISTENER.NAME]: "",
};

export const IMG_MAX_SIZE = 250000;
export const MAX_CHARECTERS = 100;
