import React, { Component } from "react";
import { get } from "lodash";
import AvatarEditor from "react-avatar-editor";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { toast } from "react-toastify";
import styles from "./MyAccount.module.scss";
import { Form, Formik } from "formik";
import FormField from "../../../component/FormField/FormField";
import ReactRange from "../../../component/Range/ReactRange";
import Loader from "../../../component/Loader";
import { MYACCOUNT } from "../infoIconMsg";
import {
  RANGE_MIN,
  RANGE_MAX,
  RANGE_STEP,
  IMG_MAX_SIZE,
  IMG_EXTENSIONS_REGEX,
  ROLE_OPTIONS,
} from "./constants";
import {
  EMAIL_REGEX,
  PHONE_REGEX,
  PASS_UPPER_LOWER_REGEX,
  PASS_NUM_SPECIAL_REGEX,
} from "../Auth/constants";
import request from "../../../utils/request";
import {
  API_URL,
  USER_API,
  ME,
  DASHBOARD,
  ADMIN_DASHBOARD,
  CHANGE_PASS_API,
  FORGOT_PASS,
  DEMO_DASHBOARD,
} from "../constants";
import { EMAIL_PREFERENCES } from "../../../component/LoginHeader/constants";
import { filterChangedData, setInitialData } from "./helper";
import dataURItoBlob from "./dataURItoBlob";
import { logout } from "../../../utils/Helper";
import StorageService from "../../../utils/StorageService";
import AuthTokenService from "../../../utils/AuthTokenService";
import { GetErrorMessage } from "../helper";
import FormikForm from "../../../component/Form/FormikForm";
import { Link } from "react-router-dom";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import {
  CAMERA,
  PROFILE_ICON,
} from "../../../component/MaterialIcons/constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import countryList from "../../../component/FormField/countries.json";
import Tooltip from "../../../component/Tooltip/Tooltip";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: "",
      imageModal: false,
      deleteModal: false,
      passwordChangeModal: false,
      imgScale: RANGE_MIN,
      croppedImg: "",
      sizeError: false,
      typeError: false,
      loading: false,
      createdAt: "",
      updatedAt: "",
      primaryField: "",
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        countryCode: "",
        country: "",
        role: "",
      },
    };
  }

  componentWillMount() {
    ReactModal.setAppElement("body");
  }

  componentDidMount() {
    this.getAccountData();
  }

  componentDidUpdate() {
    setTitle("My Account", this.context);
  }

  getAccountData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestURL = `${API_URL}${USER_API}${ME}`;
    request(requestURL, data)
      .then((res) => {
        this.SetDataInForm(res);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  SetDataInForm = (res) => {
    this.setState({ loading: false });
    if (res.status) {
      const { data } = res;
      if (data.role === "demo") {
        this.props.history.push(DEMO_DASHBOARD);
      }
      const formData = setInitialData(data, this.state.initialValues);
      this.setState({
        initialValues: formData,
        croppedImg: data.profilePicture,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      });
      return true;
    }
    toast.error(get(res, "message"));
    this.props.history.push(DASHBOARD);
  };

  handleCancel = () => {
    this.setState({
      imageModal: false,
      imgScale: RANGE_MIN,
      deleteModal: false,
      passwordChangeModal: false,
    });
  };

  saveImageAPICall = (blob, image) => {
    const formData = new FormData();
    formData.append("profilePicture", blob);
    const data = {
      method: "PUT",
      body: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    const requestURL = `${API_URL}${USER_API}${ME}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({
          loading: false,
          imgScale: RANGE_MIN,
          imageModal: false,
        });
        if (res.status) {
          toast.success(get(res, "message"));
          this.setState({ croppedImg: image });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({
          loading: false,
          imgScale: RANGE_MIN,
          imageModal: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleSaveImage = () => {
    this.setState({ loading: true });
    if (this.editor) {
      const image = this.editor.getImage().toDataURL();

      const blob = dataURItoBlob(image);
      this.saveImageAPICall(blob, image);
    }
  };

  onScaleChange = (values) => {
    this.setState({ imgScale: values[0] });
  };

  setEditorRef = (editor) => (this.editor = editor);

  checkImageValidation = (file) => {
    const fileType = IMG_EXTENSIONS_REGEX;

    if (!fileType.test(file[0].type)) {
      this.setState({ typeError: true });
      return false;
    }

    if (file[0].size > IMG_MAX_SIZE) {
      this.setState({ sizeError: true });
      return false;
    }
    return file;
  };

  onChangeFileHandler = (event) => {
    this.setState({ typeError: false, sizeError: false });
    const file = event.target.files;

    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file);
    }

    if (validateFile) {
      const reader = new FileReader();
      reader.readAsDataURL(validateFile[0]);
      reader.onloadend = (e) => {
        this.setState({
          profileImg: e.target.result,
          imageModal: true,
        });
      };
    }

    event.target.value = null;
  };

  nameRegex = /^[a-zA-Z0-9. '`]+$/;

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required()
      .min(2, "Please enter valid First Name")
      .max(255)
      .matches(this.nameRegex, "Please enter First Name")
      .label("First name"),
    lastName: Yup.string()
      .required()
      .min(2, "Please enter valid Last Name")
      .max(255)
      .matches(this.nameRegex, "Please enter Last Name")
      .label("Last name"),
    email: Yup.string()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Please enter valid phone number")
      .label("Phone number"),
    country: Yup.string().required().label("Country"),
    role: Yup.string().label("Role"),
  });

  passValidation = Yup.string()
    .required()
    .min(8, "Please enter valid password between 8 to 20 characters")
    .max(20, "Please enter valid password between 8 to 20 characters")
    .matches(
      PASS_UPPER_LOWER_REGEX,
      "Password should have at least 1 uppercase letter & 1 lowercase letter.",
    )
    .matches(
      PASS_NUM_SPECIAL_REGEX,
      "Password should have at least 1 number & 1 special character.",
    );

  passValidationSchema = Yup.object().shape({
    currentPassword: this.passValidation.label("Current password"),
    newPassword: this.passValidation.label("New password"),
  });

  saveDataSuccess = (res, values) => {
    this.setState({ loading: false });
    if (res.status) {
      const formData = setInitialData(values, this.state.initialValues);
      this.setState({
        initialValues: { ...formData },
      });
      toast.success(get(res, "message"));
      this.props.toggleDrawer(false)();
      return true;
    }
    toast.error(get(res, "message"));
    this.props.toggleDrawer(false)();
  };

  saveChangesCall = (values) => {
    this.setState({ loading: true });
    const payload = filterChangedData(values, this.state.initialValues);

    const data = {
      method: "PUT",
      body: payload,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const requestURL = `${API_URL}${USER_API}${ME}`;
    request(requestURL, data)
      .then((res) => {
        this.saveDataSuccess(res, values);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  getCountryList = () => {
    return countryList.map(({ name, ...rest }) => {
      return { label: name, value: name };
    });
  };

  deleteAccountConfirm = () => {
    this.setState({ loading: true });

    const data = {
      method: "DELETE",
    };
    const requestURL = `${API_URL}${USER_API}${ME}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false, deleteModal: false });
        if (res.status) {
          toast.success(get(res, "message"));
          if (StorageService.get("customToken")) {
            const token = StorageService.get("customToken");
            AuthTokenService.storeToken(token);
            this.props.history.push(ADMIN_DASHBOARD);
            return true;
          }
          logout();
          window.location.href = get(this.context, "whitelistUrl[0]", "");
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, deleteModal: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  deleteAccountVerify = () => {
    this.setState({ deleteModal: true });
  };

  deleteModal = () => (
    <ReactModal
      isOpen={this.state.deleteModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <h3>Are you sure you want to delete the account?</h3>
      <div className={styles.modalBtnContainer}>
        <button className={styles.primary} onClick={this.deleteAccountConfirm}>
          Yes
        </button>
        <button className={styles.secondary} onClick={this.handleCancel}>
          No
        </button>
      </div>
    </ReactModal>
  );

  imageModal = () => (
    <ReactModal
      isOpen={this.state.imageModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <AvatarEditor
        ref={this.setEditorRef}
        image={this.state.profileImg}
        width={200}
        height={200}
        scale={this.state.imgScale}
        borderRadius={150}
      />
      <ReactRange
        values={[this.state.imgScale]}
        max={RANGE_MAX}
        min={RANGE_MIN}
        step={RANGE_STEP}
        onChange={this.onScaleChange}
        thumbText={["Scale", ""]}
        valuePositionBottom
      />
      <div className={styles.modalBtnContainer}>
        <button className={styles.primary} onClick={this.handleSaveImage}>
          Save
        </button>
        <button className={styles.secondary} onClick={this.handleCancel}>
          Cancel
        </button>
      </div>
    </ReactModal>
  );

  passwordChangeCall = (values) => {
    this.setState({ loading: true });
    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${ME}${CHANGE_PASS_API}`;

    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(res.message);
          this.handleCancel();
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  passwordChangeModal = () => (
    <ReactModal
      isOpen={this.state.passwordChangeModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <FormikForm
        initialValues={{ currentPassword: "", newPassword: "" }}
        validationSchema={this.passValidationSchema}
        onSubmit={this.passwordChangeCall}
      >
        <div className={styles.formContainer}>
          <Form className={styles.passForm}>
            <h3>Change your password</h3>
            <div className="form-group mb-0 form-group-m0">
              <FormField
                name="currentPassword"
                placeholder="Current password"
                as="password"
                label="Current password"
              />
            </div>
            <div className="form-group mb-0 form-group-m0">
              <Tooltip
                place="top"
                id="info"
                light
                content={
                  <div className={`textSize14`}>
                    <span className="font-weight-bold">
                      Password should fulfil following conditions:
                    </span>
                    <li>Minimum 8 and Maximum 20 characters</li>
                    <li>
                      At least 1 uppercase and 1 lowercase alphabet is mandatory
                    </li>
                    <li>
                      At least 1 special character and 1 number is mandatory
                    </li>
                  </div>
                }
              />
              <FormField
                name="newPassword"
                placeholder="New password"
                as="password"
                label="New password"
              />
            </div>
            <div className={`form-group pb-2 pt-1`}>
              <Link className={styles.textLnk} to={FORGOT_PASS}>
                Forgot password
              </Link>
            </div>
            <div className="form-group m-0 pb-0">
              <button type="submit" disabled={this.state.loading}>
                Update password
              </button>
            </div>
          </Form>
        </div>
      </FormikForm>
    </ReactModal>
  );

  render() {
    return (
      <>
        <div className={styles.container}>
          <div
            className={styles.logoContainer}
            onClick={this.props.toggleDrawer(false)}
          >
            <div
              className={styles.closeIcon}
              onClick={this.props.toggleDrawer(false)}
            >
              <KeyboardBackspaceIcon />
            </div>
            <Image
              src={get(this.context, "emailLogo", "")}
              className={styles.logo}
              alt="Logo"
            />
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.detailsHeader}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <div className={`d-flex align-items-center`}>
                    <h1>
                      <span>My</span> account
                    </h1>
                    <Tooltip
                      place="top"
                      light
                      id="my_account"
                      content={MYACCOUNT}
                    />
                  </div>
                </div>
                <p className={`${styles.subtitle}`}>
                  Edit your account settings.
                </p>
              </div>
            </div>
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.saveChangesCall}
              enableReinitialize
              data-testid="formRender"
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div
                    className={`${styles.detailsBody} ${styles.borderBottom}`}
                  >
                    <div className={styles.formContainer}>
                      <div className={styles.profileContainer}>
                        <div className={styles.profileEdit}>
                          <div className={styles.customFileUpload}>
                            <input
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              title=""
                              multiple={false}
                              onChange={this.onChangeFileHandler}
                            />

                            {this.state.croppedImg ? (
                              <>
                                <Image
                                  src={this.state.croppedImg}
                                  alt="profile"
                                  imageType={IMAGE_TYPE.PROFILE}
                                />
                                <div className={styles.cameraSvg}>
                                  <SvgIcons icon={CAMERA} />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.profileIconContainer}>
                                  <SvgIcons icon={PROFILE_ICON} />
                                </div>

                                <div
                                  className={`${styles.cameraSvg} ${styles.profileCamera}`}
                                >
                                  <SvgIcons icon={CAMERA} />
                                </div>
                              </>
                            )}

                            {this.state.sizeError && (
                              <span className={styles.errorText}>
                                File size should be less than 5MB
                              </span>
                            )}
                            {this.state.typeError && (
                              <span className={styles.errorText}>
                                File type should be image only
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={styles.nameContainer}>
                          <div className={`form-group ${styles.formField}`}>
                            <FormField
                              name="firstName"
                              type="text"
                              placeholder="Enter First Name"
                              label="First name"
                            />
                          </div>

                          <div className={`form-group ${styles.formField}`}>
                            <FormField
                              name="lastName"
                              type="text"
                              placeholder="Enter Last Name"
                              label="Last name"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`form-group ${styles.formField} ${styles.role}`}
                      >
                        <FormField
                          name="role"
                          as="select"
                          placeholder="Enter Role"
                          options={ROLE_OPTIONS}
                          label="I am..."
                        />
                      </div>
                      <div className={`form-group ${styles.formField}`}>
                        <FormField
                          name="email"
                          type="text"
                          placeholder="Enter Email Address"
                          label="Email address"
                        />
                      </div>

                      <div className={`form-group ${styles.formField}`}>
                        <FormField
                          name="phone"
                          as="phone"
                          placeholder="Enter Phone Number"
                          label="Phone Number"
                        />
                      </div>
                      <div
                        className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                      >
                        <FormField
                          name="country"
                          as="select"
                          placeholder="Select Country"
                          label="Country"
                          options={this.getCountryList()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`form-group ${styles.passwordContainer} `}>
                    <span
                      className={styles.textLnk}
                      data-testid="textLnk"
                      onClick={() => {
                        this.setState({ passwordChangeModal: true });
                      }}
                    >
                      Change password
                    </span>
                  </div>
                  <div className={`form-group `}>
                    <div
                      className={styles.textLnk}
                      onClick={() =>
                        this.props.handleDrawer &&
                        this.props.handleDrawer(EMAIL_PREFERENCES)
                      }
                      data-testid="handleDrawer"
                    >
                      Email Preferences
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <div>
                      <button
                        type="button"
                        className={styles.delete}
                        disabled={this.state.loading}
                        onClick={this.deleteAccountVerify}
                      >
                        Delete My Account
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className={styles.save}
                        disabled={this.state.loading}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {this.imageModal()}
        {this.deleteModal()}
        {this.passwordChangeModal()}
        {this.state.loading && <Loader light />}
      </>
    );
  }
}

MyAccount.contextType = ThemeContext;
export default MyAccount;
