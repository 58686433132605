import React, { Component } from "react";
import ReactModal from "react-modal";
import HorizontalMenu from "../../../../component/HorizontalMenu";
import LoginHeader from "../../../../component/LoginHeader";
import classes from "../../YourAdvance/YourAdvance.module.scss";
import styles from "./InviteBidders.module.scss";
import { ReactComponent as Negotiations } from "../../../../assets/logos/negotiations.svg";
import FormField from "../../../../component/FormField/FormField";
import { ReactComponent as User } from "../../../../assets/logos/user.svg";
import { Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import { ReactComponent as TrashIcon } from "../../../../assets/logos/trash-icon.svg";
import * as Yup from "yup";
import { EMAIL_REGEX } from "../../Auth/constants";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import { get } from "lodash";
import {
  API_URL,
  ARTIST_API,
  INVITE_BIDDER,
  INVITE_BIDDERS_PREVIEW,
  POST_CONFIRMATION,
  UPDATE_MARKETING_STATUS,
  USER_API,
} from "../../constants";
import request from "../../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../../helper";
import { ReactComponent as EditIcon } from "../../../../assets/logos/edit-icon.svg";
import Loader from "../../../../component/Loader";
import { INITIAL_FIELD_DATA } from "./constants";
import { setTitle } from "../../../../component/ThemeManager/helper";

class InviteBidders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      initialData: {},
      noRecordFound: false,
      loading: false,
      isFieldModalOpen: false,
      editModelFieldIndex: 0,
    };
  }
  nameRegex = /^[a-zA-Z'.\s-]*$/;
  componentDidMount() {
    this.getBidderData();
  }
  componentDidUpdate() {
    setTitle("Invite Bidders", this.context);
  }
  handleCloseModal = () =>
    this.setState({
      isModalOpen: false,
      isFieldModalOpen: false,
    });
  handleOpenModel = () => {
    this.setState({
      isModalOpen: true,
    });
  };
  addInvestor = (values, validateForm, setFieldError, setFieldTouched) => {
    validateForm().then((val) => {
      if (!get(val, "emptyFieldError", "") && !get(val, "error", "")) {
        this.setState({
          initialData: {
            bidderData: [...values.bidderData, { ...INITIAL_FIELD_DATA }],
            error: "",
            emptyFieldError: "",
          },
          isFieldModalOpen: true,
          editModelFieldIndex: values.bidderData.length,
        });
      } else {
        setFieldTouched("emptyFieldError", true);
        setFieldTouched("error", true);
        setFieldError("emptyFieldError", val.emptyFieldError);
        setFieldError("error", val.error);
      }
    });
  };
  handleSubmit = (values, validateForm, setFieldError, setFieldTouched) => {
    validateForm().then((val) => {
      if (!get(val, "emptyFieldError", "") && !get(val, "error", "")) {
        this.setState({
          initialData: {
            bidderData: [...values.bidderData],
          },
          isFieldModalOpen: false,
          error: "",
        });
      } else {
        setFieldTouched("emptyFieldError", true);
        setFieldTouched("error", true);
        setFieldError("emptyFieldError", val.emptyFieldError);
        setFieldError("error", val.error);
      }
    });
  };

  addMobileInvestor = (
    values,
    validateForm,
    setFieldError,
    setFieldTouched,
  ) => {
    return (
      <ReactModal
        isOpen={this.state.isFieldModalOpen}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleCloseModal}
        className={styles.biddersModal}
        overlayClassName={`${styles.modalOverlay} ${styles.mobileOverLay}`}
      >
        <div className={styles.mobileSubTitle}>
          <p>Bidder information</p>
        </div>
        <div className="form-group">
          <FormField
            name={`bidderData[${this.state.editModelFieldIndex}.name]`}
            icon={<User />}
            as="icon"
            isBoxInput
            iconComponent
            placeholder="Enter Bidder name"
          />
        </div>
        <div className={`form-group ${styles.emailInput}`}>
          <FormField
            name={`bidderData[${this.state.editModelFieldIndex}.email]`}
            isBoxInput
            placeholder="Enter bidder email"
          />
        </div>
        <div className="form-group">
          <FormField
            name={`bidderData[${this.state.editModelFieldIndex}.role]`}
            as="select"
            options={this.roleOptions}
            placeholder="Enter bidder type"
            menuPosition
            isBoxInput
            components={{
              IndicatorSeparator: null,
            }}
          />
        </div>
        <div className={`form-group ${styles.errorField}`}>
          <FormField name="error" dataTestId="error" as="textMsg" />
        </div>
        <div className={`form-group ${styles.errorField}`}>
          <FormField
            name="emptyFieldError"
            dataTestId="emptyFieldError"
            as="textMsg"
          />
        </div>
        <div className={styles.saveChanges}>
          <button
            data-testid="saveChanges"
            type="submit"
            onClick={() =>
              this.handleSubmit(
                values,
                validateForm,
                setFieldError,
                setFieldTouched,
              )
            }
          >
            SAVE CHANGES
          </button>
        </div>
      </ReactModal>
    );
  };

  getBidderData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${INVITE_BIDDER}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (res.data.inviteBidder.bidderDetails.length) {
            this.setState({
              initialData: {
                bidderData: get(res.data, "inviteBidder.bidderDetails", [
                  { ...INITIAL_FIELD_DATA },
                ]),
                error: "",
                emptyFieldError: "",
              },
            });
          } else {
            this.setState({
              initialData: { bidderData: [{ ...INITIAL_FIELD_DATA }] },
              isModalOpen: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  saveInvesorDetail = (values) => {
    this.setState({ loading: true });
    const payload = {
      bidder: values.bidderData,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${INVITE_BIDDER}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          this.props.history.push(INVITE_BIDDERS_PREVIEW);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  updateMarketingStatus = () => {
    this.setState({ loading: true });
    const payload = {
      skipInvite: true,
    };

    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${UPDATE_MARKETING_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          this.props.history.push(POST_CONFIRMATION);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  hideDeleteIcon = () => {
    if (
      this.state.initialData.bidderData &&
      this.state.initialData.bidderData.length === 1
    ) {
      return (
        this.state.initialData.bidderData[0]["name"] &&
        this.state.initialData.bidderData[0]["email"] &&
        this.state.initialData.bidderData[0]["role"]
      );
    }
    return true;
  };
  hideAddIcon = () => {
    if (
      this.state.initialData.bidderData &&
      this.state.initialData.bidderData.length <= 9
    ) {
      return true;
    }
    return false;
  };
  addButtonFunction = (
    values,
    validateForm,
    setFieldError,
    setFieldTouched,
  ) => {
    return (
      <>
        {this.hideAddIcon() && (
          <button
            className={`${styles.addInvestor} ${styles.mobileAddInvestor}`}
            data-testid="addInvestor"
            type="button"
            onClick={() =>
              this.addInvestor(
                values,
                validateForm,
                setFieldError,
                setFieldTouched,
              )
            }
          >
            <span className={styles.add} type="button">
              +
            </span>
            <span className={styles.addInvitation} type="button">
              Add a new Invitation
            </span>
          </button>
        )}
      </>
    );
  };

  validationSchema = Yup.object().shape({
    bidderData: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .min(2, "Please enter valid Name")
          .max(255)
          .matches(this.nameRegex, "Please enter valid Name"),

        email: Yup.string()
          .required("Email is required")
          .matches(EMAIL_REGEX, "Please enter valid email only"),

        role: Yup.string().required("Please select the role"),
      }),
    ),

    error: Yup.string().test(
      "error",
      "You’ve already entered that email address",
      function () {
        if (
          this &&
          this.parent.bidderData &&
          this.parent.bidderData.length > 0
        ) {
          const filtered = this.parent.bidderData.filter((elem, i) => {
            return this.parent.bidderData.find(
              (item, index) => item.email === elem.email && i !== index,
            );
          });

          if (filtered.length > 1) {
            return false;
          }
          return true;
        }
        return true;
      },
    ),
    emptyFieldError: Yup.string().test(
      "emptyFieldError",
      "You have not added the first invite.",
      function () {
        const filteredValues = this.parent.bidderData.filter(
          (data) => !data.name && !data.email && !data.role,
        );
        if (filteredValues.length) {
          return false;
        }
        return true;
      },
    ),
  });

  roleOptions = [
    { value: "distributor", label: "Distributor" },
    { value: "producer", label: "Producer" },
    { value: "label", label: "Label" },
    { value: "other", label: "Other" },
  ];

  deleteInvestorRow = (index) => {
    const filtered =
      this.state.initialData.bidderData &&
      this.state.initialData.bidderData.filter((data, i) => index !== i);
    this.setState({
      initialData: {
        bidderData:
          filtered && filtered.length ? filtered : [{ ...INITIAL_FIELD_DATA }],
      },
    });
  };

  startingModal = () => {
    return (
      <ReactModal
        isOpen={this.state.isModalOpen}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleCloseModal}
        className={styles.biddersModal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContainer}>
          <h2>
            Invite investors, distributors, and collaborators from your network
            to join in
          </h2>
          <p>
            They don’t have to make an offer on the full advance to compete with
            beatBread’s Investor Network.
          </p>
          <p>
            They can just fund a share of your advance. We’ll handle all of your
            deal administration, including contracts with each investor who
            joins the deal.
          </p>

          <div className={styles.btnContainer}>
            <button className={styles.primary} onClick={this.handleCloseModal}>
              CONTINUE
            </button>
          </div>
        </div>
      </ReactModal>
    );
  };

  render() {
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu {...this.props} />
        <div className={classes.funnelContainer}>
          <LoginHeader headerTitle="Want to Invite Additional Investors?" />
          <div className={`${styles.pageContainer} `}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.container}>
                  <div className={styles.mobileTitle}>
                    <p>
                      Entertaining competing offers? Invite all of your
                      potential partners to bid to ensure you get their
                      strongest offers
                    </p>
                  </div>
                  <div className={styles.topTitle}>
                    <h3>Invite Investors from your close network.</h3>
                    <p className={styles.infoTitle}>
                      We’ll invite the rest.
                      <i
                        className={styles.infoIcon}
                        onClick={this.handleOpenModel}
                        data-place="top"
                      ></i>
                    </p>
                  </div>
                  <Formik
                    initialValues={this.state.initialData}
                    validationSchema={this.validationSchema}
                    onSubmit={this.saveInvesorDetail}
                    enableReinitialize
                    data-testid="formRender"
                  >
                    {({
                      errors,
                      values,
                      validateForm,
                      setFieldError,
                      setFieldTouched,
                    }) => (
                      <Form>
                        <div
                          className={`${styles.detailsBody} ${styles.borderBottom}`}
                        >
                          <div className={styles.investorDeal}>
                            <Negotiations />
                            <p>
                              Investors you invite do not have to invest in all
                              of your deal. They can invest in only a piece, as
                              little as 1%.
                            </p>
                          </div>
                          <div className={styles.formContainer}>
                            <div className={styles.biddersForm}>
                              <div className={styles.scroll}>
                                <div className={styles.mobileScroll}>
                                  {get(values, "bidderData", []).map(
                                    (data, index) => (
                                      <>
                                        <div
                                          className={styles.gridContainer}
                                          key={index}
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            className={styles.biddersInput}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              md={8}
                                              className={`form-group ${styles.firstInvestorInput}`}
                                            >
                                              <FormField
                                                name={`bidderData[${index}.name]`}
                                                placeholder="Full Name"
                                                icon={<User />}
                                                as="icon"
                                                isBoxInput
                                                iconComponent
                                              />
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              md={10}
                                              className={`form-group ${styles.investorInputEamil}`}
                                            >
                                              <FormField
                                                name={`bidderData[${index}.email]`}
                                                isBoxInput
                                                placeholder="Email Address"
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              className={`form-group  ${styles.dropDown}`}
                                            >
                                              <FormField
                                                name={`bidderData[${index}.role]`}
                                                as="select"
                                                placeholder="Role"
                                                options={this.roleOptions}
                                                menuPosition
                                                isBoxInput
                                                components={{
                                                  IndicatorSeparator: null,
                                                }}
                                              />

                                              <div
                                                className={styles.deleteIcon}
                                              >
                                                {this.hideDeleteIcon() && (
                                                  <TrashIcon
                                                    data-testid="trashIcon"
                                                    onClick={() =>
                                                      this.deleteInvestorRow(
                                                        index,
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <div className={styles.mobileView}>
                                          <div className={styles.subContainer}>
                                            <div
                                              className={styles.nameContainer}
                                            >
                                              <div className={styles.userSvg}>
                                                <User />
                                              </div>
                                              <div>{data.name}</div>
                                            </div>
                                            <div
                                              className={styles.iconContainer}
                                            >
                                              <div className={styles.editIcon}>
                                                <EditIcon
                                                  data-testid="editIcon"
                                                  onClick={() =>
                                                    this.setState({
                                                      isFieldModalOpen: true,
                                                      editModelFieldIndex:
                                                        index,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <TrashIcon
                                                  data-testid="trashIcon2"
                                                  onClick={() =>
                                                    this.deleteInvestorRow(
                                                      index,
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ),
                                  )}
                                </div>
                                <div className={styles.errormobileField}>
                                  <div
                                    className={`form-group ${styles.errorField}`}
                                  >
                                    <FormField
                                      name="emptyFieldError"
                                      dataTestId="emptyFieldError"
                                      as="textMsg"
                                    />
                                  </div>
                                  <div
                                    className={`form-group ${styles.errorField}`}
                                  >
                                    <FormField
                                      name="error"
                                      dataTestId="error"
                                      as="textMsg"
                                    />
                                  </div>
                                </div>
                                {this.addButtonFunction(
                                  values,
                                  validateForm,
                                  setFieldError,
                                  setFieldTouched,
                                )}
                              </div>
                              <div className={styles.errorFormField}>
                                <div
                                  className={`form-group ${styles.errorField}`}
                                >
                                  <FormField
                                    name="emptyFieldError"
                                    dataTestId="emptyFieldError"
                                    as="textMsg"
                                  />
                                </div>
                                <div
                                  className={`form-group ${styles.errorField}`}
                                >
                                  <FormField
                                    name="error"
                                    dataTestId="error"
                                    as="textMsg"
                                  />
                                </div>
                              </div>
                              <div className={styles.addInvestorContainer}>
                                <div data-testid="addButton">
                                  {this.addButtonFunction(
                                    values,
                                    validateForm,
                                    setFieldError,
                                    setFieldTouched,
                                  )}
                                </div>

                                <div className={styles.submitBtnContainer}>
                                  <button
                                    className={styles.noThanks}
                                    onClick={this.updateMarketingStatus}
                                    type="button"
                                  >
                                    I don’t want to invite anyone else
                                  </button>
                                  <button
                                    className={styles.previewBtn}
                                    type="submit"
                                  >
                                    PREVIEW INVITES
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.addMobileInvestor(
                          values,
                          validateForm,
                          setFieldError,
                          setFieldTouched,
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading && <Loader light backgroundNone />}
        {this.startingModal()}
      </div>
    );
  }
}
InviteBidders.contextType = ThemeContext;
export default InviteBidders;
