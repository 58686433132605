import React, { Component } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Form } from "formik";
import * as Yup from "yup";
import mainstyles from "./FFSecureLogin.module.scss";
import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import { API_URL, AUTH, LOGIN, SECURE_LINK } from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../constants";
import request from "../../../../utils/request";
import { forbiddenCheck, GetErrorMessage } from "../../helper";
import { userExists } from "../../../../utils/Helper";
import LoginHeader from "../../../../component/LoginHeader";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class FFSecureLogin extends Component {
  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(this.props.location.search);
    this.state = {
      loading: false,
      successCall: false,
      email: "",
      initialStateValue: {
        email: searchParams.get("emailId") || "",
      },
    };
  }
  componentDidMount() {
    forbiddenCheck();
  }

  componentDidUpdate() {
    setTitle("Secure Login", this.context);
  }

  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
  });

  handelSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      email: values.email,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SECURE_LINK}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            successCall: true,
            email: values.email,
          });
        } else {
          toast.error(get(res, "message"));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  sucessCallFn = () =>
    !this.state.successCall ? (
      <>
        <div className="form-group form-group-m0 mb-0">
          <FormField
            name="email"
            placeholder="Email address"
            label="Email address"
          />
        </div>
        <div className="form-group mb-0">
          <button
            type="submit"
            disabled={this.state.loading}
            className={styles.btn}
          >
            SEND SECURE LINK
          </button>
          {!userExists() && (
            <Link className={styles.formLink} to={LOGIN}>
              Return to login
            </Link>
          )}
        </div>
      </>
    ) : (
      <div className={styles.resendContainer}>
        <span>Did not receive any text or mail? </span>
        <button type="submit">Resend</button>
      </div>
    );

  render() {
    return (
      <div className={styles.mainContainer}>
        {userExists() ? <LoginHeader /> : <Header />}
        <div
          className={`${styles.loginContent} ${mainstyles.secureLoginContent}`}
        >
          <FormikForm
            initialValues={this.state.initialStateValue}
            validationSchema={this.validationSchema}
            onSubmit={this.handelSubmit}
          >
            <div
              className={`${styles.formContainer} ${mainstyles.secureLoginForm}`}
            >
              <Form>
                {!this.state.successCall && (
                  <>
                    <h1>
                      Please enter your sign up email.
                      <br />
                      We will send you a secure sign in link!
                    </h1>
                  </>
                )}
                <p className={styles.subTitle}>
                  {this.state.successCall &&
                    "Instructions for creating a new password have been sent to you."}
                </p>
                {this.sucessCallFn()}
              </Form>
            </div>
          </FormikForm>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

FFSecureLogin.contextType = ThemeContext;
export default FFSecureLogin;
