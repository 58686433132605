import "whatwg-fetch";
import { get, invoke } from "lodash";
import { logout, userExists } from "./Helper";
import AuthTokenService from "./AuthTokenService";
import StorageService from "./StorageService";
import {
  DASHBOARD,
  ADMIN_DASHBOARD,
  ERROR_FORBIDDEN,
  ERROR_PAGE,
  ADMIN_WHITELABEL,
  MANAGE_BLACKLIST,
  ADMIN_LOGIN,
  ACK_REDIRECT,
  ACK_ERROR,
  ACK_HIGH,
  ACK_LOW,
  ACK_NEW,
  ACK_IN_RANGE_1,
  ACK_IN_RANGE_2,
  ACK_IN_RANGE_3,
  MAINTENANCE_PAGE,
  SEGMENT_DATA,
} from "../routes/pages/constants";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 304
  ) {
    return null;
  }
  return response.json();
}

function parseBlob(response) {
  const httpStatus = [204, 205, 304, 401];
  if (httpStatus.indexOf(response.status) !== -1) {
    return null;
  }
  return response.blob();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 304) {
    return response;
  }
  if (response.status === 401) {
    if (
      [DASHBOARD, ADMIN_DASHBOARD, ADMIN_WHITELABEL, MANAGE_BLACKLIST].indexOf(
        window.location.pathname,
      ) !== -1
    ) {
      window.location.reload();
      logout();
    }
    return response;
  }
  if (response.status === 403) {
    logout();
    window.location.replace(ERROR_FORBIDDEN);
    return response;
  }
  if (response.status === 404) {
    if (
      invoke(get(response, "url", ""), "match", new RegExp(`${SEGMENT_DATA}`))
    ) {
      return response;
    }
    logout();
    window.location.replace(ERROR_PAGE);
    return response;
  }
  if (
    response.status === 422 ||
    response.status === 400 ||
    response.status === 504 ||
    response.status === 411
  ) {
    return response;
  }

  if (response.status === 503) {
    const routes = [
      ADMIN_LOGIN,
      ACK_REDIRECT,
      ACK_ERROR,
      ACK_HIGH,
      ACK_LOW,
      ACK_NEW,
      ACK_IN_RANGE_1,
      ACK_IN_RANGE_2,
      ACK_IN_RANGE_3,
      MAINTENANCE_PAGE,
    ];
    if (routes.indexOf(window.location.pathname) === -1) {
      window.location.replace(MAINTENANCE_PAGE);
    }
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, useDefaultToken = true) {
  const option = {
    method: options.method,
    headers: options.headers
      ? options.headers
      : {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
  };
  if (options.body) {
    if (options.headers) {
      option.body = options.body;
    } else {
      option.body = JSON.stringify(options.body);
    }
  }
  if (userExists()) {
    option.headers.Authorization = useDefaultToken
      ? `${AuthTokenService.get()}`
      : `${StorageService.get("customToken")}`;
  }
  if (options.blob) {
    return fetch(url, option).then(checkStatus).then(parseBlob);
  }
  return fetch(url, option).then(checkStatus).then(parseJSON);
}

export function requestForFormData(url, options, useDefaultToken = true) {
  const option = {
    method: options.method,
    body: options.body,
    headers: { "Access-Control-Allow-Origin": "*" },
  };

  if (userExists()) {
    option.headers.Authorization = useDefaultToken
      ? `${AuthTokenService.get()}`
      : `${StorageService.get("customToken")}`;
  }
  return fetch(url, option).then(checkStatus).then(parseJSON);
}
