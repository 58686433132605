import React, { Component } from "react";
import styles from "./YourAdvance.module.scss";
import { Link } from "react-router-dom";
import { SUCCESS } from "../constants";
class Success extends Component {
  componentDidMount() {
    window.open(
      "https://calendly.com/d/ckr-fq9-vm7/review-your-deal-terms-with-beatbread"
    );
  }

  goBackArtist = () => {
    setTimeout(() => {
      this.props.history.goBack();
      this.props.history.goBack();
    }, 10);
  };

  render() {
    return (
      <div className={styles.main_success}>
        <div className={styles.success}>
          <h1>Success</h1>
          <p>You were redirected to a Calendly appointment scheduler.</p>
          <div className={styles.buttonLine}>
            <Link to={SUCCESS} className={styles.scheduleBtn}>
              Go to Calendly
            </Link>
          </div>
          <p></p>
          <a
            data-testid="backBtn"
            className={styles.goBackLink}
            onClick={() => this.goBackArtist()}
          >
            Return to customize your advance
          </a>
        </div>
      </div>
    );
  }
}

export default Success;
