import React, { Component } from "react";
import { get } from "lodash";
import { Form } from "formik";
import * as Yup from "yup";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import countryList from "../../../../component/FormField/countries.json";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  REGISTER_API,
  HOME,
  PARTNER_USER_REGISTER_API,
} from "../../constants";
import Loader from "../../../../component/Loader";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import StorageService from "../../../../utils/StorageService";
import request from "../../../../utils/request";
import { GetErrorMessage } from "../../helper";
import {
  PHONE_REGEX,
  PASS_UPPER_LOWER_REGEX,
  PASS_NUM_SPECIAL_REGEX,
  USA,
} from "../../Auth/constants";
import AuthTokenService from "../../../../utils/AuthTokenService";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import { getSubDomain } from "../../../../component/ThemeManager/helper";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import { ROLE_OPTIONS } from "../../MyAccount/constants";
import { ReactComponent as BeatBreadLogo } from "../../../../img/beatBreadlogo.svg";
import segment from "../../../../utils/segment";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      identifier: "",
      _id: "",
      error: false,
      fname: "",
      lname: "",
      country: "",
      artistDetails: StorageService.get("ackArtistDetails", {
        localStorage: true,
      }),
      profileUrl: StorageService.get("ackArtistImg", {
        localStorage: true,
      }),
    };
  }

  nameRegex = /^[a-zA-Z0-9. '`]+$/;

  buttonText = () =>
    this.props.ackScreen ? `Create account` : `See funding options`;

  partnerScreen = () => get(this.state, "artistDetails.isPartner");

  partnerValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(8, "Please enter valid password between 8 to 20 characters")
      .max(20, "Please enter valid password between 8 to 20 characters")
      .matches(
        PASS_UPPER_LOWER_REGEX,
        "Password should have at least 1 uppercase letter & 1 lowercase letter.",
      )
      .matches(
        PASS_NUM_SPECIAL_REGEX,
        "Password should have at least 1 number & 1 special character.",
      )
      .label("Password"),
    confirmPassword: Yup.string()
      .required("confirm password is a required field.")
      .oneOf(
        [Yup.ref("password"), null],
        "Password should be same as New Password",
      ),
  });

  validationSchema = Yup.object().shape({
    textMsg: Yup.string().test(
      "err-test",
      "Please enter at least one of the above URLs",
      function () {
        return true;
      },
    ),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Please enter valid phone number")
      .label("Phone number"),
    fname: Yup.string()
      .required()
      .min(2, "Please enter valid first name")
      .max(255)
      .matches(this.nameRegex, "Please enter valid first name")
      .label("First name"),
    lname: Yup.string()
      .required()
      .min(2, "Please enter valid last name")
      .max(255)
      .matches(this.nameRegex, "Please enter valid last name")
      .label("Last name"),
    role: Yup.string().required().label("Role"),
    country: Yup.string().required().label("Country"),
    password: Yup.string()
      .required()
      .min(8, "Please enter valid password between 8 to 20 characters")
      .max(20, "Please enter valid password between 8 to 20 characters")
      .matches(
        PASS_UPPER_LOWER_REGEX,
        "Password should have at least 1 uppercase letter & 1 lowercase letter.",
      )
      .matches(
        PASS_NUM_SPECIAL_REGEX,
        "Password should have at least 1 number & 1 special character.",
      )
      .label("Password"),
  });
  buttonText = () =>
    this.props.ackScreen ? `Create account` : `See funding options`;

  handelSubmit = (values) => {
    let phone = "";
    if (values.phone) {
      const regExp = new RegExp(values.countryCode);
      phone = values.phone.replace(regExp, "");
    }
    segment.track.createdAccount();
    segment.identify(this.props.userId);
    this.setState({ loading: true });
    const payload = {
      firstName: values.fname,
      lastName: values.lname,
      _id: this.props.userId,
      password: values.password,
      artistId:
        get(this.props, "location.state.data.artistId") || this.props.artistId,
      verificationCode:
        get(this.props, "location.state.data.verificationCode") ||
        this.props.verificationCode,
      countryCode: values.countryCode,
      role: values.role,
      country: values.country,
      phone,
    };

    segment.storage.set(null,payload.artistId,this.props.userId);

    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${REGISTER_API}`;
    request(requestURL, data)
      .then((res) => {
        if (!res.status) {
          this.setState({ loading: false });
          toast.error(get(res, "message"));
          return [];
        }
        localStorage.removeItem("ackArtistDetails");
        AuthTokenService.storeToken(res.data.artistAccessToken);
        this.props.history.push(HOME);
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
    return true;
  };
  partnerDataSubmit = (values) => {
    this.setState({ loading: true });

    segment.track.createdAccount();
    segment.identify(get(this.state, "artistDetails.idUser") || this.props.userId);
    const payload = {
      password: values.password,
      _id: get(this.state, "artistDetails.idUser") || this.props.userId,
      artistId:
        get(this.props, "location.state.data.artistId") || this.props.artistId,
      verificationCode:
        get(this.props, "location.state.data.verificationCode") ||
        this.props.verificationCode,
    };

    segment.storage.set(null,payload.artistId,payload._id);

    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${PARTNER_USER_REGISTER_API}`;
    request(requestURL, data)
      .then((res) => {
        if (!res.status) {
          this.setState({ loading: false });
          toast.error(get(res, "message"));
          return [];
        }
        localStorage.removeItem("ackArtistDetails");
        AuthTokenService.storeToken(res.data.artistAccessToken);
        this.props.history.push(HOME);
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
    return true;
  };

  handleInputChange = (e) => {
    if (e.target.name !== "password" && e.target.name !== undefined) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      StorageService.set([e.target.name], e.target.value);
    }
  };

  getCountryList = () => {
    const contiresList = [...countryList];
    const index = countryList.findIndex((el) => el.alpha3 === USA);
    index !== -1 && contiresList.unshift(countryList[index]);
    return contiresList.map(({ name }) => {
      return { label: name, value: name };
    });
  };

  render() {
    return (
      <>
        <FormikForm
          initialValues={{
            fname: "",
            lname: "",
            password: "",
            role: "",
            textMsg: "",
            phone: "",
            countryCode: "",
            country: "",
            userName: "",
            confirmPassword: "",
          }}
          validationSchema={
            this.partnerScreen()
              ? this.partnerValidationSchema
              : this.validationSchema
          }
          onSubmit={
            this.partnerScreen() ? this.partnerDataSubmit : this.handelSubmit
          }
        >
          <Form
            onChange={(e) => this.handleInputChange(e, this)}
            className={this.props.className}
          >
            <div className={styles.formHeader}>
              <div className={styles.mainHeader}>
                <Image
                  src={this.state.profileUrl}
                  alt={get(this.state.artistDetails, "name")}
                  imageType={IMAGE_TYPE.PROFILE}
                  className={styles.artistImage}
                />
                {this.partnerScreen() ? (
                  <Image
                    src={get(this.context, "appLogo")}
                    alt="logo"
                    imageType={IMAGE_TYPE.LOGO}
                    className={styles.headerImages}
                  />
                ) : (
                  BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 && (
                    <div className={styles.headerImages}>
                      <BeatBreadLogo />
                    </div>
                  )
                )}
                <div className={styles.headerText}>
                  <p>
                    Hey, <span>nice to meet you!</span>
                  </p>
                </div>
              </div>
              <div className={styles.createAccountText}>
                {!this.partnerScreen() ? (
                  <p>Let’s create your account.</p>
                ) : (
                  <p className={styles.partnerText}>
                    We previously created an account for you with a temporary
                    password. Please enter a new password so that you can access
                    your account in the future.
                  </p>
                )}
              </div>
            </div>
            {!this.partnerScreen() ? (
              <>
                <div className={`${styles.nameContainer}`}>
                  <div
                    className={`${styles.formgroup} ${styles.fnameContainer} form-group form-group-m0 mb-0`}
                  >
                    <FormField
                      name="fname"
                      placeholder="First Name"
                      label="First name"
                    />
                  </div>
                  <div
                    className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                  >
                    <FormField
                      name="lname"
                      placeholder="Last Name"
                      label="Last name"
                    />
                  </div>
                </div>

                <div
                  className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                >
                  <FormField
                    name="role"
                    as="select"
                    placeholder="Select a role"
                    label="I am"
                    options={ROLE_OPTIONS}
                    components={{ IndicatorSeparator: null }}
                  />
                </div>
                <div
                  className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                >
                  <FormField
                    name="country"
                    as="select"
                    placeholder="Select Country"
                    label="Country"
                    options={this.getCountryList()}
                    components={{ IndicatorSeparator: null }}
                  />
                </div>
                <div
                  className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                >
                  <FormField
                    name="phone"
                    as="phone"
                    placeholder="Phone Number"
                    label={
                      <>
                        <span>Phone number</span>{" "}
                        <span className={`${styles.optionalSpan}`}>
                          Optional
                        </span>
                      </>
                    }
                    labelClassName="w-100 d-flex"
                  />
                </div>
                <div className={`${styles.formgroup} form-group  mb-0`}>
                  <i
                    className={`infoIcon ${styles.infoIcon}`}
                    data-tip
                    data-for="info"
                  ></i>
                  <ReactTooltip
                    className={styles.tooltip}
                    id="info"
                    type="dark"
                    effect="solid"
                    place="top"
                  >
                    <div className={`textSize14`}>
                      <span className="font-weight-bold">
                        Password should fulfil following conditions:
                      </span>
                      <li>Minimum 8 and Maximum 20 characters</li>
                      <li>
                        At least 1 uppercase and 1 lowercase alphabet is
                        mandatory
                      </li>
                      <li>
                        At least 1 special character and 1 number is mandatory
                      </li>
                    </div>
                  </ReactTooltip>
                  <FormField
                    name="password"
                    placeholder="Password"
                    as="password"
                    label="Password"
                  />
                </div>

                <div
                  className={`form-group ${styles.registerContent} mb-0 mt-auto `}
                >
                  <button
                    type="submit"
                    className={`${styles.btnregister}`}
                    disabled={this.state.loading}
                  >
                    {this.buttonText()}
                  </button>
                </div>
              </>
            ) : (
              <div>
                <div
                  className={`${styles.formgroup} ${styles.fnameContainer} form-group form-group-m0 mb-0`}
                >
                  <p>{get(this.state, "artistDetails.userEmail")}</p>
                </div>
                <div
                  className={`${styles.formgroup} form-group form-group-m0 mb-0`}
                >
                  <FormField
                    name="password"
                    placeholder="Update Password"
                    label="Update Password"
                  />
                </div>
                <div
                  className={`${styles.formgroup} ${styles.marginTop} form-group form-group-m0 mb-0`}
                >
                  <FormField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                  />
                </div>
                <div
                  className={`form-group ${styles.registerContent} ${styles.borderTop} mb-0 mt-auto `}
                >
                  <button
                    type="submit"
                    className={`${styles.btnregister} ${styles.padding}`}
                    disabled={this.state.loading}
                  >
                    Create Account
                  </button>
                </div>
              </div>
            )}
          </Form>
        </FormikForm>
        {this.state.loading && <Loader />}
      </>
    );
  }
}
Register.contextType = ThemeContext;

export default Register;
