import React from "react";
import ReactModal from "react-modal";
import styles from "./LoginHeader.module.scss";
import { numberWithCommas } from "../../routes/pages/YourAdvance/helper";
import { get, invoke } from "lodash";
import {
  GENERATING_OFFERS,
  LINK_SPOTIFY,
  SEND_REPORTS,
} from "../../routes/pages/constants";

export const checkActivePath = () => {
  if (
    window.location.pathname.includes(SEND_REPORTS) ||
    window.location.pathname.includes(LINK_SPOTIFY) ||
    window.location.pathname.includes(GENERATING_OFFERS)
  ) {
    return true;
  }
  return false;
};

export const RenderModal = (props) => {
  const renderEstimatedValue = () => {
    if (get(props, "selectedArtist.isFastFlow")) {
      const termValue =
        get(props, "selectedArtist.estimatedOffer.offer.term") / 12;
      return termValue === 7 ? `8 Years` : `${termValue} Years`;
    } else {
      if (get(props, "selectedArtist.offer.term", "") > 1) {
        return `${get(props, "selectedArtist.offer.term", "")} Years`;
      } else {
        return `${get(props, "selectedArtist.offer.term", "")} Year`;
      }
    }
  };

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={props.handleCloseModal}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.offerContainer}>
        <div className={styles.dealContainer}>
          <div className={styles.dealTextContainer}>
            <div className={styles.dealText}>Selected Deal Structure</div>
          </div>
          <div className={styles.informationText}>
            Below is a summary of your previously selected deal structure. You
            can make changes by returning to Customize Funding.
          </div>
        </div>
        <div className={styles.estimatesContainer}>
          <div className={styles.estimatesText}>Total Advance Estimate</div>
          <span className={styles.estimatesValue}>
            $
            {get(props, "selectedArtist.isFastFlow") ? (
              <>
                {numberWithCommas(invoke(props, "getMinEstimate"))} -{" "}
                {numberWithCommas(invoke(props, "getMaxEstimate"))}
              </>
            ) : (
              numberWithCommas(
                get(
                  props,
                  "selectedArtist.offerStage.defaultOffer.totalAdvance",
                  "",
                ),
              )
            )}
          </span>
        </div>
        <div className={styles.catalogContainer}>
          <div className={styles.catalogText}>
            {get(props, "selectedArtist.isFastFlow")
              ? get(props, "selectedArtist.estimatedOffer.offer.display")
              : get(props, "selectedArtist.offer.works", "")}
          </div>
          <div className={styles.catalogLine}></div>
          <div className={styles.termContainer}>
            <div className={styles.termText} data-testid="term_text">
              {renderEstimatedValue()} Term,{" "}
              <span className={styles.incomeText}>
                {get(props, "selectedArtist.isFastFlow")
                  ? get(
                      props,
                      "selectedArtist.estimatedOffer.offer.flowthrough",
                    )
                  : get(props, "selectedArtist.offer.flow_through", "")}
                % income paid to artist
              </span>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          {checkActivePath() && (
            <button
              className={styles.returnBtn}
              onClick={props.handleReturnFunc}
              data-testid="return-btn"
            >
              {get(props, "selectedArtist.isFastFlow", false)
                ? "Return to review estimates"
                : "Return to customize funding"}
            </button>
          )}
          <button
            className={styles.primaryBtn}
            onClick={props.handleCloseModal}
            data-testid="modal-close"
          >
            OK
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
