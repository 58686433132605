import React, { Component } from "react";
import styles from "../Misc.module.scss";
import { Link } from "react-router-dom";
import { HOME } from "../../constants";
import {
  getPartnerName,
  getSubDomain,
} from "../../../../component/ThemeManager/helper";
import { get } from "lodash";
import { errorPopup } from "../../../../component/ErrorPopup/helper";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import segment from "../../../../utils/segment";
class ErrorServerDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  getDomainName = () => {
    return BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1
      ? "beatbread.com"
      : "chordcash.com";
  };

  setIsClose = () => {
    this.setState({ isOpen: false });
  };

  componentDidMount() {
    segment.track.serviceDown();
  }

  render() {
    return (
      <>
        <div className="theme-bg-dark">
          <div className={styles.errorContainer}>
            <div>
              {!get(this.props, "location.search") ? (
                <h1>503</h1>
              ) : (
                <h1>500</h1>
              )}
            </div>
            <div>
              {!get(this.props, "location.search") ? (
                <h3>We’ll be back online shortly</h3>
              ) : (
                <>
                  <h3>Something went wrong</h3>
                  {errorPopup.bind(this)()}
                </>
              )}
            </div>
            <a
              href={`mailto:help@${getPartnerName()}.com`}
              className={styles.homeLink}
            >
              Contact {getPartnerName()}
            </a>
            <Link to={HOME} className={styles.homeLink}>
              Return Home
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default ErrorServerDown;
