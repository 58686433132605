import React, { Component } from "react";
import { toast } from "react-toastify";
import _, { debounce, get } from "lodash";
import Tooltip from "../../../component/Tooltip/Tooltip";
import { LineChart, Line } from "recharts";
import {
  getShortNumber,
  numberWithCommas,
  formatTrendData,
  FunnelCollapseGuide,
} from "./helper";
import {
  MIN_VALUE,
  MAX_VALUE,
  RANGE_STEP,
  MIN_MULTIPLIER_VALUE,
  MAX_MULTIPLIER_VALUE,
  INCOME_SLIDER_STEP,
  INIT_TREND_DATA,
  CUMULATIVE_MAX_PERCENT,
  MIN_TRACK_DISPLAY,
  OTHER_ISRC,
  VERIFY_DATA,
  VERIFY_DATA_NOTE,
} from "./constants";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  FUNDING,
  ERROR_SERVER_DOWN,
} from "../constants";
import { TUNE_YOUR_DATA } from "../infoIconMsg";
import generalColors from "../../../styles/global.scss";
import classes from "../Auth/Auth.module.scss";
import { GetErrorMessage } from "../helper";
import request from "../../../utils/request";
import LoginHeader from "../../../component/LoginHeader";
import ReactRange from "../../../component/Range/ReactRange";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { MUSIC_ICON } from "../../../component/MaterialIcons/constants";
import Loader from "../../../component/Loader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import Image from "../../../component/Image";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import styles from "./TuneMyData.module.scss";
import { ReactComponent as VerifyPageSvg } from "../../../img/streamingData.svg";
import { ReactComponent as MusicSvg } from "../../../img/music.svg";
import closeSvg from "../../../img/modalClose.svg";
import ReactModal from "react-modal";
import ContactPopup from "../../../component/ContactPopup";
import segment from "../../../utils/segment";
class TuneMyData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFirstTime: false,
      firstName: "",
      topTracks: [],
      resetTopTracks: [],
      streamingVolume: "",
      streamingIncome: "",
      soundExchangeIncome: "",
      resetSoundExchangeIncome: "",
      streamingIncomeMax: "",
      streamingIncomeMin: "",
      lastMonthIncome: "",
      artistProfile: {},
      offerStage: {},
      isConfirm: false,
      shortLoading: false,
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
      cumulativeVisibleTrack: 0,
      minDisplayIndex: 0,
      othersTrackIndex: 0,
      isSliderChange: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (get(history.location, "state.tuneData")) {
      const data = get(history.location, "state.tuneData");
      this.setState(
        {
          isFirstTime: data.isFirstTime,
          firstName: data.firstName,
          topTracks: data.top_tracks,
          resetTopTracks: data.top_tracks.map((track) => ({
            ...track,
          })),
          streamingVolume: data.streaming_volume,
          streamingIncome: data.streaming_income,
          soundExchangeIncome: data.sound_exchange_income,
          resetSoundExchangeIncome: data.sound_exchange_income,
          lastMonthIncome: data.last_month_income,
          artistProfile: {
            url: get(
              data,
              `profilePicture[${
                get(data, "profilePicture", [{}]).length - 1
              }].url`,
              "",
            ),
            name: data.name,
          },
          offerStage: data.offerStage,
          othersTrackIndex: _.findIndex(data.top_tracks, ["isrc", OTHER_ISRC]),
        },
        this.changeTuneDataSlider,
      );
      const state = { ...history.location.state };
      delete state.tuneData;
      history.replace({ ...history.location, state });
      return null;
    }
    this.getAdvanceData();
  }

  componentDidUpdate() {
    setTitle(VERIFY_DATA, this.context);
  }
  onSliderChange = debounce(() => this.handleSaveTunedData(true), 3000);

  loaderText = () => {
    return (
      <p>
        We are generating <br /> your<span> estimates</span>
      </p>
    );
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=true`;

    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState(
            {
              isFirstTime: res.data.isFirstTime,
              firstName: res.data.firstName,
              topTracks: res.data.top_tracks,
              resetTopTracks: res.data.top_tracks.map((track) => ({
                ...track,
              })),
              streamingVolume: res.data.streaming_volume,
              streamingIncome: res.data.streaming_income,
              soundExchangeIncome: res.data.sound_exchange_income,
              resetSoundExchangeIncome: res.data.sound_exchange_income,
              lastMonthIncome: res.data.last_month_income,
              artistProfile: {
                url: get(
                  res.data,
                  `profilePicture[${
                    get(res.data, "profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                ),
                name: res.data.name,
              },
              offerStage: res.data.offerStage,
              sliderStreams: res.data.slider_streams,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  "",
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  "",
                ),
                funnelGuideIncomeVerification: get(
                  res.data,
                  "funnelGuideIncomeVerification",
                  "",
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  "",
                ),
              },
              othersTrackIndex: _.findIndex(res.data.top_tracks, [
                "isrc",
                OTHER_ISRC,
              ]),
            },
            this.changeTuneDataSlider,
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  getLineChart = (data) => (
    <LineChart width={80} height={30} data={formatTrendData(data)}>
      <Line
        dataKey="value"
        stroke={generalColors.sidebarTooltipIconColor}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
    </LineChart>
  );

  getOtherTracksInfo = () => {
    let otherStreams = 0;
    const {
      othersTrackIndex,
      topTracks,
      minDisplayIndex,
      cumulativeVisibleTrack,
    } = this.state;
    const trendLine = INIT_TREND_DATA;
    topTracks.forEach((track, index) => {
      if (
        track.cumulativeSum > cumulativeVisibleTrack &&
        minDisplayIndex < index &&
        MIN_TRACK_DISPLAY < index + 1
      ) {
        otherStreams += track.streams_last_month;
        for (let i = 0; i < trendLine.length; i++) {
          trendLine[i] += track.trend_line_data[i];
        }
      }
    });
    return (
      (othersTrackIndex !== -1 || !!otherStreams) && (
        <tr className={styles.partitionTitle}>
          <td>
            <div className={styles.trackProfile}>
              <div className={styles.trackName}>
                <p>
                  <MusicSvg className={styles.musicSvg} />
                  All other tracks
                </p>
                <p className={`${styles.mobLastMonth} ${styles.otherTrack}`}>
                  {othersTrackIndex !== -1 ? (
                    <span className={styles.otherTrackSpan}>
                      {topTracks[othersTrackIndex] &&
                        getShortNumber(
                          topTracks[othersTrackIndex].streams_last_month,
                        )}
                    </span>
                  ) : (
                    <span>{getShortNumber(otherStreams)}</span>
                  )}
                  <span className={`${styles.title} ${styles.otherTrackTitle}`}>
                    {" "}
                    Streams last month
                  </span>
                </p>
              </div>
            </div>
          </td>
          <td>
            {othersTrackIndex !== -1 ? (
              <div className={styles.chartData}>
                <p>
                  {getShortNumber(
                    get(topTracks, [othersTrackIndex, "streams_last_month"], 0),
                  )}
                </p>
                {this.getLineChart(
                  get(topTracks, [othersTrackIndex, "trend_line_data"], []),
                )}
              </div>
            ) : (
              <div className={styles.chartData}>
                <p>{getShortNumber(otherStreams)}</p>
                {this.getLineChart(trendLine)}
              </div>
            )}
          </td>
          <td></td>
        </tr>
      )
    );
  };

  changeTuneDataSlider = (value, isrc) => {
    let sum = 0;
    let cumulativeSum = 0;
    const data = this.state.topTracks.map((track) => {
      if (track.isrc === isrc) {
        track.streaming_info_share = value;
      }
      sum =
        sum +
        (track.streaming_info_share / 100) *
          track.slider_streams *
          track.dollar_per_stream;
      cumulativeSum =
        cumulativeSum +
        (track.streaming_info_share / 100) * track.slider_streams;
      track.cumulativeSum = cumulativeSum;
      return track;
    });

    let minDisplayIndex = this.state.minDisplayIndex;
    data.forEach((track, index) => {
      if (
        track.cumulativeSum <=
          data[data.length - 1].cumulativeSum * CUMULATIVE_MAX_PERCENT &&
        minDisplayIndex < index
      ) {
        minDisplayIndex = index;
      }
    });

    const minRange =
      Math.round((sum * MIN_MULTIPLIER_VALUE) / INCOME_SLIDER_STEP) *
      INCOME_SLIDER_STEP;
    let maxRange =
      Math.round((sum * MAX_MULTIPLIER_VALUE) / INCOME_SLIDER_STEP) *
      INCOME_SLIDER_STEP;
    maxRange = maxRange <= minRange ? minRange + INCOME_SLIDER_STEP : maxRange;
    this.setState(
      {
        minDisplayIndex,
        topTracks: data,
        cumulativeVisibleTrack:
          data[data.length - 1].cumulativeSum * CUMULATIVE_MAX_PERCENT,
        streamingIncomeMin: minRange,
        streamingIncomeMax: maxRange,
        lastMonthIncome:
          this.state.lastMonthIncome ||
          Math.round((minRange + maxRange) / 3 / 50) * 50,
      },
      () => {
        !this.state.dummyArtist && this.onSliderChange();
      },
    );
  };

  soundExchangeIncomeChange = (e) => {
    this.setState({ soundExchangeIncome: e.target.value });
  };

  handleCancel = () => {
    this.setState(
      {
        tuneData: false,
        minDisplayIndex: 0,
        soundExchangeIncome: this.state.resetSoundExchangeIncome,
        topTracks: this.state.resetTopTracks.map((track) => ({ ...track })),
        isSliderChange: false,
      },
      this.changeTuneDataSlider,
    );
  };

  getSelevtedSliderValue = () => {
    const value =
      parseFloat(this.state.lastMonthIncome) /
      (Math.round(
        parseFloat(
          this.state.streamingIncomeMin + this.state.streamingIncomeMax,
        ) /
          3 /
          50,
      ) *
        50);
    if (value > MAX_MULTIPLIER_VALUE) return MAX_MULTIPLIER_VALUE;
    else if (value < MIN_MULTIPLIER_VALUE) return MIN_MULTIPLIER_VALUE;
    else return value;
  };

  handleSaveTunedData = (isCaching) => {
    if (!isCaching) {
      this.setState({ shortLoading: true, isConfirm: false });
    }
    const payload = {
      streaming_income: this.state.streamingIncome,
      sound_exchange_income: this.state.soundExchangeIncome,
      last_month_income: this.state.lastMonthIncome,
      selected_slider_value: this.getSelevtedSliderValue(),
      top_tracks: this.state.topTracks.map((track) => ({
        isrc: track.isrc,
        track_name: track.track_name,
        streaming_info_share: track.streaming_info_share,
      })),
    };
    const data = {
      method: isCaching ? "PUT" : "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}`;
    request(requestUrl, data)
      .then((res) => {
        if (isCaching === false) {
          this.setState({ tuneData: false, isConfirm: false });
          if (res.status) {
            this.setState({
              resetSoundExchangeIncome: this.state.soundExchangeIncome,
              resetTopTracks: this.state.topTracks.map((track) => ({
                ...track,
              })),
            });
            segment.track.verifiedData(this.state);
            this.props.history.push({
              pathname: FUNDING,
              state: { isDemo: this.state.dummyArtist },
            });
            toast.success(get(res, "message"));
            return true;
          }

          this.setState({ shortLoading: false });
          toast.error(get(res, "message"));
        }
      })

      .catch((err) => {
        this.setState({ shortLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };
  handleContactPopup = () => {
    this.setState({ isOpen: true });
  };

  renderLastMonthIncome = () => {
    let lastMonthIncome = "";
    if (
      this.state.streamingIncomeMin !== "" &&
      this.state.streamingIncomeMax !== ""
    ) {
      if (
        parseFloat(this.state.lastMonthIncome) >
        parseFloat(this.state.streamingIncomeMax)
      ) {
        lastMonthIncome = this.state.streamingIncomeMax;
        this.setState({ lastMonthIncome });
      } else if (
        parseFloat(this.state.lastMonthIncome) <
        parseFloat(this.state.streamingIncomeMin)
      ) {
        lastMonthIncome = this.state.streamingIncomeMin;
        this.setState({ lastMonthIncome });
      }
      return (
        <ReactRange
          data-testid="LastMonthRange"
          className={styles.reactRangeLastMonth}
          values={
            lastMonthIncome ? [lastMonthIncome] : [this.state.lastMonthIncome]
          }
          onChange={() => {}}
          onFinalChange={(values) => {
            this.setState(
              {
                lastMonthIncome: values[0],
              },
              () => {
                !this.state.dummyArtist && this.onSliderChange();
              },
            );
          }}
          min={this.state.streamingIncomeMin}
          max={this.state.streamingIncomeMax}
          thumbText={["$", ""]}
          step={INCOME_SLIDER_STEP}
          currencyValue
        />
      );
    }
    return null;
  };

  handleModalOpen = () => {
    this.setState({ isConfirm: true });
  };
  handleModalClose = () => {
    this.setState({ isConfirm: false });
  };

  isCumulativeSumTracks = (track, index) =>
    track.cumulativeSum <= this.state.cumulativeVisibleTrack ||
    this.state.minDisplayIndex >= index ||
    MIN_TRACK_DISPLAY >= index + 1;

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu
          isDemo={this.state.dummyArtist}
          offerStage={this.state.offerStage}
          {...this.props}
        />
        <div className={styles.funnelContainer}>
          <LoginHeader
            isDemo={this.state.dummyArtist}
            headerTitle={VERIFY_DATA}
          />
          <div
            className={`${styles.pageContainer} ${styles.dataReviewContainer}`}
          >
            <div
              className={`${styles.mainContainer} ${styles.streamingDataMainContainer} `}
            >
              <div
                className={`${styles.scrollContainer} ${styles.streamingDataScrollContainer} `}
              >
                <div
                  className={`${styles.container} ${styles.streamingDataContainer} `}
                >
                  {!this.state.loading && (
                    <>
                      <div className={styles.titleContainer}>
                        <div className={styles.title}>
                          <h1>
                            Does this data look right?
                            <Tooltip 
                              place="top"
                              light
                              id="your_advance"
                              delay={100}
                              content={TUNE_YOUR_DATA}
                            />
                          </h1>
                          <p>
                            Ownership of the tracks directly impacts funding.
                            Use the sliders <br /> to adjust
                            <span> the artist’s share of steaming income.</span>
                          </p>
                        </div>
                        <p className={styles.RightText}>
                          <VerifyPageSvg className={styles.svgIcon} />
                          <span>
                            Note: Recent releases may not appear below
                          </span>
                        </p>
                      </div>
                      <div className={`${styles.welcomeModal}`}></div>

                      <div className={`${styles.welcomeModal}`}>
                        <div className={styles.dataReviewSecondHeading}>
                          <div className={styles.tableWrapper}>
                            <table>
                              <tbody>
                                <tr className={styles.lastModalRow}>
                                  {!this.state.dummyArtist && (
                                    <td>
                                      <span className={styles.link}>
                                        If something doesn’t look right,
                                      </span>
                                      <span
                                        onClick={this.handleContactPopup}
                                        className={styles.contactLink}
                                      >
                                        {" "}
                                        Contact Us
                                      </span>
                                    </td>
                                  )}
                                  <td>
                                    <p className={classes.specialChar}>
                                      {getShortNumber(
                                        this.state.streamingVolume,
                                      )}
                                    </p>
                                    <p>Total Streams Last Month</p>
                                  </td>
                                  <td className={styles.btnContainer}>
                                    {this.state.isSliderChange && (
                                      <button
                                        className={styles.resetButton}
                                        onClick={this.handleCancel}
                                      >
                                        RESET
                                      </button>
                                    )}
                                    <button
                                      onClick={this.handleModalOpen}
                                      className={styles.submitButton}
                                    >
                                      CONFIRM
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className={`${styles.modalBody}`}>
                          <table className={styles.tuneDataSecondTable}>
                            <thead>
                              <tr>
                                <th>
                                  <p>TOP TRACKS</p>
                                </th>
                                <th>
                                  <p>STREAMS LAST MONTH</p>
                                </th>
                                <th>
                                  <p>ARTIST SHARE OF STREAMING INCOME</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.topTracks.map(
                                (track, index) =>
                                  (this.isCumulativeSumTracks(track, index) ||
                                    this.state.othersTrackIndex !== -1) &&
                                  this.state.othersTrackIndex !== index && (
                                    <tr key={track.isrc}>
                                      <td>
                                        <div className={styles.trackProfile}>
                                          {track.image_url ? (
                                            <Image
                                              src={track.image_url}
                                              alt="album"
                                              imageType={IMAGE_TYPE.PROFILE}
                                            />
                                          ) : (
                                            <SvgIcons icon={MUSIC_ICON} />
                                          )}
                                          <div className={styles.trackName}>
                                            <p>{track.track_name}</p>
                                            <p className={styles.mobLastMonth}>
                                              <span>
                                                {getShortNumber(
                                                  track.streams_last_month,
                                                )}
                                              </span>
                                              Streams last month
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className={styles.chartData}>
                                          <p>
                                            {getShortNumber(
                                              track.streams_last_month,
                                            )}
                                          </p>
                                          {this.getLineChart(
                                            track.trend_line_data,
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className={styles.mobSliderIncome}>
                                          <span>Share of streaming income</span>
                                          <ReactRange
                                            data-testid="ReactRange"
                                            yourAdvance={FUNDING}
                                            className={styles.reactRange}
                                            values={[
                                              track.streaming_info_share,
                                            ]}
                                            onChange={() => {}}
                                            onFinalChange={(values) => {
                                              this.changeTuneDataSlider(
                                                values[0],
                                                track.isrc,
                                              );
                                              if (!this.state.isSliderChange) {
                                                this.setState({
                                                  isSliderChange: true,
                                                });
                                              }
                                            }}
                                            min={MIN_VALUE}
                                            max={MAX_VALUE}
                                            step={RANGE_STEP}
                                            valueAlign="right"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ),
                              )}
                              {this.getOtherTracksInfo()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {this.state.dummyArtist && (
                        <FunnelCollapseGuide
                          guideData={this.state.guideData}
                          isConfirm={this.state.isConfirm}
                          {...this.props}
                        />
                      )}
                    </>
                  )}
                  {this.state.loading && <Loader backgroundNone />}
                  {this.state.shortLoading && (
                    <ShortCallLoader
                      loaderTitle="Hold tight"
                      text={this.loaderText()}
                      defaultLoader
                    />
                  )}
                </div>
              </div>
            </div>

            <ReactModal
              isOpen={this.state.isConfirm}
              shouldCloseOnEsc
              shouldCloseOnOverlayClick
              onRequestClose={this.handleModalClose}
              className={styles.contactModal}
              overlayClassName={styles.confirmModalOverlay}
            >
              <div className={styles.modal}>
                <div onClick={this.handleModalClose} className={styles.close}>
                  <img src={closeSvg} alt="closeIcon" />
                </div>
                <div className={styles.modalText}>
                  <p>How much streaming income did you receive last month?</p>

                  <p className={styles.secondDesc}>
                    An approximate number is fine but accuracy is key! This
                    slider is used to create your estimates on the next screen.
                    <div className={styles.modalRpw}>
                      <p className={styles.specialChar}>
                        ${numberWithCommas(this.state.streamingIncomeMin)}
                      </p>
                      {this.renderLastMonthIncome()}
                      <p className={styles.specialChar}>
                        ${numberWithCommas(this.state.streamingIncomeMax)}
                      </p>
                    </div>
                  </p>
                  <div className={styles.lastDesc}>
                    <div className={styles.leftInfoText}>
                      <VerifyPageSvg className={styles.clipBoardIcon} />
                      <span>{VERIFY_DATA_NOTE}</span>
                    </div>
                    <button
                      className={styles.continueButton}
                      onClick={() => this.handleSaveTunedData(false)}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        </div>
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
      </div>
    );
  }
}

TuneMyData.contextType = ThemeContext;
export default TuneMyData;
